import React, { useState } from 'react'
import TableView from '../../Packages/Custom/DataTable'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import { QuotationProvider } from './Context/quotationContext'
import QuotationFrom from './Forms/Quatationform'

const QuotationTable = () => {
  const [isLoad, setLoad] = useState(false)
  const [quotationFormIsShowing, setQuotationFormIsShowing] = useState(false)
  const [QuotationEditData, setQuotationEditData] = useState(null)


  const CustomViewModalComponent = data => {
    // Function to open the modal for editing a quotation.
    // It sets the quotation ID for the data being edited and shows the form.
    
    
    setQuotationEditData(data?.id)
    setQuotationFormIsShowing(true)
  }


  const headers = [
    // Table headers configuration for displaying data.
    {
      header: 'quotationNo',
      field: 'quotationNo',
      flex: 10,
      label: 'linkedModelId',
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Costomer Name',
      field: 'customerId',
      label: "companyName",
      flex: 10,
      inputType: 'text',
      },
      {
        header: 'City',
        field: 'customerAddress',
        label: "city",
        queryField: "city",
        flex: 10,
        inputType: 'text',
    },
    {
        header: 'State',
        field: 'customerAddress',
        label: "state",
        queryField: "state",
        flex: 10,
        inputType: 'text',
    },
    {
      header: 'Net Amount',
      field: 'netAmount',
      flex: 10,
      inputType: 'number',
  },
    {
      header: 'Date',
      field: 'CreatedAt',
      flex: 10,
      inputType: 'text',
      isDateField: true,
  },
  ]
  const CustomAddModalComponent = data => {
    // Function to open the modal for adding a new quotation.
    // It clears any existing data and shows the form.
    setQuotationEditData(null);
    setQuotationFormIsShowing(true)
  }

  return (
    <QuotationProvider>
      <>
        <div className='container-fluid index_page_aliment_warp'>
          <div className='index_page_aliment_sidebar'>
            <SideNavbar />
          </div>
          <div className='index_page_aliment_table'>
            <TableView
              headers={headers}
              query={'QuotationTable'}
              addComponent={CustomAddModalComponent}
              loadComponent={isLoad}
              titleName={'Quotation'}
              heightAdjust={'50rem'}
              permission={'View_Quotation'}
            />
          </div>
        </div>
        
        <QuotationFrom
          quotationFormIsShowing={quotationFormIsShowing}// Control the visibility of the form
          setQuotationFormIsShowing={setQuotationFormIsShowing}// Function to change visibility
          setLoad={() => {
            setLoad(!isLoad)
          }}
          quotationId={QuotationEditData} // Data for the quotation being edited
          setQuotationEditData={setQuotationEditData}
         
        />
      </>
    </QuotationProvider>
  )
}

export default QuotationTable
