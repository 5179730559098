import { Formik, Form} from "formik";
import { Modal } from "react-bootstrap";
import { mrp_item_schema } from "../../../validations/mrp";
import { useEffect, useState, useContext } from "react";
import Select from 'react-select';
import { customSelectStyle } from '../../../utils/Utils';
import { useSelector, useDispatch } from 'react-redux';
import { TotalStockByPartCode } from '../../../GraphQLQueries/ItemmasterQuery'
import { fetchItemMasterData } from "../../../ReduxState/Action/itemMasterAction";
import axiosInstance from "../../../api/axoiss";
import { FetchMrpSourceOptionQuery, MrpItemCreateQuery, MrpItemMasterFetchQuery } from "../../../GraphQLQueries/MrpQuery";
import { FetchBOMOptionOnType } from "../../../GraphQLQueries/BomMutation";
import DataContext from '../../../context/ItemMasterContext';
import { toast } from "react-toastify";


const MrpItem = ({show, close, handleAddedMrpItem, data}) => {
    const {userId} =   useContext(DataContext)
    const dispatch = useDispatch(); 
    const itemMasterDataList = useSelector(state => state.itemMasterData.data);
    const [sourceTypeOptions, setSourceTypeOptions] = useState([])
    const [bomOptionList, setBomOptionList] = useState([]);
    let initialMrpItemData = {
        itemPartCode: null,
        itemName: null,
        bom: null,
        qty: 0,
        availableQty: 0,
        shortageQty: 0,
        supplier: '',
        sourceType: '',
        cost: 0,
    }
    const [mrpItemData, setMrpItemData] = useState(initialMrpItemData);
    const [selectedPartCode, setSelectedPartCode] = useState(null)
    const [selectedPartName, setSelectedPartName] = useState(null)
    const [selectedBom, setSelectedBom] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [qty, setQty] = useState(0);
    const [cost, setCost] = useState(0);
    const [availableQty, setAvailableQty] = useState(0);
    const [shortageQty, setShortageQty] = useState(0);
    const [supplierList, setSupplierList] = useState([]);
    const [sourceType, setSourceType] = useState(null);


    const handleSelectpartCode = (option)=>{
        setSelectedPartCode({ value: option?.value , label:  option?.label })
    }

    const handleSelectpartName = (option)=>{ 
        setSelectedPartName({ value: option?.value , label:  option?.label })
    }

    const handleSelectQty = async (value) => {
        setQty(value);
        try{
            const total_stock_res = await axiosInstance.post(`/itemmaster/graphql`, { query: TotalStockByPartCode(selectedPartCode?.id) });
            const total_stock_res_data = total_stock_res?.data?.data?.itemStockByPartCode?.items?.[0]?.totalCurrentStock || 0;  
            setAvailableQty(total_stock_res_data)
            let calculate_shortage_qty = total_stock_res_data - value
            if(calculate_shortage_qty < 1){
                setShortageQty(Math.abs(calculate_shortage_qty))
            }
        }catch(e){
            console.log(e)
            // setCost(0)
            setShortageQty(0)
        }
    }

    const handleSelectBom = (option, setFieldValue) => {
        setSelectedBom(option)
        if(option){
            if(sourceType?.value !== 3){
                let source_type_option = sourceTypeOptions.find(item => item.label === option.bomType)
                if(source_type_option){
                    setSourceType(source_type_option)
                    setFieldValue('sourceType', source_type_option.value);
                }
            }

            // select supplier
            let supplier_list = option?.supplier || [];
            if(supplier_list && supplier_list.length > 0){
                supplier_list = supplier_list.map(item => ({label: item.companyName, value: item.id}))
            }
            setSupplierList(supplier_list)
            setFieldValue('supplier', supplier_list.map(item => item.value))
        }else{
            setSourceType(null)
        }
    }

    const handleUpdateDataBasedOnPartCode = async (option, setFieldValue) =>{
        if(option){
            // set source type from item master only for buy type
            let item_master_indicator_type = option?.itemIndicators?.name 
            if(option?.isManufacture){
                setSourceType({label: "MANUFACTURE", value:1})
                setFieldValue('sourceType', 1)
            }
            else if(item_master_indicator_type && item_master_indicator_type === "Buy"){
                setSourceType({label: "BUY", value:3})
                setFieldValue('sourceType', 3)
            }else{
                setSourceType(null)
            }
            // set item price
            setCost(option?.itemMrp || 0)

            // get available qty 
            const total_stock_res = await axiosInstance.post(`/itemmaster/graphql`, { query: TotalStockByPartCode(option?.id) });
            const total_stock_res_data = total_stock_res?.data?.data?.itemStockByPartCode?.items?.[0]?.totalCurrentStock || 0;  
            setAvailableQty(total_stock_res_data)

            // select supplier
            let supplier_list = option?.supplierData || [];
            if(supplier_list && supplier_list.length > 0){
                supplier_list = supplier_list.filter(item => item.supplier === true).map(item => ({label: item.companyName, value: item.id}))
            }
            setSupplierList(supplier_list)
            setFieldValue('supplier', supplier_list.map(item => item.value))

            // fetch bom based on part code
            axiosInstance.post("/itemmaster/graphql", {query: FetchBOMOptionOnType(option?.id)})
            .then(response => {
                const keys = Object.keys(response.data.data);
                const firstKey = keys.length > 0 ? keys[0] : null;
                const data = response.data.data[firstKey];
                let bom_data_list = data?.items || []
                bom_data_list = bom_data_list.map(item => ({value: item.id, label : `${item.bomNo} --- ${item.bomName}`, ...item}))
                setBomOptionList(bom_data_list)
                const defaultBomOption = shouldSetDefaultBom(bom_data_list) ? getDefaultBomOption(bom_data_list) : null;
                setSelectedBom(defaultBomOption);
                setFieldValue('bom', defaultBomOption ? defaultBomOption.value : null);
            })
            .catch(error => {
                console.log(error)
            });
        }else{
            handleClearData()
        }
    }

    const handleClearData = () => {
        setMrpItemData(initialMrpItemData)
        setSelectedPartCode(null)
        setSelectedPartName(null)
        setQty(0)
        setAvailableQty(0)
        setShortageQty(0)
        setCost(0)
        setSupplierList([])
        setSourceType(null);
    }

    const handleOnInputChangeTypeSearch = (value, field) => {
        if(value.trim() !== ''){
            let filter_obj = {
                key: field,
                value: value
            }
            dispatch(fetchItemMasterData(MrpItemMasterFetchQuery(filter_obj)));
        }else{
            dispatch(fetchItemMasterData(MrpItemMasterFetchQuery()))
        }
    }

    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors, setFieldError }, toggle, setOpenRowIndex, rowdata) => {
        let submit_data = {
            partCode: parseInt(selectedPartCode?.value),
            bom: parseInt(selectedBom?.value),
            qty: qty,
            sourceType: parseInt(sourceType?.value || 1),
            cost: cost,
            modifiedBy: parseInt(userId),
            createdBy: parseInt(userId),
        }
        if(selectedSupplier){
            submit_data['supplier'] = parseInt(selectedSupplier?.value)
        }
        if(data){
            submit_data['id'] = data?.id
        }
        let mrp_item_create_query = MrpItemCreateQuery(submit_data)
        axiosInstance.post("/itemmaster/graphql", {query: mrp_item_create_query})
        .then(response => {
            const keys = Object.keys(response.data.data);
            const firstKey = keys.length > 0 ? keys[0] : null;
            const data = response.data.data[firstKey];
            if(data?.success){
                handleAddedMrpItem(parseInt(data?.mrpItem?.id || 0))
                toast.success('Data created successfully!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                close()
            }else{
                toast.error(data?.errors?.[0], {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
        })
        .catch(error => {
            toast.error('Failed to Save', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        });
    }

    const FetchMrpSourceType = () => {
        let mrp_source_option_query = FetchMrpSourceOptionQuery()
        axiosInstance.post("/itemmaster/graphql", {query: mrp_source_option_query})
        .then(res => {
            let mrp_source = res?.data?.data?.mrpSource?.items || []
            if(mrp_source && mrp_source.length > 0){
                mrp_source = mrp_source.map(item => ({label: item.name, value: item.id}))
                setSourceTypeOptions(mrp_source)
            }
        })
        .catch(err =>{ 
            console.log(err, "error")
        })
    }

       // Function to check if default BOM should be set
       const shouldSetDefaultBom = (options) => {
        return options.length === 1;
    };

        // Function to get the default BOM option
         const getDefaultBomOption = (options) => {
         return options.length === 1 ? options[0] : null;
     };

    useEffect(()=>{
        if(data){
            setCost(data?.cost || 0)
            setQty(data?.qty)
            setSelectedPartCode({label: data?.partCode?.itemName, value: data?.partCode?.id, ...data?.partCode})
            setSelectedPartName({label: data?.partCode?.id, value: data?.partCode?.id, ...data?.partCode})
            setSelectedBom({value: data?.bom?.id, label : `${data?.bom?.bomNo} --- ${data?.bom?.bomName}`, ...data?.bom})
            let supplier_data = data?.supplier?.map(item => ({label: item.companyName, value: item.id}))
            setSelectedSupplier(supplier_data?.[0])
            setSupplierList(supplier_data)
            setSourceType({label: data?.sourceType?.name, value:data?.sourceType?.id})
            setMrpItemData({
                itemPartCode: data?.partCode?.id,
                itemName: data?.partCode?.id,
                bom: data?.bom?.id,
                qty: data?.qty,
                availableQty: 0,
                shortageQty: 0,
                supplier: supplier_data?.[0].value,
                sourceType: data?.sourceType?.id,
                cost: data?.cost || 0,
            })
        }
        FetchMrpSourceType()
    }, [])

    return (
        <>
        <Modal show={show} size='xl' onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>Mrp Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik 
                    enableReinitialize={true}
                    initialValues = {mrpItemData}
                    validationSchema={mrp_item_schema}   
                    onSubmit={handleSubmit}
                >
                {({errors, touched, isSubmitting, values, setFieldValue , Formik, formikProps })=>{
                    return(<>
                        <Form style={{width: "100%" }}>
                            <div>
                                <div className="mx-3">
                                    <div className='row pt-3'>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                onInputChange={(e)=>{handleOnInputChangeTypeSearch(e, 'itemPartCode')}}
                                                options={itemMasterDataList?.items?.map((item) => ({ value: item.id, label: item.itemPartCode, name:item.itemName, ...item  }))}
                                                name='itemPartCode' style={{fontSize: '.8rem'}} 
                                                isSearchable={true}
                                                isClearable
                                                value={selectedPartCode}
                                                onChange={(option) => {
                                                    setFieldValue('itemName', option ? option.value  : null);
                                                    setFieldValue('itemPartCode', option ? option.value  : null);
                                                    handleClearData()
                                                    handleSelectpartName({ value: option?.value, label: option?.name }) 
                                                    handleSelectpartCode(option)
                                                    handleUpdateDataBasedOnPartCode(option, setFieldValue)
                                                }}  
                                                className='mt-3'
                                                styles={customSelectStyle} 
                                                defaultValue={shouldSetDefaultBom(bomOptionList) ? getDefaultBomOption(bomOptionList) : null}           
                                            />
                                            <label htmlFor="itemPartCode" className='staan-form-input-label pt-1 px-1 mx-2'>Part Code</label>
                                            <p>{  touched.itemPartCode &&  errors.itemPartCode && <small>{errors.itemPartCode}</small>}</p>
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                onInputChange={(e)=>{handleOnInputChangeTypeSearch(e, 'itemName')}}
                                                name='itemName' style={{fontSize: '.8rem'}} 
                                                options={itemMasterDataList?.items?.map((item) => ({ value: item.id, label: item.itemName , partCode: item.itemPartCode, ...item}))}
                                                isSearchable={true}
                                                value={selectedPartName}
                                                isClearable
                                                onChange={(option) => {
                                                    setFieldValue('itemName', option ? option.value   : null);
                                                    setFieldValue('itemPartCode', option ? option.value  : null);
                                                    handleClearData()
                                                    handleSelectpartName(option) 
                                                    handleSelectpartCode({ value: option?.value, label: option?.partCode })
                                                    handleUpdateDataBasedOnPartCode(option, setFieldValue)
                                                }}  
                                                className='mt-3'
                                                styles={customSelectStyle}  
                                            />
                                            <label htmlFor="itemName" className='staan-form-input-label pt-1 px-1 mx-2'>Part Name</label>
                                            <p>{  touched.itemName &&  errors.itemName && <small>{errors.itemName}</small>}</p>
                                        </div>
                                    </div>
                                    <div className="row pt-3">
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                name='bom' style={{fontSize: '.8rem'}} 
                                                options={bomOptionList}
                                                isSearchable={true}
                                                value={selectedBom}
                                                onChange = {(option)=>{
                                                    setSelectedBom(option);
                                                    setFieldValue('bom', option ? option?.value :null);
                                                    handleSelectBom(option, setFieldValue)
                                                }}
                                                isClearable 
                                                className='mt-3'
                                                styles={customSelectStyle}  
                                                defaultValue={bomOptionList.length === 1 ? bomOptionList[0] : null}
                                            />
                                            <label htmlFor="bom" className='staan-form-input-label pt-1 px-1 mx-2'>Bom</label>
                                            <p>{  touched.bom &&  errors.bom && <small>{errors.bom}</small>}</p>
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                name='sourceType' style={{fontSize: '.8rem'}} 
                                                value={sourceType}
                                                isDisabled = {true}
                                                className='mt-3'
                                                styles={customSelectStyle}  
                                            />
                                            <label htmlFor="sourceType" className='staan-form-input-label pt-1 px-1 mx-2'>Source Type</label>
                                            <p>{ touched.sourceType &&  errors.sourceType && <small>{errors.sourceType}</small>}</p>
                                        </div>
                                    </div>
                                    <div className="row pt-3">
                                        <div className="col-4 staan-input-group">
                                            <input type='text' className='w-100 staan-form-input' 
                                                onChange={(e)=>{
                                                    handleSelectQty(e.target.value); 
                                                    setFieldValue('qty', e.target.value);
                                                }}
                                                value={qty}
                                                name='qty' style={{fontSize: '.8rem'}} 
                                            />
                                            <label htmlFor="qty" className='staan-form-input-label pt-2 px-1 mx-2'>Quantity</label>
                                            <p>{ touched.qty &&  errors.qty && <small>{errors.qty}</small>}</p>
                                        </div>
                                        <div className="col-4 staan-input-group">
                                            <input type='text' className='w-100 staan-form-input' 
                                                onChange={(e)=>{
                                                    // handleSelectQty(e.target.value); 
                                                    setFieldValue('availableQty', e.target.value);
                                                }}
                                                disabled
                                                value={availableQty}
                                                name='availableQty' style={{fontSize: '.8rem'}} 
                                            />
                                            <label htmlFor="qty" className='staan-form-input-label pt-2 px-1 mx-2'>Available Quantity</label>
                                        </div>
                                        <div className="col-4 staan-input-group">
                                            <input type='text' className='w-100 staan-form-input' 
                                                onChange={(e)=>{
                                                    // handleSelectQty(e.target.value); 
                                                    setFieldValue('shortageQty', e.target.value);
                                                }}
                                                value={shortageQty}
                                                disabled
                                                name='shortageQty' style={{fontSize: '.8rem'}} 
                                            />
                                            <label htmlFor="shortageQty" className='staan-form-input-label pt-2 px-1 mx-2'>Shortage Quantity</label>
                                        </div>
                                    </div>
                                    <div className="row pt-3">
                                        <div className="col-4 staan-input-group">
                                            <Select
                                                name='supplier' style={{fontSize: '.8rem'}} 
                                                options={supplierList}
                                                value = {selectedSupplier}
                                                className='mt-3'
                                                styles={customSelectStyle}  
                                                onChange={(option)=>{
                                                    setSelectedSupplier(option);
                                                }}
                                                // isDisabled
                                            />
                                            <label htmlFor="supplier" className='staan-form-input-label pt-1 px-1 mx-2'>Supplier</label>
                                            <p>{  touched.supplier &&  errors.supplier && <small>{errors.supplier}</small>}</p>
                                        </div>
                                        <div className="col-4 staan-input-group">
                                            <input type='text' className='w-100 staan-form-input' 
                                                value={cost * qty}
                                                name='cost' style={{fontSize: '.8rem'}} 
                                                disabled
                                            />
                                            <label htmlFor="cost" className='staan-form-input-label pt-2 px-1 mx-2'>Cost</label>
                                            <p>{ touched.cost &&  errors.cost && <small>{errors.cost}</small>}</p>
                                        </div>
                                        <div className="col-4 d-flex justify-content-end align-items-center">
                                            <div>
                                                <button 
                                                    onClick={()=>{console.log(errors, "errors")}}
                                                    type="submit" 
                                                    className="btn btn-outline-success px-3">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </>)
                }}
                </Formik>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default MrpItem;