function convertToGraphQLQuery(data) {
  let query = '';
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      let value = data[key];
      if (typeof value === 'object' && !Array.isArray(value)) {
        // Handle nested object (you can modify this part if needed)
        query += `, ${key}:{`;
        for (let nestedKey in value) {
          if (value.hasOwnProperty(nestedKey)) {
            query += `${nestedKey}:"${value[nestedKey]}",`;
          }
        }
        query = query.slice(0, -1); // Remove trailing comma
        query += '}';
      } else if (Array.isArray(value)) {
        // Handle array
        query += `, ${key}:[${value}]`;
      } else {
        // Handle primitive types
        if (typeof value === 'string') {
          query += `, ${key}:"${value}"`;
        } else {
          query += `, ${key}:${value}`;
        }
      }
    }
  }
  return query;
}

export const FetchMrpSourceOptionQuery = () => {
    let query = `query MyQuery {
        mrpSource{
          items{
            id
            name
          }
        }
    }`
    return query
}


export const MrpItemMasterFetchQuery = (search_filter) => {
    let query = `query myFirstQuery{
      itemMaster(pageSize :200`
    if(search_filter){
        query += ` ${search_filter.key}: "${search_filter.value}"`
    }
    query +=` , itemTypes: "Product", isManufacture: true, itemActive:true, bomLinked: true){
        items{
            id
            itemName
            itemPartCode
            serial
            batchNumber
            itemMrp
            isManufacture
            itemLeadTime
            itemIndicators{
                id
                name
            }
            supplierData{
              id
              supplier
              companyName
            }
          }
        }
    }`
    return query;
}


export const MrpItemFetchQuery = (ids) =>{
  let query = `query FetchMrpItemData{
    mrpItem(idList: [${ids}]){
      items{
        id
        partCode{
          id
          itemName
          itemPartCode
        }
        sourceType{
          id
          name
        }
        bom{
          id
          bomNo
          bomName
          bomType
          electricityCharges
          machineryCharges
          labourCharges
          otherCharges
          rawMaterial{
            qty
            id
            itemCost
          }
          finishedGoods{
            id
            partNo{
              itemPartCode
            }
            labourCharges
            qty
          }
        }
        qty
        cost
        supplier{
          id
          companyName
        }
      }
    }
  }`
  return query;
}


export const MrpItemCreateQuery = (data) => {
  let formatted_query_string = convertToGraphQLQuery(data);
  let query = `mutation CreateMrpItem{
    mrpItemCreateMutation(${formatted_query_string}){
      mrpItem {
        id
      }
      success
      errors
    }
  }`
  return query;
}
