import { AgGridReact } from 'ag-grid-react';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { TiCancel } from 'react-icons/ti';
import Select from 'react-select';
import { toast } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import BallTriangleLoading from "../../../components/loading/BallTriangle";
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import WaringModel from '../../../components/Warings/WaringModel';
import DataContext from '../../../context/ItemMasterContext';
import { employeeOptionQuery } from '../../../GraphQLQueries/EmployeeQueryAndMutations/EmployeeQuery';
import { expenseClaimCreateMutation, expenseClaimDeleteMutation, expenseClaimDetailsDeleteMutation } from '../../../GraphQLQueries/ExpenseQueryandMutation/expenseMutation';
import { expenseClaimEditQuery } from '../../../GraphQLQueries/ExpenseQueryandMutation/expenseQuery';
import { customSelectStyle, removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import { ExpenceClaimValidation } from '../../../validations/ExpenceValidation';
import ClaimsModal from './claimsModalPopUp';

const ExpenseClaimModal = ({ isExpenseClaimsShow, setExpenseClaimsShow, setLoad, setClaimEditData, claimEditData }) => {
    const [initialExpenseClaim, setinitialExpenseClaim] = useState({
        claimNo: '',
        claimDate: '',
        employeeDetailID: '',
        employeeDetailName: '',
        doe: '',
        status: '',
        id: '',
        expenseClaimDate: '',
        createdBy: '',

    })
    const { handltoastDeleteConfomationShow, userId } = useContext(DataContext)

    const [claimValues, setClaimValues] = useState({
        employeeIdSelect: '',
        employeeNameSelect: '',
        employeeImage: '',
        deleteData: ''
    })
    const [employeeOptionList, setEmployeeOptionList] = useState({
        employeeIDList: [],
        employeeNameList: []
    })
    const [IsShowClaimsDetail, setShowClaimsDetail] = useState(false)
    const [claimsDetailsData, setclaimsDetailsData] = useState([])
    const [editData, seteditData] = useState([])
    const [formKey, setFormKey] = useState(0)
    const [loading, setLoading] = useState(false)
    const [editView, seteditView] = useState(false)
    const [isDeleteWarningShow, setIsDeleteWarningShow] = useState(false)

    useEffect(() => {
        if (isExpenseClaimsShow && claimEditData !== null && claimEditData?.id) {
            fetchEditData(claimEditData?.id)
            seteditView(true)
        }
        else {
            seteditView(false)
        }
    }, [isExpenseClaimsShow, claimEditData])

    async function fetchEditData(id) {
        setLoading(true)
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: expenseClaimEditQuery(`id:${id}`) });
            const reponseData = response?.data?.data?.allExpenseClaim?.items?.[0]
            if (reponseData) {
                console.log("reponseData", reponseData);

                setinitialExpenseClaim({
                    claimNo: reponseData?.expenseClaimNo ? reponseData?.expenseClaimNo : '',
                    claimDate: reponseData?.expenseClaimDate ? reponseData?.expenseClaimDate : '',
                    employeeDetailID: reponseData?.employeeId ? Number(reponseData?.employeeId?.id) : '',
                    employeeDetailName: reponseData?.employeeId ? Number(reponseData?.employeeId?.id) : '',
                    // doe:reponseData?.,
                    status: reponseData?.status ? reponseData?.status?.name : '',
                    id: reponseData?.id ? reponseData?.id : '',
                    expenseClaimDate: reponseData?.expenseClaimDate ? reponseData?.expenseClaimDate : '',
                    createdBy: reponseData?.createdBy ? reponseData?.createdBy?.id : '',
                    modifiedBy: reponseData?.modifiedBy ? reponseData?.modifiedBy?.id : '',
                })
                setClaimValues(prve => ({
                    ...prve,
                    employeeIdSelect: { value: reponseData?.employeeId ? reponseData?.employeeId?.id : '', label: reponseData?.employeeId ? reponseData?.employeeId?.employeeId : '' },
                    employeeNameSelect: { value: reponseData?.employeeId ? reponseData?.employeeId?.id : '', label: reponseData?.employeeId ? reponseData?.employeeId?.employeeName : '' },
                    employeeImage: reponseData?.employeeId ? reponseData?.employeeId?.userProfile?.image : '',
                }))
                setclaimsDetailsData(reponseData?.expenseClaimDetails)
                setFormKey(prve => prve + 1)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            let errorData = ShowErrorNoties(error)
            showErrorToast(true, 'error', errorData)
        }

    }
    async function getOptions(e, name) {
        if (name === 'employeeID') {
            let peremeter = `employeeId:"${e}",employeeName:""`
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: employeeOptionQuery(peremeter) });
            const responseData = response?.data?.data?.allEmployee?.items
            console.log(response?.data);
            if (responseData?.length > 0) {
                setEmployeeOptionList(prevState => ({
                    ...prevState,
                    employeeIDList: responseData.map(item => ({
                        value: item?.id,
                        label: item?.employeeId,
                        employeeName: item?.employeeName,
                        image: item?.userProfile?.image
                    }))
                }));
            }
        }
        if (name === 'employeeName') {
            let peremeter = `employeeId:"",employeeName:"${e}"`
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: employeeOptionQuery(peremeter) });
            const responseData = response?.data?.data?.allEmployee?.items
            console.log(response?.data);
            if (responseData?.length > 0) {
                setEmployeeOptionList(prevState => ({
                    ...prevState,
                    employeeNameList: responseData.map(item => ({
                        value: item?.id,
                        label: item?.employeeName,
                        employeeId: item?.employeeId,
                        image: item?.userProfile?.image
                    }))
                }));
            }
        }

    }
    const handleDeleteClaimDetails = (id) => {
        if (id) {
            console.log("Delete Id", id);
            setclaimsDetailsData((prevState) =>
                prevState.filter((item) => item.id !== id)
            );

        }
    }
    async function DeleteClaim() {
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: expenseClaimDeleteMutation(`id:${initialExpenseClaim?.id}`) })
            let reponseData = response?.data?.data?.expenseClaimDeleteMutation
            if (reponseData?.success) {
                setLoad()
                setExpenseClaimsShow(false)
            } else {
                showErrorToast(true, "error", reponseData?.errors)
            }
        } catch (error) {
            let errorFilter = ShowErrorNoties(error)
            showErrorToast(true, "error", errorFilter)
        }

    }
    const actionsButton = ({ value, data }) => {
        const handleClick = () => {
            seteditData(data)
            setShowClaimsDetail(true)
        }
        const handledeleteConfomation = () => {
            setClaimValues(prve => ({ ...prve, deleteData: { Name: data?.expenseCategories?.expenseCategoryName, id: data.id } }))
            handltoastDeleteConfomationShow()
        }
        return (
            <>
                <button
                    type="button"
                    className="btn btn-outline-success btn-sm px-3 mx-2"
                    onClick={handleClick}
                    disabled={editView}
                >
                    <i className="fa-solid fa-pen"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-outline-danger btn-sm px-3 mx-2"
                    onClick={() => { handledeleteConfomation() }}
                    disabled={editView}
                >
                    <i className="fa-solid fa-trash"></i>
                </button>

            </>
        );
    };

    const COLUMNS = [
        {
            headerName: 'DOE',
            flex: .5,
            field: 'dateOfExp'
        },
        {
            headerName: 'Expense Category',
            flex: .10,
            field: 'expenseCategories.expenseCategoryName'
        },
        {
            headerName: 'Description',
            flex: .10,
            field: 'descriptions'
        },
        {
            headerName: 'Claim Amount',
            flex: .3,
            field: 'amount'
        },
        {
            headerName: 'GST Inv',
            flex: .2,
            field: 'gstIn',
            option: true,
            bool: true,
            inputType: 'text',
            align: 'center',
        }, {
            headerName: 'Attach Invoice',
            flex: .2,
            field: 'pdfUrl'
        },
        {
            headerName: 'Approved Amount',
            flex: .2,
            field: ''
        },
        {
            headerName: 'Action',
            cellRenderer: actionsButton,
            flex: .4,
        },


    ]
    const DEFAULT_COL_DEF = {
        filter: true, floatingFilter: true,
        resizable: true,
    }

    const handleSubmit = async (values, { resetForm, setErrors }) => {
        const reqBody = {
            createdBy: values?.id ? Number(values?.createdBy) : Number(userId),
            employeeId: values?.employeeDetailID,
            expenseClaimDate: values?.claimDate ? values?.claimDate : '',
            expenseClaimDetails: claimsDetailsData.map(item => item.id),
            id: values?.id ? Number(values?.id) : '',
            modifiedBy: values?.modifiedBy ? Number(values?.modifiedBy) : '',
            status: values?.status
        }
        console.log("reqBody", reqBody);
        let allow_null = ['id', "modifiedBy"]
        let clearedValue = removeEmptyValueInObject(reqBody, allow_null)
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, {
                query: expenseClaimCreateMutation(clearedValue, allow_null)
            })
            const responseData = response?.data?.data?.expenseClaimCreateMutation
            if (responseData.success) {
                toast.success(`Expense Claim Detail Created Successfully...`, {
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light'
                })
                setLoad()
                setExpenseClaimsShow(false)

            }
            // close()

        }
        catch (error) {
            setLoading(false)
            let errorData = ShowErrorNoties(error)
            showErrorToast(true, 'error', errorData)
        }

    }

    function close() {
        console.log("close function");

        setinitialExpenseClaim({
            claimNo: '',
            claimDate: '',
            employeeDetailID: '',
            employeeDetailName: '',
            doe: '',
            status: '',
            id: '',
            expenseClaimDate: '',
            createdBy: '',

        })
        setClaimValues({
            employeeIdSelect: '',
            employeeNameSelect: '',
            employeeImage: '',
            deleteData: ''
        })
        setEmployeeOptionList({
            employeeIDList: [],
            employeeNameList: []
        })
        setclaimsDetailsData([])
        seteditData([])
        seteditView(false)
        setClaimEditData(null)
        setExpenseClaimsShow(false)
    }
    return (
        <>
            {<BallTriangleLoading isshow={loading} />}
            <Modal show={isExpenseClaimsShow} fullscreen>
                <div className="row mx-1">
                    <div className="col-5 m-3" style={{ color: "#5CADA9" }}>
                        <Modal.Title>
                            <i className="fa-solid fa-arrow-left fa-sm me-2" onClick={() => { close() }}></i>
                            Expense Claim
                        </Modal.Title>
                    </div>
                    <div className="col-6 mt-4 text-end">
                        <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={!editView || initialExpenseClaim?.status === "Submit"} onClick={() => { seteditView(false) }}></i>
                        <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3" hidden={!editView || initialExpenseClaim?.status === "Submit"} onClick={() => { setIsDeleteWarningShow(true) }}></i>
                        <TiCancel
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Cancel"
                            className='fs-2 pb-1'
                            style={{ color: '#7f7f7f', cursor: "pointer" }}
                            hidden={!editView || initialExpenseClaim?.status === "Submit"}
                        >
                        </TiCancel>
                    </div>

                </div>

                <hr />
                <Modal.Body>
                    <Formik
                        initialValues={initialExpenseClaim}
                        validationSchema={ExpenceClaimValidation}
                        onSubmit={handleSubmit}
                        enableReinitialize key={formKey}
                    >
                        {({ errors, submitForm, touched, setFieldValue, values, resetForm }) => (
                            <>
                                <Form>
                                    <div className='row ms-1 mt-1' style={{ width: '100%' }}>
                                        <div className="row">
                                            <div className='col-8'>
                                                <div className="row">
                                                    <div className="col-6 staan-input-group">
                                                        <Field
                                                            type='text'
                                                            name='claimNo'
                                                            id="claimNo"
                                                            values={initialExpenseClaim?.claimNo}
                                                            disabled={true}
                                                            className='w-100 staan-form-input'
                                                        />
                                                        <label htmlFor="claimNo" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                                            console.log("values", values);
                                                        }}>Expense Claim No</label>
                                                    </div>
                                                    <div className="col-6 staan-input-group">
                                                        <Field
                                                            type='date'
                                                            name='claimDate'
                                                            id="claimDate"
                                                            values={initialExpenseClaim?.claimDate}
                                                            disabled={editView}
                                                            className='w-100 staan-form-input'
                                                        />
                                                        <label htmlFor="claimDate" className='staan-form-input-label pt-1 px-1 mx-2'>Expense Claim Date<span className='text-danger'>*</span></label>
                                                        {touched.claimDate && errors.claimDate && <small>{errors.claimDate}</small>}

                                                    </div>

                                                    <div className="col-6 staan-input-group">
                                                        <Select
                                                            onInputChange={(e) => {
                                                                e ? getOptions(e, "employeeID") :
                                                                    setEmployeeOptionList(prevState => ({
                                                                        ...prevState,
                                                                        employeeIDList: []
                                                                    }));
                                                            }}
                                                            options={employeeOptionList.employeeIDList}
                                                            name='employeeDetailID'
                                                            value={claimValues?.employeeIdSelect}
                                                            className='mt-3'
                                                            onChange={(option) => {
                                                                setFieldValue('employeeDetailID', option ? Number(option?.value) : null)
                                                                setFieldValue('employeeDetailName', option ? Number(option?.value) : null)
                                                                setClaimValues(prve => ({
                                                                    ...prve,
                                                                    employeeIdSelect: option ? option : null,
                                                                    employeeNameSelect: option ? { value: option?.value, label: option?.employeeName } : null,
                                                                    employeeImage: option ? option?.image ? option?.image : null : null
                                                                }))
                                                            }}
                                                            styles={customSelectStyle}
                                                            isDisabled={editView}
                                                        />
                                                        <label className='staan-form-input-label  px-1 mx-2' onClick={() => {
                                                            console.log(employeeOptionList.employeeIDList);
                                                        }}>Employee ID<span className='text-danger'>*</span></label>
                                                        {touched.employeeDetailID && errors.employeeDetailID && <small>{errors.employeeDetailID}</small>}
                                                    </div>
                                                    <div className="col-6 staan-input-group">
                                                        <Select
                                                            onInputChange={(e) => {
                                                                e ? getOptions(e, "employeeName") :
                                                                    setEmployeeOptionList(prevState => ({
                                                                        ...prevState,
                                                                        employeeNameList: []
                                                                    }));
                                                            }}
                                                            options={employeeOptionList.employeeNameList}
                                                            name='employeeDetailName'
                                                            value={claimValues?.employeeNameSelect}
                                                            className='mt-3'
                                                            onChange={(option) => {
                                                                setFieldValue('employeeDetailName', option ? Number(option?.value) : null)
                                                                setFieldValue('employeeDetailID', option ? Number(option?.value) : null)
                                                                setClaimValues(prve => ({
                                                                    ...prve,
                                                                    employeeNameSelect: option ? option : null,
                                                                    employeeIdSelect: option ? { value: option?.value, label: option?.employeeId } : null,
                                                                    employeeImage: option ? option?.image ? option?.image : null : null

                                                                }))
                                                            }}
                                                            styles={customSelectStyle}
                                                            isDisabled={editView}
                                                        />
                                                        <label className='staan-form-input-label  px-1 mx-2' onClick={() => {
                                                            console.log("claimValues", claimValues);
                                                        }}>Employee Name <span className='text-danger'>*</span></label>
                                                        {touched.employeeDetailName && errors.employeeDetailName && <small>{errors.employeeDetailName}</small>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="border rounded-circle document" style={{ width: "8rem", height: "8rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    {claimValues?.employeeImage ? (
                                                        <div style={{ width: "100%", height: "100%" }}>
                                                            <img
                                                                src={claimValues?.employeeImage}
                                                                alt={`Profile picture of ${claimValues?.employeeNameSelect}`}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "cover",
                                                                    borderRadius: "50%",
                                                                    padding: "1px"
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className='text-center' style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                            <p className='pt-3' style={{ margin: 0 }}>Click Here To Select Image</p>
                                                            <p style={{ margin: 0, textAlign: "center" }}> Area : 160 X 160
                                                                <br />
                                                                Size : 2MB
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row  ms-3 mt-4 card shadow mx-2 pb-2'>
                                            <div className='row justify-content-between align-items-center'>
                                                <div className='col-2'>
                                                    <h6 className='commanModelTitleColor' onClick={() => {
                                                        console.log("claimsDetailsData", claimsDetailsData);
                                                    }}>Claims Details</h6>
                                                </div>
                                                <div className='col-3 text-end'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-outline-primary mt-4 px-4'
                                                        onClick={() => { setShowClaimsDetail(true) }}
                                                        disabled={editView}
                                                    >
                                                        <i className='bx bxs-plus-circle me-2'></i>New
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                className='ag-theme-alpine mt-2'
                                                style={{ height: '30rem', width: '100%' }}
                                            >
                                                <AgGridReact
                                                    rowData={claimsDetailsData}
                                                    columnDefs={COLUMNS}
                                                    defaultColDef={DEFAULT_COL_DEF}
                                                // components={COMPONENTS}

                                                />

                                            </div>

                                        </div>
                                        <div className="row m-3">
                                            <div className="text-end">
                                                <button type="button" className="btn btn-outline-danger btn-sm me-2 px-4" onClick={() => {
                                                    setFieldValue('status', "Draft"); submitForm()
                                                }}
                                                    disabled={editView || initialExpenseClaim?.status === "Submit"}>
                                                    Draft
                                                </button>
                                                <button type="button" className="btn btn-sm btn-outline-success shadow-sm px-4"
                                                    onClick={() => {
                                                        setFieldValue('status', "Submit"); submitForm()
                                                    }}
                                                    disabled={!editView ? false : initialExpenseClaim?.status === "Draft" ? false : true}>
                                                    Submit
                                                </button>
                                                <button type="button" className="btn btn-sm btn-outline-info shadow-sm px-3 ml-2" disabled={!initialExpenseClaim?.status === "Submit"}>
                                                    Verified
                                                </button>

                                            </div>
                                        </div>
                                        {IsShowClaimsDetail &&
                                            <ClaimsModal
                                                IsShowClaimsDetail={IsShowClaimsDetail}
                                                setShowClaimsDetail={setShowClaimsDetail}
                                                setclaimsDetailsData={setclaimsDetailsData}
                                                editData={editData}
                                                seteditData={seteditData}
                                            />
                                        }
                                        {claimValues?.deleteData?.Name &&
                                            <DeleteConformation
                                                handleDeletedId={(id) => { handleDeleteClaimDetails(id) }}
                                                deleteData={claimValues?.deleteData}
                                                fullquery={expenseClaimDetailsDeleteMutation(`id:${claimValues?.deleteData?.id}`)}
                                            />
                                        }
                                        {isDeleteWarningShow &&
                                            <WaringModel
                                                state={isDeleteWarningShow}
                                                setstate={setIsDeleteWarningShow}
                                                title={'Confirm'}
                                                text={"Confirm to delete"}
                                                Confirmtion={value => {
                                                    if (value) {
                                                        DeleteClaim(value)
                                                    }
                                                }}
                                            />}
                                    </div>
                                </Form>

                            </>
                        )}
                    </Formik>


                </Modal.Body>
            </Modal>
        </>
    );
}

export default ExpenseClaimModal;
