import React, { useEffect, useState } from 'react';
import Tree from './BomTreeComps/TreeNode';
import { useContext } from 'react';
import DataContext from '../../../context/ItemMasterContext';
import { Oval } from 'react-loader-spinner';
import html2pdf from 'html2pdf.js';


const BomTreeView = ({treeData, handleAddBOMShow}) => {
    const {clickedKey, isKeyUpdated} = useContext(DataContext)
    const [expandAll, setExpandAll] = useState(true);
    const [treeViewData, setTreeViewData] = useState([]);
    const [isExpandClicked, setExpendClicked] = useState(true);
    const [isLoad, setLoad] = useState(false);
    

    const addIsOpenProperty = (data) => {
        return data.map((item) => {
            const newItem = { ...item, is_open: expandAll };
            if (newItem.children) {
                newItem.children = addIsOpenProperty(newItem.children);
            }
            return newItem;
        });
    };
    
    const handleToggleView = (data, key) => {
        return data.map((item) => {
            const newItem = { ...item };
            if (newItem.key === key) {
                newItem.is_open = !newItem.is_open;
            }
            if (newItem.children) {
                newItem.children = handleToggleView(newItem.children, key);
            }
            return newItem;
        });
    };

    const handlePdfReportDownload = () => {
        let fileName = 'bomtree'
        const table = document.getElementById('bom-tree-structure');
        const opt = {
          margin: 0,
          filename: `${fileName}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'A4', orientation: 'landscape' }
        };
        html2pdf().set(opt).from(table).save();
    };

    const handleExcelDownload = () => {
        handlePdfReportDownload()
    }

    useEffect(()=>{
        // console.log(treeData)
        if(treeData){
            if(clickedKey){
                let updated_data = handleToggleView(treeViewData, clickedKey);
                // console.log(updated_data, "updated_data")
                setTreeViewData(updated_data);
            }
            if(isExpandClicked){
                // console.log(treeData, "treeData")
                const updatedTreeData = addIsOpenProperty(treeData);
                // console.log(updatedTreeData, "updatedTreeData")
                setTreeViewData(updatedTreeData);
                setExpendClicked(false)
            }
        }
        
    }, [isLoad, isKeyUpdated, treeData])

    return ( 
        <>
        <div>
            <div className='row'>
                <div className='col-3'></div>
                <div className='col-5'></div>
                {treeData && <div className='col-4 text-end'>    
                    <button 
                        type='button' 
                        onClick={()=>{handleExcelDownload()}}
                        className='btn btn-sm btn-outline-secondary me-2 px-4'><i className="fa-regular fa-file-pdf"></i> Export</button>
                    <button 
                        type='button' 
                        onClick={()=>{setLoad(!isLoad); setExpandAll(true); setExpendClicked(true)}}
                        className='btn btn-sm btn-outline-primary me-2 px-3'>Expand All</button>
                    <button 
                        type='button' 
                        onClick={()=>{setLoad(!isLoad); setExpendClicked(true); setExpandAll(false)}}
                        className='btn btn-sm btn-outline-danger px-1'>Collapse All</button>
                </div> }
            </div>
            <div style={{fontSize: '.9rem'}} className='my-5' id="bom-tree-structure">
                {treeData && <Tree 
                    treeData={treeViewData} 
                    
                />}
                {!treeData && <div style={{width: '100%'}}>
                    <div  className='mx-auto w-25 d-flex justify-content-center align-items-center'>
                        <Oval
                            visible={true}
                            height='80'
                            width='80'
                            color='#4fa94d'
                            ariaLabel='oval-loading'
                            wrapperStyle={{}}
                            wrapperClass=''
                        />
                    </div>
                </div>}
            </div>  
        </div>
        </>
    );
}
 
export default BomTreeView;