import AddFinishedGoods from "./AddFinishedGoods";
import CustomTableContainer from "../CustomTableContainer";
import React, { useState, useEffect} from 'react' ;
import { FetchFinishedGoods } from "../../../GraphQLQueries/BomMutation";
import axiosInstance from "../../../api/axoiss";
import {ToastContainer, toast , Bounce} from 'react-toastify';

const FinishedGoods = ({handleFGData, isEditView, data, bomType, isNew}) => {
    
    const [finishedGoodsData, setFinishedGoodsData] = useState([])
    const [isEditViewMode, setEditListViewMode] = useState(false)
    const [rowIndex, setRowIndex] = useState(null)
    const [currentEditingData, setCurrentEditingData] = useState(null);

    // finished_goods table columns
    const finished_goods_column_details = [
        {
            header : "Part Code", 
            fieldname : 'part_code', 
            object : "label",
            width: 'col-1',
         },
        {
            header : "Part Name", 
            fieldname : 'part_name',
            object : "label",
            width: 'col-3',
         },
        {
            header : "Category", 
            fieldname :'category',
            width: 'col-1'
        },
        {
            header : "Qty", 
            fieldname :'qty',
            width: 'col-1'
        },
        {
            header : "Unit", 
            fieldname :'uom',
            object : "label",
            width: 'col-1'
        },
        {
            header : "Cost Allocation", 
            fieldname :'cost_allocation',
            width: 'col-1',
        },
        {
            header : "Remarks", 
            fieldname :'remarks',
            width: 'col-1',
        }
    ]

    const [headers, setHeaders] = useState(finished_goods_column_details)
    const [isShowAddFinishedGoods, setShowAddFinishedGoods] = useState(false)
    const handleCloseAddFinishedGoods = () => {
        setCurrentEditingData(null)
        setShowAddFinishedGoods(false)
    }

    const handleShowAddFinishedGoods = () => {
        setShowAddFinishedGoods(true)
    }

    const handleAddedData = (data) => {
        setFinishedGoodsData([data])
        handleFGData([data.id], data)
    }

    useEffect(()=>{
        if(data && data?.[0]){
            let fg_fetch_query = FetchFinishedGoods(data[0])
            axiosInstance.post("/itemmaster/graphql", {query: fg_fetch_query})
            .then(res => {
                let response = res?.data?.data?.finishedGoods?.items?.[0]
                let updated_data = {...response}
                updated_data['cost_allocation'] = updated_data['costAllocation']
                updated_data['part_code'] = {label: updated_data?.['partNo']?.['itemPartCode'], value: updated_data?.['partNo']?.['id']}
                updated_data['part_name'] = {label: updated_data?.['partNo']?.['itemName'], value: updated_data?.['partNo']?.['id']}
                updated_data['uom'] = {label: updated_data?.['unit']?.['name'], value: updated_data?.['unit']?.['id']}
                setFinishedGoodsData([updated_data])
            }).catch(e => {
                console.log(e)
            })

        }
        if(isEditView === true || isEditView === undefined){
            setEditListViewMode(true)
        }else{
            setEditListViewMode(false)
        }
        // console.log(isNew, "isNew")
        if(isNew){
            setFinishedGoodsData([])
        }
        if(bomType === 'SUBCONTRACT'){
            finished_goods_column_details.push({
                header : "Labour Charges (per unit)", 
                fieldname :'labourCharges',
                width: 'col-1',
            })
            setHeaders(finished_goods_column_details)
        }else{
            setHeaders(finished_goods_column_details)
        }
        
    }, [isEditView, data, bomType])

    const handleEditTableRow = (data) => {
        setCurrentEditingData(finishedGoodsData[data])
        setRowIndex(data)
        setShowAddFinishedGoods(true)
    }

    const handleDeleteTableRow = (data) => {
        toast.error("Finished Goods Can't be Deleted!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    }

    return(
        <>
            <ToastContainer/>
            <div className='row'>
                <div className='col-3'>
                    <h6>Finished Goods</h6>
                </div>
                <div className='col-7'></div>
                <div className='col-2 text-end'>
                    {isEditViewMode && finishedGoodsData?.length < 1 && <button 
                        type='button'
                        onClick = {handleShowAddFinishedGoods}
                        className='fw-bold btn btn-sm btn-outline-primary'><i className='bx bxs-plus-circle' ></i> New </button>}
                </div>
            </div>

            { finishedGoodsData && finishedGoodsData.length > 0 && <div className="py-3 fw-bold" style={{fontSize: '.7rem'}}>
                <CustomTableContainer 
                    isEditViewMode = {isEditViewMode}
                    headers = {headers}
                    data = {finishedGoodsData}
                    handleEditTableRow = {(d)=>{handleEditTableRow(d)}}
                    handleDeleteTableRow = {(d)=>{handleDeleteTableRow(d)}}
                />
            </div>}

            {isShowAddFinishedGoods && <AddFinishedGoods 
                isEditViewMode = {isEditViewMode}
                handleCloseAddFinishedGoods = {handleCloseAddFinishedGoods}
                isShowAddFinishedGoods ={isShowAddFinishedGoods}
                handleAddedData = {(d)=>{handleAddedData(d)}}
                data = {currentEditingData}
                bomType = {bomType}
            />}
        </>
    )
}

export default FinishedGoods;