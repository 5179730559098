import { 
    FETCH_USER_DATA_FAILURE,
    FETCH_USER_DATA_SUCCESS,
    FETCH_USER_DATA_REQUEST
} from "../Action/userAction";

const initialUserState = {
    data: [],
    isLoading: false,
    error: null,
};
    
export const userReducer = (state = initialUserState, action) => {
      switch (action.type) {
        case FETCH_USER_DATA_REQUEST:
          return {
            ...state,
            isLoading: true,
            error: null,
        };
        case FETCH_USER_DATA_SUCCESS:
          return {
            ...state,
            isLoading: false,
            data: action.payload,
        };
        case FETCH_USER_DATA_FAILURE:
          return {
            ...state,
            isLoading: false,
            error: action.payload,
        };
        default:
          return state;
      }
};
