import React, { useEffect, useContext, useState} from "react";
import { Modal } from "react-bootstrap";
import ProductionContext from "../../../context/ProductionContext";
import axiosInstance from "../../../api/axoiss";
import DataContext from '../../../context/ItemMasterContext';
import { ToastContainer, toast } from "react-toastify";


const MRConfirmation  =  ({show, close, itemDetail}) => {
    const {manufacturingRawMaterialData, subcontractRawMaterialData} = useContext(ProductionContext);
    const [rowData, setRowData] = useState([]);
    const {userId} =   useContext(DataContext);


    const ProceedMaterialRequest = () => {
        console.log(rowData)
        // let raw_material_list = [...manufacturingRawMaterialData, ...subcontractRawMaterialData]
        try {
            let post_body_content = {
                "created_by": userId,
                "pomaster": itemDetail?.poMaster?.id,
                "department": itemDetail?.poMaster?.department?.id,
                "rawmaterials": rowData,
            }
            axiosInstance.post('/itemmaster/create-material-req-batch', {data: post_body_content})
            .then(response => {
                toast.success('Material Request Created Successfully!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                close()
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.error('Error Creating MR:', error);
        }   
    }

    useEffect(()=>{
        // console.log(itemDetail, "itemDetail from confirmation")
        let combined_raw_materials = [...manufacturingRawMaterialData, ...subcontractRawMaterialData];
        let constructed_detail_list = {}
        combined_raw_materials?.forEach(raw_material => {
            if (!constructed_detail_list[raw_material.store.storeName]) {
                constructed_detail_list[raw_material.store.storeName] = [];
            }
            constructed_detail_list[raw_material.store.storeName].push(raw_material);
        })
        let row_data = []
        for(let [key, value] of Object.entries(constructed_detail_list)){
            let row_item  = {
                "store": key,   
                "storeID": value.length > 0 ? value[0].store.id : null,
                "total_item": value?.length || 0,
                "total_qty": value?.reduce((acc, current)=> acc + parseFloat(current?.actualQty || 0), 0),
                "items": value
            }
            row_data.push(row_item)
        }
        setRowData(row_data)
    }, [])

    return (
        <Modal show={show} onHide={close} size="xl" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Material Request Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="my-3 mx-auto" style={{width: '90%'}}>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Store Name</th>
                                <th scope="col">Total Items</th>
                                <th scope="col">Total Qty</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowData && rowData?.map((item, index)=> {
                                return <tr key={index}>
                                        <th scope="row">{index+1}</th>
                                        <td>{item?.store}</td>
                                        <td>{item?.total_item}</td>
                                        <td>{item?.total_qty}</td>
                                    </tr>
                            })}
                        </tbody>
                    </table>
                    <div className="mt-5 d-flex justify-content-center">
                        <div style={{width: '30%'}} className="d-flex justify-content-between">
                            <button className="btn btn-sm btn-outline-danger px-2" onClick={close}>Cancel</button>
                            <button className="btn btn-sm btn-outline-success px-2" onClick={ProceedMaterialRequest}>Proceed</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <ToastContainer />
        </Modal>
    )
}

export default MRConfirmation;