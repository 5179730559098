import axiosInstance from "../../api/axoiss";

// Action Types
// Account Master item action types
export const FETCH_ACCOUNT_DATA_REQUEST = 'FETCH_ACCOUNT_DATA_REQUEST';
export const FETCH_ACCOUNT_DATA_SUCCESS = 'FETCH_ACCOUNT_DATA_SUCCESS';
export const FETCH_ACCOUNT_DATA_FAILURE = 'FETCH_ACCOUNT_DATA_FAILURE';


// Fetch Items Action Creators
const fetchDataRequest = (action_type) => {
    return {
      type: action_type,
    };
};
  
const fetchDataSuccess = (data, action_type) => {
    return {
        type: action_type,
        payload: data,
    };
};

const fetchDataFailure = (error, action_type) => {
    return {
        type: action_type,
        payload: error,
    };
};


  // FG fetch all Thunk Action Creators
export const fetchAccountData = (fetch_query) => {
    return (dispatch) => {
      dispatch(fetchDataRequest(FETCH_ACCOUNT_DATA_REQUEST));
      axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
      .then(response => {
          const keys = Object.keys(response.data.data);
          const firstKey = keys.length > 0 ? keys[0] : null;
          const data = response.data.data[firstKey]
          dispatch(fetchDataSuccess(data, FETCH_ACCOUNT_DATA_SUCCESS));
      })
      .catch(error => {
          dispatch(fetchDataFailure(error.message, FETCH_ACCOUNT_DATA_FAILURE));
      });
    };
};