import React, { createContext, useState, } from 'react';

const ProductionContext = createContext({});

export const ProductionDataProvider = ({ children }) => {
    const [manufacturingRawMaterialData, setManufacturingRawMaterialData] = useState([]);
    const [subcontractRawMaterialData, setSubContractRawMaterialData] = useState([]);

    // useEffect(()=>{
    //     console.log(manufacturingRawMaterialData, "moRawMaterialData")
    // }, [manufacturingRawMaterialData, subcontractRawMaterialData])

    return (
        <ProductionContext.Provider
            value={{
                subcontractRawMaterialData, setSubContractRawMaterialData, 
                manufacturingRawMaterialData, setManufacturingRawMaterialData, 
            }}
        >
            {children}
        </ProductionContext.Provider>
    )
}
export default ProductionContext;