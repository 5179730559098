import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select'
import axiosInstance, { commanFetch } from '../../../api/axoiss'
import Can from "../../../components/authorizationComponents/Can"
import showErrorToast from '../../../components/notifications/react-toastify/toast'
import DataContext from '../../../context/ItemMasterContext'
import { DepartmentQuery } from '../../../GraphQLQueries/ItemmasterQuery'
import { allPermissionModelforOptions, rolelist, userManagementCreateMutation } from '../../../GraphQLQueries/userQueryandMutations/userQuerys'
import { customSelectStyle, removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils'
import { UserManagmentSchema } from '../../../validations/UserValidations'

const UserManagmentForm = ({ isShow, setIsShow, setLoad, editData, setEditData }) => {
    const {CommanFetchForAllUser, allUsersList, userId} =  useContext(DataContext)
    const [initialValue, setInitialValue] = useState({
        id: '',
        user: '', 
        firstName: '',
        lastName: '',
        department: '',
        role: '',
        profile: '',
        modifiedBy: '',
        createdBy: ''
    })
    const [edit, setEdit]= useState(true)
    const [departmentList , setDepartmentList] = useState([])
    const [profileList ,setProfileList] = useState([])
    const [roleList, setRoleList] = useState([])
    const [userManagmentFormValue, setUserManagmentFormValue] = useState()
    useEffect(()=>{ 
       if (isShow && editData !==null){  
        initialValue['id'] = Number(editData?.id) ?? null
        initialValue['user'] = Number(editData?.user?.id) ?? null 
        initialValue['firstName'] = editData?.user?.firstName
        initialValue['lastName'] = editData?.user?.lastName
        initialValue['department'] = Number(editData?.department?.id) ?? null
        initialValue['role'] = Number(editData?.role?.id) ?? null 
        initialValue['profile'] = Number(editData?.profile?.id) ?? null
        initialValue['createdBy'] = Number(editData?.createdBy?.id) ?? null
        setUserManagmentFormValue({
            user: {id :editData?.user?.id , label:editData?.user?.username},
            email: editData?.user?.email ?? "",
            firstName: editData?.user?.firstName ?? "",
            lastName: editData?.user?.lastName ?? "",
            department: {id :editData?.department?.id , label:editData?.department?.name},
            role : {id :editData?.role?.id , label:editData?.role?.roleName} ,
            profile : {id :editData?.profile?.id , label:editData?.profile?.profileName}

            })
            // 
            setEdit(true)
       } else{
        setEdit(false)
       }
       
       
    }, [editData, isShow ])
   
    async function fetchOptions(query, name) {   
        if(query){
            const response = await commanFetch(query)
            const reponseData = response?.data?.items
            name === "departmant" ?  
            setDepartmentList(reponseData?.map((item)=>({"value": item?.id, "label": item?.name}))) : 
            setDepartmentList([])
            name === "rolelist" ?  setRoleList(reponseData?.map((item)=>({"value": item?.id, "label": item?.roleName}))) :
            setRoleList([])
            name === "profile" ?  setProfileList(reponseData?.map((item)=>({"value": item?.id, "label": item?.profileName}))) :
            setProfileList([])
        } else{
            setDepartmentList([])
            setRoleList([])
        } 
    }
     // retun null ;  Submit functions
     const handleSubmit =async  (values, { setSubmitting, resetForm }) =>{
        
        if (!initialValue['id']){
            values['createdBy'] = Number(userId) 
        } else{
            values['modifiedBy'] = Number(userId)
        }
        
        let ClearValue = removeEmptyValueInObject(values, ["id", "modifiedBy","department", "role"])
          
        try {
        const response = await axiosInstance.post(`/itemmaster/graphql`, {
            query: userManagementCreateMutation(ClearValue)
        });
        const responseData = response?.data?.data?.userManagementCreateMutation;
        if (responseData.success) {
            setIsShow(false)
            showErrorToast(true, "success", "", "Saved Successfully" )
            close()
            setSubmitting(false)
            setLoad()
          
            
            
            
        } else {
            let errorData = ShowErrorNoties(responseData.errors);
            showErrorToast(true, "error", errorData,  )
        }
    } catch (error) {
            let errorData = ShowErrorNoties(error); 
            showErrorToast(true, "error", errorData,  )
    }
        
    }
    function close(params) { 
        setIsShow(false)
        setUserManagmentFormValue( )
        setEditData(null)
        setInitialValue({
            id: '',
            user: '', 
            firstName: '',
            lastName: '',
            department: '',
            role: '', 
            modifiedBy: '',
            createdBy: ''
        })
    }
  return (
    <>
      <Modal show={isShow} backdrop='static' keyboard={false} size='xl'>
        <div className='row'>
          <div className='col-5 m-3' style={{ color: '#5CADA9' }}>
            <Modal.Title>
              <i
                className='fa-solid fa-arrow-left fa-sm me-2'
                onClick={close}
              ></i>
              Users
            </Modal.Title>
          </div>
          <div className='col-6 mt-4 text-end'>
            <Can I={"Edit"} a={"User_Management"}> 
                <i
                className='fa-solid fa-pen fs-5 text-primary pe-3'
                hidden={!(editData && edit)}
                onClick={()=>{setEdit(!edit)}}
                ></i>
            </Can>
            
            {/* <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " hidden={!(editData && edit)} onClick={()=>{handledeleteConfomation(editData)}} ></i>  */}
            {/* <TbHistory type="button" className='fs-4 ms-3' data-bs-toggle="offcanvas" hidden={!(uomEditId && edit)} data-bs-target="#History" onClick={()=>{setHistoryIds(initialUom.HistoryDetails)}} /> */}
          </div>
          <hr />
        </div>
        <Modal.Body>
          <Formik
            initialValues={initialValue}
            validationSchema={UserManagmentSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, isSubmitting, setFieldValue, values }) => {
              return (
                <Form>
                    <div className='row'>
                        <div className='col-6 staan-input-group'>
                        <Select
                            onInputChange={(e)=>{CommanFetchForAllUser(e)}}
                            options={allUsersList}
                            isClearable
                            isDisabled={editData && edit}
                            className='my-3'
                            id='user'
                            value={userManagmentFormValue?.user}
                            onChange={(options)=>{
                                setFieldValue("user", options? Number(options?.value): "" )
                                
                                setFieldValue("firstName", options?.firstName ?  (options?.firstName): "" )
                                setFieldValue("lastName",  options?.lastName ?  (options?.lastName): "" )
                                setUserManagmentFormValue({
                                    user: options ?? null,
                                    email: options?.email ?? "",
                                    firstName: options?.firstName ?? "",
                                    lastName: options?.lastName ?? ""
                                });
                            }}
                            styles={customSelectStyle}
                        />
                        <label
                            htmlFor='user'
                            className='staan-form-input-label   px-1 mx-2'
                            onClick={()=>{console.log(values, userManagmentFormValue);
                            }}
                        >
                            User<span className='text-danger'>*</span>
                        </label>
                        {touched.user && errors.user && (
                            <small className='text-danger'>{errors.user}</small>
                        )}
                        </div>
                        <div className="col-6 staan-input-group">
                            <Field type='email' name='email'      value={userManagmentFormValue?.email} placeholder='' disabled  className='w-100 staan-form-input'/>
                            <label
                            htmlFor='email'
                            className='staan-form-input-label pt-1   px-1 mx-2'
                            >
                            Email 
                            </label>
                            {touched.email && errors.email && (
                                <small className='text-danger'>{errors.email}</small>
                            )}

                        </div>
                        <div className="col-6 staan-input-group">
                            <Field type='text' name='firstName' disabled={editData && edit}  placeholder=''
                            onChange={(e)=>{
                                setFieldValue("firstName", e?.target?.value ) 
                                setUserManagmentFormValue(prve=>({
                                    ...prve,
                                    firstName: e?.target?.value ?? ""
                                }))
                               
                            }}
                            value={userManagmentFormValue?.firstName}    className='w-100 staan-form-input'/>
                            <label
                            htmlFor='firstName'
                            className='staan-form-input-label pt-1  px-1 mx-2'
                            >
                                First Name 
                            </label>
                            {touched.firstName && errors.firstName && (
                            <small className='text-danger'>{errors.firstName}</small>
                        )}

                        </div>
                        <div className="col-6 staan-input-group">
                            <Field type='text' name='lastName' disabled={editData && edit} placeholder=''
                            onChange={(e)=>{
                                setFieldValue("lastName",  e?.target?.value ) 
                                setUserManagmentFormValue(prve=>({
                                    ...prve,
                                    lastName: e?.target?.value ?? ""
                                }))
                            }}
                            value={userManagmentFormValue?.lastName}  className='w-100 staan-form-input'/>
                            <label
                            htmlFor='lastName'
                            className='staan-form-input-label pt-1  px-1 mx-2'
                            >
                                Last Name 
                            </label> 
                            {touched.lastName && errors.lastName && (
                                <small className='text-danger'>{errors.lastName}</small>
                            )}
                        </div>
                        <div className='col-6 staan-input-group'>
                        <Select
                            onInputChange={(e)=>{e.trim() ? fetchOptions(DepartmentQuery(`name: "${e.trim()}"`), "departmant") : 
                            setDepartmentList([])}}
                            isClearable
                            options={departmentList}
                            isDisabled={editData && edit}
                            className='my-3'
                            id='department'
                            value={userManagmentFormValue?.department}
                            onChange={(options)=>{
                                setFieldValue("department", options? Number(options?.value): "" )
                                setUserManagmentFormValue(prve=>({
                                    ...prve,
                                    department:  options ?? null, 
                                }))
                            }}
                            styles={customSelectStyle}
                        />
                        <label
                            htmlFor='user'
                            className='staan-form-input-label   px-1 mx-2'
                        >
                            Department<span className='text-danger'>*</span>
                        </label>
                        {touched.department && errors.department && (
                            <small className='text-danger'>{errors.department}</small>
                        )}
                        </div>
                        <div className='col-6 staan-input-group'>
                        <Select
                            onInputChange={(e)=>{e.trim() ? fetchOptions(rolelist(`roleName: "${e.trim()}"`), "rolelist") :
                            setRoleList([])}}
                            options={roleList}
                            isClearable
                            isDisabled={editData && edit}
                            className='my-3'
                            id='role'
                            value={userManagmentFormValue?.role}
                            onChange={(options)=>{
                                setFieldValue("role", options? Number(options?.value): "" )
                                setUserManagmentFormValue(prve=>({
                                    ...prve,
                                    role:  options ?? null, 
                                }))
                            }}
                            styles={customSelectStyle}
                        />
                        <label
                            htmlFor='user'
                            className='staan-form-input-label pt-1  px-1 mx-2'
                        >
                            Role 
                        </label>
                        {touched.role && errors.role && (
                            <small className='text-danger'>{errors.role}</small>
                        )}
                        </div>
                        <div className='col-6 staan-input-group'>
                        <Select
                           onInputChange={(e)=>{e.trim() ? fetchOptions(allPermissionModelforOptions(`profileName: "${e.trim()}"`), "profile") : 
                           setProfileList([])}}
                            options={profileList}
                            isClearable
                            isDisabled={editData && edit}
                            className='my-3'
                            id='profile'
                            value={userManagmentFormValue?.profile}
                            onChange={(options)=>{
                                setFieldValue("profile", options? Number(options?.value): "" )
                                setUserManagmentFormValue(prve=>({
                                    ...prve,
                                    profile:  options ?? null, 
                                }))
                            }}
                            styles={customSelectStyle}
                        />
                        <label
                            htmlFor='profile'
                            className='staan-form-input-label pt-1  px-1 mx-2'
                        >
                            Profile 
                        </label>
                        {touched.profile && errors.profile && (
                            <small className='text-danger'>{errors.profile}</small>
                        )}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='text-end'> 
                       
                            <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={close}    >Cancel</button>
                       
                            
                            <Can allow_list={["Save_User_Management","Edit_User_Management"]}> 
                                <button type="submit" className="btn btn-sm btn-outline-success shadow-sm" disabled={editData && edit ? true: isSubmitting} >Save</button>
                            </Can>
                            
                        </div>
                    </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UserManagmentForm
