import React, {useMemo, useState, useEffect, useContext} from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import { SortHierarchySerialNumbers } from "../../../utils/Utils";
import {  FetchRecursiveProductionRawMaterials } from "../../../GraphQLQueries/ProductionOrderQuery";
import axiosInstance from "../../../api/axoiss";
import { SortRawMaterials } from "../../../utils/production_order_util";
import ProductionContext from "../../../context/ProductionContext";


const PoRawMaterils = ({data, masterData, type, po_number, detail}) => {
    const {setSubContractRawMaterialData, setManufacturingRawMaterialData} = useContext(ProductionContext);
    const containerStyle = useMemo(() => ({ width: "100%", height: "25rem" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const rowStyle = { background: 'white' };
    const [rowData, setRowData] = useState([]);

    const getRowStyle = params => {
        if (params.data.isCombo === true) {
            return { background: '#E6E4E4' };
        }
    };

    let column_def = [
        { 
            headerName: 'S No', 
            valueGetter: "node.rowIndex + 1", 
            flex: 1,
        },
        { 
            headerName: 'Order No', 
            flex: 2, 
            valueGetter: (params) => {
                return params.data?.productionorderitemdetailSet?.[0]?.productionorderlinkingtableSet?.[0]?.poMaster?.orderNo || '';
            },
        },
        { headerName: 'Part No', field: "partCode.itemPartCode", flex: 2, 
            tooltipValueGetter: (p) => p.value 
        },
        { headerName: 'Part Name', field: "partCode.itemName", flex: 5, 
            tooltipValueGetter: (p) => p.value 
        },
        { headerName: 'Category', field: "category.name" , flex: 2},
        { headerName: 'UOM', field: "unit.name" , flex: 2},
        { headerName: 'Fixed', field: "fixed" , flex: 2},
        { headerName: 'Required', field: "actualQty", 
            flex: 2
        },
        // { headerName: 'Stock', field: "totalStock" , flex: 2},
        { headerName: 'Issued', field: "issuedQty", flex: 2 },
        { headerName: 'Used', field: "usedQty", flex: 2  },
        { headerName: 'Store', field: "store.storeName" , flex: 3, 
            tooltipValueGetter: (p) => p.value 
        },
    ]

    const [columnDefs, setColumnDefs] = useState(column_def);
    const defaultColDef = {
        // editable: true, 
        resizable: true
    }

    useEffect(()=>{
        if(data){
            // if(type === 'SUBCONTRACT'){
            //     setColumnDefs([...column_def, 
            //         {
            //             headerName: 'Supplier',
            //             flex: 2,
            //             field: 'selectedSupplier',
            //             editable: true,
            //             cellEditor: "agSelectCellEditor",
            //             cellEditorParams: params => {
            //                 return {
            //                     values: params.data.supplierOptions
            //                 };
            //             },
            //             cellStyle: params => {
            //                 if (params.value === "--Select Supplier--") {
            //                     return { borderColor: 'red' };
            //                 } else {
            //                     return { borderColor: '' };
            //                 }
            //             }
            //         }
            //     ])
            // }
            axiosInstance.post("/itemmaster/graphql", {query: FetchRecursiveProductionRawMaterials(detail, type)})
            .then(res => {
                let data_list = res?.data?.data?.recursiveRawMaterials?.items || []
                let updated_data_list = data_list?.map(rm_item => {
                    let new_data = {...rm_item}
                    // let total_stock = 0;
                    // let current_store_id = rm_item?.store?.id 
                    // if(current_store_id){
                    //     rm_item?.partCode?.itemstockSet?.map(stock_item => {
                    //         if(stock_item.store.id === current_store_id){
                    //             total_stock +=  parseFloat(stock_item.currentStock)
                    //             return null
                    //         }
                    //         return null
                    //     })
                    // }
                    // if(type === 'SUBCONTRACT'){
                    //     let supplier_options = rm_item?.partCode?.supplierData || []
                    //     new_data['supplierOptions'] = supplier_options?.map(supplier => supplier?.companyName)
                    //     new_data['selectedSupplier'] = supplier_options?.length === 1 ? supplier_options[0]['companyName'] : '--Select Supplier--'
                    // }
                    // new_data['totalStock'] = total_stock
                    new_data['orderNo'] = rm_item?.productionorderitemdetailSet?.[0]?.productionorderlinkingtableSet?.[0]?.poMaster?.orderNo
                    return new_data
                })
                let sorted_data = SortHierarchySerialNumbers(updated_data_list)
                sorted_data = SortRawMaterials(sorted_data)
                setRowData(sorted_data)
                if(type === 'SUBCONTRACT'){
                    let type_added = sorted_data?.map(item => {return {...item, "bom_type": "SUBCONTRACT"}})
                    setSubContractRawMaterialData(type_added)
                }else{
                    let type_added = sorted_data?.map(item => {return {...item, "bom_type": "MANUFACTURE"}})
                    setManufacturingRawMaterialData(type_added)
                }
            })
            .catch(err =>{ 
                console.log(err, "error")
            })
        }
    }, [data, detail])

    return(<>
        <div>
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact
                        rowData = {rowData}
                        rowStyle = {rowStyle}
                        columnDefs = {columnDefs}
                        defaultColDef = {defaultColDef}
                        getRowStyle = {getRowStyle}
                    />
                </div>
            </div>
        </div>
    </>)
}

export default PoRawMaterils;