
import { createItemDisplayGroup } from "../../GraphQLQueries/ItemMastermutation";
import axiosInstance from "../../api/axoiss";

// Item Master Action Types
export const FETCH_ITEM_MASTER_DATA_REQUEST = 'FETCH_ITEM_MASTER_DATA_REQUEST';
export const FETCH_ITEM_MASTER_DATA_SUCCESS = 'FETCH_ITEM_MASTER_DATA_SUCCESS';
export const FETCH_ITEM_MASTER_DATA_FAILURE = 'FETCH_ITEM_MASTER_DATA_FAILURE';
export const CLEAR_ITEM_MASTER_DATA = 'CLEAR_ITEM_MASTER_DATA';

// Item Group Action Types
export const FETCH_OPTIONS_DATA_REQUEST = 'FETCH_OPTIONS_DATA_REQUEST';
export const FETCH_OPTIONS_DATA_SUCCESS = 'FETCH_OPTIONS_DATA_SUCCESS';
export const FETCH_OPTIONS_DATA_FAILURE = 'FETCH_OPTIONS_DATA_FAILURE';

// Item Display Action Types
export const FETCH_ITEM_DISPLAY_DATA_REQUEST = 'FETCH_ITEM_DISPLAY_DATA_REQUEST';
export const FETCH_ITEM_DISPLAY_DATA_SUCCESS = 'FETCH_ITEM_DISPLAY_DATA_SUCCESS';
export const FETCH_ITEM_DISPLAY_DATA_FAILURE = 'FETCH_ITEM_DISPLAY_DATA_FAILURE';

// Item Display Group create action types
export const CREATE_DISPLAY_GROUP_DATA_REQUEST = 'CREATE_DISPLAY_GROUP_DATA_REQUEST';
export const CREATE_DISPLAY_GROUP_DATA_SUCCESS = 'CREATE_DISPLAY_GROUP_DATA_SUCCESS';
export const CREATE_DISPLAY_GROUP_DATA_FAILURE = 'CREATE_DISPLAY_GROUP_DATA_FAILURE';

// GST Type
export const FETCH_GST_TYPE_DATA_REQUEST = 'FETCH_GST_TYPE_DATA_REQUEST';
export const FETCH_GST_TYP_DATA_SUCCESS = 'FETCH_GST_TYP_DATA_SUCCESS';
export const FETCH_GST_TYP_DATA_FAILURE = 'FETCH_GST_TYP_DATA_FAILURE';


//Item Master Action Creators
const fetchItemMasterDataRequest = () => {
  return {
    type: FETCH_ITEM_MASTER_DATA_REQUEST,
  };
};
const fetchItemMasterDataSuccess = (data) => {
  return {
    type: FETCH_ITEM_MASTER_DATA_SUCCESS,
    payload: data,
  };
};
const fetchItemMasterDataFailure = (error) => {
  return {
    type: FETCH_ITEM_MASTER_DATA_FAILURE,
    payload: error,
  };
};


//Item master options Action Creators
const fetchOptionsDataRequest = () => {
  return {
    type: FETCH_OPTIONS_DATA_REQUEST,
  };
};
const fetchOptionsDataSuccess = (data) => { 
  return {
    type: FETCH_OPTIONS_DATA_SUCCESS,
    payload: data,
  };
};
const fetchOptionsDataFailure = (error) => {
  return {
    type: FETCH_OPTIONS_DATA_FAILURE,
    payload: error,
  };
};


//Item Display Action Creators
const fetchItemDisplayGroupDataRequest = () => {
  return {
    type: FETCH_ITEM_DISPLAY_DATA_REQUEST,
  };
};
const fetchItemDisplayGroupDataSuccess = (data) => {
  return {
    type: FETCH_ITEM_DISPLAY_DATA_SUCCESS,
    payload: data,
  };
};
const fetchItemDisplayGroupDataFailure = (error) => {
  return {
    type: FETCH_ITEM_DISPLAY_DATA_FAILURE,
    payload: error,
  };
};


// Create Item Action Creators
const createDataRequest = (action_type) => {
  return {
    type: action_type,
  };
};

const createDataSuccess = (data, action_type) => {
  return {
    type: action_type,
    payload: data,
  };
};

const createDataFailure = (error, action_type) => {
  return {
    type: action_type,
    payload: error,
  };
};

// fetch
const fetchDataRequest = (action_type) => {
  return {
    type: action_type,
  };
};

const fetchDataSuccess = (data, action_type) => {
  return {
    type: action_type,
    payload: data,
  };
};

const fetchDataFailure = (error, action_type) => {
  return {
    type: action_type,
    payload: error,
  };
};

export const clearItemMasterData = () => {
  return {
    type: CLEAR_ITEM_MASTER_DATA
  }
};

// Item Master Thunk Action Creator
export const fetchItemMasterData = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchItemMasterDataRequest());
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
      .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        dispatch(fetchItemMasterDataSuccess(data));
      })
      .catch(error => {
        dispatch(fetchItemMasterDataFailure(error.message));
      });
  };
};


// Item master options Thunk Action Creator
export const fetchOptionsData = (fetch_query, optionName ) => {

  return (dispatch) => {
    dispatch(fetchOptionsDataRequest());
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
      .then(response => { 
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response?.data?.data[firstKey] 
        let dataObject = {name : optionName , value : data} 
 
        
        dispatch(fetchOptionsDataSuccess(dataObject));
      })
      .catch(error => {
        dispatch(fetchOptionsDataFailure(error.message));
      });
  };
};


// Item display Group Thunk Action Creator
export const fetchItemDisplayGroupData = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchItemDisplayGroupDataRequest());
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
      .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey] 
        dispatch(fetchItemDisplayGroupDataSuccess(data));
      })
      .catch(error => {
        dispatch(fetchItemDisplayGroupDataFailure(error.message));
      });
  };
};


// Item Display Group Create Thunk Action Creator
export const createDisplayGroup = (part_code, display) => {
  return (dispatch) => {
    dispatch(createDataRequest(CREATE_DISPLAY_GROUP_DATA_REQUEST));
    let mutation_query = createItemDisplayGroup(part_code, display)
    axiosInstance.post("/itemmaster/graphql", {query: mutation_query})
    .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        if(data.success){
            dispatch(createDataSuccess(data, CREATE_DISPLAY_GROUP_DATA_SUCCESS));
        }else{
            dispatch(createDataFailure(data, CREATE_DISPLAY_GROUP_DATA_FAILURE));
        } 
    })
    .catch(error => {
        dispatch(createDataFailure(error.message, CREATE_DISPLAY_GROUP_DATA_FAILURE));
    });
  }
}


// gst type
export const fetchGstType = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchDataRequest(FETCH_GST_TYPE_DATA_REQUEST));
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
    .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        if(data?.items?.length > 0){
            dispatch(fetchDataSuccess(data, FETCH_GST_TYP_DATA_SUCCESS));
        }else{
            dispatch(fetchDataFailure(data, FETCH_GST_TYP_DATA_FAILURE));
        } 
    })
    .catch(error => {
      console.log(error)
        dispatch(fetchDataFailure(error.message, FETCH_GST_TYP_DATA_FAILURE));
    });
  }
}