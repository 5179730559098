import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
// import DataContext from '../../../context/ItemMasterContext';
import Select from 'react-select'
import { ToastContainer, } from 'react-toastify'
import axiosInstance from '../../../api/axoiss'
import {
    ItemMasterFetchSingleQuery,
    StockHistoryFetchQuery,
    //  StockHistoryLogFetchQuery,
    StockItemsMultipleFetch
} from '../../../GraphQLQueries/ItemmasterQuery'
import { StockStatementItemComboQtyFetchQuery } from '../../../GraphQLQueries/StoreQueries'
import { DateFormate, onCellDoubleClicked, UnitConversion } from '../../../utils/Utils'
import InventoryApprovals from '../../Stock/InventoryApproval'

const StockStatementDetails = ({
    isShowSelectedStockStatement,
    handleCloseSelectedStockStatement,
    stockStatement,
    part_id,
    from
}) => {
    const [InventoryApprovalData, setInventoryApprovalData] = useState([])
    const [currentMasterData, setCurrentMasterData] = useState(null)
    const [isShowStockHistoryComponent, setShowStockHistoryComponent] = useState(false)
    const [stockValues, setStockValues] = useState({store:{ value: "all", label: "All" }, 
        alternateUomList:[], unit:{ value: "", label: "" }})
    const [isShowStockBatchSerialComponent, setShowStockBatchSerialComponent] =
    useState(true)
    const [stockData, setStockData] = useState()
    const [stockHistoryTabClassName, setStockHistoryTabClassName] =
    useState('nav-link')
    const [stockSerialBatchTabClassName, setStockSerialBatchTabClassName] =
        useState('nav-link active')
    const [batchSerialTabName, setBatchSerialTabName] = useState('Tracking')
    const [historyData, setHistoryData] = useState([])
    const [totalQuantity, setTotalQuantity] = useState(0)
    const [transactionModalDetailsShow, setTransactionModalDetailsShow] =
    useState(false)
  // const [transactionModalDetailsShowId, setTransactionModalDetailsShowId] = useState(null);
  // const [transactionModalDetailsShowModal, setTransactionModalDetailsShowModal] = useState(null);
    const [isComboItem, setComboItem] = useState(false)
    const [totalComboQty, setTotalComboQty] = useState(0)

    const handleTransactionModalDetailsShow = () => {
        setTransactionModalDetailsShow(true)
    }

    const handleTransactionModalDetailsClose = () => {
        setTransactionModalDetailsShow(false)
        localStorage.removeItem('inventory_approval_ids')
    }

    const handleTotalQuantityCalculation = stockStatement => {
        let all_qty = stockStatement?.map(item => parseInt(item.currentStock))
        let total_qty = 0
        all_qty?.forEach(function (number) {
        total_qty += Math.trunc(number)
        })
        return total_qty
    }

  // columns and field name for the table
    let columns = [
        { headerName: 'Store', field: 'store.storeName', flex: 3,onCellDoubleClicked: onCellDoubleClicked },
        { headerName: 'Qty', field: 'currentStock', flex: 2,onCellDoubleClicked: onCellDoubleClicked },
        { headerName: 'UOM', field: 'unit.name', flex: 2 , onCellDoubleClicked: onCellDoubleClicked}
    ]

    const [columnFields, setColumnFields] = useState(columns)

    useEffect(() => {
        let itemmaster_fetch_query = ItemMasterFetchSingleQuery(part_id)
        axiosInstance
        .post('/itemmaster/graphql', { query: itemmaster_fetch_query })
        .then(res => {
            let item_master = res?.data?.data?.itemMaster?.items?.[0]
            setComboItem(item_master?.itemComboBool)
            let unitList  = item_master?.alternateUom?.map((uom)=>({value: uom?.addtionalUnit?.id, label: uom?.addtionalUnit?.name, cf:uom?.conversionFactor}))
            let mainUnit = { value:item_master?.itemUom?.id, label: item_master?.itemUom?.name }
            unitList =  [mainUnit, ...unitList];
            setStockValues(prev=>({...prev, alternateUomList:unitList, unit:mainUnit}))
            if (item_master.itemComboBool) {
            let item_combo_bool_query =
                StockStatementItemComboQtyFetchQuery(part_id)
            axiosInstance
                .post('/itemmaster/graphql', { query: item_combo_bool_query })
                .then(qty_res => {
                let fetched_qty =
                    qty_res?.data?.data?.stockStatementItemComboQty?.items?.[0]?.[
                    'qty'
                    ]
                setTotalComboQty(fetched_qty || 0)
                })
                .catch(err => {
                console.log(err)
                setTotalComboQty(0)
                })
            }
        })
        .catch(err => {
            setComboItem(false)
        })
        if (stockStatement) {
        setCurrentMasterData(stockStatement)
        }
        let stock_ids = stockStatement ? stockStatement['stockId'] : []
        let stock_fetch_query = StockItemsMultipleFetch(stock_ids)
        // console.log(stock_fetch_query)
        let stock_items = []
        axiosInstance
        .post('/itemmaster/graphql', { query: stock_fetch_query })
        .then(res => {
            stock_items = res?.data?.data?.stockItems?.items
            // console.log(stock_items, "stock_items")
            let updated_stock_items = []
            if (stockStatement?.['isSerial']) {
            for (let entry of stock_items) {
                let items = entry.serialNumber
                let serial_numbers = []
                for (let item of items) {
                let new_stock_entry = { ...entry }
                delete new_stock_entry['serialNumber']
                new_stock_entry['serialNumber'] = item['serialNumber']
                new_stock_entry['currentStock'] = 1
                serial_numbers.push(item['serialNumber'])
                updated_stock_items.push(new_stock_entry)
                }
                let concatenated_serial_number = new Set(serial_numbers)
                entry['serialNumber'] = [...concatenated_serial_number].join(', ')
            }
            setInventoryApprovalData(updated_stock_items)
            setStockData(updated_stock_items)
            let total_qty = handleTotalQuantityCalculation(updated_stock_items)
            setTotalQuantity(total_qty)
            } else {
            setInventoryApprovalData(stock_items)
            setStockData(stock_items)
            let total_qty = handleTotalQuantityCalculation(stock_items)
            setTotalQuantity(total_qty)
            }
        })
        .catch(err => {
            console.log(err)
        })
        let columns = [
        { headerName: 'Store', field: 'store.storeName', flex: 3 , onCellDoubleClicked: onCellDoubleClicked},
        { headerName: 'Qty', field: 'currentStock', flex: 2 , onCellDoubleClicked: onCellDoubleClicked},
        { headerName: 'UOM', field: 'unit.name', flex: 2, onCellDoubleClicked: onCellDoubleClicked }
        ]
        if (stockStatement?.['isSerial']) {
        columns.push({
            headerName: 'Serial Number',
            field: 'serialNumber',
            flex: 5,
            onCellDoubleClicked: onCellDoubleClicked
        })
        setColumnFields(columns)
        } else if (stockStatement?.['isBatch']) {
        columns.push({
            headerName: 'Batch Number',
            field: 'batchNumber.batchNumberName',
            flex: 3,
            onCellDoubleClicked: onCellDoubleClicked
        })
        setColumnFields(columns)
        } else {
        setBatchSerialTabName('')
        handleShowStockHistoryTab()
        }
        const getStockHistory = () => {
        let stock_history_log_query = StockHistoryFetchQuery(stock_ids)
        axiosInstance
            .post('/itemmaster/graphql', { query: stock_history_log_query })
            .then(res => {
            const response = res?.data?.data?.stockHistoryDetails?.items
            // console.log(response, "response")
            setHistoryData(response)
            })
            .catch(error => {
            // Handle errors here
            console.error('Error fetching data:', error)
            })
        }
        getStockHistory()
        if (from && from === 'pos') {
        handleShowBatchSerialTab()
        }
    }, [stockStatement, part_id, from])

    const TransactionDocument = ({ data }) => {
        const handleViewTransactionDocument = data => {
        localStorage.removeItem('inventory_approval_ids')
        if (data) {
            if (data['transactionModule'] === 'ItemInventoryApproval') {
            localStorage.setItem('inventory_approval_ids', [
                data['transactionId']
            ])
            handleTransactionModalDetailsShow()
            } else if (data['transactionModule'] === 'InventoryHandler') {
            let inventory_approval_fetch = `query fetch{
                            inventoryHandler(id: ${data.transactionId}){
                            items{
                                inventoryHandlerId
                                inventoryId{
                                id
                                }
                            }
                            }
                        }`
            axiosInstance
                .post('/itemmaster/graphql', { query: inventory_approval_fetch })
                .then(res => {
                let approval_id =
                    res?.data?.data?.inventoryHandler?.items?.[0]?.inventoryId
                if (approval_id) {
                    approval_id = approval_id.map(item => item.id)
                    localStorage.setItem('inventory_approval_ids', approval_id)
                    handleTransactionModalDetailsShow()
                }
                })
                .catch(err => {
                console.log(err)
                })
            }
        }
        }

        return (
        <>
            <div
            style={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => {
                handleViewTransactionDocument(data)
            }}
            >
            {data.displayId}
            </div>
        </>
        )
    }

  //stock history columns
    let stock_history_columns = [
        { headerName: 'Date', field: 'date', flex: 3 ,
            cellRenderer: (props) => DateFormate(props?.data?.date)
        },
        { headerName: 'Doc', field: 'displayName', flex: 2 },
        {
        headerName: 'Trans No',
        field: 'displayId',
        flex: 2,
        cellRenderer: TransactionDocument
        },
        { headerName: 'Starting', field: 'startStock', flex: 2 ,  onCellDoubleClicked: onCellDoubleClicked,},
        { headerName: 'Inward', field: 'added', flex: 2 ,  onCellDoubleClicked: onCellDoubleClicked,},
        { headerName: 'Outward', field: 'reduced', flex: 2 ,  onCellDoubleClicked: onCellDoubleClicked,},
        { headerName: 'Closing', field: 'endStock', flex: 2 ,  onCellDoubleClicked: onCellDoubleClicked,},
        { headerName: 'UOM', field: 'unit', flex: 2 ,  onCellDoubleClicked: onCellDoubleClicked,},
        { headerName: 'Created By', field: 'savedBy', flex: 2,  onCellDoubleClicked: onCellDoubleClicked, }
    ]

  // default config for the table
    const defaultColDef = {
        sortable: true,
        editable: false,
        filter: true,
        floatingFilter: true,
        resizable: true,
        wrapText: true
    }

  // shows stock history tab
    const handleShowStockHistoryTab = () => {
        setShowStockHistoryComponent(true)
        setShowStockBatchSerialComponent(false)
        setStockHistoryTabClassName('nav-link active')
        setStockSerialBatchTabClassName('nav-link')
    }

  // shows stock history tab
    const handleShowBatchSerialTab = () => {
        setShowStockHistoryComponent(false)
        setShowStockBatchSerialComponent(true)
        setStockHistoryTabClassName('nav-link')
        setStockSerialBatchTabClassName('nav-link active')
    }
    let uniqueStores = Array.from(
        new Set(
          InventoryApprovalData?.map(item => item?.store.id)
        )
      ).map(id => {
        const item = InventoryApprovalData.find(item => item.store.id === id);
        return {
          value: item.store.id,
          label: item.store.storeName
        };
      });
    const finalStores = [{ value: "all", label: "All" }, ...uniqueStores];
    return (
    <>
        <Modal
            show={isShowSelectedStockStatement}
            fullscreen
        >
            <Modal.Header  >
            <Modal.Title className='commanModelTitleColor'>
                <i
                className='fa-solid fa-arrow-left fa-sm me-2 '
                onClick={() => {
                    handleCloseSelectedStockStatement()
                }}
                ></i>
                {currentMasterData && currentMasterData['partCode']}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div>
                <div className='card'>
                <div className='card-body d-flex justify-content-between'>
                    <div>
                    <h5 className='card-title'>
                        {currentMasterData && currentMasterData['partName']}
                    </h5>
                    <p className='card-text mt-3'>
                        {currentMasterData && currentMasterData['description']}
                    </p>
                    </div>
                    {isComboItem && (
                    <div>
                        <p>
                        Total Combo Item Quantity:{' '}
                        <span className='mx-3 fw-bold'>{totalComboQty}</span>
                        </p>
                    </div>
                    )}
                    <div>
                    <p>
                        Total Quantity:{' '}
                        <span className='mx-3 fw-bold'>{totalQuantity}</span>
                    </p>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-6">

                    </div>
                    <div className="col-6 ">
                        <div className="row">
                            <div className="col-6 staan-input-group">
                                <Select
                                className='my-3'
                                options = {finalStores}
                                value={stockValues?.store}
                                onChange={(options)=>{
                                    if (options?.value === "all"){
                                        setStockValues(options)
                                        setStockData(InventoryApprovalData)
                                    } else if(options?.value){
                                        setStockValues(options)
                                        setStockData(InventoryApprovalData?.filter((stock)=>stock.store.id=== options?.value))
                                    }
                                }}
                                />
                                <label  className='staan-form-input-label pt-1 px-1 mx-2' >Store</label>
                                
                            </div>
                            <div className="col-6 staan-input-group">
                                <Select
                                className='my-3'
                                options = {stockValues?.alternateUomList}
                                value={stockValues?.unit}
                                onChange={(options)=>{
                                    if (options){
                                        for (let stock in stockData) {
                                            let qty = stockData[stock]?.currentStock
                                            UnitConversion(stockValues?.unit , options, qty)
                                            setStockValues(prve=>({...prve, unit:options}))
                                          }
                                    }
                                    
                                }}
                                />
                                <label  className='staan-form-input-label pt-1 px-1 mx-2' >UOM</label>
                                
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div>
                <div className='mt-3'>
                <ul className='nav nav-tabs'>
                    {!from && (
                    <li className='nav-item'>
                        <span
                        role='button'
                        className={stockHistoryTabClassName}
                        onClick={() => {
                            handleShowStockHistoryTab()
                        }}
                        >
                        Stock History
                        </span>
                    </li>
                    )}
                    {batchSerialTabName !== '' && (
                    <li className='nav-item'>
                        <span
                        role='button'
                        className={stockSerialBatchTabClassName}
                        onClick={() => {
                            handleShowBatchSerialTab()
                        }}
                        >
                        {batchSerialTabName}
                        </span>
                    </li>
                    )}
                </ul>
                </div>
                {isShowStockHistoryComponent && (
                <div className='card'>
                    <div
                    className='ag-theme-alpine card'
                    style={{ height: '400px', width: '100%' }}
                    >
                    <AgGridReact
                        rowData={historyData}
                        columnDefs={stock_history_columns}
                        defaultColDef={defaultColDef}
                        alwaysShowHorizontalScroll={true}
                        pagination={true}
                    />
                    </div>
                </div>
                )}
                {isShowStockBatchSerialComponent && (
                <div
                    className='ag-theme-alpine card'
                    style={{ height: '400px', width: '100%' }}
                >
                    <AgGridReact
                    rowData={stockData}
                    columnDefs={columnFields}
                    defaultColDef={defaultColDef}
                    />
                </div>
                )}
            </div>
            {transactionModalDetailsShow && (
                <InventoryApprovals
                show={transactionModalDetailsShow}
                close={handleTransactionModalDetailsClose}
                />
            )}
            </Modal.Body>
        </Modal>
        <ToastContainer/>
    </>
    )
}

export default StockStatementDetails
