export const enquiryCreateMutation = () => {
    return `mutation enquiryDataCreateMutation{
        enquiryDataCreateMutation(
           
        ){
          enquiryData{
            id
            name
            email
            mobileNumber
            alternateMobileNumber
            salesPerson{
              id
              username
            }
            status
            remarks
            followUp
            message
            location
          }
          success
          errors
        }
    }`
}


export const currentConferenceFetch = (date, incharge) => {
    let query = `query fetchCurrentConference{
        conferenceData(date: "${date}", inChargeId:${incharge}){
          items{
            id
            name
          }
        }
    }`
    return query
}

export const conferenceCreateMutation = (data)=>{
  let query = `
      mutation conferenceCreateMutation{
        conferenceDataCreateMutation(${data}){
          conferenceDataItem{
            id
            status
            startDate
            endDate
            defaultStore{
              id
              storeName
            }
            currency{
              id
              Currency{
                id
                name
              }
              
            }
            inCharge{
              id
              username
            }
            createdBy{
              id
              username
            }
          }
          errors
          success
        }
      }`
  return query
}


