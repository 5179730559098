import React, {useMemo, useState, useEffect} from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axiosInstance from "../../../api/axoiss";
import { FetchProductionOrderFinishedGoods } from "../../../GraphQLQueries/ProductionOrderQuery";


const PoFg = ({fgData, poData, type}) => {
    const containerStyle = useMemo(() => ({ width: "100%", height: "10rem" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([
        { headerName: 'S.No',  valueGetter: "node.rowIndex + 1", flex: 1},
        { headerName: 'Part Code', field: "partCode.itemPartCode", tooltipValueGetter: (p) => p.value , flex: 2},
        { headerName: 'Part Name', field: "partCode.itemName", tooltipValueGetter: (p) => p.value , flex: 5},
        { headerName: 'Category', field: "category.name" , flex: 2},
        { headerName: 'MO Qty', field: "productionQty" , flex: 1},
        { headerName: 'UOM', field: "unit.name" , flex: 1},
        { headerName: 'Completed', field: "completedQty", editable: true , flex: 1},
        { headerName: 'Accepted', field: "acceptedQty", editable: true , flex: 1},
        { headerName: 'Rework', field: "reworkQty", editable: true , flex: 1},
        { headerName: 'Rejected', field: "rejectedQty" , editable: true, flex: 1},
        { headerName: 'Remarks', field: "remarks", tooltipValueGetter: (p) => p.value  , flex: 1},
    ]);

    const defaultColDef = {
        // editable: true, 
        resizable: true
    }

    useEffect(()=>{
        if(fgData){
            axiosInstance.post("/itemmaster/graphql", {query: FetchProductionOrderFinishedGoods(fgData.id)})
            .then(res => {
                let data_list = res?.data?.data?.productionOrderFinishedGoods?.items || []
                setRowData(data_list)
            })
            .catch(err =>{ 
                console.log(err, "error")
            })
            
        }
        // setRowData([fgData]);
        // console.log(poData, "poData")
    }, [fgData, type])

    return(<>
        <div>
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                    />
                </div>
            </div>
        </div>
    </>)
}

export default PoFg;