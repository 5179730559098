import { Field, Form, Formik } from 'formik'
import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import axiosInstance, { commanFetch } from '../../../api/axoiss'
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation'
import showErrorToast from '../../../components/notifications/react-toastify/toast'
import DataContext from '../../../context/ItemMasterContext'
import * as QueryData from '../../../GraphQLQueries/ItemmasterQuery'
import {
  leadDeleteMutations,
  leadsCreateMutation
} from '../../../GraphQLQueries/LeadQueryAndMutations/LeadMutation'
import {
  customSelectStyle,
  DateFormate,
  removeEmptyValueInObject,
  ShowErrorNoties
} from '../../../utils/Utils'
import { LeadEditSchema } from '../../../validations/LeadValidation'
import Activities from '../../Activities/models/Activities'
import { QuotationProvider } from '../../Quotation/Context/quotationContext'
import QuotationFrom from '../../Quotation/Forms/Quatationform'
import SalesOrderForm from '../../SalesOrder2/model/SalesOrderForm'
import Lead from '../Lead'
import './LeadFormEdit.css'; // Ensure this path is correct

const LeadFormEdit = ({ editData, onClose, setload }) => {
  const { userId, handltoastDeleteConfomationShow } = useContext(DataContext)
  const [isEditMode, setIsEditMode] = useState(false)
  const [customerList, setcustomerList] = useState([])
  const [salePersonlist, setsalePersonlist] = useState([])
  const [leadCurrencylist, setleadCurrencylist] = useState([])
  const [deleteData, setdeleteData] = useState({ Name: '', id: '' })
  const [ShowLeadComponent, setShowLeadComponent] = useState(false)
  const [isShowSalesOrder, setIsShowSalesOrder] = useState(false)
  const [quotationId, setQuotationId] = useState(null)
  const [quotationsList, setQuotationsList] = useState(editData?.quotationsSet)

  const location = useLocation()
  let data = ""
  if (location.state?.leadData) {
    data = location.state?.leadData
  } else {
    data = editData

  }


  // State hooks for Select field values
  const [selectedCustomer, setSelectedCustomer] = useState(
    data?.customer?.id || ''
  )
  const [selectedLeadCurrency, setSelectedLeadCurrency] = useState(
    data?.leadCurrency?.Currency?.name || ''
  )
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(
    data?.salesPerson?.id || ''
  )



  const [currentStep, setCurrentStep] = useState(data?.status?.name)
  const [leadStatus, setLeadStatus] = useState(data?.status?.name)

  const [IsshowQuotation, setshowQuotation] = useState(false)

  const [initialValue, setInitialValue] = useState({
    LeadName: data?.leadName || '',
    LeadNumber: data?.leadNo || '',
    customerValue: data?.customer?.companyName || '',
    LeadCurrency: data?.leadCurrency?.id || '',
    LeadValue: data?.leadValue || '',
    ExpectedClosureDate: data?.expectedClosingDate || '',
    LeadStatus: data?.status?.name,
    SalesPerson: data?.salesPerson?.username || '',
    Source: data?.Enquiry?.conferenceData?.name || '',
    Requirements: data?.requirement,
    id: data?.Enquire?.linkContactDetalis?.id,
    Name: data?.Enquiry?.linkContactDetalis?.contactPersonName || '',
    Email: data?.Enquiry?.linkContactDetalis?.email || '',
    MobileNumber: data?.Enquiry?.linkContactDetalis?.phoneNumber || '',
    WhatsappNumber: data?.Enquiry?.linkContactDetalis?.whatsappNo || ''
  })
  const steps = [
    { value: 1, label: 'Qualified' },
    { value: 2, label: 'Quotation' },
    { value: 3, label: 'Demo' },
    { value: 4, label: 'Negotiation' },
    { value: 5, label: 'Won' }
  ];
  // const handleStepChange = (stepIndex, setFieldValue) => {
  //   setCurrentStep(stepIndex)
  //   const newStatus = steps[stepIndex - 1]
  //   setLeadStatus(newStatus)
  //   setFieldValue('LeadStatus', newStatus)
  // }
  async function getOption(e, queryName) {
    let search_term = e.trim().replace(/"/g, '\\"')
    if (queryName === 'SupplierOption' && search_term) {
      const response = await commanFetch(QueryData[queryName](search_term))
      if (response.success) {
        const reponseData = response?.data?.items
        setcustomerList(
          reponseData?.map(customer => ({
            value: customer.id,
            label: customer.companyName
          }))
        )
      }
    } else if (queryName === 'userdataOptionsQuery' && search_term) {
      const response = await commanFetch(QueryData[queryName](search_term))
      if (response.success) {
        const reponseData = response?.data?.items
        setsalePersonlist(
          reponseData?.map(saleperson => ({
            value: saleperson.id,
            label: saleperson.username
          }))
        )
      }
    } else if (queryName === 'leadCurrencyList' && search_term) {
      const response = await commanFetch(QueryData[queryName](search_term))
      if (response.success) {
        const reponseData = response?.data?.items
        setleadCurrencylist(
          reponseData?.map(leadcurrency => ({
            value: leadcurrency.id,
            label: leadcurrency.name
          }))
        )
      }
    } else {
      setcustomerList([])
      setsalePersonlist([])
    }
  }
  //  return null ;
  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setErrors }
  ) => {
    let saveValues = {
      id: String(data?.id),
      createdBy: Number(data?.createdBy?.id),
      customer: Number(selectedCustomer),
      expectedClosingDate: values.ExpectedClosureDate,
      leadCurrency: Number(values?.LeadCurrency),
      leadName: values?.LeadName,
      leadValue: String(values?.LeadValue),
      salesPerson: Number(selectedSalesPerson),
      status: values?.LeadStatus,
      requirement: values?.Requirements,
      modifiedBy: Number(userId)
    }


    let ClearValue = removeEmptyValueInObject(saveValues, [])
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query: leadsCreateMutation(ClearValue)
      })
      console.log('response######################', response)
      const responseData = response?.data?.data?.leadsCreateMutation
      if (responseData.success) {
        resetForm()
        showErrorToast(true, 'success', '', 'Saved Successfully')
        setSubmitting(false)
        setShowLeadComponent(true)
        setload()
        onClose()
      } else {
        let errorData = ShowErrorNoties(responseData.errors)
        showErrorToast(true, 'error', errorData)
      }
    } catch (error) {
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorData)
    }
  }
  const handledeleteConfomation = () => {
    if (data) {
      console.log(data);

      handltoastDeleteConfomationShow()
      setdeleteData({
        Name: data?.leadName,
        id: data?.id
      })
    }
  }
  const handleDeletedId = deleted_id => {
    if (deleted_id) {
      console.log('Deleted Id---------', deleted_id)

      onClose()
      setload()
    }
  }
  // return null; convertToSalesOrder
  function CreateSalesOrder(params) {
    setIsShowSalesOrder(true)
  }

  return (
    <>
      <QuotationProvider>
        {ShowLeadComponent ? (
          <Lead />
        ) : (
          <Formik
            initialValues={initialValue}
            validationSchema={LeadEditSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              setFieldValue,
              values,
              isSubmitting,
              submitForm
            }) => {
              return (
                <>
                  <Form>
                    <Modal show={true} fullscreen onHide={onClose}>
                      <Modal.Header>
                        <Modal.Title style={{ width: '100%' }}>
                          <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center commanModelTitleColor'>
                              <h3 className='mx-2'>
                                <i
                                  className='fa-solid fa-arrow-left fa-sm me-2'
                                  onClick={() => { setShowLeadComponent(true); setQuotationId("") }}
                                  style={{ cursor: 'pointer' }}
                                ></i>
                                Leads
                              </h3>
                            </div>

                            {/* <div className='d-flex align-items-center'>
                              <span
                                className='me-3'
                                style={{ marginRight: '50px' }}
                              >
                                <i
                                  role='button'
                                  className={`fa-solid fa-pen me-4`}
                                  onClick={() => setIsEditMode(!isEditMode)}
                                ></i>
                              </span>

                              <span style={{ marginRight: '50px' }}>
                                <i
                                  role='button'
                                  className='fa-solid fa-trash text-danger'
                                  onClick={() => {
                                    handledeleteConfomation()
                                  }}
                                ></i>
                              </span>
                            </div> */}
                            {!isEditMode && (
                              <div className='d-flex align-items-center'>
                                <span className='me-3' style={{ marginRight: '50px' }}>
                                  <i
                                    role='button'
                                    className={`fa-solid fa-pen me-4`}
                                    onClick={() => setIsEditMode(!isEditMode)}
                                  ></i>
                                </span>

                                <span style={{ marginRight: '50px' }}>
                                  <i
                                    role='button'
                                    className='fa-solid fa-trash text-danger'
                                    onClick={() => {
                                      handledeleteConfomation();
                                    }}
                                  ></i>
                                </span>
                              </div>
                            )}
                          </div>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className='container-fluid mt-2 height-fluid'>
                          <div className='row'>
                            <div className='col-12'>
                              <ul className='list-unstyled d-flex position-relative'>
                                {steps.map((step, index) => (
                                  <li
                                    key={step.value} // Use a unique identifier from the step object
                                    className={`stepper-item ${step.label === currentStep ? 'current' : ''} ${step.label !== currentStep && index < steps.findIndex(s => s.label === currentStep) ? 'complete' : ''}`}
                                  >
                                    {step.label} {/* Access the label property here */}
                                  </li>
                                ))}
                              </ul>
                            </div>

                          </div>
                          <div className='row mt-2'>
                            {/* First Column */}
                            <div className='col-12 col-md-4'>
                              <div className='row'>
                                <div className='col-12 me-3 mt-4 staan-input-group'>
                                  <Field
                                    type='text'
                                    name='LeadNumber'
                                    id='leadNumber'
                                    className='w-100 staan-form-input'
                                    disabled={true}
                                  />
                                  <label
                                    htmlFor='leadNumber'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                    onClick={() => {
                                      console.log(leadStatus)
                                    }}
                                  >
                                    Lead Number
                                  </label>
                                  {touched.LeadNumber && errors.LeadNumber && (
                                    <small className='staan-error-msg'>
                                      {errors.LeadNumber}
                                    </small>
                                  )}
                                </div>
                                <div className='col-12 mt-2 staan-input-group'>
                                  <Field
                                    type='text'
                                    name='LeadName'
                                    id='leadName'
                                    className='w-100 staan-form-input'
                                    disabled={!isEditMode}
                                  />
                                  <label
                                    htmlFor='leadName' onClick={() => {
                                      console.log(currentStep);
                                    }}
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Lead Name<span className='text-danger'>*</span>
                                  </label>
                                  {touched.LeadName && errors.LeadName && (
                                    <small className='staan-error-msg'>
                                      {errors.LeadName}
                                    </small>
                                  )}
                                </div>
                                <div className='col-12 mt-2 staan-input-group'>
                                  <Select
                                    name='customerValue'
                                    className='my-3'
                                    styles={customSelectStyle}
                                    options={customerList}
                                    value={customerList.find(
                                      option => option.label === selectedCustomer
                                    )}
                                    onChange={option => {
                                      setSelectedCustomer(
                                        option ? option.value : ''
                                      )
                                      setFieldValue(
                                        'customerValue',
                                        option ? option.value : ''
                                      )
                                    }}
                                    onInputChange={e => {
                                      getOption(e, 'SupplierOption')
                                    }}
                                    placeholder={data?.customer?.companyName}
                                    isDisabled={!isEditMode}
                                  />
                                  <label
                                    htmlFor='customerValue'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Customer<span className='text-danger'>*</span>
                                  </label>
                                  {touched.customerValue &&
                                    errors.customerValue && (
                                      <small className='staan-error-msg'>
                                        {errors.customerValue}
                                      </small>
                                    )}
                                </div>
                                <div className='col-12 mt-2 staan-input-group'>
                                  <Select
                                    name='LeadCurrency'
                                    className='my-3'
                                    options={leadCurrencylist}
                                    styles={customSelectStyle}
                                    placeholder={data?.leadCurrency?.Currency?.name}
                                    value={leadCurrencylist.find(
                                      option =>
                                        option.label === selectedLeadCurrency
                                    )}
                                    onChange={option => {
                                      setSelectedLeadCurrency(
                                        option ? option.value : ''
                                      )
                                      setFieldValue(
                                        'LeadCurrency',
                                        option ? option.value : ''
                                      )
                                    }}
                                    onInputChange={e => {
                                      getOption(e, 'leadCurrencyList')
                                    }}
                                    isDisabled={!isEditMode}
                                  />
                                  <label
                                    htmlFor='LeadCurrency'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                    onClick={() => {
                                      console.log(initialValue)
                                    }}
                                  >
                                    Lead Currency<span className='text-danger'>*</span>
                                  </label>
                                  {touched.LeadCurrency &&
                                    errors.LeadCurrency && (
                                      <small className='staan-error-msg'>
                                        {errors.LeadCurrency}
                                      </small>
                                    )}
                                </div>
                                <div className='col-12 mt-2 staan-input-group'>
                                  <Field
                                    type='number'
                                    name='LeadValue'
                                    id='LeadValue'
                                    className='w-100 staan-form-input'
                                    disabled={!isEditMode}
                                  />
                                  <label
                                    htmlFor='LeadValue'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Lead Value<span className='text-danger'>*</span>
                                  </label>
                                  {touched.LeadValue && errors.LeadValue && (
                                    <small className='staan-error-msg'>
                                      {errors.LeadValue}
                                    </small>
                                  )}
                                </div>
                                <div className='col-12 mt-2 staan-input-group'>
                                  <Field
                                    type='date'
                                    name='ExpectedClosureDate'
                                    className='staan-form-input  '
                                    style={{
                                      border: '1px solid rgb(206, 201, 201)',
                                      fontSize: '0.8rem'
                                    }}

                                    onChange={e =>
                                      setFieldValue(
                                        'ExpectedClosureDate',
                                        e.target.value
                                      )
                                    }
                                    disabled={!isEditMode}
                                  />
                                  <label className='staan-form-input-label pt-1 px-1 mx-2'>
                                    Expected Closure Date<span className='text-danger'>*</span>
                                  </label>
                                </div>
                                {/* <div className='col-12 mt-2 staan-input-group'>
                                  <Field
                                    type='text'
                                    name='LeadStatus'
                                    id='LeadStatus'
                                    className='w-100 staan-form-input'
                                    value={leadStatus}
                                    placeholder={leadStatus}
                                    disabled={true}
                                  />
                                  <label
                                    htmlFor='LeadStatus'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Lead Status
                                  </label>
                                </div> */}
                                <div className='col-12 mt-2 staan-input-group'>
                                  <Select
                                    name='LeadStatus'
                                    options={steps}
                                    className='my-3'
                                    styles={customSelectStyle}
                                    value={leadStatus}
                                    placeholder={leadStatus}
                                    onChange={options => {
                                      setLeadStatus(options?.label)
                                      setCurrentStep(options?.label)
                                      setFieldValue('LeadStatus', options?.label)
                                    }}
                                    isDisabled={!isEditMode || data?.status?.name === "Won"}
                                  />
                                  <label
                                    htmlFor='LeadStatus'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Lead Status<span className='text-danger'>*</span>
                                  </label>
                                  {touched.SalesPerson && errors.SalesPerson && (
                                    <small className='staan-error-msg'>
                                      {errors.SalesPerson}
                                    </small>
                                  )}
                                </div>
                                <div className='col-12 mt-2 staan-input-group'>
                                  <Select
                                    name='SalesPerson'
                                    options={salePersonlist}
                                    className='my-3'
                                    styles={customSelectStyle}
                                    value={salePersonlist.find(
                                      option =>
                                        option.label === selectedSalesPerson
                                    )}
                                    placeholder={data?.salesPerson?.username}
                                    onChange={option => {
                                      setSelectedSalesPerson(
                                        option ? option.value : ''
                                      )
                                      setFieldValue(
                                        'SalesPerson',
                                        option ? option.value : ''
                                      )
                                    }}
                                    onInputChange={e => {
                                      getOption(e, 'userdataOptionsQuery')
                                    }}
                                    isDisabled={!isEditMode}
                                  />
                                  <label
                                    htmlFor='SalesPerson'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Sales Person<span className='text-danger'>*</span>
                                  </label>
                                  {touched.SalesPerson && errors.SalesPerson && (
                                    <small className='staan-error-msg'>
                                      {errors.SalesPerson}
                                    </small>
                                  )}
                                </div>
                                <div className='col-12 staan-input-group'>
                                  <Field
                                    as='textarea'
                                    name='Requirements'
                                    id='Requirements'
                                    className='w-100 staan-form-input'
                                    rows='4'
                                    disabled={!isEditMode}
                                  />
                                  <label
                                    htmlFor='Requirements'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Requirements
                                  </label>
                                </div>
                                <div className='col-12 mt-2 staan-input-group'>
                                  <Field
                                    type='text'
                                    name='Source'
                                    id='Source'
                                    className='w-100 staan-form-input'
                                    disabled={true}
                                  />
                                  <label
                                    htmlFor='Source'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Source<span className='text-danger'>*</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            {/* Second Column */}
                            <div className='col-12 col-md-4'>
                              <label style={{ marginBottom: '0' }}>
                                Contact
                              </label>
                              <div className="row">
                                <div className="col-6 my-1 staan-input-group">
                                  <Field
                                    type='text'
                                    name='Name'
                                    id='Name'
                                    className='w-100 staan-form-input'
                                    disabled={true}

                                  />
                                  <label
                                    htmlFor='Name'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Name <span className='text-danger'>*</span>
                                  </label>
                                </div>
                                <div className="col-6 my-1 staan-input-group">
                                  <Field
                                    type='text'
                                    name='Email'
                                    id='Email'
                                    className='w-100 staan-form-input'
                                    disabled={true}

                                  />
                                  <label
                                    htmlFor='Email'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Email <span className='text-danger'>*</span>
                                  </label>
                                </div>
                                <div className="col-6 my-1 staan-input-group">
                                  <Field
                                    type='text'
                                    name='MobileNumber'
                                    id='MobileNumber'
                                    className='w-100 staan-form-input'
                                    disabled={true}
                                  />
                                  <label
                                    htmlFor='MobileNumber'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Mobile Number <span className='text-danger'>*</span>
                                  </label>
                                </div>
                                <div className="col-6 my-1 staan-input-group">
                                  <Field
                                    type='text'
                                    name='WhatsappNumber'
                                    id='WhatsappNumber'
                                    className='w-100 staan-form-input'

                                    disabled={true}
                                  />
                                  <label
                                    htmlFor='WhatsappNumber'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Whatsapp Number <span className='text-danger'>*</span>
                                  </label>
                                </div>
                              </div>
                              <label
                                htmlFor='quotationList'
                                className='form-label'
                              >
                                Quotation{quotationsList?.length > 0 ? - quotationsList?.length : ""}
                              </label>
                              <ul
                                id='quotationList'
                                className='list-group '
                                style={{ height: '35rem', overflowY: 'scroll' }}
                              >
                                {quotationsList?.map((Quotations, index) => (
                                  <li key={Quotations?.id} onClick={() => {
                                    setQuotationId(Quotations?.id); setshowQuotation(true);
                                  }} className='p-3 list-group-item list-group-item-action' style={{ cursor: "pointer" }}>
                                    <div className="row">
                                      <div className="col-6 fw-bold">
                                        {Quotations?.quotationNo?.linkedModelId}
                                      </div>
                                      <div className="col-6 text-end">
                                        <span className='fw-semibold'>Status :</span> <span className=''>{Quotations?.status?.name}</span>
                                      </div>
                                      <div className="col-12 mt-1">
                                        <span className='fw-semibold'>Created At :</span> <span className=' '>{DateFormate(Quotations?.CreatedAt)}</span>
                                      </div>

                                      <div className="col-6 mt-1">
                                        <span className='fw-semibold'>CreatedBy :</span> <span className=' '>{Quotations?.createdBy?.username}</span>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            {/* Third Column */}
                            <div className='col-12 col-md-4 mt-2 mt-md-0'>
                              <Activities
                                enquiry_id={data?.Enquiry?.id}
                                show={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='row justify-content-center my-3'>
                          <div className='col-3'>
                            <button
                              type='submit'
                              className='btn btn-outline-success px-4 ms-4'
                              style={{ width: '80%' }}
                              onClick={submitForm}
                              disabled={isSubmitting}
                            >
                              Save
                            </button>
                          </div>
                          <div className='col-3'>
                            <button
                              type='button'
                              className='btn btn-outline-secondary px-2 ms-4'
                              style={{ width: '80%' }}
                              onClick={() => { setshowQuotation(true) }}
                              disabled={data?.status?.name === "Won"}
                            >
                              Quotation
                            </button>
                          </div>
                          <div className='col-3'>
                            <button
                              type='submit'
                              className='btn btn-outline-primary px-2 ms-4'
                              style={{ width: '80%', paddingRight: '20px' }}
                              onClick={CreateSalesOrder}
                              disabled={data?.status?.name === "Won"}
                            >
                              Sales Order
                            </button>
                          </div>
                          <div className='col-3'>
                            <button
                              type='button'
                              className='btn btn-outline-info px-2 ms-4'
                              style={{ width: '80%' }}
                              disabled={data?.status?.name === "Won"}

                            >
                              Lost
                            </button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </Form>
                  {deleteData && (
                    <DeleteConformation
                      handleDeletedId={id => handleDeletedId(id)}
                      deleteData={deleteData}
                      fullquery={leadDeleteMutations(deleteData?.id)}
                    />
                  )}
                </>
              )
            }}
          </Formik>
        )}
        {IsshowQuotation &&
          <QuotationFrom
            quotationFormIsShowing={IsshowQuotation}
            setQuotationFormIsShowing={setshowQuotation}
            quotationId={quotationId}
            setQuotationEditData={setQuotationId}
            leadData={data}
            setQuotationsList={setQuotationsList}>
          </QuotationFrom>
        }
        {isShowSalesOrder && editData &&
          <SalesOrderForm
            isShow={isShowSalesOrder}
            setIsShow={setIsShowSalesOrder}
            editData={null}
            setEditData={null}
            leadData={editData}
          />
        }
      </QuotationProvider>
    </>
  )
}

export default LeadFormEdit
