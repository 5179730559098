import React, { useEffect, useState } from 'react';
import PoDetails from './PoDetails';


const PoDetailCarousal = ({poItemDetailData}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [totalData, setTotalData] = useState(0);

    const goToPrevSlide = () => {
        const index = activeIndex === 0 ? poItemDetailData.length - 1 : activeIndex - 1;
        setActiveIndex(index);
    };

    const goToNextSlide = () => {
        const index = activeIndex === poItemDetailData.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(index);
    };
    
    useEffect(()=>{
        // console.log(poItemDetailData, "poItemDetailData")
        setTotalData(poItemDetailData?.length)
    }, [])

    return (
        <div key={poItemDetailData.id}> 
            <div  id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    {poItemDetailData && poItemDetailData.length > 1 && <div className='mt-3 mb-5 text-end'>
                        <div>
                            <span className='me-2'>
                                <i style={{cursor: 'pointer'}} className="fa-solid fa-chevron-left fs-5 text-primary" title='Previous'  onClick={goToPrevSlide}></i>
                            </span>
                            <span className='fs-5'>
                                    <span className='fs-5 me-1'>{activeIndex+1}</span>
                                        of 
                                    <span  className='fs-5 ms-1'>{totalData}</span>
                                </span>
                            <span className='ms-2'>
                                <i style={{cursor: 'pointer'}} className="fa-solid fa-chevron-right fs-5 text-primary" title='Next' onClick={goToNextSlide}></i>
                            </span>
                        </div>
                    </div>}
                    {
                        poItemDetailData && poItemDetailData.map((item, index) => (<>
                            <div key={index} className={`carousel-item ${index === activeIndex ? 'active' : ''}`}>
                                <PoDetails 
                                    itemDetail = {item}
                                />
                            </div>
                        </>))
                    }
                </div>
                
            </div>
        </div>
    )
}

export default PoDetailCarousal;