import React,{useContext, useState} from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableView from '../../Packages/Custom/DataTable'
import DataContext from '../../context/ItemMasterContext'
import UserManagmentForm from './models/UserManagementForm'
 

const UserManageMentlist = ( ) => {
    const {screenSize} = useContext(DataContext)
    const [isLoad, setLoad] = useState(false)
    const [isShow, setIsShow] = useState(false)
    const [editData, setEditData] = useState(null)
    const CustomAddModalComponent = () => { 
        setIsShow(true)
    }
    const CustomViewModalComponent = (data) => {
        setEditData(data)
        setIsShow(true) 
    }
    const headers = [
        {
            header: 'User Name',
            field: 'user',
            label: "username",
            flex: 10, 
            inputType: 'text',  
            renderComponent: CustomViewModalComponent,
        }, 
        {
            header: 'Email',
            field: 'user',
            label: "email",
            flex: 10,
            inputType: 'text',
        },
        {
            header: 'Department',
            field: 'department', 
            label:"name",
            flex: 10,
            inputType: 'text',
        },
        {
            header: 'Role',
            field: 'role', 
            label: "roleName",
            flex: 10,
            inputType: 'text',
        },

    ]
  return (
    <>
        <div className="container-fluid index_page_aliment_warp">
            <div className='index_page_aliment_sidebar'>
                <SideNavbar/>
            </div>
            <div className='index_page_aliment_table'>
               <div id="custom-table-itemmaster">
               <TableView
                 headers={headers}
                 query={'allUsermanagement'}
                 addComponent={CustomAddModalComponent}
                 loadComponent={isLoad} 
                 titleName={'Users'}
                 permission={"Save_User_Management"}
                 
                 heightAdjust = {`${(screenSize?.height/20)}rem`}
                />
               </div>

            </div>

        </div>
        <UserManagmentForm
        isShow = {isShow}
        setIsShow = {setIsShow}
        setLoad ={()=>{setLoad(!isLoad)}}
        editData = {editData}
        setEditData= {setEditData}
        />

   
    
    </>
  )
}

export default UserManageMentlist