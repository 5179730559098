import * as  yup from "yup";


export const mrp_schema = yup.object().shape({
    isSalesOrder: yup.boolean(),
    isProductionOrder : yup.boolean(),
    isItemGroup : yup.boolean(),
    itemGroup: yup.array(),
    mrpItem: yup.array(),
})

export const mrp_item_schema = yup.object().shape({
    itemPartCode : yup.number().required('Part Code is required'),
    itemName : yup.number().required('Item Name is required'),
    qty: yup.string().required('Qty is required'),
    bom: yup.string().required('Bom is required'),
    availableQty: yup.string(),
    shortageQty: yup.string(),
    // supplier: yup.number().required('Supplier is required'),
    sourceType: yup.number().required('Source Type is required'),
})