import React, { useContext, useEffect, useState } from 'react';
import DataContext from '../../../context/ItemMasterContext';
import { DateFormate, HistoryFontSize, TimeFormate, convertHtmlToText } from '../../../utils/Utils';

const HistoryData =  ( ) => {
  const {historyIds, setHistoryIds, userdata} =  useContext(DataContext)
  const [historyData, setHistoryData] = useState([])
  useEffect(() => {
    
    // const fetchData = async (itemId) => {
    //   try {
    //     const response = await axiosInstance.get(`/itemmaster/Itemmsterhistor/${itemId}`);
    //     // Process the response as needed
    //     const responseData = Array.isArray(response.data) ? response.data : [response.data];
    //     // console.log(responseData);
    //     setHistoryData(prevData => [...prevData, ...responseData])
    //   } catch (error) {
    //     // Handle errors
    //     console.error(`Error fetching data for itemId ${itemId}:`, error);
    //   }
    // }; 
    // if (historyIds.length > 0) {
    //   // Use Promise.all to wait for all asynchronous operations to complete
 
    //   Promise.all(historyIds.map((itemId) => fetchData(itemId)))
    //     .then(() => {
    //       // All requests have completed
    //       console.log('All data fetched successfully');
    //     })
    //     .catch((error) => {
    //       // Handle errors in fetching data
    //       console.error('Error fetching data:', error);
    //     });
    // }
    
    if (historyIds.length>0){
      setHistoryData(historyIds)
    } 
  }, [historyIds]);
  function getUserData(id) {
   let user =  userdata.filter(user=> Number(user.id) === Number(id))
   if (user) {
     
    return  user[0].username ;
  } else {
    
    return "Unknown User";
  }
  }
  return (
    <>
    <div className="offcanvas offcanvas-end"style={{zIndex:"1000000"}} tabIndex="-1" id="History" aria-labelledby="offcanvasRightLabel">
       <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">History</h5>
         <button  className="btn-close text-reset" onClick={()=>{setHistoryIds([]); setHistoryData([])}} data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {historyData.length > 0 ? (
            historyData
            .sort((a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate))
            .map((item, index) => (
              <div key={index}>
                <span>----------------------------------------------------</span> <br/>
                <div className="row d-flex justify-content-between">
                  <div className="col-6">
                  <span style={{fontSize:HistoryFontSize }}>Date:  { DateFormate(item['modifiedDate'])}</span> <br/>
                  </div>
                  <div className="col-4 test-end">
                  <span style={{fontSize:HistoryFontSize }}>Time : {TimeFormate(item['modifiedDate'])}</span> <br/>
                  </div>
                  
                </div>
                <br/>
                {item.UpdatedState.split(";").map((x, index) => (
                    
                  <React.Fragment key={index}> 
                    <span  style={{ marginBottom: "0rem !important", fontSize:HistoryFontSize }}>{convertHtmlToText(x)}</span>
                    <br />
                  </React.Fragment>
                ))}
                <span>{item.SavedBy?.['username']}</span>
              </div>
            ))
          ) : (
            <p>No history data available</p>
          )}

        </div>
    </div>
    </>
  )
}

export default HistoryData