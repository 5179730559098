import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FcCancel } from "react-icons/fc";
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import axiosInstance from '../../../api/axoiss';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import DataContext from '../../../context/ItemMasterContext';
import { employeeQuery } from '../../../GraphQLQueries/EmployeeQueryAndMutations/EmployeeQuery';
import { allExpenseRequestDatas, expenseRequestCancleMutation, expenseRequestCreateMutation, expenseRequestDeleteMutation } from '../../../GraphQLQueries/ExpenseRequestQueryAndMuatations/expenseRequestQuery';
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { customSelectStyle, removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import { ExpenseRequestSchema } from '../../../validations/expenseRequestValidations';

const ExpenseRequestFrom = ({expenceValue, setExpenceValue, setLoad}) => {
    const {userId, handltoastDeleteConfomationShow} = useContext(DataContext)
    const [edit, setEdit] = useState("")
    const [formKey, setFormKey] = useState(0)
    const [initialExpenseRequest, setInitialExpenseRequest] = useState({
    id: "",
    employeeName: "",
    expense_request_no :"",
    expenseRequestDate: "",
    requestAmount: "",
    expenseFor: "",
    approvedBy: "",
    payBy: "",
    isCancel:false,
    createdBy: userId ? Number(userId): "",
    modifiedBy: "",
    })
    const [deleteData, setDeleteData] = useState({Name:"",id:""})
     // Redux
    const dispatch = useDispatch();
    const Options = useSelector(state => state.itemGroup.data);

     // get data by Redux
    useEffect(()=>{
        if (Options.name === "EmployeeNameExpenseRequest"){
            setExpenceValue(prve=>({
                ...prve, EmployeeNameList :  Options?.value?.items?.map(item => ({ value: item?.id, label: item?.employeeName}))
            }))
        }
    },[Options])

    //call edit data Functions
    useEffect(()=>{
    if (expenceValue?.isExpenceShow && expenceValue?.editdata !== null && expenceValue?.editdata?.id){
        fetchEditData(expenceValue?.editdata?.id)
        setEdit(true)
    } else{
        setEdit(false)
    }
    },[expenceValue?.isExpenceShow, expenceValue?.editdata])

    // on edit fetch The Value from dataBase
    async function fetchEditData(id) {
        
        try{
        const response = await axiosInstance.post(`/itemmaster/graphql`, {query: allExpenseRequestDatas(`id:${id}`)});
        const reponseData = response?.data?.data?.allExpenseRequest?.items?.[0]
        if (reponseData){
            setInitialExpenseRequest({
                id: reponseData?.id ? Number(reponseData?.id):"",
                employeeName: reponseData?.employeeName?.id ? Number(reponseData?.employeeName?.id):"",
                expense_request_no :reponseData?.expenseRequestNo,
                expenseRequestDate: reponseData?.expenseRequestDate,
                requestAmount: reponseData?.requestAmount,
                expenseFor: reponseData?.expenseFor,
                isCancel : reponseData?.isCancel,
                approvedBy:reponseData?.approvedBy?.id ? Number(reponseData?.approvedBy?.id): "",
                payBy: reponseData?.payBy?.id ? Number(reponseData?.payBy?.id) : "",
                createdBy: reponseData?.createdBy?.id ? Number(reponseData?.createdBy?.id) :  Number(userId),
                modifiedBy: "",
            })
            setExpenceValue(prev=>({...prev, "EmployeeName":{ value:reponseData?.employeeName?.id, label:reponseData?.employeeName?.employeeName}}))
            setFormKey(prve=>prve+1)
        }
        } catch(error){
            let errorFilter = ShowErrorNoties(error)
            showErrorToast(true,"error", errorFilter)
        }
        
    }

    // return null; update the value
    const handleSubmit = async (values) => {
        let duplicateValue = { ...values };
        delete duplicateValue['expense_request_no']
        let allow_null = ['id',"createdBy","modifiedBy","approvedBy","payBy" ]
        let expenseResuestData = removeEmptyValueInObject(duplicateValue, allow_null, ['expenseFor'])
        try{
            const response = await axiosInstance.post(`/itemmaster/graphql`, {query: expenseRequestCreateMutation(expenseResuestData) })
            let reponseData =  response?.['data']?.['data']?.['expenseRequestCreateMutation']
            if (reponseData?.success){
                showErrorToast(true,"success", "", "Saved Successfully.")
                close()
            } else{
                let errorFilter = ShowErrorNoties(reponseData?.errors)
                showErrorToast(true,"error", errorFilter)
            }
        } catch(error){
            let errorFilter = ShowErrorNoties(error)
            showErrorToast(true,"error", errorFilter)
        }
    }

    const handledeleteConfomation = (data) => {
        handltoastDeleteConfomationShow();
        setDeleteData({ Name:data?.expense_request_no, id: data?.id});
    }
        //  after delete clear the data
    const handleDeletedId = (deleted_id) =>{
        if (deleted_id){
            close()
            setEdit(false)
        } 
        }
    // return null; cancle the request
    const handleCancle = async()=>{
        try{
            const response = await axiosInstance.post(`/itemmaster/graphql`, {query: expenseRequestCancleMutation(initialExpenseRequest?.id) });
            const reponseData = response?.data?.data?.expenseRequestCancleMutation
            if (reponseData?.success){
                showErrorToast(true,"success", "", "Cancle Successfully.")
                close()
            } else{
                let errorFilter = ShowErrorNoties(reponseData?.errors)
                showErrorToast(true,"error", errorFilter)
            }
            } catch(error){
                let errorFilter = ShowErrorNoties(error)
                showErrorToast(true,"error", errorFilter)
            }
    }
    // return  null; close the model clear the datas
    function close(params) {
        setExpenceValue({isExpenceShow:false, EmployeeNameList:[], EmployeeName:{ value: "", label: ""}})
        setInitialExpenseRequest({
            id: "",
            employeeName: "",
            expense_request_no :"",
            expenseRequestDate: "",
            requestAmount: "",
            expenseFor: "",
            isCancel:false,
            approvedBy: "",
            payBy: "",
            createdBy: userId ? Number(userId): "",
            modifiedBy: "",
            })
        setLoad()
    }

    return (
        <Modal
        show={expenceValue?.isExpenceShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        >
        <div  className="row">
            <div className="col-5 m-3" style={{color:"#5CADA9"}}>
                <Modal.Title>
                    <i className='fa-solid fa-arrow-left fa-sm me-2'
                        onClick={close}
                    ></i>Expense Request {initialExpenseRequest?.isCancel ? <span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'> Canceled </span> : ""}
                </Modal.Title>
            </div>
            <div className="col-6 mt-4 text-end" >
            {/* <Can I={"Edit"} a={"Store"}> */}
                <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={initialExpenseRequest?.payBy ? true: initialExpenseRequest?.isCancel? true : !(expenceValue?.editdata && edit)} onClick={()=>{setEdit(!edit)}}  ></i>
            {/* </Can> */}
            {/* <Can I={"Delete"} a={"Store"}> */}
                <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " hidden={initialExpenseRequest?.payBy ? true : initialExpenseRequest?.isCancel? true : !(expenceValue?.editdata && edit)}
                onClick={()=>{handledeleteConfomation(initialExpenseRequest)}}
                ></i>
            {/* </Can> */}
                <FcCancel className='fs-3 cursor-pointer' hidden={initialExpenseRequest?.payBy ? true : initialExpenseRequest?.isCancel? true : !(expenceValue?.editdata && edit)} onClick={handleCancle} />
            </div>
        </div>
        <hr/>
        <Modal.Body>
            <Formik initialValues={initialExpenseRequest}
            onSubmit={handleSubmit}
            validationSchema={ExpenseRequestSchema}
            enableReinitialize key={formKey}>
                {({errors, touched, setFieldValue, })=>{
                    return(
                        <Form>
                            <div className="row mx-2">
                                <div className="col-12 col-md-6 staan-input-group" >
                                    <Field type='text' name='expense_request_no' disabled  id="expense_request_no"   className='w-100 staan-form-input'/>
                                    <label htmlFor="expense_request_no" className='staan-form-input-label pt-1 px-1 mx-2' onClick={()=>{console.log(initialExpenseRequest);
                                    }} >Expense Request No</label>
                                    {touched.expense_request_no &&  errors.expense_request_no && <small>{errors.expense_request_no}</small>}
                                </div>
                                <div className="col-12 col-md-6 staan-input-group" >
                                    <Field type='date' name='expenseRequestDate' disabled={edit} id="expenseRequestDate" className='w-100 staan-form-input'/>
                                    <label htmlFor="expenseRequestDate" className='staan-form-input-label pt-1 px-1 mx-2' >Expense Request Date<span className='text-danger'>*</span></label>
                                    {touched.expenseRequestDate &&  errors.expenseRequestDate && <small>{errors.expenseRequestDate}</small>}
                                </div>
                                <div className="col-12 col-md-6 staan-input-group" >
                                        <ReactSelect
                                            onInputChange={e => {
                                                e.trim()
                                                    ? dispatch(
                                                        fetchOptionsData(
                                                        employeeQuery(
                                                            `employeeName: "${e.trim()}"`
                                                        ),
                                                        'EmployeeNameExpenseRequest'
                                                        )
                                                    )
                                                    : setExpenceValue(prve=>({
                                                    ...prve, EmployeeNameList :[]
                                                    }))
                                                }}
                                        
                                        value={expenceValue?.EmployeeName}
                                        options={expenceValue?.EmployeeNameList}
                                        isDisabled={edit}
                                        name='department'
                                        onChange={(option)=>{
                                            setExpenceValue(prev=>({...prev,EmployeeName:option? option : null }))
                                            setFieldValue("employeeName",option?.value ? Number(option?.value) : null )
                                        }}
                                        
                                        className='mt-3'
                                        styles={customSelectStyle}
                                        />
                                    <label htmlFor="employeeName" className='staan-form-input-label pt-1 px-1 mx-2' >Employee Name<span className='text-danger'>*</span></label>
                                    {touched.employeeName &&  errors.employeeName && <small>{errors.employeeName}</small>}
                                </div>
                                <div className="col-12 col-md-6 staan-input-group" >
                                    <Field type='number' name='requestAmount' disabled={edit} id="requestAmount" className='w-100 staan-form-input'/>
                                    <label htmlFor="requestAmount" className='staan-form-input-label pt-1 px-1 mx-2' >Request Amount<span className='text-danger'>*</span></label>
                                    {touched.requestAmount &&  errors.requestAmount && <small>{errors.requestAmount}</small>}
                                </div>
                                <div className="col-12   staan-input-group" >
                                    <Field as="textarea" name='expenseFor' id="expenseFor"  disabled={edit}  className='w-100 staan-form-input' rows='4'/>
                                    <label htmlFor="expenseFor" className='staan-form-input-label pt-1 px-1 mx-2' >Request For</label>
                                    {touched.expenseFor &&  errors.expenseFor && <small>{errors.expenseFor}</small>}
                                </div>
                                <div className="col-12 d-flex justify-content-evenly mt-3">
                                    <button type="submit" class="btn btn-outline-secondary  " disabled={ initialExpenseRequest?.isCancel? true : initialExpenseRequest?.approvedBy ? true: false}  style={{width:"6rem"}}>Request</button>
                                    <button type="submit" class="btn btn-outline-success"
                                    disabled={initialExpenseRequest?.isCancel? true :  initialExpenseRequest?.approvedBy? true : initialExpenseRequest?.id ? false: true} 
                                    onClick={()=>{setFieldValue("approvedBy", userId ? Number(userId): "" )}}
                                    style={{width:"6rem"}}>Approved</button>
                                    <button type="submit" class="btn btn-outline-primary"
                                    disabled={initialExpenseRequest?.isCancel? true : initialExpenseRequest?.payBy ? true : initialExpenseRequest?.approvedBy? false :  true } style={{width:"6rem"}}
                                    onClick={()=>{setFieldValue("payBy", userId ? Number(userId): "" )}}>Pay</button>
                                </div>

                            </div>
                        </Form>
                    )
                }}

            </Formik>
        </Modal.Body>
        {deleteData?.Name &&
        <DeleteConformation
        handleDeletedId={(id)=>{handleDeletedId(id)}}
        deleteData={deleteData} 
        fullquery={expenseRequestDeleteMutation(deleteData?.id)}
        queryName={"expenseRequestDeleteMutation"}
        />
        }
    
        </Modal>
    )
}

export default ExpenseRequestFrom