import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import BallTriangleLoading from "../../../components/loading/BallTriangle";
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import WaringModel from '../../../components/Warings/WaringModel';
import DataContext from '../../../context/ItemMasterContext';
import { expenseCategoriesCreateMutation, expenseCategoriesDeleteMutation } from '../../../GraphQLQueries/ExpenseQueryandMutation/expenseMutation';
import { categoryEditQuery } from '../../../GraphQLQueries/ExpenseQueryandMutation/expenseQuery';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { customSelectStyle, removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import { ExpenceCategoryValidation } from '../../../validations/ExpenceValidation';



const ExpenseCategoriesModal = ({ isCategoriesShow, setCategoriesShow, setCategoriesEditData, categoriesEditData, setLoad }) => {
    const [initialExpenseCategory, setinitialExpenseCategory] = useState({
        categoryName: '',
        accountName: '',
        isActive: false,
        createdBy: '',
        modifiedBy: '',
        id: ''
    })
    const { userId } = useContext(DataContext)
    const [formKey, setFormKey] = useState(0)
    const [isEditView, setEditview] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const OptionsDataList = useSelector(state => state.itemGroup.data);
    const [categoriesValues, setcategoriesValues] = useState({
        accountNameSelect: '',
        checkActive: false
    })
    const [accountNameList, setaccountNameList] = useState([])
    const [isDeleteWarningShow, setIsDeleteWarningShow] = useState(false)

    useEffect(() => {
        if (isCategoriesShow && categoriesEditData !== null && categoriesEditData?.id) {
            fetchEditData(categoriesEditData?.id)
            setEditview(true)
        } else {
            setEditview(false)
        }

    }, [isCategoriesShow, categoriesEditData])

    useEffect(() => {
        if (OptionsDataList.name === "accountsMaster") {
            setaccountNameList(OptionsDataList?.value?.items?.map((item) => ({ value: item?.id, label: item?.accountsName })))
        }

    }, [OptionsDataList])

    async function fetchEditData(id) {
        setLoading(true)
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: categoryEditQuery(`id:${id}`) });
            const reponseData = response?.data?.data?.allExpenseCategories?.items[0]
            if (reponseData) {
                console.log("reponseData", reponseData);
                setinitialExpenseCategory({
                    categoryName: reponseData?.expenseCategoryName,
                    accountName: reponseData?.accountName?.id,
                    isActive: reponseData?.active,
                    createdBy: reponseData?.createdBy?.id,
                    modifiedBy: reponseData?.modifiedBy ? reponseData?.modifiedBy?.id : '',
                    id: reponseData?.id
                })
                setcategoriesValues({
                    accountNameSelect: { value: reponseData?.accountName?.id, label: reponseData?.accountName?.accountsName },
                    checkActive: reponseData?.active
                })
                setFormKey(prev => prev + 1)
            }
            else {
                showErrorToast(true, 'error', 'Item Not Found!!!!')
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            let errorData = ShowErrorNoties(error)
            showErrorToast(true, 'error', errorData)
        }

    }
    function close() {
        setinitialExpenseCategory({
            categoryName: '',
            accountName: '',
            isActive: '',
            createdBy: '',
            modifiedBy: '',
            id: ''
        })
        setcategoriesValues({
            accountNameSelect: '',
            checkActive: false
        })
        setEditview(false)
        setCategoriesEditData('')
        setLoad()
        setCategoriesShow(false)
    }
    const handleSubmit = async (values, { resetForm, setErrors }) => {
        const reqBody = {
            accountName: Number(values.accountName),
            active: values.isActive,
            createdBy: values.createdBy ? Number(values.createdBy) : Number(userId),
            expenseCategoryName: values.categoryName,
            id: values.id ? values.id : ''
        }
        if (values?.id) {
            reqBody.modifiedBy = values.modifiedBy ? Number(values.modifiedBy) : Number(userId)
        }
        let allow_null = ["id", "createdBy"]
        let clearedValue = removeEmptyValueInObject(reqBody, allow_null)
        setLoading(true)
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, {
                query: expenseCategoriesCreateMutation(clearedValue, allow_null)
            })
            const responseData = response?.data?.data?.expenseCategoriesCreateMutation
            console.log("responseData", responseData);

            if (responseData.success) {
                setLoading(false)
                toast.success(`Expense Categories Created Successfully...`, {
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light'
                })
                setLoad()
                setCategoriesShow(false)
            }
            close()

        }
        catch (error) {
            setLoading(false)
            let errorData = ShowErrorNoties(error)
            showErrorToast(true, 'error', errorData)
        }
    }
    async function DeleteTheCategory() {
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: expenseCategoriesDeleteMutation(`id:${initialExpenseCategory?.id}`) })
            let reponseData = response?.data?.data?.expenseCategoriesDeleteMutation
            console.log("reponseData.success",reponseData.success);
            
            if (reponseData?.success) {
                close()
            } else {
                showErrorToast(true, "error", reponseData?.errors)
            }
            console.log(response);

        } catch (error) {
            let errorFilter = ShowErrorNoties(error)
            showErrorToast(true, "error", errorFilter)
        }

    }
    return (
        <>
            {<BallTriangleLoading isshow={loading} />}
            <Modal show={isCategoriesShow} size="lg">
                <div className="row mx-1">
                    <div className="col-5 m-3" style={{ color: "#5CADA9" }}>
                        <Modal.Title>
                            <i className="fa-solid fa-arrow-left fa-sm me-2" onClick={() => close()}></i>
                            Expense Category
                        </Modal.Title>
                    </div>
                    <div className="col-6 mt-4 text-end">
                        <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={(!isEditView)} onClick={() => { setEditview(false) }}></i>
                        <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3" hidden={(!isEditView)} onClick={() => { setIsDeleteWarningShow(true) }} ></i>
                    </div>
                </div>
                <hr />
                <Modal.Body>
                    <Formik
                        initialValues={initialExpenseCategory}
                        validationSchema={ExpenceCategoryValidation}
                        onSubmit={handleSubmit}
                        enableReinitialize key={formKey}
                    >
                        {({ errors, submitForm, touched, setFieldValue, values, resetForm }) => (
                            <>
                                <Form>
                                    <div className="row">
                                        <div className="col-6 staan-input-group">
                                            <Field
                                                type='text'
                                                name='categoryName'
                                                id="categoryName"
                                                values={initialExpenseCategory?.categoryName}
                                                disabled={isEditView}
                                                className='w-100 staan-form-input'
                                            />
                                            <label htmlFor="categoryName" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                                console.log("values", values);
                                            }}>Expense Category Name</label>
                                            {touched.categoryName && errors.categoryName && <small>{errors.categoryName}</small>}

                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                onInputChange={(e) => {
                                                    e ?
                                                        dispatch(fetchOptionsData(QueryData.accountsMasterForPosOptionQuery(`accountsName :"${e}"`), "accountsMaster")) : setaccountNameList([])
                                                }}
                                                options={accountNameList}
                                                name='accountName'
                                                value={categoriesValues?.accountNameSelect}
                                                className='mt-3'
                                                onChange={(option) => {
                                                    setFieldValue('accountName', option ? Number(option?.value) : null)
                                                    setcategoriesValues(prve => ({
                                                        ...prve,
                                                        accountNameSelect: option ? option : null
                                                    }))
                                                }}
                                                isDisabled={isEditView}
                                                styles={customSelectStyle}
                                            />
                                            <label className='staan-form-input-label  px-1 mx-2'>Account Name<span className='text-danger'>*</span></label>
                                            {touched.accountName && errors.accountName && <small>{errors.accountName}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group m-3">
                                            <input
                                                type="checkbox"
                                                checked={categoriesValues.checkActive}
                                                onChange={e => {
                                                    const isChecked = e.target.checked;
                                                    if (isChecked) {
                                                        setcategoriesValues(prve => ({
                                                            ...prve,
                                                            checkActive: true
                                                        }))
                                                        setFieldValue('isActive', true)
                                                    }
                                                    else {
                                                        setcategoriesValues(prve => ({
                                                            ...prve,
                                                            checkActive: false
                                                        }))
                                                        setFieldValue('isActive', false)
                                                    }
                                                }}
                                                className="me-2"
                                                disabled={isEditView}
                                            />
                                            <span>
                                                Active
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="text-end">
                                            <button type="button" className="btn btn-outline-danger btn-sm me-2"onClick={() => close()}>
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-sm btn-outline-success shadow-sm px-3">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                    {isDeleteWarningShow &&
                                        <WaringModel
                                            state={isDeleteWarningShow}
                                            setstate={setIsDeleteWarningShow}
                                            title={'Confirm'}
                                            text={"Confirm to delete"}
                                            Confirmtion={value => {
                                                if (value) {
                                                    DeleteTheCategory(value)
                                                }
                                            }}
                                        />}
                                </Form>
                            </>


                        )}


                    </Formik>


                </Modal.Body>
            </Modal>
        </>
    );
}

export default ExpenseCategoriesModal;
