import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { TbHistory } from 'react-icons/tb'
import Select from 'react-select'
import { ToastContainer } from 'react-toastify'
import axiosInstance from '../../../api/axoiss'
import Can from '../../../components/authorizationComponents/Can'
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation'
import showErrorToast from '../../../components/notifications/react-toastify/toast'
import DataContext from '../../../context/ItemMasterContext'
import * as QueryData from '../../../GraphQLQueries/ItemmasterQuery'
import {
  ShowErrorNoties,
  checkChangesInputs,
  currentPath,
  customSelectStyle,
  removeEmptyValueInObject
} from '../../../utils/Utils'
import { itemUOMschema } from '../../../validations/itemmaster'
import HistoryData from './HistoryData'

const AddUom = ({ uomEditId, setuomEditId, setLoad }) => {
  const {
    ItemUomAdd,
    handleUOMClose,
    userId,
    // setUOMData,
    setItemUOMSelect,
    historyIds,
    setHistoryIds,
    handltoastDeleteConfomationShow
  } = useContext(DataContext)
  const [initialUom, setInitialUom] = useState({
    name: '',
    e_way_bill_uom: '',
    Description: '',
    HistoryDetails: '',
    modified_by: '',
    createdby: ''
  })
  const [formKey, setFormKey] = useState(0)
  const [edit, setEdit] = useState(true)
  const [deleteData, setdeleteData] = useState({ Name: '', id: '' })
  const [ewaybillUOM, setEwaybillUOM] = useState()
  let url = '/itemmaster/UOM'
  const currentURL = currentPath(window.location.href)

  // return null; fetch edit data set into input
  useEffect(() => {
    if (uomEditId) {
      async function fetchData(id) {
        try{
          const response = await axiosInstance.post(`/itemmaster/graphql`, {query: QueryData.UomEdit(id)});
          const reponseData = response?.data?.data?.uom?.items?.[0]
          if (reponseData){
            setInitialUom({
              name: reponseData?.['name'],
              e_way_bill_uom: reponseData?.['eWayBillUom'],
              Description: reponseData?.['descriptionText'] ? reponseData?.['descriptionText'] : "",
              HistoryDetails: reponseData?.['historyDetails'],
              modified_by: "",
              createdby:reponseData?.[0]?.['createdBy']
              ? reponseData?.[0]?.['createdBy']?.['id']
              : ''
            })
            setEwaybillUOM({
              value: reponseData?.['eWayBillUom'],
              label: reponseData?.['eWayBillUom']
            })
            
            setFormKey(prevKey => prevKey + 1)
          }
        } catch(error){ 
          
          let errorData =  ShowErrorNoties(error)
          showErrorToast(true, "error", errorData, "")
        }
      }
    
      fetchData(uomEditId.id)
    }
  }, [uomEditId])
  
  // return null; reset the initial value
  function reset_form () {
    setEwaybillUOM({ value: '', label: '' })
    setInitialUom({
      name: '',
      e_way_bill_uom: '',
      Description: '',
      modified_by: '',
      createdby: ''
    })
    setEdit(true)
  }

  // return null; Submit the value
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const save_uom = {
      id: uomEditId ? Number(uomEditId.id) : '',
      name: values['name'],
      eWayBillUom: values['e_way_bill_uom'],
      descriptionText:
        values['Description'] && values['Description'] !== ''
          ? values['Description']
          : null,
      modifiedBy: uomEditId ? Number(userId) : '',
      createdBy: uomEditId
        ? Number(initialUom.createdby)
          ? Number(initialUom.createdby)
          : Number(userId)
        : Number(userId)
    }
    let variables = removeEmptyValueInObject(save_uom)
    const mutation =
      `mutation UomCreate{
        uomCreateMutation(` +
      variables +
      `) {
          Uom{
            id
            name
          }
          success
          errors
          }
        }
      `
      try {
        const response = await axiosInstance.post('/itemmaster/graphql', {
          query: mutation
        })
        if (response.data.data.uomCreateMutation.success) {
          let responseData = response.data.data.uomCreateMutation.Uom
          setItemUOMSelect({
            id: responseData['id'],
            lable: responseData['name']
          })
          if (setuomEditId){
            setuomEditId('')
          }
          resetForm()
          reset_form()
          handleUOMClose()
        
          setSubmitting(false)
          
          

          if (currentURL === 'unit') {
            setLoad()
          }
          showErrorToast(true, "success", "", "Saved Successfully.")
        } else {
          let error = response.data.data.uomCreateMutation.errors

          let errorData = ShowErrorNoties(error)
          showErrorToast(true, "error", errorData, "")
          
        }
      } catch (error) {
        let errorData = ShowErrorNoties(error)
        showErrorToast(true, "error", errorData, "")
      }
  }
 
  function E_Way_BillUOM () {
    const options = [
      { value: 'BAGS', label: 'BAGS' },
      { value: 'BALE', label: 'BALE' },
      { value: 'BUNDLES', label: 'BUNDLES' },
      { value: 'BUCKLES', label: 'BUCKLES ' },
      { value: 'BILLION OF UNITS', label: 'BILLION OF UNITS' },
      { value: 'BOX', label: 'BOX' },
      { value: 'BOTTLES', label: 'BOTTLES' },
      { value: 'BUNCHES', label: 'BUNCHES' },
      { value: 'CANS', label: 'CANS' },
      { value: 'CUBIC METERS', label: 'CUBIC METERS' },
      { value: 'CUBIC CENTIMETERS', label: 'CUBIC CENTIMETERS' },
      { value: 'CENTIMETERS', label: 'CENTIMETERS' },
      { value: 'CARTONS', label: 'CARTONS' },
      { value: 'DOZENS', label: 'DOZENS' },
      { value: 'DRUMS', label: 'DRUMS' },
      { value: 'GREAT GROSS', label: 'GREAT GROSS' },
      { value: 'GRAMMES', label: 'GRAMMES' },
      { value: 'GROSS', label: 'GROSS' },
      { value: 'GROSS YARDS', label: 'GROSS YARDS' },
      { value: 'KILOGRAMS', label: 'KILOGRAMS' },
      { value: 'KILOLITRE', label: 'KILOLITRE' },
      { value: 'KILOMETRE', label: 'KILOMETRE' },
      { value: 'LITRES', label: 'LITRES' },
      { value: 'MILILITRE', label: 'MILILITRE' },
      { value: 'METERS', label: 'METERS' },
      { value: 'METRIC TON', label: 'METRIC TON' },
      { value: 'NUMBERS', label: 'NUMBERS' },
      { value: 'OTHERS', label: 'OTHERS' },
      { value: 'PACKS', label: 'PACKS' },
      { value: 'PIECES', label: 'PIECES' },
      { value: 'PAIRS', label: 'PAIRS' },
      { value: 'QUINTAL', label: 'QUINTAL' },
      { value: 'ROLLS', label: 'ROLLS' },
      { value: 'SETS', label: 'SETS' },
      { value: 'SQUARE FEET', label: 'SQUARE FEET' },
      { value: 'SQUARE METERS', label: 'SQUARE METERS' },
      { value: 'SQUARE YARDS', label: 'SQUARE YARDS' },
      { value: 'TABLETS', label: 'TABLETS' },
      { value: 'TEN GROSS', label: 'TEN GROSS' },
      { value: 'THOUSANDS', label: 'THOUSANDS' },
      { value: 'TONNES', label: 'TONNES' },
      { value: 'TUBES', label: 'TUBES' },
      { value: 'US GALLONS', label: 'US GALLONS' },
      { value: 'UNITS', label: 'UNITS' },
      { value: 'YARDS', label: 'YARDS' }
    ]
    return options
  }

  const E_Way_BillUOMOptions = E_Way_BillUOM()
  // clear confomations
  const [waringToCloseModel, setWaringToCloseModel] = useState(false)
  
 
  let currentValues = {}
   // Confirmation befor close
  const WaringToClose = () => {
    // return; if use click yes reset the data
    const clearTheData = () => {
      handleUOMClose()
      if (uomEditId) {
        setuomEditId()
      }

      reset_form()
      setWaringToCloseModel()
    }
    return (
      <>
        <Modal
          show={waringToCloseModel}
          onHide={()=>{ setWaringToCloseModel(false)}}
          backdrop='static'
          keyboard={false}
          size='sm'
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to leave without saving?</Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-outline-warning btn-sm'
              onClick={()=>{ setWaringToCloseModel(false)}}
            >
              No
            </button>
            <button
              type='button'
              className='btn btn-outline-success btn-sm'
              onClick={clearTheData}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  // before close any changes in form it call the Confirmation
  function Close () {
    let isDuplicate = checkChangesInputs([initialUom, currentValues])
    if (!isDuplicate) { 
      handleUOMClose()
      if (uomEditId) {
        setuomEditId()
      }

      reset_form()
    } else {
      setWaringToCloseModel(true)
    }
  }
   // return null; befor delete call the Confirmation to delete
  const handledeleteConfomation = data => {
    handltoastDeleteConfomationShow()
    setdeleteData({
      Name: data.name,
      id: data.id
    })
  }
  // return null ; after delete close the Form
  const handleDeletedId = deleted_id => {
     
    
    if (deleted_id) {
      handleUOMClose()
      setuomEditId()
      reset_form()
      setLoad()
      setEdit(true)
    }
  }

  return (
    <>
      {WaringToClose()}
      <HistoryData historyIds={historyIds} setHistoryIds={setHistoryIds} />
      <ToastContainer />
      <Modal
        show={ItemUomAdd}
        size='lg'
        onHide={() => {
          Close()
        }}
      >
        <div className='row'>
          <div className='col-5 m-3' style={{ color: '#5CADA9' }}>
            {' '}
            <Modal.Title>
              <i
                className='fa-solid fa-arrow-left fa-sm me-2'
                onClick={() => {
                  Close()
                }}
              ></i>
              Unit of measurement{' '}
            </Modal.Title>
          </div>
          <div className='col-6 mt-4 text-end'>
            <Can I={'Edit'} a={'UOM'}>
              <i
                className='fa-solid fa-pen fs-5 text-primary pe-3'
                hidden={!(uomEditId && edit)}
                onClick={() => {
                  setEdit(!edit)
                }}
              ></i>
            </Can>
            <Can I={'Delete'} a={'UOM'}>
              <i
                className='fa-solid fa-trash text-danger fs-5 pe-3 ps-3  '
                hidden={!(uomEditId && edit)}
                onClick={() => {
                  handledeleteConfomation(uomEditId)
                }}
              ></i>
            </Can>
            <Can I={'Historys'} a={'UOM'}>
              <TbHistory
                type='button'
                className='fs-4 ms-3'
                data-bs-toggle='offcanvas'
                hidden={!(uomEditId && edit)}
                data-bs-target='#History'
                onClick={() => {
                  setHistoryIds(initialUom.HistoryDetails)
                }}
              />
            </Can>
          </div>
          <hr />
        </div>
        <Modal.Body>
          <Formik
            initialValues={initialUom}
            validationSchema={itemUOMschema}
            onSubmit={handleSubmit}
            enableReinitialize
            key={formKey}
          >
            {({ errors, touched, values, isSubmitting, setFieldValue }) => {
              currentValues = values
              return (
                <Form>
                  <div className='row'>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='name'
                        placeholder=''
                        disabled={uomEditId && edit}
                        className='w-100 staan-form-input'
                      />
                      <label
                        htmlFor='Name'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Name
                      </label>
                      {touched.name && errors.name && (
                        <small className='text-danger'>{errors.name}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Select
                        name='E_Way_Bill_UOM'
                        value={ewaybillUOM}
                        options={E_Way_BillUOMOptions}
                        isClearable
                        isDisabled={uomEditId && edit}
                        onChange={option => {
                          setFieldValue(
                            'e_way_bill_uom',
                            option ? option.value : null
                          )
                          setEwaybillUOM({ value: option?.value, label: option?.label })
                        }}
                        className='my-3'
                        styles={customSelectStyle}
                      />
                      <label
                        htmlFor='E_Way_Bill_UOM'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        E Way Bill UOM{' '}
                      </label>
                      {touched.Item_Group && errors.Item_Group && (
                        <small>{errors.Item_Group}</small>
                      )}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 staan-input-group'>
                      <Field
                        type='text'
                        as='textarea'
                        style={{ height: '5rem' }}
                        rows='3'
                        name='Description'
                        disabled={uomEditId && edit}
                        className='w-100 staan-form-input'
                      />
                      <label
                        htmlFor='Description'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Description
                      </label>
                      {touched.Description && errors.Description && (
                        <small className='text-danger'>
                          {errors.Description}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='text-end'>
                      <Button
                        variant='outline-danger'
                        className='mx-2'
                        size='sm'
                        onClick={() => {
                          Close()
                        }}
                      >
                        Cancel
                      </Button>
                      <Can allow_list={['Save_UOM', 'Edit_UOM']}>
                        <Button
                          type='submit'
                          disabled={isSubmitting}
                          className='mx-2 px-3'
                          size='sm'
                          variant='outline-success'
                        >
                          Save
                        </Button>
                      </Can>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {deleteData.Name && (
        <DeleteConformation
          handleDeletedId={id_ => {
            handleDeletedId(id_)
          }}
          deleteData={deleteData}
          url={url}
        />
      )}
    </>
  )
}

export default AddUom
