import React, { useState } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableView from '../../Packages/Custom/DataTable'
import ExpenseRequestFrom from './models/ExpenseRequestFrom'

const ExpenseRequest = () => {
    const [isLoad, setLoad] = useState(false)
    const [expenceValue, setExpenceValue] = useState({isExpenceShow:false, 
        EmployeeNameList:[],
        EmployeeName:{ value: "", label: ""},
        editdata:null})
    const CustomAddModalComponent = () => {
        setExpenceValue(prev=>({...prev, isExpenceShow:true}))
    }
    const CustomViewModalComponent = (data)=>{
        setExpenceValue(prev=>({...prev, isExpenceShow:true,editdata:data}))
    }
    const headers = [
        {
            header: 'ExpenseRequestNo',
            field: 'expenseRequestNo',
            flex: 10,
            inputType: 'text',
            renderComponent: CustomViewModalComponent,
        },
        {
            header: 'Employee Name',
            field: 'employeeName',
            label: 'employeeName',
            flex: 10,
            inputType: 'text',
        },
        {
            header: 'Request Amount',
            field: 'requestAmount',
            flex: 10,
            inputType: 'number',
        },
        {
            header: 'Expense Request Date',
            field: 'expenseRequestDate',
            flex: 10,
            inputType: 'text',
            isDateField: true,
        },
    ]
    return (
        <>
            <div className="index_page_aliment_warp">
                <div className="index_page_aliment_sidebar">
                    <SideNavbar/>
                </div>
                <div className="index_page_aliment_table">
                    <TableView
                        headers={headers}
                        query={"ExpenseRequestTable"}
                        addComponent={CustomAddModalComponent}
                        loadComponent={isLoad}
                        titleName={"Expense Request"}
                        heightAdjust={"40rem"}
                        permission={"Save_Department"}
                    
                    />
                </div>

            </div>
            {expenceValue?.isExpenceShow &&
                <ExpenseRequestFrom
                expenceValue={expenceValue}
                setExpenceValue={setExpenceValue}
                setLoad ={()=>{setLoad(!isLoad)}}
                />
            }
        </>
    )
}

export default ExpenseRequest