export const categoryEditQuery = (data) => {
  // id: 6
  return `
    query MyQuery { 

  allExpenseCategories(${data}) { 

    items { 

      active 

      expenseCategoryName 
      accountName{
        accountsName
        id
      }

      id 
      createdBy{
        id
      }
        modifiedBy{
        id
      }

    } 

    pageInfo { 

      hasNextPage 

      hasPreviousPage 

      totalPages 

    } 

  } 

} 
    `
}

export const categoryOptionQuery = (data) => {
  // id: 6
  return `
  query MyQuery { 
  allExpenseCategories(${data}) { 
    items { 
      id
      expenseCategoryName
      active
      accountName{
        gstApplicable
      }
    } 
  } 
} 
   
`
}

export const expenseClaimEditQuery = (data) => {
  return `
query MyQuery {
  allExpenseClaim(${data}) {
    items {
      id
      status{
        id
        name
      }
      createdBy {
        id
        username
      }
      modifiedBy{
        id
        username
      }
      employeeId {
        id
        employeeId
        employeeName
        userProfile{
          id
          image
        }
      }
      expenseClaimDate
      expenseClaimNo
      id
      
      expenseClaimDetails {
        amount
        dateOfExp
        descriptions
        gstIn
        id
        pdfUrl
        expenseCategories {
          id
          expenseCategoryName
        }
      }
      status {
        id
        name
      }
    }
  }
}
`

}