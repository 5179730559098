import { toast, Bounce } from 'react-toastify';
import axiosInstance from '../api/axoiss';
import { removeEmptyValueInObject, ShowErrorNoties} from './Utils';
import { createPosOrderItem } from '../GraphQLQueries/ItemMastermutation';
import { SalesOrderItemComboCreateMutation } from '../GraphQLQueries/PosItemComboQuery';

// return Objects; splite the gst rate
function togettax (savedScanerdara, gst_rate, statedata = null, isSales, stateForSales) {
    if (isSales) {
      // we face the state change problem so we use statedata
      if (statedata !== null) {
        if (statedata === 'Tamil Nadu') {
          savedScanerdara['sgst'] = Number(gst_rate / 2)
          savedScanerdara['cgst'] = Number(gst_rate / 2)
          savedScanerdara['igst'] = 0
        } else {
          savedScanerdara['igst'] = Number(gst_rate)
          savedScanerdara['sgst'] = 0
          savedScanerdara['cgst'] = 0
        }
      } else { 
        if (stateForSales === 'Tamil Nadu') {
          savedScanerdara['sgst'] = Number(gst_rate / 2)
          savedScanerdara['cgst'] = Number(gst_rate / 2)
          savedScanerdara['igst'] = 0
        } else {
          savedScanerdara['igst'] = Number(gst_rate)
          savedScanerdara['sgst'] = 0
          savedScanerdara['cgst'] = 0
        }
      }
      return savedScanerdara
    } else {
      return savedScanerdara
    }
}

// Return Object; Save The No batch/serial Number
export const SaveNoBatchSerialNumberPosItem = async ({
  data, getGstRate, posItemData, setPosItemData,
    discountPercentageInValue, discountValue, discountTotalValue, clearAllDiscount, setisDataSave,
    isCombo, 
}
) => {
    let setError = ''
    // let setEdit = false 
    if(data?.id){
      // setEdit = true
    }else{
      delete data['id']
    }
    data['description'] = String(data?.['description']).replace(/"/g, "/");
    data['discountValueForPerItem'] = "0"
    data['discountPercentage'] = "0"
    data['finalValue'] = "0"
    data['discountValue'] = "0"
    const saveData = togettax(data, getGstRate) 
    let dupObject = posItemData.find(
      itemIds => Number(itemIds?.partCode?.id) === Number(data.partCode)
    ) 
    if (dupObject){
      data['id'] = Number(dupObject['id']) 
      data['amount'] =
        Number(dupObject['rate']) * Number(data['qty'])
      // setEdit = true
    } else if (data['id']  !== "" && data['id'] !== undefined){
      // setEdit = true
    }
    let emptyRemovedData = removeEmptyValueInObject(saveData)
    if (!setError) {
      try {
        let mutation_query  = ''
        let fetch_key = ''
        if(isCombo){
            mutation_query = SalesOrderItemComboCreateMutation(emptyRemovedData);
            fetch_key = 'salesOrderItemComboCreateMutation'
        }else{
            mutation_query = createPosOrderItem(emptyRemovedData);
            fetch_key = 'salesOrderCreateMutation'
        } 
        const respones = await axiosInstance.post(`/itemmaster/graphql`, {
          query: mutation_query
        })  
         const result = respones['data']['data'][fetch_key]
         let responseData = Array.isArray(result.SalesoderInstance)
         ? result.SalesoderInstance
         : [result.SalesoderInstance]
          
         if (result.success){  
          let updated_res_data = {
            index: Number(responseData[0]?.id),
            id: responseData[0]?.id, 
            partCode : responseData[0]?.partCode?.['itemPartCode'],
            partNumber: responseData[0]?.partCode,
            partName : responseData[0]?.partCode?.['itemName'],
            Description : responseData[0]?.partCode?.description,
            qty : Number(responseData[0]?.qty),
            rate : responseData[0]?.rate, 
            uom : {value: responseData[0]?.uom?.id, label: responseData[0]?.uom?.name},
            Amount  : responseData[0]?.['amount'], 
            getRate :  responseData[0]?.['gstRate'],
            createdby :  responseData[0]?.['createdBy'],
            display:  responseData[0]?.['display'],
          } 
            if (posItemData.length > 0) {
              let existing_data = [...posItemData]
              existing_data = existing_data.filter(item => Number(item.partNumber.id) !== Number(responseData[0]?.partCode.id))
              setPosItemData([updated_res_data, ...existing_data]);
            } else {
                setPosItemData(responseData)
            } 
            toast.success('Saved', {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light'
            })
            if (
              Number(discountPercentageInValue) > 0 ||
              Number(discountValue) > 0 ||
              Number(discountTotalValue) > 0
            ) {
              clearAllDiscount({ responceData: responseData[0] })
            }
            return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    } else {
      toast.error(setError, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce
      })
      return false
    }
 
   
}

// Return Object; Save The Batch Number
export const SaveBatchNumberPosItem = async ({data, getGstRate, posItemData, setPosItemData,
  discountPercentageInValue, discountValue, discountTotalValue, addedItems, setAddedItems,
  clearAllDiscount, setisDataSave, isCombo, }
) => {
    /*
    check if  data id it is  already add we increase the qty , amount then add it 
    check else normally add the data 
    */
    // let setEdit = false
    if(data?.id){
      // setEdit = true
    }else{
      delete data['id']
    }
    let setError = ''
    data['description'] = String(data['description']).replace(/"/g, "/"); 
    data['discountValueForPerItem'] = "0"
    data['discountPercentage'] = "0"
    data['finalValue'] = "0"
    data['discountValue'] = "0"
    // data
    const saveData = togettax(data, getGstRate)
    if (addedItems && addedItems[data['partCode']]) {
    let findedDuplicate = addedItems[data['partCode']].find(
        checkDupicate =>
        Number(checkDupicate.batchNumber) === Number(saveData['batch'])
    )
    if (saveData['id'] === '') {
        if (findedDuplicate) {
        let editObject = posItemData.find(
            itemIds => Number(itemIds.id) === Number(findedDuplicate.id)
        )
        if (editObject) {
            data['id'] = Number(editObject['id'])
            data['qty'] = Number(editObject['qty']) + Number(1)
            data['amount'] =
            Number(editObject['rate']) * Number(data['qty'])
            // setEdit = true
        } else {
            setError = 'Duplicate Obj No Find In Table'
        }
        }
    } else {
        // setEdit = true
    } 
    } 
    let variable = removeEmptyValueInObject(saveData);
    if (!setError) {
    try {
        let mutation_query  = ''
        let fetch_key = ''
        if(isCombo){
          mutation_query = SalesOrderItemComboCreateMutation(variable);
          fetch_key = 'salesOrderItemComboCreateMutation'
        }else{
          mutation_query = createPosOrderItem(variable)
          fetch_key = 'salesOrderCreateMutation'
        }
        console.log(mutation_query, "mutation_query pos item combo")
        const respones = await axiosInstance.post(`/itemmaster/graphql`, {query: mutation_query})
        console.log(respones, "pos item combo res")
        const result = respones['data']['data'][fetch_key]
        if (result.success) {
          // setisDataSave(true);
          let responseData = Array.isArray(result.SalesoderInstance)
            ? result.SalesoderInstance
            : [result.SalesoderInstance]
          let updated_res_data = {
              index: Number(responseData[0]?.id),
              id: responseData[0]?.id, 
              partCode : responseData[0]?.partCode?.['itemPartCode'],
              partNumber: responseData[0]?.partCode,
              partName : responseData[0]?.partCode?.['itemName'],
              Description : responseData[0]?.partCode?.description,
              qty : Number(responseData[0]?.qty),
              rate : responseData[0]?.rate, 
              uom : {value: responseData[0]?.uom?.id, label: responseData[0]?.uom?.name},
              Amount  : responseData[0]?.['amount'],
              BatchNo :  responseData[0]?.['batch']?.['batchNumberName'],
              batchNumber: {label: responseData[0]?.['batch']['batchNumberName'], value: responseData[0]?.['batch']?.['id']},
              Serialnum :  responseData[0]?.['serial'],
              getRate :  responseData[0]?.['gstRate'],
              createdby :  responseData[0]?.['createdBy'],
              display:  responseData[0]?.['display'],
          }
          if (posItemData.length > 0) {
              let existing_data = [...posItemData]
              existing_data = existing_data.filter(item => Number(item.partNumber.id) !== Number(responseData[0]?.partCode.id))
              setPosItemData([updated_res_data, ...existing_data]);
          } else {
              setPosItemData(responseData)
          }
           
          toast.success('Saved', {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light'
          })
          if (
              Number(discountPercentageInValue) > 0 ||
              Number(discountValue) > 0 ||
              Number(discountTotalValue) > 0
          ) {
              clearAllDiscount({ responceData: responseData[0] })
          }
          return true
        } else {
          return false
        }
    } catch (error) {
        return false
    }
    } else {
    toast.error(setError, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce
    })
    return false
    }
}

// Return Object; Save Serial Number;
export const SaveSerialNumberPosItem = async ({data, getGstRate, posItemData, setPosItemData,
  discountPercentageInValue, discountValue, discountTotalValue, clearAllDiscount, 
  setisDataSave, isCombo, 
}) => { 
    let setError = ''
    // let setEdit = false
    if(data?.id){
      // setEdit = true
    }else{
      delete data['id']
    }
    let SavedData = data
    // if (posItemData.length>0){
    //   for (let key in posItemData) {
    //       let singlData = posItemData[key]
    //       if (
    //         Number(singlData['partCode']['id']) === Number(data['partCode'])
    //       ) {
    //       setEdit = true
    //           if(data['id'] === ""){ 
    //               let dupSerialNumber = singlData['serial'].filter(
    //                 serial_number_item =>
    //                   data['serial'].includes(Number(serial_number_item["id"]))         
    //               )   
    //               if (dupSerialNumber.length === 0){ 
    //                   data['qty'] = Number(singlData['qty']) + Number(data['qty'])
    //                   let serialList = []
    //                   singlData['serial'].map((serialItem)=>{
    //                       serialList.push(serialItem.id)
    //                   })
    //                   data['serial'].map((serialItem)=>{
    //                       serialList.push(serialItem)
    //                   })
    //                   data['amount'] = Number(data['rate']) * Number(data['qty'])
    //                   data['serial'] = serialList
    //                   data['id'] = singlData['id']
    //                   data['discountValueForPerItem'] = "0"
    //                   data['discountPercentage'] = "0"
    //                   data['finalValue'] = "0"
    //                   data['discountValue'] = "0"
    //                   SavedData = data
    //               } else{
    //                   toast.error("Serial number is already exiating", {
    //                       position: 'bottom-right',
    //                       autoClose: 5000,
    //                       hideProgressBar: false,
    //                       closeOnClick: true,
    //                       pauseOnHover: true,
    //                       draggable: true,
    //                       progress: undefined,
    //                       theme: 'light',
    //                       transition: Bounce
    //                   })
    //               }
    //           } else{
    //               SavedData = data
    //           }
    //       } else{
    //       SavedData = data
    //       }
    //   }
    // } else {
    //   SavedData = data
    // }  
    const saveData = togettax(SavedData, getGstRate)
    let variable = removeEmptyValueInObject(saveData)
    if (!setError && SavedData !== '') {
      try {
          let mutation_query  = ''
          let fetch_key = ""
          if(isCombo){
              mutation_query = SalesOrderItemComboCreateMutation(variable);
              fetch_key = 'salesOrderItemComboCreateMutation'
          }else{
              mutation_query = createPosOrderItem(variable)
              fetch_key = "salesOrderCreateMutation"
          }
          const respones = await axiosInstance.post(`/itemmaster/graphql`, {
          query: mutation_query
          })
          const result = respones['data']['data'][fetch_key]
          if (result.success) {
          // setisDataSave(true)
          let responseData = Array.isArray(result.SalesoderInstance)
              ? result.SalesoderInstance
              : [result.SalesoderInstance]
              const serialNumbers = responseData[0]?.['serial']?.map(item => item.serialNumber);
              const joinedSerialNumbers = serialNumbers ? serialNumbers.join(', ') : '';
              let updated_res_data = {
                index: Number(responseData[0]?.id),
                id: responseData[0]?.id, 
                partCode : responseData[0]?.partCode?.['itemPartCode'],
                partNumber: responseData[0]?.partCode,
                partName : responseData[0]?.partCode?.['itemName'],
                Description : responseData[0]?.partCode?.description,
                qty : Number(responseData[0]?.qty),
                rate : responseData[0]?.rate, 
                uom : {value: responseData[0]?.uom?.id, label: responseData[0]?.uom?.name},
                Amount  : responseData[0]?.['amount'],
                BatchNo :  "",
                serialNumber: responseData[0]?.['serial'],
                Serialnum :  joinedSerialNumbers,
                getRate :  responseData[0]?.['gstRate'],
                createdby :  responseData[0]?.['createdBy'],
                display:  responseData[0]?.['display'],
            }
          if (posItemData.length > 0) {
              let existing_data = [...posItemData]
              existing_data = existing_data.filter(item => Number(item.partNumber.id) !== Number(responseData[0]?.partCode.id))
              setPosItemData([updated_res_data, ...existing_data]);
          } else {
              setPosItemData(responseData)
          }
          toast.success('Saved', {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light'
          })
          if (
              Number(discountPercentageInValue) > 0 ||
              Number(discountValue) > 0 ||
              Number(discountTotalValue) > 0
          ) {
              clearAllDiscount()
          }
          return true
          } else {
          console.log(result.errors)
          return false
          }
      } catch (error) {
          console.log(error)
          return false
      }
    } else {
    toast.error(setError, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce
    })
    return false
    }
}

// return null ; Apply the discount percentage
export const saveOverallDiscountPercentage = async (percentagevalue, posItemData, setItemDetialData, userId) => {
  if (posItemData.length > 0) {
    posItemData.map(async row => {
      const rateOfItem = row.rate
      const discountValueForPerItem = callOverallDiscountPercentage(percentagevalue, rateOfItem)
      const finalamount = row.qty * discountValueForPerItem
      const savedata = {
        id: Number(row['id']),
        partCode: Number(row['partNumber']['id']),
        description: row['Description'].replace(/"/g, '\\"'),
        qty: row['qty'] ? Number(row['qty']) : 1,
        rate: row['rate'] ? Number(row['rate']) : 0,
        gstRate: row['getRate'] ? Number(row['getRate']) : '',
        uom: row['uom'] ? Number(row['uom']) : '',
        batch: row['batchNumber']?.['value'] ? Number(row['batchNumber']?.['value']) : '',
        amount: finalamount ? `${finalamount}` : '',
        discountValueForPerItem: `${discountValueForPerItem}`,
        discountPercentage: Number(percentagevalue),
        finalValue: '',
        DiscountValue: '',
      }
      row = {...row, FnAmount: finalamount ? `${finalamount}` : '',
      discountValueForPerItem: `${discountValueForPerItem}`,
      discountPercentage: Number(percentagevalue)}
      // const EditData = togettax(savedata, row['gstRate'])
      let variable = removeEmptyValueInObject(savedata) 
      await SaveWithDisCount(variable, setItemDetialData, row)
    })
  }
}

// return null ; Apply the discount Value
export const callOverallDiscountValue = async (totalToSubtract, posItemData, setItemDetialData, userId) => {
  if (posItemData.length > 0) {
    posItemData.map(async row => {
      // Calculate the total value
      const totalValue = posItemData.reduce((sum, row) => sum + (Number(row.qty) * Number(row.rate)), 0)
      const totalCheckedValue = totalValue
      // const totalCheckedValue = TotalWithTaxValue
      // ? TotalWithTaxValue
      // : totalValue
      // Calculate the reduction for each item
      const reductions = posItemData.map( row => (row.rate / totalCheckedValue) * totalToSubtract )
      // Apply the discounts and update the rows
      posItemData.map(async (row, index) => {
      const discountedRate = row.rate - reductions[index]
      // Update the row properties
      const savedata = {
        id: Number(row['id']),
        partCode: Number(row['partNumber']['id']),
        description: row['Description'].replace(/"/g, '\\"'),
        qty: row['qty'] ? Number(row['qty']) : 1,
        rate: row['rate'] ? Number(row['rate']) : 0,
        gstRate: row['getRate'] ? Number(row['getRate']) : '',
        uom: row['uom'] ? Number(row['uom']) : '',
        batch: row['batchNumber']?.['value'] ? Number(row['batchNumber']?.['value']) : '',
        amount: (Number(discountedRate) * row.qty).toFixed(2),
        discountValueForPerItem: discountedRate.toFixed(2),
        discountPercentage: '',
        finalValue: '',
        discountValue: reductions[index].toFixed(2),
      }
      // const EditData = togettax(savedata, row['gstRate'])
      row = {...row, FnAmount: (Number(discountedRate) * row.qty).toFixed(2),
      discountValueForPerItem: discountedRate.toFixed(2),
      discountValue: reductions[index].toFixed(2)}
      let variable = removeEmptyValueInObject(savedata)  
      await SaveWithDisCount(variable, setItemDetialData, row)
      })
    })
  }
}

// retun null ; Apply the set total discount
export const callFinalTotalDiscount = (finalTotal, posItemData, setItemDetialData, userId, TotalWithTaxValue) => {
  if (posItemData.length > 0) { 
    posItemData.map(async (row, index) => {
      // Calculate the total value of items
      const totalValue = posItemData.reduce((sum, item) => sum + (Number(row.qty) * Number(row.rate)), 0)
      // Round the finalTotal to 2 decimal places
      const roundedFinalTotal = parseFloat(Number(finalTotal).toFixed(2));

      // Calculate the totalCheckedValue
      const totalCheckedValue = TotalWithTaxValue
        ? parseFloat(Number(TotalWithTaxValue).toFixed(2))
        : parseFloat(Number(totalValue).toFixed(2))
      // Calculate the ratio of each item's value to the total value
      const ratios = posItemData.map(item => item.rate / totalCheckedValue)
      // Calculate the reduction for each item
      const reductions = ratios.map(
        ratio => ratio * (totalCheckedValue - roundedFinalTotal)
      )
      // Create an array of promises for the API calls
      const savedata = {
        id: Number(row['id']),
        partCode: Number(row['partNumber']['id']),
        description: row['Description'].replace(/"/g, '\\"'),
        qty: row['qty'] ? Number(row['qty']) : 1,
        rate: row['rate'] ? Number(row['rate']) : 0,
        gstRate: row['getRate'] ? Number(row['getRate']) : '',
        uom: row['uom'] ? Number(row['uom']) : '',
        batch: row['batchNumber']?.['value'] ? Number(row['batchNumber']?.['value']) : '',
        amount: ((row.rate - reductions[index]) * row['qty']).toFixed(2),
        discountValueForPerItem: reductions[index].toFixed(2),
        discountPercentage: '',
        finalValue: (row.rate - reductions[index]).toFixed(2),
        discountValue: '',
      }
      row = {...row, FnAmount: ((row.rate - reductions[index]) * row['qty']).toFixed(2),
        discountValueForPerItem: reductions[index].toFixed(2),
        finalValue: (row.rate - reductions[index]).toFixed(2)}
      // const EditData = togettax(savedata, row['gstRate'])
      let variable = removeEmptyValueInObject(savedata) 
      await SaveWithDisCount(variable, setItemDetialData, row)
      // const updatePromises = posItemData.map(async (item, index) => {
      // })
    })
  }
}

// return null ; Save with discount
async function SaveWithDisCount (variable, setPosItemData, data_list, isDup = false) {
  if(variable.includes('id: NaN')){
    toast.error('Please save combo items to apply discounts!',
    {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Bounce
  }
    )
  }else{
    try {
      const respones = await axiosInstance.post(`/itemmaster/graphql`, {
        query: SalesOrderItemComboCreateMutation(variable)
      })
      const result = respones['data']['data']['salesOrderItemComboCreateMutation']
      if (result.success) {
        let responseData = Array.isArray(result.SalesoderInstance)
          ? result.SalesoderInstance
          : [result.SalesoderInstance] 
          if(setPosItemData){
            setPosItemData(prevData =>{
              return prevData.map(item =>{
                return item.id === responseData[0].id ? data_list : item
              })
            })
          }
          
       
      } else {
        let listOfError = ShowErrorNoties(result.errors)
        toast.error(listOfError, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      }
    } catch (error) {
      let listOfError = ShowErrorNoties(error)
        toast.error(listOfError, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
    }
  }

}

// return Number; to calculate the Discount Value
function callOverallDiscountPercentage (percentage, price) {
  const percentagevalue = (Number(price) / 100) * Number(percentage)
  const finalAmount = price - percentagevalue
  return finalAmount
}

const itemComboClearDiscount = (qty, posItemData) => {
  let setItemDetialData = null
  if (posItemData.length > 0) { 
    posItemData.map(async (row, index) => {
      const savedata = {
        id: Number(row['id']),
        partCode: Number(row['partNumber']['id']),
        description: row['Description'].replace(/"/g, '\\"'),
        qty: row['qty'] ? Number(row['qty']) : 1,
        rate: row['rate'] ? Number(row['rate']) : 0,
        gstRate: row['getRate'] ? Number(row['getRate']) : '',
        uom: row['uom'] ? Number(row['uom']) : '',
        batch: row['batchNumber']?.['value'] ? Number(row['batchNumber']?.['value']) : '',
        amount: ((row.rate) * (row['qty']*qty)).toFixed(2),
        discountValueForPerItem: 0,
        discountPercentage: 0,
        finalValue: 0,
        discountValue: 0,
      }
      let variable = removeEmptyValueInObject(savedata) 
      await SaveWithDisCount(variable, setItemDetialData, row)
    })
  }
}


export const applyDiscountOverall = (posItemData, qty, TotalWithTaxValue, userId, discount_percentage, discount_value, final_value, is_clear) => {
  if(Number(discount_percentage) > 0){
      saveOverallDiscountPercentage(discount_percentage, posItemData, userId)
  }else if(Number(discount_value) > 0){
      callOverallDiscountValue(discount_value * qty, posItemData, userId)
  }else if(Number(final_value) > 0){
      callFinalTotalDiscount(final_value * qty, posItemData, userId, TotalWithTaxValue)
  }else if(is_clear){
      itemComboClearDiscount(qty, posItemData)
  }
}


export const convertToDateTime = (timeString) => {
  const currentDate = new Date();
  // Split the time string into parts
  const [hours, minutes, seconds] = timeString.split(' : ').map(Number);
  
  // Set the time part using the extracted values
  currentDate.setHours(hours, minutes, seconds, 0);
  return currentDate;
}


export const calculateMinutesBetweenTime = (startDateTime, startEndTime) => {
  if(startDateTime && startEndTime){
    if(startEndTime > startDateTime){
      const time1 = startDateTime.getTime();
      const time2 = startEndTime.getTime();
    
      // Calculate the difference in milliseconds
      const differenceInMilliseconds = Math.abs(time2 - time1);
    
      // Convert milliseconds to minutes
      const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
    
      return Math.round(differenceInMinutes);
    }else{
      return NaN
    } 
  }else{
    return NaN
  }

}

// function calculateMinutesBetweenDates(date1, date2) {
//   const date1Obj = new Date(date1);
//   const date2Obj = new Date(date2);

//   // Get the difference in milliseconds
//   const differenceInMs = date2Obj - date1Obj;

//   // Convert milliseconds to minutes
//   const differenceInMinutes = differenceInMs / (1000 * 60);

//   return differenceInMinutes;
// }

export function convertToIST(isoString) {
  const date = new Date(isoString);

  // Get the time components and convert to IST
  const options = { timeZone: 'Asia/Kolkata', hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' };
  const istTime = date.toLocaleTimeString('en-GB', options);

  return istTime.replace(/:/g, ' : ');
}