export const EmployeeFormEditQuery = (data) => {
    return `query MyQuery {
    allEmployee(${data}) {
        items {
            aadhaarNo
            altMobile
            bankAccountNo
            bankName
            branch
            designation
            educationQualification
            email
            employeeId
            employeeName
            id
            ifscCode
            mobile
            panNo
            remark
            document{
                id
                documentFile
            }
            userProfile {
            id
            image
            }
            permanentAddress {
            addressLine1
            addressLine2
            addressType
            city
            country
            default
            id
            pincode
            state
            }
            presentAddress {
            addressLine1
            addressLine2
            addressType
            city
            country
            default
            id
            pincode
            state
            }
            userId {
            id
            username
            }
            department {
                id
                name
            }
            createdBy{
                id
                username
            }
        }
    }
}`
}

export const employeeQuery = (data) =>{

    return `query MyQuery {
    allEmployee(${data}) {
        items {
              id
            employeeName 
        }
    }
}`

}

export const employeeOptionQuery=(data)=>{
return  `
query MyQuery {
  allEmployee(${data}) {
    items {
      id
      employeeId
      employeeName
      userProfile{
        image
      }
    }
  }
}`

}
