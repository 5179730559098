import React from 'react'
import logo from "../../img/maxnik_Logo.png"
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'


const Dashboard = () => {
  return (
     <>

        <div className='container-fluid' style={{width:"100%", position:"relative"}}>
            <div className='' style={{position:"fixed", top:"0", left:"0", width:"10%", zIndex:"20"}}>
                <SideNavbar/>
            </div>
            <div className='d-flex align-items-center' style={{height:"100vh"}}>
                <img src={logo} alt='Staan'/>
            </div>
        </div>
        {/* <div className='container-fluid d-flex align-items-center' >
            <img src={logo} alt='Staan'/>

        </div> */}
     
     </>
  )
}

export default Dashboard