import React, { useState } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableView from '../../Packages/Custom/DataTable'
import DePartMentModel from './models/DePartMentModel'

const Department = () => {
    const [isLoad, setLoad] = useState(false)
    const [departmentIsShowing, setDepartmentIsShowing] = useState()
    const [departmentEditData, setDepartmentEditData] = useState(null)
    const CustomViewModalComponent = (data)=>{
        setDepartmentIsShowing(true) 
        setDepartmentEditData(data);
      }
    const headers = [
        {
          header: 'Department No',
          field: 'No', 
          flex: 10,
        //   query: 'accountsMasterOptionQueryForTable',
          inputType: 'text',
          renderComponent: CustomViewModalComponent,
        },
        {
          header: 'Department Name',
          field: 'name',  
          flex: 10,
        //   query: 'accountGroupOptionforTable',
        //   inputType: 'text', 
        },
        {
          header: 'Department Head',
          field: 'departmentHeadUserId', 
          label: "username",
          flex: 10, 
          align: 'center', 
        //   option: true,
        //   bool: true,
        //   inputType: 'text',
        },
        
      ]
     
    const CustomAddModalComponent = () => {
        setDepartmentIsShowing(true)
    }
  return (
    <>
    <div className='container-fluid index_page_aliment_warp'>
      <div className='index_page_aliment_sidebar'>
        <SideNavbar />
      </div>
      <div className='index_page_aliment_table'>
          <TableView
            headers={headers}
            query={"DepartmentTableQuery"}
            addComponent={CustomAddModalComponent}
            loadComponent={isLoad}
            titleName={"Department"}
            heightAdjust={"40rem"}
            permission={"Save_Department"}
          />
      </div>
    </div>
    <DePartMentModel
    departmentEditData = {departmentEditData}
    setDepartmentEditData = {setDepartmentEditData}
    departmentIsShowing = {departmentIsShowing}
    setDepartmentIsShowing = {setDepartmentIsShowing}
    setLoad = {()=>{setLoad(!isLoad)}}
    />
    
    </>
  )
}

export default Department