import React, { useState }from 'react';
import Modal from 'react-bootstrap/Modal';
import { DateRange } from 'react-date-range';

const CustomDatePicker = ({show, close, handleGetSelectedDate}) => {
    const [reportDate, setReportDate] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ])

    const handleDatePicker = (item) => {
        setReportDate([item.selection])
        handleGetSelectedDate(item.selection)
    }
    
    return(
        <>
            <Modal
                show={show} 
                onHide={close}
                size='md'
            >
                <Modal.Header closeButton></Modal.Header>

                <DateRange
                    editableDateInputs={true}
                    onChange={item => handleDatePicker(item)}
                    moveRangeOnFirstSelection={false}
                    ranges={reportDate}
                />
            </Modal>
        </>
    )
}


export default CustomDatePicker;