
export const HandleDuplicateData = (prev, current) => {
    let existing_data = []
    existing_data = existing_data.concat(prev)
    current.forEach(currentItem => {
    const matchFound = prev?.some(prevItem => 
        currentItem?.parent === prevItem?.parent && currentItem?.partno?.id === prevItem?.partno?.id
    );

    if (!matchFound) {
        existing_data.push(currentItem);
    }
    });
    return existing_data
}

export const RemoveElementsBasedonParent = (data_list, key) => {
    return data_list.filter(obj => obj.parent !== key);
}

export const UpdateQtyBasedOnPartCode = (combinedData) => {
    const uniqueData = combinedData.reduce((acc, current) => {
        const currentQty = parseFloat(current.qty);
        if (acc.has(current.partNumber.id)) {
            const existingItem = acc.get(current.partNumber.id);
            const existingQty = parseFloat(existingItem.qty);
            existingItem.qty = (existingQty + currentQty).toFixed(2);
        } else {
            acc.set(current.partNumber.id, { ...current, qty: currentQty.toFixed(2) });
        }
        return acc;
    }, new Map());

    return Array.from(uniqueData.values());
}

export const HandleChildBomsAsSubProductionOrder = (item_combo_bom_data) => {
    let sub_po_inhouse = [];
    let sub_po_subcontract = [];
    let raw_materials_list = [];
    item_combo_bom_data.forEach(element => {
        let new_part_data = {...element.itemCombo[0]}
        new_part_data['itemQty'] = element.itemCombo[0]['qty']
        new_part_data['partNumber'] = element.itemCombo[0]['partNo']
        new_part_data['parent'] = element.parent
        if ('itemQty' in new_part_data){
            new_part_data['qty'] = new_part_data['itemQty']
        }
        if(element?.bom?.length > 0){
            new_part_data =  {
                ...new_part_data, 
                'bomOptions': element?.bom,
                'bom': element.bom.map(item => `${item?.bomNo} -- ${item?.bomName} -- ${item?.bomType}`),
                'selectedBom': element.bom.length > 0 ? `${element?.bom[0]?.bomNo} -- ${element?.bom[0]?.bomName} -- ${element?.bom[0]?.bomType}` : '-- Select Bom --', 
            }
            if(element?.bom[0]?.bomType === "MANUFACTURE"){                        
                sub_po_inhouse.push(new_part_data)
            }else{
                new_part_data['selectedSupplier'] = '-- Select Supplier --'
                new_part_data['supplierOptions'] = element?.bom[0]?.['supplier']?.map(item => item?.companyName)
                new_part_data['supplierOptionsList'] = element?.bom[0]?.['supplier']
                sub_po_subcontract.push(new_part_data)
            }
        }else{
            raw_materials_list.push({...new_part_data, 'bom': null})
        }
    });
    return [sub_po_inhouse, sub_po_subcontract, raw_materials_list]
}

export const MergeDuplicateItemsIntoOne = (data_list) => {
    let result_data = []
    if(data_list){
        const aggregated = {};
        data_list?.forEach(item => {
            const partId = item.partNo.id;
            const qty = parseFloat(item.itemQty); 
            if (aggregated[partId]) {
                aggregated[partId].itemQty += qty;
              } else {
                aggregated[partId] = { ...item, itemQty: qty }; 
              }
        });
        data_list?.forEach(item => {
            const partId = item.partNo.id;
            if (aggregated[partId]) {
              item.itemQty = aggregated[partId].itemQty.toString();  
            }
        });
        const seen = new Set();
        result_data = data_list?.filter(item => {
        const partId = item.partNo.id;
        if (seen.has(partId)) {
            return false;
        } else {
            seen.add(partId);
            return true;
        }
        });
    }
    return result_data
}

export const HandleComboChild = async (item_combo_bom_data) => {
    let data_list = [];
    item_combo_bom_data.forEach(element => {
        let new_part_data = {...element.itemCombo[0]}
        new_part_data['itemQty'] = element.itemCombo[0]['qty']
        new_part_data['partNumber'] = element.itemCombo[0]['partNo']
        new_part_data['parent'] = element.parent
        let total_stock = 0
        if(new_part_data?.partNumber?.itemstockSet){
            for (let stock_data of new_part_data?.partNumber?.itemstockSet) {
                total_stock += parseFloat(stock_data.currentStock);
            }
        }
        new_part_data['totalStock'] = total_stock
        if ('itemQty' in new_part_data){
            new_part_data['qty'] = new_part_data['itemQty']
        }
        if(element?.bom?.length > 0){
            new_part_data =  {
                ...new_part_data, 
                'bomOptions': element?.bom,
                'bom': element.bom.map(item => `${item?.bomNo} -- ${item?.bomName} -- ${item?.bomType}`),
                'selectedBom': element.bom.length > 0 ? `${element?.bom[0]?.bomNo} -- ${element?.bom[0]?.bomName} -- ${element?.bom[0]?.bomType}` : '-- Select Bom --', 
            }
            if(element?.bom[0]?.bomType === "MANUFACTURE"){                        
                data_list.push(new_part_data)
            }else{
                new_part_data['selectedSupplier'] = '-- Select Supplier --'
                new_part_data['supplierOptions'] = element?.bom[0]?.['supplier']?.map(item => item?.companyName)
                new_part_data['supplierOptionsList'] = element?.bom[0]?.['supplier']
                data_list.push(new_part_data)
            }
        }else{
            new_part_data['selectedSupplier'] = '-- Select Supplier --'
            new_part_data['supplierOptions'] = element?.partNumber?.['supplierData']?.map(item => item?.companyName)
            new_part_data['supplierOptionsList'] = element?.partNumber?.['supplierData']
            data_list.push({...new_part_data, 'bom': null})
        }
    });
    return data_list;
}

export const SortRawMaterials = (arr) => {
    try{
       let data =  arr.sort((a, b) => {
            const splitA = a?.['orderNo']?.split('-').map(val => val.padStart(3, '0'));
            const splitB = b?.['orderNo']?.split('-').map(val => val.padStart(3, '0'));
            if (splitA[0] < splitB[0]) return -1;
            if (splitA[0] > splitB[0]) return 1;
            
            if (splitA[1] === undefined) return -1;
            if (splitB[1] === undefined) return 1;
            
            return splitA[1] - splitB[1];
        });
        return data
    }catch(e){
        console.log(e)
        return arr
    }
      
}