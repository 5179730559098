import React from 'react';
import Modal from 'react-bootstrap/Modal';

const TaxModel = ({isShow, setIsShow, igstTax , sgstTax, cgstTax}) => {
    const TaxDisplay = ({ taxType, taxData }) => {
        if (!taxData) return null; // Return null if no tax data
    
        return (
            <div className=''>
                {(() => {
                            const parsedTax = typeof taxData === 'string' ? JSON.parse(taxData) : taxData;

                            if (typeof parsedTax === 'object' && parsedTax !== null) {
                                return Object.entries(parsedTax).map(([key, value]) => (
                                    <div className='row' key={key}>
                                        <div className='col-5'>{`${taxType} @ ${key}%`}</div>
                                        <div className='col-5'>{` : ₹  ${value}`}</div>
                                    </div>
                                ));
                            } 
                        })()}
            </div>
        );
    };

    return (
        <Modal show={isShow} onHide={()=>{setIsShow(false)}}>
            <Modal.Header closeButton>
                <Modal.Title>Tax</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row mx-1 '>
                    <TaxDisplay taxType="IGST" taxData={igstTax} />
                    <TaxDisplay taxType="SGST" taxData={sgstTax} />
                    <TaxDisplay taxType="CGST" taxData={cgstTax} />
                </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
            </Modal>
    )
}

export default TaxModel