export const rolelist = (data) => {
      return `
       query MyQuery {
  allRole(${data}){
    items {
      id
      roleName
    }
  }
}
      `
    }

export const userManagementCreateMutation=(data)=>{
    // createdBy: 10 department: 10 id: "" modifiedBy: 10
    // role: 1 user: 10 lastName: "" ,firstName: ""
    return `
    mutation MyMutation {
            userManagementCreateMutation(
            ${data}
            ) {
            errors
            success
            }
        }`
}

  
export const profileCreateMutation = (data) => {
  // allowedPermission:[11] createdBy: 10 , description: "" , id: "" modifiedBy: 10
  // profileName: ""
  return `
        mutation MyMutation {
        profileCreateMutation(${data}) {
          errors
          success
        }
      }
  `
}
  
  
export const allowedPermissionCreateMutation = (data) => {
  // createdBy: 10 id: "" modelName: "" modifiedBy: 10 permissionOptions: 10
  return `
        mutation MyMutation {
        allowedPermissionCreateMutation(${data}) {
          allowedPermissionInstance{
          id
        }
          errors
          success
        }
      }
  `}
  
export const allPermissionModel = (data) => {
  return ` 
query MyQuery {

  allPermissionModel {
  id
    modelName
    permissionOptions {
      id
      optionsName
    }
  }
}
  `}

export const allPermissionModelforOptions = (data) => { 
  // profileName: "t"
  return `
  query MyQuery {
  allProfile(${data}) {
    items {
      id
      profileName
    }
  }
}
  `}

export const userPermissionforOptions = (data) => {
  // isSales: true userName:"j"
  return `
  query MyQuery {
  userPermission(${data}) {
    items {
      userId {
        id
        username
      }
    }
  }
}
  `}