import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { TbHistory } from 'react-icons/tb'
import Select from 'react-select'
import { ToastContainer } from 'react-toastify'
import * as QueryData from '../../../GraphQLQueries/ItemmasterQuery'
import axiosInstance from '../../../api/axoiss'
import Can from '../../../components/authorizationComponents/Can'
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation'
import showErrorToast from '../../../components/notifications/react-toastify/toast'
import DataContext from '../../../context/ItemMasterContext'
import {
  OnchangeGetData,
  ShowErrorNoties,
  checkChangesInputs,
  currentPath,
  customSelectStyle,
  removeEmptyValueInObject
} from '../../../utils/Utils'
import { itemGroupSchema } from '../../../validations/itemmaster'
import HistoryData from './HistoryData'

const AddItemGroup = ({ itemGroupEditId, setitemGroupEditId, setLoad }) => {
  const {
    itemGroupAdd,
    handleitemGroupClose,
    setItemGroupSelect,
    userId,
    handltoastDeleteConfomationShow,
    historyIds,
    setHistoryIds
  } = useContext(DataContext)
  const [initialGroup, setinitialGroup] = useState({
    Name: '',
    Hsn: '',
    Group: '',
    HistoryDetails: '',
    modified_by: '',
    createdby: ''
  })
  const [formKey, setFormKey] = useState(0)
  const [edit, setEdit] = useState(true)
  const [itemGroupValue, setItemGroupValue]= useState({selectHsn:{ value: '', label: '' },
    selectParentGroup:{value: '', label: ''}
  })
  const currentURL = currentPath(window.location.href)
  const [deleteData, setdeleteData] = useState({ Name: '', id: '' })
  const [hsnOptions, setHsnOptions] = useState([])
  const [itemGroupNameOption, setItemGroupNameOptions] = useState([])
  const url = '/itemmaster/itemGroup'


  useEffect(() => {
    // return null; after fetch data update input
    async function fetchData (id) {
      if (id) {
        try{
          const response = await axiosInstance.post(`/itemmaster/graphql`, {query: QueryData.ItemGroupEdit(id)});
          const reponseData = response?.data?.data?.itemGroupsName?.items?.[0]
          if (reponseData){
            setinitialGroup({
              Name: reponseData?.['name'],
              Hsn: reponseData?.['hsn'] ? reponseData?.['hsn']?.['id']
              : '',
              Group: reponseData?.['parentGroup']
              ? reponseData?.['parentGroup']?.['id']
              : '',
              HistoryDetails:reponseData?.['historyDetails'],
              modified_by: '',
              createdby: reponseData?.['createdBy']?.['id']
            })
            setItemGroupValue(prve=>({...prve, selectHsn:{
              value: reponseData?.['hsn']?.['id'],
              label: reponseData?.['hsn']?.['hsnCode']
            }, selectParentGroup:{value:reponseData?.['parentGroup']?.['id'],
              label:reponseData?.['parentGroup']?.['name']}}))
            setFormKey(prevKey => prevKey + 1)
          }
        } catch(error){

        }
        
      }
    }
    if (itemGroupEditId === '') {
      setinitialGroup({
        Name: '',
        Hsn: '',
        Group: '',
        modified_by: '',
        createdby: ''
      })
      setEdit(false)
    } else {
      setEdit(true)
    }

    if (itemGroupEditId) {
      fetchData(itemGroupEditId.id)
    }
  }, [itemGroupEditId])

  // return null; reset the initial value
  function reset_form () {
    if (itemGroupEditId) {
      setitemGroupEditId('')
    }
    setItemGroupValue(prve=>({...prve, selectHsn:{value: "", label: ""} , 
      selectParentGroup:{value: "", label: ""}})) 
    setinitialGroup({ Name: '',
      Hsn: '',
      Group: '',
      HistoryDetails: '',
      modified_by: '',
      createdby: ''})
  }

  // return null; Submit the value
  const handleSubmit = async (
    values,
    { setSubmitting, resetForm}
  ) => {
    const save_itemGroup = {
      id: itemGroupEditId ? itemGroupEditId.id : '',
      name: values['Name'],
      parentGroup: values['Group'] ? Number(values['Group']) : '',
      hsn: values['HSN'] ? Number(values['HSN']) : '',
      modifiedBy: itemGroupEditId ? Number(userId) : '',
      createdBy: itemGroupEditId
        ? initialGroup.createdby
          ? Number(initialGroup.createdby)
          : Number(userId)
        : Number(userId)
    }
    let allow_null = ['parentGroup', 'hsn', 'id', 'modifiedBy']
    let variables = removeEmptyValueInObject(save_itemGroup, allow_null)
    const mutation =
      `
      mutation itemGroup_{
        itemGroupsNameCreateMutation(` +
      variables +
      `) {
          ItemGroupsName {
            id
            name
          }
          success
          errors
        }
      }
    `
    const response = await axiosInstance.post('/itemmaster/graphql', {
      query: mutation
    })
    if (response.data.data.itemGroupsNameCreateMutation.success) {
      const responseData =
        response.data.data.itemGroupsNameCreateMutation.ItemGroupsName
        setItemGroupSelect({
          id: responseData['id'],
          lable: responseData['name']
        })
        if (setitemGroupEditId){
          setitemGroupEditId('')
        }
        resetForm()
        handleitemGroupClose()
        reset_form() 
        if (currentURL === 'item_Group') {
          setLoad()
        }
        showErrorToast(true, "success", "", "Saved Successfully.")
        setSubmitting(false)
    } else {
      let error = response.data.data.itemGroupsNameCreateMutation.errors
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, "error", errorData, "")
    }
  }

  // clear confomations
  const [waringToCloseModel, setWaringToCloseModel] = useState(false)

  let currentValues = {}

  // Confirmation befor close
  const WaringToClose = () => {
    // return; if use click yes reset the data
    const clearTheData = () => {
      handleitemGroupClose()
      reset_form()
      setWaringToCloseModel(false)
    }
    return (
      <>
        <Modal
          show={waringToCloseModel}
          onHide={()=>{ setWaringToCloseModel(false)}}
          backdrop='static'
          size='sm'
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to leave without saving?</Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-outline-warning btn-sm'
              onClick={()=>{ setWaringToCloseModel(false)}}
            >
              No
            </button>
            <button
              type='button'
              className='btn btn-outline-success btn-sm'
              onClick={clearTheData}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
  // before close any changes in form it call the Confirmation
  function Close () {
    let isDuplicate = checkChangesInputs([initialGroup, currentValues])
    if (!isDuplicate) {
      handleitemGroupClose()
      reset_form()
    } else {
      setWaringToCloseModel(true)
    }
  }
  // return null; befor delete call the Confirmation to delete
  const handledeleteConfomation = data => {
    handltoastDeleteConfomationShow()
    setdeleteData({
      Name: data.name,
      id: data.id
    })
  }
  // return null ; after delete close the Form
  const handleDeletedId = deleted_id => {
    if (deleted_id) {
      handleitemGroupClose()
      reset_form()
      setEdit(false)
      setLoad()
    }
  }

  // getting data for react select
  async function getOptions (e, queryName, optionName) {
    let search_term = e.trim().replace(/"/g, '\\"')
    if (queryName === 'HsnOptionQueryWithFiliter' && search_term) {
      let hsn_query_argument = `hsnCode: ${search_term}, `
      let responseData = await OnchangeGetData(
        QueryData[queryName](hsn_query_argument)
      )
      if (responseData.success) {
        setHsnOptions(responseData.data[optionName]['items'])
      }
    } else {
      setHsnOptions([])
    }
    if (queryName === 'ItemGroupOptionQueryWithFiliter' && search_term) {
      let responseData = await OnchangeGetData(
        QueryData[queryName](String(search_term))
      )
      if (responseData.success) {
        setItemGroupNameOptions(responseData.data[optionName]['items'])
      }
    } else {
      setItemGroupNameOptions([])
    }
  }
  return (
    <>
      {WaringToClose()}
      <HistoryData historyIds={historyIds} setHistoryIds={setHistoryIds} />
      <ToastContainer />
      <Modal
        show={itemGroupAdd}
        onHide={Close}
        backdrop='static'
        keyboard={false}
      >
        <div className='row'>
          <div className='col-5 m-3' style={{ color: '#5CADA9' }}>
            {' '}
            <Modal.Title>
              <i
                className='fa-solid fa-arrow-left fa-sm me-2'
                onClick={() => {
                  Close()
                }}
              ></i>
              Item Group
            </Modal.Title>
          </div>
          <div className='col-6 mt-4 text-end'>
            <Can I={'Edit'} a={'Item_Group'}>
              <i
                className='fa-solid fa-pen fs-5 text-primary pe-3'
                hidden={!(itemGroupEditId && edit)}
                onClick={() => {
                  setEdit(!edit)
                }}
              ></i>
            </Can>
            <Can I={'Delete'} a={'Item_Group'}>
              <i
                className='fa-solid fa-trash text-danger fs-5 pe-3 ps-3  '
                hidden={!(itemGroupEditId && edit)}
                onClick={() => {
                  handledeleteConfomation(itemGroupEditId)
                }}
              ></i>
            </Can>
            <Can I={'Historys'} a={'Item_Group'}>
              <TbHistory
                type='button'
                className='fs-4 ms-3'
                data-bs-toggle='offcanvas'
                hidden={!(itemGroupEditId && edit)}
                data-bs-target='#History'
                onClick={() => {
                  setHistoryIds(initialGroup.HistoryDetails)
                }}
              />
            </Can>
          </div>
          <hr />
        </div>
        <Modal.Body>
           
          <Formik
            initialValues={initialGroup}
            validationSchema={itemGroupSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            key={formKey}
          >
            {({ errors, touched, isSubmitting, values, setFieldValue }) => {
              currentValues = values
              return (
                <Form>
                  <div className='row'>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        id='Name'
                        name='Name'
                        disabled={itemGroupEditId && edit}
                        className='w-100 staan-form-input'
                      />
                      <label
                        htmlFor='Name'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Name
                      </label>
                      {touched.Name && errors.Name && (
                        <small>{errors.Name}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Select
                        name='Hsn'
                        isClearable
                        onInputChange={e => { getOptions(e, 'HsnOptionQueryWithFiliter', 'hsn')}}
                        options={hsnOptions.map(item => ({
                          value: item.id,
                          label: item.hsnCode
                        }))}
                        isSearchable={true}
                        isDisabled={itemGroupEditId && edit}
                        value={itemGroupValue?.selectHsn}
                        onChange={option => {
                          setFieldValue('HSN', option ? option.value : null)
                          setItemGroupValue(prve=>({...prve, selectHsn:{
                            value: option?.value , label:option?.label
                          }}))
                        }}
                        className='my-3'
                        styles={customSelectStyle}
                      />
                      <label
                        htmlFor='HSN'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        HSN
                      </label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 staan-input-group'>
                      <Select
                        name='Group'
                        onInputChange={e => {
                          getOptions(
                            e,
                            'ItemGroupOptionQueryWithFiliter',
                            'itemGroupsName'
                          )
                        }}
                        value={itemGroupValue?.selectParentGroup}
                        isDisabled={itemGroupEditId && edit}
                        options={itemGroupNameOption.map(item => ({
                          value: item.id,
                          label: item.name
                        }))}
                        onChange={option => {
                          setFieldValue('Group', option ? option.value : '')
                          setItemGroupValue(prve=>({...prve, selectParentGroup:option? option:{value: '', label: ''}}))
                        }}
                        className='my-3'
                        styles={customSelectStyle}
                        isClearable
                      />
                      <label
                        htmlFor='Group'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Parent Group
                      </label>
                      {touched.Group && errors.Group && (
                        <small>{errors.Group}</small>
                      )}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='text-end'>
                      <button
                        type='button'
                        className='btn btn-outline-danger btn-sm me-2'
                        onClick={() => {
                          Close()
                        }}
                      >
                        Cancel
                      </button>
                      <Can allow_list={['Save_Item_Group', 'Edit_Item_Group']}>
                        <button
                          type='submit'
                          className='px-3 btn btn-sm btn-outline-success shadow-sm'
                          disabled={isSubmitting}
                        >
                          Save
                        </button>
                      </Can>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {deleteData.Name && (
        <DeleteConformation
          handleDeletedId={id_ => {
            handleDeletedId(id_)
          }}
          deleteData={deleteData}
          url={url}
        />
      )}
    </>
  )
}

export default AddItemGroup
