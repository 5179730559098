export const QueryFilter = (filters, bool_values, no_string_fields) => {
  let query = ''
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  } else {
    query = ''
  }
  return query
}

export const QueryFilterWithArray = (
  filters,
  bool_values,
  no_string_fields,
  array_field
) => {
  let query = ''
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (array_field.includes(key)) {
        query += `, ${key}: [${value}]`
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  } else {
    query = ''
  }
  return query
}

export const ItemMasterQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending', 'id']
  let query = `query MyQuery {
      itemMaster(page:${page}, pageSize:${pageSize} any:${true}`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
      items{
        id
        itemPartCode
        itemName
         category {
          name
        }
         itemGroup{ 
          name
        }
        itemUom{ 
          name
        }
        itemTypes{
          name
        } 
        itemIndicators{
            name
            }
        service
        itemComboBool
        keepStock
        itemActive
      }
      pageInfo{
        totalItems
        hasNextPage
        hasPreviousPage
        totalPages
      }
    }
    }`
  return query
}

export const ItemMasterOptionQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending', 'id']
  let query = `query MyQuery {
      itemMaster(page:${page}, pageSize:${pageSize}`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value?.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value?.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
      items{
        id
        itemPartCode
        itemName
      }
      pageInfo{
        totalItems
        hasNextPage
        hasPreviousPage
        totalPages
      }
    }
    }`
  return query
}

export const UOMOptionQuery = data => {
  let filler = ''
  if (data) {
    filler = `name: "${data}"`
  }
  let query = `query MyQuery {
    uom(pageSize:200, ${filler ? filler : ''} ){
    items{
      id
      name
    }
  }
}`
  return query
}

export const ItemGroupOptionQuery = data => {
  let query
  if (data) {
    query = `query MyQuery {
      itemGroupsName(pageSize:200, name: "${data}"){
      items{
        id
        name
      }
    }
  }`
  } else {
    query = `query MyQuery {
      itemGroupsName(pageSize:200){
      items{
        id
        name
      }
    }
  }`
  }
  return query
}

export const ItemCategoryOptionQuery = () => {
  return `query MyQuery {
    categories{
      items{
        id
        name
      }
    }
    }`
}

export const ItemTypeOptionQuery = () => {
  return `query MyQuery {
    itemType{
      items{
        id
        name
      }
    }
    }`
}

export const ItemIndicatiorOptionQuery = () => {
  return `query MyQuery {
    itemIndicators{
      items{
        id
        name
      }
    }
    }`
}

export const ItemDisplayGroupQuery = part_code => {
  return `query myFirstQuery(partCode: ${part_code}){
    displayGroup{
      items{
        id
        display
      }
  }
}`
}

export const ItemGroupOptionQueryWithFiliter = data => {
  return `query MyQuery($name: String) {
    itemGroupsName(name: $name) {
      items {
        id
        name
      }
    }
  }`
}

export const ItemGroupOptionQueryWithFiliterForTable = (
  page = 1,
  pageSize = 20,
  data
) => {
  let search_term = data?.['name'] ?? data?.['parentGroup']
  let conditions
  if (data?.['name']) {
    conditions = `name: "${search_term}"`
  } else if (data?.['parentGroup']) {
    conditions = `name: "${search_term}"`
  }
  let query =
    `query MyQuery {
    itemGroupsName(` +
    conditions +
    `) {
      items {
        id
        name
      }
    }
  }`
  return query
}

export const HsnOptionQueryWithFiliter = data => {
  return `query MyQuery{ 
    hsn(pageSize:200, ${data}){
      items{
        id
        hsnCode
        gstRates{
          rate
        }
      }
      
    }
  }`
}

export const fetchposStockReport = conderence_id => {
  return `query myquery{
    posStockReport(  conferenceId: ${conderence_id}){
      items{
        sno
        partName
        partNumber
        stockIn
        stockOut
        stockBlance
      }
    }
  }`
}

export const HsnOptionQueryForTable = (page = 1, pageSize = 20, data) => {
  let search_term = data?.['hsnCode']
  let query = `
  query MyQuery{ 
    hsn(pageSize:200, hsnCode: ${search_term}){
      items{
        id
        hsnCode
        }
      }
      
    }`
  return query
}

export const HsnOptionQueryWithFiliterForTable = (
  page = 1,
  pageSize = 20,
  data
) => {
  let search_term = data?.['hsn']
  let query = `query MyQuery{ 
  hsn(pageSize:200, hsnCode: ${search_term}){
    items{
      id
      hsnCode
      gstRates{
        rate
      }
    }
    
  }
}`
  return query
}

// page=1, pageSize=20, data

export const HsnTypeOptionQueryWithFiliter = () => {
  return `query hsnType{
    hsnType{
      items{
        id
        name
      }
    }
}`
}

export const gstRateOptionQueryWithFiliter = () => {
  return `query gstRate{
    gstRate{
      items{
        id
        rate
      }
    }
  }`
}

export const accountsMasterOptionQueryWithFiliter = (
  accountsName,
  accountsType,
  accountsType2
) => {
  let query = `query accountsMaster{
    accountsMaster( accountsName: "${accountsName}", accountsType: "${accountsType}" , accountsType2: "${accountsType2}"){
          items{
            id
            accountsName
          }
        }
    }`
  return query
}

export const accountsMasterOptionQuery = accountsName => {
  let query = `query accountsMaster{
    accountsMaster(pageSize:200, accountsName: "${accountsName}"){
          items{
            id
            accountsName
          }
        }
    }`
  return query
}

export const accountsMasterOptionQueryForTable = (
  page = 1,
  pageSize = 20,
  data
) => {
  let search_term = data?.['accountsName']
    ? data['accountsName']
    : data?.['storeAccount']
      ? data['storeAccount']
      : ''
  let query = `query accountsMaster{
    accountsMaster(pageSize:200, accountsName: "${search_term}"){
          items{
            id
            accountsName
          }
        }
    }`
  return query
}

export const AccountGrouptypeOptionQuery = () => {
  let query = ` query MyQuery {
    accountsGroupType{
      items{
        id
        name
          }
    }
  }`
  return query
}

export const alternateQuery = data => {
  return `query getData{
    alternateUnit(id:${data}){
      id
      addtionalUnit{
        name
        id
      }
      conversionFactor
      fixed
      createdBy{
        id
        username
      }
      modifiedBy{
        id
        username
      }
      
    }
  }`
}

export const itemComboQuery = data => {
  return ` 
    query getValue{
      itemCombo(id:${data}){
        id
        sNo
        partNumber{
          id
          itemPartCode
          itemName
        }
        itemQty
        itemDisplay{
          id
          display
        }
        isMandatory
        
      }
    }`
}

export const finishedGoodsQuery = () => {
  return `query finishedGoods {
        finishedGoods{
            items {
              id
              serialNumber
              partNo{
                id
                itemName
              }
              category
              qty
              unit{
                id
              }
              costAllocation
              remarks
            }
        }
    }`
}

export const rawMaterialQuery = () => {
  return `query rawMaterial {
        rawMaterials{
            items {
              id
              serialNumber
              partNo{
                id
                itemName
              }
              category
              qty
              unit{
                id
              }
              store{
                id
              }
            }
        }
    }`
}

export const scrapQuery = () => {
  return `query scrap {
        scrap{
            items {
              id
              serialNumber
              partNo{
                id
                itemName
              }
              category
              qty
              unit{
                id
              }
              costAllocation
            }
        }
    }`
}

export const routingQuery = () => {
  return `query routing {
        routing{
            items {
              id
              serialNumber
              routeName
            }
        }
    }`
}

export const bomQuery = (page = 1, pageSize = 20, filters, bool_values) => {
  let no_string_fields = [
    'descending',
    'id',
    'totalRawMaterial',
    'status',
    'finishedGoods',
    'modifiedBy'
  ]
  let query = `query bom {
    bom(page:${page}, pageSize:${pageSize}`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
          items {
            id
            bomName
            bomNo
            bomType
            modifiedBy{
              id
              username
            }
            updatedAt
            totalRawMaterial
            status{
              id
              status
            }
            finishedGoods{
              id
              partNo{
                id
                itemPartCode
                itemName
              }
            }
          }
        pageInfo{
          totalItems
          hasNextPage
          hasPreviousPage
          totalPages
        }
      }
  }`
  return query
}

export const bomNumberOptionQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let query = `query MyQuery {
    bom(bomNoContains: "${filters['bomNo']}"){
      items{
        id
        bomNo
      }
    }
  }`
  return query
}

export const finishedGoodsOptionQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  return `query finishedGoods {
    finishedGoods(finishedGoodsName: "${filters['finishedGoods']}"){
        items {
          id
          partNo{
            id
            itemName
          }
        }
    }
  }`
}

export const bomStatusOptionQuery = () => {
  return `query status {
    bomStatus{
        items {
          id
          status
        }
    }
}`
}

export const bomTypeOptionQuery = () => {
  let query = `query MyQuery {
    bomTypeOption{
      items{
        id
        bomType
      }
    }
  }`
  return query
}

export const userOptionQuery = () => {
  return `query useroption {
    User{
        items {
          id
          username
        }
    }
}`
}

export const storeFetchQuery = () => {
  return `query myFirstQuery{
    Store{
      items{
        id
        storeName
        matained
        conference
      }
    }
  }`
}

export const storeFetchWithKeepStockQuery = () => {
  return `query myFirstQuery{
    Store{
      items{
        id
        storeName
        matained
      }
    }
  }`
}

function objectToString(obj) {
  return Object.entries(obj)
    .map(
      ([key, value]) =>
        `${key}: ${typeof value === 'string' ? `"${value}"` : value}`
    )
    .join(', ')
}

export const itemMasterOptionFetchQuery = (
  search_filter = null,
  extra_filter = null
) => {
  let query = {}
  if (search_filter && extra_filter) {
    let filter_str = objectToString(extra_filter)
    query = `query myFirstQuery{
      itemMaster(pageSize :200 , ${search_filter.key}: "${search_filter.value}", ${filter_str}){
        items{
          id
          itemName
          itemPartCode
          description
          itemComboBool
          serial
          batchNumber
          itemMrp
          itemCost
          itemUom{
            id
            name
          }
          category{
            id 
            name
          }
          itemHsn{
            gstRates{
              rate
              
            }
          }
        }
      }
    }`
  } else if (search_filter) {
    query = `query myFirstQuery{
      itemMaster(pageSize :200 ,${search_filter.key}: "${search_filter.value}"){
        items{
          id
          itemName
          itemPartCode
          itemComboBool
          description
          serial
          batchNumber
          itemMrp
          itemCost
          alternateUom{
            id
            addtionalUnit {
              id
              name
            }
          }
          itemUom{
            id
            name
          }
          category{
            id 
            name
          }
          itemHsn{
            gstRates{
              rate
              
            }
          }
        }
      }
    }`
  } else if (extra_filter) {
    let filter_str = objectToString(extra_filter)
    query = `query myFirstQuery{
      itemMaster(pageSize :200 , ${filter_str}){
        items{
          id
          itemName
          itemPartCode
          description
          itemComboBool
          serial
          batchNumber
          itemMrp
          itemCost
          itemUom{
            id
            name
          }
          category{
            id 
            name
          }
          itemHsn{
            gstRates{
              rate
              
            }
          }
        }
      }
    }`
  } else {
    query = `query myFirstQuery{
      itemMaster{
        items{
          id
          itemName
          itemPartCode
          itemComboBool
          itemCost
          itemUom{
            id
            name
          }
          category{
            id 
            name
          }
        }
     }
    }`
  }
  return query
}

export const ItemMasterProductsFetchQuery = (from, search_filter) => {
  let query = `query {
    itemMaster(pageSize: 200, itemTypes: "Product" `
  if (search_filter) {
    query += `, ${search_filter.key}: "${search_filter.value}", `
  }
  if (from) {
    query += ` sourceFrom: "${from}", `
  }
  query += `) {
      items {
        id
        itemName
        itemPartCode
        description
        serial
        batchNumber
        itemMrp
        itemCost
        alternateUom {
          id
          addtionalUnit {
            id
            name
          }
        }
        itemUom {
          id
          name
        }
        category {
          id
          name
        }
        itemHsn {
          gstRates {
            rate
          }
        }
      }
    }
  }`
  return query
}

export const itemMasterDataForPosFetchQuery = search_filter => {
  let query = `query myFirstQuery{
    itemMaster(${search_filter.key}: "${search_filter.value}"){
      items{
        id
        itemName
        itemPartCode
        batchNumber
        itemComboBool
        description
        serial
        batchNumber
        itemMrp
        itemUom{
          id
          name
        } 
        itemHsn{
          gstRates{
            rate
          }
        }
      }
    }
  }`
  return query
}

export const ItemMasterPurchaseOrderFetchQuery = search_filter => {
  let query = `query myFirstQuery{
    itemMaster(${search_filter}){
      items{
        id
        itemName
        itemPartCode
        itemCost
        variation
        description
        purchaseUom{
           id
          name
        }
        alternateUom{
          id
          conversionFactor
          addtionalUnit {
            id
            name
          }
        }
        itemUom{
          id
          name
        }
        description
        category{
          id 
          name
        }
        itemHsn{
          id
          gstRates{
            rate
          }
        }
        itemMrp
      }
    }
  }`

  return query
}

export const uomOptionFetchQuery = () => {
  return `query myFirstQuery{
    uom{
      items{
        id
        name      
      }
    }
  }`
}

export const categoryFetchQuery = () => {
  return `query myFirstQuery{
    categories{
      items{
        id
        name      
      }
    }
  }`
}

export const accountGroupOptionforTable = (page = 1, pageSize = 20, data) => {
  let search_term = data?.['accountsGroupName']
  let query = `query group{
    accountsGroup(accountsGroupName: "${search_term}", pageSize:100){
      items{
        id
        accountsGroupName
        accountsType{
          id
          name
        }
      }
    }
  }`
  return query
}

export const accountGroupOption = data => {
  let query = `query group{
    accountsGroup(accountsGroupName: "${data}", pageSize:50){
      items{
        id
        accountsGroupName
        accountsType{
          id
          name
        }
      }
    }
  }`
  return query
}

export const StoreQuery = (page = 1, pageSize = 20, filters, bool_values) => {
  let no_string_fields = ['descending']
  let query =
    `
  query myStore{
      Store(page:${page}, pageSize:${pageSize} any:${true} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
        items{
          id
          storeName
          storeAccount{
            id
            accountsName
          }
          storeIncharge{
            id
            username
          }
          
          matained
          
          
        }
        pageInfo{
          totalItems
          hasNextPage
          hasPreviousPage
          totalPages
        }
      }
    }`
  return query
}

export const ContactTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending']
  let query =
    `
    query MyQuery {
  contactDetalis(page:${page}, pageSize:${pageSize}   ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `) {
    items {
      contactPersonName
      default
      email
      salutation
       
      id
      otherNo
      phoneNumber
      whatsappNo
      supplierformdataSet {
        supplierNo
      }
      contactType {
        name
      }
    }
       pageInfo{
            totalItems
            hasNextPage
            hasPreviousPage
            totalPages
          }
  }
}`
  return query
}

export const ContactFetch = (data) => {
  // phoneNumber: "${params}"
  return ` query MyQuery {
      contactDetalis(${data}){
        items {
           id
          contactPersonName
          salutation
          email
           phoneNumber 
          contactType{
            id
            name
          }
           whatsappNo
           otherNo
          supplierformdataSet {
            companyName
            address {
              default
              pincode
            }
          }
        }
      }
    }`
}

export const UomTableQuery = (page = 1, pageSize = 20, filters, bool_values) => {
  let no_string_fields = ['descending']
  let query =
    `
  query uomTableQuery{
    uom(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
          items{
            id
            name
            eWayBillUom
            descriptionText 
          }
          pageInfo{
            totalItems
            hasNextPage
            hasPreviousPage
            totalPages
          }
        }
    }`
  return query
}

export const UomEdit = data => {
  return `
  query uomEdit{
    uom(id:${data}){
          items{
            id
            name
            eWayBillUom
            descriptionText 
            createdBy{
              id
              username
            } 
            historyDetails{
              id
              Action
              ColumnName
              PreviousState
              UpdatedState
              modifiedDate
              SavedBy{
                id
                username
              }
            }
          }
         
        }
    }`
}

export const itemGroupTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending']
  let query =
    `
  query itemGroupNameTableQuery{
    itemGroupsName(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
          items{
            id
            name
            parentGroup{
              id
              name
            }
            hsn{
              id
              hsnCode
            }
          }
         pageInfo{
          totalItems
          hasNextPage
          hasPreviousPage
          totalPages
          }
        }
  }`
  return query
}

export const ItemGroupEdit = data => {
  let query = `
  query itemGroupNameEdit{
    itemGroupsName(id:${data}){
          items{
            id
            name
            parentGroup{
              id
              name
            }
            hsn{
              id
              hsnCode
            }
            createdBy{
              id
              username
            }
            historyDetails{
              id
              Action
              ColumnName
              PreviousState
              UpdatedState
              modifiedDate
              SavedBy{
                id
                username
              }
            }
          }
         
        }
    }`
  return query
}

export const AccountMasterTAbleQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending']
  let query =
    `
  query AccounutMasterTableQuery{
    accountsMaster(page:${page}, pageSize:${pageSize}, any:${true} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
          items{
            id
           accountsName
          accountsGroupName{
            id
            accountsGroupName
          }
            gstApplicable
            tds
            accountsActive
          }
          pageInfo{
          totalItems
          hasNextPage
          hasPreviousPage
          totalPages
          }
        }
    }
  `
  return query
}

export const AccountMasterEdit = data => {
  let query = `
  query AccounutMasterEdit{
    accountsMaster(id:${data}, any:${true}){
          items{
            id
           accountsName
          accountsGroupName{
            id
            accountsGroupName
          }
            gstApplicable
            tds
            accountsActive
            createdBy{
              id
              username
            } 
            accountType
            allowPayment
            allowReceipt
            enforcePositionBalance
            historyDetails{
              id
              Action
              ColumnName
              PreviousState
              UpdatedState
              modifiedDate
              SavedBy{
                id
                username
              }
            }
          }
         
          
        }
    }`
  return query
}

export const AccountGroupTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending']
  let query =
    ` query accountsGroupTableQuery{
    accountsGroup(page:${page}, pageSize:${pageSize}, any:${true}` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
          items{
            id
            accountsGroupName
            accountsType{
              id
              name
            }
            groupActive 
          }
      pageInfo{
          totalItems
          hasNextPage
          hasPreviousPage
          totalPages
          }
        }
    }
  `
  return query
}

export const AccountGroupEdit = data => {
  let query = `
  query accountsGroupTableQuery{
    accountsGroup(id:${data}, any:${true})  {
          items{
            id
            accountsGroupName
            accountsType{
              id
              name
            }
            groupActive 
            createdBy{
              id
              username
            } 
             historyDetails{
              id
              Action
              ColumnName
              PreviousState
              UpdatedState
              modifiedDate
              SavedBy{
                id
                username
              }
            }
          }
      
        }
    }`
  return query
}

export const HsnTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending', 'hsnCode', 'gstRates', "cessRate"]
  let query =
    `
  query HSnTableQuery{
    hsn(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
          items{
            id
            hsnCode
            hsnTypes{
              id
              name
            }
            gstRates{ 
              rate
            }
            description 
						cessRate
            rcm
            itc 
          }
        pageInfo{
          totalItems
          hasNextPage
          hasPreviousPage
          totalPages
          }
      
        }
    }
  `
  return query
}

export const HsnEdit = data => {
  let query = `
  query HSnTableQuery{
    hsn(id:${data}){
          items{
            id
            effectiveDate
            hsnCode
            hsnTypes{
              id
              name
            }
            gstRates{
              id
              rate
            }
            description 
						cessRate
            rcm
            itc 
             createdBy{
              id
              username
            } 
             historyDetails{
              id
              Action
              ColumnName
              PreviousState
              UpdatedState
              modifiedDate
              SavedBy{
                id
                username
              }
            }
          }
      
       }
    }`
  return query
}

export const CurrencyExchangeTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['rate', 'descending']
  let query =
    `
  query currencyTableQuery{
    currencyExchangeConnection(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
          items{
            id
            Currency{
              id
              name
            }
            rate
            date
           
          }
       pageInfo{
          totalItems
          hasNextPage
          hasPreviousPage
          totalPages
          }
       }
    }`
  return query
}

export const CurrencyEdit = data => {
  let query = `
  query currencyTableQuery{
    currencyExchangeConnection(id:${data}){
          items{
            id
             Currency{
              id
              name
            }
            rate
            date
            createdBy{
              id
              username
            } 
             historyDetails{
              id
              Action
              ColumnName
              PreviousState
              UpdatedState
              modifiedDate
              SavedBy{
                id
                username
              }
            }
          }
    
      
       }
    }`
  return query
}

export const CurrencyMasterTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending']

  let query =
    `
  query myQuery{
    currencyMaster(page:${page}, pageSize:${pageSize}, any:${true} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
      items{
        id
        name
        currencySymbol
        active
        formate{
          id
          formate
        }
      }
       pageInfo{
            totalItems
            hasNextPage
            hasPreviousPage
            totalPages
      }
    }
  }`
  return query
}

export const CurrencyMasterEdit = data => {
  let query = `
   query currencyMasterEdit{
    currencyMaster(id:${data}, any:${true}){
      items{
        id
        name
        currencySymbol
        active
        formate{
          id
          formate 
       }
       createdBy{
          id
          username
        }
       historyDetails{
              id
              Action
              ColumnName
              PreviousState
              UpdatedState
              modifiedDate
              SavedBy{
                id
                username
              }
            }
       
      }
   
    }
  }`
  return query
}

export const CurrencyOptionsFetch = () => {
  let query = `query MyQuery {
    currencyMaster {
      items {
        id
        name
        currencySymbol
      }
    }
  }`
  return query
}

export const formateOption = () => {
  return `
  query myQuery_{
    currencyFormate{
     items{
      id
       formate
     }
   }
 }
  `
}

export const PosTypeOptions = () => {
  return `
query posType{
  resourcePosType{
    items{
      id
      ReSourceIsPosType
    }
  }
}

  `
}

export const NumberingSeriesTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['currentValue', 'descending']
  let query =
    `
  query currencyMasterEdit{
    numberingSeries(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
      items{
        id
        numberingSeriesName
        resource
        posType
        {
          id
          ReSourceIsPosType
        }
        formate
        currentValue
        default
        active
      }
       pageInfo{
            totalItems
            hasNextPage
            hasPreviousPage
            totalPages
       }
    }
  }`
  return query
}

export const PosSalesOredrTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending', 'balanceAmount']
  let query =
    `
  query myquery{
    salesOrder(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
     items
     {
       id
       POSId{
        linkedModelId
      }
       CreatedAt
       OrderDate
       status
       posType
       CosName
       Mobile
       FinalTotalValue
       marketingEvent{
        id
        name
      }
       balanceAmount
       Pending
       isDelivered
       Remarks
       createdby{
         id
         username
       }
     }
        pageInfo{
         totalPages
         hasNextPage
         hasPreviousPage
         totalItems
       }
   }
 }`
  return query
}

export const NumberingSeriesEdit = data => {
  let query = `
  query numberingSeriesEdit{
    numberingSeries(id:${data}){
      items{
        id
        numberingSeriesName
        resource
        posType
        {
          id
          ReSourceIsPosType
        }
        department{
          id
          name
        }
        formate
        currentValue
        default
        active
        lastSerialHistory
        createdBy{
              id
              username
            } 
       historyDetails{
        id
        Action
        ColumnName
        PreviousState
        UpdatedState
        modifiedDate
        SavedBy{
          id
          username
        }
      }
      }
     
    }
  }`
  return query
}

export const userdataOptionsQuery = data => {
  let query = `query myuser{
    User(UserName:"${data}"){
      items{
       id
       username
        email
        firstName
        lastName
        isActive
      }
    }
  }`
  return query
}

export const StockStatementQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending', 'id', 'currentStock']
  let query = `query MyQuery {
      stockStatement(page:${page}, pageSize:${pageSize}`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
      items{
        id
        partCode
        partName
        currentStock
        itemGroup
        stores
        stockId
        isSerial
        isBatch
        description
      }
      totalQty
      pageInfo{
        totalItems
        hasNextPage
        hasPreviousPage
        totalPages
      }
    }
    }`
  return query
}

export const itemMasterCustomOptionFetchQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let key = ''
  let value = ''
  if (filters.hasOwnProperty('partCode')) {
    key = 'itemPartCode'
    value = filters['partCode']
  } else if (filters.hasOwnProperty('itemPartCode')) {
    key = 'itemPartCode'
    value = filters['itemPartCode']
  } else {
    key = 'itemName'
    value = filters['partName']
  }
  let query = `query myFirstQuery{
      itemMaster(pageSize:200, ${key}: "${value}"){
        items{
          id
          itemName
          itemPartCode
        }
      }
    }`
  return query
}

export const StockItemsMultipleFetch = stock_ids => {
  return `query MyQuery {
    stockItems(stockIds: [${stock_ids}]) {
      items {
        batchNumber {
          batchNumberName
          id
        }
        currentStock
        id
        lastSerialHistory
        serialNumber {
          id
          serialNumber
        }
        store {
          id
          storeName
        }
        unit{
          id
          name
        }
      }
    }
  }`
}

export const getBatchOrSerial = (
  partID,
  StoreID,
  batchNumber = null,
  serialNumber = null
) => {
  let condition = ''
  if (batchNumber) {
    condition = `, batchNumberString: "${batchNumber}" `
  } else if (serialNumber) {
    condition = `, SerialString: "${serialNumber}" `
  }

  return `
    query myQuery {
      itemStock(store: ${StoreID}, partNumber: ${partID}${condition}) {
        items { 
          batchNumber {
            id
            batchNumberName
          }
          serialNumber {
            id
            serialNumber
          }
        }
      }
    }
  `
}

export const StockHistoryLogFetchQuery = stock_ids => {
  let query = `query stockHistoryLog{
    stockHistoryLog(stockId: [${stock_ids}]){
      items{
        id
        partCodeId
        added
        reduced
        startStock
        endStock
        date
      }
    }
  }`
  return query
}

export const StockHistoryFetchQuery = stock_ids => {
  let query = `query StockHistoryFetch{
    stockHistoryDetails(stockId: [${stock_ids}]){
      items{
        reduced
        added
        transactionId
        transactionModule
        displayName
        startStock
        endStock
        date
        savedBy
        displayId
        unit
      }
    }
  }`
  return query
}

export const PosItemDetailsEdit = id => {
  return `
  query myposItem{
    salesOrderItem(id:${id}){
      items{
        id
      partCode
      {
        id
        itemPartCode  
        itemName
        serial
        batchNumber
      }
      posItemComboBool
      description
      uom{
        id
        name
      }
      qty
      serial{
        id
        serialNumber
      }
      batch{
        id
        batchNumberName
      }
      rate
      gstRate
      amount  
      createdBy{
        id
        username
      }
    }
      }
}
  `
}

export const PosSalesOrderEdit = id => {
  let query = `
  query data {
    salesOrder(id:${id}) {
      items {
        id
        district
        pincode{
          id
          pincode
        }
        CosName
        CreatedAt
        DiscountFinalTotal
        sampleContactLink{
          id
          contactPersonName
        }
        Email
        FinalTotalValue
        IsPOS
        Mobile
        OrderDate
        OverallDiscountPercentage
        OverallDiscountPercentageValue
        OverallDiscountValue
        balanceAmount
        receivedAmount
        POSId{
          linkedModelId
        }
        Pending
        Remarks
        customerName{
          id
          companyName
          legalName
        }
        marketingEvent {
          id
          name
        }
        State
        TotalAmount
        UpdatedAt
        WhatsappNumber
        balanceAmount
        cgst
        id
        igst
        allStockReduced
        isDelivered
        itemDetails {
          id
          partCode {
            id
            itemPartCode
            itemName
            serial
            batchNumber
          }
          posItemComboBool
          description
          stockReduce
          isCanceled
          uom {
            id
            name
          }
          sgst
          cgst
          igst
          qty
          itemCombo{
            id
          }
          serial {
            id
            serialNumber
          }
          batch {
            id
            batchNumberName
          }
          createdBy{
            id
            username
          }
          rate
          gstRate
          amount
          discountPercentage
          discountValue
          discountValueForPerItem
          finalValue
        }
        posType
        receivedAmount
        sgst
        status
        store {
          storeName
          id
        }
        payment {
          id
        }
        BillingAddress {
          addressLine1
          addressLine2
          addressType
          city
          country
          default
          id
          pincode
          state
        }
        DeliverAddress {
          addressLine1
          addressLine2
          addressType
          city
          country
          default
          id
          pincode
          state
        }
        SalesPerson{
          id
          username
        }
        contactPerson{
          id
          contactPersonName
        }
        createdby{
          id
          username
        }
        UpdatedAt
      }
    }
  }`
  return query
}

export const accountsMasterForPosOptionQuery = data => {
  let query =
    `query accountsMaster{
    accountsMaster(pageSize:200, ` +
    data +
    `){
          items{
            id
            accountsName
          }
        }
    }`
  return query
}

export const InventoryHandlerMasterQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending', 'id']
  let query = `query MyQuery {
      inventoryHandler(page:${page}, pageSize:${pageSize}, startsWith: "IAA"`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
      items{
        inventoryHandlerId
        store{
          id
          storeName
        }
        inventoryId {
          id
        }
        qtyofInventoryApproval
        conference{
          id
          name
        }
        createdAt
        savedBy{
          username
        }
      }
      pageInfo{
        totalItems
        hasNextPage
        hasPreviousPage
        totalPages
      }
    }
    }`
  return query
}

export const InventoryDeletionHandlerMasterQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending', 'id']
  let query = `query MyQuery {
      inventoryHandler(page:${page}, pageSize:${pageSize},  startsWith: "IAD", `
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
      items{
        inventoryHandlerId
        store{
          id
          storeName
        }
        inventoryId {
          id
        }
        createdAt
        savedBy{
          username
        }
      }
      pageInfo{
        totalItems
        hasNextPage
        hasPreviousPage
        totalPages
      }
    }
    }`
  return query
}

export const InventoryHandlerAddIdOptionFetchQuery = (
  page = 1,
  pageSize = 20,
  data,
  bool_values
) => {
  let search_term = data?.['inventoryHandlerId']

  let query = `query fetchhandlerOptions{
      inventoryHandler(startsWith: "IAA-", inventoryHandlerId:"${search_term}"){
      items{
        id
        inventoryHandlerId
      }
    }
  }`
  return query
}

export const InventoryHandlerDeleteIdOptionFetchQuery = (
  page = 1,
  pageSize = 20,
  data,
  bool_values
) => {
  let search_term = data?.['inventoryHandlerId']

  let query = `query fetchhandlerOptions{
      inventoryHandler(startsWith: "IAD-", inventoryHandlerId:"${search_term}"){
      items{
        id
        inventoryHandlerId
      }
    }
  }`
  return query
}

export const InverntoryApprovalMasterQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let inventory_approval_ids = localStorage.getItem('inventory_approval_ids')
  let no_string_fields = ['descending', 'id', 'qty']
  let query = `query MyQuery {
      inventoryApproval(page:${page}, pageSize:${pageSize}, approvalId: [${inventory_approval_ids}]`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
      items{
        id
        partNumber{
          id
          itemPartCode
          itemName
        }
        batchNumber {
          id
          batchNumberName
        }
        qty
        store{
          storeName
        }
        serialNumberJoined
      }
      pageInfo{
        totalItems
        hasNextPage
        hasPreviousPage
        totalPages
      }
    }
    }`
  return query
}

export const SerialNumberOptionFetchQuery = data => {
  return `query serialNumberFetch {
    serialNumber(page:1, pageSize: 200, serialNumber: "${data}"){
    items{
      id
      serialNumber
    }
  }
  }`
}

export const BatchNumberOptionFetchQuery = (page = 1, pageSize = 200, filters, bool_values) => {
  if (Object.keys(filters).length === 2 && Object(filters).hasOwnProperty('batchNumber') && Object(filters).hasOwnProperty('partNumber')) {
    let query = `query batchNumberFetch {
      batchNumber(page:${page}, pageSize: ${pageSize}, batchNumberName: "${filters['batchNumber'].trim()}", partNumber:${filters['partNumber'].trim()} ){
      items{
          id
          batchNumberName
          partNumber{
            id
            itemPartCode
          }
      }
    }
    }`

    return query
  } else {
    let no_string_fields = ['partNumber', 'batchNumber']
    let query = `query batchNumberFetch {
      batchNumber(page:${page}, pageSize:${pageSize}`
    if (filters && Object.keys(filters).length > 0) {
      for (const [key, value] of Object.entries(filters)) {
        if (bool_values.includes(key) || no_string_fields.includes(key)) {
          if (typeof value === 'object') {
            query += `, ${key}:${value[value.key]}`
          } else {
            query += `, ${key}:${value}`
          }
        } else if (
          key.endsWith('Lt') ||
          key.endsWith('Gt') ||
          key.endsWith('Gte') ||
          key.endsWith('Lte') ||
          key.endsWith('Start') ||
          key.endsWith('End')
        ) {
          if (typeof value === 'object') {
            query += `, ${key}:${value[value.key]}`
          } else {
            query += `, ${key}:${value}`
          }
        } else {
          if (typeof value === 'object') {
            query += `, ${key}:"${value[value.key]}"`
          } else {
            query += `, ${key}:"${value}"`
          }
        }
      }
    }
    query += `){
        items{
          id
          batchNumberName
          partNumber{
            id
            itemPartCode
          }
        }
      }
      }`
    return query
  }
}

export const ItemMasterFetchSingleQuery = (data, key = null) => {
  if (key) {
    return `query MyQuery {
      itemMaster(page:1, pageSize:200, ${key}Equals: "${data}"){
      items{
        id
        itemPartCode
        itemName
         category {
          name
        }
         itemGroup{ 
          id
          name
        }
        itemUom{ 
          id
          name
        }
        service
        itemComboBool
        keepStock
        itemActive
        batchNumber
        serial
        serialFormat
        serialStarting
        serialAutoGentrate
      }
      pageInfo{
        totalItems
        hasNextPage
        hasPreviousPage
        totalPages
      }
    }
    }`
  } else if (data) {
    return `query MyQuery {
      itemMaster(page:1, pageSize:10, id: ${data}){
      items{
        id
        itemPartCode
        itemName
         category {
          name
        }
         itemGroup{ 
          id
          name
        }
        itemUom{ 
          id
          name
        }
           alternateUom{
          addtionalUnit{
            id
            name
          }
          conversionFactor
        }
        service
        itemComboBool
        keepStock
        itemActive
        batchNumber
        serial
        serialFormat
        serialStarting
        serialAutoGentrate
      }
      pageInfo{
        totalItems
        hasNextPage
        hasPreviousPage
        totalPages
      }
    }
    }`
  } else {
    return `query MyQuery {
      itemMaster(page:1, pageSize:10){
      items{
        id
        itemPartCode
        itemName
         category {
          name
        }
         itemGroup{ 
          id
          name
        }
        itemUom{ 
          id
          name
        }
        service
        itemComboBool
        keepStock
        itemActive
        batchNumber
        serial
        serialFormat
        serialStarting
        serialAutoGentrate
      }
      pageInfo{
        totalItems
        hasNextPage
        hasPreviousPage
        totalPages
      }
    }
    }`
  }
}

export const StockSerialHistoryDataFetch = () => {
  return `query StockSerialHistoryFetchQuery{
    stockSerialHistory{
      items{
        id
        store {
          id
        }
        lastSerialHistory
        partNo{
          id
          itemPartCode
          itemName
        }
      }
    }
  }`
}

export const StockDataFetch = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['batchNumber', 'id', 'partNumber', 'store']
  let query = `query MyQuery {
    itemStock(page:${page}, pageSize:${pageSize}`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
    items{
      id
      store {
        id
      }
      lastSerialHistory
      batchNumber{
        id
        batchNumberName
      }
      serialNumber{
        id
        serialNumber
      }
      partNumber{
        id
        itemPartCode
      }
      currentStock
    }
    }
  }`
  return query
}

export const SupplierDataFetch = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = [
    'batchNumber',
    'id',
    'partNumber',
    'store',
    'descending'
  ]
  let query = `query SupplierDataFetch{
    supplierFormData(page:${page}, pageSize:${pageSize}, any:${true}, source: "Supplier"`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
      items{
        id
        companyName
        legalName
        customer
        supplier
        transporter
        transporterId
        gstinType{
          id
          gstType
        }
        gstin
        tcs
        panNo
        active
        supplierCreditedPeriod
        customerCreditedPeriod
        creditedLimit
        customerGroup{
          id
          name
        }
        supplierGroup{
          id
          name
        }
        salesPerson{
          id
          username
        }
        contact{
          id
          contactPersonName
          salutation
          whatsappNo
          email
          phoneNumber
          otherNo
          default
        }
        address {
          id
          addressLine1
          addressLine2
          pincode
          addressType
          city
          state
          country
          default
        }
      }
      pageInfo{
        totalPages
        hasNextPage
        hasPreviousPage
        totalItems
      }
    }
  }`
  return query
}

export const CustomerDataFetch = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = [
    'batchNumber',
    'id',
    'partNumber',
    'store',
    'descending'
  ]
  let query = `query SupplierDataFetch{
    supplierFormData(page:${page}, pageSize:${pageSize}, any:${true}, source: "Customer"`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
      items{
        id
        supplierNo
        companyName
        legalName
        customer
        supplier
        transporter
        transporterId
        gstinType{
          id
          gstType
        }
        gstin
        tcs
        panNo
        active
        supplierCreditedPeriod
        customerCreditedPeriod
        creditedLimit
        customerGroup{
          id
          name
        }
        supplierGroup{
          id
          name
        }
        salesPerson{
          id
          username
        }
        contact{
          id
          contactPersonName
          salutation
          whatsappNo
          email
          phoneNumber
          otherNo
          default
          contactType {
        name
      }
        }
        address {
          id
          addressLine1
          addressLine2
          pincode
          addressType
          city
          state
          country
          default
        }
      }
      pageInfo{
        totalPages
        hasNextPage
        hasPreviousPage
        totalItems
      }
    }
  }`
  return query
}

export const SupplierFormCompanyOptionFetchQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['batchNumber', 'id', 'partNumber', 'store']
  let query = `query SupplierFormCompanyOptionFetchQuery{
    supplierFormData(page:${page}, pageSize:${pageSize}`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
      items{
        id
        companyName
      }
      pageInfo{
        totalPages
        hasNextPage
        hasPreviousPage
        totalItems
      }
    }
  }`
  return query
}

export const SupplierFormLegalOptionFetchQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['batchNumber', 'id', 'partNumber', 'store']
  let query = `query SupplierFormLegalOptionFetchQuery{
    supplierFormData(page:${page}, pageSize:${pageSize}`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
      items{
        id
        legalName
      }
      pageInfo{
        totalPages
        hasNextPage
        hasPreviousPage
        totalItems
      }
    }
  }`
  return query
}

export const SupplierFormGSTINOptionFetchQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['batchNumber', 'id', 'partNumber', 'store']
  let query = `query SupplierFormGSTINOptionFetchQuery{
    supplierFormData(page:${page}, pageSize:${pageSize}`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
      items{
        id
        gstin
      }
      pageInfo{
        totalPages
        hasNextPage
        hasPreviousPage
        totalItems
      }
    }
  }`
  return query
}

export const SupplierFormPanNumberOptionFetchQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['batchNumber', 'id', 'partNumber', 'store']
  let query = `query SupplierFormPanNumberOptionFetchQuery{
    supplierFormData(page:${page}, pageSize:${pageSize}`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
      items{
        id
        panNo
      }
      pageInfo{
        totalPages
        hasNextPage
        hasPreviousPage
        totalItems
      }
    }
  }`
  return query
}

export const onlyForSupplierOption = data => {
  let query = `query MyQuery {
    supplierFormData(companyName: "${data}", supplier:true) {
      items {
        legalName
        companyName
        id 
      }
    }
  }`
  return query
}
export const SupplierOptionPerameter = data => {
  let query = `query MyQuery {
    supplierFormData(${data}) {
      items {
        id 
        supplierNo
        legalName
        companyName
        supplierCreditedPeriod
        contact{
           id
          contactPersonName
          email
          phoneNumber
          whatsappNo
          otherNo
          default
        }
        address{
          id
          addressType
          addressLine1
          addressLine2
          city
          state
          country
          pincode
          default
        }
        gstin
        gstinType{
          gstType
        }
      }
    }
  }`
  return query
}
export const SupplierOption = data => {
  let query = `query MyQuery {
    supplierFormData(companyName: "${data}", customer:true) {
      items {
        legalName
        companyName
        id 
      }
    }
  }`
  return query
}

export const SupplierOption_withparameter = data => {
  // 
  let query = `query MyQuery {
    supplierFormData(${data}) {
      items {
        legalName
        companyName
        id 
      }
    }
  }`
  return query
}

export const SupplierEdit = id => {
  let query = `query MyQuery {
    supplierFormData( id: ${id}) {
      items {
        companyName
        id
        salesPerson{
          id
          username
        }
        gstin
        address{
          id
          addressType
          addressLine1
          addressLine2
          city
          pincode
          state
          country
        }
        contact{
          id
          contactPersonName
          phoneNumber
          default
        }
      }
    }
  }`
  return query
}


export const ConferenceOptions = (data) => {
  let peremeter = `(${data})`
  return `
  query myQuery{
    conferenceData ${data ? peremeter : ""}{
      items{
        id
        currency{
          id
          Currency{
            id
            name
          }
        }
        name
      }
    }
  } 
  `
}

export const EWayBill = (page = 1, pageSize = 100, data) => {
  let search_term = data?.['eWayBillUom']
  let query = `
  query e_way_bill{
    ewayBillOptions(eWayBillUom: "${search_term}"){
      items{
        id
        eWayBillUom
      }
    }
} 
  `
  return query
}

export const NumberingOptions = (page = 1, pageSize = 100, data) => {
  let search_term = data?.['numberingSeriesName']
  let query = `       
query numbering{
  numberingSeries(numberingSeriesName:"${search_term}"){
    items{
      id
      numberingSeriesName
    }
  }
}
    `

  return query
}

export const NumberingFormateOptions = (page = 1, pageSize = 100, data) => {
  let search_term = data?.['formate']
  let query = `       
    query numbering{
      numberingSeries(formate:"${search_term}"){
        items{
          id 
          formate
        }
      }
    }
    `

  return query
}

export const CurrencyMasterOptionsForTable = (
  page = 1,
  pageSize = 20,
  data
) => {
  let search_term = data?.['name']
  let query = `
  query MyQuery {
    currencyMaster(name:"${search_term}"){
      items {
        id
        name
      }
    }
  }
  `
  return query
}

export const CurrencySymbolForTable = (page = 1, pageSize = 20, data) => {
  let search_term = data?.['currencySymbol']
  let query = `
  query MyQuery {
    currencyMaster(currencySymbol:"${search_term}"){
      items {
        id
        currencySymbol
      }
    }
  }
  `
  return query
}

export const CurrencyExchangesOptionsForTable = (
  page = 1,
  pageSize = 20,
  data
) => {
  let search_term = data?.['name']
  let query = `
  query MyQuery {
    currencyExchangeConnection(name: "${search_term}"){
      items {
        name
        id
      }
    }
  }
  `
  return query
}

export const AccountMasterFetchQuery = () => {
  let query = `query accountMasterFetch{
    accountsMaster{
      items{
        id
        accountType
        accountsName
        accountsActive
        allowPayment
        allowReceipt
        accountsGroupName {
          id
        }
      }
    }
  }`
  return query
}

export const accountsMasterTypeWithIndex = () => {
  let query = `
  query MyQuery {
  accountsMasterTypeWithIndex {
    id
    accountType
    accountsName
    accountsActive
    allowPayment
    allowReceipt
    accountsGroupName {
      id
    }
  }
}`
  return query
}

export const paymentmodeQuery = (id = null, id_list = null) => {
  let query = `query paymentmodeFetch{
    paymentMode`
  if (id) {
    query += `(id: ${id})`
  } else if (id_list) {
    query += `(idList: [${id_list}])`
  }
  query += `{
      items{
        id
        payby {
          id
          accountsName
        }
        payAmount
        balanceAmount
        CreatedAt
      }
    }
  }`
  return query
}

export const stockIdFetchQuery = (part_number = null, store = null) => {
  let query = `query stockidsFetch{
    stockIds(partNumber: ${part_number}, store: ${store}){
      items{
        id
        partNumber{
          id
          itemPartCode
          itemName
          description
        	batchNumber
          serial
        }
      }
    }
  }`
  return query
}

export const fetchGstTypeQuery = () => {
  let query = `query fetchGstType{
    gstType{
      items{
        id
        gstType
      }
    }
  }`
  return query
}

export const ConferenceTableMasterQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending']
  let query = `query conferenceDataFetch{
    conferenceData(page: ${page}, pageSize: ${pageSize}`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
      items{
        id
        name
        status
        startDate
        endDate
        createdBy{
          id
          username
        }
        inCharge{
          id
          username
        }
        defaultStore{
          id
          storeName
        }
        currency{
          id
          Currency{
            id
            name
          }
          
        }
        additionalInCharges{
        id
        username
        }
      }
      pageInfo{
        totalItems
        totalItems
        hasNextPage
        hasPreviousPage
      }
    }
  }`
  return query
}

export const ConferenceNameOptionFetchQuery = (
  page = 1,
  pageInfo = 20,
  data
) => {
  let contructed_query = ''
  if (data && data.hasOwnProperty('Name')) {
    contructed_query = `(Name: "${data['Name']}")`
  }
  let query = `query conferenceDataFetch{
    conferenceData${contructed_query}{
      items{
        id
        name
      }
    }
  }`
  return query
}

export const EnquiryDatamasterFetchQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending', 'id']
  let user_id = localStorage.getItem('userid')
  let query = `query EnquiryDataFetch{
    enquiryData(page: ${page}, pageSize: ${pageSize}, currentUser: ${user_id}`
  if (filters && Object.keys(filters).length > 0) {
    for (const [key, value] of Object.entries(filters)) {
      if (bool_values.includes(key) || no_string_fields.includes(key)) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else if (
        key.endsWith('Lt') ||
        key.endsWith('Gt') ||
        key.endsWith('Gte') ||
        key.endsWith('Lte') ||
        key.endsWith('Start') ||
        key.endsWith('End')
      ) {
        if (typeof value === 'object') {
          query += `, ${key}:${value[value.key]}`
        } else {
          query += `, ${key}:${value}`
        }
      } else {
        if (typeof value === 'object') {
          query += `, ${key}:"${value[value.key]}"`
        } else {
          query += `, ${key}:"${value}"`
        }
      }
    }
  }
  query += `){
      items{
        id
        email
        status
        mobileNumber
        location
        remarks
        message
        followUp
         pincode{
          id
          pincode
        }
        district{
          id
        district
        }
        state{
          id
          stateName
        }
        name
        organizationName
        alternateMobileNumber
        otherNumber
        linkContactDetalis {
         id
          contactPersonName
        email
        otherNo
        phoneNumber
        whatsappNo
        }
        conferenceData{
          id
          name 
        }
        salesPerson {
          id
          username
        }
        interests{
          id
          Name
        }
        interestsJoined
        createdAt
        createdBy {
        id
        username
        }
      }
      pageInfo{
        totalItems
        totalItems
        hasNextPage
        hasPreviousPage
        totalPages
      }
    }
  }`

  return query
}

export const EnquiryMasterNameOptionFetchQuery = (
  page = 1,
  pageInfo = 20,
  data,
  bool_values
) => {
  if (data) {
    const key = Object.keys(data)[0]
    let subQuery = ''

    if (key.includes('salesPerson')) {
      subQuery = `{
        id
        username
      }`
    } else if (key.includes('conferenceData')) {
      subQuery = `{
        id
        name
      }`
    }
    let user_id = localStorage.getItem('userid')
    const query = `query EnquiryOptionFetch {
      enquiryData(${key}Contains: "${data[key]}", currentUser: ${user_id}) {
        items {
          id
          ${key} ${subQuery &&
      `
            ${subQuery}
          `
      }
        }
      }
    }`
    return query
  }
}

export const EnquiryStatuSFetchQuery = () => {
  let query = `query EnquiryStatusFetch {
    enquiryStatus{
      items {
        id
      	status
    }
  }
  }`
  return query
}

export const ProductFetchQuery = () => {
  let query = `query ProductFetch {
    product{
      items {
        id
      	Name
    }
  }
  }`
  return query
}

export const SupplierOptionQuery = search_term => {
  let query = `query SupplierTableQuery{
    supplierFormData(companyName: "${search_term}"){
      items{
        id
        companyName
      }
    }
  }`
  return query
}

export const TotalStockByPartCode = part_code => {
  let query = `query ItemStockByPartCodeFetch{
    itemStockByPartCode(partNumber: ${part_code}){
      items{
        id
        totalCurrentStock
        partNumber {
          id
        }
      }
    }
  }`
  return query
}

export const PosIdTableFiliter = (page = 1, pageSize = 20, data) => {
  let search_term = data?.['POSId']
  let Query = `
  query mysalesOrderId{
    salesOrder(POSId: "${search_term}"){
      items{
        id
        POSId{
          linkedModelId
        }
      }
    }
  }`
  return Query
}

export const Postype = () => {
  let Query = `
  query mysalesOrdertype{
    postypeOptions{
     items{
       id
       posType
     }
   }
 
 }`
  return Query
}

export const posStatus = () => {
  let Query = `
  query MyQuery {
    posStatus{
     items{ 
      id
       Status
     }
   }
 }`
  return Query
}

export const posCostomeNameForTableFilter = (page = 1, pageSize = 20, data) => {
  let search_term = data?.['CosName']
  let Query = `
  query mysalesOrderId{
    salesOrder(CosName: "${search_term}"){
      items{
        id
        CosName
      }
    }
  }`

  return Query
}

export const posRemorksTableFilter = (page = 1, pageSize = 20, data) => {
  let search_term = data?.['Remarks']
  let Query = `
  query mysalesOrderId{
    salesOrder(Remarks: "${search_term}"){
      items{
        id
        Remarks
      }
    }
  }`
  return Query
}

export const posNumberForTableFilter = (page = 1, pageSize = 20, data) => {
  let search_term = data?.['Mobile']
  let Query = ` 
  query mysalesOrderId{
    salesOrder(Mobile:"${search_term}"){
      items{
        id
        Mobile
      }
    }
  }`
  return Query
}

export const CustomerGroupFetchQuery = () => {
  let customer_group_fetch_query = `query CustomerGroupFetch{
    customerGroups(pageSize: 200){
      items{
        id
        name
      }
    }
  }`
  return customer_group_fetch_query
}

export const SupplierGroupFetchQuery = () => {
  let supplier_group_fetch_query = `query SupplierGroupFetch{
    supplierGroups(pageSize: 200){
      items{
        id
        name
      }
    }
  }`
  return supplier_group_fetch_query
}

export const RoutingFetchQuery = () => {
  let query = `query RoutingDataQuery{
    routing{
      items{
        routeName
        id
      }
    }
  }`
  return query
}

export const CurrentConferenct = (userId, isCurrenConference = true) => {
  let query = `
  query myquery {
    currentConferenceData(userId:${userId}, isCurrenConference: ${isCurrenConference}){
    items{
      id
      name
      defaultStore{
        id
        storeName
      }
    }
}
}`
  return query
}

export const PosReport = (eventId, startDate, endDate) => {
  let query = `
  query myquery{
    posReport(eventId:${eventId}, startDate : "${startDate}" , 
    endDate: "${endDate}"){
      items{
        totalPosAmount
        currentBalanceAmount
        cashAmountReceived
        bankAmountReceived
        swipeAmountReceived
      }
    }
  
 }`
  return query
}

export const PosDetailedReport = (
  eventId,
  startDate,
  endDate,
  iscollectionswise,
  userId
) => {
  let Query = `
  query myreport{
    reportDetails(event: ${eventId}, startDate: "${startDate}", endDate: "${endDate}", iscollectionswise: ${iscollectionswise},
    useriId: ${userId}
    ){
      items{
        id
        IsPOS
        OrderDate
         POSId 
        CosName
       Mobile
       FinalTotalValue 
       balanceAmount
       Remarks
       Payments
      },
    totalAmount{
      FinalTotalValue
      balanceAmount
      bank
    }
    }
  } `
  return Query
}

export const posStockHistoryId = (transactionId, model) => {
  let query = `
   query MyQuery {
    stockHistoryDetails(transactionId: ${transactionId}, transactionModule: "${model}") {
      items {
        id
        partCodeId
      }
    }
  }`
  return query
}

export const Area_data = (data, page) => {
  let query = `
  query area{
    areaNameList(areaName: "${data}", page:${page}){
      items{
        id
        areaName
      }
    }
  }`
  return query
}

export const districtQuery = data => {
  let query = `
  query districts{
    districtsList(id:${data}){
      items{
        id
        district
      }
    }
  }`
  return query
}

export const pincodeQuery = (data, page) => {
  let query = `
  query pincode{
    pincodeList(pincode:"${data}", page:${page}){
      items{
        id
        pincode
      }
    }
  }
  `
  return query
}

export const pincode_id_Query = data => {
  let query = `
  query pincode{
    pincodeList(id:${data}){
     items{
       id
       pincode
     }
   }
   }
   
  `
  return query
}

export const StateQuery = data => {
  let query = `
  query state{
    statesList(id:${data}){
      items{
        id
        stateName
      } 
    }
  } 
  `
  return query
}

export const Filiter_address_with_pincode_Query = data => {
  let query = `
  query addressmaster{
    addressMasterList(${data}){
      items{
        pincode{
          id
          pincode
        }
        district{
          id
          district
        }
        state{
          id
          stateName
        }  
      }
    }
  }
  
  
  `
  return query
}

export const DepartmentTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = []
  let query =
    ` 
    query department{
      department(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
        items{
          id
          No
          name
          departmentHeadUserId{
            id
            username
          }
          createdBy{
            id
            username
          }
        
          historyDetails{
            id
            Action
            ColumnName
            PreviousState
            UpdatedState
            modifiedDate
            SavedBy{
              id
              username
            }
          }
          
        }
        pageInfo{
          totalItems
            hasNextPage
            hasPreviousPage
            totalPages
        }
        
      }
    }
  `
  return query
}

export const DepartmentQuery = data => {
  let query = `
   query department{
    department(${data}){
      items{
        id
        No
        name
        departmentHeadUserId{
          id
          username
        }
        createdBy{
          id
          username
        }
      }
    }
  }
   `

  return query
}

export const userPermissionOptions = (data) => {
  // isSales: true userName:"j"
  return `
  query MyQuery {
  userPermission(${data}) {
    items {
      userId {
        id
        username
      }
    }
  }
}
  `}

export const TermsConditionsQuotation = data => {
  let query = `
  query termsConditions{
    termsConditions(page:1, pageSize:200, name:"${data}", module:"Quotations" ){
     items{
       id
       name
       tc
     }
    
   }
  
}
  `
  return query
}

export const TermsConditionsTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = []
  let query =
    ` 
  query termsConditions{
    termsConditions(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
     items{
       id
       name
       tc
       module
       createdBy{
         id
         username
       }
      
       modifiedBy{
         id
         username
       }
       historyDetails{
        id
        Action
        ColumnName
        PreviousState
        UpdatedState
        modifiedDate
        SavedBy{
          id
          username
        }
      }
       
     }
     pageInfo{
     totalItems
       hasNextPage
       hasPreviousPage
       totalPages
   }
   }
  
}
  `
  return query
}

export const OtherExpensesTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = []
  let query =
    ` 
  query other{
    otherExpenses(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
      items{
        id
        name
        account{
          id
          accountsName
        }
       HSN{
        id
        hsnCode
      }
        
      }
      pageInfo{
       totalItems
         hasNextPage
         hasPreviousPage
         totalPages
     } 
    }   
  }    
  `
  return query
}

// purchase query
export const PurchaseTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = []
  let query =
    ` 
  query purchase{
    purchaseOrder(page:${page}, pageSize:${pageSize}, active:${true} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
      items{
        id
        purchaseOrderNo{
          linkedModelId
        }
        createdAt
        status
        netAmount
        dueDate
         department{
          name
        }
        receivingStoreId{
          storeName
        }
        supplierId{
          id
          supplierNo
          companyName
        }
        creditPeriod
          otherExpenses{
                    id
          otherExpensesId{
            id
            name
            HSN{
              
              gstRates{
                rate
              }
            }
            
          }
          amount
          igst
          sgst
          cgst
          createdBy{
            id
          }
        }
      }
       pageInfo{
         totalItems
           hasNextPage
           hasPreviousPage
           totalPages
       } 
    }
  }
  `
  return query
}

export const poStatusQuery = (page = 1, pageSize = 20, data) => {
  let query = `
    query MyQuery {
      purchaseStatus{
        items {
          status
          id
        }
      }
    }
`
  return query
}

export const poSupplierCodeQuery = (page = 1, pageSize = 20, data) => {
  let test = data?.['supplierId']
  let query = `
    query MyQuery {
    purchaseOrder(supplierId: "${test}", isSupplierDistinct:true) {
    items { 
      supplierId {
        id
        supplierNo 
      } 
    }
  }
}
`
  return query
}

export const poSupplierNameQuery = (page = 1, pageSize = 20, data) => {
  let test = data?.['companyName']
  let query = `
    query MyQuery {
    purchaseOrder(companyName: "${test}",isSupplierDistinct:true ) {
    items { 
      supplierId {
        id
        companyName 
      } 
    }
  }
}
`
  return query
}

export const poDepartmentQuery = (page = 1, pageSize = 20, data) => {
  let test = data?.['department']
  let query = `
     query MyQuery {
    purchaseOrder( department: "${test}", isDepartmentDistinct:true  ) {
    items { 
      department{
        id
        name
      }
    }
  }
}
`
  return query
}

export const poReceivingStoreQuery = (page = 1, pageSize = 20, data) => {
  let test = data?.['receivingStoreId']
  let query = `
     query MyQuery {
  purchaseOrder(receivingStoreId:"${test}", isreceivingStoreDistinct:true) {
  items { 
     receivingStoreId{
      id
      storeName
    }
  }
}
}
`
  return query
}

export const ProductionOrderTableFetchQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = []
  let query =
    `query MyQuery {
      productionOrderLinking(page:${page}, pageSize:${pageSize}, ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
        items {
          id
          isSubProductionOrder
          poMaster{
            id
            orderNo
            orderDate
            status{
              id
              name
            }
            department{
              id
              name
            }
            modifiedBy{
              id
              username
            }
            updatedAt
            isSubProductionOrder
          }
        }
        pageInfo{
          totalItems
          hasNextPage
          hasPreviousPage
          totalPages
        }
      }
  }`
  return query
}

export const GrnTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = []
  let query =
    `
    query GoodsReceiptNote{
    allGoodsReceiptNote(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
      items{
        id
        ginNo{
          linkedModelId
        }
        ginDate
        purchaseOrderId{
          purchaseOrderNo{
            linkedModelId
          }
          supplierId{
            supplierNo
            companyName
          }
        }
      
      }
         pageInfo{
          totalItems
            hasNextPage
            hasPreviousPage
            totalPages
        }
    }
}
  `
  return query
}

export const GinPurchaseOrderFilter = (page = 1, pageSize = 20, data) => {
  let test = data?.['purchaseOrderId']
  let query = `
      query GoodsReceiptNote{
    allGoodsReceiptNote(page:1, pageSize:20 ,purchaseOrderId: "${test}"){
      items{ 
        purchaseOrderId{
          purchaseOrderNo{
            linkedModelId
          }
        }
      }
    }
 } `
  return query
}

export const GrnPurchaseOrderFilter = (page = 1, pageSize = 20, data) => {
  let test = data?.['purchaseOrderId']
  let query = `
      query GoodsReceiptNote{
    onlyGoodsReceiptNote(page:1, pageSize:20 ,purchaseOrderId: "${test}"){
      items{ 
        purchaseOrderId{
          purchaseOrderNo{
            linkedModelId
          }
        }
      }
    }
 } `
  return query
}

export const onlyGrnTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = []
  let query =
    `
    query GoodsReceiptNote{
    onlyGoodsReceiptNote(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
      items{
        id
        ginNo{
          linkedModelId
        }
        ginDate
        grnNo{
        linkedModelId
        }
        grnDate
        purchaseOrderId{
          purchaseOrderNo{
            linkedModelId
          }
          supplierId{
            supplierNo
            companyName
          }
        }
      
      }
         pageInfo{
          totalItems
            hasNextPage
            hasPreviousPage
            totalPages
        }
    }
}
  `
  return query
}

export const QirTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = []

  let query =
    `
  query MyQuery {
  allQualityInspectionReport(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
    items {
      id
      qirDate
      remarks
      updatedAt
      createdAt
      status{
        status
      }
      createdBy{
        username
      }
      goodsreceiptnoteSet {
        id
        ginNo{
          linkedModelId
        }
        ginDate
      }
      qirNo {
        linkedModelId
      }
    }
      pageInfo{
          totalItems
            hasNextPage
            hasPreviousPage
            totalPages
        }
  }
}`
  return query
}

export const QirStatusQuery = () => {
  let query = `query MyQuery {
  qirStatusConnections {
    items {
      id
      status
    }
  }
}`
  return query
}

export const DeliveryChallanQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = []
  let query =
    `
  query MyQuery {
  deliveryChallan(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
    items {
      id
      dcDate
      dcNo {
        linkedModelId
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
    }
  }
}
  `
  return query
}

export const PurchaseReturnQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = []
  let query =
    `
  query MyQuery {
  purchaseReturnChallan( page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `) {
    items {
      purchaseReturnChallanNo {
        linkedModelId
      }
      id
      createdAt
    }
    pageInfo {
      hasNextPage
      totalItems
      hasPreviousPage
      totalPages
    }
  }
}
  `
  return query
}

export const ProductionOrderNumberOptionQuery = (
  page,
  size,
  search_term,
  bool
) => {
  let query = `query FetchPONumber{
    productionOrderMaster(orderNoContains: "${search_term['orderNo']}"){
      items{
        id
        orderNo
      }
    }
  }`
  return query
}

export const MaterialRequestMasterFetchQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = []
  let query = `query FetchMaterialRequest{
      materialRequestMaster`
  query +=
    `(page:${page}, pageSize:${pageSize}, ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    ')'
  query += `{
          items{
              id
              requestNo
              requestDate
              remarks
              status{
                  id
                  name
              }
              requestFor{
                  id
                  name
              }
              issuingStore{
                  id
                  storeName
              }
              department{
                id
                name
              }
              modifiedBy{
                  id
                  username
              }
              productionOrder{
                orderNo
                orderDate
              }
              createdBy{
                id
                username
              }
              updatedAt
              itemDetails{
                id
              }
          }
          pageInfo{
              totalItems
              totalPages
              hasNextPage
              hasPreviousPage
          }
      }
  }`
  return query
}

export const CommonStatusType = data => {
  // table: "Meeting"
  let query = `
  query MyQuery {
    CommonStatusType(${data}) {
      id
      name
    }
  }`
  return query
}

export const RoleQuery = (page = 1, pageSize = 20, filters, bool_values) => {
  let no_string_fields = []
  let query =
    `
  query MyQuery {
  allRole (page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
    items {
         id
        roleName
        descriptions
        createdBy{
          id
          username
        }
            reportTo{
        id
        username
      }
       modifiedBy{
        id
        username
      }
      shareDataWith{
        id
        username
      }
        createdAt
       	updatedAt
    }
    pageInfo{
      totalItems
      hasNextPage
      hasPreviousPage
      totalItems
    }
  }
}

  `
  return query
}

export const allUsermanagement = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = []

  return (
    `query MyQuery {
  allUsermanagement (page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
    items {
      id
      role {
        id
        roleName
      }
      user {
        id
        username
        email
        firstName
        lastName
      }
      profile{
        id
        profileName
      }
      department {
        id
        name
      }
      createdBy{
        id
        username
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
    }
  }
}`
  )
}

export const allProfile = (page = 1, pageSize = 20, filters, bool_values) => {
  let no_string_fields = []

  return (
    `query MyQuery {
  allProfile(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
    items {
      id
      profileName
      description
      createdBy {
        id
        username
      }
       allowedPermission {
        permissionModel{
          id
          modelName
        }
        modelName
        id
         permissionOptions {
          id
          optionsName
        }
        createdBy {
          id
          username
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
    }
  }
}`
  )
}

export const LeadTableQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending', 'leadValue']
  let query =
    `query MyQuery {
  allLead (page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
    items {
      expectedClosingDate
      createdBy{
        id
      }
      id
      status{
        id
        name
      }
      leadName
      leadNo
      leadValue
      requirement
      rextFollowUp
      quotationsSet {
        id
        quotationNo {
          linkedModelId
        }
        status{
          id
          name
        }
          
           CreatedAt
        createdBy{
          username
        }
      }
      customer {
        id
        companyName
      }
        leadCurrency{
        id
        Currency{
          id
          name
        }
      }
        salesPerson{
        id
        username
      }
      Enquiry {
        id
         conferenceData{
          name
        }
        linkContactDetalis {
          contactPersonName
          email
          phoneNumber
          whatsappNo
        }
           state{
          stateName
        }
        district{
          district
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
    }
  }
}

  `

  return query
}

export const leadCurrencyList = () => {
  return `
    query MyQuery {
      currencyMaster {
        items {
          id
          name
        }
      }
    }
  `
}

export const otherIncomingChargesQuery = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['descending', 'leadValue']
  let query =
    `query MyQuery {
  otherIncomeCharges(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `) {
    items {
      name
      account {
        id
        accountsName
      }
      id
      hsn {
        id
        hsnCode
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
    }
  }
}

  `
  return query
}



export const QuotationTable = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ["descending", "id"]
  let query =
    `query MyQuery {
  allQuotations(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) + ` , active:${true}){
    items {
       id
      customerId {
        id
        companyName
      }
      CreatedAt
      quotationNo {
        id
        linkedModelId
      }
      customerAddress{
        city
        state
      }
      netAmount
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
    }
  }
}  `

  return query
}

export const allSalesorder = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ["descending"]
  let query =
    `query MyQuery {
  allSalesorder2(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) + `, active:true){
    items {
      id
      salesOrderNo {
        linkedModelId
      }
      CreatedAt
      customerPoNo
      department{
        id
        name
      }
      buyer{
        id
        companyName
        address{
          city
          state
        }
      }
        buyerAddress{
        city
        state
      }
      netAmount
      createdBy{
        id
        username
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
    }
  }
}`
  return query
}

export const allEmployee = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ["descending"]
  let query =
    `query MyQuery {
  allEmployee(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `){
    items {
      id
      employeeId
      employeeName
      department{
        id
        name
      }
      designation
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
    }
  }
}`
  return query
}


export const ExpenseRequestTable = (
  page = 1,
  pageSize = 20,
  filters,
  bool_values
) => {
  let no_string_fields = ['requestAmount', "descending"]
  let query =
    `query MyQuery {
  allExpenseRequest(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `) {
    items {
      expenseRequestNo
      id
      expenseRequestDate
      expenseFor
      employeeName{
        id
        employeeName
      }
      requestAmount
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
    }
  }
}`
  return query
}


export const ExpenseCategories = (
  page = 10,
  pageSize = 10,
  filters,
  bool_values
) => {
  let no_string_fields = ["descending"]
  let query =
    `query MyQuery { 

  allExpenseCategories( 
    page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `) { 

    items { 

      active 

      expenseCategoryName 
      accountName{
        accountsName
      }

      id 

    } 

    pageInfo { 

      hasNextPage 

      hasPreviousPage 

      totalPages 

    } 

  } 

}  `

  return query
}

export const ExpenseClaim = (
  page = 10,
  pageSize = 10,
  filters,
  bool_values
) => {
  let no_string_fields = ["descending"]
  let query =
    `query MyQuery {
  allExpenseClaim(page:${page}, pageSize:${pageSize} ` +
    QueryFilter(filters, bool_values, no_string_fields) +
    `) {
    items {
      id
      createdBy {
        id
        username
      }
      employeeId {
        id
        employeeId
        employeeName
      }
      expenseClaimDate
      expenseClaimNo
      id
      expenseClaimDetails {
        amount
        dateOfExp
        descriptions
        gstIn
        id
        pdfUrl
        expenseCategories {
          id
          expenseCategoryName
        }
      }
      status {
        id
        name
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
    }
  }
}  `

  return query
}