import React, {useState, useEffect} from 'react' 
import TableView from '../../Packages/Custom/DataTable';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import AddBOM from './AddBOM';
import BomTreeModal from './BomTree/BomTreeModal';


const BOM = () => {    
    const [currentBOMId, setCurrentBOMId] = useState(null);
    const [isLoad, setLoad] = useState(false);
    const [isShowAddBOMForm, setShowAddBOMForm] = useState(false);
    const [isShowTreeView, setShowTreeView] = useState(false);
    const [modals, setModals] = useState([{ id: 1, show: false }]);
    const [isNew, setNew] = useState(false);
    const [loadComp, setLoadComp] = useState(false)

    // const handleAddBOMShow = () => {
    //     setShowAddBOMForm(!isShowAddBOMForm)
    // }
    
    // const handleCloseBOMShow = () => {
    //     setLoad(!isLoad)
    //     setCurrentBOMId(null)
    //     setShowAddBOMForm(!isShowAddBOMForm)
    // }

    
    const handleAddBOMShow = (id) => {
      setModals(modals.map(modal => modal.id === id ? { ...modal, show: true } : modal));
    };

    const handleCloseBOMShow = (id) => {
      setLoadComp(!loadComp)
      setNew(false)
      setModals(modals.map(modal => modal.id === id ? { ...modal, show: false } : modal));
    };

    const handleShowBomTreeView = () => {
      setShowTreeView(!isShowTreeView)
    }

    const handleCloseBomTreeView = () => {
      setCurrentBOMId(null)
      setShowTreeView(!isShowTreeView)
    }

    const CustomViewModalComponent = (data) => {
        setCurrentBOMId(data.id);
        handleAddBOMShow(1);
    }

    const CustomTreeViewComponent = (data) => {
      setCurrentBOMId(data.id);
      handleShowBomTreeView();
    }
  
    const CustomAddModalComponent = () => {
      setCurrentBOMId(null);
      setNew(true)
      handleAddBOMShow(1);
    }

    const handleDuplicateCurrentBom = () =>{
      const newId = modals.length > 0 ? modals[modals.length - 1].id + 1 : 1;
      setModals([...modals, { id: newId, show: true, isDuplicate: true }]);
    }

    const bom_column_details = [
        {
            header: 'ID',
            field: 'id',
            flex: 5,
            inputType: 'number',
            align: 'center',
            renderComponent: CustomTreeViewComponent,
        },
        {
          header: 'BOM No',
          field: 'bomNo',
          flex: 8,
          inputType: 'text',
          query: 'bomNumberOptionQuery'
      },
        {
          header: 'BOM Name',
          field: 'bomName',
          flex: 10,
          inputType: 'text',
          renderComponent: CustomViewModalComponent,
        },
        {
          header: 'Status',
          field: "status", 
          label: "status",
          flex: 5,
          inputType: 'text',
          option: true,
          query: "bomStatusOptionQuery",
          key: "value",
        },
        {
          header: 'BOM Type',
          field:"bomType", 
          flex: 12,
          inputType: 'text',
          option: true,
          query: 'bomTypeOptionQuery',
          key: 'label'
        },
        {
          header: 'FG Name',
          field:"finishedGoods", 
          label: 'partNo.itemName',
          flex: 12,
          inputType: 'text',
          query: "finishedGoodsOptionQuery",
          key: 'value'
        },
        {
          header: 'Number of RM',
          field:"totalRawMaterial", 
          flex: 5,
          align: "center",
          inputType: 'number',
        },
        {
          header: 'Last Modified By',
          field:"modifiedBy", 
          label: 'username',
          flex: 7,
          inputType: 'text',
          option: true,
          query: "userOptionQuery",
          key: "value",
        },
        {
          header: 'Last Modified Date',
          field:"updatedAt", 
          flex: 5,
          inputType: 'text',
          isDateField: true,
        },
    ]

    return(
        <>
        <SideNavbar/>
        <div id="custom-table-itemmaster">
            <TableView 
                headers = {bom_column_details}
                query = {'bomQuery'}
                addComponent = {CustomAddModalComponent}
                loadComponent = {isLoad}
                titleName = {'Bill of Materials'}
                heightAdjust = {'40rem'} 
            />
        </div>
        {modals.map(modal => (
            <AddBOM 
                modal = {modal}
                handleCloseBOMShow = {handleCloseBOMShow}
                isShowAddBOMForm = {isShowAddBOMForm}
                isLoad = {isLoad}
                setLoad = {setLoad}
                currentBomId = {currentBOMId}
                isEditDelOption = {!isNew}
                isNew = {isNew}
                loadComp = {loadComp}
                handleDuplicateCurrentBom = {handleDuplicateCurrentBom}
                key = {modal.id}
            />
          ))
        }
        { isShowTreeView && 
            <BomTreeModal 
              show = {isShowTreeView}
              close = {handleCloseBomTreeView}
              bomId = {currentBOMId}
            />
        }
        </>
    )
}

export default BOM; 