import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { ToastContainer } from 'react-toastify'
import axiosInstance from '../../api/axoiss'
import Can from '../../components/authorizationComponents/Can'
import showErrorToast from '../../components/notifications/react-toastify/toast'
import DataContext from '../../context/ItemMasterContext'
import { conferenceCreateMutation } from '../../GraphQLQueries/EnquiryMutations'
import {
  storeFetchQuery
} from '../../GraphQLQueries/ItemmasterQuery'
import { fetchStoreData } from '../../ReduxState/Action/storeActions'
import {
  customSelectStyle,
  removeEmptyValueInObject,
  ShowErrorNoties
} from '../../utils/Utils'
import { AddConferenceSchema } from '../../validations/Enquiryvalidation'
import DeleteData from '../Comps/Delete'

const ConferenceForm = ({
  handleConferenceFromClose,
  AddConference,
  conferenceEdit,
  setConferenceEdit,
  setLoad
}) => {
  const { CommanFetchForAllUser, allUsersList, setConference, userId, prefetchValue,userdata} = useContext(DataContext)
  const dispatch = useDispatch()
  const getData = useSelector(state => state.itemGroup.data)
 
  const storeDataList = useSelector(state => state.storeData.data)
  const [additionalIncharges, setAdditionalIncharges] = useState([])
  const [InchargeSelect, setInchargeSelect] = useState('')
  const handleselectIncharge = option => {
    setInchargeSelect({ value: option?.value, label: option?.label })
  }
  const [defaultStoreSeletc, setDefaultStoreSeletc] = useState({
    value: '',
    label: ''
  })
  const handleselectDefaultStore = option => {
    setDefaultStoreSeletc({ value: option?.value, label: option?.label })
  }
  const [currencySelect, setCurrencySelect] = useState({ value: '', label: '' })
  const handleSelectCurrency = option => {
    setCurrencySelect({ value: option?.value, label: option?.label })
  }
  
  const [initialConferenceValues, setInitialConferenceValues] = useState({
    name: '',
    Startdate: '',
    enddate: '',
    incharge: '',
    Status: true,
    DefaultStore: '',
    Currency: '',
    additionalIncharges: [],
    createdBy: ''
  })
  const [isEditView, setEditView] = useState(true)
  const handleChangeEditView = () => {
    setEditView(!isEditView)
  }
  // retun null;prefetch data
  useEffect(() => {
    // This function will only run when userId changes
    const fetchData = () => {
    
      dispatch(fetchStoreData(storeFetchQuery()));
    };

    if (userId) {
      fetchData();
    }
  }, [userId, dispatch]);

  // retun null; update the edit data
  useEffect(() => {
    if (conferenceEdit) {
      setEditView(false)
      initialConferenceValues.name = conferenceEdit['name']
      initialConferenceValues.incharge = conferenceEdit['inCharge']?.id
      initialConferenceValues.Startdate = conferenceEdit['startDate']
      initialConferenceValues.enddate = conferenceEdit['endDate']
      initialConferenceValues.Status = conferenceEdit['status']
      initialConferenceValues.DefaultStore = conferenceEdit['defaultStore']?.id ? Number(conferenceEdit['defaultStore']?.id) : ""
      initialConferenceValues.Currency = conferenceEdit['currency']?.id ? Number(conferenceEdit['currency']?.id) : ""
      initialConferenceValues.createdBy = conferenceEdit?.['createdBy']?.['id']
      initialConferenceValues.additionalIncharges =
      conferenceEdit?.additionalInCharges.map(user => ({
        value: user?.id,
        label: user?.username
      }))
      handleSelectCurrency({
        value: conferenceEdit?.currency?.id,
        label: conferenceEdit?.currency?.Currency?.name
      })
      handleselectDefaultStore({
        value: conferenceEdit?.defaultStore?.id,
        label: conferenceEdit?.defaultStore?.storeName
      }) 
      
      // setCurrencySelect({ value: option?.value, label: option?.label })
      // setDefaultStoreSeletc({ value: option?.value, label: option?.label })
      setAdditionalIncharges(
        conferenceEdit?.additionalInCharges.map(user => ({
          value: user?.id,
          label: user?.username
        }))
      )
    } else {
      setEditView(true)
      setInitialConferenceValues({
        name: '',
        Startdate: '',
        enddate: '',
        incharge: '',
        Status: true,
        DefaultStore: '',
        Currency: '',
        createdBy: '',
        additionalInCharges: []
      })
    }

    if (initialConferenceValues.incharge) {
      setInchargeSelect({
        value: conferenceEdit?.['inCharge']?.id,
        label: conferenceEdit?.['inCharge']?.username
      })
    }
    
  }, [initialConferenceValues.incharge])

  // retun null; make clear the value
  const reset_From = () => {
    setConferenceEdit('')
    setInchargeSelect('')
    setCurrencySelect('')
    setDefaultStoreSeletc('')
  }
  // retun null; submit conference
  const handlesubmit = async (
    values,
    { setSubmitting, resetForm, setErrors }
  ) => {
    const startDateObject = new Date(values['Startdate'])
    const day = startDateObject.getDate().toString().padStart(2, '0')
    const month = (startDateObject.getMonth() + 1).toString().padStart(2, '0')
    const year = startDateObject.getFullYear()
    const formattedStartdate = `${year}-${month}-${day}`
    const endDateObject = new Date(values['enddate'])
    const day_ = endDateObject.getDate().toString().padStart(2, '0')
    const month_ = (endDateObject.getMonth() + 1).toString().padStart(2, '0')
    const year_ = endDateObject.getFullYear()
    const formattedEndtdate = `${year_}-${month_}-${day_}`
    const saveConference = {
      id: conferenceEdit?.id ? conferenceEdit.id : '',
      name: values['name'],
      startDate: formattedStartdate,
      endDate: formattedEndtdate,
      inCharge: Number(values['incharge']),
      status: values['Status'],
      createdBy: conferenceEdit.id
        ? Number(initialConferenceValues?.createdBy)
        : Number(userId),
      defaultStore: Number(values['DefaultStore']),
      currency: Number(values['Currency']),
      additionalInCharges: [values['additionalInCharges']]
    }
    let clearValue = removeEmptyValueInObject(saveConference)

    try {
      const response = await axiosInstance.post('/itemmaster/graphql', {
        query: conferenceCreateMutation(clearValue)
      })
      let responseData = response?.data?.data?.conferenceDataCreateMutation
      if (responseData?.success) {
        responseData = responseData?.conferenceDataItem
        setConference(prevData => {
          return prevData.map(item => {
            return item.id === responseData?.id ? responseData : item
          })
        })
        resetForm()
        reset_From()
        handleConferenceFromClose()
        setLoad()
        showErrorToast(true, 'success', '', 'Successfully Saved')
      } else {
        let errorData = ShowErrorNoties(responseData?.errors)
        showErrorToast(true, 'error', `${errorData}`, '')
      }
    } catch (e) {
      let errorData = ShowErrorNoties(e)
      showErrorToast(true, 'error', `${errorData}`, '')
    }
  }

  const [showDeleteConfirmation, setDeleteConfirmation] = useState(false)
  // retun null; delete process
  const proceedToDelete = status => {
    if (status) {
      let delete_query = `mutation DeleteConferenceData{
        conferenceDataDeleteMutation(id: ${conferenceEdit?.id}){
          success
          errors
        }
      }`
      try {
        axiosInstance
          .post('/itemmaster/graphql', { query: delete_query })
          .then(res => {
            let response = res?.data?.data?.conferenceDataDeleteMutation
            reset_From()
            handleConferenceFromClose()
            setLoad()
            if (response?.success) {
              showErrorToast(true, 'success', '', 'Data Deleted Successfully')
            
            } else {
              let errorData = ShowErrorNoties(response?.errors)
              showErrorToast(true, 'error', `${errorData}`, '')
            }
          })
          .catch(e => {
            let errorData = ShowErrorNoties(e)
            showErrorToast(true, 'error', `${errorData}`, '')
          })
      } catch (e) {
            let errorData = ShowErrorNoties(e)
            showErrorToast(true, 'error', `${errorData}`, '')
      }
    }
  }

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmation(false)
  }

  return (
    <>
      <ToastContainer />
      <Modal
        size='lg'
        show={AddConference}
        onHide={() => {
          handleConferenceFromClose()
          reset_From()
        }}
      >
        <Modal.Header>
          <Modal.Title style={{ width: '100%' }}>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <h5 style={{ color: '#5CADA9' }}>
                  <i
                    className='fa-solid fa-arrow-left fa-sm me-2'
                    onClick={() => {
                      handleConferenceFromClose()
                      reset_From()
                    }}
                  ></i>
                  {conferenceEdit ? 'Edit Conference' : 'Add Conference'}
                </h5>
              </div>
              {conferenceEdit && (
                <div style={{ fontSize: '1rem' }}>
                  <Can I={'Edit'} a={'Conference'}>
                    <span>
                      <i
                        className={`fa-solid fa-pen me-3`}
                        role='button'
                        onClick={() => {
                          handleChangeEditView()
                        }}
                      ></i>
                    </span>
                  </Can>
                  <Can I={'Delete'} a={'Conference'}>
                    <span>
                      <i
                        className='fa-solid fa-trash me-3 text-danger'
                        onClick={() => {
                          setDeleteConfirmation(true)
                        }}
                      ></i>
                    </span>
                  </Can>
                </div>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialConferenceValues}
            validationSchema={AddConferenceSchema}
            onSubmit={handlesubmit}
          >
            {formikProps => (
              <Form>
                <div className='row'>
                  <div className='col-6 text-start mt-md-2 staan-input-group'>
                    <Field
                      type='text'
                      name='name'
                      placeholder=''
                      className='w-100 staan-form-input'
                      disabled={!isEditView}
                    />
                    <label
                      htmlFor='name'
                      className='staan-form-input-label pt-1 px-1 mx-2'
                      onClick={()=>{console.log(initialConferenceValues);
                      }}
                    >
                      Name
                    </label>
                    {formikProps.touched.name && formikProps.errors.name && (
                      <small>{formikProps.errors.name}</small>
                    )}
                  </div>
                  <div className='col-6 mt-md-2 staan-input-group'>
                    <Select
                    onInputChange={(option)=>{
                      CommanFetchForAllUser(option)
                    }}
                      name='incharge'
                      id='incharge_'
                      value={InchargeSelect}
                      options={allUsersList}
                      onChange={option => {
                        formikProps.setFieldValue(
                          'incharge',
                          option ? option?.value : null
                        )
                        handleselectIncharge(option)
                      }}
                      styles={customSelectStyle}
                      className='mt-3'
                      isClearable
                      isDisabled={!isEditView}
                    />
                    <label className='staan-form-input-label pt-1 px-1 mx-2'>
                      Incharge
                    </label>
                    {formikProps.touched.incharge &&
                      formikProps.errors.incharge && (
                        <small>{formikProps.errors.incharge}</small>
                      )}
                  </div>
                  <div className='col-6 mt-md-2 staan-input-group'>
                    <input
                      type='date'
                      id='Startdate'
                      name='Startdate'
                      className='w-100 staan-form-input'
                      value={formikProps.values.Startdate}
                      onChange={formikProps.handleChange}
                      disabled={!isEditView}
                    />
                    <label className='staan-form-input-label pt-1 px-1 mx-2'>
                      Start Date
                    </label>
                    {formikProps.touched.Startdate &&
                      formikProps.errors.Startdate && (
                        <small>{formikProps.errors.Startdate}</small>
                      )}
                  </div>
                  <div className='col-6 mt-md-2 staan-input-group'>
                    <input
                      type='date'
                      id='enddate'
                      name='enddate'
                      className='w-100 staan-form-input'
                      value={formikProps.values.enddate}
                      onChange={formikProps.handleChange}
                      disabled={!isEditView}
                    />
                    <label className='staan-form-input-label pt-1 px-1 mx-2'>
                      End Date
                    </label>
                    {formikProps.touched.enddate &&
                      formikProps.errors.enddate && (
                        <small>{formikProps.errors.enddate}</small>
                      )}
                  </div>
                  <div className='col-6  mt-md-2  staan-input-group'>
                    <Select
                      name='DefaultStore'
                      id='DefaultStore'
                      value={defaultStoreSeletc}
                      options={storeDataList?.items?.map(item => ({
                        value: item.id,
                        label: item.storeName
                      }))}
                      onChange={option => {
                        formikProps.setFieldValue(
                          'DefaultStore',
                          option ? option?.value : null
                        )
                        handleselectDefaultStore(option)
                      }}
                      styles={customSelectStyle}
                      className='mt-3'
                      isClearable
                      isDisabled={!isEditView}
                    />
                    <label className='staan-form-input-label pt-1 px-1 mx-2'>
                      Default Store
                    </label>
                    {formikProps.touched.DefaultStore &&
                      formikProps.errors.DefaultStore && (
                        <small>{formikProps.errors.DefaultStore}</small>
                      )}
                  </div>
                  <div className='col-6  mt-md-2  staan-input-group'>
                    <Select
                      name='Currency'
                      id='Currency'
                      value={currencySelect}
                      options={prefetchValue?.currencyExchangeList}
                      onChange={option => {
                        formikProps.setFieldValue(
                          'Currency',
                          option ? option?.value : null
                        )
                        handleSelectCurrency(option)
                      }}
                      styles={customSelectStyle}
                      className='mt-3'
                      isClearable
                      isDisabled={!isEditView}
                    />
                    <label className='staan-form-input-label pt-1 px-1 mx-2'>
                      Currency
                    </label>
                    {formikProps.touched.Currency &&
                      formikProps.errors.Currency && (
                        <small>{formikProps.errors.Currency}</small>
                      )}
                  </div>
                  <div className='col-6 staan-input-group'>
                    <Select
                      onInputChange={e => {
                        CommanFetchForAllUser(e)
                      }}
                      styles={customSelectStyle}
                      className='my-3'
                      isClearable
                      isSearchable={true}
                      options={allUsersList}
                      isDisabled={!isEditView}
                      value={additionalIncharges}
                      onChange={options => {
                        const selectedValues = options
                          ? options.map(option => Number(option.value))
                          : []
                        console.log(selectedValues)

                        formikProps.setFieldValue(
                          'additionalInCharges',
                          selectedValues
                        )
                        setAdditionalIncharges(options)
                      }}
                      isMulti
                    />
                    <label
                      htmlFor='supplierData'
                      className='staan-form-input-label pt-1 px-1 mx-2'
                    >
                      Additional Incharges
                    </label>
                  </div>
                  <div className='col-md-6 col-12 mt-md-2'>
                    <label>
                      Active
                      <Field
                        type='checkbox'
                        name='Status'
                        checked={
                          formikProps.values.Status !== ''
                            ? formikProps.values.Status
                            : initialConferenceValues.Status
                        }
                        onChange={e => {
                          formikProps.setFieldValue('Status', e.target.checked)
                        }}
                        className='ms-2 me-4'
                        disabled={!isEditView}
                      />
                    </label>
                    {formikProps.touched.Status &&
                      formikProps.errors.Status && (
                        <small>{formikProps.errors.Status}</small>
                      )}
                  </div>
                </div>
                <div className='row mt-4 mx-2'>
                  <div className='text-end'>
                    <Can allow_list={['Save_Conference', 'Edit_Conference']}>
                      <button type='submit' className='btn btn-success'>
                        Save
                      </button>
                    </Can>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {showDeleteConfirmation && (
        <DeleteData
          value={conferenceEdit?.name}
          show={showDeleteConfirmation}
          close={handleCloseDeleteConfirmation}
          proceedDelete={d => {
            proceedToDelete(d)
          }}
        />
      )}
    </>
  )
}

export default ConferenceForm
