import html2pdf from 'html2pdf.js';
import React, { useRef } from 'react';
import logofinal from '../../img/logo-final.png';
import "./salesOrderPdfTemplate.css";


const SalesOrderPdfTemplate = () => {
    const pdfRef = useRef();
    const handlePdfReportDownload = () => {
        setTimeout(() => {
          const element = pdfRef.current;
        
          
    
          if (!element) {
            console.error("No content found for PDF generation.");
            return;
          }
    
          const options = {
            margin: 0,
            filename: 'quotation.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'cm', format: 'a4', orientation: 'landscape' },
          };
    
          html2pdf()
            .from(element)
            .set(options)
            .save()
            .catch((err) => {
              console.error("PDF generation failed:", err);
            });
        }, 100);
    };
    
    return (
        <div ref={pdfRef} style={{
            width: '30cm',
            overflow: 'hidden',
            padding: '1cm',
            margin: '0',
            alignContent: 'center',
        }}>
            <div className='salesOrderPDF' style={ {border:"solid 1px black"}}>
            <header className='row align-items-start justify-content-between' style={{ marginTop: '0', padding: "1rem"  }}>
                <div className='col-8'>
                    <h1 style={{ fontSize: '1.5em' }}>Staan Bio-Med Engineering Private Limited</h1>
                    <div className='row mt-3'>
                    <div className='col-6  fs-6'>
                        <p>
                        190-A, Bharathiyar Rd, opp. Martin Residency, Alamelu Manga Puram,
                        <br />
                        Ganapathy, Coimbatore, Tamil Nadu 641006
                        </p>
                        <p>GST IN: 123456789</p>
                    </div>
                    <div className='col-6 fs-6 '>
                        <p>
                            Phone: 0422 253 3806
                            <br/>
                            Website: <a href="https://www.staanbiomed.com/" target="_blank" rel="noopener noreferrer">staanbiomed.com</a>
                            <br/>
                            Email: <a href="mailto:info@staan.in">info@staan.in</a>
                        </p>
                        
                        
                    </div>
                    </div>
                </div>
                <div className='col-4'>
                    <img src={logofinal} alt="Logo" className="img-fluid" style={{ maxHeight: '80px', width: 'auto' }} />
                </div>
            </header>
            <div className='  align-items-start justify-content-between '>
                
                <p className='text-center'>Quotation</p>
                <div>
                <div className='row d-flex align-items-start m-0 'style={{border:".5px solid black", borderCollapse: 'collapse'}} >
                            <div className="col-6 p-1 " style={{borderBottom: ".5px solid black", borderRight: ".5px solid black"}}>
                                <p className='text-center p-0 m-0'>Customer Details</p>
                            </div>
                            <div className="col-6  p-1 " style={{borderBottom: ".5px solid black"}}>
                                <p className='text-center p-0 m-0'>Quotation Details</p>
                            </div>
                            <div className='col-6' style={{borderRight: ".5px solid black"}}>
                                <div className='row'>
                                    <div className='col-12'>
                                    <p className='m-0'>Customer Id: 123</p>
                                    <p className='m-0'>Address: 190 A, Bharathiar Road, Ganapathy,  Coimbatore - 641006,<br/> Tamil Nadu, India.</p>
                                    
                                    </div>
                                    <div className="col-6 mt-2">
                                    <p className='m-0'>Person Name: Jagathese</p>
                                    <p className='m-0'>Person Phone Number: 123456789</p>
                                    </div>
                                    <div className='col-6'>
                                    <p>Name: Sudarshan</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6' >
                                <div className='row' style={{ margin: '0' }}>
                                    <div className='col-6'>
                                    <p>Quotation No: 123</p>
                                    <p>Sales Person: Sudarshan</p>
                                    </div>
                                    <div className='col-6'>
                                    <p>Quotation Date: 123</p>
                                    <p>Department: IT</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div className='align-items-start justify-content-between '>
            
                <div className='table-responsive'>
                <table className='table '  >
                    <thead>
                        <tr>
                            <th className='text-center'>S No</th>
                            <th className='text-center'>Part Code</th>
                            <th className='text-center'>Description</th>
                            <th className='text-center'>HSN/SAC</th>
                            <th className='text-center'>Qty</th>
                            <th className='text-center'>UOM</th>
                            <th className='text-center'>Rate</th>
                            <th className='text-center'>Discount</th>
                            <th className='text-center' colSpan="2"  >Tax</th>
                            <th className='text-center'>Total</th>
                        </tr>
                        <tr>
                            <th className='text-center'  colSpan="8"></th> 
                            <th className='text-center'>%</th>
                            <th className='text-center'>Amount</th>
                            <th className='text-center'></th>
                        </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td className='text-center'>1</td>
                        <td className='text-center'>PC-001</td>
                        <td>Sample Part Description</td>
                        <td className='text-center'>1234</td>
                        <td className='text-center'>10</td>
                        <td className='text-center'>Pcs</td>
                        <td className='text-center'>100.00</td>
                        <td className='text-center'>5</td>
                        <td className='text-center'>5</td>
                        <td className='text-center'>1500</td>
                        <td className='text-center'>105.00</td>
                    </tr>
                    <tr>
                        <td className='text-center'>2</td>
                        <td className='text-center'>PC-002</td>
                        <td>Sample Part Description</td>
                        <td className='text-center'>56789</td>
                        <td className='text-center'>10</td>
                        <td className='text-center'>Pcs</td>
                        <td className='text-center'>200.00</td>
                        <td className='text-center'>5</td>
                        <td className='text-center'>5</td>
                        <td className='text-center'>2000</td>
                        <td className='text-center'>150.00</td>
                    </tr>
                    {/* Add more rows as needed */}
                    </tbody>
                </table>
                </div>
           
            </div>
            <div className='row align-items-start justify-content-between  '>
            <div className='col-6'></div>
            <div className='col-6'>
                <div className='table-responsive'>
                    <p>Other Income Charges</p>
                <table className='table '  style={{ borderCollapse: 'collapse', borderColor:"black !important" }}>
                    <thead>
                            <tr>
                            <th className='text-center'>Account</th>
                            <th colSpan="2" className='text-center'>Tax</th>
                            <th className='text-center'>Amount</th>
                            </tr>
                            <tr>
                            <th className='text-center'></th>
                            <th className='text-center'>%</th>
                            <th className='text-center'>Amount</th>
                            <th className='text-center'></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td className='text-center'>Service Charge</td>
                            <td className='text-center'>5%</td>
                            <td className='text-center'>5%</td>
                            <td className='text-center'>500.00</td>
                            </tr>
                            {/* Add more rows as needed */}
                        </tbody>
                </table>
                </div>
            </div>
            </div>
            <div className='row align-items-start justify-content-between mt-4'>
            <div className='col-6'></div>
            <div className='col-6 text-left'>
                <div className='row'>
                <div className='col-6'>
                    <p>Total Amount : </p>
                    <p>Total Tax : </p>
                    <p>Total Amount After Tax : </p>
                </div>
                <div className='col-6'>
                    <p>15000</p>
                    <p>500</p>
                    <p>15500</p>
                </div>
                </div>

            </div>
            </div>
            <div className='row align-items-start justify-content-between mt-4'>
            <div className='col-12'>
                <div className='border border-secondary rounded-top p-2'>
                <span className='font-weight-bold p-2'>
                    Total Amount in words:
                </span>
                <span>
                    Fifteen Thousand Five Hundred Only
                </span>
                </div>
            </div>
            </div>
            <div className='row align-items-start justify-content-between mt-4'>
            <div className='col-6'>
                <p className='font-weight-bold' style={{ textDecoration: 'underline' }}>Bank Details:</p>
                <p>Name: INDIAN BANK</p>
                <p>Account No: 909840212</p>
                <p>Branch: Ganapathy</p>
                <p>IFSC Code: IDIB000G059</p>
            </div>
            </div>
            <div className='row align-items-start justify-content-between mt-4'>
            <div className='col-6'>
                <h1 style={{ fontSize: '1.5em' }}>Terms and Conditions</h1>
                <ol>
                <li>Transportation Charges Extra as applicable.</li>
                <li>Any manufacturing defect will be rectified free of cost within the warranty period or one year from the date of installation, excluding cushion.</li>
                <li>Delivery will be done within 4 weeks from the date of confirmed order along with advance.</li>
                <li>Payment 50% as advance and balance against delivery.</li>
                </ol>
            </div>
            </div>
            <div className='row align-items-start justify-content-between mt-4'>
            <div className='col-6'></div>
            <div className='col-6 text-right'>
                <p>For <strong>Staan Bio-Med Engineering Private Limited</strong></p>
                <div style={{ height: '50px' }}></div>
                <p>Authorized Signatory</p>
            </div>
            </div>
            <div className='row align-items-start justify-content-between mt-4'>
            <div className='col-12'>
                <div className='table-responsive'>
                <table className='table table-bordered'>
                    <thead>
                    <tr>
                        <th className='text-center'>S No</th>
                        <th className='text-center'>Part Code</th>
                        <th className='text-center'>Description</th>
                        <th className='text-center'>HSN/SAC</th>
                        <th className='text-center'>Qty</th>
                        <th className='text-center'>UOM</th>
                        <th className='text-center'>Rate</th>
                        <th className='text-center'>Discount</th>
                        <th colSpan="2" className='text-center'>Tax</th>
                        <th className='text-center'>Total</th>
                    </tr>
                    <tr>
                        <th className='text-center'></th>
                        <th className='text-center'></th>
                        <th className='text-center'></th>
                        <th className='text-center'></th>
                        <th className='text-center'></th>
                        <th className='text-center'></th>
                        <th className='text-center'></th>
                        <th className='text-center'></th>
                        <th className='text-center'>%</th>
                        <th className='text-center'>Amount</th>
                        <th className='text-center'></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className='text-center'>1</td>
                        <td className='text-center'>PC-001</td>
                        <td>Sample Part Description</td>
                        <td className='text-center'>1234</td>
                        <td className='text-center'>10</td>
                        <td className='text-center'>Pcs</td>
                        <td className='text-center'>100.00</td>
                        <td className='text-center'>5</td>
                        <td className='text-center'>5</td>
                        <td className='text-center'>1500</td>
                        <td className='text-center'>105.00</td>
                    </tr>
                    <tr>
                        <td className='text-center'>2</td>
                        <td className='text-center'>PC-002</td>
                        <td>Sample Part Description</td>
                        <td className='text-center'>56789</td>
                        <td className='text-center'>10</td>
                        <td className='text-center'>Pcs</td>
                        <td className='text-center'>200.00</td>
                        <td className='text-center'>5</td>
                        <td className='text-center'>5</td>
                        <td className='text-center'>2000</td>
                        <td className='text-center'>150.00</td>
                    </tr>
                    {/* Add more rows as needed */}
                    </tbody>
                </table>
                </div>
            </div>
            </div>

            </div>
         
 
            {/* PDF Download Button */}
            <div className='row justify-content-end mt-4'>
            <div className='col-4 text-right'>
                <button
                className='btn btn-primary'
                onClick={handlePdfReportDownload}
                >
                Download as PDF
                </button>
            </div>
            </div>
        </div >
    )
}

export default SalesOrderPdfTemplate