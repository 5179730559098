import React, { useContext, useState } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import DataContext from '../../context/ItemMasterContext'
import TableView from '../../Packages/Custom/DataTable'
import ProfileForm from './models/ProfileForm'

const ProfileList = () => {
    const { screenSize } = useContext(DataContext)
    const [isLoad, setLoad] = useState(false)
    const [isShow, setIsShow] = useState(false)
    const [editData, setEditData] = useState(null)
    const CustomAddModalComponent = () => {
        setIsShow(true)
    }

    const CustomViewModalComponent = (data) => {
        setEditData(data)
        setIsShow(true)
    }
    const headers = [
        {
            header: 'Profile name',
            field: 'profileName',
            flex: 10,
            inputType: 'text',
            renderComponent: CustomViewModalComponent,
        },
        {
            header: 'Description',
            field: 'description',
            flex: 10,
            inputType: 'text',
        },

    ]
    return (
        <>
            <div className='container-fluid index_page_aliment_warp'>
                <div className="index_page_aliment_sidebar">
                    <SideNavbar />
                </div>
                <div className="index_page_aliment_table">
                    <TableView
                        headers={headers}
                        query={"allProfile"}
                        addComponent={CustomAddModalComponent}
                        loadComponent={isLoad}
                        titleName={'Profile'}
                        permission={"Save_Profile"}
                        heightAdjust={`${(screenSize?.height / 20)}rem`}
                    />
                </div>

            </div>
            <ProfileForm
                isShow={isShow}
                setIsShow={setIsShow}
                setLoad={() => { setLoad(!isLoad) }}
                editData={editData}
                setEditData={setEditData}
            />

        </>
    )
}

export default ProfileList