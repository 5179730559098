import * as  yup from "yup"

export const ItemMasterSchema = yup.object().shape({
    Item_PartCode: yup.string().required("Please Enter PartCode"),
    Item_name: yup.string().required("Please Enter Name"),
    Description: yup.string(),
    Item_Group :  yup.string().required("Please Select Group"),
    Item_type: yup.string().required("Please Select Item Type"),
    Item_UOM: yup.string().required("Please Select UOM"),
    Alternate_uom : yup.array(),
    category : yup.string().required("Please Select Category"),
    Item_Indicator: yup.string().nullable(),
    Item_Cost : yup.number(),
    Purchase_uom : yup.string().nullable(),
    Total_Stock : yup.number(),
    Rejected_Stock : yup.number(),
    Item_Safe_Stock : yup.number(),
    Item_Order_Qty : yup.number(),
    Item_Leadtime : yup.number(),
    Item_Purchase_Account : yup.string(),
    Item_Mrp : yup.number(),
    Item_min_price : yup.number(),
    Item_Sales_Account : yup.number(),
    Item_HSN:  yup.string().required("Please Enter Hsn"),
    Keep_stock: yup.boolean(),
    serial: yup.boolean(),
    Batch_number: yup.boolean(),
    serial_auto_gentrate: yup.boolean(),
    Serial_format: yup.string().when('serial_auto_gentrate', {
            is: true,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
        }),
    Serial_starting: yup.number().when('serial_auto_gentrate', {
        is: true,
        then:  (schema) => schema.required(),
        otherwise:  (schema) => schema.notRequired(),
    }),
    Service: yup.boolean(),
    Warranty_Require : yup.boolean(),
    Item_Warranty_based: yup.string(),
    Item_Installation: yup.boolean(),
    Item_Combo: yup.boolean(),
    item_Combo_data : yup.array(),
    Item_Barcode: yup.boolean(),
    item_qc: yup.boolean(),
    Item_Active: yup.boolean(),
   
    // modified_by: ""
})

export const AlternateSchema = yup.object().shape({
  uom : yup.string().required("UOM is required"),
  Conversion : yup.number().required("conversion Factor is required")
})

export const itemGroupSchema = yup.object().shape({
    Name:yup.string().required("Pls Enter Name"),
    Hsn: yup.string(),
    Group: yup.string(),
}) 


export const itemUOMschema = yup.object().shape({
    name:yup.string().required("Pls Enter Name"),
    E_Way_Bill_UOM :yup.string(),
    Description : yup.string()
})

export const itemHsnSchema =yup.object().shape({
    hsn_type : yup.string().required("Select any one"),
    hsn_code : yup.number().required("HSN Code Is Required"),
    gst_rate : yup.number(),
    cess_rate : yup.number(),
    rcm : yup.boolean(),
    itc: yup.boolean(),
    Description : yup.string().required('Description Is Required'),
    effectiveDate : yup.date().required("Effective Date Is Required")

})


export const itemAccountSchema = yup.object().shape({
    Name : yup.string().required('Pls Enter Accounts Name'),
    Group : yup.string(),
    Gst : yup.boolean(),
    tds : yup.boolean(),
    AccountType : yup.string(),
    Active : yup.boolean(),

})

export const AccountGroupSchema = yup.object().shape({
    Accounts_Group_Name : yup.string().required('Pls Enter Accounts Name'),
    Accounts_Type :yup.string().required('Pls  Select Type'),
    Group_Active : yup.boolean(),
})


export const  ItemComboSchema = yup.object().shape({
    PartCode:yup.string().required('Select Part Code'),
    PartName : yup.string().required('Select Part Name'),
    qty: yup.number().required("Pls Enter QTY"),
    isMandatory : yup.boolean(),
    displaye : yup.string().nullable()
})

export const StoreSchema = yup.object().shape({
    StoreName :yup.string().required('Pls Enter Name'),
    matained: yup.boolean(),
    Action : yup.boolean(),
    StoreAccount : yup.string().required('Pls Enter Account'),
    StoreInCharge :   yup.string()
})



export const StockSchema  = yup.object().shape({
    part_no : yup.string().required('Select Part Code'),
    part_name : yup.string().required('Select Name'),
    // store :   yup.string().required('  Select Store'),
    qty: yup.string()
    .matches(/^[-+]?[0-9]*\.?[0-9]+$/, 'Enter a valid numeric value for Qty')
    .required('Qty is required'),
    BatchNumber : yup.string(),
    UOM: yup.string().required('UOM is required'),
    // Serialnum : yup.array(),
})
export const SupplierFromSchema =yup.object().shape({
    Company_Name : yup.string().required('Enter Name'),
    Legal_Name : yup.string().required('Enter Legal Name'), 
    GSTIN_Type : yup.string().required('Select Gst Type'),
    GSTIN : yup.string().required('Enter GSTIN'),
    TCS : yup.string() ,
    Pan_no : yup.string(),
    Transporter: yup.boolean(),
    active : yup.boolean(), 
    Customer: yup.boolean(),
    customer_group: yup.string().when('Customer', {
      is: true,
      then: (schema) => schema.required("Customer Group is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    sales_person: yup.string().when('Customer', {
      is: true,  
      then: (schema) => schema.required("Sales Person is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  
    customer_credited_period: yup.number().when('Customer', {
      is: true,
      then: (schema) => schema.required("Credited Period is required"),
      otherwise: (schema) => schema.notRequired(), 
    }),
  
    credited_limit: yup.number().when('Customer', {
      is: true,
      then: (schema) => schema.required("Credited Limit is required"),
      otherwise: (schema) => schema.notRequired(), 
    }),
    Supplier: yup.boolean(),
    supplier_group: yup.string().when('Supplier', {
      is: true,
      then: (schema) => schema.required("Supplier Group is required"),
      otherwise: (schema) => schema.notRequired(),  
    }),
  
    supplier_credited_period: yup.number().when('Supplier', {
      is: true,
      then: (schema) => schema.required("Credited Period is required"),
      otherwise: (schema) => schema.notRequired(),   
    }),

    contact: yup.array(),
        TransporterId: yup.string().when('Transporter', {
        is: true,
        then: (schema) => schema.required("Enter TransporterId "),
        otherwise: (schema) => schema.notRequired()  // No requirement when Transporter is not selected
      }),
}).test(
    'conditional-required',
    'At least one of Customer, Supplier, or Transporter must be selected',
    function (value) {
      const { Customer, Supplier, Transporter } = value;
  
      if (!(Customer || Supplier || Transporter)) {
        return this.createError({
          path: 'Transporter',
          message: this.message,
        });
      }
  
      return true;
    }
  );
export const  SupplierGroupSchema =  yup.object().shape({
  name : yup.string().required('Name is required'),
  parent_group : yup.string(),
  Active : yup.boolean() 
})
export const  CustomerGroupSchema =  yup.object().shape({
  name : yup.string().required('Name is required'),
  parent_group : yup.string(),
  Active : yup.boolean()  
})
  
export const ContectSchema = yup.object().shape({
 
    Contact_Person_Name : yup.string().required('Enter Name'),
    Salutation :  yup.string().required('Select Salutation'),
    Email : yup.string().email("Please Enter Valid Email") ,
    Phone_number : yup.string()
    .min(10, 'Mobile number must be at least 10 characters')
    .max(15, 'Mobile number must be less than or equal to 15 characters')
    .required('Please Enter Mobile'), 
    WhatsappNo : yup.string()
    .nullable()
    .min(10, 'Mobile number must be at least 10 characters')
    .max(15, 'Mobile number must be less than or equal to 15 characters'),
    OtherNo : yup.string()
    .nullable()
    .min(10, 'Mobile number must be at least 10 characters')
    .max(15, 'Mobile number must be less than or equal to 15 characters'),
    
})
//  "WhatsappNo": null,
// "OtherNo": null

export const AddressSchema = yup.object().shape({
     address_type : yup.string().required('Select Type'),
     Address_Line_1 : yup.string().required('Enter Address'),
     Address_Line_2 : yup.string().required('Enter Address'),
     City : yup.string().required('Enter City'),
     pincode : yup.number("Invalid").max(6,"Invalid data").min(6, "Invalid data"),
     State :  yup.string("Select State"),
     Country : yup.string('Select State')
})


export const CurrencyExchangesSchema = yup.object().shape({
    Name :  yup.string().required('Enter Name'),
    Rate :  yup.string().required('Enter rate'),
})

export const CurrencyMasterSchema = yup.object().shape({
    Name:  yup.string().required('Enter Name'),
    Currency_symbol: yup.string().required('Enter symbol'),
    Formate: yup.string().required('Enter Formate'),
    Active:  yup.boolean(),
})

export const SalesorderSchema = yup.object().shape({
    IsPOS : yup.boolean(),
    posType : yup.string().required('Select Pos Type'),
    marketingEvent : yup.string().required('Select Marketing Event'),
    OrderDate : yup.date().required('Enter Date'),
    Mobile : yup.number().required('Enter Number'),
    CosName : yup.string().required('Enter Customer Name'),
    Email : yup.string().email("Please Enter Valid Email").required("Please Enter Email"),
    City : yup.string().required('Enter City'),
    Remark : yup.string(),
    Currency :yup.string().required('Enter Currency Name')
})
 
export const PosItemSchema = yup.object().shape({
    partCode :yup.string().required('Part Code is required'),
    partName : yup.string().required('Part Name is required'),
    Description : yup.string(),
    qty : yup.number().required('Qty is required'),
    uom : yup.string().required('Uom is required'),
    rate: yup.number().required('Rate is required'),
    Amount  :yup.number(), 
    BatchNo : yup.string()
})


export const AddressFromSchema = yup.object().shape({
    address_type :  yup.string().required('Address Type is required'),
    Address_Line_1 : yup.string().required('Address Line 1 is required'),
    Address_Line_2 : yup.string(),
    City : yup.string().required('City is required'),
    pincode : yup.number().required('Pincode  is required'),
    State : yup.string().required("State is required"),
    Country : yup.string().required("Country is required"),
})

export const PosSalesorderSchema = yup.object().shape({
  marketingEvent : yup.string().required('Select Marketing Event'),
  isSales : yup.boolean(),
  Store : yup.string().required('Store is required'),
  CostomerName : yup.string().when("isSales",{
    is: true,
    then: (schema) => schema.required("Customer Name is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  BillingAddress : yup.string().when("isSales",{
    is: true,
    then: (schema) => schema.required("Billing Address is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  DeliverAddress : yup.string().nullable(),
  Mobile : yup.number().when("isSales",{
    is: false,
    then: (schema) => schema.required("Mobile is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  WhatsappNumber:yup.number().nullable(),
  CosName : yup.string().when("isSales",{
    is: false,
    then: (schema) => schema.required("Customer Name is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  Email : yup.string().email("Please Enter Valid Email") ,
  State : yup.string() ,
  OrderDate : yup.date(),
 
})

export const PosSchema = yup.object().shape({
    part_no : yup.string().required('Part Code is required'),
    part_name : yup.string().required('Part Name is required'),
    qty : yup.number().required('Qty is required'),
    rate : yup.number(),
    amount : yup.number(),
    discount : yup.string(),
    tax : yup.string(),
})


export const PosPaymentSchema = yup.object().shape({
  payby: yup.string().required('Pay By type is required'),
  pay_amount: yup.number().required('Pay amount is required'),
  balance_amount: yup.number()
})


export const NumberingSchema = yup.object().shape({
  Name : yup.string().required( "Name is required"),
  Resource : yup.string().required("Resource is required"),
  Formate : yup.string().required("Formate is required"),
  Current_Value : yup.number().required("Current Value is required"),
  Default : yup.boolean(),
  Active : yup.boolean(),
})

export const LabelPrinter = yup.object().shape({
  part_no :yup.string().required('Part Code is required'),
  part_name : yup.string().required('Part Name is required'),
  qty : yup.string()
  .matches(/^[-+]?[0-9]*\.?[0-9]+$/, 'Enter a valid numeric value for Qty')
  .required('Qty is required'),
  BatchNumber : yup.string(),
})


export const PurchaseSchema = yup.object().shape({
  Item_Cost: yup.string()
      .required('Item Cost is required'),
  Purchase_uom: yup.string()
      .required('Purchase unit of measure is required'),
  Item_Purchase_Account: yup.string()
      .required('Item Purchase Account is required'),
  variation: yup.string()
      .test(
          'is-valid-variation',
          'Variation must be a valid percentage or currency amount',
          value => {
              if (value.trim() === ''){
                return true; // Allow empty string
              } 
              return /^₹\d+(\.\d+)?$/g.test(value) || /^\d+(\.\d+)?%$/g.test(value);
          }
      )
      .test(
          'is-valid-percentage-range',
          'Percentage must be between 1 and 100',
          value => {
              if (/^\d+(\.\d+)?%$/g.test(value)) {
                  const percentageValue = parseInt(value.replace('%', ''), 10);
                  return percentageValue >= 0 && percentageValue <= 100;
              }
              return true; // Passes if it's not a percentage value
          }
      )
});

export const SellingSchema = yup.object().shape({
  Item_Mrp: yup.string()
      .required('Item Mrp is required'),
  Item_Sales_Account: yup.string()
      .required('Item Sales Account of measure is required'),
});

export const PurchaseSellingSchema = yup.object().shape({
  Item_Cost: yup.string()
  .required('Item Cost is required'),
  Purchase_uom: yup.string()
    .required('Purchase unit of measure is required'),
  Item_Purchase_Account: yup.string()
    .required('Item Purchase Account is required'),
  variation: yup.string()
    .test(
        'is-valid-variation',
        'Variation must be a valid percentage or currency amount',
        value => {
            if (value.trim() === ''){
              return true; // Allow empty string
            } 
            return /^₹\d+(\.\d+)?$/g.test(value) || /^\d+(\.\d+)?%$/g.test(value);
        }
    )
    .test(
        'is-valid-percentage-range',
        'Percentage must be between 1 and 100',
        value => {
            if (/^\d+(\.\d+)?%$/g.test(value)) {
                const percentageValue = parseInt(value.replace('%', ''), 10);
                return percentageValue >= 0 && percentageValue <= 100;
            }
            return true; // Passes if it's not a percentage value
        }
    ),
  Item_Mrp: yup.string()
      .required('Item Mrp is required'),
  Item_Sales_Account: yup.string()
      .required('Item Sales Account of measure is required'), 
 
});