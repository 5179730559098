

export const idCalllog =(data)=>{
    // isEnquiry: true, id: 172
    return `
       query MyQuery {
            idCalllog(${data}) {
            calls {
                createdAt
                id
                outcome
                subject
                salesPerson{
                id
                username
                }
                createdBy{
                id
                username
                }
            }
            }
        }
    `
}


export const idMeeting = (data)=>{
    // isEnquiry: false, id: 172
    return `
   query MyQuery {
    idMeeting(${data}) {
      meeting {
        createdAt
        endDate
        endTime
        id
        outcome
        plannedEndDate
        plannedEndTime
        plannedStartDate
        plannedStartTime
        startDate
        startTime
        subject
        updatedAt
        status {
          id
          name
        }
        salesPerson{
          id
          username
        }
        createdBy{
          id
          username
        }
      }
    }
  }
    `
}


export const mixWithCalllogAndMeeting = (data)=>{
  // id: 171, isEnquiry: true
  return `
     query MyQuery {
  activities(${data}) {
    ... on CallLogType {
      id
      createdAt
       types
       outcome
      subject
      updatedAt
      createdBy{
        id
        username
      }
      salesPerson{
        id
        username
      }
    }
    ... on MeetingType {
      id
      createdAt
      endDate
      endTime
      completedDate
       outcome
      plannedEndDate
      plannedEndTime
      plannedStartDate
      plannedStartTime
      startDate
      startTime
      subject
      updatedAt
      status {
        id
        name
      }
        createdBy{
        id
        username
      }
      salesPerson{
        id
        username
      }
    }
     ... on NotesType {
      id
      note
      updatedAt
      createdAt
      createdBy{
        id
        username
      }
    }
  }
}
  `
}
