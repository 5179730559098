import React,{useContext, useState, useEffect} from 'react' 
import Modal from 'react-bootstrap/Modal'
import {   Formik , Field, Form } from 'formik';
import Select from 'react-select';
import { ItemComboSchema } from '../../../validations/itemmaster';
import DataContext from '../../../context/ItemMasterContext';
import axiosInstance from '../../../api/axoiss';
import { ToastContainer, toast,Bounce } from 'react-toastify';
import { OnchangeGetData, ShowErrorNoties, customSelectStyle, removeEmptyValueInObject} from '../../../utils/Utils';
import { useParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable'; 
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery"
import { useSelector, useDispatch } from 'react-redux';
import { fetchItemDisplayGroupData } from '../../../ReduxState/Action/itemMasterAction';
import { createItemDisplayGroup } from '../../../GraphQLQueries/ItemMastermutation';


const ItemComboForm = ({comboFrom, handelcomboFromClose, post, setPost, itemComboEdit, setItemComboEdit, handleAddedData}) => {
    const dispatch = useDispatch(); 
    const itemDisplayGroupDataList = useSelector(state => state.itemDisplayGroup.data);

    const {userId} = useContext(DataContext)
    const [initialValue, setInitialValue] = useState({
        PartCode :"",
        PartName :"",
        qty :"",
        displaye :"",
        Is_Mandatory :true,
        modifiedBy : "",
        createdBy : "",
    })
    const [PartCode, setPartCode] = useState('')
    const handleChangePartCode=(option)=>{
        setPartCode({value: option.value, label: option.label})
    }
    const [PartName, setPartName] = useState('')
    const handleChangePartName=(option)=>{
        setPartName({value: option.value, label: option.label})
    }
    const [Qty, setQty] = useState('')
    const handleChangeQty=(e)=>{
        setQty(e.target.value);
    }
    const [isMandatory, setIsMandatory] = useState(true)
    const [currentPartItemDisplayOptions, setCurrentPartItemDisplayOptions] = useState([])
    const [itemMasterOptions, setItemMasterOptions] = useState([])
    const [itemMasterOptionsName, setItemMasterOptionsName] = useState([]) 
    const [ItemDisplay, setItemDisplay] = useState('')
    
    // check it on edit 
    useEffect(() => {
        dispatch(fetchItemDisplayGroupData(QueryData.ItemDisplayGroupQuery(67787)));
        let temp_options = post?.map((item)=>({value: item?.itemDisplay?.id, label: item?.itemDisplay?.display }))
        temp_options = [...new Set(temp_options.map(option => JSON.stringify(option)))].map(str => JSON.parse(str));
        setCurrentPartItemDisplayOptions(temp_options)
        let dup = itemComboEdit  
        if(dup){
            initialValue['PartCode'] = dup["partNumber"]?.['id'] 
            initialValue['PartName'] = dup["partNumber"]?.['id'] 
            initialValue['qty'] = Number(dup["itemQty"])
            setIsMandatory(dup["Is_Mandatory"])
            setQty(Number(dup["itemQty"]))
            setPartCode({value: dup["partNumber"]?.['id'], label: dup['partNumber']?.['itemPartCode']})
            setPartName({value: dup["partNumber"]?.['id'], label: dup["partNumber"]?.['itemName'] })
            setItemDisplay({value: dup.itemDisplay?.id, label: dup.itemDisplay?.display})
        }  
    }, [itemComboEdit, dispatch])
 
    
    // return nothing; reset the values
    function reset( ) {
        setPartCode()
        setPartName()
        setQty()
        setInitialValue(
            {
            PartCode :"",
            PartName :'',
            qty :'',
            displaye :'',
            Is_Mandatory :true
            }
           
        )
        setItemComboEdit(undefined)
       
    }
   
    //  return nothing; submit functions
    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => { 
        const SaveValue ={  
            partNumber :Number(values['PartCode']),
            itemQty :Number(values['qty']),
            itemDisplay : ItemDisplay?.value ? Number(ItemDisplay.value) : "" ,
            isMandatory :values['Is_Mandatory'],  
            createdBy : Number(userId)
        } 
        if (itemComboEdit !== undefined){ 
            SaveValue['sNo'] = itemComboEdit.id ?Number(itemComboEdit['sNo']) : ""
            SaveValue['id'] =  itemComboEdit.id ?Number(itemComboEdit['id']) : "" 
            SaveValue['modifiedBy'] = itemComboEdit.id ? Number(userId):""  
            SaveValue['createdBy'] =  itemComboEdit.id ? Number(itemComboEdit['createdBy'])
             ? Number(itemComboEdit['createdBy']): Number(userId) :Number(userId) 
        } 
        let variables = removeEmptyValueInObject(SaveValue)
   
        const mutations = `mutation mutate{
            itemComboCreateMutatio(`+variables+`){
              ItemCombo{
                id
                sNo 
                partNumber{
                  id
                  itemPartCode
                  itemName
                }
                itemQty
                itemDisplay{
                  id
                  display
                }
                isMandatory
                createdBy
                {
                  id
                }
                modifiedBy
                {
                   id
                }
              }
              success
              errors
            }
          }`  
        if(itemComboEdit !== undefined){ 
         
            const response = await axiosInstance.post(`/itemmaster/graphql`, {query: mutations})
                
            if (response.data.data.itemComboCreateMutatio.success){
                const responseDatas = response.data.data.itemComboCreateMutatio.ItemCombo
                const responseData = Array.isArray(responseDatas) ? responseDatas : [responseDatas]; 
                handleAddedData(responseData)
                handelcomboFromClose()
                resetForm()
                reset()
                setItemComboEdit(undefined)
                toast.success('Updated', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            } else{
                let error = response.data.data.itemComboCreateMutatio.errors
        
                let errorData = ShowErrorNoties(error) 
                toast.error( errorData , {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
            }
                             
               
             

        } else{ 
                const response = await axiosInstance.post("/itemmaster/graphql", {query: mutations})
                if (response.data.data.itemComboCreateMutatio.success){
                    const responseDatas = response.data.data.itemComboCreateMutatio.ItemCombo
                    const responseData = Array.isArray(responseDatas) ? responseDatas : [responseDatas]; 
                    console.log('created...', responseData)
                    setPost(prevData => { 
                        // Calculate the index number based on the length of the previous data
                        const index = prevData.length + 1; 
                        // Map through the responseData and add the index to each object
                        responseData[0]['sNo'] = index; 
                        // Concatenate the newData with the previous data
                        return [...prevData, ...responseData ];
                    }); 
                    handelcomboFromClose()
                    resetForm()
                    reset()
                    toast.success('Saved', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                        setItemComboEdit()
                } else{
                    let error = response.data.data.itemComboCreateMutatio.errors
            
                    let errorData = ShowErrorNoties(error) 
                    toast.error( errorData , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                    });
                }
               
           
        }
         
    }

    // return nothing; save display group
    const handleAddNewDisplayItem = async (new_term = '') => {
        let mutation_query = createItemDisplayGroup(PartCode.value, new_term)
        axiosInstance.post("/itemmaster/graphql", {query: mutation_query})
        .then(response => {
            const keys = Object.keys(response.data.data);
            const firstKey = keys.length > 0 ? keys[0] : null;
            const data = response.data.data[firstKey]
            const secondkeys = Object.keys(data);
            const secondKey = secondkeys.length > 0 ? secondkeys[0] : null;
            if(data.success){
                setItemDisplay({label: data[secondKey].display, value: data[secondKey].id})
                setCurrentPartItemDisplayOptions(prevData => {
                    if (Array.isArray(prevData)) {
                        return [...prevData, { label: data[secondKey].display, value: data[secondKey].id }];
                    } else {
                        return [{ label: data[secondKey].display, value: data[secondKey].id }];
                    }
                }
                )
            }else{
                console.log(data.errors)
            } 
        })
        .catch(error => {
            console.log(error)
        });
    };

    // test creatable react 
    const [displayItemInputValue, setDisplayItemInputValue] = useState('');

    const handleDisplayItemChange = (newValue, actionMeta) => {
        if(actionMeta['action'] === "create-option"){ 
            handleAddNewDisplayItem(newValue.label)
        }else{
            setItemDisplay(newValue)
        }
    };

    //  onchange value 
    const handleDisplayItemInputChange = (inputValue, actionMeta) => {
        setDisplayItemInputValue(inputValue);
    };

    //  return nothing; on press enter send the option to handleDisplayItemChange
    const handleKeyDown = event => {
        if (event.key === 'Enter' && displayItemInputValue.trim() !== '') {
          const newOption = { value: displayItemInputValue.trim(), label: displayItemInputValue.trim() };
          handleDisplayItemChange(newOption, { action: 'create-option' });
          event.preventDefault();
        }
    };

    // return 0;  to get Options value
    async function getOptions(e, queryName, optionName, selectId){
        let search_term = e.trim().replace(/"/g, '\\"');
        if (queryName === "itemMasterOptionFetchQuery" && search_term && selectId === "PartCode"){
            let responseData =  await  OnchangeGetData(QueryData[queryName]({key: 'itemPartCode', value: search_term}))
            if (responseData.success){  
                setItemMasterOptions(responseData.data.itemMaster.items) 
            }  
        } else{
            setItemMasterOptions([])
        }
        if (queryName === "itemMasterOptionFetchQuery" && search_term && selectId === "PartName"){
            let responseData =  await  OnchangeGetData(QueryData[queryName]({key:'itemName', value: search_term}))
            if (responseData.success){  
                setItemMasterOptionsName(responseData.data.itemMaster.items) 
                }  
            } else{
            setItemMasterOptionsName([])
            }
        
    }

  return (
    <>
     <ToastContainer/>
      <Modal show={comboFrom}  onHide={()=>{
        handelcomboFromClose()
        reset()
        setItemComboEdit(undefined)
      }}>
        <Modal.Header closeButton>
            <Modal.Title>Item Combo Form</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
           <Formik initialValues={initialValue}
           validationSchema={ItemComboSchema}
           onSubmit={handleSubmit}>
            {({errors, touched, setFieldValue})=>(
                <>
                <Form>
                    <div className='row'>
                        <div className="col-6 staan-input-group">
                            <Select
                                name="PartCode"
                                onInputChange={(e)=>{getOptions(e,"itemMasterOptionFetchQuery","itemMaster", "PartCode")}}
                                value={PartCode}
                                onChange={(options)=>{handleChangePartCode(options);
                                    setPartName({value: options.id, label: options.name})
                                    setFieldValue('PartCode', options ? options.value : null)
                                    setFieldValue('PartName', options ? options.value : null)
                                }}
                                options={itemMasterOptions.map((item)=>({
                                    value: item.id, label: item.itemPartCode, name : item.itemName
                                }))} 
                                styles={customSelectStyle}
                                className='my-3'
                            />                              
                            <label htmlFor="PartCode" className='staan-form-input-label pt-1 px-1 mx-2'>Part Code</label>
                            {  touched.PartCode &&  errors.PartCode && <small>{errors.PartCode}</small>}
                        </div>
                        <div className="col-6 staan-input-group">
                            <Select
                                name="PartName"
                                onInputChange={(e)=>{getOptions(e,"itemMasterOptionFetchQuery","itemMaster", "PartName")}}
                                value={PartName}
                                onChange={(options)=>{handleChangePartName(options);
                                    setPartCode({value: options.id, label: options.partcode});
                                    setFieldValue('PartCode', options ? options.value : null);
                                    setFieldValue('PartName', options ? options.value : null)
                                }}
                                    options={itemMasterOptionsName.map((item)=>({
                                        value: item.id, label: item.itemName, partcode: item.itemPartCode 
                                    }))} 
                                styles={customSelectStyle}
                                className='my-3'
                            /> 
                            <label htmlFor="PartName" className='staan-form-input-label pt-1 px-1 mx-2'>Part Name  </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6  staan-input-group">
                            <Field type='number' id= "qty" name='qty' value={Qty} onChange={(e)=>{
                                handleChangeQty(e)
                                setFieldValue('qty', e.target.value)}} className='w-100 staan-form-input'/>
                            <label htmlFor="qty" className='staan-form-input-label pt-2 px-1 mx-2' >Qty</label>
                            {  touched.qty &&  errors.qty && <small>{errors.qty}</small>}
                        </div>
                        <div className="col-6 staan-input-group">
                            <CreatableSelect
                                name="displaye" 
                                isClearable
                                onChange={handleDisplayItemChange}
                                onInputChange={handleDisplayItemInputChange}
                                onKeyDown={handleKeyDown}
                                inputValue={displayItemInputValue}
                                options={currentPartItemDisplayOptions}
                                value = {ItemDisplay}
                                styles={customSelectStyle}
                                className='my-3'
                            />
                            <label htmlFor="displaye" className='staan-form-input-label pt-1 px-1 mx-2'>Display</label>
                            {  touched.displaye &&  errors.displaye && <small>{errors.displaye}</small>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-6 my-3  staan-input-group">
                            <Field type="checkbox" name="Is_Mandatory"  className='me-2' />
                            <label style={{fontSize: '0.8rem'}} className='pb-1'>is Mandatory</label>
                            {  touched.Is_Mandatory &&  errors.Is_Mandatory && <small>{errors.Is_Mandatory}</small>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='text-end'>
                            <button type="submit" className="px-3 btn btn-sm btn-outline-success shadow-sm">Save</button>
                        </div>
                    </div>
                 </Form>
                </>
            )}
           </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ItemComboForm