import React, {useMemo, useState, useEffect, useRef, useCallback} from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import axiosInstance from "../../../api/axoiss";
import {FetchMRDetailsList, MRDetailsUpdateIssuedQtyById} from '../../../GraphQLQueries/MaterialRequestQuery'

const MRDetails = ({data}) => {
    const containerStyle = useMemo(() => ({ width: "100%", height: "35rem" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const gridRef = useRef();

    const [editableRowIndex, setEditableRowIndex] = useState(null);
    const [rowData, setRowData] = useState([]);


    const onBtStopEditing = useCallback((rowIndex) => {
        gridRef.current.api.stopEditing({
            rowIndex: rowIndex,
            colKey: "issuedQty"
        })
        let data_list = [];
        gridRef.current.api.forEachNode(node => data_list.push(node.data));
        let updated_qty = data_list[rowIndex].issuedQty;
        let current_row_id = data_list[rowIndex].id;
        let mr_detail_update_query = MRDetailsUpdateIssuedQtyById(current_row_id, updated_qty);
        axiosInstance.post("/itemmaster/graphql", {query: mr_detail_update_query})
        .then(response => {
            // console.log(response)
            setRowData(data_list)
        })
        .catch(error => {
            console.log(error)
            // toast.error('Failed to Save', {
            //     position: "bottom-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            // })
        })
    }, []);
    
    const onBtStartEditing = useCallback((rowIndex, key, pinned) => {
        gridRef.current.api.setFocusedCell(rowIndex, "issuedQty", pinned);
        gridRef.current.api.startEditingCell({
            rowIndex: rowIndex,
            colKey: "issuedQty",
            rowPinned: pinned,
            key: key,
        });
    }, []);

    const ActionCellRenderer = (data) => {
        const [iconClassName, setIconClassName] = useState('fa-pen');
        const handleActionClick = () => {
            const rowIndex = data.node.rowIndex;
            if(iconClassName === 'fa-pen'){
                setEditableRowIndex(rowIndex);
                setIconClassName('fa-check fs-5 text-success')
                onBtStartEditing(rowIndex, undefined)
            }else{
                setIconClassName('fa-pen')
                onBtStopEditing(rowIndex)
            }
        }
        return (
            <>
                <div>
                    <i className={`fa-solid ms-4 fw-bold ${iconClassName}`} onClick={handleActionClick}></i>
                </div>
            </>
        )
    }

    const defaultColDef = {
        resizable: true
    }
    
    let columnDefs = [
        { 
            headerName: 'S No', 
            valueGetter: "node.rowIndex + 1", 
            flex: 1
        },
        { 
            headerName: 'Part Code', 
            field: "partNumber.itemPartCode", 
            flex: 2, 
        },
        { 
            headerName: 'Part Name', 
            field: "partNumber.itemName", 
            flex: 5, 
        },
        { 
            headerName: 'HSN', 
            field: "hsnCode.hsnCode" , 
            flex: 2
        },
        { 
            headerName: 'Qty', 
            field: "qty" , 
            flex: 2,
            cellStyle: params => {
                const itemQty = parseFloat(params.value);
                const totalStock = parseFloat(params.data.totalStock);
        
                if (totalStock >= itemQty) {
                    return { color: 'green' };
                } else {
                    return { color: 'red' };
                }
            }
        },
        { 
            headerName: 'UOM', 
            field: "uom.name" , 
            flex: 2
        },
        { 
            headerName: 'Stock', 
            field: "totalStock" , 
            flex: 2
        },
        { 
            headerName: 'Issued', 
            field: "issuedQty", 
            flex: 2,
            editable: (params) => {
                let is_editable = params.node.rowIndex === editableRowIndex;
                if(editableRowIndex === null){
                    is_editable = true
                }
                return is_editable;
            },
            valueParser: "Number(newValue)",
        },
        { 
            headerName: 'Balance', 
            flex: 2 ,
            valueGetter: params => {
                const itemQty = parseFloat(params.data.qty);
                const issuedQty = parseFloat(params.data.issuedQty);
                return itemQty - issuedQty
            }
        },
        { 
            headerName: 'Action', 
            flex: 2, 
            cellRenderer: ActionCellRenderer,
        },
    ]

    // const [columnDefs, setColumnDefs] = useState(column_def);


    useEffect(()=>{
        if(data){
            let current_store_id = data?.issuingStore?.id
            let id_list = data?.itemDetails?.map(detail => detail.id)
            axiosInstance.post("/itemmaster/graphql", {query: FetchMRDetailsList(id_list)})
            .then(response => {
                let item_list = response?.data?.data?.materialRequestItemDetails?.items || [];
                let stock_updated = item_list?.map(detail => {
                    let total_stock = 0;
                    detail?.partNumber?.itemstockSet?.map(stock_item => {
                        if(stock_item.store.id === current_store_id){
                            total_stock +=  parseFloat(stock_item.currentStock)                        
                        }
                        return null;
                    })
                    return {...detail, "totalStock": total_stock}
                })
                setRowData(stock_updated || [])
            })
            .catch(error => {
                console.log(error)
                // toast.error('Failed to Save', {
                //     position: "bottom-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                // })
            })
        }
    }, [])


    return (
        <>
            <div className="h-100 pb-5 px-2">
                <h6 style={{color: '#00B2EE'}}>Item Details</h6>
                <div className="h-100">
                    <div style={containerStyle}>
                        <div style={gridStyle} className="ag-theme-alpine">
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                            />
                        </div>
                        <div className="mt-3 d-flex justify-content-center align-items-center">
                            <div className="w-25 d-flex justify-content-between">
                                <button className="btn btn-sm btn-outline-danger px-3 py-2 fs-6">Pending</button>
                                <button className="btn btn-sm btn-outline-success px-3 py-2 fs-6">Issued</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MRDetails;