export const PosItemComboQuery = (part_id) => {
    return `query MyQuery {
      itemMaster(id: ${part_id}) {
        items {
          itemComboData {
            id
            itemQty
            isMandatory
            sNo
            itemDisplay {
              display
            }
            partNumber {
              id
              itemName
              itemPartCode
              itemMrp
              
              description
              serial
              batchNumber
              itemUom{
                id
                name
              }
              itemHsn{
                gstRates{
                  rate
                }
              }
            }  
        }
      }
    }
  }`
}

export const FetchStockByPartCode = (part_code, store) => {
  return `query StockFetch{
    itemStock(partNumber: ${part_code}, store: ${store}){
      items{
        id
        serialNumber{
          serialNumber
          id
        }
        batchNumber{
          id
          batchNumberName
        }
      }
    }
  }`
}

export const CreateSalesOrderItemCombo = () => {
  let query = `mutation MyMutation {
    salesOrderItemComboCreateMutation(`
  query += ``
  query += `){
      errors
      success
      SalesoderInstance {
        Status
        id
      }
    }
  }`
  return query;
}

export const SalesOrderItemComboCreateMutation = (data) => {
  let mutations =`
  mutation salesordersitemMutations{
    salesOrderItemComboCreateMutation(${data}){
      SalesoderInstance{
        id
        partCode{
          id
          itemName
          itemPartCode
          itemMrp
          description
          serial
          batchNumber
          itemUom{
            id
            name
          }
        }
        gstRate
        description
        qty
        rate
        amount
         
        stockReduce
        uom{
          id
          name
        }
        batch{
          id
          batchNumberName
        }
        serial{
          id
          serialNumber
        }
        createdBy{
          id
          username
        }
        discountPercentage
        discountValue
        finalValue
        discountValueForPerItem
        igst
        cgst
        sgst 
        display
      }
      success
      errors
    }
      
  }` 
  return mutations
} 

export const UpdateItemComboToSalesOrder = (id, item_combo) => {
  let query = `mutation UpdateItemComboToSalesOrder{
    salesOrderCreateMutation(id: ${id}, itemCombo: [${item_combo}]){
      SalesoderInstance{
        id
        itemCombo{
          id
        }
      }
      success
      errors
    }
  }`
  return query;
}

export const FetchPosSalesOrderItemCombo = (id_list) => {
  return `query fetchSalesOrderItemCombo {
    salesOrderItemCombo(idList: [${id_list}]) {
      items {
        id
        partCode {
          id
          itemName
          itemMrp
          itemPartCode
          serial
          batchNumber
          description
          itemUom{
            id
            name
          }
        }
        uom {
          id
          name
        }
        stockReduce
        gstRate
        finalValue
        display
        discountValueForPerItem
        discountValue
        discountPercentage
        description
        createdAt
        cgst
        amount
        igst
        batch {
          id
          batchNumberName
        }
        serial {
          id
          serialNumber
        }
        rate
        qty
        sgst
        
      }
    }
  }`
}


export const CheckPosSalesOrderItemCombo = (part_id, store) => {
  let query = `
  query MyQuery {
    stockStatementItemComboQty(id: ${part_id}, store: ${store}) {
      items {
        qty
      }
    }
  }
  `
  return query
}