import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaClipboard } from 'react-icons/fa'; // Import the copy icon from react-icons

const WaringModelWithCopy = ({ state, setState, title, text, Confirmtion, Copy }) => {
  const [copied, setCopied] = useState(false); // State to manage copied status

    const handleClose = () => {
        if (setState) {
        setState(false);
        setCopied(false); // Reset copied status when closing the modal
        }
    };

    // Function to copy text to clipboard
    const handleCopy = () => {
        navigator.clipboard.writeText(text)
        .then(() => {
            setCopied(true); // Set copied status to true
            setTimeout(() => {
            setCopied(false); // Reset after 2 seconds
            }, 2000);
        })
        .catch(err => {
            console.error('Error copying text: ', err);
        });
    };

    return (
        <>
        <Modal
            show={state}
            onHide={handleClose}
            keyboard={false}
        >
            <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title> {/* Keep the title as is */}
            </Modal.Header>
            <Modal.Body>
            {text}
            {/* Render the copy icon if the Copy prop is passed */}
            {Copy && (
                <span
                style={{ cursor: 'pointer', marginLeft: '10px', display: 'inline-flex', alignItems: 'right' }}
                onClick={handleCopy} // Call the copy function on click
                title="Copy to clipboard" // Tooltip for the icon
                >
                <FaClipboard />
                {copied && <span style={{ marginLeft: '5px', color: 'green' }}>Copied!</span>} {/* Show copied message */}
                </span>
            )}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="outline-warning" size="sm" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default WaringModelWithCopy;