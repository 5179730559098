import { AgGridReact } from 'ag-grid-react';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { TiCancel } from 'react-icons/ti';
import ReactQuill from 'react-quill';
import Select from 'react-select';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { deliveryChallanDeleteMutation, reworkDeliveryChallanCancelMutation, reworkdeliveryChallanCreateMutation, reworkdeliveryChallanItemDetailsCreateMutation, TermsConditionsFetchQuery } from '../../../GraphQLQueries/PurchaseItemMutations';
import { purchasefetchdata, reworkdeliveryChallanFetchQuery } from '../../../GraphQLQueries/PurchaseItemQueries';
import { customSelectStyle } from '../../../Packages/Custom/TableUtils/TableUtils';
import axiosInstance, { commanFetch } from '../../../api/axoiss';
import TransportDetails from '../../../components/TransportDetails';
import WaringModel from '../../../components/Warings/WaringModel';
import Can from "../../../components/authorizationComponents/Can";
import BallTriangle_ from '../../../components/loading/BallTriangle';
import DataContext from '../../../context/ItemMasterContext';
import { addSno, calculateTax, gettax, onCellDoubleClicked, removeEmptyValueInObject, roundToNearest10, ShowErrorNoties, textEditormodules } from '../../../utils/Utils';

const DeliveryChallanForm = ({SaveQir, isShowing, setIsShowing, initialDcData, rdcEdit, }) => {
    const {userId} = useContext(DataContext)
    const [billingAddressSelected, setBillingAddressSelected] = useState(null)
    const [dcItemDetails, setDcItemDetails] = useState([])
    const [totalTax, setTotalTax] = useState(0.00)
    const [roundof , setRoundof] = useState(0.00)
    const [sgstTax, setSgstTax] = useState([])
    const [cgstTax, setCgstTax] = useState([])
    const [igstTax, setIgstTax] = useState([])
    const [totalAmountBeforeTax, setTotalAmountBeforeTax]  = useState(0.00)
    const [fixedFinalAmount, setFinalAmount]  = useState(0.00)
    const [loading, setLoading] = useState(false)
    const [tCList, setTcList] = useState([])
    const [termsOptions, setTermsOptions] = useState(null)
    const [termsConditions, setTermsConditions] = useState(null)
    const [status, setStatus] = useState(null)
    const [isShowTranSpoterDetails, setIsShowTranSpoterDetails] = useState(false)
    const [ownVehicle, setOwnVehicle] = useState()
    const [courier, setCourier] =  useState()
    const [otherModel, setOtherModel] = useState()
    const [TranSpoter, setTranSpoter] = useState(null)
    const [transportData , setTransportData] =  useState({model: null})
    const [waringToCancle, setWaringToCancle] = useState(false)
    const [waringToDelete, setWaringToDelete] = useState(false)

    //initial and Edit
    useEffect(() => {
        console.log(isShowing);
        
        const fetchData = async () => {
            if (isShowing && initialDcData?.New && initialDcData?.poID) {
                setLoading(true)
                await fecthInitialData(initialDcData?.poID);
                setDcItemDetails(initialDcData?.DCItemDetails);
                setLoading(false)
            } else if (rdcEdit?.id && isShowing) {
                setLoading(true)
                let parameter = `id:${rdcEdit?.id}`;
                const response = await commanFetch(reworkdeliveryChallanFetchQuery(parameter));
                const reponseData = response?.data?.items?.[0]  
                if (response.success){
                    setInitialValues(prev => ({
                        ...prev,
                        id : reponseData?.id,
                        dcDate: reponseData?.dcDate,
                        dcNo :reponseData?.dcNo?.linkedModelId,
                        supplier_Number: reponseData?.supplier?.supplierNo,
                        supplier_name: reponseData?.supplier?.companyName,
                        supplier_id : reponseData?.supplier?.id,
                        purchase_order_no: reponseData?.purchaseOrderNo?.purchaseOrderNo?.linkedModelId,
                        purchase_id  : reponseData?.purchaseOrderNo?.id,
                        receiving_store : reponseData?.receivingStore,
                        scrap_reject_store : reponseData?.scrapRejectStore,
                        gstin : reponseData?.gstin,
                        gstin_type : reponseData?.gstinType,
                        state : reponseData?.state,
                        place_of_supply : reponseData?.placeOfSupply,
                    })) 
                    setStatus(reponseData?.status?.name)
                    setTotalAmountBeforeTax(reponseData?.beforTax)
                    setTotalTax(reponseData?.taxTotal)
                    setRoundof(reponseData?.roundOff)
                    setFinalAmount(reponseData?.netAmount)
                    const parsedAddress = JSON.parse(reponseData?.address);
                    setBillingAddressSelected(parsedAddress) 
                    let addSerialNo =  addSno(reponseData?.reworkDeliveryChallanItemDetails)
                    setDcItemDetails(addSerialNo)
                    setTermsOptions({value:reponseData?.termsConditions?.id, 
                        label: reponseData?.termsConditions?.name, 
                        tc: reponseData?.termsConditions?.tc})
                    setTermsConditions(reponseData?.termsConditionsText)
                    setOwnVehicle(JSON.parse(reponseData?.ownVehicle))
                    setCourier(JSON.parse(reponseData?.courier))
                    setOtherModel(reponseData?.otherModel)
                    if (reponseData?.vehicleNo !== null){
                        setTranSpoter({vehicleNo:reponseData?.vehicleNo ,transport:reponseData?.transportId?.companyName})
                    }
                    
                } else {
                    console.error("Error fetching data:", response.error)
                }
                setLoading(false)
                
            }
        };

        fetchData();
    }, [isShowing, ])

    async function fetchOptions(e,  name, updatedState) {
        let search_term = e.trim().replace(/"/g, '\\"');
        let queryWithPerementr = ""
        if (name === "TermsConditionsFetchQuery"){
            let peremeter = `pageSize:200, name:"${search_term}", module:"Purchase"`
            queryWithPerementr = TermsConditionsFetchQuery(peremeter)
        }
        const response = await commanFetch(queryWithPerementr);
        const reponseData = response?.data?.items
        if (response.success){
                if (name === "TermsConditionsFetchQuery"){  
                    setTcList(reponseData?.map((item)=>(
                        {value:item?.id, label: item?.name, tc: item?.tc}
                    )))
                }
             
        } else {
            console.error("Error fetching data:", response.error);
        }
    }
   
    // retun null; calculate the Value 
    useEffect(()=>{
        if (dcItemDetails.length > 0){
            let Final_value = 0
            let fixedTotalvalue = 0 
            const igstList = {}
            const cgstList = {}
            const sgstList = {}
            dcItemDetails.forEach(itemValue =>{
                fixedTotalvalue += Number(itemValue?.rate) * Number(itemValue?.reworkQty) 
                Final_value += Number(itemValue?.amount)
                let dcItemDetailsHSN =  gettax(itemValue, itemValue?.tax, billingAddressSelected?.state )
                if (dcItemDetailsHSN.igst) {
                    if (igstList[dcItemDetailsHSN.igst]) {
                        igstList[dcItemDetailsHSN.igst].push(dcItemDetailsHSN.amount)
                    } else {
                        igstList[dcItemDetailsHSN.igst] = [dcItemDetailsHSN.amount]
                    }
                    }
                if (dcItemDetailsHSN.cgst) {
                if (cgstList[dcItemDetailsHSN.cgst]) {
                    cgstList[dcItemDetailsHSN.cgst].push(dcItemDetailsHSN.amount)
                } else {
                    cgstList[dcItemDetailsHSN.cgst] = [dcItemDetailsHSN.amount]
                }
                }
                if (dcItemDetailsHSN.sgst) {
                if (sgstList[dcItemDetailsHSN.sgst]) {
                    sgstList[dcItemDetailsHSN.sgst].push(dcItemDetailsHSN.amount)
                } else {
                    sgstList[dcItemDetailsHSN.sgst] = [dcItemDetailsHSN.amount]
                }
                }
            })
            const { taxResult: igstResult, taxValues: igstaxvalues } =
            calculateTax(igstList)
            const { taxResult: cgstResult, taxValues: cgstaxvalues } =
            calculateTax(cgstList)
            const { taxResult: sgstResult, taxValues: sgstaxvalues } =
            calculateTax(sgstList)
            setTotalTax((igstaxvalues + cgstaxvalues + sgstaxvalues).toFixed(2)) 
            setIgstTax(igstResult)
            setCgstTax(cgstResult)
            setSgstTax(sgstResult)
            setTotalAmountBeforeTax(Final_value.toFixed(2))
            setFinalAmount(roundToNearest10(igstaxvalues + cgstaxvalues + sgstaxvalues+Final_value).toFixed(2)) 
            setRoundof((roundToNearest10(igstaxvalues + cgstaxvalues + sgstaxvalues+Final_value).toFixed(2)
        - (igstaxvalues + cgstaxvalues + sgstaxvalues+Final_value).toFixed(2)).toFixed(2))
        }
    },[dcItemDetails]) 

    const [initialValues, setInitialValues] = useState({
        id:"",
        dcNo:"",
        dcDate: "",
        status:"",
        purchase_id : "",
        supplier_id : "",
        supplier_Number:"",
        supplier_name : "",
        address : "",
        purchase_order_no:"",
        receiving_store:"",
        scrap_reject_store:"",
        remarks:"",
        gstin_type:"",
        gstin:"",
        state:"",
        place_of_supply:"",
        befor_tax : "",
        tax_total : "",
        round_off : "",
        net_amount : "",
        terms_conditions: "",
        terms_conditions_text : "",
    })

    async function fecthInitialData(poID) { 
        let perementr =  `id:${poID}`  
        try{
            const response = await axiosInstance.post(`/itemmaster/graphql`, {query:  purchasefetchdata(perementr)});
            const responseData = response?.['data']?.['data']?.['purchaseOrder']
            if (responseData?.['items']?.[0]){
                let data = responseData?.['items']?.[0]
    
                setInitialValues(prev => ({
                    ...prev,
                    id : "",
                    supplier_Number: data?.supplierId?.supplierNo,
                    supplier_name: data?.supplierId?.companyName,
                    supplier_id : data?.supplierId?.id,
                    purchase_order_no: data?.purchaseOrderNo?.linkedModelId,
                    purchase_id  : data?.id ,
                    receiving_store : data?.receivingStoreId,
                    scrap_reject_store : data?.scrapRejectStoreId,
                    gstin : data?.gstin,
                    gstin_type : data?.gstinType,
                    state : data?.state,
                    place_of_supply : data?.placeOfSupply,
                }));
                setBillingAddressSelected(data?.address)
                
            }
        } catch (error){
            console.error(error);
        }
    }

    async function SaverdcItem(item) {
        let save = {
            id: item?.id ? Number(item?.id) : "",
            itemMasterId: Number(item?.itemMasterId?.id),
            hsnId: Number(item?.hsnId?.id),
            rate: item?.rate,
            amount: Number(item?.amount),
            reworkQty: item?.reworkQty,
            purchaseOrderItem : item?.purchaseOrderItem?.id? Number(item?.purchaseOrderItem?.id): null,
            tax: item?.tax,
            uom: Number(item?.uom?.id),
            createdBy: item?.createdBy?.id ? Number(item?.createdBy?.id) : Number(userId),
            modifiedBy : item?.createdBy?.id ? Number(userId) : "" ,
        }
        let allow_null = ['id', "modifiedBy", "purchaseOrderItem"]
        let cleanValue = removeEmptyValueInObject(save, allow_null); 
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, {
                query: reworkdeliveryChallanItemDetailsCreateMutation(cleanValue)
            });
            const responseData = response?.data?.data?.deliveryChallanItemDetailsCreateMutation;
            if (responseData.success) {
                let reworkDeliveryChallanItemDetailsInstance = responseData.reworkDeliveryChallanItemDetailsInstance;
                return reworkDeliveryChallanItemDetailsInstance;
            } else {
                let errorData = ShowErrorNoties(responseData.errors);
                toast.error(errorData, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return null; // Return null if there are errors
            }
        } catch (error) {
            console.error("Error saving item:", error); // Log the error
            return null; // Return null in case of a catch error
        }
    } 

    //   Retun Null ; Save 
    async function handleSubmit(params) {
            let saved_item_ids = []
            if (!termsConditions){
                toast.error("Terms & Conditions required", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return ""
            }
            if (dcItemDetails.length > 0) {
                const savePromises = dcItemDetails.map(item => SaverdcItem(item));
                let  results = await Promise.all(savePromises); 
                results  = addSno(results)
                setDcItemDetails(results)
                saved_item_ids.push(results.map((item)=>item?.id))
            } 
            let SaveRDC = {
                id : initialValues?.id ? Number(initialValues?.id) : "" ,
                status : "Draft",
                supplier : initialValues?.supplier_id ? Number(initialValues?.supplier_id) : "",
                address : billingAddressSelected,
                purchaseOrderNo : initialValues?.purchase_id ? Number(initialValues?.purchase_id) : "" ,
                receivingStore : initialValues?.receiving_store?.id ? Number(initialValues?.receiving_store?.id) : "",
                scrapRejectStore : initialValues?.scrap_reject_store?.id ? Number(initialValues?.scrap_reject_store?.id) : "",
                reworkDeliveryChallanItemDetails : saved_item_ids,
                state : initialValues?.state,
                placeOfSupply : initialValues?.place_of_supply,
                gstin : initialValues?.gstin,
                gstinType : initialValues?.gstin_type,
                beforTax : totalAmountBeforeTax ? totalAmountBeforeTax : 0,
                taxTotal : totalTax ? totalTax : 0,
                cgst : cgstTax,
                sgst : sgstTax,
                igst : igstTax,
                termsConditions : termsOptions?.value ? Number(termsOptions?.value) : "",
                termsConditionsText : termsConditions,
                roundOff : roundof ? roundof : 0,
                netAmount : fixedFinalAmount ? fixedFinalAmount :0,
                createdBy : Number(userId),
            }
            let ClearValue = removeEmptyValueInObject(SaveRDC) 
            try {
                const response = await axiosInstance.post(`/itemmaster/graphql`, {
                    query: reworkdeliveryChallanCreateMutation(ClearValue)
                });
                const responseData = response?.data?.data?.deliveryChallanCreateMutation;
                if (responseData.success) {
                    if (SaveQir){
                        SaveQir("Checked", responseData?.reworkDeliveryChallanInstance?.id)
                    }
                    
                    setIsShowing(false)
                    toast.success( `Saved Successfully`, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light", 
                    });
                } else {
                    let errorData = ShowErrorNoties(responseData.errors);
                    toast.error(errorData, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }); 
                }
            } catch (error) { 
                toast.error(error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }); 
            }
    } 
    
    // define the column
    const columns = [
        {
        headerName: 'S.No',
        field: 'sNo',   
        flex: .7,
        onCellDoubleClicked: onCellDoubleClicked,
        },
        {
        headerName: 'Part Code',
        field: 'itemMasterId.itemPartCode',   
        flex: 2,
        onCellDoubleClicked: onCellDoubleClicked,
        //   cellRenderer: partcodecellrender,
        },
        {
        headerName: 'Part Name',
        field: 'itemMasterId.itemName',   
        flex: 2,
        onCellDoubleClicked: onCellDoubleClicked,
        },
        {
        headerName: 'HSN',
        field: 'hsnId.hsnCode', 
        flex: 2,
        onCellDoubleClicked: onCellDoubleClicked,
        },
        {
        headerName: 'Rework Qty',
        field: 'reworkQty',   
        flex: 1,
        onCellDoubleClicked: onCellDoubleClicked,
        },
        {
        headerName: 'UOM',
        field: 'uom.name', 
        flex: 1.5,
        onCellDoubleClicked: onCellDoubleClicked,

        },
        {
        headerName: 'Rate',
        field: 'rate',   
        flex: 1,
        onCellDoubleClicked: onCellDoubleClicked,
        }, 
        {
        headerName: 'Tax',
        field: 'tax',   
        flex: 1,
        onCellDoubleClicked: onCellDoubleClicked,
        },
        {
        headerName: 'Amount',
        field: 'amount',   
        flex: 2,
        onCellDoubleClicked: onCellDoubleClicked,
        }
    ]

    function Close(params) {
        setIsShowing(false)
        setInitialValues({  id:"",
            dcNo:"",
            dcDate: "",
            status:"",
            purchase_id : "",
            supplier_id : "",
            supplier_Number:"",
            supplier_name : "",
            address : "",
            purchase_order_no:"",
            receiving_store:"",
            scrap_reject_store:"",
            remarks:"",
            gstin_type:"",
            gstin:"",
            state:"",
            place_of_supply:"",
            befor_tax : "",
            tax_total : "",
            round_off : "",
            net_amount : "",
            terms_conditions: "",
            terms_conditions_text : "",})
        setBillingAddressSelected()
        setDcItemDetails([])
        setTotalTax("")
        setRoundof("")
        setSgstTax([])
        setCgstTax([])
        setIgstTax([])
        setTermsConditions ("")
        setTermsOptions("")
        setTranSpoter()
        setOwnVehicle()
        setCourier()
        setTransportData({model: null})
        setOtherModel(null)
    }
    // retun null; change status Submit
    async function submit(statusData) {
        setLoading(true)
        let SaveRDC = { 

        }
        if (statusData === "Submit"){
            SaveRDC['id'] = initialValues?.id
            SaveRDC['status'] = "Submit"
        }
        let ClearValue = removeEmptyValueInObject(SaveRDC)
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, {
                query: reworkdeliveryChallanCreateMutation(ClearValue)
            });
            const responseData = response?.data?.data?.deliveryChallanCreateMutation;
            if (responseData.success) {
                setStatus("Submit")
                toast.success( `${statusData} successfully.`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            } else {
                let errorData = ShowErrorNoties(responseData.errors);
                toast.error(errorData, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }); 
            }
        } catch (error) { 
            toast.error(error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            }); 
        }
        setLoading(false)

        
    }
    // retun null; change status Dispath
    const passValueToDispath = async(data, trantransporterId)=>{
        setLoading(true)
        let currentDetails = { ...data }; 
        let saveWithtransportData = {
            "id" : initialValues?.id,
            "status" : "Dispatch"
        }
        if (data?.["model"]?.['label'] === "Own Vehicle"){
            delete currentDetails?.model;
            saveWithtransportData['ownVehicle'] = currentDetails 
        } else if (data?.["model"]?.['label'] === "Courier"){
            delete currentDetails?.model;
            saveWithtransportData['courier'] = currentDetails 
        } else if (data?.["model"]?.['label'] === "Other Mode"){
            delete currentDetails?.model;
            saveWithtransportData['otherModel'] = currentDetails?.['Other_Mode']
        } else if (data?.["model"]?.['label'] === "Transport"){
            delete currentDetails?.model;
            saveWithtransportData['vehicleNo'] = currentDetails?.['vehicle_no']
            saveWithtransportData['transportId'] = trantransporterId ? Number(trantransporterId) : null
        }  
        let ClearValue = removeEmptyValueInObject(saveWithtransportData) 
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, {
                query: reworkdeliveryChallanCreateMutation(ClearValue)
            });
            const responseData = response?.data?.data?.deliveryChallanCreateMutation;
            if (responseData.success) {
                setStatus("Dispatch")
                setIsShowing(false)
                toast.success( `Dispatch successfully.`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            } else {
                let errorData = ShowErrorNoties(responseData.errors);
                toast.error(errorData, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }); 
            }
        } catch (error) { 
            toast.error(error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            }); 
        }
        setLoading(false)
    }
     // retun null; set status to cancle
    async function makeStatesCancle (params) {
        if (params){
        let perameter = `id:${Number(rdcEdit?.id)}`
        try{ 
            const respones = await axiosInstance.post("/itemmaster/graphql", {query: reworkDeliveryChallanCancelMutation(perameter) })
            const responseData = respones.data.data.reworkDeliveryChallanCancelMutation
            if (responseData.success){  
                setStatus("Canceled")
                toast.success('Canceled Successfully', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                    });
            } else{
                let error = responseData.errors
                let errorData = ShowErrorNoties(error) 
                toast.error( errorData , {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
            }  
        } catch(error){ 
        }
        } 
    }
    async function makeDelete (params) {
        if (params){
        let perameter = `id:${Number(rdcEdit?.id)}`
        try{ 
            const respones = await axiosInstance.post("/itemmaster/graphql", {query: deliveryChallanDeleteMutation(perameter) })
            const responseData = respones.data.data.deliveryChallanDeleteMutation
            if (responseData.success){ 
            
            
                toast.success(`Deleted Successfully`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                    });
                    Close()
                    // if (setLoad){
            
                    //     setLoad()
                    //   }
                    
            }  else{
                let error = responseData.errors
            
                let errorData = ShowErrorNoties(error) 
                console.log(errorData);
                toast.error(`${errorData}`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                    });
            }  
        } catch(error){ 
        }
        
        } 
    }

  return (
    <>   
        {<BallTriangle_ isshow={loading}/>}
         <ToastContainer/>
        <Modal show={isShowing} fullscreen>
            <Modal.Body>
                <div className='itemMaster_Top mx-3 mt-1 d-flex justify-content-center card'   style={{ width: '98%' }}>
                    <div className="row  align-items-center  justify-content-between ">
                        <div className="col-6  d-flex align-items-center"   >
                            <h3 className='mx-2 commanModelTitleColor'>
                            <i className='fa-solid fa-arrow-left fa-sm me-2 commanModelTitleColor' onClick={()=>{Close()}} ></i>
                            Rework Delivery Challan   
                            </h3> 
                            <span>{status}</span>
                        </div> 
                        <div className="col-6 text-end">
                            <TiCancel  hidden={  status ==="Canceled"? true : rdcEdit?.id ? false :  true}
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Cancle"
                                className='fs-2 pb-1'
                                style={{ color: '#7f7f7f', cursor: "pointer" }}
                                onClick={() => { 
                                  setWaringToCancle(true)
                                }}
                            />
                            <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  "
                              onClick={()=>{setWaringToDelete(true)}}
                            hidden={status ==="Canceled"? false : true}  ></i> 
                        </div>
                    </div> 
                </div>
                <div style={{width:"98%"}}>
                    <Formik initialValues={initialValues}
                    validationSchema="" 
                    >
                    {({errors, touched, setFieldValue, values, isSubmitting, submitForm})=>{
                        return(
                            <Form>
                                {/* Supplier Details */}
                                <div className="row ms-3 mt-3">
                                    <div className="col mx-2"> 
                                        <div className="row">
                                            {/* left Side */}
                                            <div className="col card shadow  mx-2 ">
                                                <div className="row">
                                                    <div className="col-6 staan-input-group">
                                                        <Field type='text' name='dcNo' disabled value={initialValues?.dcNo}  className='w-100 staan-form-input'/>
                                                        <label htmlFor="dcNo" className='staan-form-input-label pt-1 px-1 mx-2' onClick={()=>{console.log(initialValues)}}>DC No</label> 
                                                    </div>
                                                    <div className="col-6 staan-input-group">
                                                        <Field type='text' name='dcDate' disabled value={initialValues?.dcDate}  className='w-100 staan-form-input'/>
                                                        <label htmlFor="dcDate" className='staan-form-input-label pt-1 px-1 mx-2'>DC Date</label> 
                                                    </div>
                                                    <div className="col-6 staan-input-group">
                                                        <Field type='text' name='supplier_Number' disabled value={initialValues?.supplier_Number} className='w-100 staan-form-input'/>
                                                        <label htmlFor="supplier_Number" className='staan-form-input-label pt-1 px-1 mx-2'   >Supplier Number <span className='text-danger'>*</span> </label>
                                                        {touched.supplier_Number &&  errors.supplier_Number && <small>{errors.supplier_Number}</small>}
                                                    </div>
                                                    <div className="col-6 staan-input-group"> 
                                                        <Field type='text' name='supplier_name' disabled  value={initialValues?.supplier_name} className='w-100 staan-form-input'/> 
                                                        <label htmlFor="supplier_name" className='staan-form-input-label pt-1 px-1 mx-2'   >Supplier Name<span className='text-danger'>*</span> </label>
                                                        {touched.supplier_name &&  errors.supplier_name && <small>{errors.supplier_name}</small>}
                                                    </div>
                                                    <div className="row mt-1">
                                                        <div className="col-6">
                                                          
                                                            <p>Billing Address <span className='text-danger'>*</span></p>
                                                            <p>Line 1: {billingAddressSelected?.addressLine1}</p>
                                                            <p>Line 2: {billingAddressSelected?.addressLine2}</p>
                                                            <p>City  : {billingAddressSelected?.city}</p>
                                                            <div className="row">
                                                            <div className="col-7">
                                                                <p>State : {billingAddressSelected?.state}</p>
                                                            </div>
                                                            <div className="col-4">
                                                                <p>Pincode : {billingAddressSelected?.pincode}</p>
                                                            </div>
                                                            </div> 
                                                        </div>
                                                        <div className="col-6 ps-3">
                                                            <div className="row"> 
                                                        
                                                            
                                                        
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* right Side */}
                                            <div className="col card shadow  mx-2" >
                                                <div className="row">
                                                    <div className="col-6 staan-input-group">
                                                        <Field type='text' name='purchase_order_no' value={initialValues?.purchase_order_no}   placeholder='Enter Credit Period' className='w-100 staan-form-input'/>
                                                        <label htmlFor="purchase_order_no" className='staan-form-input-label pt-1 px-1 mx-2'>Purchase Order No<span className='text-danger'>*</span></label>
                                                        {touched.purchase_order_no &&  errors.purchase_order_no && <small>{errors.purchase_order_no}</small>}
                                                    </div>
                                                    <div className="col-6 staan-input-group">
                                                        <Field type='text' name='receiving_store' value={initialValues?.receiving_store?.storeName}   placeholder='Enter Credit Period' className='w-100 staan-form-input'/>
                                                        <label htmlFor="contact" className='staan-form-input-label pt-1 px-1 mx-2'>Receiving Store<span className='text-danger'>*</span></label>
                                                        {touched.receiving_store &&  errors.receiving_store && <small>{errors.receiving_store}</small>}
                                                    </div>
                                                    <div className="col-6 staan-input-group">
                                                        <Field type='text' name='scrap_reject_store' value={initialValues?.scrap_reject_store?.storeName}  className='w-100 staan-form-input mb-2'/>
                                                        <label htmlFor="scrap_reject_store" className='staan-form-input-label pt-1 px-1 mx-2'>Scrap / Reject Store<span className='text-danger'>*</span></label>
                                                        {  touched.scrap_reject_store &&  errors.scrap_reject_store && <small>{errors.scrap_reject_store}</small>}
                                                    </div>
                                                    <div className="col-12">
                                                        <h5>Transport Details : </h5>
                                                        <div className="row">
                                                            <h6>{ownVehicle? `Own Vehicle` : courier? "Courier":otherModel? "Other Model": ""}</h6>
                                                            {ownVehicle? 
                                                            <>
                                                                 
                                                                <div className="col-6 staan-input-group">
                                                                    <input 
                                                                        type='text' 
                                                                        name='Vehicle_No' 
                                                                        value={ownVehicle?.Vehicle_No}
                                                                        className='w-100 staan-form-input mb-2'
                                                                    />
                                                                    <label htmlFor="Vehicle_No" className='staan-form-input-label pt-1 px-1 mx-2'>Vehicle No<span className='text-danger'>*</span></label>
                                                                </div> 
                                                                <div className="col-6 staan-input-group">
                                                                    <input 
                                                                        type='text' 
                                                                        name='Driver_Name' 
                                                                        value={ownVehicle?.Driver_Name}
                                                                        className='w-100 staan-form-input mb-2'
                                                                    />
                                                                    <label htmlFor="Driver_Name" className='staan-form-input-label pt-1 px-1 mx-2'>Driver Name<span className='text-danger'>*</span></label>
                                                                </div>
                                                            </> :  
                                                            courier? 
                                                            <>
                                                                <div className="col-6 staan-input-group">
                                                                    <input 
                                                                        type='text' 
                                                                        name='Docket_No' 
                                                                        value={courier?.Docket_No}
                                                                        className='w-100 staan-form-input mb-2'
                                                                    />
                                                                    <label htmlFor="Docket_No" className='staan-form-input-label pt-1 px-1 mx-2'>Docket No<span className='text-danger'>*</span></label>
                                                                </div> 
                                                                <div className="col-6 staan-input-group">
                                                                    <input 
                                                                        type='text' 
                                                                        name='Docket_Date' 
                                                                        value={courier?.Docket_Date}
                                                                        className='w-100 staan-form-input mb-2'
                                                                    />
                                                                    <label htmlFor="Docket_Date" className='staan-form-input-label pt-1 px-1 mx-2'>Docket Date<span className='text-danger'>*</span></label>
                                                                </div>
                                                            </> :
                                                            otherModel? 
                                                            <>
                                                                 <div className="col-12 staan-input-group">
                                                                    <textarea 
                                                                        rows="4" 
                                                                        type='text' 
                                                                        name='Other_Mode'  
                                                                        value={otherModel} 
                                                                        
                                                                        className='w-100 staan-form-input mb-2'
                                                                    />
                                                                    <label htmlFor="Other_Mode" className='staan-form-input-label pt-1 px-1 mx-2'>Other Mode<span className='text-danger'>*</span></label>
                                                                </div>
                                                                
                                                            </> 
                                                            :
                                                            TranSpoter? 
                                                            <>
                                                                <div className="col-6 staan-input-group">
                                                                    <input 
                                                                        type='text' 
                                                                        name='vehicleNo' 
                                                                        value={TranSpoter?.vehicleNo}
                                                                        className='w-100 staan-form-input mb-2'
                                                                    />
                                                                    <label htmlFor="vehicleNo" className='staan-form-input-label pt-1 px-1 mx-2'>Vehicle No<span className='text-danger'>*</span></label>
                                                                </div> 
                                                                <div className="col-6 staan-input-group">
                                                                    <input 
                                                                        type='text' 
                                                                        name='transportId' 
                                                                        value={TranSpoter?.transport}
                                                                        className='w-100 staan-form-input mb-2'
                                                                    />
                                                                    <label htmlFor="transportId" className='staan-form-input-label pt-1 px-1 mx-2'>Transport <span className='text-danger'>*</span></label>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                            </>}
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                        {/* Gst Side */}
                                        <div className="row mt-3">
                                            <h5 className='mt-3'>GST Details</h5>
                                            <div className="col-12 card  shadow">
                                                <div className="row my-4">
                                                    <div className="col-3 staan-input-group">
                                                        <Field type='text' name='gstin_type' disabled value={initialValues?.gstin_type} className='w-100 staan-form-input'/>
                                                        <label htmlFor="gstin_type" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN Type<span className='text-danger'>*</span></label>
                                                        {touched.gstin_type &&  errors.gstin_type && <small>{errors.gstin_type}</small>}
                                                    </div>
                                                    <div className="col-3 staan-input-group">
                                                        <Field type='text' name='gstin' disabled value={initialValues?.gstin} className='w-100 staan-form-input'/>
                                                        <label htmlFor="gstin" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN<span className='text-danger'>*</span></label>
                                                        {  touched.gstin &&  errors.gstin && <small>{errors.gstin}</small>}
                                                    </div>
                                                    <div className="col-3 staan-input-group">
                                                        <Field type='text' name='state'  value={initialValues?.state} disabled  className='w-100 staan-form-input'/>
                                                        <label htmlFor="state" className='staan-form-input-label pt-1 px-1 mx-2'>State<span className='text-danger'>*</span></label>
                                                        {  touched.state &&  errors.state && <small>{errors.state}</small>}
                                                    </div>
                                                    <div className="col-3 staan-input-group"> 
                                                        <Field type='text' name='place_of_supply'  value={initialValues?.place_of_supply} disabled  className='w-100 staan-form-input'/>
                                                        <label htmlFor="place_of_supply" className='staan-form-input-label pt-1 px-1 mx-2'>Place Of Supply<span className='text-danger'>*</span></label>
                                                        {touched.place_of_supply &&  errors.place_of_supply && <small>{errors.place_of_supply}</small>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Item Details */}
                                        <div className="row   mt-4 card shadow-lg pb-3">
                                            <div className="row justify-content-between">
                                            <div className="col-3">
                                                <h5 className='mt-4'>Item Details</h5>
                                            </div>
                                            
                                            </div>
                                            <div className="ag-theme-alpine mt-2" style={{ height: '40rem', width: '100%' }}>
                                            <AgGridReact
                                            rowData={dcItemDetails}
                                            columnDefs={columns}
                                            pagination={false} 
                                            />
                                            </div>

                                        </div>
                                        <div className="row justify-content-between  gap-3 mt-4">
                                            {/* Terms & Conditions */}
                                            <div className="col card shadow-lg p-2 pb-3">
                                                <div className="row">
                                                    <div className="col-6 staan-input-group">
                                                        <Select
                                                        options={tCList}
                                                        onInputChange={(e)=>{fetchOptions(e, "TermsConditionsFetchQuery" )}}
                                                        value={termsOptions}
                                                        name='termsConditions' 
                                                        className='my-3' 
                                                        styles={customSelectStyle}
                                                        onChange={(option)=>{
                                                            setTermsOptions(option)
                                                            setTermsConditions(option?.tc)
                                                        }}
                                                        />
                                                        <label htmlFor="supplier_Number" className='staan-form-input-label pt-1 px-1 mx-2'>Terms & Conditions</label>
                                                    </div>
                                                    <div className="col-12 staan-input-group " > 
                                                        <ReactQuill
                                                        value={termsConditions} 
                                                        modules={textEditormodules}
                                                        onChange={(e)=>{  
                                                            setTermsConditions(e) 
                                                        }} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Amount */}
                                            <div className="col card shadow-lg">
                                                <div className="card mt-3">
                                                    <table class="table "> 
                                                    <tbody>
                                                        <tr> 
                                                        <td className='text-end'>Before Tax</td> 
                                                        <td className='text-end'>{totalAmountBeforeTax? totalAmountBeforeTax : "0.00"}</td>
                                                        </tr> 
                                                        <tr> 
                                                        <td className='text-end cursor-pointer text-primary' >Tax Total</td> 
                                                        <td className='text-end'>{totalTax ? totalTax : "0.00"}</td>
                                                        </tr>
                                                        <tr> 
                                                        <td className='text-end'>Round of</td> 
                                                        <td className='text-end'>{roundof}</td>
                                                        </tr>
                                                        <tr> 
                                                        <td className='text-end  fw-bold'>Net Amount</td> 
                                                        <td className='text-end'>{fixedFinalAmount? fixedFinalAmount : "0.00"}</td>
                                                        </tr>
                                                    </tbody>
                                                    </table>
                                                </div> 
                                            </div>
                                            {/* button */}
                                            <div className="row mt-3 ms-3 justify-content-evenly"  >
                                                <div className="col-1">
                                                <Can I={"Draft"} a={"Delivery_Challan"}>
                                                <button type="button"  class="btn btn-outline-secondary  px-4" disabled = {status === null ? false : status === "Draft" ? false : true } onClick={()=>{handleSubmit()}}> Draft </button>    
                                                </Can>
                                                </div>
                                                <div className="col-1">
                                                    <Can I={"Submit"} a={"Delivery_Challan"}>
                                                    <button type="button"     class="btn btn-outline-success px-3" disabled={status === "Draft" ? false : status === "Submit" ? true : true } 
                                                onClick={()=>{submit("Submit")}} >Submit</button>
                                                    </Can>
                                                </div> 
                                                <div className="col-1">
                                                    <Can I={"Dispatch"} a={"Delivery_Challan"}>
                                                    <button type="submit"  class="btn btn-outline-primary px-3"
                                                 disabled={status === "Dispatch" ? true : status === "Submit" ? false : true }
                                                onClick={()=>{setIsShowTranSpoterDetails(true)}} >Dispatch</button>
                                                    </Can>
                                                </div>
                                                
                                            </div> 
                                        </div>
                                    </div>
                                    
                                </div>
                            </Form>
                        )
                    }}
                        

                    </Formik>
                </div>
            </Modal.Body>
        </Modal>
        <TransportDetails
        show = {isShowTranSpoterDetails}
        setShow={setIsShowTranSpoterDetails}
        passValueToSubmit ={passValueToDispath}
        transportData = {transportData}
        setTransportData = {setTransportData}
        />
        {waringToCancle && 
            <WaringModel
            state={waringToCancle}
            setstate={setWaringToCancle}
            title={"Confirmation"}
            text={'Confirm to cancel'}
            Confirmtion={value => {
                makeStatesCancle(value)
            }}
        />}
         {waringToDelete && 
        <WaringModel
            state={waringToDelete}
            setstate={setWaringToDelete}
            title={"Confirmation"}
            text={'Confirm to Delete'}
            Confirmtion={value => {
                makeDelete(value)
            }}
        />
        }
       
    </>
  )
}

export default DeliveryChallanForm