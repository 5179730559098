import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import Select from 'react-select'; 
import axiosInstance from '../../../api/axoiss';
import Button from 'react-bootstrap/Button';
import {  Field, Form, Formik } from 'formik';
import DataContext from '../../../context/ItemMasterContext';
import StaanTables from '../../../Packages/StaanTables1.2';
import { StockSchema } from '../../../validations/itemmaster';
import { customSelectStyle } from '../../../utils/Utils';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStoreData } from '../../../ReduxState/Action/storeActions';
import {  ItemMasterFetchSingleQuery, StockDataFetch, StockSerialHistoryDataFetch, TotalStockByPartCode, storeFetchQuery } from '../../../GraphQLQueries/ItemmasterQuery'
import { fetchItemMasterData } from "../../../ReduxState/Action/itemMasterAction";
import { itemMasterOptionFetchQuery } from '../../../GraphQLQueries/ItemmasterQuery';
import { FetchDataItem } from '../../../CustomRequests/FetchData';
import { fetchStockSerialHistoryData } from '../../../ReduxState/Action/stockAction';
import { CreateStockHistory } from '../../../GraphQLQueries/InventoryMutation';
import { Oval } from 'react-loader-spinner'
import Can from "../../../components/authorizationComponents/Can"

const StockDeletion = ({show, close, setLoad, isLoad}) => {
    const {userId} =  useContext(DataContext)
      const dispatch = useDispatch(); 
      const storeDataList = useSelector(state => state.storeData.data);
      const itemMasterDataList = useSelector(state => state.itemMasterData.data);
    
      
      const [post, setPost] = useState([])
      const [storeSelect, setStoreSelete] =useState([])
      const handleSelectStore = (options)=>{
          setStoreSelete({ value: options.value , label: options.label })
      }

      // InventoryHandlerData, 
      const [batchNumberOptions, setBatchNumberOptions] = useState([]);
      const [serialNumberOptions, setSerialNumberOptions] = useState([]);
      const [UOMOptions, setUOMOptions] = useState([]);
      
      const column = [
        {header : "id", 
        fieldname :"id", 
        width: 'col-1'},
        {header : "Part Code", fieldname : 'part_no', 
        object : "label",
        width: 'col-1',
         },
        {header : "Part Name", fieldname : 'part_name',
        object : "label",
         width: 'col-2',
         },
          {
          header : "Qty", 
          fieldname :'qty',
          width: 'col-1',
          
         
          },
          {
          header : "UOM", 
          fieldname :'UOM',
          width: 'col-1',
          object : "label",
         
          },
          {
            header : "Batch Number", 
            fieldname :'BatchNumber',
            width: 'col-2',
            object : "label",
            },
          {
            header : "Serial Number", 
            fieldname :'SerialnumLable',
            width: 'col-3', 
           },
         
      ]
    
      //Get Uom
      const  getUom =  async (value)  => {
          setUOMOptions([])
          let selectItemmasterdata = await FetchDataItem(ItemMasterFetchSingleQuery(value))
          selectItemmasterdata = selectItemmasterdata ? selectItemmasterdata['itemMaster']?.['items'] : []
          if(selectItemmasterdata.length > 0) {
              selectItemmasterdata = selectItemmasterdata[0]
          }else{
              selectItemmasterdata = {}
          }
          const item_uom_options = selectItemmasterdata?.['itemUom']
          if(item_uom_options){
              setUOMOptions([{label: item_uom_options?.name, value: item_uom_options?.id}])
          }
          // listUom.map((uomid)=>{
          //   let uomobj = ItemUOMdata.find(item =>item.id === uomid )
      
      
          //   setUOMOptions(prev=> [...prev, { value: uomobj.id , label:  uomobj.name }])
          // })
          // console.log(listUom);
      }
    
    
      // filiter function for serial and batch number options
      const filtertheData = async (itemValue, batch_or_serial) => {
        getUom(itemValue)
        let datafiltered = [];
        setBatchNumberOptions([])
        setSerialNumberOptions([])
        let storeobj = storeSelect
        try {
          let stock_fetch_query = StockDataFetch(1, 200, {partNumber: itemValue, store: storeobj.value}, [])
          let stock_data = await axiosInstance.post("/itemmaster/graphql", {query: stock_fetch_query})
          let stock_data_exists = stock_data?.data?.data?.itemStock?.items
          const filteredData = stock_data_exists
          // check preves value remove the selece options
          post.map((postitem)=>{
            let selectedSerialId = []
            if(postitem['Serialnum']){
              if(postitem['part_no']['value'] === itemValue ){
                selectedSerialId = postitem['Serialnum'].map((item)=>item.value)
              }
            }
          })
          if(filteredData.length > 0){
            if ( batch_or_serial === 'serial' && filteredData.length >0 ){
              filteredData && filteredData.map(filter_item => {
                if(filter_item['serialNumber'].length > 0){
                  datafiltered.push(filter_item['serialNumber'])
                }
              })
              datafiltered && datafiltered.map((textoptions)=>{
                textoptions.map(item => {
                  let construct_created_obj = {
                    label: item?.serialNumber,
                    value: item?.id
                  }
                  setSerialNumberOptions(prev =>[...prev, construct_created_obj])
                })
              })
            }else if(batch_or_serial=== 'batch' && filteredData[0]['batchNumber']){
              filteredData.map((data) => {  
                let batch_number = data['batchNumber']
                if(batch_number){
                  let batchoptions = {value: batch_number?.['id'] , label: batch_number?.['batchNumberName']}
                  console.log(batchoptions)
                  setBatchNumberOptions(prev =>[...prev, batchoptions])  
                }
              })
            }  
          } else{
            console.log("--->> not find");
          }
        } catch (error) {
          console.error(error);
          // Handle error, e.g., show a user-friendly message or retry logic
        }  
      };

      // initial Values to Formik input
      const initialValues = {
        id : "",
        part_no : "",
        part_name : "",
        qty : "",
        UOM: "",
        BatchNumber: "",
        Serialnum : [] 
      }
    
      // input value handler 
      const [selectpartCode, setSelectpartCode] = useState('')
      const handleSelectpartCode = (option)=>{
        if(option){
          setSelectpartCode({ value: option?.value , label:  option?.label })
        }else{
          setSelectpartCode({ value: '' , label:  '' })
          setSelectpartName({ value: '' , label:  '' })
        }
      }
      const [selectUOM, setSelectUOM] = useState('')
       const handleSelectUOM = (option)=>{
        if(option){
          setSelectUOM({ value: option?.value , label:  option?.label })
        }else{
          setSelectUOM({ value: '' , label:  '' })
        }
      }
      
      const [selectpartName, setSelectpartName] = useState('')
      const handleSelectpartName = (option)=>{
        if(option){
          setSelectpartName({ value: option?.value , label:  option?.label })
        }else{
          setSelectpartCode({ value: '' , label:  '' })
          setSelectpartName({ value: '' , label:  '' })
        }
      }
      const [qty, setQty] = useState('')
      const handleSelectQty = (value)=>{
        setQty(value)
      }
    
      const [selectBatchNumber , setSelectBatchNumber] = useState();
      const handleSelectBatchNumber = (option)=>{
        setSelectBatchNumber({ value: option?.value , label:  option?.label })
      }

      const [selectSerialNumber, setSelectSerialNumber] = useState([]);
      const handleSelectSerialNumber = (options) => {
        setSelectSerialNumber(options);
      };

      // reset data
      function resetData(params){
        setSelectpartCode({ value: "" , label:  "" })
        setSelectpartName({ value: "" , label:  "" })
        setQty()
        setSelectUOM('')
        setSelectBatchNumber({ value: "" , label:  "" })
        setSelectSerialNumber({ value: "" , label:  "" })
        setSerialOrBatch('')
        
      }

      // handle submit 
      let error = ""
      const handleSubmit = async (values, { setSubmitting, resetForm, setErrors, setFieldError }, toggle, setOpenRowIndex) => {
        let toCheckTotal = 0
        if(serialOrBatch === "batch"){
            if (values['BatchNumber'] === "") {
              setFieldError ('BatchNumber', 'Batch Number is required');
              error = 'Batch Number is required'
            } else { 
              setFieldError ('BatchNumber', null);
              error = ''
            } 
        } 
        if(serialOrBatch === 'serial'){  
          if(values['Serialnum'] === ""){
            setFieldError ('Serialnum', 'Serial Number is required');
        
            error = 'Serial Number is required'
          } else{
                
            if(values['Serialnum'].length === Number(values['qty'])){
              error = ""
            } else{
            
              error = "Serial Number and qty in missmatch"
              setFieldError ('Serialnum', 'Serial Number and qty in missmatch');
        
            }
              
            
          }
        } 
        
        if(error === ""){     
          if(values['Serialnum'].length >0){
            let editValueWithSerial =  post.filter(postItem=> postItem.id === values.id ? postItem : "")
            editValueWithSerial = editValueWithSerial.length > 0 ? editValueWithSerial[0]['part_no']['value'] : ""
            if (editValueWithSerial !== ""){
              const savedValue = {
                id : values.id,
                part_no : selectpartCode,
                part_name : selectpartName,
                Serialnum :  selectSerialNumber,
                SerialnumLable: selectSerialNumber? selectSerialNumber.map((selectItem)=>selectItem.label).join(',') : [],
                BatchNumber : '' ,
                qty : qty, 
                UOM :selectUOM,
              }     
              setPost(
                (prevData) => {
                  return prevData.map((item) => {
                    return item.id === savedValue.id ? savedValue : item;
                  });
                }
              ) 
            } else{ 
              const savedValue = {
                id : values.id,
                part_no : selectpartCode,
                part_name : selectpartName,
                Serialnum :  selectSerialNumber,
                SerialnumLable: selectSerialNumber? selectSerialNumber.map((selectItem)=>selectItem.label).join(',') : [],
                BatchNumber :  '',
                qty : qty, 
                UOM :selectUOM
              }   
              console.log(savedValue);
              setPost(pver =>[...pver, savedValue])
            }
            toggle()
            setOpenRowIndex()
            resetForm()
            resetData()
      
          }
          else if(values['BatchNumber']){
            const SavedValue = {
              BatchNumber :  selectBatchNumber,
              Serialnum : "",
              part_name : selectpartName,
              part_no : selectpartCode,
              qty : qty,
              
            }  
            toCheckTotal = SavedValue['qty']
            let stock_fetch_query = StockDataFetch(1, 200, {partNumber: selectpartCode?.value, store: storeSelect?.value, batchNumber: selectBatchNumber?.value}, [])
            let stock_data = await axiosInstance.post("/itemmaster/graphql", {query: stock_fetch_query})
            let tocheckSavededValue = stock_data?.data?.data?.itemStock?.items?.[0]
            post.map((postitem)=>{
              if(postitem['BatchNumber']){
                if(postitem['part_no']['value'] === SavedValue['part_no']['value'] &&   (postitem['BatchNumber']['value']) ===  (SavedValue['BatchNumber']['value'])){
                  
                  if(Number(postitem.id) !== Number(values.id)){
                    toCheckTotal = Number(toCheckTotal) + Number(postitem['qty'])
                  } 
                }
              } 
            }) 
            if(tocheckSavededValue){ 
              let finalQty = Number(tocheckSavededValue?.['currentStock']) - Number(toCheckTotal)
              if (finalQty >=0){  
                // to check edit or not edit  
                let editValue =  post.filter(postItem=> postItem.id === values.id ? postItem : "")
                editValue = editValue.length > 0 ?editValue[0]['part_no']['value'] : ""
                if (editValue !== ""){
                  const savedValue = {
                    id : values.id,
                    part_no : selectpartCode,
                    part_name : selectpartName,
                    Serialnum :  '',
                    BatchNumber :  selectBatchNumber,
                    qty : qty, 
                    UOM :selectUOM
                  }    
                  // console.log("update", savedValue);
                  setPost(previousData => {
                    const index = previousData.findIndex(item => item.id === savedValue.id);
                    if (index !== -1) {
                      return [
                          ...previousData.slice(0, index), 
                          savedValue,  
                          ...previousData.slice(index + 1)  
                      ];
                    } else {
                      return [...previousData, savedValue];
                    }
                  });
                } else{ 
                  const savedValue = {
                    id : values.id,
                    part_no : selectpartCode,
                    part_name : selectpartName,
                    Serialnum :  '',
                    BatchNumber :  selectBatchNumber,
                    qty : qty, 
                    UOM :selectUOM
                  }    
                  // console.log("add", savedValue);
                  setPost(previousData => {
                    const index = previousData.findIndex(item => item.id === savedValue.id);
                    if (index !== -1) {
                      return [
                          ...previousData.slice(0, index), 
                          savedValue,  
                          ...previousData.slice(index + 1)  
                      ];
                    } else {
                      return [...previousData, savedValue];
                    }
                  });
                }
                toggle()
                setOpenRowIndex()
                resetForm()
                resetData()
              }
              else{
                alert("The  Value in Negative.")
              }
            }
      
          }
          else if(values['BatchNumber'] ==='' || values['BatchNumber'] === undefined & values['Serialnum']==="" || values['Serialnum'] === undefined){
            let toCheckTotalWithoutSerialBatch = 0
            const SavedValue = {
              BatchNumber :  '',
              Serialnum : "",
              part_name : selectpartName,
              part_no : selectpartCode,
              qty : qty,
              UOM :selectUOM
            } 
            toCheckTotalWithoutSerialBatch = SavedValue['qty']  
            let stock_fetch_query = StockDataFetch(1, 200, {partNumber: selectpartCode?.value, store: storeSelect?.value}, [])
            let stock_data = await axiosInstance.post("/itemmaster/graphql", {query: stock_fetch_query})
            let tocheckWithoutSerialBatch = stock_data?.data?.data?.itemStock?.items
            // console.log(tocheckWithoutSerialBatch, 'tocheckWithoutSerialBatch')
            // const tocheckWithoutSerialBatch = stock.map(stockData => Number(stockData.part_no) === Number(selectpartCode['value'])?stockData : "")
            // .filter(stockData => Number(stockData.store) === Number(storeSelect['value']))
            post.map((postitem)=>{ 
              if(postitem['BatchNumber'] === "" &&  values['Serialnum'] === ""){  
                if(postitem['part_no']['value'] === SavedValue['part_no']['value']){  
                  if(Number(postitem.id) !== Number(values.id)){ 
                    toCheckTotalWithoutSerialBatch = Number(toCheckTotalWithoutSerialBatch) + Number(postitem['qty']) 
                  } 
                }
              } 
            }) 
          
            if(tocheckWithoutSerialBatch && tocheckWithoutSerialBatch?.length>0){
              // console.log(tocheckWithoutSerialBatch);
              let finalQtyWithoutSerialBatch = Number(tocheckWithoutSerialBatch[0]['currentStock']) - Number(toCheckTotalWithoutSerialBatch)
            
              if(finalQtyWithoutSerialBatch >=0){
                let editValueWithoutSerialBatch =  post.filter(postItem=> postItem.id === values.id ? postItem : "")
                  editValueWithoutSerialBatch = editValueWithoutSerialBatch.length > 0 ? editValueWithoutSerialBatch[0]['part_no']['value'] : ""
              
                  if (editValueWithoutSerialBatch !== ""){
                    const savedValue = {
                      id : values.id,
                      part_no : selectpartCode,
                      part_name : selectpartName,
                      Serialnum :  '',
                      BatchNumber :  '',
                      qty : qty, 
                      UOM :selectUOM
                    }    
                    setPost(
                      (prevData) => {
                        return prevData.map((item) => {
                          return item.id === savedValue.id ? savedValue : item;
                        });
                      }
                    ) 
                  } else{ 
                    const savedValue = {
                      id : values.id,
                      part_no : selectpartCode,
                      part_name : selectpartName,
                      Serialnum :   [],
                      BatchNumber :  { value:  "" , label:   "" },
                      qty : qty, 
                      UOM :selectUOM
                    }   
                    // console.log(savedValue);
                    // setPost(pver =>[...pver, savedValue])
                    setPost(previousData => {
                      const index = previousData.findIndex(item => item.id === savedValue.id);
                      if (index !== -1) {
                          // If item with same id exists, update it
                          return [
                              ...previousData.slice(0, index),  // Keep items before the updated one
                              savedValue,  // Add the updated item
                              ...previousData.slice(index + 1)  // Keep items after the updated one
                          ];
                      } else {
                          // If item with same id doesn't exist, add the new item
                          return [...previousData, savedValue];
                      }
                  });
                  }
                  toggle()
                  setOpenRowIndex()
                  resetForm()
                  resetData()
              } else{
                alert("The  Value in Negative.")
              }
            
      
            }else{
              alert("Stock not found")
            }
        
      
          }
        }
        
    
      }
      
    // check batch number or serial number 
      const [serialOrBatch, setSerialOrBatch] = useState(null)
      const getSerial_batch = async (value) => { 
        let selectItemmasterdata = await FetchDataItem(ItemMasterFetchSingleQuery(value))
        selectItemmasterdata = selectItemmasterdata ? selectItemmasterdata['itemMaster']?.['items'] : []
        if(selectItemmasterdata.length > 0) {
            selectItemmasterdata = selectItemmasterdata[0]
        }else{
            selectItemmasterdata = {}
        }
        if(selectItemmasterdata.batchNumber){ 
          filtertheData(selectItemmasterdata.id , 'batch') 
          setSerialOrBatch('batch')
        } else if(selectItemmasterdata.serial){ 
          filtertheData(selectItemmasterdata.id, 'serial')
          setSerialOrBatch('serial') 
        }  else{
          filtertheData(selectItemmasterdata.id, '')
          setSerialOrBatch('')
        }
      }
    
      const handleOnInputChangeTypeSearch = (value, field) => {
        let extra_filter = {
          itemTypes: 'Product',
          itemComboBool: false
        }
          if(value.trim() !== ''){
              let filter_obj = {
                  key: field,
                  value: value
              }
              dispatch(fetchItemMasterData(itemMasterOptionFetchQuery(filter_obj, extra_filter)));
          }else{
              dispatch(fetchItemMasterData(itemMasterOptionFetchQuery(null, extra_filter)))
          }
      }

      const [loadingModels, setLoadingModels] = useState(false);
      const LoadingModel = () => {
          return (
            <>
              <Modal show={loadingModels} backdrop='static' keyboard={false} centered>
                <Modal.Body className=''>
                  <div className='d-flex justify-content-center   '>
                    <Oval
                      visible={true}
                      height='80'
                      width='80'
                      color='#4fa94d'
                      ariaLabel='oval-loading'
                      wrapperStyle={{}}
                      wrapperClass=''
                    />
                  </div>
                </Modal.Body>
              </Modal>
            </>
          )
        }

    // input sections 
    const InputFrom =(toggle,setOpenRowIndex, rowdata)=>{
      //  ()=>{toggle(); setOpenRowIndex()}
      initialValues['id'] = rowdata.id
      useEffect(()=>{
      
        if(rowdata['part_no']['value']){
          initialValues['part_no']  = rowdata['part_no']['value']
          getSerial_batch(rowdata['part_no']['value'])
          initialValues['part_name']  = rowdata['part_name']['value']
          initialValues['qty'] = rowdata['qty']
          initialValues['UOM'] = rowdata['UOM']['value']
          initialValues['BatchNumber'] = rowdata['BatchNumber']['value']
          initialValues['Serialnum'] = rowdata['Serialnum']
          handleSelectpartCode(rowdata['part_no'])
          handleSelectpartName(rowdata['part_name'])
          handleSelectQty(rowdata['qty'])
          handleSelectBatchNumber(rowdata['BatchNumber'])
          handleSelectSerialNumber(rowdata['Serialnum'])
          handleSelectUOM(rowdata['UOM'])
        }
       
      },[])
     
        return(
          <>
          <Formik 
          initialValues={initialValues}
          validationSchema={StockSchema}
          onSubmit={(values, formikProps) => handleSubmit(values, formikProps, toggle, setOpenRowIndex)}>
             {({errors, submitForm, touched, setFieldValue})=>(
              <>
               <Form>
                <div className="row">
                    <div className="col-3 staan-input-group">
                        <Select
                            onInputChange={(e)=>{handleOnInputChangeTypeSearch(e, 'itemPartCode')}}
                            name="part_no" 
                            options={itemMasterDataList?.items?.map((item) => ({ value: item.id, label: item.itemPartCode, name:item.itemName  }))}
                            isSearchable={true}
                            isClearable
                            value={selectpartCode}
                            onChange={(option) => {
                                setFieldValue('part_no', option ? option?.value : null);
                                setFieldValue('part_name', option ? option?.value : null)
                                handleSelectpartCode(option)
                                getSerial_batch(option?.value)
                                getUom(option?.value)
                                setSelectUOM('')
                                setSelectBatchNumber('')
                                setSelectSerialNumber('')
                                setSelectpartName({ value: option?.value , label:  option?.name })
                            }} 
                            styles={customSelectStyle}
                            className='mt-3'              
                        />
                        <label htmlFor="part_no" className='staan-form-input-label pt-2 px-1 mx-2'>Part Code</label>
                        {  touched.part_no &&  errors.part_no && <small>{errors.part_no}</small>}
                    </div>
                    <div className="col-4 staan-input-group">
                        <Select
                            name="part_name"
                            onInputChange={(e)=>{handleOnInputChangeTypeSearch(e, 'itemName')}}
                            options={itemMasterDataList?.items?.map((item) => ({ value: item.id, label: item.itemName , partCode: item.itemPartCode}))}
                            isSearchable={true}
                            value={selectpartName}
                            isClearable
                            onChange={(option) => {
                                setFieldValue('part_name', option ? option?.value : null);
                                handleSelectpartName(option) 
                                getSerial_batch(option?.value)
                                setFieldValue('part_no', option ? option?.value : null)
                                setSelectUOM('')
                                setSelectBatchNumber('')
                                setSelectSerialNumber('')
                                setSelectpartCode({ value: option?.value , label:  option?.partCode })}
                            }  
                            styles={customSelectStyle}
                            className='mt-3'                    
                        />
                        <label htmlFor="part_Name" className='staan-form-input-label pt-2 px-1 mx-2'>Part Name  </label>
                        {  touched.part_name &&  errors.part_name && <small>{errors.part_name}</small>}
                    </div> 
                    <div className="col-2 staan-input-group">
                        <Field type='text' id='qty' name='qty'  value={qty} 
                            onChange={(e) => {setFieldValue('qty', e.target.value);
                            handleSelectQty(e.target.value)}}
                            className='w-100 staan-form-input '/> 
                        <label htmlFor="qty" className='staan-form-input-label pt-2 px-1 mx-2'>Qty  </label>
                        {touched.qty &&  errors.qty && <small>{errors.qty}</small>}
                    </div>
                    <div className='col-3 staan-input-group'>
                        <Select
                            name="UOM" 
                            options ={UOMOptions}
                            value={selectUOM}
                            onChange={(option)=>{
                                setFieldValue('UOM', option ? option?.value : null)
                                handleSelectUOM({ value: option?.value , label:  option?.label })
                            }}
                            styles={customSelectStyle}
                            className='mt-3' 
                        /> 
                        <label htmlFor="UOM" className='staan-form-input-label pt-2 px-1 mx-2'>UOM</label>
                        {  touched.UOM &&  errors.UOM && <small>{errors.UOM}</small>}
                    </div> 
                    <div className='col-2 staan-input-group'>
                        <Select
                            name="BatchNumber" 
                            options ={batchNumberOptions}
                            value={selectBatchNumber}
                            isDisabled ={serialOrBatch === 'batch' ? false : true}
                            onChange={(option)=>{
                                setFieldValue('BatchNumber', option ? option?.value : null)
                                handleSelectBatchNumber({ value: option?.value , label:  option?.label })
                            }}
                            styles={customSelectStyle}
                            className='mt-3'  
                        /> 
                        <label htmlFor="BatchNumber" className='staan-form-input-label pt-2 px-1 mx-2'>Batch Number  </label>
                        {  touched.BatchNumber &&  errors.BatchNumber && <small>{errors.BatchNumber}</small>}
                    </div> 
                    <div className='col-3  staan-input-group'>
                        <Select
                            name="Serialnum" 
                            options ={serialNumberOptions}
                            value={selectSerialNumber}
                            isDisabled ={serialOrBatch === 'serial' ? false : true}
                            isMulti
                            isOptionDisabled= {() => selectSerialNumber?.length >= qty ?true : false } 
                            onChange={(options) => { 
                            setFieldValue('Serialnum', options ? options.map(option => option?.value)  : []);
                            handleSelectSerialNumber(options);
                            }}
                            styles={customSelectStyle}
                            className='mt-3'  
                        /> 
                        <label htmlFor="Serialnum" className='staan-form-input-label pt-2 px-1 mx-2'>Serial Number  </label>

                        {  touched.Serialnum &&  errors.Serialnum && <small>{errors.Serialnum}</small>}
                    </div>
                    <div className='col-1 mt-3 '>
                    <div className='row'>
                        <div className='col-6 text-end'>
                        <button   className="  save-button"  onClick={()=>{submitForm( )}}>
                        <i className="fa-regular fa-floppy-disk"  ></i>
                        </button>
                        
                        </div>
                        <div className='col-6'>
                        <button type='button' className="delete-button"  onClick={()=>{getDeleteRowId(rowdata)}}>
                        <i className="fa-solid fa-trash"></i>
                        </button>
                        </div>
                        </div>
                    </div>
                </div>
               </Form>
              </>
              )}
    
          </Formik>
          
          </>
        )
      }
    
      
      // reduse qty in Store data base
      const ReduseQty = async ()=>{
        let batch_or_serial = '';
        let inventroId = [] 
        let stock_history_data_list = []
        setLoadingModels(true)
        try{ 
          // to save in store 
          for (let singlePost of post) { 
              let singlePost_1 = singlePost
              let stock_history_data = null;
              
              if(singlePost['BatchNumber']['value'] === "" && singlePost['Serialnum'] <=0){
                let stock_fetch_query = StockDataFetch(1, 200, 
                    { partNumber: singlePost['part_no']['value'], 
                      store: storeSelect?.value}, 
                    [])
                let stock_data = await axiosInstance.post("/itemmaster/graphql", {query: stock_fetch_query})
                let tocheckBatch = stock_data?.data?.data?.itemStock?.items
                let previous_state = 0
                stock_history_data = {
                  "action": "DELETE",
                  "storeLink": storeSelect?.value,
                  "partNumber": singlePost['part_no']['value'],
                  "previousState": previous_state,
                  "added": 0,
                  "reduced": singlePost['qty'],
                  "updatedState": Number(previous_state) - Number(singlePost['qty']),
                }
                axiosInstance.post("/itemmaster/graphql", {query: TotalStockByPartCode(singlePost['part_no']['value'])})
                .then(res => {
                    let total_stock_data = res?.data?.data?.itemStockByPartCode?.items
                    total_stock_data = total_stock_data?.[0]?.totalCurrentStock
                    if(total_stock_data){
                        previous_state = total_stock_data
                        stock_history_data['previousState'] = previous_state
                        stock_history_data['updatedState'] = Number(previous_state) - Number(singlePost['qty'])
                    }
                })
                .catch(err => {
                    console.log(err)
                });
                tocheckBatch[0]['currentStock'] = Number(tocheckBatch[0]['currentStock']) - Number(singlePost['qty'])
                  try{
                    let item_stock_mutate =  `mutation itemstockmutation {
                      itemStockCreateMutation(id:${tocheckBatch[0]['id']}, currentStock: ${tocheckBatch[0]['currentStock']}){
                        success
                        errors
                        itemStock{
                          id
                        }
                      }
                    }`
                    const response = await axiosInstance.post("/itemmaster/graphql", {query: item_stock_mutate})
                    let stock_update_response = response?.data?.data?.itemStockCreateMutation
                    if(stock_update_response?.success){
                      stock_history_data['stockLink'] = stock_update_response.itemStock.id
                      try{
                        let inventory_approval_mutation_query = `mutation InventoryApprovalCreateQuery{
                          inventoryApprovalCreateMutation(partNumber: ${singlePost_1?.['part_no']['value']},
                          unit: ${singlePost_1?.['UOM']['value']}, qty: "${singlePost_1?.['qty']}", store: ${storeSelect?.['value']}){
                            inventoryApprovalItem {
                              id
                            }
                            success
                            errors
                          }
                        }`
                        const inventory_approval_response = await axiosInstance.post(`/itemmaster/graphql`, {'query': inventory_approval_mutation_query});
                        let created_inventory_id = inventory_approval_response?.data?.data?.inventoryApprovalCreateMutation
                        if(created_inventory_id?.success){
                          inventroId.push(created_inventory_id?.inventoryApprovalItem?.id)
                          stock_history_data_list.push(stock_history_data)
                        }
                      } catch(error){
                        console.log(error);
                      }
                    }
                  } catch(error){
                    console.log(error);
                  }
              } else if(singlePost['BatchNumber']){
                  batch_or_serial = 'batch'
                  let stock_fetch_query = StockDataFetch(1, 200, 
                    { partNumber: singlePost['part_no']['value'], 
                      store: storeSelect?.value, 
                      batchNumber: singlePost['BatchNumber']['value']}, 
                    [])
                  let stock_data = await axiosInstance.post("/itemmaster/graphql", {query: stock_fetch_query})
                  let tocheckBatch = stock_data?.data?.data?.itemStock?.items?.[0]
                  let previous_state = 0
                  stock_history_data = {
                    "action": "DELETE",
                    "storeLink": storeSelect?.value,
                    "partNumber": singlePost['part_no']['value'],
                    "previousState": previous_state,
                    "added": 0,
                    "reduced": singlePost['qty'],
                    "updatedState": Number(previous_state) - Number(singlePost['qty']),
                  }
                  axiosInstance.post("/itemmaster/graphql", {query: TotalStockByPartCode(singlePost['part_no']['value'])})
                        .then(res => {
                            let total_stock_data = res?.data?.data?.itemStockByPartCode?.items
                            total_stock_data = total_stock_data?.[0]?.totalCurrentStock
                            if(total_stock_data){
                                previous_state = total_stock_data
                                stock_history_data['previousState'] = previous_state
                                stock_history_data['updatedState'] = Number(previous_state) - Number(singlePost['qty'])
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        });
                  tocheckBatch['currentStock'] = Number(tocheckBatch?.['currentStock']) - Number(singlePost?.['qty'])
                  try{
                    let item_stock_mutate =  `mutation itemstockmutation {
                      itemStockCreateMutation(id:${tocheckBatch['id']}, currentStock: ${tocheckBatch['currentStock']}){
                        success
                        errors
                        itemStock{
                          id
                        }
                      }
                    }`
                    const response = await axiosInstance.post("/itemmaster/graphql", {query: item_stock_mutate})
                    let stock_update_response = response?.data?.data?.itemStockCreateMutation
                    if(stock_update_response.success){
                      stock_history_data['stockLink'] = stock_update_response.itemStock.id
                      try{
                        let inventory_approval_mutation_query = `mutation InventoryApprovalCreateQuery{
                          inventoryApprovalCreateMutation(partNumber: ${singlePost_1?.['part_no']['value']},
                          batchNumber: ${singlePost_1?.['BatchNumber']['value']}, unit: ${singlePost_1?.['UOM']['value']}, qty: "${singlePost_1?.['qty']}", store: ${storeSelect?.['value']}){
                            inventoryApprovalItem {
                              id
                            }
                            success
                            errors
                          }
                        }`
                        const inventory_approval_response = await axiosInstance.post(`/itemmaster/graphql`, {'query': inventory_approval_mutation_query});
                        let created_inventory_id = inventory_approval_response?.data?.data?.inventoryApprovalCreateMutation
                        if(created_inventory_id?.success){
                          inventroId.push(created_inventory_id?.inventoryApprovalItem?.id)
                          stock_history_data_list.push(stock_history_data)
                        }
                      } catch(error){
                          console.log(error);
                      }
                    }
                  } catch(error){
                    console.log(error);
                  }
              } else if(singlePost['Serialnum']){
                  batch_or_serial = 'serial'
                  let stock_fetch_query = StockDataFetch(1, 200, 
                    { partNumber: singlePost['part_no']['value'], 
                      store: storeSelect?.value}, 
                    [])
                    let stock_data = await axiosInstance.post("/itemmaster/graphql", {query: stock_fetch_query});
                    let tocheckSerial = null;
                    
                    if (stock_data && stock_data.data && stock_data.data.data && stock_data.data.data.itemStock && stock_data.data.data.itemStock.items) {
                        tocheckSerial = stock_data.data.data.itemStock.items[0];
                    }
                    if (tocheckSerial) {
                      let previous_state = 0
                        stock_history_data = {
                        "action": "DELETE",
                        "storeLink": storeSelect?.value,
                        "partNumber": singlePost['part_no']['value'],
                        "previousState": previous_state,
                        "added": 0,
                        "reduced": singlePost['qty'],
                        "updatedState": Number(previous_state) - Number(singlePost['qty']),
                        }
                        axiosInstance.post("/itemmaster/graphql", {query: TotalStockByPartCode(singlePost['part_no']['value'])})
                        .then(res => {
                            let total_stock_data = res?.data?.data?.itemStockByPartCode?.items
                            total_stock_data = total_stock_data?.[0]?.totalCurrentStock
                            if(total_stock_data){
                                previous_state = total_stock_data
                                stock_history_data['previousState'] = previous_state
                                stock_history_data['updatedState'] = Number(previous_state) - Number(singlePost['qty'])
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        });
                        tocheckSerial['currentStock'] =  tocheckSerial['currentStock'] - singlePost['qty'];
                        let BalnceSerialIds = tocheckSerial['serialNumber'].filter(serialId =>
                            !singlePost.Serialnum.some(serial => String(serial.value) === String(serialId.id))
                        );
                        BalnceSerialIds = BalnceSerialIds.map(item => Number(item.id))
                        tocheckSerial['serialNumber'] = BalnceSerialIds;
                        // singlePost.Serialnum.map(serial => {
                        //   let delete_serial_number_query = `mutation SerialNumberDeleteMutation{
                        //     serialNumberDeleteMutation(id: ${serial.value}){
                        //       success
                        //       errors
                        //     }
                        //   }`
                        //   axiosInstance.post("/itemmaster/graphql", {query: delete_serial_number_query})
                        //   .then(res => {
                        //       console.log('Serial Number Deleted Successfully')
                        //   })
                        //   .catch(err => {
                        //       console.log(err)
                        //   });
                        // })
                        
                    }
                  try{
                    let item_stock_mutate =  `mutation itemstockmutation {
                      itemStockCreateMutation(id:${tocheckSerial['id']}, currentStock: ${tocheckSerial['currentStock']},
                      serialNumber: [${tocheckSerial['serialNumber']}]){
                        success
                        errors
                        itemStock{
                          id
                      }
                      }
                    }`
                    const response = await axiosInstance.post("/itemmaster/graphql", {query: item_stock_mutate})
                    let stock_update_response = response?.data?.data?.itemStockCreateMutation
                   if(stock_update_response?.success){
                    stock_history_data['stockLink'] = stock_update_response.itemStock.id
                        let saveInventorySerial =   {
                          qty : singlePost_1['qty'],
                          BatchNumber :  "",
                          isDelete : false,
                          part_no : singlePost_1['part_no']['value'],
                          UOM : singlePost_1['UOM']['value'],
                          Store : storeSelect['value'],
                          Serialnum : singlePost_1.Serialnum.map(serial => serial.value),
                        }
                        try{
                          let inventory_approval_mutation_query = `mutation InventoryApprovalCreateQuery{
                            inventoryApprovalCreateMutation(partNumber: ${singlePost_1?.['part_no']['value']},
                            serialNumber: [${saveInventorySerial?.['Serialnum']}], unit: ${singlePost_1?.['UOM']['value']}, qty: "${singlePost_1?.['qty']}", store: ${storeSelect?.['value']}){
                              inventoryApprovalItem {
                                id
                              }
                              success
                              errors
                            }
                          }`
                          const inventory_approval_response = await axiosInstance.post(`/itemmaster/graphql`, {'query': inventory_approval_mutation_query});
                          let created_inventory_id = inventory_approval_response?.data?.data?.inventoryApprovalCreateMutation
                          if(created_inventory_id?.success){
                            inventroId.push(created_inventory_id?.inventoryApprovalItem?.id)
                            stock_history_data_list.push(stock_history_data)
                          }
                        } catch(error){
                          console.log(error);
                        }
                      }
                  } catch(error){
                    console.log(error);
                  }
              }
          }
        if(inventroId.length > 0) {
          try{
              let inventory_handler_mutation_query = `mutation InventoryHandlerCreateQuery{
                inventoryHandlerCreateMutation(savedBy: ${userId}, inventoryId: [${inventroId}], store: ${storeSelect?.['value']}, actions: "Delete"){
                inventoryHandlerItem {
                  inventoryHandlerId
                  id
                }
                success
                errors
                }
              }`
              try {
                  const inventory_handler_response = await axiosInstance.post("/itemmaster/graphql", {query: inventory_handler_mutation_query})
                  const inventory_handler_response_data = inventory_handler_response?.data?.data?.inventoryHandlerCreateMutation
                  if(inventory_handler_response_data?.success){
                      stock_history_data_list.forEach(item => {
                          let current_history_item = {...item}
                          current_history_item['transactionModule'] = "InventoryHandler"
                          current_history_item["displayName"] = "Stock Deletion"
                          current_history_item['savedBy'] = userId
                          current_history_item['displayId'] = inventory_handler_response_data?.inventoryHandlerItem?.inventoryHandlerId
                          current_history_item['transactionId'] = inventory_handler_response_data?.inventoryHandlerItem?.id
                          let stock_history_mutation_query = CreateStockHistory(current_history_item)
                          axiosInstance.post("/itemmaster/graphql", {query: stock_history_mutation_query})
                          .then(res => {
                              console.log(res?.data?.data?.stockHistoryCreateMutation)
                          }).catch(err => {
                              console.log(err);
                          })
                      })
                      close()
                      setLoad(!isLoad)
                  }else{
                      console.log(inventory_handler_response_data)
                      // alert_custom_error(inventory_handler_response_data?.errors[0])
                  }
                  
              } catch (error) {
                  console.error('Error updating stock:', error);
              } 
          } catch(error){
              console.log(error);
          }
        } 
        } catch(error){
            console.log(error);
        }
        setLoadingModels(false)
      }
    
      const handleShowDeleteModel = () => {
        setDeleteComfomation(true);
      };
      
      const handleCloseDeleteModel = () => {
        setDeleteComfomation(false);
      };
    
      const [deleteConfomation, setDeleteComfomation] = useState(false);
      const [deleteData, setDeleteData] = useState({})
    
      const RowDeleteConfomations = () => {
     
          const deleteTheData=()=>{
            // console.log(post);
            // console.log(deleteData);
             const removed_delete_for_total_data = post.filter(data=> data.id !== deleteData.id)
             console.log(removed_delete_for_total_data);
            setPost(removed_delete_for_total_data)
            setDeleteData({})
            handleCloseDeleteModel()
          }
         return (
           <>
             <Modal show={deleteConfomation} onHide={handleCloseDeleteModel}>
               <Modal.Header closeButton>
                 <Modal.Title>Delete Confomations</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                <div className='row fs-5 ms-2 text-danger'>
                Are you Confirm To Delete {deleteData['part_no']? deleteData['part_no']['label'] : ""}  
                </div>
                </Modal.Body>
               <Modal.Footer>
                 <Button variant="secondary" onClick={()=>{handleCloseDeleteModel();
                 setDeleteData({})
                 }}>
                   Close
                 </Button>
                 <Button variant="primary" onClick={deleteTheData}>
                   Save Changes
                 </Button>
               </Modal.Footer>
             </Modal>
           </>
         );
      };
       
      const getDeleteRowId=(data)=>{
        setDeleteData(data)
        handleShowDeleteModel()
      }

      useEffect(()=>{
        dispatch(fetchStoreData(storeFetchQuery()));
        dispatch(fetchStockSerialHistoryData(StockSerialHistoryDataFetch()));
      }, [dispatch])

    return (
        <>
        {LoadingModel()}
            <RowDeleteConfomations/>
            <Modal show={show} size='xl' onHide={close} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Stock Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div  className='container-fluid m-0 p-0 bg-white' style={{height:'100%'}}>
                    <div className='row'>
                        <div className='col-3 pe-4 staan-input-group'>
                            <Select 
                                options={storeDataList?.items?.filter(item => item.matained === true).map((item) => ({ value: item.id, label: item.storeName}))} 
                                value={storeSelect}
                                onChange={(option)=>{handleSelectStore(option)}} 
                                styles={customSelectStyle}
                                className='mt-3'
                            />
                            <label htmlFor="exampleFormControlInput1" className="staan-form-input-label pt-2 px-1 mx-2" >Store</label>
                        </div> 
                    </div>
                    <div style={{width: '100%'}}>
                      <div style={{height: '35rem', overflowY: 'scroll'}}>
                          <StaanTables
                              row = {post}
                              column = {column}  
                              inputSection = {InputFrom}
                              getDeleteRowId= {getDeleteRowId}
                          />
                        </div>
                    </div>
                    <div className='mt-2 d-flex justify-content-end'>
                        <div>
                        <Can allow_list={["Save_Stock_Deletion","Edit_Stock_Deletion"]}>
                        <button type="button" className="btn btn-success" onClick={ReduseQty}>Save</button>
                        </Can>
                        </div>
                    </div>
                </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default StockDeletion;