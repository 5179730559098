import React, { useEffect, useState } from 'react';
import '../Style/staan_table.css';
import StaanRows from './StaanRows1.2';

const StaanTab = ({row, column, inputSection, getDeleteRowId, isdisabled, deleteIsdisabled,  }) => { 
    const [tempRow, setTempRow ] = useState([]) 
    //  last id
    const [rowId , setRowId] = useState(1)

    //  to handle open state
    const [isOpen, setOpenState] = useState(false);
 
    function toggle(params) {   
      setOpenState(!isOpen); 
    }

    //  it is handle which row want to open
    const [openRowIndex, setOpenRowIndex] = useState(0);
 

    // to handle not able to add next new row with out saving already created one
    useEffect(() => { 
      
      row?.forEach((item, index) => {setRowId(Number(item.id) + 1)}); 
      setTempRow(row); 
    }, [row]);
  

  const addRow =()=>{  
      let newRow = {}
      column.map((col)=>{newRow[col.fieldname] = ''})
      newRow['id'] = rowId
      setTempRow([...tempRow, newRow])
      toggle()
      setOpenRowIndex(rowId)
  }

  const handleTableCurrentlyActive = () => { 
    let current_actives = document.getElementsByClassName("fa-regular fa-floppy-disk");
    if(current_actives.length >0){ 
      alert('Please save any active row before proceeding')
    } else{ 
      addRow()
    }
  }

  return (
    <>
      <div id='staan-input-table' style={{height: '100%'}}>
        <table className='StaanTable'>
          <thead>
            <tr style={{fontSize: '.9rem'}}>
              {column.map((col, index) => (
                <th
                  onClick={()=>{console.log(row)}}
                  key={index}
                  style={{
                    width: col['width'] ? `${col['width']}rem` : 'fit-content',
                    display: col['hide'] ? 'none' : 'table-cell'
                  }}
                  >
                  {col['header']}
                </th>
              ))}
              <th>Actions</th>
            </tr> 
          </thead>
          <tbody>
              <StaanRows
                tempRow ={tempRow}
                isdisabled = {isdisabled}
                column ={column}  
                isOpen = {isOpen}
                toggle = {toggle}
                setOpenState = {setOpenState}
                getDeleteRowId = {getDeleteRowId}
                openRowIndex = {openRowIndex} 
                setOpenRowIndex = {setOpenRowIndex}
                inputSection = {inputSection}
                deleteIsdisabled = {deleteIsdisabled}
              />
          </tbody>
          
        <tfoot>
        <tr style={{background:'#ffff'}}>
            <td colSpan={9}>
              <button 
                className='btn btn-sm btn-outline-primary' 
                  type="button" 
                  style={{borderRadius: 'none !important', border: '1px solid'}}
                  disabled={isdisabled}
                  onClick={()=>{handleTableCurrentlyActive()}}  ><i className="fa-solid fa-plus"></i> New</button>
            </td>
          </tr>
        </tfoot>
        </table>
      </div>
    </>
  )
}

export default StaanTab