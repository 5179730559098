import { createFgItem, createRmItem, createScrapItem } from "../../GraphQLQueries/BomMutation";
import axiosInstance from "../../api/axoiss";

// Action Types
// Finished Goods item action types
export const FETCH_FG_DATA_REQUEST = 'FETCH_FG_DATA_REQUEST';
export const FETCH_FG_DATA_SUCCESS = 'FETCH_FG_DATA_SUCCESS';
export const FETCH_FG_DATA_FAILURE = 'FETCH_FG_DATA_FAILURE';
// Finished Goods Item create action types
export const CREATE_FG_DATA_REQUEST = 'CREATE_FG_DATA_REQUEST';
export const CREATE_FG_DATA_SUCCESS = 'CREATE_FG_DATA_SUCCESS';
export const CREATE_FG_DATA_FAILURE = 'CREATE_FG_DATA_FAILURE';
// Raw Material item action types
export const FETCH_RM_DATA_REQUEST = 'FETCH_RM_DATA_REQUEST';
export const FETCH_RM_DATA_SUCCESS = 'FETCH_RM_DATA_SUCCESS';
export const FETCH_RM_DATA_FAILURE = 'FETCH_RM_DATA_FAILURE';
// Raw Material Item create action types
export const CREATE_RM_DATA_REQUEST = 'CREATE_RM_DATA_REQUEST';
export const CREATE_RM_DATA_SUCCESS = 'CREATE_RM_DATA_SUCCESS';
export const CREATE_RM_DATA_FAILURE = 'CREATE_RM_DATA_FAILURE';
// Raw Material item action types
export const FETCH_SCRAP_DATA_REQUEST = 'FETCH_SCRAP_DATA_REQUEST';
export const FETCH_SCRAP_DATA_SUCCESS = 'FETCH_SCRAP_DATA_SUCCESS';
export const FETCH_SCRAP_DATA_FAILURE = 'FETCH_SCRAP_DATA_FAILURE';
// Raw Material Item create action types
export const CREATE_SCRAP_DATA_REQUEST = 'CREATE_SCRAP_DATA_REQUEST';
export const CREATE_SCRAP_DATA_SUCCESS = 'CREATE_SCRAP_DATA_SUCCESS';
export const CREATE_SCRAP_DATA_FAILURE = 'CREATE_SCRAP_DATA_FAILURE';


export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';


// Fetch Items Action Creators
const fetchDataRequest = (action_type) => {
  return {
    type: action_type,
  };
};


const fetchDataSuccess = (data, action_type) => {
  return {
    type: action_type,
    payload: data,
  };
};


const fetchDataFailure = (error, action_type) => {
  return {
    type: action_type,
    payload: error,
  };
};


// Create FG Item Action Creators
const createDataRequest = (action_type) => {
    return {
      type: action_type,
    };
};

  
const createDataSuccess = (data, action_type) => {
    return {
      type: action_type,
      payload: data,
    };
};


const createDataFailure = (error, action_type) => {
    return {
      type: action_type,
      payload: error,
    };
};


// FG fetch all Thunk Action Creators
export const fetchFgData = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchDataRequest(FETCH_FG_DATA_REQUEST));
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
    .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        dispatch(fetchDataSuccess(data, FETCH_FG_DATA_SUCCESS));
    })
    .catch(error => {
        dispatch(fetchDataFailure(error.message, FETCH_FG_DATA_FAILURE));
    });
  };
};


// FG create item
export const createFgData = (data) => {
    return (dispatch) => {
        dispatch(createDataRequest(CREATE_FG_DATA_REQUEST));
        let mutation_query = createFgItem(data)
        axiosInstance.post("/itemmaster/graphql", {query: mutation_query})
        .then(response => {
            const keys = Object.keys(response.data.data);
            const firstKey = keys.length > 0 ? keys[0] : null;
            const data = response.data.data[firstKey]
            if(data.success){
                dispatch(createDataSuccess(data, CREATE_FG_DATA_SUCCESS));
            }else{
                dispatch(createDataFailure(data, CREATE_FG_DATA_FAILURE));
            } 
        })
        .catch(error => {
            dispatch(createDataFailure(error.message, CREATE_FG_DATA_FAILURE));
        });
    }
};


// RM fetch all 
export const fetchRMData = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchDataRequest(FETCH_RM_DATA_REQUEST));
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
    .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        dispatch(fetchDataSuccess(data, FETCH_RM_DATA_SUCCESS));
    })
    .catch(error => {
        dispatch(fetchDataFailure(error.message, FETCH_RM_DATA_FAILURE));
    });
  };
};


// RM create item
export const createRMData = (data) => {
  return (dispatch) => {
      dispatch(createDataRequest(CREATE_RM_DATA_REQUEST));
      let mutation_query = createRmItem(data)
      axiosInstance.post("/itemmaster/graphql", {query: mutation_query})
      .then(response => {
          const keys = Object.keys(response.data.data);
          const firstKey = keys.length > 0 ? keys[0] : null;
          const data = response.data.data[firstKey]
          if(data.success){
              dispatch(createDataSuccess(data, CREATE_RM_DATA_SUCCESS));
          }else{
              dispatch(createDataFailure(data, CREATE_RM_DATA_FAILURE));
          } 
      })
      .catch(error => {
        console.log(error)
          dispatch(createDataFailure(error.message, CREATE_RM_DATA_FAILURE));
      });
  }
}


// Scrap fetch all 
export const fetchScrapData = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchDataRequest(FETCH_SCRAP_DATA_REQUEST));
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
    .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        dispatch(fetchDataSuccess(data, FETCH_SCRAP_DATA_SUCCESS));
    })
    .catch(error => {
        dispatch(fetchDataFailure(error.message, FETCH_SCRAP_DATA_FAILURE));
    });
  };
};


// Scrap create item
export const createScrapData = (data) => {
  return (dispatch) => {
      dispatch(createDataRequest(CREATE_SCRAP_DATA_REQUEST));
      let mutation_query = createScrapItem(data)
      axiosInstance.post("/itemmaster/graphql", {query: mutation_query})
      .then(response => {
          const keys = Object.keys(response.data.data);
          const firstKey = keys.length > 0 ? keys[0] : null;
          const data = response.data.data[firstKey]
          if(data.success){
              dispatch(createDataSuccess(data, CREATE_SCRAP_DATA_SUCCESS));
          }else{
              dispatch(createDataFailure(data, CREATE_SCRAP_DATA_FAILURE));
          } 
      })
      .catch(error => {
        console.log(error)
          dispatch(createDataFailure(error.message, CREATE_SCRAP_DATA_FAILURE));
      });
  }
}


// fetch routing
export const fetchRoutingData = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchDataRequest(FETCH_DATA_REQUEST));
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
    .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        dispatch(fetchDataSuccess(data, FETCH_DATA_SUCCESS));
    })
    .catch(error => {
        dispatch(fetchDataFailure(error.message, FETCH_DATA_FAILURE));
    });
  };
};


// fetch work center
export const fetchWorkCenterData = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchDataRequest(FETCH_DATA_REQUEST));
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
    .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        dispatch(fetchDataSuccess(data, FETCH_DATA_SUCCESS));
    })
    .catch(error => {
        dispatch(fetchDataFailure(error.message, FETCH_DATA_FAILURE));
    });
  };
};


// fetch bom option list
export const fetchBOMData = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchDataRequest(FETCH_DATA_REQUEST));
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
    .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        dispatch(fetchDataSuccess(data, FETCH_DATA_SUCCESS));
    })
    .catch(error => {
        dispatch(fetchDataFailure(error.message, FETCH_DATA_FAILURE));
    });
  };
};


// fetch supplier
export const fetchSupplierData = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchDataRequest(FETCH_DATA_REQUEST));
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
    .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        dispatch(fetchDataSuccess(data, FETCH_DATA_SUCCESS));
    })
    .catch(error => {
        dispatch(fetchDataFailure(error.message, FETCH_DATA_FAILURE));
    });
  };
};
