import { AgGridReact } from 'ag-grid-react'
import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { AiOutlinePrinter, AiOutlineShoppingCart } from 'react-icons/ai'
import { MdInput } from 'react-icons/md'
import { TiCancel } from 'react-icons/ti'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Bounce, toast, ToastContainer } from 'react-toastify'
import io from 'socket.io-client'
import {
  CancelPOSQuery,
  checkStockPOSQuery,
  createPOSMode,
  createPosOrderItem,
  deletePosOrderItem,
  salesOrderBulkCreateMutation,
  salesOrderBulkUpdatetionsMutation,
  salesOrderItemClearDiscount,
  salesOrderItemFinalTotalDiscount,
  salesOrderItemOverallDiscountPercentage,
  salesOrderItemoverallDiscountValue,
  SubmitPOSQuery
} from '../../GraphQLQueries/ItemMastermutation'
import * as QueryData from '../../GraphQLQueries/ItemmasterQuery'
import {
  FetchPosSalesOrderItemCombo,
  UpdateItemComboToSalesOrder
} from '../../GraphQLQueries/PosItemComboQuery'
import { fetchOptionsData } from '../../ReduxState/Action/itemMasterAction'
import axiosInstance, { commanFetch } from '../../api/axoiss'
import WaringModel from '../../components/Warings/WaringModel'
import Can from '../../components/authorizationComponents/Can'
import DeleteConfirmModal from '../../components/deleteConformations.js/DeleteConfirmModal'
import LoadingModelBallTriangle from '../../components/loading/BallTriangle'
import showErrorToast from '../../components/notifications/react-toastify/toast'
import DataContext from '../../context/ItemMasterContext'
import useShortcutFocus from '../../hook/useShortcutFocus'
import { applyDiscountOverall } from '../../utils/PosUtils'
import {
  // TimeFormate,
  calculateTax,
  checkChangesInputs,
  getCurrentDate,
  onCellDoubleClicked,
  OnchangeGetData,
  posCustomSelectStyle,
  removeEmptyValueInObject,
  roundToNearest10,
  ShowErrorNoties
} from '../../utils/Utils'
import { PosSalesorderSchema } from '../../validations/itemmaster'
import SupplierFrom from '../ItemMaster/model/SupplierFrom'
import StockStatementDetails from '../StockStatement/Details/StockStatementDetails'
import PosItemComboModal from './model/PosItemComboModal'
import PosItemDetails from './model/PosItemDetails'
import POSPayment from './model/PosPayment'
import ShowStockNeed from './model/ShowStockNeed'
import ShowUnsaveditemcombo from './model/ShowUnsaveditemcombo'

const PosPage = ({ isShowPos, setIsShowPos }) => {
  const {
    Navigate,
    conferenct,
    setConference,
    userId,
    userName,
    posEdit,
    setPosEdit,
    currentConference,
    handleSupplierShow,
    currentConferenceStore,
    posItemData,
    setPosItemData,
    salesOrderData,
    setSalesOrderData,
    userdata,
    setUserdata,
    supplierSelect,
    setSupplierSelect
  } = useContext(DataContext)

  const dispatch = useDispatch()
  const getData = useSelector(state => state.itemGroup.data)
  const [waringModel, setWaringModel] = useState(false)
  const [showStockNeed, setShowStockNeed] = useState(false)
  const [showStockNeedList, setShowStockNeedList] = useState([])
  const [showComboUnSaved, setshowComboUnSaved] = useState(false)
  const [itemComboUnSavedList, setitemComboUnSavedList] = useState([])
  const [waringToClearStoreModel, setWaringToClearStoreModel] = useState(false)
  const gridApiRef = useRef(null)
  const [isDataSave, setisDataSave] = useState(false)
  const [totalBillingItem, setTotalBillingItem] = useState(0)
  const cosNameRef = useRef(null)
  const [addedItems, setAddedItems] = useState({})
  const [storeOptions, setStoreOptions] = useState([])
  const [ItemDetaileData, setItemDetailsData] = useState([])
  const [posItemEdit, setPosItemEdit] = useState('')
  const [positemAdd, setPosItemAdd] = useState(false)
  const [BillingAddresssOptions, setBillingAddresssOptions] = useState([])
  const [DeliverAddressOptions, setDeliverAddressOptions] = useState([])
  const [costomerGet, setCostomerGet] = useState(null)
  const [Edit, SetEdit] = useState(false)
  // amount details
  const [TotalValue, setTotalValue] = useState(0.0)
  const [totalTax, setTotalTax] = useState(0.0)
  const [TotalWithTaxValue, setTotalWithTaxValue] = useState(0.0)
  const [topayWithoutRoundoff, settopayWithoutRoundoff] = useState(0.0)
  const [sgstTax, setSgstTax] = useState([])
  const [cgstTax, setCgstTax] = useState([])
  const [igstTax, setIgstTax] = useState([])
  const [supplierOptions, setSupplierOptions] = useState([])
  const [checkDiscount, setCheckDiscount] = useState(false)
  const [posCustomId, setPosCustomId] = useState('')
  const [currentBalance, setCurrentBalance] = useState(0.0)
  const [receivedAmount, setReceivedAmount] = useState(0.0)

  const [totalBillAmount, setTotalBillAmount] = useState(0.0)
  const [checkStockAvailability, setCheckStockAvailability] = useState()
  const [isSomeStockReduced, setIsSomeStockReduced] = useState(false)
  const [isAllStockReduced, setIsAllStockReduced] = useState(false)
  const [isPaymentAdd, setPaymentAdd] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValue, setinitialValue] = useState({
    id: '',
    marketingEvent: '',
    OrderDate: '',
    isSales: false,
    CostomerName: '',
    BillingAddress: '',
    DeliverAddress: '',
    Store: '',
    Mobile: '',
    WhatsappNumber: '',
    CosName: '',
    Email: '',
    State: '',
    City: '',
    createdBy: '',
    SalesPerson: '',
    payment: []
  })
  const [formKey, setFormKey] = useState(0)
  const [remarksText, setRemarksText] = useState('')
  const [isDelivered, setisDelivered] = useState(true)
  const [posTypeChangeError, setPosTypeChangeError] = useState(null)
  const [posEditData, setPosEditData] = useState(null)
  const [isLoad, setIsLoad] = useState(false)
  const [selectedStore, setSelectedStore] = useState([])
  const [stateForSales, setstateForSales] = useState('')
  const [costomerName, setCostomerName] = useState('')
  const [mobile, setMobile] = useState('')
  const [selectMarketingEvent, setSelectMarketingEvent] = useState('')
  const [salesPerson, setSalesPerson] = useState({ value: '', label: '' })
  const [contactNumber, setContactNumber] = useState('')
  const [contactPersonOptions, setContactPersonOptions] = useState([])
  const [contactPerson, setContactPerson] = useState({ value: '', label: '' })
  const [city, setCity] = useState('')
  const [State, setState] = useState([])
  const [pincode, setPincode] = useState('')

  const [pincodeList, setPincodeList] = useState([])
  const [selectcostomerName, setSelectCostomerName] = useState([])
  const [selectLegalName, setSelectLegalName] = useState([])
  const [mobileNumber, setMobileNumber] = useState('')
  const [whatsAppNumber, setWhatsAppNumber] = useState('')
  const [email, setEmail] = useState('')
  const [selectBillingAddress, setSelectBillingAddress] = useState('')
  const [discountPercentageInValue, setDiscountPercentageInValue] = useState('')
  const [discountPercentage, setDiscountPercentage] = useState('')
  const [totalDiscountValueForSales, setTotalDiscountValueForSales] =
    useState('')
  const [discountValue, setDiscountValue] = useState('')
  const inputRef = React.createRef()
  let timeoutId
  let updateValue = ''
  const [scannerValue, setScannerValue] = useState('')
  const [paymentTransIds, setPaymentTransIds] = useState([])
  const [discountError, setdiscountError] = useState('')
  const [salesActiveClassName, setSalesActiveClassName] = useState('bg-white')
  const [samplesActiveClassName, setSamplesActiveClassName] =
    useState('logoGreen')
  const [isSales, setSales] = useState(false)
  const [orderDate, setOrderDate] = useState(getCurrentDate())
  const [selectDeliverAddress, setSelectDeliverAddress] = useState({
    value: '',
    label: ''
  })
  const [pending, setPending] = useState(false)
  const [disCountModel, setdisCountModel] = useState(false)
  const [showItemComboModal, setShowItemComboModal] = useState(false)
  const [isShowPaymentModal, setShowPaymentModal] = useState(false)
  const [TaxDetailsModel, setTaxDetailsModel] = useState(false)
  useShortcutFocus(inputRef, 's', true) // 's' for the S key, true for Alt key
 
  const [isShowDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false)
  const [loadingModels, setLoadingModels] = useState(false)
  const [discountTotalValue, setDiscountTotalValue] = useState('')
  const [currentEditItemComboId, setCurrentEditItemComboId] = useState(null)
  const [currentEditItemComboData, setCurrentEditItemComboData] = useState(null)
  const [initialProcess, setInitialProcess] = useState(false)
  const [samplelinkedCostomerId, setSamplelinkedCostomerId] = useState(null)
  const [isNewContact, setIsNewContact] = useState(false)
  const [contactList, setContactList] = useState([])

  const handlepositemShow = () => {
    setPosItemAdd(true)
  }
  const handlepositemclose = () => {
    setPosItemAdd(false)
  }

  const handleOpenPaymentModal = () => {
    setShowPaymentModal(true)
  }

  const handleClosePaymentModal = () => {
    setShowPaymentModal(false)
  }

  const handleShowItemComboModal = () => {
    setShowItemComboModal(true)
  }

  const handleCloseItemComboModal = () => {
    setShowItemComboModal(false)
  }

  const handleShowDisCountModel = () => {
    setdisCountModel(true)
  }

  // input value handler for intable edit form
  //  State for Sales
  const handleCostomerName = even => {
    setCostomerName(even.target.value)
  }

  const handleMarketingEventChange = options => {
    if (options) {
      setSelectMarketingEvent({ value: options.value, label: options.label })
    } else {
      setSelectMarketingEvent({ value: '', label: '' })
    }
  }

  const handleChangeSalesPerson = options => {
    if (options) {
      setSalesPerson({ value: options.value, label: options.label })
    } else {
      setSalesPerson({ value: '', label: '' })
    }
  }

  const handleChangeContactPerson = options => {
    if (options) {
      setContactPerson({ value: options.value, label: options.label })
      setContactNumber(options.phone)
    } else {
      setContactPerson({ value: '', label: '' })
      setContactNumber('')
    }
  }

  const handleStateChange = even => {
    setState(even.target.value)
  }

  const handleSelectCostomerName = options => {
    if (options) {
      setSelectCostomerName(
        { value: options.value, label: options.label },
        setCostomerName(options.label)
      )
      initialValue['CosName'] = options.label
      getAddress(options.value)
    } else {
      setSelectCostomerName({ value: '', label: '' }, setCostomerName(''))
      setSelectLegalName('')
      initialValue['CosName'] = ''
      getAddress('')
    }
  }

  const handlemobileNumber = even => {
    setMobileNumber(even.target.value)
  }

  const handlewhatsAppNumber = e => {
    setWhatsAppNumber(e)
  }

  const handleEmail = even => {
    setEmail(even.target.value)
  }

  const handleSelectBillingAddress = options => {
    if (options) {
      setstateForSales(options.State)
      setSelectBillingAddress({ value: options.value, label: options.label })
    } else {
      setstateForSales('')
      setSelectBillingAddress({ value: '', label: '' })
    }
  }

  const handleSelectDeliverAddress = options => {
    if (options) {
      setSelectDeliverAddress({ value: options.value, label: options.label })
    } else {
      setSelectDeliverAddress({ value: '', label: '' })
    }
  }

  const handlediscountpercentage = value => {
    setDiscountPercentage(value)
  }

  const handlediscountValue = value => {
    setDiscountValue(value)
  }

  const handleDiscountTotalValue = value => {
    setDiscountTotalValue(value)
  }

  const handlescannerValueChange = value => {
    updateValue = updateValue + value
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      setScannerValue(updateValue)
    }, 300)
  }

  const handleCloseDisCountModel = () => {
    if (
      Number(discountPercentageInValue) > 0 ||
      Number(discountValue) > 0 ||
      Number(discountTotalValue) > 0
    ) {
      if (!checkDiscount) {
        setdiscountError('Apply the discount or clear value')
      } else {
        setdisCountModel(false)
        if (!checkDiscount) {
          handleDiscountTotalValue()
          handlediscountpercentage()
          handlediscountValue()
        }
      }
    } else {
      setdisCountModel(false)
    }
  }

  const handleCurrentPendingBalance = (blnc_amt, received_amt) => {
    setReceivedAmount(received_amt)
    // let calBalance= TotalWithTaxValue ? TotalWithTaxValue- receivedAmount : TotalValue? TotalValue-receivedAmount : ""
  }

  // retun 0; fetch data in Pos
  async function fetchDatawithRedex () {
    //  it is send the query for send request
    dispatch(fetchOptionsData(QueryData.storeFetchQuery(), 'StoreOptionsInPos'))
    let responseData = await OnchangeGetData(
      QueryData.ConferenceOptions(),
      '/graphql'
    )
    if (responseData.success) {
      setConference(responseData.data.conferenceData.items)
    }
  }

  async function getOptions (e, query) {
    let search_term = e.trim().replace(/"/g, '\\"')
    if (query === 'userdataOptionsQuery' && search_term) {
      let responseData = await OnchangeGetData(
        QueryData.userdataOptionsQuery(search_term)
      )
      if (responseData.success) {
        setUserdata(responseData.data.User.items)
      }
    }
    if (query === 'SupplierOption' && search_term) {
      let responseData = await OnchangeGetData(
        QueryData.SupplierOption(search_term)
      )
      if (responseData.success) {
        setSupplierOptions(responseData.data.supplierFormData.items)
      }
    }
  }

  const handleSelectStore = options => {
    if (ItemDetaileData.length > 0) {
      setWaringToClearStoreModel(true)
    } else {
      if (options) {
        localStorage.setItem('SelectedPosStore', options?.value)
        setSelectedStore({ value: options?.value, label: options?.label })
        initialValue['Store'] = options?.value
      } else {
        setSelectedStore({ value: ' ', label: '' })
      }
    }
  }

  // retun 0; call it on initial
  useEffect(() => {
    fetchDatawithRedex()
    if (posEdit === '' || posEdit === null || posEdit === undefined) {
      if (!posEdit && currentConference) {
        initialValue['marketingEvent'] = currentConference?.value
        handleMarketingEventChange({
          value: currentConference?.value,
          label: currentConference?.lable
        })
      }
      if (!posEdit && currentConferenceStore) {
        let Options = {
          value: currentConferenceStore?.value,
          label: currentConferenceStore?.lable
        }
        handleSelectStore(Options)
      }
    }
  }, [currentConferenceStore, currentConference])

  //retun null; handle Balnce
  useEffect(() => {
    if (TotalWithTaxValue && receivedAmount !== undefined) {
      setCurrentBalance(
        (roundToNearest10(TotalWithTaxValue) - receivedAmount).toFixed(2)
      )
      updateThePayment(
        (roundToNearest10(TotalWithTaxValue) - receivedAmount).toFixed(2)
      )
    } else if (TotalValue && receivedAmount !== undefined) {
      setCurrentBalance(
        (roundToNearest10(TotalValue) - receivedAmount).toFixed(2)
      )
      updateThePayment(
        (roundToNearest10(TotalValue) - receivedAmount).toFixed(2)
      )
    }
  }, [receivedAmount, TotalWithTaxValue, TotalValue])

  useEffect(() => {
    if (supplierSelect?.id) {
      setSelectCostomerName({
        value: supplierSelect?.id,
        label: supplierSelect?.lable
      })
      initialValue['CostomerName'] = supplierSelect?.id
      initialValue['CosName'] = supplierSelect?.lable
      setCostomerName(supplierSelect?.lable)
      getAddress(supplierSelect?.id)
    }
  }, [supplierSelect])

  async function getAddress (params) {
    if (params) {
      setBillingAddresssOptions([])
      setDeliverAddressOptions([])
      setContactPersonOptions([])
      setSelectBillingAddress('')
      setSelectDeliverAddress('')
      setContactNumber('')
      setContactPerson({ value: '', label: '' })
      setCostomerGet(null)
      initialValue['DeliverAddress'] = ''

      let responseData = await OnchangeGetData(QueryData.SupplierEdit(params))
      if (responseData.success) {
        if (responseData.data.supplierFormData.items[0]?.['gstin']) {
          setCostomerGet(responseData.data.supplierFormData.items[0]?.['gstin'])
        }
        if (responseData.data.supplierFormData.items[0].address.length > 0) {
          responseData.data.supplierFormData.items[0].address.map(Address => {
            if (Address.addressType === 'BILLING_ADDRESS') {
              let AddressLable = `${Address?.addressLine1}, ${Address?.addressLine2}, ${Address?.city} ,${Address?.state} , ${Address?.country}`
              setSelectBillingAddress({
                value: Address?.id,
                label: AddressLable,
                State: Address?.state
              })
              initialValue['BillingAddress'] = Address?.id
              setBillingAddresssOptions(preState => [
                ...preState,
                {
                  value: Address?.id,
                  label: AddressLable,
                  State: Address?.state
                }
              ])

              if (Address.state) {
                onChangPosTypeChangeTaxForSales(Address.state, false)
              }
              setstateForSales(Address?.state)
            } else if (String(Address.addressType) === 'SHIPING_ADDRESS') {
              let AddressLable = `${Address?.addressLine1}, ${Address?.addressLine2}, ${Address?.city} ,${Address?.state} , ${Address?.country}`
              // setSelectDeliverAddress({
              //   value: Address.id,
              //   label: AddressLable
              // })
              // initialValue['DeliverAddress'] = Address?.id
              setDeliverAddressOptions(preState => [
                ...preState,
                { value: Address.id, label: AddressLable }
              ])
            }
          })
        }
        let salesPerson =
          responseData.data.supplierFormData.items[0]['salesPerson']
        if (salesPerson !== '') {
          setSalesPerson({
            value: salesPerson?.id,
            label: salesPerson?.username
          })
          initialValue['SalesPerson'] = salesPerson?.id
        }
        let contact_person_options =
          responseData.data.supplierFormData.items[0]['contact']
        // default
        let defaul_context = contact_person_options.filter(
          itemContext => itemContext.default === true
        )
        if (defaul_context[0]) {
          handleChangeContactPerson({
            value: defaul_context[0]['id'],
            label: defaul_context[0]['contactPersonName'],
            phone: defaul_context[0]['phoneNumber']
          })
        }

        setMobileNumber(defaul_context[0]['phoneNumber'])
        initialValue['Mobile'] = defaul_context[0]['phoneNumber']
        setContactPersonOptions(contact_person_options)
      }
      setFormKey(prevKey => prevKey + 1)
    } else {
      setBillingAddresssOptions([])
      setDeliverAddressOptions([])
      setContactPersonOptions([])
      setSelectBillingAddress('')
      setSelectDeliverAddress('')
      setContactNumber('')
      setContactPerson({ value: '', label: '' })
    }
  }

  const handleSalesComponentActive = () => {
    setSalesActiveClassName('logoGreen')
    setSamplesActiveClassName('bg-body')
    setSales(true)
  }

  const handleSamplesComponentActive = () => {
    setSalesActiveClassName('bg-body')
    setSamplesActiveClassName('logoGreen')
    setSales(false)
  }

  const handleDeliver = () => {
    setisDelivered(!isDelivered)
  }

  const handlePending = () => {
    setPending(!pending)
  }
  const [status, setstatus] = useState(null)
  const [initialdata, setIitialdata] = useState(null)
  // const [createdBy, setCreatedBy] = useState()
  // const [ lastModified , setLastModified] = useState({date: "", time:""})

  // for Edit page

  async function fetchEditData (posEdit) {
    const response = await commanFetch(QueryData.PosSalesOrderEdit(posEdit))
    const reponseData = response?.data?.items
    if (response.success && reponseData.length > 0) {
      setPosEditData(reponseData)
      setInitialProcess(true)
      setIsSomeStockReduced(false)
      setIsAllStockReduced(reponseData[0]['allStockReduced'])
      SetEdit(true)
      setTotalValue(reponseData[0]['TotalAmount'])
      setPosCustomId(reponseData[0]['POSId']?.['linkedModelId'])
      setOrderDate(reponseData[0]['OrderDate'])
      setRemarksText(
        reponseData[0]['Remarks'] !== null ? reponseData[0]['Remarks'] : ''
      )
      setReceivedAmount(reponseData[0]['receivedAmount'])

      //   // Submit
      initialValue['status'] = reponseData[0]['status']
      setstatus(initialValue['status'])
      if (reponseData[0]['balanceAmount']) {
        setCurrentBalance(reponseData[0]['balanceAmount'])
      }
      if (reponseData[0]['receivedAmount']) {
        setReceivedAmount(reponseData[0]['receivedAmount'])
      }
      initialValue['createdBy'] = reponseData[0]['createdby']?.['id']
      // setCreatedBy(reponseData?.[0]?.['createdby']?.['username'])
      // setLastModified({date:  DateFormate(reponseData[0]['UpdatedAt']), time:TimeFormate(reponseData[0]['UpdatedAt'])})
      // // pos type
      if (reponseData[0]['State'] !== '' && reponseData[0]['State'] !== null) {
        setState(reponseData[0]['State'])
      } else {
        setState()
      }
      initialValue['isSales'] = reponseData[0]['posType']
      reponseData[0]['posType'] === 'Sample'
        ? handleSamplesComponentActive()
        : handleSalesComponentActive()
      // // marketiong event

      initialValue.marketingEvent = reponseData[0]['marketingEvent']?.id
      if (reponseData[0]['marketingEvent']) {
        handleMarketingEventChange({
          value: reponseData[0]['marketingEvent']?.id,
          label: reponseData[0]['marketingEvent']?.name
        })
      }
      initialValue['Store'] = reponseData[0]['store']?.id
      if (initialValue['Store'] !== '') {
        setSelectedStore({
          value: reponseData[0]['store']?.id,
          label: reponseData[0]['store']?.storeName
        })
        localStorage.setItem('SelectedPosStore', reponseData[0]['store']?.id)
      }
      initialValue['isDelivered'] = reponseData[0]['isDelivered']
      setisDelivered(reponseData[0]['isDelivered'])
      initialValue['Pending'] = reponseData[0]['Pending']
      setPending(reponseData[0]['Pending'])
      initialValue['payment'] = reponseData[0]['payment'].map(item =>
        Number(item.id)
      )
      if (reponseData[0]['payment'].length > 0) {
        setPaymentAdd(false)
      } else {
        setPaymentAdd(true)
      }
      setPaymentTransIds(initialValue['payment'])
      initialValue.SalesPerson = reponseData[0]['SalesPerson']?.['id']
      if (salesPerson !== '') {
        setSalesPerson({
          value: reponseData[0]['SalesPerson']?.['id'],
          label: reponseData[0]['SalesPerson']?.['username']
        })
      }

      setPosItemData(reponseData[0]['itemDetails'])
      for (let posEditDataIndex in reponseData[0]['itemDetails']) {
        let data = reponseData[0]['itemDetails'][posEditDataIndex]
        if (data['batch']?.id) {
          setAddedItems(prevValue => {
            //   // Check if the key already exists and is an array, then append to it
            const existingEntries = prevValue[data['partCode']['id']] || []
            return {
              ...prevValue,
              [data['partCode']['id']]: [
                ...existingEntries,
                {
                  id: data['id'],
                  batchNumber: data['batch']['id']
                }
              ]
            }
          })
        }
      }

      if (reponseData[0]['posType'] === 'Sample') {
        setIitialdata({
          marketingEvent: initialValue.marketingEvent
            ? initialValue.marketingEvent
            : '',
          Store: initialValue['Store'] ? initialValue['Store'] : '',
          CosName: reponseData[0]['CosName'] ? reponseData[0]['CosName'] : '',
          Mobile: reponseData[0]['Mobile'] ? reponseData[0]['Mobile'] : '',
          WhatsappNumber: reponseData[0]['WhatsappNumber']
            ? reponseData[0]['WhatsappNumber']
            : '',
          Email: reponseData[0]['Email'] ? reponseData[0]['Email'] : '',
          State: reponseData[0]['State'] ? reponseData[0]['State'] : '',
          City: reponseData[0]['City'] ? reponseData[0]['City'] : '',
          balanceAmount: reponseData[0]['balanceAmount']
            ? reponseData[0]['balanceAmount']
            : '',
          SalesPerson: reponseData[0]['SalesPerson']?.['id']
            ? reponseData[0]['SalesPerson']?.['id']
            : '',
          City: reponseData[0]['district'] ? reponseData[0]['district'] : '',
          remarksText:
            reponseData[0]['Remarks'] !== null ? reponseData[0]['Remarks'] : '',
          isDelivered: reponseData[0]['isDelivered'],
          pending: reponseData[0]['Pending']
        })
        if (reponseData?.[0]?.['sampleContactLink']?.['id']) {
          setSamplelinkedCostomerId(
            reponseData?.[0]?.['sampleContactLink']?.['id']
          )
          setMobile({
            value: reponseData?.[0]?.['sampleContactLink']?.['id'],
            label: reponseData[0]['Mobile']
          })
          setIsNewContact(false)
        } else {
          setIsNewContact(true)
        }
        // Costomer Name
        initialValue.CosName = reponseData[0]['CosName']
        setCostomerName(reponseData[0]['CosName'])
        // Mobile Number
        initialValue.Mobile = reponseData[0]['Mobile']
        setMobileNumber(reponseData[0]['Mobile'])
        // whatapp Number
        initialValue['WhatsappNumber'] = reponseData[0]['WhatsappNumber']
          ? reponseData[0]['WhatsappNumber']
          : ''
        if (
          reponseData[0]['WhatsappNumber'] === null ||
          reponseData[0]['WhatsappNumber'] === undefined ||
          reponseData[0]['WhatsappNumber'] !== ''
        ) {
          setWhatsAppNumber(reponseData[0]['WhatsappNumber'])
        } else {
          setWhatsAppNumber('')
        }
        // Email
        if (
          reponseData[0]['Email'] !== '' &&
          reponseData[0]['Email'] !== null
        ) {
          initialValue['Email'] = reponseData[0]['Email']
          setEmail(reponseData[0]['Email'])
        } else {
          initialValue['Email'] = ''
          setEmail('')
        }
        // state

        if (reponseData?.[0]?.['pincode']) {
          setPincode({
            value: reponseData?.[0]?.['pincode']?.['id'],
            label: reponseData?.[0]?.['pincode']?.['pincode']
          })
        }

        setCity(reponseData[0]['district'])
        setFormKey(prevKey => prevKey + 1)
      } else if (reponseData[0]['posType'] === 'Sales') {
        setIitialdata({
          marketingEvent: initialValue.marketingEvent
            ? initialValue.marketingEvent
            : '',
          Store: initialValue['Store'] ? initialValue['Store'] : '',
          CosName: reponseData[0]['CosName'],
          BillingAddress: reponseData[0]['BillingAddress']?.['id'],
          DeliverAddress: reponseData[0]['DeliverAddress']?.id
            ? reponseData[0]['DeliverAddress']?.id
            : '',
          contactPerson: reponseData[0]['contactPerson']?.id
            ? reponseData[0]['contactPerson']?.id
            : '',
          // State: reponseData[0]['State'] ? reponseData[0]['State'] : '',
          balanceAmount: reponseData[0]['balanceAmount'],
          SalesPerson: reponseData[0]['SalesPerson']?.['id']
            ? reponseData[0]['SalesPerson']?.['id']
            : '',
          remarksText:
            reponseData[0]['Remarks'] !== null ? reponseData[0]['Remarks'] : '',
          isDelivered: reponseData[0]['isDelivered'],
          pending: reponseData[0]['Pending']
        })

        // Costomer Name
        initialValue.CosName = reponseData[0]['CosName']
        setCostomerName(reponseData[0]['CosName'])
        initialValue['CostomerName'] = reponseData[0]['customerName']?.['id']
        if (initialValue['CostomerName'] !== '') {
          getAddress(initialValue['CostomerName'])
          setSelectCostomerName({
            value: reponseData[0]['customerName']?.['id'],
            label: reponseData[0]['customerName']?.['companyName']
          })
          setSelectLegalName(reponseData[0]['customerName']?.['legalName'])
        }

        initialValue['BillingAddress'] =
          reponseData[0]['BillingAddress']?.['id']
        if (initialValue['BillingAddress'] !== '') {
          let selectBillingAddresEdit = reponseData[0]['BillingAddress']
          let AddressLable = `${selectBillingAddresEdit?.addressLine1}, ${selectBillingAddresEdit?.addressLine2},
                ${selectBillingAddresEdit?.city} ,${selectBillingAddresEdit?.state} , ${selectBillingAddresEdit?.country}`
          handleSelectBillingAddress({
            value: selectBillingAddresEdit?.id,
            label: AddressLable,
            State: selectBillingAddresEdit?.state
          })
        }
        initialValue['DeliverAddress'] = reponseData[0]['DeliverAddress']?.id

        if (
          initialValue['DeliverAddress'] !== '' &&
          initialValue['DeliverAddress'] !== undefined
        ) {
          let selectDeliverAddresEdit = reponseData[0]['DeliverAddress']
          let DeliverAddressLable = `${selectDeliverAddresEdit?.addressLine1}, ${selectDeliverAddresEdit?.addressLine2},
                ${selectDeliverAddresEdit?.city}, ${selectDeliverAddresEdit?.country}`
          handleSelectDeliverAddress({
            value: selectDeliverAddresEdit.id,
            label: DeliverAddressLable
          })
        }
        initialValue['contactPerson'] = reponseData[0]['contactPerson']?.id
        if (initialValue['contactPerson'] !== '') {
          setContactPerson({
            value: reponseData[0]['contactPerson']?.id,
            label: reponseData[0]['contactPerson']?.contactPersonName
          })
        }
      }
      if (reponseData[0]['OverallDiscountPercentage'] > 0) {
        setCheckDiscount(true)
        setDiscountPercentage(reponseData[0]['OverallDiscountPercentage'])
        setDiscountPercentageInValue(
          reponseData[0]['OverallDiscountPercentageValue']
        )
      } else if (reponseData[0]['OverallDiscountValue'] > 0) {
        setCheckDiscount(true)

        if (
          Number(reponseData[0]['OverallDiscountValue']) <
          Number(reponseData[0]['FinalTotalValue'])
        ) {
          setDiscountValue(reponseData[0]['OverallDiscountValue'])
        }
      } else if (Number(reponseData[0]['DiscountFinalTotal'] > 0)) {
        setCheckDiscount(true)
        setDiscountTotalValue(reponseData[0]['DiscountFinalTotal'])
      } else {
        setIsAllStockReduced(false)
        setdiscountError('')
        setCheckDiscount(false)
        setDiscountPercentage('')
        setDiscountValue('')
        setDiscountTotalValue('')
      }

      setFormKey(prevKey => prevKey + 1)
      setInitialProcess(false)
    } else {
      console.error('Error fetching data:', response.error)
    }
    console.log(initialValue)
  }
  useEffect(() => {
    if (posEdit) {
      fetchEditData(posEdit)
    } else if (posEdit === null) {
      resetpos()
    } else {
      resetpos()
    }
  }, [posEdit, isLoad])

  const [toPay, setToPay] = useState('0.00')

  const ApplyDiscountToItemCombo = (which, value) => {
    let discount_percentage = 0
    let discount_value = 0
    let final_value = 0
    let is_clear = false
    if (which === 'percentage') {
      discount_percentage = value
    } else if (which === 'value') {
      discount_value = value
    } else if (which === 'total') {
      final_value = value
    } else {
      is_clear = true
    }
    posItemData.map(singleitem => {
      let item_combo = singleitem['itemCombo']?.map(item => item.id)
      if (singleitem['posItemComboBool'] && item_combo.length < 1) {
        toast.error('Please save combo items to apply/clear discounts!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: 'light'
          // transition: Bounce,
        })
      } else if (item_combo && item_combo.length > 0) {
        let fetch_query = FetchPosSalesOrderItemCombo(item_combo)
        axiosInstance
          .post('/itemmaster/graphql', { query: fetch_query })
          .then(res => {
            let res_data = res?.data?.data?.salesOrderItemCombo?.items
            if (res_data && res_data.length > 0) {
              let updated_data = res_data.map((item, index) => {
                const serialNumbers = item?.['serial']?.map(
                  item => item.serialNumber
                )
                const joinedSerialNumbers = serialNumbers
                  ? serialNumbers.join(', ')
                  : ''
                let new_item = {
                  id: item?.id,
                  index: index + 1,
                  partCode: item?.partCode?.itemPartCode,
                  partName: item?.partCode?.itemName,
                  Description: item?.partCode?.description,
                  qty: Number(item['qty']),
                  rate: item?.rate,
                  uom: item?.uom?.id,
                  Amount: item?.amount,
                  store: selectedStore?.value,
                  BatchNo: item?.batch?.batchNumberName,
                  Serialnum: joinedSerialNumbers,
                  getRate: item?.gstRate,
                  createdby: '',
                  partNumber: item['partCode'],
                  display: item.display,
                  serialNumber: item?.['serial'],
                  batchNumber: {
                    label: item?.['batch']?.['batchNumberName'],
                    value: item?.['batch']?.['id']
                  }
                }
                return new_item
              })
              applyDiscountOverall(
                updated_data,
                singleitem.qty,
                TotalWithTaxValue,
                userId,
                discount_percentage,
                discount_value,
                final_value,
                is_clear
              )
            }
          })
      }
    })
  }

  // retun null; depends on value call discount function
  function callDiscountfunction () {
    let fun_name = null
    let value_d = 0
    if (discountPercentage) {
      fun_name = 'percentage'
      value_d = discountPercentage
      if (Number(discountPercentage) > 0 && Number(discountPercentage) <= 100) {
        saveOverallDiscountPercentage(discountPercentage)
        setCheckDiscount(true)
        setdiscountError('')
      } else {
        setdiscountError('Value must be between 0 and 100')
      }
    } else if (discountValue) {
      fun_name = 'value'
      value_d = discountValue
      if (isSales) {
        if (Number(discountValue) <= Number(TotalWithTaxValue)) {
          callOverallDiscountValue(discountValue)

          setCheckDiscount(true)
          setdiscountError('')
        } else {
          setdiscountError('Value must be between less than Total pay')
        }
      } else {
        if (Number(discountValue) <= Number(TotalValue)) {
          callOverallDiscountValue(discountValue)
          setCheckDiscount(true)
          setdiscountError('')
        } else {
          setdiscountError('Value must be between less than Total pay')
        }
      }
    } else if (discountTotalValue) {
      fun_name = 'total'
      value_d = discountTotalValue
      if (isSales) {
        if (Number(discountTotalValue) <= Number(TotalWithTaxValue)) {
          callFinalTotalDiscount(discountTotalValue)
          setCheckDiscount(true)
          setdiscountError('')
        } else {
          setdiscountError('Value must be between less than Total pay')
        }
      } else {
        if (Number(discountTotalValue) <= Number(TotalValue)) {
          callFinalTotalDiscount(discountTotalValue)
          setCheckDiscount(true)
          setdiscountError('')
        } else {
          setdiscountError('Value must be between less than Total pay')
        }
      }
    } else {
      fun_name = null
      value_d = 0
      clearAllDiscount()
    }
    ApplyDiscountToItemCombo(fun_name, value_d)
  }

  // retun null ; Save with discount
  // async function SaveWithDisCount (variable, isDup = false) {
  //   try {
  //     const respones = await axiosInstance.post(`/itemmaster/graphql`, {
  //       query: createPosOrderItem(variable)
  //     })
  //     const result = respones['data']['data']['salesOrderCreateMutation']
  //     if (result.success) {
  //       let responseData = Array.isArray(result.SalesoderInstance)
  //         ? result.SalesoderInstance
  //         : [result.SalesoderInstance]
  //       if (isDup){
  //         setPosItemData(prevData => [ ...prevData, responseData[0]])
  //       } else{
  //         setPosItemData(prevData =>{
  //           return prevData.map(item =>{
  //             return item.id === responseData[0].id ? responseData[0] : item
  //           })
  //         })
  //       }

  //     } else {
  //       let listOfError = ShowErrorNoties(result.errors)
  //       toast.error(listOfError, {
  //         position: 'top-right',
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: 'light',
  //         transition: Bounce
  //       })
  //     }
  //   } catch (error) {
  //     let listOfError = ShowErrorNoties(error)
  //       toast.error(listOfError, {
  //         position: 'top-right',
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: 'light',
  //         transition: Bounce
  //       })
  //   }
  // }

  // retun null ; Apply the discount percentage
  async function saveOverallDiscountPercentage (percentagevalue) {
    if (posItemData.length > 0) {
      let itemIds = posItemData.map(item => Number(item.id))
      try {
        const respones = await axiosInstance.post(`/itemmaster/graphql`, {
          query: salesOrderItemOverallDiscountPercentage(
            itemIds,
            percentagevalue
          )
        })
        const result =
          respones['data']['data']['salesOrderItemOverallDiscountPercentage'][
            'items'
          ]
        if (result) {
          setPosItemData(result)
          setdisCountModel(false)
        } else {
          toast.error('Clear DisCount and try again', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce
          })
        }
      } catch (error) {
        let listOfError = ShowErrorNoties(error)
        toast.error(listOfError, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      }
    }
  }

  // retun null ; Apply the discount Value
  async function callOverallDiscountValue (totalToSubtract) {
    if (posItemData.length > 0) {
      let itemIds = posItemData.map(item => Number(item.id))
      try {
        const respones = await axiosInstance.post(`/itemmaster/graphql`, {
          query: salesOrderItemoverallDiscountValue(itemIds, totalToSubtract)
        })
        const result =
          respones['data']['data']['salesOrderItemoverallDiscountValue'][
            'items'
          ]
        if (result) {
          setPosItemData(result)
          setdisCountModel(false)
        } else {
          toast.error('Clear DisCount and try again', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce
          })
        }
      } catch (error) {
        let listOfError = ShowErrorNoties(error)
        toast.error(listOfError, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      }
    }
  }

  // retun null ; Apply the set total discount
  async function callFinalTotalDiscount (finalTotal) {
    if (posItemData.length > 0) {
      let itemIds = posItemData.map(item => Number(item.id))
      let totalWithTax = TotalWithTaxValue ? TotalWithTaxValue : null
      try {
        const respones = await axiosInstance.post(`/itemmaster/graphql`, {
          query: salesOrderItemFinalTotalDiscount(
            itemIds,
            finalTotal,
            totalWithTax
          )
        })

        const result =
          respones?.['data']?.['data']?.['salesOrderItemFinalTotalDiscount']?.[
            'items'
          ]
        if (result) {
          setPosItemData(result)
          setdisCountModel(false)
        } else {
          toast.error('Clear DisCount and try again', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce
          })
        }
      } catch (error) {
        let listOfError = ShowErrorNoties(error)
        toast.error(listOfError, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      }
    }
  }

  // retun Null ; remove the Discount save the with out Discount Value
  async function clearAllDiscount (
    responseDataToChange = null,
    deletedData = null
  ) {
    if (posItemData.length > 0) {
      let itemIds = posItemData.map(item => Number(item.id))

      try {
        const respones = await axiosInstance.post(`/itemmaster/graphql`, {
          query: salesOrderItemClearDiscount(itemIds)
        })

        const result =
          respones?.['data']?.['data']?.['salesOrderItemClearDiscount']?.[
            'items'
          ]
        if (result) {
          setPosItemData(result)
          setdiscountError('')
          setCheckDiscount(false)
          setDiscountPercentage('')
          setDiscountValue('')
          setDiscountTotalValue('')
        } else {
          toast.error('Try again', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce
          })
        }
      } catch (error) {
        let listOfError = ShowErrorNoties(error)
        toast.error(listOfError, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      }
    }
    // ApplyDiscountToItemCombo(null, 0)
  }

  // retun Objects; splite the gst rate
  function togettax (savedScanerdara, gst_rate, statedata = null) {
    if (isSales) {
      // we face the state change problem so we use statedata
      if (statedata !== null) {
        if (statedata === 'Tamil Nadu') {
          savedScanerdara['sgst'] = Number(gst_rate / 2)
          savedScanerdara['cgst'] = Number(gst_rate / 2)
          savedScanerdara['igst'] = 0
        } else {
          savedScanerdara['igst'] = Number(gst_rate)
          savedScanerdara['sgst'] = 0
          savedScanerdara['cgst'] = 0
        }
      } else {
        if (stateForSales === 'Tamil Nadu') {
          savedScanerdara['sgst'] = Number(gst_rate / 2)
          savedScanerdara['cgst'] = Number(gst_rate / 2)
          savedScanerdara['igst'] = 0
        } else {
          savedScanerdara['igst'] = Number(gst_rate)
          savedScanerdara['sgst'] = 0
          savedScanerdara['cgst'] = 0
        }
      }
      return savedScanerdara
    } else {
      savedScanerdara['sgst'] = 0
      savedScanerdara['cgst'] = 0
      savedScanerdara['igst'] = 0
      return savedScanerdara
    }
  }

  // Retun Objcet; Save The Batch Number
  async function SaveTheValuesbatch (params, getGstRate) {
    /*
    check if  params id it is  already add we increase the qty , amount then add it 
    check else normally add the data 
    */
    let setEdit = false
    let setError = ''

    params['discountValueForPerItem'] = '0'
    params['discountPercentage'] = '0'
    params['finalValue'] = '0'
    params['discountValue'] = '0'
    // params
    const saveData = togettax(params, getGstRate)
    if (addedItems[params['partCode']]) {
      let findedDuplicate = addedItems[params['partCode']].find(
        checkDupicate =>
          Number(checkDupicate.batchNumber) === Number(saveData['batch'])
      )
      if (saveData['id'] === '') {
        if (findedDuplicate) {
          let editObject = posItemData.find(
            itemIds => Number(itemIds.id) === Number(findedDuplicate.id)
          )
          if (editObject) {
            params['id'] = Number(editObject['id'])
            params['qty'] = Number(editObject['qty']) + Number(1)
            params['amount'] =
              Number(editObject['rate']) * Number(params['qty'])
            setEdit = true
          } else {
            setError = 'Duplicate Obj No Find In Table'
          }
        }
      } else {
        setEdit = true
      }
    }
    params['description'] = String(params['description']).replace(/"/g, '\\"')
    let variable = removeEmptyValueInObject(saveData)
    if (!setError) {
      try {
        const respones = await axiosInstance.post(`/itemmaster/graphql`, {
          query: createPosOrderItem(variable)
        })
        const result = respones['data']['data']['salesOrderCreateMutation']
        if (result.success) {
          setisDataSave(true)
          let responseData = Array.isArray(result.SalesoderInstance)
            ? result.SalesoderInstance
            : [result.SalesoderInstance]
          if (responseData?.[0]?.['posItemComboBool']) {
            setCurrentEditItemComboId(params?.['partCode'])

            handleShowItemComboModal()
          }
          setCurrentEditItemComboData(responseData[0])
          if (posItemData.length > 0) {
            if (setEdit) {
              setPosItemData(prevData => {
                return prevData.map(item => {
                  return item.id === responseData[0].id ? responseData[0] : item
                })
              })
            } else {
              setPosItemData(prevData => [...responseData, ...prevData])
            }
          } else {
            setPosItemData(responseData)
          }

          setAddedItems(prevValue => {
            // Check if the key already exists and is an array, then append to it
            const existingEntries =
              prevValue[responseData[0]['partCode']['id']] || []
            return {
              ...prevValue,
              [responseData[0]['partCode']['id']]: [
                ...existingEntries,
                {
                  id: responseData[0]['id'],
                  batchNumber: responseData[0]['batch']['id']
                }
              ]
            }
          })
          console.log(!isLoading)
          toast.success('Saved', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          })
          if (
            Number(discountPercentageInValue) > 0 ||
            Number(discountValue) > 0 ||
            Number(discountTotalValue) > 0
          ) {
            clearAllDiscount({ responceData: responseData[0] })
          }
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    } else {
      toast.error(setError, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce
      })
      return false
    }
  }

  // Retun Object; Save Serial Number;
  async function SaveSerialNumber (params, getGstRate) {
    let setError = ''
    let setEdit = false
    let SavedData = ''
    if (posItemData.length > 0) {
      for (let key in posItemData) {
        let singlData = posItemData[key]
        if (
          Number(singlData['partCode']['id']) === Number(params['partCode'])
        ) {
          setEdit = true
          if (params['id'] === '') {
            let dupSerialNumber = singlData['serial'].filter(
              serial_number_item =>
                params['serial'].includes(Number(serial_number_item['id']))
            )
            if (dupSerialNumber.length === 0) {
              params['qty'] = Number(singlData['qty']) + Number(params['qty'])
              let serialList = []
              singlData['serial'].map(serialItem => {
                serialList.push(serialItem.id)
              })
              params['serial'].map(serialItem => {
                serialList.push(serialItem)
              })
              params['amount'] = Number(params['rate']) * Number(params['qty'])
              params['serial'] = serialList
              params['id'] = singlData['id']
              params['discountValueForPerItem'] = '0'
              params['discountPercentage'] = '0'
              params['finalValue'] = '0'
              params['discountValue'] = '0'
              SavedData = params
            } else {
              toast.error('Serial number is already exiating', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce
              })
            }
          } else {
            SavedData = params
          }
        } else {
          SavedData = params
        }
      }
    } else {
      SavedData = params
    }
    const saveData = togettax(SavedData, getGstRate)
    let variable = removeEmptyValueInObject(saveData)
    if (!setError && SavedData !== '') {
      try {
        const respones = await axiosInstance.post(`/itemmaster/graphql`, {
          query: createPosOrderItem(variable)
        })
        const result = respones['data']['data']['salesOrderCreateMutation']
        if (result.success) {
          setisDataSave(true)
          let responseData = Array.isArray(result.SalesoderInstance)
            ? result.SalesoderInstance
            : [result.SalesoderInstance]
          if (responseData?.[0]?.['posItemComboBool']) {
            setCurrentEditItemComboId(params?.['partCode'])

            handleShowItemComboModal()
          }
          setCurrentEditItemComboData(responseData[0])
          if (posItemData.length > 0) {
            if (setEdit) {
              setPosItemData(prevData => {
                return prevData.map(item => {
                  return item.id === responseData[0].id ? responseData[0] : item
                })
              })
            } else {
              setPosItemData(prevData => [...responseData, ...prevData])
            }
          } else {
            setPosItemData(responseData)
          }

          toast.success('Saved', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          })
          if (
            Number(discountPercentageInValue) > 0 ||
            Number(discountValue) > 0 ||
            Number(discountTotalValue) > 0
          ) {
            clearAllDiscount()
          }
          return true
        } else {
          let listOfError = ShowErrorNoties(result.errors)
          toast.error(listOfError, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce
          })
          return false
        }
      } catch (error) {
        let listOfError = ShowErrorNoties(error)
        toast.error(listOfError, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
        return false
      }
    } else {
      toast.error(setError, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce
      })
      return false
    }
  }
  // Retun Object; Save None Batch And Serial;
  async function SaveNoneBs (params, getGstRate) {
    let setError = ''
    let setEdit = false
    params['discountValueForPerItem'] = '0'
    params['discountPercentage'] = '0'
    params['finalValue'] = '0'
    params['discountValue'] = '0'
    let dupObject = posItemData.find(
      itemIds => Number(itemIds?.partCode?.id) === Number(params.partCode)
    )
    if (dupObject) {
      params['id'] = Number(dupObject['id'])
      if (params['qty'] === 1 && params['id'] === '') {
        if (params['qty'] === 1 && params['id'] === '') {
          params['qty'] = Number(dupObject['qty']) + Number(1)
        }
      }
      params['amount'] = Number(dupObject['rate']) * Number(params['qty'])

      setEdit = true
    } else {
      params['amount'] = Number(params['rate']) * Number(params['qty'])
    }
    params['description'] = String(params['description']).replace(/"/g, '\\"')
    const saveData = togettax(params, getGstRate)
    let emptyRemovedData = removeEmptyValueInObject(saveData)
    if (!setError) {
      try {
        const respones = await axiosInstance.post(`/itemmaster/graphql`, {
          query: createPosOrderItem(emptyRemovedData)
        })
        const result = respones['data']['data']['salesOrderCreateMutation']
        if (result.success) {
          setisDataSave(true)
          let responseData = Array.isArray(result.SalesoderInstance)
            ? result.SalesoderInstance
            : [result.SalesoderInstance]
          if (responseData?.[0]?.['posItemComboBool']) {
            setCurrentEditItemComboId(params?.['partCode'])

            handleShowItemComboModal()
          }
          setCurrentEditItemComboData(responseData[0])

          if (posItemData.length > 0) {
            if (setEdit) {
              setPosItemData(prevData => {
                return prevData.map(item => {
                  return item.id === responseData[0].id ? responseData[0] : item
                })
              })
            } else {
              setPosItemData(prevData => [...responseData, ...prevData])
            }
          } else {
            setPosItemData(responseData)
          }
          toast.success('Saved', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          })
          if (
            Number(discountPercentageInValue) > 0 ||
            Number(discountValue) > 0 ||
            Number(discountTotalValue) > 0
          ) {
            clearAllDiscount({ responceData: responseData[0] })
          }
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    } else {
      toast.error(setError, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce
      })
      return false
    }
  }

  let error = ''
  //retun Null; get scanner input data
  async function datacall () {
    /*
     * get partcode and serial or Batch
     * the check partcode, serial or Batch match in Store
     * if match depence on serial or Batch send the data to Save functions
     */
    if (scannerValue) {
      let partcode = scannerValue.split('|')[0]
      partcode = partcode.replace(/"/g, '\\"')
      let serialorBatch = scannerValue.split('|')[1]
      const savedScanerdata = {
        id: '',
        partCode: '',
        description: '',
        qty: 1,
        rate: '',
        uom: '',
        posItemComboBool: false,
        amount: '',
        batch: '',
        serial: [],
        modifiedBy: '',
        createdBy: ''
      }
      let batchOrSerial = ''
      let getGstRate = ''
      try {
        let response = await OnchangeGetData(
          QueryData.itemMasterDataForPosFetchQuery({
            key: 'itemPartCodeEquals',
            value: partcode
          })
        )
        let responceData = response.data.itemMaster.items[0]
        setScannerValue('')
        savedScanerdata['partCode'] = responceData['id']
          ? Number(responceData['id'])
          : ''
        savedScanerdata['description'] = responceData['description']

        savedScanerdata['rate'] = Number(responceData['itemMrp'])
        savedScanerdata['uom'] = responceData['itemUom']?.['id']
          ? Number(responceData['itemUom']['id'])
          : ''
        savedScanerdata['posItemComboBool'] = responceData['itemComboBool']
        savedScanerdata['amount'] = 1 * Number(responceData['itemMrp'])
        savedScanerdata['createdBy'] = Number(userId)
        if (responceData['itemHsn']) {
          getGstRate = responceData['itemHsn']?.['gstRates']?.['rate']
          savedScanerdata['gstRate'] = Number(getGstRate)
        } else {
          error = 'Pls Check The GST Rate'
        }
        if (responceData.batchNumber) {
          batchOrSerial = 'batch'
        } else if (responceData.serial) {
          batchOrSerial = 'Serial'
        } else {
          batchOrSerial = ''
        }
      } catch (errors) {
        error = 'Not Found'
      }
      if (batchOrSerial === 'batch') {
        if (serialorBatch !== '' && serialorBatch !== undefined) {
          let response = await OnchangeGetData(
            QueryData.getBatchOrSerial(
              savedScanerdata['partCode'],
              selectedStore['value'],
              serialorBatch
            )
          )
          if (response.success) {
            let responseData =
              response['data']['itemStock']['items']['0']?.['batchNumber']?.[
                'id'
              ]
            if (responseData) {
              savedScanerdata['batch'] = Number(responseData)
            } else {
              error = 'Batch Number Not found '
            }
          } else {
            error = 'Batch Number Not found '
            let errorData = ShowErrorNoties(response.errors)
            toast.error(errorData, {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              transition: Bounce
            })
          }
        } else {
          error = 'Enter With Batch Number'
        }
      } else if (batchOrSerial === 'Serial') {
        if (serialorBatch !== '' && serialorBatch !== undefined) {
          let response = await OnchangeGetData(
            QueryData.getBatchOrSerial(
              savedScanerdata['partCode'],
              selectedStore['value']
            )
          )
          if (response.success) {
            let responseData =
              response['data']['itemStock']?.['items']?.['0']?.['serialNumber']
            if (responseData) {
              let responceSerial = responseData.filter(
                serialItem => serialItem.serialNumber === serialorBatch
              )

              if (responceSerial?.[0]?.['id']) {
                savedScanerdata['serial'] = [
                  Number(responceSerial?.[0]?.['id'])
                ]
              } else {
                error = 'Serial Number Not found '
              }
            } else {
              error = 'Serial Number Not found '
            }
          } else {
            error = 'Serial Number Not found '
            let errorData = ShowErrorNoties(response.errors)
            toast.error(errorData, {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              transition: Bounce
            })
          }
        } else {
          error = 'Enter With Serial Number'
        }
      }
      if (!error) {
        if (batchOrSerial === 'batch') {
          SaveTheValuesbatch(savedScanerdata, getGstRate)
        } else if (batchOrSerial === 'Serial') {
          SaveSerialNumber(savedScanerdata, getGstRate)
        } else {
          SaveNoneBs(savedScanerdata, getGstRate)
          // toast.error('We still Working For this Functions', {
          //   position: 'top-right',
          //   autoClose: 2000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: 'light',
          //   transition: Bounce
          // })
        }
      } else {
        toast.error(error, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      }
    }
  }

  // OnChange pos type check with tax or with out tax
  useEffect(() => {
    if (
      !initialProcess &&
      ItemDetaileData.length > 0 &&
      isSales &&
      (posEditData === null || posEditData?.length > 0)
    ) {
      // onChangPosTypeChangeTaxForSales()
    } else if (
      !initialProcess &&
      ItemDetaileData.length > 0 &&
      !isSales &&
      (posEditData || posEditData?.length > 0)
    ) {
      console.log('--->>')

      onChangPosTypeChangeTaxForSample()
    }
  }, [isSales])

  // on Change Pos Type we need to change tax only For Sales
  async function onChangPosTypeChangeTaxForSales (
    state = '',
    clearDiscount = true
  ) {
    setLoadingModels(true)
    if (posItemData.length > 0) {
      // Get the selected rows
      let bulkUpdate = []
      const selectedRows = posItemData
      for (let i = 0; i < selectedRows.length; i++) {
        let newData = selectedRows[i]
        const savedata = {
          id: Number(newData['id']),
          partCode: Number(newData['partCode']['id']),
          description: newData['description'],
          qty: newData['qty'] ? Number(newData['qty']) : 1,
          rate: newData['rate'] ? Number(newData['rate']) : 0,
          gstRate: newData['gstRate'] ? Number(newData['gstRate']) : '',
          uom: newData['uom']?.['id'] ? Number(newData['uom']?.['id']) : '',
          batch: newData['batch']?.['id']
            ? Number(newData['batch']?.['id'])
            : null,
          createdBy: newData['createdBy']
            ? Number(newData['createdBy']['id'])
            : '',
          igst: '',
          sgst: '',
          cgst: ''
        }
        savedata['amount'] = newData.amount

        let statedata
        if (state !== '') {
          statedata = state
        }
        if (clearDiscount) {
          savedata['amount'] = (newData.rate * newData['qty']).toFixed(2)
          setCheckDiscount(false)
          setDiscountPercentage('')
          setDiscountValue('')
          setDiscountTotalValue('')
          savedata['discountValueForPerItem'] = '0'
          savedata['discountPercentage'] = '0'
          savedata['finalValue'] = '0'
          savedata['discountValue'] = '0'
        }

        // savedata['description'] = String(savedata['description']).replace(/"/g, '\\"');
        const SaveData_ = togettax(savedata, savedata['gstRate'], statedata)
        bulkUpdate.push(SaveData_)
        // if (clearDiscount){
        //   setCheckDiscount(false)
        //   setDiscountPercentage('')
        //   setDiscountValue('')
        //   setDiscountTotalValue('')
        // }
      }
      try {
        const response = await axiosInstance.post('/itemmaster/graphql', {
          query: salesOrderBulkUpdatetionsMutation(),
          variables: { salesOrders: bulkUpdate }
        })
        const result =
          response?.['data']?.['data']?.['salesOrderBulkCreateMutation']
        if (result?.success) {
          setPosItemData(result?.['updatedItems'])
        } else {
          toast.error('Try again', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce
          })
        }
      } catch (error) {
        let listOfError = ShowErrorNoties(error)
        toast.error(listOfError, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      }
    }
    setLoadingModels(false)
  }

  // on Change Pos Type we need to change tax only For Sample
  async function onChangPosTypeChangeTaxForSample () {
    setLoadingModels(true)
    if (posItemData.length > 0) {
      // Get the selected rows
      const selectedRows = posItemData
      let bulkUpdate = []
      for (let i = 0; i < selectedRows.length; i++) {
        let newData = selectedRows[i]
        const savedata = {
          id: newData['id'] ? Number(newData['id']) : null, // Handle null values
          partCode: newData['partCode']
            ? Number(newData['partCode']['id'])
            : null,
          description: newData['description'] || '', // Default to empty string if not provided
          qty: newData['qty'] ? Number(newData['qty']) : 1,
          rate: newData['rate'] ? Number(newData['rate']) : 0,
          gstRate: newData['gstRate'] ? Number(newData['gstRate']) : 0,
          uom: newData['uom']?.['id'] ? Number(newData['uom']['id']) : null, // Use null for missing IDs
          batch: newData['batch']?.['id']
            ? Number(newData['batch']['id'])
            : null,
          discountValueForPerItem: newData['discountValueForPerItem']
            ? Number(newData['discountValueForPerItem'])
            : 0,
          discountPercentage: newData['discountPercentage']
            ? Number(newData['discountPercentage'])
            : 0,
          finalValue: newData['finalValue'] ? Number(newData['finalValue']) : 0,
          discountValue: newData['discountValue']
            ? Number(newData['discountValue'])
            : 0,
          createdBy: newData['createdBy']
            ? Number(newData['createdBy']['id'])
            : null,
          igst: newData['igst'] ? Number(newData['igst']) : 0,
          sgst: newData['sgst'] ? Number(newData['sgst']) : 0,
          cgst: newData['cgst'] ? Number(newData['cgst']) : 0
        }
        savedata['amount'] = (savedata['rate'] * savedata['qty']).toFixed(2)
        const SaveData_ = togettax(savedata, savedata['gstRate'])
        SaveData_['description'] = String(SaveData_['description']).replace(
          /"/g,
          '"'
        )
        bulkUpdate.push(SaveData_)
      }
      try {
        const response = await axiosInstance.post('/itemmaster/graphql', {
          query: salesOrderBulkUpdatetionsMutation(),
          variables: { salesOrders: bulkUpdate }
        })
        const result =
          response?.['data']?.['data']?.['salesOrderBulkCreateMutation']
        if (result?.success) {
          setPosItemData(result?.['updatedItems'])
          setCheckDiscount(false)
          setDiscountPercentage('')
          setDiscountValue('')
          setDiscountTotalValue('')
        } else {
          toast.error('Try again', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce
          })
        }
      } catch (error) {
        let listOfError = ShowErrorNoties(error)
        toast.error(listOfError, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      }
    }
    setLoadingModels(false)
  }

  // pos table columns
  const ratecellrender = params => {
    let discount_percentage =
      params.data?.discountPercentage !== undefined
        ? params.data.discountPercentage
        : 0
    let discount_value =
      params.data?.discountValue !== undefined ? params.data.discountValue : 0
    let final_value =
      params.data.finalValue !== undefined ? params.data.finalValue : 0
    if (params.data['stockReduce']) {
      setIsSomeStockReduced(true)
    }
    return (
      <>
        {params.data.rate}
        {Number(discount_percentage) !== 0
          ? -params.data.discountPercentage +
            '  %  = ' +
            Number(params.data.discountValueForPerItem).toFixed(2)
          : Number(discount_value) !== 0
          ? '-' +
            Number(params.data.discountValue).toFixed(2) +
            ' = ' +
            Number(params.data.discountValueForPerItem).toFixed(2)
          : Number(final_value) !== 0
          ? ' - ' +
            Number(params.data.discountValueForPerItem).toFixed(2) +
            ' = ' +
            Number(params.data.finalValue).toFixed(2)
          : ''}
      </>
    )
  }

  const SerialNumber = params => {
    let serialList = []
    params?.data?.serial?.map((item, index) => {
      serialList.push(item.serialNumber)
    })
    return (
      <>
        <div>{serialList.join(', ')}</div>
      </>
    )
  }

  const descriptionUpdate = params => {
    return (
      <>
        <div
          style={{
            color: params.data['stockReduce'] ? 'green' : 'black',
            fontWeight: params.data['stockReduce'] ? 'bold' : ''
          }}
        >
          {params.data.description}
        </div>
      </>
    )
  }

  const Taxcellrender = params => {
    return (
      <>
        {params.data.sgst > 0 ? `SGST ${params.data.sgst}% |` : ''}{' '}
        {params.data.cgst > 0 ? `CGST ${params.data.cgst}%` : ''}{' '}
        {params.data.igst > 0 ? `IGST ${params.data.igst}%` : ''}
      </>
    )
  }

  const [deleteData, setdeleteData] = useState()
  const [url, setUrl] = useState('')
  const [isShowStockStatementModal, setShowStockStatementModal] =
    useState(false)
  const [selectedPartNo, setSelectedPartNo] = useState(null)
  const [selectedStockStatementDetails, setSelectedStockStatementDetails] = useState([])

  const handleCloseStockStatementModal = () => {
    setShowStockStatementModal(false)
  }
  const handleShowStockStatementModal = data => {
    let store_data = localStorage.getItem('SelectedPosStore')
    axiosInstance
      .post('/itemmaster/graphql', {
        query: QueryData.stockIdFetchQuery(data['partCode']['id'], store_data)
      })
      .then(res => {
        let stock_ids = []
        stock_ids = res?.data?.data?.stockIds?.items
        let construct_stock_dict = {
          stockId: [],
          itemPartCode: data?.partCode?.itemPartCode,
          itemName: data?.partCode?.itemName,
          description: data?.description,
          batchNumber: '',
          serial: ''
        }
        if (stock_ids.length > 0) {
          construct_stock_dict['partCode'] =
            stock_ids[0]?.partNumber?.['itemPartCode']
          construct_stock_dict['partName'] =
            stock_ids[0]?.partNumber?.['itemName']
          construct_stock_dict['description'] =
            stock_ids[0]?.partNumber?.['description']
          construct_stock_dict['isBatch'] =
            stock_ids[0]?.partNumber?.['batchNumber']
          construct_stock_dict['isSerial'] =
            stock_ids[0]?.partNumber?.['serial']
        }
        construct_stock_dict['stockId'] = stock_ids?.map(item => item.id)
        setSelectedStockStatementDetails(construct_stock_dict)
        setShowStockStatementModal(true)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })
    setSelectedPartNo(data.partCode.id)
  }

  const deleteButton = ({ value, data }) => {
    // Assign the value to the higher-scoped itemId
    const handleClick = () => {
      setPosItemEdit(data)
      handlepositemShow()
    }
    const handledeleteConfomation = () => {
      setdeleteData({ Name: data.partCode.itemPartCode, id: data.id })
      setUrl('/itemmaster/SalesOrderItem')
      handleShowDeleteConfirmationModal()
    }

    const showStock = () => {
      handleShowStockStatementModal(data)
    }

    return (
      <>
        <button
          type='button'
          className='btn btn-outline-success btn-sm px-3 mx-2'
          onClick={handleClick}
          disabled={
            data['stockReduce'] ? true : data['isCanceled'] ? true : false
          }
        >
          <i className='fa-solid fa-pen'></i>
        </button>
        <button
          type='button'
          className='btn btn-outline-danger btn-sm px-3 mx-2'
          onMouseDown={handledeleteConfomation}
          disabled={
            data['stockReduce'] ? true : data['isCanceled'] ? true : false
          }
        >
          <i className='fa-solid fa-trash '></i>
        </button>
        <AiOutlineShoppingCart
          style={{ cursor: 'pointer' }}
          className='fs-3'
          onClick={() => {
            showStock()
          }}
        />
        <span
          onClick={() => {
            setCurrentEditItemComboId(data?.partCode?.id)
            setCurrentEditItemComboData(data)
            handleShowItemComboModal()
          }}
        >
          <i
            style={{ cursor: 'pointer' }}
            className='fa-brands fa-creative-commons-share fs-4 ms-2'
          ></i>
        </span>
      </>
    )
  }

  // delete pos
  const deletePos = () => {
    if (status === 'Canceled') {
      setdeleteData({ Name: posCustomId, id: posEdit })
      setUrl('/itemmaster/SalesOrder')
      handleShowDeleteConfirmationModal()
    }
  }

  //retun null; DisCount Model
  const Discount = () => {
    return (
      <>
        <Modal
          show={disCountModel}
          backdrop='static'
          onHide={handleCloseDisCountModel}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5>Discount</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row mx-1 '>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12 text-center'>
                    <p className='text-danger'>{discountError}</p>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-4 staan-input-group'>
                    <input
                      type='number'
                      value={discountPercentage}
                      onChange={e => {
                        handlediscountpercentage(e.target.value)
                      }}
                      disabled={
                        discountValue
                          ? true
                          : discountTotalValue
                          ? true
                          : false || checkDiscount
                      }
                      className='staan-form-input text-end pe-3'
                    />
                    <label
                      htmlFor='OverallDiscountPercentage'
                      className='staan-form-input-label pt-1 px-1 mx-2 fw-bold'
                    >
                      Overall Discount Percentage %
                    </label>
                  </div>
                  <div className='col-4 staan-input-group'>
                    <input
                      type='number'
                      value={discountValue}
                      disabled={
                        discountPercentage
                          ? true
                          : discountTotalValue
                          ? true
                          : false || checkDiscount
                      }
                      onChange={e => {
                        handlediscountValue(e.target.value)
                      }}
                      className='staan-form-input text-end pe-3'
                    />
                    <label
                      htmlFor='OverallDiscountValue'
                      className='staan-form-input-label pt-1 px-1 mx-2 fw-bold'
                    >
                      Overall Discount Value
                    </label>
                  </div>
                  <div className='col-4 staan-input-group'>
                    <input
                      type='number'
                      value={discountTotalValue}
                      disabled={
                        discountPercentage
                          ? true
                          : discountValue
                          ? true
                          : false || checkDiscount
                      }
                      onChange={e => {
                        handleDiscountTotalValue(e.target.value)
                      }}
                      className='staan-form-input text-end pe-3'
                    />
                    <label
                      htmlFor='FinalTotal'
                      className='staan-form-input-label pt-1 px-1 mx-2 fw-bold'
                    >
                      Final Total
                    </label>
                  </div>
                </div>
                <div className='d-flex justify-content-center align-items-center mb-2'>
                  <div>
                    <button
                      type='button'
                      className='btn btn-sm btn-outline-danger mt-3 me-2 px-5'
                      onClick={() => {
                        clearAllDiscount()
                      }}
                    >
                      Clear
                    </button>
                    <button
                      type='button'
                      className='btn btn-sm btn-outline-success mt-3 ms-3'
                      disabled={checkDiscount}
                      onClick={() => {
                        callDiscountfunction()
                      }}
                    >
                      Apply Discount
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  const handleShowTaxModel = () => {
    setTaxDetailsModel(true)
  }

  const handleCloseModel = () => {
    setTaxDetailsModel(false)
  }

  // Tax Model
  const TaxModel = () => {
    return (
      <>
        <Modal show={TaxDetailsModel} onHide={handleCloseModel}>
          <Modal.Header closeButton>
            <Modal.Title>Tax</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row mx-1 '>
              <div className=''>
                {igstTax
                  ? Object.entries(igstTax).map(([key, value]) => (
                      <div className='row'>
                        <div key={key} className='col-5'>
                          {`Igst @ ${key}%`}
                        </div>
                        <div className='col-5  '>{` : ₹  ${value}`}</div>
                      </div>
                    ))
                  : ''}
              </div>
              <div className=''>
                {sgstTax
                  ? Object.entries(sgstTax).map(([key, value]) => (
                      <div className='row  '>
                        <div key={key} className='col-5'>
                          {`sgst @ ${key}%`}
                        </div>
                        <div className='col-5 '>{` : ₹  ${value}`}</div>
                      </div>
                    ))
                  : ''}
              </div>
              <div className=''>
                {cgstTax
                  ? Object.entries(cgstTax).map(([key, value]) => (
                      <div className='row'>
                        <div key={key} className='col-5'>
                          {`cgst @ ${key}%`}
                        </div>
                        <div className='col-5 '>{` : ₹  ${value}`}</div>
                      </div>
                    ))
                  : ''}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    )
  }

  // State selectedStore
  // function handleDisabled () {
  //   if (isSales) {
  //     if (selectedStore.value && stateForSales) {
  //       setAddNewState(true)
  //     } else {
  //       setAddNewState(false)
  //     }
  //   } else {
  //     if (selectedStore?.value && State?.value) {
  //       setAddNewState(true)
  //     } else {
  //       setAddNewState(false)
  //     }
  //   }
  // }

  const [isDataLoading, setDataLoading] = useState(true)

  const columns = [
    {
      headerName: 'Part Code - Name',
      field: 'description',
      flex: 2,
      // minWidth: 300,
      cellRenderer: descriptionUpdate,
      onCellDoubleClicked: onCellDoubleClicked
      // cellStyle: {   'font-weight': 'bold' },
    },
    {
      headerName: 'Qty',
      field: 'qty',
      flex: 1,
      cellStyle: { textAlign: 'center' }
    },
    {
      headerName: 'Batch',
      field: 'batch.batchNumberName',
      flex: 1,
      onCellDoubleClicked: onCellDoubleClicked
      // cellStyle: {     fontWeight: 'bold' }
    },
    {
      headerName: 'Serial',
      field: 'serial',
      cellRenderer: SerialNumber,
      onCellDoubleClicked: onCellDoubleClicked,
      flex: 1
      // cellStyle: {   fontWeight: 'bold' },
    },
    {
      headerName: 'Rate',
      field: 'rate',
      cellRenderer: ratecellrender,
      onCellDoubleClicked: onCellDoubleClicked,
      flex: 1.8,
      Width: 50
      // cellStyle: { textAlign: 'right',  fontWeight: 'bold' },
    },
    {
      headerName: 'Amount',
      field: 'amount',
      flex: 1,
      onCellDoubleClicked: onCellDoubleClicked,
      cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Tax',
      cellRenderer: Taxcellrender,
      flex: 1,
      onCellDoubleClicked: onCellDoubleClicked,
      hide: !isSales
    },
    {
      headerName: 'Action',
      cellRenderer: deleteButton,
      flex: 3
    }
  ]

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    floatingFilter: true,
    resizable: true
  }

  const components = {
    deleteButton: deleteButton
  }

  // make values is empty
  function resetpos () {
    setSamplelinkedCostomerId()
    setPosEdit()
    setDiscountPercentage()
    setCheckDiscount(false)
    setDiscountValue()
    setDiscountTotalValue()
    setTotalWithTaxValue()
    setTotalValue(0.0)
    setItemDetailsData([])
    setPosEditData([])
    setState()
    setSalesPerson({ value: '', label: '' })
    setSelectCostomerName({ value: '', label: '' })
    setSelectBillingAddress({ value: '', label: '' })
    setSelectDeliverAddress({ value: '', label: '' })
    setCostomerName('')
    setMobileNumber('')
    setWhatsAppNumber('')
    setEmail('')
    setPosItemData([])
    setPaymentTransIds([])
    setPaymentAdd(true)
    setCity('')
    setCurrentBalance(0.0)
    setPending(false)
    setSupplierSelect({ value: '', label: '' })
  }

  // retun bool ; check item combo
  async function checkItemCombo (params) {
    let unsavedList = []
    for (let itemDataindex in posItemData) {
      if (posItemData[itemDataindex]['posItemComboBool']) {
        let itemCombo = posItemData[itemDataindex]['itemCombo']
        let data = `query myquery{
              itemMaster(id: ${posItemData?.[itemDataindex]?.['partCode']?.['id']}){
                items{
                  itemComboData{
                    id
                  } 
                 
                }
                
              }
            }`
        try {
          const respones = await axiosInstance.post('/itemmaster/graphql', {
            query: data
          })

          if (
            itemCombo.length ===
            respones?.data?.data?.itemMaster?.items?.[0]['itemComboData']
              ?.length
          ) {
          } else {
            let itemData = {
              part: posItemData?.[itemDataindex]?.['partCode']?.['itemPartCode']
            }
            unsavedList.push(itemData)
          }
        } catch (err) {
          console.log(err)
        }
      }
    }

    return unsavedList
  }

  async function savePos (SavedValue, setSubmitting) {
    let allow_null = [
      'Remarks',
      'DeliverAddress',
      'SalesPerson',
      'contactPerson',
      'modifiedBy',
      'Currency',
      'id',
      'customerName',
      'BillingAddress',
      'sampleContactLink',
      'pincode'
    ]
    let allow_mute_line = ['Remarks']
    let variable = removeEmptyValueInObject(
      SavedValue,
      allow_null,
      allow_mute_line
    )
    let blance = currentBalance
    if (Number(blance) !== Number(0.0) && pending === true) {
      errorOnSubmit = ''
    } else if (Number(blance) === Number(0.0)) {
      errorOnSubmit = ''
    } else if (blance < 0.0) {
      errorOnSubmit = 'Check the Balance'
    } else {
      errorOnSubmit = 'Please Close The Payment Or Enable The Pending'
    }
    if (Number(TotalValue <= 0)) {
      errorOnSubmit = 'Total Amount should not be 0.00'
    }
    if (!posTypeChangeError) {
      if (errorOnSubmit === '') {
        try {
          const respones = await axiosInstance.post('/itemmaster/graphql', {
            query: createPOSMode(variable)
          })
          const responseData = respones.data.data.salesOrderPosCreateMutation
          if (responseData.success) {
            setPosEdit(responseData.salesOrderPosInstance?.id)
            setIsLoad(prev => !prev)
            setSubmitting(false)
            setIsNewContact(false)
            setSamplelinkedCostomerId('')
            toast.success('Saved Successfully', {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              transition: Bounce
            })
          } else {
            setSubmitting(false)
            showErrorToast(true, 'error', `${responseData?.errors}`, '')
          }
        } catch (error) {
          let filiterError = ShowErrorNoties(error)
          setSubmitting(false)
          toast.error(filiterError, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce
          })
        }
      } else {
        setSubmitting(false)
        toast.error(errorOnSubmit, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      }
    } else {
      setSubmitting(false)
      toast.error('Sample should not contain Bank/Swipe payment!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce
      })
    }
  }

  let errorOnSubmit = ''
  // save pos
  const handleSubmit = (values, { resetForm, setErrors, setSubmitting }) => {
    const itemids = posItemData.map(item => item.id)

    let SavedValue = {
      posType: isSales ? 'Sales' : 'Sample',
      OrderDate: orderDate ? orderDate : getCurrentDate(),
      Mobile: values['Mobile'] ? values['Mobile'] : '',
      WhatsappNumber: values['WhatsappNumber'] ? values['WhatsappNumber'] : '',
      CosName: costomerName ? costomerName : '',
      Email: values['Email'] ? values['Email'] : '',
      district: city ? city : '',
      State: State ? State : '',
      pincode: pincode?.value ? Number(pincode?.value) : '',
      store: selectedStore.value ? Number(selectedStore.value) : '',
      sampleContactLink: samplelinkedCostomerId
        ? Number(samplelinkedCostomerId)
        : '',
      customerName: values['CostomerName']
        ? Number(values['CostomerName'])
        : '',
      BillingAddress: values['BillingAddress']
        ? Number(values['BillingAddress'])
        : '',
      DeliverAddress: values['DeliverAddress']
        ? Number(values['DeliverAddress'])
        : '',
      SalesPerson: values['SalesPerson'] ? Number(values['SalesPerson']) : '',
      OverallDiscountPercentage: discountPercentage
        ? discountPercentage
        : Number(0),
      OverallDiscountPercentageValue: discountPercentageInValue
        ? discountPercentageInValue
        : 0,
      OverallDiscountValue: discountValue ? discountValue : Number(0),
      DiscountFinalTotal: discountTotalValue ? discountTotalValue : Number(0),
      TotalAmount: TotalValue ? TotalValue : '',
      balanceAmount: currentBalance ? currentBalance : '',
      receivedAmount: receivedAmount ? receivedAmount : 0,
      igst: Object.keys(igstTax).length > 0 ? igstTax : {},
      sgst: Object.keys(sgstTax).length > 0 ? sgstTax : {},
      cgst: Object.keys(cgstTax).length > 0 ? cgstTax : {},
      isDelivered: isDelivered,
      status: status,
      Pending: pending,
      AllStockReduced: isAllStockReduced,
      FinalTotalValue: toPay ? toPay : '',
      Remarks: remarksText,
      marketingEvent: values['marketingEvent']
        ? Number(values['marketingEvent'])
        : '',
      Currency: values['Currency'] ? Number(values['Currency']) : '',
      itemDetails: itemids,
      payment: paymentTransIds,
      contactPerson: contactPerson.value ? Number(contactPerson.value) : '',
      createdby: posEdit ? Number(initialValue['createdBy']) : Number(userId),
      modifiedBy: posEdit ? Number(userId) : '',
      id: posEdit ? Number(posEdit) : ''
    }
    if (status === null) {
      SavedValue['status'] = 'Save'
      checkItemCombo()
        .then(res => {
          if (res.length > 0) {
            setitemComboUnSavedList(res)
            setshowComboUnSaved(true)
            return
          } else {
            savePos(SavedValue, setSubmitting)
          }
        })
        .catch(err => {
          errorOnSubmit = 'check the item combo'
          setSubmitting(false)
        })
    } else {
      savePos(SavedValue, setSubmitting)
    }
  }

  const handlePrint = data => {
    const orderDates = new Date(orderDate)
    const formattedDate =
      orderDates.getDate().toString().padStart(2, '0') +
      '/' +
      (orderDates.getMonth() + 1).toString().padStart(2, '0') +
      '/' +
      orderDates.getFullYear()
    let print_data = {
      label_name: 'pos_label_new',
      data: {
        BillNo: posCustomId,
        Date: formattedDate,
        Name: costomerName,
        Mobile: mobileNumber,
        items: ItemDetaileData.map((item, index) => ({
          sno: index + 1,
          Name: item.description,
          qty: item.qty,
          rate: item.rate,
          Amount: Number(item.qty) * Number(item.rate)
          // item.amount
        })),
        TotalItem: totalBillingItem,
        TotalAmount: totalBillAmount,
        discount: Number(discountPercentageInValue)
          ? discountPercentageInValue
          : Number(discountValue)
          ? Number(discountValue).toFixed(2)
          : Number(discountTotalValue)
          ? isSales
            ? Number(totalDiscountValueForSales).toFixed(2)
            : Number(totalBillAmount - discountTotalValue).toFixed(2)
          : '0.00',
        BillingAddress: '',
        Taxkey: '',
        TaxAmount: '',
        TotalAfterDiscount: '',
        Total: toPay,
        TaxInvoice: '',
        Gst: 'ESTIMATE'
      }
    }
    if (totalTax > 0) {
      print_data['data']['Mobile'] = mobileNumber
      print_data['data']['BillingAddress'] = selectBillingAddress['label']
      print_data['data']['contactperson'] = contactPerson['label']
      print_data['data']['CostomerGst'] = costomerGet
      print_data['data']['TaxInvoice'] = 'Tax Invoice'
      print_data['data']['Taxkey'] = 'Total Tax'
      print_data['data']['TaxAmount'] = totalTax
      print_data['data']['Gst'] = '33AAICS8026R1ZQ'
      print_data['data']['Name'] = selectLegalName
    }

    if (data['posType'] === 'Sales') {
      print_data['data']['TotalAfterDiscount'] = data['FinalTotalValue']
    } else {
      print_data['data']['TotalAfterDiscount'] = discountPercentage
        ? roundToNearest10(totalBillAmount - discountPercentageInValue).toFixed(
            2
          )
        : discountValue
        ? roundToNearest10(totalBillAmount - discountValue).toFixed(2)
        : discountTotalValue
        ? discountTotalValue
        : totalBillAmount
    }
    const newSocket = io('http://localhost:5000', {
      reconnectionAttempts: 1
    }) 
    if (newSocket) {
      newSocket.emit('print-data', print_data)
      newSocket.emit('message', 'Hello from React!')
    }
  }

  const handlePaymentTransactionIds = data_list => {
    setPaymentTransIds(data_list)
    setPaymentAdd(false)
  }

  const handleShowDeleteConfirmationModal = () => {
    setShowDeleteConfirmationModal(true)
  }

  const handleCloseDeleteConfirmationModal = () => {
    setShowDeleteConfirmationModal(false)
  }

  const handleDeletedData = status => {
    if (status) {
      let deletedData = []
      if (url.includes('SalesOrderItem')) {
        const remove_delete_data = posItemData.filter(data => {
          if (data.id !== deleteData.id) {
            return data
          } else {
            deletedData.push(data)
          }
        })
        setPosItemData([])
        delete addedItems[deletedData[0]['partCode']['id']]
        clearAllDiscount(null, deleteData?.id)
        setIsLoading(!isLoading)
      } else {
        const remove_delete_data = salesOrderData.filter(
          data => data.id !== deleteData.id
        )
        for (let singleitem in ItemDetaileData) {
          axiosInstance
            .delete(
              `/itemmaster/SalesOrderItem/${ItemDetaileData[singleitem]['id']}`
            )
            .then(data => {})
            .catch(err => {
              let filterError = ShowErrorNoties(err)
              toast.error(filterError, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce
              })
            })
        }
        Navigate('/posdata')
        setSalesOrderData(remove_delete_data)
      }
    }
  }

  const handlePosTypeChangeError = error_msg => {
    setPosTypeChangeError(error_msg)
  }

  // check initial stock availability; returns bool value
  useEffect(() => {
    if ((status !== null || status === 'Save') && ItemDetaileData.length > 0) {
      CheckStock()
    }
  }, [status, isLoading])

  // retun bool ; check the stock
  async function CheckStock () {
    try {
      let peremeter = `posID: "${posEdit}",`
      const respones = await axiosInstance.post('/itemmaster/graphql', {
        query: checkStockPOSQuery(peremeter)
      })
      const responseData = respones.data.data.checkStock
      if (responseData?.success) {
        setCheckStockAvailability(true)
      } else {
        setCheckStockAvailability(false)
      }
    } catch (error) {
      setCheckStockAvailability(false)
      let filiterError = ShowErrorNoties(error)
      showErrorToast(true, 'error', `${filiterError}`, '')
    }
  }

  // retun null ; reduces the stock
  async function ReducesStock () {
    // showErrorToast(true, "warn", `We Working For Update Try Again later`, "")
    // for (let index = 0; index < ItemDetaileData.length; index++) {
    //   const singleitem = ItemDetaileData[index];
    //   // reduce item combo stock
    //   let item_combo = singleitem['itemCombo']?.map(item => item.id)
    //   let isItemCombo = singleitem?.["posItemComboBool"]
    //   // if (isItemCombo && item_combo && item_combo.length > 0){
    //   //   let fetch_query = FetchPosSalesOrderItemCombo(item_combo);
    //   //   axiosInstance.post('/itemmaster/graphql', {query: fetch_query})
    //   //   .then(res => {
    //   //       let res_data_list = res?.data?.data?.salesOrderItemCombo?.items;
    //   //       res_data_list.forEach(res_data => {
    //   //           let ic_id = res_data['id']
    //   //           let ic_PartCode = res_data['partCode']?.['id'];
    //   //           let ic_batch = res_data?.['batch']?.['id'];
    //   //           let ic_Store = selectedStore?.value;
    //   //           let ic_Qty = res_data?.['qty'];
    //   //           let ic_serial = res_data?.['serial']?.map((serialId)=> serialId.id)
    //   //           let stock_history_data = {
    //   //             "action": "DELETE",
    //   //             "storeLink": selectedStore?.value ? Number(selectedStore.value):"" ,
    //   //             "partNumber": ic_PartCode? Number(ic_PartCode):"",
    //   //             "previousState": 0,
    //   //             "added": 0,
    //   //             "reduced": Number(ic_Qty),
    //   //             "updatedState": 0,
    //   //             "conference": selectMarketingEvent?.value ?  Number(selectMarketingEvent.value) : ""
    //   //           }

    //   //           if (ic_batch ){
    //   //               let stock_fetch_query = QueryData.StockDataFetch(1, 200,
    //   //                 { partNumber: ic_PartCode,
    //   //                   store: ic_Store,
    //   //                   batchNumber: ic_batch
    //   //                 },
    //   //               [])
    //   //               axiosInstance.post("/itemmaster/graphql", {query: stock_fetch_query})
    //   //               .then(res => {
    //   //                   let tocheckBatch = res?.data?.data?.itemStock?.items?.[0];
    //   //                   let updatedStock  = Number(tocheckBatch?.['currentStock']) - Number(ic_Qty)
    //   //                   if (Number(tocheckBatch['currentStock']) >= 0 && !singleitem['stockReduce']){
    //   //                     stock_history_data['previousState'] = tocheckBatch['currentStock']
    //   //                     stock_history_data['updatedState'] =  updatedStock
    //   //                     try{
    //   //                       let item_stock_mutate =  `mutation itemstockmutation {
    //   //                         itemStockCreateMutation(id:${tocheckBatch['id']}, currentStock: ${updatedStock}){
    //   //                           success
    //   //                           errors
    //   //                         }
    //   //                       }`
    //   //                       axiosInstance.post('/itemmaster/graphql', { query: item_stock_mutate })
    //   //                       .then(stock_res => {
    //   //                         stock_history_data['stockLink'] = tocheckBatch?.['id']? Number(tocheckBatch['id']) : ""
    //   //                         let stock_update_response = stock_res?.data?.data?.itemStockCreateMutation
    //   //                         if (stock_update_response.success) {
    //   //                           try {
    //   //                             let inventory_approval_mutation_query = `mutation InventoryApprovalCreateQuery{
    //   //                               inventoryApprovalCreateMutation(partNumber: ${ic_PartCode},
    //   //                               batchNumber: ${ic_batch}, qty: "${ic_Qty}", store: ${ic_Store}){
    //   //                                 inventoryApprovalItem {
    //   //                                   id
    //   //                                 }
    //   //                                 success
    //   //                                 errors
    //   //                               }
    //   //                             }`
    //   //                             axiosInstance.post(`/itemmaster/graphql`, { query: inventory_approval_mutation_query })
    //   //                             .then(res => {
    //   //                               let created_inventory_id = res?.data?.data?.inventoryApprovalCreateMutation
    //   //                               if (created_inventory_id?.success) {
    //   //                                 // update the item is redused
    //   //                                 let SaveValue = {
    //   //                                   id: Number(singleitem['id']),
    //   //                                   stockReduce: true
    //   //                                 }
    //   //                                 let cleanValue = removeEmptyValueInObject(SaveValue)
    //   //                                 // update the item is history
    //   //                                 stock_history_data['transactionModule'] = "SalesOrder"
    //   //                                 stock_history_data["displayName"] = "POS"
    //   //                                 stock_history_data['savedBy'] = Number(userId)
    //   //                                 stock_history_data['displayId'] = posCustomId
    //   //                                 stock_history_data['transactionId'] = Number(posEdit)
    //   //                                 let stock_history_mutation_query = CreateStockHistory(stock_history_data)
    //   //                                 axiosInstance.post("/itemmaster/graphql", {query: stock_history_mutation_query})
    //   //                                 .then(res => { }).catch(err => { console.log(err); })
    //   //                                 SaveWithDisCount(cleanValue).then(res => {console.log(res);}).catch(err => {})
    //   //                                 axiosInstance.post("/itemmaster/graphql", {query: `  mutation salesordersitemMutations{
    //   //                                   salesOrderItemComboCreateMutation(id :${ic_id}, stockReduce:true){
    //   //                                     SalesoderInstance{
    //   //                                       id
    //   //                                     }
    //   //                                     success
    //   //                                     errors
    //   //                                   }
    //   //                                 }`}).then(res=>{}).catch(err=>{console.log(err);})

    //   //                                 if (index === Number(ItemDetaileData.length) - 1) {
    //   //                                   allStockReduced(true)}
    //   //                               }
    //   //                             }).catch(err => {
    //   //                               console.log(err)
    //   //                             })
    //   //                           } catch (error) {
    //   //                             console.log(error)
    //   //                           }
    //   //                         }
    //   //                       })
    //   //                       .catch(err => {
    //   //                         console.log(err)
    //   //                       })
    //   //                     } catch (error) {
    //   //                       console.log(error)
    //   //                     }
    //   //                   } else {
    //   //                     toast.error('check the stock', {
    //   //                       position: 'top-right',
    //   //                       autoClose: 2000,
    //   //                       hideProgressBar: false,
    //   //                       closeOnClick: true,
    //   //                       pauseOnHover: true,
    //   //                       draggable: true,
    //   //                       progress: undefined,
    //   //                       theme: 'light',
    //   //                       transition: Bounce
    //   //                     })
    //   //                     setLoadingModels(false)
    //   //                     return ;
    //   //                   }
    //   //               })
    //   //           } else if(ic_serial.length>0){
    //   //             let stock_fetch_query = QueryData.StockDataFetch(1, 200, { partNumber: ic_PartCode, store: ic_Store}, [])
    //   //             axiosInstance.post("/itemmaster/graphql", {query: stock_fetch_query})
    //   //             .then(stock_data => {
    //   //               let tocheckSerial = stock_data?.data?.data?.itemStock?.items?.[0]
    //   //               let serialIds = tocheckSerial['serialNumber']?.map((serialId)=> serialId.id )
    //   //               let check_serial_exists = ic_serial?.map(serialItem => serialIds.includes(serialItem))
    //   //               if (check_serial_exists.some(item => item === false)) {
    //   //                 toast.error('check the stock', {
    //   //                   position: 'top-right',
    //   //                   autoClose: 2000,
    //   //                   hideProgressBar: false,
    //   //                   closeOnClick: true,
    //   //                   pauseOnHover: true,
    //   //                   draggable: true,
    //   //                   progress: undefined,
    //   //                   theme: 'light',
    //   //                   transition: Bounce
    //   //                 })
    //   //                 return ;
    //   //               } else {
    //   //                 let updatedStoct =  Number(tocheckSerial['currentStock']) - Number(ic_Qty);
    //   //                 stock_history_data['previousState']= tocheckSerial['currentStock']
    //   //                 stock_history_data['updatedState'] =  updatedStoct
    //   //                 if (Number(updatedStoct) >= 0 ){
    //   //                   let BalnceSerialIds = tocheckSerial['serialNumber'].filter(serialId =>
    //   //                     !ic_serial.some(serialItem => String(serialItem) === String(serialId.id))
    //   //                   );
    //   //                   BalnceSerialIds = BalnceSerialIds.map(item => Number(item.id))
    //   //                   tocheckSerial['serialNumber'] = BalnceSerialIds;
    //   //                   try{
    //   //                     let item_stock_mutate =  `mutation itemstockmutation {
    //   //                       itemStockCreateMutation(id:${tocheckSerial['id']}, currentStock: ${updatedStoct},
    //   //                       serialNumber: [${tocheckSerial['serialNumber']}]){
    //   //                         success
    //   //                         errors
    //   //                       }
    //   //                     }`
    //   //                     axiosInstance.post("/itemmaster/graphql", {query: item_stock_mutate})
    //   //                     .then( item_stock_res => {
    //   //                         let stock_update_response = item_stock_res?.data?.data?.itemStockCreateMutation
    //   //                         if(stock_update_response?.success){
    //   //                           stock_history_data['stockLink'] = tocheckSerial?.['id']? Number(tocheckSerial['id']): ""
    //   //                             try{
    //   //                               let inventory_approval_mutation_query = `mutation InventoryApprovalCreateQuery{
    //   //                                 inventoryApprovalCreateMutation(partNumber: ${ic_PartCode},
    //   //                                 serialNumber: [${BalnceSerialIds}], qty: "${ic_Qty}", store: ${ic_Store}){
    //   //                                   inventoryApprovalItem {
    //   //                                     id
    //   //                                   }
    //   //                                   success
    //   //                                   errors
    //   //                                 }
    //   //                               }`
    //   //                               axiosInstance.post(`/itemmaster/graphql`, {'query': inventory_approval_mutation_query})
    //   //                               .then(inventory_approval_response => {
    //   //                                 let created_inventory_id = inventory_approval_response?.data?.data?.inventoryApprovalCreateMutation
    //   //                                 if(created_inventory_id?.success){
    //   //                                   let SaveValue = { id: Number(singleitem['id']), stockReduce : true }
    //   //                                   let cleanValue = removeEmptyValueInObject(SaveValue)
    //   //                                   SaveWithDisCount(cleanValue).then(res => {}).catch(err => {})
    //   //                                   if(index === Number(ItemDetaileData.length) - 1){
    //   //                                     allStockReduced(true)
    //   //                                   }
    //   //                                   // update the item is history
    //   //                                   stock_history_data['transactionModule'] = "ItemInventoryApproval"
    //   //                                   stock_history_data["displayName"] = "POS"
    //   //                                   stock_history_data['savedBy'] = Number(userId)
    //   //                                   stock_history_data['displayId'] = posCustomId
    //   //                                   stock_history_data['transactionId'] = created_inventory_id?.inventoryApprovalItem?.id?
    //   //                                   Number(created_inventory_id.inventoryApprovalItem.id) :""
    //   //                                   let stock_history_mutation_query = CreateStockHistory(stock_history_data)
    //   //                                   axiosInstance.post("/itemmaster/graphql", {query: stock_history_mutation_query})
    //   //                                   .then(res => {}).catch(err => { console.log(err); })
    //   //                                   axiosInstance.post("/itemmaster/graphql", {query: `  mutation salesordersitemMutations{
    //   //                                     salesOrderItemComboCreateMutation(id :${ic_id}, stockReduce:true){
    //   //                                       SalesoderInstance{
    //   //                                         id
    //   //                                       }
    //   //                                       success
    //   //                                       errors
    //   //                                     }
    //   //                                   }`}).then(res=>{}).catch(err=>{console.log(err);})
    //   //                                 }
    //   //                               })
    //   //                               .catch(err => {
    //   //                                 console.log(err)
    //   //                               })
    //   //                             } catch(error){
    //   //                               console.log(error);
    //   //                           }
    //   //                         }
    //   //                     })
    //   //                     .catch(err => {
    //   //                       console.log(err)
    //   //                     })
    //   //                   } catch(error){
    //   //                     console.log(error);
    //   //                   }
    //   //                 } else{
    //   //                   toast.error('check the stock', {
    //   //                     position: 'top-right',
    //   //                     autoClose: 2000,
    //   //                     hideProgressBar: false,
    //   //                     closeOnClick: true,
    //   //                     pauseOnHover: true,
    //   //                     draggable: true,
    //   //                     progress: undefined,
    //   //                     theme: 'light',
    //   //                     transition: Bounce
    //   //                   })
    //   //                   setLoadingModels(false)
    //   //                   return ;
    //   //                 }
    //   //               }
    //   //             })
    //   //             .catch(err => {
    //   //               console.log(err)
    //   //             })
    //   //           }  else if(ic_batch === undefined && ic_serial?.length===0  ){
    //   //             let stock_fetch_query = QueryData.StockDataFetch(1, 200,
    //   //               { partNumber: ic_PartCode,
    //   //                 store: ic_Store,
    //   //               },
    //   //               [])
    //   //               axiosInstance.post("/itemmaster/graphql", {query: stock_fetch_query})
    //   //               .then(res => {
    //   //                   let tocheckBatch = res?.data?.data?.itemStock?.items?.[0];
    //   //                   let updatedStock  = Number(tocheckBatch?.['currentStock']) - Number(ic_Qty)
    //   //                   if (Number(tocheckBatch['currentStock']) >= 0 && !singleitem['stockReduce']){
    //   //                     stock_history_data['previousState'] = tocheckBatch['currentStock']
    //   //                     stock_history_data['updatedState'] =  updatedStock
    //   //                     try{
    //   //                       let item_stock_mutate =  `mutation itemstockmutation {
    //   //                         itemStockCreateMutation(id:${tocheckBatch['id']}, currentStock: ${updatedStock}){
    //   //                           success
    //   //                           errors
    //   //                         }
    //   //                       }`
    //   //                       axiosInstance.post('/itemmaster/graphql', {query: item_stock_mutate})
    //   //                       .then(res =>{
    //   //                         stock_history_data['stockLink'] = tocheckBatch?.['id']?
    //   //                         Number(tocheckBatch['id']): ""
    //   //                         let stock_update_response =
    //   //                         res?.data?.data?.itemStockCreateMutation
    //   //                         if (stock_update_response.success) {
    //   //                           try {
    //   //                             let inventory_approval_mutation_query = `mutation InventoryApprovalCreateQuery{
    //   //                               inventoryApprovalCreateMutation(partNumber: ${ic_PartCode},
    //   //                                , qty: "${ic_Qty}", store: ${ic_Store}){
    //   //                                 inventoryApprovalItem {
    //   //                                   id
    //   //                                 }
    //   //                                 success
    //   //                                 errors
    //   //                               }
    //   //                             }`
    //   //                             axiosInstance.post(
    //   //                               `/itemmaster/graphql`,
    //   //                               { query: inventory_approval_mutation_query }
    //   //                             ).then(inventory_approval_response => {
    //   //                                 let created_inventory_id =
    //   //                                 inventory_approval_response?.data?.data
    //   //                                   ?.inventoryApprovalCreateMutation
    //   //                               if (created_inventory_id?.success) {
    //   //                                 // update the item is redused
    //   //                                 let SaveValue = {
    //   //                                   id: Number(singleitem['id']),
    //   //                                   stockReduce: true
    //   //                                 }
    //   //                                 let cleanValue = removeEmptyValueInObject(SaveValue)
    //   //                                 // update the item is history
    //   //                                 stock_history_data['transactionModule'] = "SalesOrder"
    //   //                                 stock_history_data["displayName"] = "POS"
    //   //                                 stock_history_data['savedBy'] = Number(userId)
    //   //                                 stock_history_data['displayId'] = posCustomId
    //   //                                 stock_history_data['transactionId'] = Number(posEdit)
    //   //                                 let stock_history_mutation_query = CreateStockHistory(stock_history_data)
    //   //                                 axiosInstance.post("/itemmaster/graphql", {query: stock_history_mutation_query})
    //   //                                 .then(res => {
    //   //                                 }).catch(err => {console.log(err)})
    //   //                                 axiosInstance.post("/itemmaster/graphql", {query: `  mutation salesordersitemMutations{
    //   //                                   salesOrderItemComboCreateMutation(id :${ic_id}, stockReduce:true){
    //   //                                     SalesoderInstance{
    //   //                                       id
    //   //                                     }
    //   //                                     success
    //   //                                     errors
    //   //                                   }
    //   //                                 }`}).then(res=>{}).catch(err=>{console.log(err);})
    //   //                                 SaveWithDisCount(cleanValue).then(res => {
    //   //                                   if (index === Number(ItemDetaileData.length) - 1) {
    //   //                                     allStockReduced(true)
    //   //                                   }
    //   //                                 }).catch(err => {

    //   //                                 })

    //   //                               }
    //   //                             })
    //   //                             .catch(err => {

    //   //                             })

    //   //                           } catch (error) {
    //   //                             console.log(error)
    //   //                           }
    //   //                         }
    //   //                       })

    //   //                     } catch (error) {
    //   //                       console.log(error)
    //   //                     }
    //   //                 } else {
    //   //                     toast.error('check the stock', {
    //   //                       position: 'top-right',
    //   //                       autoClose: 2000,
    //   //                       hideProgressBar: false,
    //   //                       closeOnClick: true,
    //   //                       pauseOnHover: true,
    //   //                       draggable: true,
    //   //                       progress: undefined,
    //   //                       theme: 'light',
    //   //                       transition: Bounce
    //   //                     })
    //   //                     setLoadingModels(false)
    //   //                     return ;

    //   //                 }
    //   //               }).catch(err => {
    //   //                 console.log(err)
    //   //               })
    //   //           }
    //   //       })

    //   //   })
    //   //   .catch(err => {
    //   //       console.log(err);
    //   //   })
    //   // }

    //   // main item stock reduce

    //   if (!isItemCombo){

    //   }
    // }
    setLoadingModels(true)
    try {
      let peremeter = `posId: "${posEdit}", submitBy: ${userId}`
      const respones = await axiosInstance.post('/itemmaster/graphql', {
        query: SubmitPOSQuery(peremeter)
      })
      const responseData = respones.data.data.salesOrderSubmitMutation
      if (responseData?.success) {
        setstatus('Submited')
        setPosEdit(posEdit)
        showErrorToast(true, 'success', '', 'pos Submit Successfully')
      } else {
        showErrorToast(true, 'error', `${responseData?.errors}`, '')
      }
    } catch (error) {
      let filiterError = ShowErrorNoties(error)
      showErrorToast(true, 'error', `${filiterError}`, '')
    }
    setLoadingModels(false)
  }

  // retun mull , on cancel Add in stock in pos
  async function AddtionStock (params) {
    setLoadingModels(true)

    // for (let index = 0; index < ItemDetaileData.length; index++) {
    //   const singleitem = ItemDetaileData[index]
    //   // item combo
    //   let isItemCombo = singleitem?.["posItemComboBool"]
    //   let item_combo = singleitem['itemCombo']?.map(item => item.id)

    //   // if (isItemCombo && item_combo && item_combo.length > 0){
    //   //   let fetch_query = FetchPosSalesOrderItemCombo(item_combo);
    //   //   axiosInstance.post('/itemmaster/graphql', {query: fetch_query})
    //   //   .then(res => {
    //   //       let res_data_list = res?.data?.data?.salesOrderItemCombo?.items;
    //   //       res_data_list.forEach(res_data => {
    //   //           let ic_PartCode = res_data['partCode']?.['id'];
    //   //           let ic_batch = res_data?.['batch']?.['id'];
    //   //           let ic_Store = selectedStore?.value;
    //   //           let ic_Qty = res_data?.['qty'];
    //   //           let ic_serial = res_data?.['serial']?.map((serialId)=> serialId.id)
    //   //           let stock_history_data = {
    //   //             "action": "DELETE",
    //   //             "storeLink": selectedStore?.value ? Number(selectedStore.value):"" ,
    //   //             "partNumber": ic_PartCode? Number(ic_PartCode):"",
    //   //             "previousState": 0,
    //   //             "added": 0,
    //   //             "reduced": Number(ic_Qty),
    //   //             "updatedState": 0,
    //   //             "conference": selectMarketingEvent?.value ?  Number(selectMarketingEvent.value) : ""
    //   //           }
    //   //           if (ic_batch){
    //   //               let stock_fetch_query = QueryData.StockDataFetch(1, 200,
    //   //                 { partNumber: ic_PartCode,
    //   //                   store: ic_Store,
    //   //                   batchNumber: ic_batch
    //   //                 },
    //   //               [])

    //   //               axiosInstance.post("/itemmaster/graphql", {query: stock_fetch_query})
    //   //               .then(res => {
    //   //                   let tocheckBatch = res?.data?.data?.itemStock?.items?.[0];
    //   //                   let updatedStock = Number(tocheckBatch?.['currentStock']) + Number(ic_Qty)
    //   //                   if (Number(tocheckBatch['currentStock']) >= 0  ){
    //   //                     stock_history_data['previousState'] = tocheckBatch['currentStock']
    //   //                     stock_history_data['updatedState'] =  updatedStock
    //   //                     try{
    //   //                       let item_stock_mutate =  `mutation itemstockmutation {
    //   //                         itemStockCreateMutation(id:${tocheckBatch['id']}, currentStock: ${updatedStock}){
    //   //                           success
    //   //                           errors
    //   //                         }
    //   //                       }`
    //   //                       axiosInstance.post('/itemmaster/graphql', { query: item_stock_mutate })
    //   //                       .then(stock_res => {
    //   //                         stock_history_data['stockLink'] = tocheckBatch?.['id']? Number(tocheckBatch['id']) : ""
    //   //                         let stock_update_response = stock_res?.data?.data?.itemStockCreateMutation
    //   //                         if (stock_update_response.success){

    //   //                           try {
    //   //                             let inventory_approval_mutation_query = `mutation InventoryApprovalCreateQuery{
    //   //                               inventoryApprovalCreateMutation(partNumber: ${ic_PartCode},
    //   //                               batchNumber: ${ic_batch}, qty: "${ic_Qty}", store: ${ic_Store}){
    //   //                                 inventoryApprovalItem {
    //   //                                   id
    //   //                                 }
    //   //                                 success
    //   //                                 errors
    //   //                               }
    //   //                             }`
    //   //                             axiosInstance.post(`/itemmaster/graphql`, { query: inventory_approval_mutation_query })
    //   //                             .then(res => {
    //   //                               let created_inventory_id = res?.data?.data?.inventoryApprovalCreateMutation
    //   //                               if (created_inventory_id?.success) {
    //   //                                 // update the item is reduced
    //   //                                 let SaveValue = {
    //   //                                     id: Number(singleitem['id']),
    //   //                                     stockReduce: false,
    //   //                                     isCanceled: true
    //   //                                 }
    //   //                                 let cleanValue = removeEmptyValueInObject(SaveValue)
    //   //                                 // update the item is history
    //   //                                 stock_history_data['transactionModule'] = "SalesOrder"
    //   //                                 stock_history_data["displayName"] = "POS"
    //   //                                 stock_history_data['savedBy'] = Number(userId)
    //   //                                 stock_history_data['displayId'] = posCustomId
    //   //                                 stock_history_data['transactionId'] = Number(posEdit)
    //   //                                 let stock_history_mutation_query = CreateStockHistory(stock_history_data)
    //   //                                 axiosInstance.post("/itemmaster/graphql", {query: stock_history_mutation_query})
    //   //                                 .then(res => {

    //   //                                 }).catch(err => { console.log(err); })
    //   //                                 SaveWithDisCount(cleanValue).then(res => {}).catch(err => {})
    //   //                                 if (index === Number(ItemDetaileData.length) - 1) {
    //   //                                   allStockReduced(false, true)
    //   //                                 }
    //   //                               }
    //   //                             }).catch(err => {
    //   //                               console.log(err)
    //   //                             })
    //   //                           } catch (error) {
    //   //                             console.log(error)
    //   //                           }
    //   //                         }
    //   //                       })
    //   //                       .catch(err => {
    //   //                         console.log(err)
    //   //                       })
    //   //                     } catch (error) {
    //   //                       console.log(error)
    //   //                     }
    //   //                   } else {
    //   //                     console.log("-----conmo");
    //   //                     toast.error('check the stock', {
    //   //                       position: 'top-right',
    //   //                       autoClose: 2000,
    //   //                       hideProgressBar: false,
    //   //                       closeOnClick: true,
    //   //                       pauseOnHover: true,
    //   //                       draggable: true,
    //   //                       progress: undefined,
    //   //                       theme: 'light',
    //   //                       transition: Bounce
    //   //                     })
    //   //                     setLoadingModels(false)
    //   //                     return ;
    //   //                   }
    //   //               })
    //   //               .catch(err => {
    //   //                 console.log(err)
    //   //               })
    //   //           } else if(ic_serial.length>0){
    //   //             let stock_fetch_query = QueryData.StockDataFetch(1, 200, { partNumber: ic_PartCode, store: ic_Store}, [])
    //   //             axiosInstance.post("/itemmaster/graphql", {query: stock_fetch_query})
    //   //             .then(stock_data => {
    //   //               let tocheckSerial = stock_data?.data?.data?.itemStock?.items?.[0]
    //   //               let serialIds = tocheckSerial['serialNumber']?.map((serialId)=> serialId.id )
    //   //               let check_serial_exists = ic_serial?.map(serialItem => serialIds.includes(serialItem))
    //   //               if (check_serial_exists.some(item => item === false)) {
    //   //                 toast.error('check the stock', {
    //   //                   position: 'top-right',
    //   //                   autoClose: 2000,
    //   //                   hideProgressBar: false,
    //   //                   closeOnClick: true,
    //   //                   pauseOnHover: true,
    //   //                   draggable: true,
    //   //                   progress: undefined,
    //   //                   theme: 'light',
    //   //                   transition: Bounce
    //   //                 })
    //   //                 return ;
    //   //               } else {
    //   //                 let updatedStoct =  Number(tocheckSerial['currentStock']) - Number(ic_Qty);
    //   //                 stock_history_data['previousState'] = tocheckSerial['currentStock']
    //   //                 stock_history_data['updatedState'] =  updatedStoct
    //   //                 if (Number(updatedStoct) >= 0 ){
    //   //                   let BalnceSerialIds = tocheckSerial['serialNumber'].filter(serialId =>
    //   //                     !ic_serial.some(serialItem => String(serialItem) === String(serialId.id))
    //   //                   );
    //   //                   BalnceSerialIds = BalnceSerialIds.map(item => Number(item.id))
    //   //                   tocheckSerial['serialNumber'] = BalnceSerialIds;
    //   //                   try{
    //   //                     let item_stock_mutate =  `mutation itemstockmutation {
    //   //                       itemStockCreateMutation(id:${tocheckSerial['id']}, currentStock: ${updatedStoct},
    //   //                       serialNumber: [${tocheckSerial['serialNumber']}]){
    //   //                         success
    //   //                         errors
    //   //                       }
    //   //                     }`
    //   //                     axiosInstance.post("/itemmaster/graphql", {query: item_stock_mutate})
    //   //                     .then( item_stock_res => {
    //   //                         let stock_update_response = item_stock_res?.data?.data?.itemStockCreateMutation
    //   //                         if(stock_update_response?.success){
    //   //                           stock_history_data['stockLink'] = tocheckSerial?.['id']? Number(tocheckSerial['id']): ""
    //   //                             try{
    //   //                               let inventory_approval_mutation_query = `mutation InventoryApprovalCreateQuery{
    //   //                                 inventoryApprovalCreateMutation(partNumber: ${ic_PartCode},
    //   //                                 serialNumber: [${BalnceSerialIds}], qty: "${ic_Qty}", store: ${ic_Store}){
    //   //                                   inventoryApprovalItem {
    //   //                                     id
    //   //                                   }
    //   //                                   success
    //   //                                   errors
    //   //                                 }
    //   //                               }`
    //   //                               axiosInstance.post(`/itemmaster/graphql`, {'query': inventory_approval_mutation_query})
    //   //                               .then(inventory_approval_response => {
    //   //                                 let created_inventory_id = inventory_approval_response?.data?.data?.inventoryApprovalCreateMutation
    //   //                                 if(created_inventory_id?.success){
    //   //                                   let SaveValue = { id: Number(singleitem['id']),  stockReduce: false,
    //   //                                   isCanceled: true }
    //   //                                   let cleanValue = removeEmptyValueInObject(SaveValue)
    //   //                                   SaveWithDisCount(cleanValue).then(res => {}).catch(err => {})
    //   //                                   if(index === Number(ItemDetaileData.length) - 1){
    //   //                                     allStockReduced(false, true)
    //   //                                   }
    //   //                                   // update the item is history
    //   //                                   stock_history_data['transactionModule'] = "ItemInventoryApproval"
    //   //                                   stock_history_data["displayName"] = "POS"
    //   //                                   stock_history_data['savedBy'] = Number(userId)
    //   //                                   stock_history_data['displayId'] = posCustomId
    //   //                                   stock_history_data['transactionId'] = created_inventory_id?.inventoryApprovalItem?.id?
    //   //                                   Number(created_inventory_id.inventoryApprovalItem.id) :""
    //   //                                   let stock_history_mutation_query = CreateStockHistory(stock_history_data)
    //   //                                   axiosInstance.post("/itemmaster/graphql", {query: stock_history_mutation_query})
    //   //                                   .then(res => {}).catch(err => { console.log(err); })
    //   //                                 }
    //   //                               })
    //   //                               .catch(err => {
    //   //                                 console.log(err)
    //   //                               })
    //   //                             } catch(error){
    //   //                               console.log(error);
    //   //                             }
    //   //                         }
    //   //                     })
    //   //                     .catch(err => {
    //   //                       console.log(err)
    //   //                     })
    //   //                   } catch(error){
    //   //                     console.log(error);
    //   //                   }
    //   //                 } else{
    //   //                   toast.error('check the stock', {
    //   //                     position: 'top-right',
    //   //                     autoClose: 2000,
    //   //                     hideProgressBar: false,
    //   //                     closeOnClick: true,
    //   //                     pauseOnHover: true,
    //   //                     draggable: true,
    //   //                     progress: undefined,
    //   //                     theme: 'light',
    //   //                     transition: Bounce
    //   //                   })
    //   //                   setLoadingModels(false)
    //   //                   return ;
    //   //                 }
    //   //               }
    //   //             })
    //   //             .catch(err => {
    //   //               console.log(err)
    //   //             })
    //   //           } else if(batch === undefined && serial?.length===0){
    //   //             let stock_fetch_query = QueryData.StockDataFetch(1, 200, { partNumber: ic_PartCode, store: ic_Store}, [])
    //   //             axiosInstance.post('/itemmaster/graphql', {query: stock_fetch_query })
    //   //             .then(stock_data =>{
    //   //                 let tocheckNoBS = stock_data?.data?.data?.itemStock?.items?.[0]
    //   //                 tocheckNoBS['currentStock'] = Number(tocheckNoBS?.['currentStock']) + Number(Qty)
    //   //                 if (Number(tocheckNoBS['currentStock']) >= 0) {
    //   //                   try {
    //   //                     let item_stock_mutate = `mutation itemstockmutation {
    //   //                       itemStockCreateMutation(id:${tocheckNoBS['id']}, currentStock: ${tocheckNoBS['currentStock']}){
    //   //                         success
    //   //                         errors
    //   //                       }
    //   //                     }`
    //   //                     axiosInstance.post('/itemmaster/graphql', {query: item_stock_mutate})
    //   //                     .then(response =>{
    //   //                       let stock_update_response =
    //   //                       response?.data?.data?.itemStockCreateMutation
    //   //                     if (stock_update_response.success) {
    //   //                       try {
    //   //                         let inventory_approval_mutation_query = `mutation InventoryApprovalCreateQuery{
    //   //                           inventoryApprovalCreateMutation(partNumber: ${PartCode},
    //   //                               qty: "${Qty}", store: ${Store}){
    //   //                             inventoryApprovalItem {
    //   //                               id
    //   //                             }
    //   //                             success
    //   //                             errors
    //   //                           }
    //   //                         }`
    //   //                         axiosInstance.post(
    //   //                           `/itemmaster/graphql`,
    //   //                           { query: inventory_approval_mutation_query }
    //   //                         ).then(inventory_approval_response =>{
    //   //                           let created_inventory_id = inventory_approval_response?.data?.data?.inventoryApprovalCreateMutation
    //   //                           if (created_inventory_id?.success) {
    //   //                             let SaveValue = {
    //   //                               id: Number(singleitem['id']),
    //   //                               stockReduce: false,
    //   //                               isCanceled: true
    //   //                             }
    //   //                             let cleanValue = removeEmptyValueInObject(SaveValue)
    //   //                             SaveWithDisCount(cleanValue)
    //   //                             if (index === Number(ItemDetaileData.length) - 1) {
    //   //                               allStockReduced(false)
    //   //                             }
    //   //                           }
    //   //                         })

    //   //                       } catch (error) {
    //   //                         let listOfError = ShowErrorNoties(error)
    //   //                         toast.error(listOfError, {
    //   //                         position: 'top-right',
    //   //                         autoClose: 2000,
    //   //                         hideProgressBar: false,
    //   //                         closeOnClick: true,
    //   //                         pauseOnHover: true,
    //   //                         draggable: true,
    //   //                         progress: undefined,
    //   //                         theme: 'light',
    //   //                         transition: Bounce
    //   //                        })
    //   //                       }
    //   //                     }
    //   //                     })

    //   //                   } catch (error) {
    //   //                     let listOfError = ShowErrorNoties(error)
    //   //                     toast.error(listOfError, {
    //   //                     position: 'top-right',
    //   //                     autoClose: 2000,
    //   //                     hideProgressBar: false,
    //   //                     closeOnClick: true,
    //   //                     pauseOnHover: true,
    //   //                     draggable: true,
    //   //                     progress: undefined,
    //   //                     theme: 'light',
    //   //                     transition: Bounce
    //   //                     })
    //   //                   }
    //   //                 } else {
    //   //                   toast.error('check the stock', {
    //   //                     position: 'top-right',
    //   //                     autoClose: 2000,
    //   //                     hideProgressBar: false,
    //   //                     closeOnClick: true,
    //   //                     pauseOnHover: true,
    //   //                     draggable: true,
    //   //                     progress: undefined,
    //   //                     theme: 'light',
    //   //                     transition: Bounce
    //   //                   })
    //   //                 }
    //   //             }).catch(err =>{
    //   //               console.log(err);
    //   //             })

    //   //           }
    //   //       })

    //   //   })
    //   //   .catch(err => {
    //   //       console.log(err);
    //   //   })
    //   // }

    //   // main item
    //   let PartCode = singleitem['partCode']?.['id']
    //   let batch = singleitem['batch']?.['id']
    //   let Store = selectedStore.value
    //   let Qty = singleitem['qty']
    //   let serial = singleitem['serial']?.map(serialId => serialId.id)

    //   if (!isItemCombo){
    //     if (batch) {
    //       let stock_fetch_query = QueryData.StockDataFetch(1, 200, { partNumber: PartCode, store: Store, batchNumber: batch }, [])
    //       let stock_data = await axiosInstance.post('/itemmaster/graphql', {query: stock_fetch_query })
    //       let tocheckBatch = stock_data?.data?.data?.itemStock?.items?.[0]
    //       tocheckBatch['currentStock'] = Number(tocheckBatch?.['currentStock']) + Number(Qty)

    //       if (Number(tocheckBatch['currentStock']) >= 0) {
    //         try {
    //           let item_stock_mutate = `mutation itemstockmutation {
    //             itemStockCreateMutation(id:${tocheckBatch['id']}, currentStock: ${tocheckBatch['currentStock']}){
    //               success
    //               errors
    //             }
    //           }`
    //           const response = await axiosInstance.post('/itemmaster/graphql', {
    //             query: item_stock_mutate
    //           })
    //           let stock_update_response =
    //             response?.data?.data?.itemStockCreateMutation
    //           if (stock_update_response.success) {
    //             try {
    //               let inventory_approval_mutation_query = `mutation InventoryApprovalCreateQuery{
    //                 inventoryApprovalCreateMutation(partNumber: ${PartCode},
    //                 batchNumber: ${batch}, qty: "${Qty}", store: ${Store}){
    //                   inventoryApprovalItem {
    //                     id
    //                   }
    //                   success
    //                   errors
    //                 }
    //               }`
    //               const inventory_approval_response = await axiosInstance.post(
    //                 `/itemmaster/graphql`,
    //                 { query: inventory_approval_mutation_query }
    //               )
    //               let created_inventory_id = inventory_approval_response?.data?.data?.inventoryApprovalCreateMutation
    //               if (created_inventory_id?.success) {
    //                 let SaveValue = {
    //                   id: Number(singleitem['id']),
    //                   stockReduce: false,
    //                   isCanceled: true
    //                 }
    //                 let cleanValue = removeEmptyValueInObject(SaveValue)
    //                 await SaveWithDisCount(cleanValue)
    //                 if (index === Number(ItemDetaileData.length) - 1) {
    //                   allStockReduced(false)
    //                 }
    //               }
    //             } catch (error) {
    //               let listOfError = ShowErrorNoties(error)
    //               toast.error(listOfError, {
    //               position: 'top-right',
    //               autoClose: 2000,
    //               hideProgressBar: false,
    //               closeOnClick: true,
    //               pauseOnHover: true,
    //               draggable: true,
    //               progress: undefined,
    //               theme: 'light',
    //               transition: Bounce
    //              })
    //             }
    //           }
    //         } catch (error) {
    //           let listOfError = ShowErrorNoties(error)
    //           toast.error(listOfError, {
    //           position: 'top-right',
    //           autoClose: 2000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: 'light',
    //           transition: Bounce
    //           })
    //         }
    //       } else {
    //         toast.error('check the stock', {
    //           position: 'top-right',
    //           autoClose: 2000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: 'light',
    //           transition: Bounce
    //         })
    //       }
    //     } else if (serial.length > 0) {
    //       let stock_fetch_query = QueryData.StockDataFetch(
    //         1,
    //         200,
    //         { partNumber: PartCode, store: Store },
    //         []
    //       )
    //       let stock_data = await axiosInstance.post('/itemmaster/graphql', {
    //         query: stock_fetch_query
    //       })
    //       let tocheckSerial = stock_data?.data?.data?.itemStock?.items?.[0]
    //       let serialIds = tocheckSerial['serialNumber']?.map(
    //         serialId => serialId.id
    //       )
    //       tocheckSerial['currentStock'] =
    //         Number(tocheckSerial['currentStock']) + Number(Qty)
    //       const combinedListConcat = serialIds.concat(serial)

    //       try {
    //         let item_stock_mutate = `mutation itemstockmutation {
    //             itemStockCreateMutation(id:${tocheckSerial['id']}, currentStock: ${tocheckSerial['currentStock']},
    //             serialNumber: [${combinedListConcat}]){
    //               success
    //               errors
    //             }
    //           }`
    //         const response = await axiosInstance.post('/itemmaster/graphql', {
    //           query: item_stock_mutate
    //         })
    //         let stock_update_response =
    //           response?.data?.data?.itemStockCreateMutation
    //         if (stock_update_response?.success) {
    //           try {
    //             let inventory_approval_mutation_query = `mutation InventoryApprovalCreateQuery{
    //                   inventoryApprovalCreateMutation(partNumber: ${PartCode},
    //                   serialNumber: [${combinedListConcat}], qty: "${Qty}", store: ${Store}){
    //                     inventoryApprovalItem {
    //                       id
    //                     }
    //                     success
    //                     errors
    //                   }
    //                 }`
    //             const inventory_approval_response = await axiosInstance.post(
    //               `/itemmaster/graphql`,
    //               { query: inventory_approval_mutation_query }
    //             )
    //             let created_inventory_id = inventory_approval_response?.data?.data?.inventoryApprovalCreateMutation
    //             if (created_inventory_id?.success) {
    //               let SaveValue = {
    //                 id: Number(singleitem['id']),
    //                 stockReduce: false,
    //                 isCanceled: true
    //               }
    //               let cleanValue = removeEmptyValueInObject(SaveValue)
    //               await SaveWithDisCount(cleanValue)
    //               if (index === Number(ItemDetaileData.length) - 1) {
    //                 allStockReduced(false)
    //               }
    //             }
    //           } catch (error) {
    //             let listOfError = ShowErrorNoties(error)
    //               toast.error(listOfError, {
    //               position: 'top-right',
    //               autoClose: 2000,
    //               hideProgressBar: false,
    //               closeOnClick: true,
    //               pauseOnHover: true,
    //               draggable: true,
    //               progress: undefined,
    //               theme: 'light',
    //               transition: Bounce
    //              })
    //           }
    //         }
    //       } catch (error) {
    //         let listOfError = ShowErrorNoties(error)
    //               toast.error(listOfError, {
    //               position: 'top-right',
    //               autoClose: 2000,
    //               hideProgressBar: false,
    //               closeOnClick: true,
    //               pauseOnHover: true,
    //               draggable: true,
    //               progress: undefined,
    //               theme: 'light',
    //               transition: Bounce
    //              })
    //       }
    //     } else if(batch === undefined && serial?.length===0  ){

    //       let stock_fetch_query = QueryData.StockDataFetch(1, 200, { partNumber: PartCode, store: Store}, [])
    //       let stock_data = await axiosInstance.post('/itemmaster/graphql', {query: stock_fetch_query })
    //       let tocheckBatch = stock_data?.data?.data?.itemStock?.items?.[0]
    //       tocheckBatch['currentStock'] = Number(tocheckBatch?.['currentStock']) + Number(Qty)
    //       if (Number(tocheckBatch['currentStock']) >= 0) {
    //         try {
    //           let item_stock_mutate = `mutation itemstockmutation {
    //             itemStockCreateMutation(id:${tocheckBatch['id']}, currentStock: ${tocheckBatch['currentStock']}){
    //               success
    //               errors
    //             }
    //           }`
    //           const response = await axiosInstance.post('/itemmaster/graphql', {
    //             query: item_stock_mutate
    //           })
    //           let stock_update_response =
    //             response?.data?.data?.itemStockCreateMutation
    //           if (stock_update_response.success) {
    //             try {
    //               let inventory_approval_mutation_query = `mutation InventoryApprovalCreateQuery{
    //                 inventoryApprovalCreateMutation(partNumber: ${PartCode},
    //                     qty: "${Qty}", store: ${Store}){
    //                   inventoryApprovalItem {
    //                     id
    //                   }
    //                   success
    //                   errors
    //                 }
    //               }`
    //               const inventory_approval_response = await axiosInstance.post(
    //                 `/itemmaster/graphql`,
    //                 { query: inventory_approval_mutation_query }
    //               )
    //               let created_inventory_id = inventory_approval_response?.data?.data?.inventoryApprovalCreateMutation
    //               if (created_inventory_id?.success) {
    //                 let SaveValue = {
    //                   id: Number(singleitem['id']),
    //                   stockReduce: false,
    //                   isCanceled: true
    //                 }
    //                 let cleanValue = removeEmptyValueInObject(SaveValue)
    //                 await SaveWithDisCount(cleanValue)
    //                 if (index === Number(ItemDetaileData.length) - 1) {
    //                   allStockReduced(false)
    //                 }
    //               }
    //             } catch (error) {
    //               let listOfError = ShowErrorNoties(error)
    //               toast.error(listOfError, {
    //               position: 'top-right',
    //               autoClose: 2000,
    //               hideProgressBar: false,
    //               closeOnClick: true,
    //               pauseOnHover: true,
    //               draggable: true,
    //               progress: undefined,
    //               theme: 'light',
    //               transition: Bounce
    //              })
    //             }
    //           }
    //         } catch (error) {
    //           let listOfError = ShowErrorNoties(error)
    //           toast.error(listOfError, {
    //           position: 'top-right',
    //           autoClose: 2000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: 'light',
    //           transition: Bounce
    //           })
    //         }
    //       } else {
    //         toast.error('check the stock', {
    //           position: 'top-right',
    //           autoClose: 2000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: 'light',
    //           transition: Bounce
    //         })
    //       }
    //     }
    //   }

    // }
    try {
      let peremeter = `posID: "${posEdit}"`
      const respones = await axiosInstance.post('/itemmaster/graphql', {
        query: CancelPOSQuery(peremeter)
      })
      const responseData = respones.data.data.salesOrderCancleMutation
      if (responseData?.success) {
        setstatus('Canceled')
        showErrorToast(true, 'success', '', 'pos Cancel Successfully')
      } else {
        showErrorToast(true, 'error', `${responseData?.errors}`, '')
      }
    } catch (error) {
      let filiterError = ShowErrorNoties(error)
      showErrorToast(true, 'error', `${filiterError}`, '')
    }
    setLoadingModels(false)
  }

  function checkDuplicateValue () {
    let checkValue = {}
    // initialdata
    if (initialdata === null) {
      return false
    } else {
      if (!isSales) {
        checkValue['marketingEvent'] = selectMarketingEvent?.value
          ? selectMarketingEvent?.value
          : ''
        checkValue['Store'] = selectedStore?.value ? selectedStore?.value : ''
        checkValue['CosName'] = costomerName ? costomerName : ''
        checkValue['Mobile'] = mobileNumber ? mobileNumber : ''
        checkValue['WhatsappNumber'] = whatsAppNumber ? whatsAppNumber : ''
        checkValue['Email'] = email ? email : ''
        checkValue['State'] = State ? State : ''
        checkValue['City'] = city ? city : ''
        checkValue['balanceAmount'] = currentBalance ? currentBalance : ''
        checkValue['SalesPerson'] = salesPerson.value ? salesPerson.value : ''
        checkValue['remarksText'] = remarksText
        checkValue['isDelivered'] = isDelivered
        checkValue['pending'] = pending
      } else {
        checkValue['marketingEvent'] = selectMarketingEvent?.value
          ? selectMarketingEvent?.value
          : ''
        checkValue['Store'] = selectedStore?.value ? selectedStore?.value : ''
        checkValue['CosName'] = costomerName ? costomerName : ''
        checkValue['BillingAddress'] = selectBillingAddress?.value
          ? selectBillingAddress?.value
          : ''
        checkValue['DeliverAddress'] = selectDeliverAddress?.value
          ? selectDeliverAddress?.value
          : ''
        checkValue['contactPerson'] = contactPerson?.value
          ? contactPerson?.value
          : ''
        checkValue['balanceAmount'] = currentBalance ? currentBalance : ''
        // checkValue['State'] = State.value ? State.value : ''
        checkValue['SalesPerson'] = salesPerson.value ? salesPerson.value : ''
        checkValue['remarksText'] = remarksText
        checkValue['isDelivered'] = isDelivered
        checkValue['pending'] = pending
      }
      console.log(initialdata, checkValue)
      return checkChangesInputs([initialdata, checkValue])
    }
  }

  // retun null ; on submit check the value
  async function checkSubmit () {
    let isDuplicate = checkDuplicateValue()
    if (!isDuplicate) {
      setShowStockNeedList([])
      if (Number(currentBalance) > Number(0.0)) {
        toast.warn('Close the payment ', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      } else if (pending) {
        showErrorToast(true, 'warn', 'Mark the pending status as false.', '')
      } else if (!isDelivered) {
        toast.warn('Delivery Should be Completed before Submit!.', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      } else if (!checkStockAvailability) {
        try {
          let peremeter = `posID: "${posEdit}",`
          const respones = await axiosInstance.post('/itemmaster/graphql', {
            query: checkStockPOSQuery(peremeter)
          })
          const responseData = respones.data.data.checkStock
          if (responseData?.success) {
            showErrorToast(
              true,
              'success',
              ``,
              'Stock status is updated try Again'
            )
            setCheckStockAvailability(true)
          } else {
            const needStock = JSON.parse(responseData?.needStock)

            try {
              const rawNeedStock = responseData?.needStock
              console.log('Raw needStock:', rawNeedStock) // Log raw data

              const needStock = JSON.parse(rawNeedStock)
              console.log('Parsed needStock:', needStock) // Log parsed data
              console.log(typeof needStock)

              if (Array.isArray(needStock)) {
                setShowStockNeedList(needStock)
              } else {
                console.error('Parsed needStock is not an array:', needStock)
                setShowStockNeedList([])
              }
            } catch (error) {
              console.error('Error parsing JSON:', error)
              setShowStockNeedList([])
            }

            // setShowStockNeedList(needStock)
            setCheckStockAvailability(false)
            setShowStockNeed(true)
          }
        } catch (error) {
          setCheckStockAvailability(false)
          let filiterError = ShowErrorNoties(error)
          showErrorToast(true, 'error', `${filiterError}`, '')
        }
      } else {
        ReducesStock()
      }
    } else {
      toast.warn('Save The Changes', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce
      })
    }
  }

  // return null , on change store delete the data
  async function DeletePosItemData (params) {
    if (params === 'yes') {
      for (let indexofitem in ItemDetaileData) {
        let id = ItemDetaileData[indexofitem]['id']
        try {
          const respones = await axiosInstance.post('/itemmaster/graphql', {
            query: deletePosOrderItem(id)
          })
          if (respones.data.data.salesOrderDeleteMutation.success) {
            const remove_delete_data = posItemData.filter(
              data => data.id !== id
            )
            setPosItemData(remove_delete_data)
          } else {
            let filiterError = ShowErrorNoties(error)
            toast.error(filiterError, {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              transition: Bounce
            })
          }
        } catch (error) {
          let listOfError = ShowErrorNoties(error)
          toast.error(listOfError, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce
          })
        }
      }
    }
  }

  function permissionToStockAddtion (params) {
    if (params) {
      AddtionStock()
    }
  }

  // retun Number; show Round off Value
  function RoundOffValue (params) {
    // topayWithoutRoundoff
    let withouttax =
      Number(toPay) > Number(topayWithoutRoundoff)
        ? '+' + `${Number(toPay) - Number(topayWithoutRoundoff)}`
        : Number(toPay) < Number(topayWithoutRoundoff)
        ? `${Number(toPay) - Number(topayWithoutRoundoff)}`
        : '0.00'
    return Number(withouttax).toFixed(2)
  }

  // retun null; duplica the data
  async function duplicateTheData (params) {
    setstatus(null)
    setPosCustomId('')
    SetEdit(false)
    setPosEdit(null)
    setOrderDate(getCurrentDate())
    setPosItemData([])
    setDiscountPercentage('')
    setDiscountValue('')
    setDiscountTotalValue('')
    setIsSomeStockReduced(false)
    setPosEditData(null)
    setReceivedAmount(0.0)
    let duplicateObjectsList = []
    posItemData.map(async (row, index) => {
      // Update the row properties
      let finalamount = (Number(row.rate) * Number(row['qty'])).toFixed(2)
      const savedata = {
        partCode: Number(row['partCode']['id']),
        description: row['description'],
        uom: row['uom']?.['id'] ? Number(row['uom']?.['id']) : '',
        qty: row['qty'] ? Number(row['qty']) : 1,
        serial: row['serial']?.length > 1 ? row['serial'] : null,
        batch: row['batch']?.['id'] ? Number(row['batch']?.['id']) : null,
        stockReduce: false,
        isCanceled: false,
        gstRate: row['gstRate'] ? Number(row['gstRate']) : '',
        rate: row['rate'] ? Number(row['rate']) : 0,
        amount: Number(finalamount),
        discountPercentage: 0.0,
        discountValue: 0.0,
        discountValueForPerItem: 0.0,
        finalValue: 0.0,
        createdBy: Number(userId)
      }

      savedata['description'] = String(savedata['description']).replace(
        /"/g,
        '\\"'
      )
      const EditData = togettax(savedata, row['gstRate'])
      duplicateObjectsList.push(EditData)
    })

    try {
      const response = await axiosInstance.post('/itemmaster/graphql', {
        query: salesOrderBulkCreateMutation(),
        variables: { salesOrders: duplicateObjectsList }
      })
      const result =
        response?.['data']?.['data']?.['salesOrderBulkCreateMutation']
      if (result?.success) {
        setPosItemData(result?.['createdItems'])
      } else {
        toast.error('Try again', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      }
    } catch (error) {
      let listOfError = ShowErrorNoties(error)
      toast.error(listOfError, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce
      })
    }
  }

  async function updateThePayment () {
    if (initialValue['payment'].length > 0) {
      let update_payment_id =
        initialValue['payment'][initialValue['payment'].length - 1]
      let update_balance = toPay - receivedAmount

      if (
        Number(update_balance) !== Number(currentBalance) &&
        Number(toPay) !== 0
      ) {
        let payment_mutation = `mutation MyMutation {
          paymentModeCreateMutation(balanceAmount: "${update_balance}", id: "${update_payment_id}"){
            errors
            success
            paymentModeInstance {
              id
              payby {
                id
                accountsName
              }
              payAmount
              balanceAmount
              CreatedAt
              createdBy {
                id
                username
              }
            }
          }
        }`
        try {
          const response = await axiosInstance.post('itemmaster/graphql', {
            query: payment_mutation
          })
          const responseData =
            response?.data?.data?.paymentModeCreateMutation?.paymentModeInstance
        } catch (error) {
          let filiterError = ShowErrorNoties(error)
          toast.error(filiterError, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: 'light'
            // transition: Bounce,
          })
        }
      }
    }
  }

  function BackToPosTable (params) {
    if (status === 'Save') {
      let isDuplicate = checkDuplicateValue()
      if (!isDuplicate) {
        
        if (setIsShowPos){
          setIsShowPos(false)
        } else{
          Navigate('/posdata')
        }
        resetpos()
      } else {
        toast.warn('Save The Changes', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      }
    } else {
      if (setIsShowPos){
        setIsShowPos(false)
      } else{
        Navigate('/posdata')
      }
      resetpos()
    }
  }

  //current
  const updateItemComboToMasterItem = ids => {
    let mutation_query = UpdateItemComboToSalesOrder(
      currentEditItemComboData.id,
      ids
    )
    axiosInstance
      .post('/itemmaster/graphql', { query: mutation_query })
      .then(res => {
        setPosItemData(prevData => {
          return prevData.map(item => {
            if (item.id === currentEditItemComboData.id) {
              return { ...item, itemCombo: ids }
            }
            return item
          })
        })
        handleCloseItemComboModal()
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (posItemData.length > 0) {
      setItemDetailsData(posItemData)
    } else {
      setItemDetailsData(posItemData)
    }
    if (posItemData.length > 0) {
      const selectedRows = posItemData
      let calamount = 0
      let fixedTotalAmount = 0
      let tableTotalQty = 0
      let TotalDiscountValueForSales = 0
      const igstList = {}
      const cgstList = {}
      const sgstList = {}
      selectedRows.forEach(itemvalue => {
        fixedTotalAmount += itemvalue.rate * itemvalue.qty
        if (itemvalue.DiscountValue) {
          TotalDiscountValueForSales += Number(itemvalue.discountValue)
        } else if (itemvalue.DiscountValueForPerItem !== NaN) {
          TotalDiscountValueForSales +=
            Number(itemvalue.discountValueForPerItem) * itemvalue.qty
        }
        tableTotalQty += Number(itemvalue.qty)

        calamount += Number(itemvalue.amount)

        if (itemvalue.igst) {
          if (igstList[itemvalue.igst]) {
            igstList[itemvalue.igst].push(itemvalue.amount)
          } else {
            igstList[itemvalue.igst] = [itemvalue.amount]
          }
        }

        if (itemvalue.cgst) {
          if (cgstList[itemvalue.cgst]) {
            cgstList[itemvalue.cgst].push(itemvalue.amount)
          } else {
            cgstList[itemvalue.cgst] = [itemvalue.amount]
          }
        }

        if (itemvalue.sgst) {
          if (sgstList[itemvalue.sgst]) {
            sgstList[itemvalue.sgst].push(itemvalue.amount)
          } else {
            sgstList[itemvalue.sgst] = [itemvalue.amount]
          }
        }
      })
      setTotalDiscountValueForSales(
        Number(TotalDiscountValueForSales).toFixed(2)
      )
      setTotalBillingItem(tableTotalQty)
      setTotalBillAmount(Number(fixedTotalAmount).toFixed(2))

      const { taxResult: igstResult, taxValues: igstaxvalues } =
        calculateTax(igstList)
      const { taxResult: cgstResult, taxValues: cgstaxvalues } =
        calculateTax(cgstList)
      const { taxResult: sgstResult, taxValues: sgstaxvalues } =
        calculateTax(sgstList)
      setIgstTax(igstResult)
      setCgstTax(cgstResult)
      setSgstTax(sgstResult)
      setTotalTax((igstaxvalues + cgstaxvalues + sgstaxvalues).toFixed(2))
      setTotalValue(calamount.toFixed(2))
      setToPay(roundToNearest10(calamount).toFixed(2))
      settopayWithoutRoundoff(calamount.toFixed(2))
      if (igstaxvalues || cgstaxvalues || sgstaxvalues) {
        setTotalWithTaxValue(
          roundToNearest10(
            igstaxvalues + cgstaxvalues + sgstaxvalues + calamount
          ).toFixed(2)
        )
        settopayWithoutRoundoff(
          igstaxvalues + cgstaxvalues + sgstaxvalues + calamount
        )
        setToPay(
          roundToNearest10(
            igstaxvalues + cgstaxvalues + sgstaxvalues + calamount
          ).toFixed(2)
        )
      } else if (fixedTotalAmount) {
        setTotalWithTaxValue('')
      } else {
        setTotalTax(0.0)
      }
      setIsLoading(!isLoading)
    } else {
      setTotalDiscountValueForSales(0.0)
      setTotalBillingItem(0.0)
      setTotalTax(0.0)
      setTotalWithTaxValue(0.0)
      setTotalBillAmount(0.0)
      setToPay('0.00')
      setTotalTax(0.0)
      setTotalValue(0.0)
      settopayWithoutRoundoff(0.0)
      console.log('-----2')
      setIsLoading(!isLoading)
    }
  }, [posItemData])



  //  after get scanner data pass to  datacall
  useEffect(() => {
    if (isSales) {
      if (selectedStore.value) {
        if (selectcostomerName.value) {
          if (stateForSales) {
            datacall()
          }
        }
      }
    } else {
      if (selectedStore.value) {
        datacall()
      }
    }
  }, [scannerValue])

  //  initialValue to check

  useEffect(() => {
    if (supplierSelect?.id) {
      setSelectCostomerName({
        value: supplierSelect?.id,
        label: supplierSelect?.lable
      })
      initialValue['CostomerName'] = supplierSelect?.id
      initialValue['CosName'] = supplierSelect?.lable
      setCostomerName(supplierSelect?.lable)
      getAddress(supplierSelect?.id)
    }
  }, [supplierSelect])

  useEffect(() => {
    if (discountPercentage) {
      const percentagevalue =
        (Number(TotalValue) / 100) * Number(discountPercentage)
      setDiscountPercentageInValue(percentagevalue.toFixed(2))
    } else if (discountPercentage === '') {
      setDiscountPercentageInValue()
    }
  }, [discountPercentage])

  // retun 0; call it on initial
  useEffect(() => {
    fetchDatawithRedex()
    if (posEdit === '' || posEdit === null || posEdit === undefined) {
      if (!posEdit && currentConference) {
        initialValue['marketingEvent'] = currentConference?.value
        handleMarketingEventChange({
          value: currentConference?.value,
          label: currentConference?.lable
        })
      }
      if (!posEdit && currentConferenceStore) {
        let Options = {
          value: currentConferenceStore?.value,
          label: currentConferenceStore?.lable
        }
        handleSelectStore(Options)
      }
    }
  }, [currentConferenceStore, currentConference])

  useEffect(() => {
    setDataLoading(true)
    if (getData.name === 'StoreOptionsInPos') {
      setStoreOptions(getData.value.items)
    }
    if (getData.name === 'PosEditData') {
      if (Number(getData.value.items?.[0]?.['id']) === Number(posEdit)) {
        setPosEditData(getData.value.items)
      }
    }
    if (getData.name === 'posPincodeList') {
      setPincodeList(getData.value.items)
    }
    if (getData.name === 'distri_state') {
      let filiter_resuelt = getData?.value?.items?.[0]

      // setPincode({ value: filiter_resuelt?.['pincode']?.id, label: filiter_resuelt?.['pincode']?.pincode})

      setState(filiter_resuelt?.['state']?.stateName)
      setCity(filiter_resuelt?.['district']?.district)
    }
    setDataLoading(false)
  }, [getData])

  //retun null; handle Balnce
  useEffect(() => {
    if (TotalWithTaxValue && receivedAmount !== undefined) {
      setCurrentBalance(
        (roundToNearest10(TotalWithTaxValue) - receivedAmount).toFixed(2)
      )
      updateThePayment(
        (roundToNearest10(TotalWithTaxValue) - receivedAmount).toFixed(2)
      )
    } else if (TotalValue && receivedAmount !== undefined) {
      setCurrentBalance(
        (roundToNearest10(TotalValue) - receivedAmount).toFixed(2)
      )
      updateThePayment(
        (roundToNearest10(TotalValue) - receivedAmount).toFixed(2)
      )
    }
  }, [receivedAmount, TotalWithTaxValue, TotalValue])

  async function fetchContactDataforContactOptions (params) {
    let queryWithPerementer = ` 
       query MyQuery {
      contactDetalis(phoneNumber: "${params}"){
        items {
          contactPersonName
          email
          id
          otherNo
          phoneNumber 
          whatsappNo
          supplierformdataSet { 
            address {
              default
              pincode
            }
          }
        }
      }
    }`
    const response = await commanFetch(queryWithPerementer)

    const responseData = response?.data?.items

    if (response.success) {
      setContactList(
        responseData?.map(item => ({
          value: item?.id,
          label: item?.phoneNumber,
          name: item?.contactPersonName,
          email: item?.email,
          whatsappNo: item?.whatsappNo,
          otherNo: item?.otherNo,
          pincode: item?.supplierformdataSet?.[0]?.address
            ?.filter(address => address?.default)
            ?.map(address => address.pincode)[0]
        }))
      )
    } else {
      console.error('Error fetching data:', response.error)
    }
  }

  // retun null; create new
  const handleCreate = (e, formikProps) => {
    formikProps.setFieldValue('Mobile', e)

    setMobileNumber(e)
    setIsNewContact(true)
    setSamplelinkedCostomerId()
  }

  const handleSelectpincode = options => {
    if (options) {
      let peremeter = `pincode:${options?.value}, pincodeDistinct:true`

      dispatch(
        fetchOptionsData(
          QueryData.Filiter_address_with_pincode_Query(peremeter),
          'distri_state'
        )
      )
      if (options.label) {
        setPincode({ value: options.value, label: options.label })
      }
    } else {
      console.log('----->>>')

      setPincode({ value: '', label: '' })
      setState('')
      setCity('')
    }
  }

  return (
    <>
      <ToastContainer autoClose={1000} />
      {Discount()}
      {TaxModel()}
      {<LoadingModelBallTriangle isshow={loadingModels} />}
      {showItemComboModal && (
        <PosItemComboModal
          show={showItemComboModal}
          close={handleCloseItemComboModal}
          partCode={currentEditItemComboId}
          store={selectedStore}
          data={currentEditItemComboData}
          updateItemComboToMasterItem={updateItemComboToMasterItem}
          TotalWithTaxValue={TotalWithTaxValue}
          userId={userId}
        />
      )}
      <Modal
        show={isShowPos}
        fullscreen
        backdrop='static'
        keyboard={false}
        centered
      >
        <div
          className='container-fluid bg-light '
          style={{ width: '100%', position: 'relative' }}
          tabIndex={0}
        >
          <div
            className='itemMaster_Top mx-3  d-flex justify-content-center card'
            style={{ width: '98%', maxHeight: '5rem', minHeight: '5rem' }}
          >
            <div className='d-flex align-items-center justify-content-between  '>
              <div className='row'>
                <div className='col-12'>
                  <h3 className='mx-2 commanModelTitleColor d-flex align-items-center'>
                    <i
                      className='fa-solid fa-arrow-left fa-sm me-2'
                      onClick={() => {
                        BackToPosTable()
                      }}
                    ></i>
                    POS{' '}
                    <span className='btn badge rounded-pill text-dark ms-3 fs-6 logoGreen'>
                      {status !== null
                        ? `${status === 'Save' ? 'Draft' : status}`
                        : ''}
                    </span>
                  </h3>
                </div>
              </div>
              <div className='border rounded-pill pt-2 pb-2 ps-3 pe-3 bg-light d-flex align-items-center justify-content-center'>
                <button
                  type='button'
                  onClick={() => {
                    handleSalesComponentActive()
                  }}
                  disabled={Edit}
                  className={`btn   badge rounded-pill text-dark    postypeSales  fs-6 ${salesActiveClassName}`}
                >
                  Sales
                </button>
                <button
                  type='button'
                  disabled={Edit}
                  onClick={() => {
                    handleSamplesComponentActive()
                  }}
                  className={`btn   badge rounded-pill text-dark m-0 p-0 fs-6 postype ${samplesActiveClassName}`}
                >
                  Sample
                </button>
              </div>
              <div className=''>
                <div className='' hidden={!posEdit ? true : false}>
                  <Can I={'Cancel'} a={'POS'}>
                    <TiCancel
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Cancle'
                      className='fs-2 pb-1  '
                      style={{ color: '#7f7f7f', cursor: 'pointer' }}
                      onClick={() => {
                        if (status === 'Submited' || status === 'Save') {
                          setWaringModel(true)
                        }
                      }}
                    />
                  </Can>
                  <Can I={'Copy'} a={'POS'}>
                    <i
                      className='fa-solid fa-copy  fs-5 pt-1 mx-3 text-success'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Duplicate'
                      role='button'
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (status === 'Canceled') {
                          duplicateTheData()
                        } else if (
                          userName === 'Jegathish_E' ||
                          userName === 'M Antony Armstrong' ||
                          userName === 'Jegathish E'
                        ) {
                          duplicateTheData()
                        }
                      }}
                    ></i>
                  </Can>
                  <Can I={'Delete'} a={'POS'}>
                    <i
                      className='fa-solid fa-trash mx-3 fs-5 text-danger '
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Delete'
                      style={{ cursor: 'pointer' }}
                      onClick={deletePos}
                    ></i>
                  </Can>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-3 mx-3' style={{ width: '98%', height: '52rem' }}>
            <Formik
              enableReinitialize={typeof posEdit === 'object' ? true : false}
              key={formKey}
              initialValues={initialValue}
              validationSchema={PosSalesorderSchema}
              onSubmit={handleSubmit}
            >
              {formikProps => {
                return (
                  <>
                    <Form
                      className='row'
                      style={{ width: '100%', height: '100%' }}
                    >
                      {(formikProps.values.isSales = isSales)}
                      <div className='left-side col-3 '>
                        <div
                          className='customer-details card pt-3'
                          style={{ height: isSales ? '60%' : '66%' }}
                        >
                          <div className='row d-flex justify-content-around '>
                            <div className='col-6 fw-bold ms-3'>
                              <span
                                style={{
                                  color: 'rgb(11 120 84)',
                                  fontSize: '1.1rem'
                                }}
                              >
                                {posCustomId ? posCustomId : ''}{' '}
                              </span>
                            </div>
                            <div className='col-5 col-md-5'>
                              <input
                                type='date'
                                id='OrderDate'
                                name='OrderDate'
                                className=' form-control w-100 fs-6 fw-bold'
                                disabled={
                                  status === 'Canceled'
                                    ? true
                                    : isSomeStockReduced
                                }
                                style={{ border: 'none', fontSize: '.8rem' }}
                                value={orderDate}
                                onChange={e => {
                                  setOrderDate(e.target.value)
                                }}
                              />
                            </div>
                          </div>
                          {!isSales && (
                            <div
                              className='sample-customer-card pb-4'
                              style={{ overflow: 'scroll' }}
                            >
                              <div className='row mx-2'>
                                <div className='col-12 staan-input-group'>
                                  <Select
                                    name='marketingEvent'
                                    isClearable
                                    isDisabled={
                                      status === 'Canceled'
                                        ? true
                                        : isSomeStockReduced
                                    }
                                    options={conferenct?.map(con => ({
                                      value: con.id,
                                      label: con.name
                                    }))}
                                    value={selectMarketingEvent}
                                    onChange={option => {
                                      handleMarketingEventChange(option)
                                      formikProps.setFieldValue(
                                        'marketingEvent',
                                        option?.value
                                      )
                                      initialValue['marketingEvent'] =
                                        option?.value
                                    }}
                                    className='mt-3'
                                    styles={posCustomSelectStyle}
                                  />
                                  <label
                                    htmlFor='marketingEvent'
                                    className='staan-form-input-label pt-0 px-1 mx-2'
                                  >
                                    Marketing Event{' '}
                                    <span className='text-danger'>*</span>
                                  </label>
                                  {formikProps.touched.marketingEvent &&
                                    formikProps.errors.marketingEvent && (
                                      <small className='staan-error-msg'>
                                        {formikProps.errors.marketingEvent}
                                      </small>
                                    )}
                                </div>
                              </div>
                              <div className='row mx-2'>
                                <div className='col-12  staan-input-group'>
                                  <Select
                                    isDisabled={
                                      status === 'Canceled'
                                        ? true
                                        : isSomeStockReduced
                                    }
                                    options={storeOptions.map(item => ({
                                      value: item.id,
                                      label: item.storeName
                                    }))}
                                    isClearable
                                    value={selectedStore}
                                    onChange={option => {
                                      handleSelectStore(option)
                                      formikProps.setFieldValue(
                                        'Store',
                                        option?.value
                                      )
                                    }}
                                    className='mt-3'
                                    styles={posCustomSelectStyle}
                                  />
                                  <label className='staan-form-input-label pt-0 px-1 mx-2'>
                                    Store <span className='text-danger'>*</span>
                                  </label>
                                  {!isSales && formikProps.errors.Store && (
                                    <small className='staan-error-msg'>
                                      {formikProps.errors.Store}
                                    </small>
                                  )}
                                  {formikProps.touched.Store &&
                                    formikProps.errors.Store && (
                                      <small className='staan-error-msg'>
                                        {formikProps.errors.Store}
                                      </small>
                                    )}
                                </div>
                              </div>
                              <div className='row mx-2'>
                                <div className='col-12 staan-input-group pos_input_group'>
                                  <Field
                                    type='text'
                                    name='CosName'
                                    disabled={
                                      status === 'Canceled'
                                        ? true
                                        : !isNewContact
                                        ? true
                                        : isSomeStockReduced
                                    }
                                    value={costomerName}
                                    Ref={cosNameRef}
                                    onChange={e => {
                                      handleCostomerName(e)
                                      formikProps.setFieldValue(
                                        'CosName',
                                        e.target.value
                                      )
                                    }}
                                    placeholder=''
                                    className='w-100 staan-form-input'
                                  />
                                  <label
                                    htmlFor='CosName'
                                    className='staan-form-input-label pt-0 px-1 mx-2'
                                  >
                                    Customer Name{' '}
                                    <span className='text-danger'>*</span>
                                  </label>
                                  {formikProps.touched.CosName &&
                                    formikProps.errors.CosName && (
                                      <small className='staan-error-msg'>
                                        {formikProps.errors.CosName}
                                      </small>
                                    )}
                                </div>
                              </div>
                              <div className='row mx-2'>
                                <div className='col-12 staan-input-group pos_input_group'>
                                  {isNewContact ? (
                                    <Field
                                      type='number'
                                      name='Mobile'
                                      disabled
                                      // {
                                      //   status === 'Canceled'
                                      //     ? true
                                      //     : isSomeStockReduced
                                      // }
                                      value={mobileNumber}
                                      onChange={e => {
                                        handlemobileNumber(e)
                                        formikProps.setFieldValue(
                                          'Mobile',
                                          e.target.value
                                        )
                                      }}
                                      className='w-100 staan-form-input'
                                    />
                                  ) : (
                                    <CreatableSelect
                                      onInputChange={e => {
                                        fetchContactDataforContactOptions(e)
                                      }}
                                      name='Mobile'
                                      isClearable
                                      options={contactList}
                                      isSearchable={true}
                                      value={mobile}
                                      onCreateOption={e => {
                                        handleCreate(e, formikProps)
                                      }}
                                      onChange={option => {
                                        if (option) {
                                          setSamplelinkedCostomerId(
                                            option?.value
                                          )
                                          setCostomerName(option?.name)
                                          formikProps?.setFieldValue(
                                            'CosName',
                                            option?.name
                                          )
                                          handlewhatsAppNumber(
                                            option?.whatsappNo
                                          )
                                          setEmail(option?.email)
                                          formikProps.setFieldValue(
                                            'Email',
                                            option?.email
                                          )
                                          setMobile(option ? option : null)
                                          formikProps.setFieldValue(
                                            'Mobile',
                                            option ? option?.label : null
                                          )
                                          formikProps.setFieldValue(
                                            'WhatsappNumber',
                                            option?.whatsappNo
                                          )
                                          dispatch(
                                            fetchOptionsData(
                                              QueryData.Filiter_address_with_pincode_Query(
                                                `pincode: ${option?.pincode}`
                                              ),
                                              'distri_state'
                                            )
                                          )
                                        } else {
                                          setSamplelinkedCostomerId('')
                                          setCostomerName('')
                                          formikProps?.setFieldValue(
                                            'CosName',
                                            ''
                                          )
                                          handlewhatsAppNumber(' ')
                                          setEmail(' ')
                                          formikProps.setFieldValue(
                                            'Email',
                                            ' '
                                          )
                                          setMobile(null)
                                          formikProps.setFieldValue(
                                            'Mobile',
                                            null
                                          )
                                          formikProps.setFieldValue(
                                            'WhatsappNumber',
                                            null
                                          )
                                        }
                                      }}
                                      styles={{
                                        control: (provided, state) => ({
                                          ...provided,
                                          fontSize: '.8rem',
                                          backgroundColor: state.isDisabled
                                            ? 'transparent'
                                            : provided.backgroundColor,
                                          color: state.isDisabled
                                            ? '#000'
                                            : provided.color,
                                          borderColor: '#65e6b4'
                                        }),
                                        option: provided => ({
                                          ...provided,
                                          fontSize: '.8rem !important'
                                        })
                                      }}
                                      placeholder='Search Mobile Number. . .'
                                      className='my-3  '
                                    />
                                  )}
                                  <label
                                    htmlFor='Mobile'
                                    className='staan-form-input-label pt-0 px-1 mx-2'
                                  >
                                    Mobile Number{' '}
                                    <span className='text-danger'>*</span>
                                    <span
                                      className='cursor-pointer'
                                      style={
                                        !isNewContact
                                          ? { color: ' ' }
                                          : { color: 'Highlight' }
                                      }
                                      onClick={() => {
                                        setIsNewContact(!isNewContact)
                                        setSamplelinkedCostomerId('')
                                        setCostomerName('')
                                        formikProps?.setFieldValue(
                                          'CosName',
                                          ''
                                        )
                                        handlewhatsAppNumber('')
                                        setEmail('')
                                        setMobile(null)
                                      }}
                                    >
                                      {' '}
                                      {isNewContact ? (
                                        <MdInput className='fs-5' />
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                  </label>
                                  {formikProps.touched.Mobile &&
                                    formikProps.errors.Mobile && (
                                      <small className='staan-error-msg'>
                                        {formikProps.errors.Mobile}
                                      </small>
                                    )}
                                </div>
                              </div>
                              <div className='row mx-2'>
                                <div className='col-12 staan-input-group pos_input_group'>
                                  <Field
                                    type='number'
                                    name='WhatsappNumber'
                                    value={whatsAppNumber}
                                    disabled={
                                      status === 'Canceled'
                                        ? true
                                        : !isNewContact
                                        ? true
                                        : isSomeStockReduced
                                    }
                                    onChange={e => {
                                      handlewhatsAppNumber(e.target.value)
                                      formikProps.setFieldValue(
                                        'WhatsappNumber',
                                        e.target.value
                                      )
                                    }}
                                    className='w-100 staan-form-input'
                                  />
                                  <label
                                    htmlFor='WhatsappNumber'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Whatsapp Number
                                  </label>
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: '.7rem'
                                    }}
                                    onClick={() => {
                                      if (mobileNumber) {
                                        formikProps.setFieldValue(
                                          'WhatsappNumber',
                                          mobileNumber
                                        )
                                        handlewhatsAppNumber(mobileNumber)
                                      }
                                    }}
                                    className='text-success p-2'
                                  >
                                    <i className='fa-solid fa-clone'></i> Same
                                    as Mobile Number
                                  </span>
                                  {formikProps.touched.WhatsappNumber &&
                                    formikProps.errors.WhatsappNumber && (
                                      <small className='staan-error-msg'>
                                        {formikProps.errors.WhatsappNumber}
                                      </small>
                                    )}
                                </div>
                              </div>
                              <div className='row mx-2'>
                                <div className='col-12 staan-input-group pos_input_group'>
                                  <Field
                                    type='email'
                                    name='Email'
                                    disabled={
                                      status === 'Canceled'
                                        ? true
                                        : !isNewContact
                                        ? true
                                        : isSomeStockReduced
                                    }
                                    value={email}
                                    onChange={e => {
                                      handleEmail(e)
                                      formikProps.setFieldValue(
                                        'Email',
                                        e.target.value
                                      )
                                    }}
                                    className='w-100 staan-form-input'
                                  />
                                  <label
                                    htmlFor='Email'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Email
                                  </label>
                                  {formikProps.touched.Email &&
                                    formikProps.errors.Email && (
                                      <small className='staan-error-msg'>
                                        {formikProps.errors.Email}
                                      </small>
                                    )}
                                </div>
                              </div>
                              <div className='row mx-2'>
                                <div className='col-6 staan-input-group pos_input_group'>
                                  <Select
                                    onInputChange={e => {
                                      dispatch(
                                        fetchOptionsData(
                                          QueryData.pincodeQuery(e, 1),
                                          'posPincodeList'
                                        )
                                      )
                                    }}
                                    name='pincode'
                                    isDisabled={
                                      status === 'Canceled'
                                        ? true
                                        : isSomeStockReduced
                                    }
                                    value={pincode}
                                    isClearable
                                    options={pincodeList.map(item => ({
                                      value: item?.id,
                                      label: item?.pincode
                                    }))}
                                    onChange={option => {
                                      handleSelectpincode(option)
                                      formikProps.setFieldValue(
                                        'pincode',
                                        option?.value
                                      )
                                    }}
                                    className='mt-3'
                                    styles={posCustomSelectStyle}
                                  />
                                  <label
                                    htmlFor='pincode'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Pincode
                                  </label>
                                  {formikProps.touched.pincode &&
                                    formikProps.errors.pincode && (
                                      <small className='staan-error-msg'>
                                        {formikProps.errors.pincode}
                                      </small>
                                    )}
                                </div>
                                <div className='col-6 staan-input-group pos_input_group'>
                                  <Field
                                    type='text'
                                    name='City'
                                    value={city}
                                    disabled
                                    Ref={cosNameRef}
                                    onChange={e => {
                                      setCity(e.target.value)
                                      formikProps.setFieldValue(
                                        'City',
                                        e.target.value
                                      )
                                    }}
                                    placeholder=''
                                    className='w-100 staan-form-input'
                                  />
                                  <label
                                    htmlFor='City'
                                    className='staan-form-input-label pt-2 px-1 mx-2'
                                  >
                                    City
                                  </label>
                                  {formikProps.touched.City &&
                                    formikProps.errors.City && (
                                      <small className='staan-error-msg'>
                                        {formikProps.errors.City}
                                      </small>
                                    )}
                                </div>
                              </div>
                              <div className='row mx-2'>
                                <div className='col-6 staan-input-group pos_input_group'>
                                  <Select
                                    onInputChange={e => {
                                      getOptions(e, 'userdataOptionsQuery')
                                    }}
                                    isDisabled={
                                      status === 'Canceled'
                                        ? true
                                        : isSomeStockReduced
                                    }
                                    isClearable
                                    name='SalesPerson'
                                    value={salesPerson}
                                    options={userdata.map(option => ({
                                      value: option.id,
                                      label: option.username
                                    }))}
                                    onChange={option => {
                                      handleChangeSalesPerson(option)
                                      formikProps.setFieldValue(
                                        'SalesPerson',
                                        option?.value
                                      )
                                    }}
                                    className='mt-3'
                                    styles={posCustomSelectStyle}
                                  />
                                  <label
                                    htmlFor='SalesPerson'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    Sales Person
                                  </label>
                                  {formikProps.touched.SalesPerson &&
                                    formikProps.errors.SalesPerson && (
                                      <small className='staan-error-msg'>
                                        {formikProps.errors.SalesPerson}
                                      </small>
                                    )}
                                </div>
                                <div className='col-6 staan-input-group pos_input_group'>
                                  <Field
                                    type='text'
                                    name='State'
                                    value={State}
                                    disabled
                                    onChange={e => {
                                      handleStateChange(e)
                                      formikProps.setFieldValue(
                                        'State',
                                        e.target.value
                                      )
                                    }}
                                    placeholder=''
                                    className='w-100 staan-form-input'
                                  />
                                  <label
                                    htmlFor='State'
                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                  >
                                    State
                                  </label>
                                  {formikProps.touched.State &&
                                    formikProps.errors.State && (
                                      <small className='staan-error-msg'>
                                        {formikProps.errors.State}
                                      </small>
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                          {isSales && (
                            <>
                              <div
                                className='sample-customer-card pb-4'
                                style={{ overflow: 'scroll' }}
                              >
                                <div className='row py-1 mx-2'>
                                  <div className='col-12 staan-input-group'>
                                    <Select
                                      name='marketingEvent'
                                      isClearable
                                      options={conferenct?.map(con => ({
                                        value: con.id,
                                        label: con.name
                                      }))}
                                      isDisabled={
                                        status === 'Canceled'
                                          ? true
                                          : isSomeStockReduced
                                      }
                                      value={selectMarketingEvent}
                                      onChange={option => {
                                        handleMarketingEventChange(option)
                                        formikProps.setFieldValue(
                                          'marketingEvent',
                                          option?.value
                                        )
                                        initialValue['marketingEvent'] =
                                          option?.value
                                      }}
                                      className='mt-3'
                                      styles={posCustomSelectStyle}
                                      // posCustomSelectStyle
                                    />
                                    <label
                                      htmlFor='marketingEvent'
                                      className='staan-form-input-label pt-1 px-1 mx-2'
                                    >
                                      Marketing Event
                                      <span className='text-danger'>*</span>
                                    </label>
                                    {formikProps.touched.marketingEvent &&
                                      formikProps.errors.marketingEvent && (
                                        <small className='staan-error-msg'>
                                          {formikProps.errors.marketingEvent}
                                        </small>
                                      )}
                                  </div>
                                </div>
                                <div className='row  py-1 mx-2'>
                                  <div className='col-12 staan-input-group'>
                                    <Select
                                      options={storeOptions.map(item => ({
                                        value: item.id,
                                        label: item.storeName
                                      }))}
                                      isClearable
                                      isDisabled={
                                        status === 'Canceled'
                                          ? true
                                          : isSomeStockReduced
                                      }
                                      value={selectedStore}
                                      onChange={option => {
                                        handleSelectStore(option)
                                        formikProps.setFieldValue(
                                          'Store',
                                          option?.value
                                        )
                                      }}
                                      className='mt-3'
                                      styles={posCustomSelectStyle}
                                    />
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='staan-form-input-label pt-1 px-1 mx-2'
                                    >
                                      Store{' '}
                                      <span className='text-danger'>*</span>
                                    </label>
                                    {isSales && formikProps.errors.Store && (
                                      <small className='staan-error-msg'>
                                        {formikProps.errors.Store}
                                      </small>
                                    )}
                                    {formikProps.touched.Store &&
                                      formikProps.errors.Store && (
                                        <small className='staan-error-msg'>
                                          {formikProps.errors.Store}
                                        </small>
                                      )}
                                  </div>
                                </div>
                                <div className='row py-1 mx-2'>
                                  <div className='col-12 staan-input-group'>
                                    <Select
                                      onInputChange={e => {
                                        getOptions(e, 'SupplierOption')
                                      }}
                                      isDisabled={
                                        status === 'Canceled'
                                          ? true
                                          : isSomeStockReduced
                                      }
                                      value={selectcostomerName}
                                      options={supplierOptions.map(item => ({
                                        value: item.id,
                                        label: item.companyName,
                                        legalName: item.legalName
                                      }))}
                                      isClearable
                                      onChange={option => {
                                        handleSelectCostomerName(option)
                                        formikProps.setFieldValue(
                                          'CostomerName',
                                          option?.value
                                        )
                                        initialValue['CostomerName'] =
                                          option?.value
                                        setSelectLegalName(option?.legalName)
                                      }}
                                      className='mt-3'
                                      styles={posCustomSelectStyle}
                                    />
                                    <label
                                      htmlFor='Costomer'
                                      className='staan-form-input-label pt-1 px-1 mx-2'
                                      onClick={() => {
                                        console.log(formikProps.values)
                                      }}
                                    >
                                      Customer{' '}
                                      <span className='text-danger'>*</span>
                                      <i
                                        className='bx bx-plus-circle'
                                        onClick={() => {
                                          if (!isSomeStockReduced) {
                                            handleSupplierShow()
                                          }
                                        }}
                                      ></i>
                                    </label>
                                    {isSales &&
                                      formikProps.errors.CostomerName && (
                                        <small className='staan-error-msg'>
                                          {formikProps.errors.CostomerName}
                                        </small>
                                      )}
                                    {formikProps.touched.CostomerName &&
                                      formikProps.errors.CostomerName && (
                                        <small className='staan-error-msg'>
                                          {formikProps.errors.CostomerName}
                                        </small>
                                      )}
                                  </div>
                                </div>
                                <div className='row py-1 mx-2'>
                                  <div className='col-12  staan-input-group'>
                                    <Select
                                      value={selectBillingAddress}
                                      options={BillingAddresssOptions}
                                      isClearable
                                      isDisabled={
                                        status === 'Canceled'
                                          ? true
                                          : isSomeStockReduced
                                      }
                                      onChange={option => {
                                        handleSelectBillingAddress(option)
                                        formikProps.setFieldValue(
                                          'BillingAddress',
                                          option?.value
                                        )
                                        if (option.value) {
                                          onChangPosTypeChangeTaxForSales(
                                            option.State,
                                            false
                                          )
                                        }
                                      }}
                                      className='mt-3'
                                      styles={posCustomSelectStyle}
                                    />
                                    <label
                                      htmlFor='BillingAddress'
                                      className='staan-form-input-label pt-1 px-1 mx-2'
                                    >
                                      Billing Address{' '}
                                      <span className='text-danger'>*</span>
                                    </label>
                                    {isSales &&
                                      formikProps.errors.BillingAddress && (
                                        <small className='staan-error-msg'>
                                          {formikProps.errors.BillingAddress}
                                        </small>
                                      )}
                                    {formikProps.touched.BillingAddress &&
                                      formikProps.errors.BillingAddress && (
                                        <small className='staan-error-msg'>
                                          {formikProps.errors.BillingAddress}
                                        </small>
                                      )}
                                  </div>
                                </div>
                                <div className='row py-1 mx-2'>
                                  <div className='col-12 staan-input-group'>
                                    <Select
                                      value={selectDeliverAddress}
                                      options={DeliverAddressOptions}
                                      isClearable
                                      isDisabled={
                                        status === 'Canceled'
                                          ? true
                                          : isSomeStockReduced
                                      }
                                      onChange={option => {
                                        handleSelectDeliverAddress(option)
                                        formikProps.setFieldValue(
                                          'DeliverAddress',
                                          option?.value
                                        )
                                      }}
                                      className='mt-3'
                                      styles={posCustomSelectStyle}
                                    />
                                    <label
                                      htmlFor='DeliverAddress'
                                      className='staan-form-input-label pt-1 px-1 mx-2'
                                    >
                                      Delivery Address
                                    </label>
                                    {formikProps.touched.DeliverAddress &&
                                      formikProps.errors.DeliverAddress && (
                                        <small className='staan-error-msg'>
                                          {formikProps.errors.DeliverAddress}
                                        </small>
                                      )}
                                  </div>
                                </div>
                                <div className='row mx-2 my-1'>
                                  <div className='col-6 staan-input-group'>
                                    <Select
                                      isClearable
                                      name='contactPerson'
                                      isDisabled={
                                        status === 'Canceled'
                                          ? true
                                          : isSomeStockReduced
                                      }
                                      value={contactPerson}
                                      options={contactPersonOptions.map(
                                        item => ({
                                          label: item.contactPersonName,
                                          value: item.id,
                                          phone: item.phoneNumber
                                        })
                                      )}
                                      onChange={option => {
                                        handleChangeContactPerson(option)
                                        formikProps.setFieldValue(
                                          'contactPerson',
                                          option?.value
                                        )
                                      }}
                                      className='mt-3'
                                      styles={posCustomSelectStyle}
                                    />
                                    <label
                                      htmlFor='contactPerson'
                                      className='staan-form-input-label pt-1 px-1 mx-2'
                                    >
                                      Contact Person
                                    </label>
                                    {formikProps.touched.contactPerson &&
                                      formikProps.errors.contactPerson && (
                                        <small className='staan-error-msg'>
                                          {formikProps.errors.contactPerson}
                                        </small>
                                      )}
                                  </div>
                                  <div className='col-6 staan-input-group'>
                                    <Field
                                      type='p'
                                      name='Contace_Number'
                                      value={contactNumber}
                                      disabled
                                      className='w-100 staan-form-input'
                                    />
                                    <label
                                      htmlFor='Email'
                                      className='staan-form-input-label pt-2 px-1 mx-2'
                                    >
                                      Contace Number
                                    </label>
                                  </div>
                                </div>
                                <div className='row mx-2 my-1'>
                                  <div className='col-12 staan-input-group'>
                                    <Select
                                      isDisabled={
                                        status === 'Canceled'
                                          ? true
                                          : isSomeStockReduced
                                      }
                                      name='SalesPerson'
                                      value={salesPerson}
                                      isClearable
                                      options={userdata.map(option => ({
                                        value: option.id,
                                        label: option.username
                                      }))}
                                      onChange={option => {
                                        handleChangeSalesPerson(option)
                                        formikProps.setFieldValue(
                                          'SalesPerson',
                                          option?.value
                                        )
                                      }}
                                      className='mt-3'
                                      styles={posCustomSelectStyle}
                                    />
                                    <label
                                      htmlFor='SalesPerson'
                                      className='staan-form-input-label pt-1 px-1 mx-2'
                                    >
                                      Sales Person
                                    </label>
                                    {formikProps.touched.SalesPerson &&
                                      formikProps.errors.SalesPerson && (
                                        <small className='staan-error-msg'>
                                          {formikProps.errors.SalesPerson}
                                        </small>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          className='bill-details card mt-2 pt-2 pb-2'
                          style={{
                            fontSize: '.8rem',
                            height: isSales ? '31%' : '24%'
                          }}
                        >
                          <div className='row my-1 mx-1'>
                            <div className='col-5 fw-bold'>Total Amount</div>
                            <div className='col-1 fw-bold'>: </div>
                            <div className='col-1 fw-bold'>₹ </div>
                            <div className='col-4 text-end fw-bold'>
                              {totalBillAmount === 0 ? '0.00' : totalBillAmount}
                            </div>
                          </div>
                          <div className='row my-1 mx-1'>
                            <div
                              className='col-5 fw-bold text-danger '
                              onClick={() => {
                                if (status === 'Canceled') {
                                } else if (!isSomeStockReduced) {
                                  handleShowDisCountModel()
                                }
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              Discount
                            </div>
                            <div className='col-1 fw-bold'>: </div>
                            <div className='col-1 fw-bold'>₹ </div>
                            <div className='col-4 text-end fw-bold'>
                              {checkDiscount
                                ? Number(discountPercentageInValue)
                                  ? discountPercentageInValue
                                  : Number(discountValue)
                                  ? Number(discountValue).toFixed(2)
                                  : Number(discountTotalValue)
                                  ? isSales
                                    ? Number(
                                        totalDiscountValueForSales
                                      ).toFixed(2)
                                    : Number(
                                        totalBillAmount - discountTotalValue
                                      ).toFixed(2)
                                  : '0.00'
                                : '0.00'}
                            </div>
                          </div>
                          <div className='row my-1 mx-1' hidden={!isSales}>
                            <div className='col-5 fw-bold'>Before Tax</div>
                            <div className='col-1 fw-bold'>: </div>
                            <div className='col-1 fw-bold'>₹ </div>
                            <div className='col-4 text-end fw-bold'>
                              {Number(discountTotalValue) > 0
                                ? (
                                    Number(totalBillAmount) -
                                    Number(totalDiscountValueForSales)
                                  ).toFixed(2)
                                : Number(discountPercentageInValue) > 0
                                ? (
                                    Number(totalBillAmount) -
                                    Number(discountPercentageInValue)
                                  ).toFixed(2)
                                : Number(discountValue) > 0
                                ? (
                                    Number(totalBillAmount) -
                                    Number(discountValue)
                                  ).toFixed(2)
                                : Number(totalBillAmount).toFixed(2)}
                            </div>
                          </div>
                          <div className='row my-1 mx-1' hidden={!isSales}>
                            <div
                              className='col-5 fw-bold '
                              onClick={handleShowTaxModel}
                            >
                              Total Tax
                            </div>
                            <div className='col-1 fw-bold'>: </div>
                            <div className='col-1 fw-bold'>₹ </div>
                            <div className='col-4 text-end fw-bold'>
                              {totalTax === 0 ? '0.00' : totalTax}
                            </div>
                          </div>
                          <div className='row my-1 mx-1'>
                            <div className='col-5 fw-bold'>Round Off</div>
                            <div className='col-1 fw-bold'>: </div>
                            <div className='col-1 fw-bold'>₹ </div>
                            <div className='col-4 text-end fw-bold'>
                              {' '}
                              {RoundOffValue()}
                            </div>
                          </div>
                          <div className='row my-1 mx-1'>
                            <div
                              className='col-5 text-success fw-bold'
                              style={{ cursor: 'pointer' }}
                              onClick={handleOpenPaymentModal}
                            >
                              To Pay
                            </div>
                            <div className='col-1 fw-bold'>: </div>
                            <div className='col-1 fw-bold'>₹ </div>
                            <div className='col-4 text-end fw-bold'>
                              {toPay}
                            </div>
                          </div>
                          <div className='row my-1 mx-1'>
                            <div className='col-5  fw-bold  '>Received</div>
                            <div className='col-1 fw-bold'>: </div>
                            <div className='col-1 fw-bold'>₹ </div>
                            <div className='col-4 text-end fw-bold'>
                              {receivedAmount === 0
                                ? '0.00'
                                : Edit && receivedAmount !== undefined
                                ? Number(receivedAmount).toFixed(2)
                                : receivedAmount !== undefined
                                ? Number(receivedAmount).toFixed(2)
                                : '0.00'}
                            </div>
                          </div>
                          <div className='row my-1 mx-1'>
                            <div className='col-5  fw-bold  '>Balance</div>
                            <div className='col-1 fw-bold'>: </div>
                            <div className='col-1 fw-bold'>₹ </div>
                            <div className='col-4 text-end fw-bold'>
                              {TotalWithTaxValue && receivedAmount !== undefined
                                ? (
                                    roundToNearest10(TotalWithTaxValue) -
                                    receivedAmount
                                  ).toFixed(2)
                                : TotalValue && receivedAmount !== undefined
                                ? (
                                    roundToNearest10(TotalValue) -
                                    receivedAmount
                                  ).toFixed(2)
                                : '0.00'}
                            </div>
                          </div>
                        </div>
                        <div
                          className='action-details card mt-2'
                          style={{ height: '7%' }}
                        >
                          <div
                            className='d-flex justify-content-evenly align-items-center'
                            style={{ height: '100%' }}
                          >
                            <Can I={'Draft'} a={'POS'}>
                              <button
                                onClick={() => {
                                  formikProps.submitForm()
                                }}
                                type='button'
                                disabled={
                                  status === 'Canceled'
                                    ? true
                                    : isSomeStockReduced
                                    ? true
                                    : formikProps.isSubmitting
                                    ? true
                                    : false
                                }
                                className='btn   btn btn-outline-secondary    '
                                style={{ width: '8rem' }}
                              >
                                Draft
                              </button>
                            </Can>
                            <Can I={'Submit'} a={'POS'}>
                              <button
                                onClick={checkSubmit}
                                disabled={
                                  status === 'Save'
                                    ? false
                                    : status === 'Submited'
                                    ? true
                                    : isAllStockReduced
                                    ? true
                                    : true
                                }
                                className='ms-3  btn   btn-outline-success  '
                                type='button'
                                style={{ width: '8rem' }}
                              >
                                Submit
                              </button>
                            </Can>
                          </div>
                        </div>
                      </div>
                      <div
                        className='right-side card col-9 pt-1'
                        style={{ height: '100%' }}
                      >
                        <div className=''>
                          <div className='row' style={{ width: '100%' }}>
                            <div className='col-1 d-flex align-items-center pt-2 mt-2  '>
                              <label
                                htmlFor='scannedqr'
                                className='form-label fw-bold'
                              >
                                SCAN QR:
                              </label>
                            </div>
                            <div className='col-3 mt-2 ms-0 ps-0  '>
                              <>
                                <input
                                  disabled={
                                    status === 'Canceled'
                                      ? true
                                      : isSomeStockReduced
                                  }
                                  autoComplete={false}
                                  name='scannedqr'
                                  type='text'
                                  value={scannerValue}
                                  ref={inputRef}
                                  onChange={e => {
                                    handlescannerValueChange(e.target.value)
                                  }}
                                  // style={{backgroundColor:"green"}}
                                  className='w-100 form-control  bg-light'
                                />
                              </>
                            </div>
                            <div className='col-3 d-flex justify-content-between align-items-center'>
                              <p className=' mt-2 pt-2  '>Alt + S</p>
                              <p className='  mt-2 pt-2 fw-bold text-end'>
                                Total Qty: {totalBillingItem}
                              </p>
                            </div>
                            <div className='col-4 pe-4 text-end mt-2 '>
                              <button
                                type='button'
                                className='btn btn-outline-primary px-4'
                                disabled={
                                  status === 'Canceled'
                                    ? true
                                    : isSomeStockReduced
                                }
                                onClick={() => {
                                  if (isSales) {
                                    if (selectedStore.value) {
                                      if (selectcostomerName.value) {
                                        if (stateForSales) {
                                          handlepositemShow()
                                        }
                                      }
                                    }
                                  } else {
                                    if (selectedStore.value) {
                                      handlepositemShow()
                                    }
                                  }
                                }}
                              >
                                <i className='bx bxs-plus-circle'></i> Add Item
                              </button>
                            </div>
                            <div className='col-1 text-center mt-2'>
                              {posEdit ? (
                                <AiOutlinePrinter
                                  style={{ cursor: 'pointer' }}
                                  className='fs-3'
                                  onClick={() => {
                                    if (status === 'Canceled') {
                                    } else {
                                      handlePrint(posEdit)
                                    }
                                  }}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='mt-3'>
                          <div
                            className='ag-theme-alpine '
                            style={{ height: '40rem', width: '100%' }}
                          >
                            {!isDataLoading && (
                              <AgGridReact
                                ref={gridApiRef}
                                rowData={ItemDetaileData}
                                columnDefs={columns}
                                defaultColDef={defaultColDef}
                                components={components}
                                pagination={false}
                              />
                            )}
                          </div>
                          <div className='row'>
                            <div className='col-7 staan-input-group'>
                              <textarea
                                id='Tax'
                                className='w-100 staan-form-input-text-area'
                                disabled={
                                  status === 'Canceled'
                                    ? true
                                    : isSomeStockReduced
                                }
                                value={remarksText}
                                onChange={e => {
                                  setRemarksText(e.target.value)
                                }}
                                rows={2}
                              />
                              <label
                                htmlFor='Tax'
                                className='staan-form-input-label pt-2 px-1 mx-2'
                              >
                                Remarks
                              </label>
                            </div>
                            <div className='col-3 my-4'>
                              <div className='row'>
                                <div className='col-6'>
                                  <label
                                    className='form-check form-switch'
                                    style={{ fontSize: '.8rem' }}
                                  >
                                    Pending
                                  </label>
                                </div>
                                <div className='col-6 form-check form-switch'>
                                  <Field
                                    disabled={
                                      status === 'Canceled'
                                        ? true
                                        : isSomeStockReduced
                                    }
                                    type='checkbox'
                                    name='Pending'
                                    className='me-3 form-check-input'
                                    checked={pending === true ? true : false}
                                    onClick={() => {
                                      handlePending()
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='col-2 my-4 '>
                              <div>
                                <div className='row'>
                                  <div className='col-6'>
                                    <label style={{ fontSize: '.8rem' }}>
                                      Delivered
                                    </label>
                                  </div>
                                  <div className='col-6 form-check form-switch'>
                                    <Field
                                      disabled={
                                        status === 'Canceled'
                                          ? true
                                          : isSomeStockReduced
                                      }
                                      type='checkbox'
                                      name='isDelivered'
                                      className=' form-check-input'
                                      checked={
                                        isDelivered === true ? true : false
                                      }
                                      onClick={() => {
                                        handleDeliver()
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <SupplierFrom formikProps={formikProps} />
                      {isShowPaymentModal && (
                        <POSPayment
                          handlePaymentTransactionIds={ids_ => {
                            handlePaymentTransactionIds(ids_)
                          }}
                          isShowPaymentModal={isShowPaymentModal}
                          handleClosePaymentModal={handleClosePaymentModal}
                          totalAmount={toPay}
                          data={paymentTransIds}
                          isPaymentAdd={isPaymentAdd}
                          handleCurrentPendingBalance={(amount, rec_amt) => {
                            handleCurrentPendingBalance(amount, rec_amt)
                          }}
                          isSales={isSales}
                          handlePosTypeChangeError={err_msg => {
                            handlePosTypeChangeError(err_msg)
                          }}
                          initialValue={initialValue}
                          status={status}
                          formikProps={formikProps}
                        />
                      )}
                    </Form>
                  </>
                )
              }}
            </Formik>
          </div>
        </div>
      </Modal>

      <PosItemDetails
        SaveTheValuesbatch={(params, getGstRate) => {
          SaveTheValuesbatch(params, getGstRate)
        }}
        SaveSerialNumber={(params, getGstRate) => {
          SaveSerialNumber(params, getGstRate)
        }}
        SaveNoneBs={(params, getGstRate) => {
          SaveNoneBs(params, getGstRate)
        }}
        positemAdd={positemAdd}
        handlepositemclose={handlepositemclose}
        setPosItemData={setPosItemData}
        posItemData={posItemData}
        posItemEdit={posItemEdit}
        setPosItemEdit={setPosItemEdit}
        selectPostype={isSales}
        ItemDetaileData={ItemDetaileData}
        selectedStore={selectedStore}
        togettax={togettax}
        discountPercentageInValue={discountPercentageInValue}
        discountValue={discountValue}
        discountTotalValue={discountTotalValue}
        clearAllDiscount={clearAllDiscount}
        isDataSave={isDataSave}
        setisDataSave={setisDataSave}
      />
      {deleteData && (
        <DeleteConfirmModal
          isShowDeleteConfirmationModal={isShowDeleteConfirmationModal}
          handleCloseDeleteConfirmationModal={
            handleCloseDeleteConfirmationModal
          }
          deleteData={deleteData}
          url={url}
          handleDeletedData={data => {
            handleDeletedData(data)
          }}
        />
      )}

      {isShowStockStatementModal && (
        <StockStatementDetails
          isShowSelectedStockStatement={isShowStockStatementModal}
          handleCloseSelectedStockStatement={handleCloseStockStatementModal}
          stockStatement={selectedStockStatementDetails}
          part_id={selectedPartNo}
          from={'pos'}
        />
      )}
      {waringToClearStoreModel && (
        <WaringModel
          state={waringToClearStoreModel}
          setstate={setWaringToClearStoreModel}
          title={'Confirm'}
          text={'This action will clear item data'}
          Confirmtion={value => {
            DeletePosItemData(value)
          }}
        />
      )}
      {waringModel && (
        <WaringModel
          state={waringModel}
          setstate={setWaringModel}
          title={'Confirm'}
          text={'Confirm to cancel'}
          Confirmtion={value => {
            permissionToStockAddtion(value)
          }}
        />
      )}
      {showStockNeed && (
        <ShowStockNeed
          showStockNeed={showStockNeed}
          setShowStockNeed={setShowStockNeed}
          showStockNeedList={showStockNeedList}
        />
      )}
      {showComboUnSaved && (
        <ShowUnsaveditemcombo
          showComboUnSaved={showComboUnSaved}
          setshowComboUnSaved={setshowComboUnSaved}
          itemComboUnSavedList={itemComboUnSavedList}
        />
      )}
    </>
  )
}

export default PosPage
