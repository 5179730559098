import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TbHistory } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { storeCreate, storeEdit } from '../../../GraphQLQueries/ItemMastermutation';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import axiosInstance from '../../../api/axoiss';
import Can from "../../../components/authorizationComponents/Can";
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import DataContext from '../../../context/ItemMasterContext';
import "../../../style.css";
import { ShowErrorNoties, checkChangesInputs, customSelectStyle, removeEmptyValueInObject } from '../../../utils/Utils';
import { StoreSchema } from '../../../validations/itemmaster';
import HistoryData from '../../ItemMaster/model/HistoryData';

const StoreAdd = ({storeEditId,setstoreEditId, setLoad}) => {
    const {  storeAdd, handleStoreAddClose, handltoastDeleteConfomationShow, 
    userId, historyIds, setHistoryIds} = useContext(DataContext)
   
    const [edit, setEdit]= useState(true)
    const [deleteData, setdeleteData] = useState({  Name:"",id:""})
    const [selectAccount , setSelectAccount] = useState()
    const url = "/itemmaster/Store"
    const dispatch = useDispatch();     
    const OptionsDataList = useSelector(state => state.itemGroup.data);
    const handleSelectAccount =(option)=>{
        setSelectAccount( { value: option.value , label:  option.label });
    }
    const [selectInCharge , setSelectInCharge] = useState()
    const handleSelectCharge =(option)=>{
        if (option){
          setSelectInCharge( { value: option.value , label:  option.label });
        } else{
          setSelectInCharge( { value: "" , label: "" });
        }
        
    }
    const [initialStore, setinitialStore] = useState({
        "id"  : "",
        "StoreName": "",
        "matained": true,
        "Action": true,
        "StoreAccount": '',
        "StoreInCharge": '', 
        "createdby": null,
        "modifiedBy" : null,
        "HistoryDetails":"",
        "conference": false,
    })

    const handledeleteConfomation = (data) => {
       
      handltoastDeleteConfomationShow();
      
      setdeleteData({
       Name:data.StoreName,
       id: data.id
      }); 
  
    }
    function reset_form(){  
      setSelectAccount({value:"" , label:  "" });
      setSelectInCharge({value:"" , label:  "" }); 
    }
    async function getStore(id) { 
      try {  
        const response = await axiosInstance.post("/itemmaster/graphql", { query: storeEdit(id) });
        const responseData = response.data.data.Store.items[0];  
        return responseData;
      } catch (error) {  
        return null; // Return null in case of error
      }
     
    }
    let selectedStore ;
    async function fetchData(id) {
      try {  
        selectedStore = await getStore(id);  
        if (selectedStore) {   
          initialStore.id = selectedStore['id']
          initialStore.StoreName = selectedStore['storeName'];
          initialStore.StoreAccount = selectedStore['storeAccount']?.['id']
          initialStore.StoreInCharge = selectedStore['storeIncharge']?.['id']
          initialStore.matained = selectedStore['matained'];
          initialStore.Action = selectedStore['action'];
          initialStore.conference = selectedStore['conference'];
          initialStore.createdby = selectedStore['createdBy']?.['id'];
          initialStore.HistoryDetails = selectedStore['historyDetails'];
          setSelectAccount({ value: selectedStore?.['storeAccount']?.['id'], label: selectedStore?.['storeAccount']?.['accountsName'] });
          setSelectInCharge({ value: selectedStore?.['storeIncharge']?.['id'], label: selectedStore?.['storeIncharge']?.['username'] });
          setFormKey((prevKey) => prevKey + 1);  
        }
      } catch (error) {
        let errorData = ShowErrorNoties(error) 
        toast.error( errorData , {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          }); 
      }
    }
   
    useEffect(() => {
  
    
      if (storeEditId) {  
        fetchData(storeEditId);
      }
    }, [storeEditId]);

  
    useEffect(() => {  
      
      if (storeEditId === "") {
        setinitialStore({
          "StoreName": "",
          "matained": true,
          "Action": true,
          "StoreAccount": '',
          "StoreInCharge": '',
          "conference": false,
        }) 
        setEdit(false)
      }else{ 
        setEdit(true)
      }
    }, [storeEditId]); 

    if (storeEditId === "storeEditId"){
      setinitialStore(
        {
          "StoreName": "",
          "matained": true,
          "Action": true,
          "StoreAccount": '',
          "StoreInCharge": '',
          "conference": false,
    }
      )
    } 

    const handlesubmit = async (values, { setSubmitting,   setErrors, resetForm })=>{ 
        const SaveStore ={
            "id":storeEditId ? storeEditId : "",
            "storeName": values['StoreName'],
            "storeAccount": Number(values['StoreAccount']),
            "storeIncharge": values['StoreInCharge'] ? Number(values['StoreInCharge']) : "",
            "matained": values['matained'],
            "action": values['Action'],
            "modifiedBy": storeEditId ? Number(userId) : "",
            "createdBy": storeEditId ? Number(initialStore.createdby) : Number(userId),
            "conference": values['conference']
        }  
        let allow_null = ['id',"modifiedBy", "storeIncharge"]
        let variable = removeEmptyValueInObject(SaveStore, allow_null) 
        if(storeEditId){ 
          try{
            const response = await axiosInstance.post("/itemmaster/graphql",  {query: storeCreate(variable)}) 
            if (response.data.data.StoreCreateMutations.success) {
              setstoreEditId('')
              handleStoreAddClose() 
              reset_form()
              setLoad()
              setSubmitting(false);
              toast.success('Successfully Updated', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
            } else{
              let errorData = ShowErrorNoties(response.data.data.StoreCreateMutations.errors) 
              toast.error( errorData , {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                }); 
            } 
          } catch(error){  
            let errorData = ShowErrorNoties(error)
            toast.error( errorData , {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              });
          }
           
        }else{ 
            try{
              const response = await axiosInstance.post("/itemmaster/graphql",  {query: storeCreate(variable)}) 
              if (response.data.data.StoreCreateMutations.success) {
                handleStoreAddClose()
                setstoreEditId('')
                reset_form()
                setLoad()
                toast.success('Successfully Saved', {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Bounce,
                  });
              } else{ 
                let errorData = ShowErrorNoties(response.data.data.StoreCreateMutations.errors)
                toast.error( errorData , {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Bounce,
                  }); 
              }
               
            } catch(error){
                let errorData = ShowErrorNoties(error) 
                toast.error( errorData , {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Bounce,
                  }); 
            }  
        }
        
    }

    // clear confomations
    const [waringToCloseModel, setWaringToCloseModel] = useState(false)
    const handleCloseWaring=()=>{
      setWaringToCloseModel(false)
    }
    const handleShowWaring =()=>{
      setWaringToCloseModel(true)
    }

    const [formKey, setFormKey] = useState(0);
    let currentValues = {};
    const WaringToClose = ()=>{
      const cleaeTheData=()=>{
            handleStoreAddClose()
            setstoreEditId('')
            reset_form()
            handleCloseWaring()
      }

      return(
        <>
      
          <Modal
              show={waringToCloseModel}
              onHide={handleCloseWaring}
              backdrop="static"
              keyboard={false}
              enableReinitialize={true}
              key={formKey}
          >
        <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Do you want to leave without saving?
              </Modal.Body>
              <Modal.Footer>
              <button type="button" className="btn btn-outline-warning btn-sm" onClick={handleCloseWaring}>No</button>
              <button type="button" className="btn btn-outline-success btn-sm" onClick={cleaeTheData}>Yes</button>
              </Modal.Footer>
      </Modal>
        
        </>
      )
    } 

    function Close() {
      
      let isDuplicate =  checkChangesInputs([initialStore, currentValues])
      if(!isDuplicate){
        handleStoreAddClose()
        setstoreEditId('')
        reset_form()
        setEdit(false)
      } else{
        handleShowWaring()
        
      }
      
    }

    const handleDeletedId = (deleted_id) =>{ 
      if (deleted_id){
        handleStoreAddClose()
        setstoreEditId('')
        reset_form()
        setEdit(false)
        setLoad()
      } 
    }
    const [accountOptions, setAccountOptions] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const getOption =(e, query, name)=>{
      let search_term = e.trim().replace(/"/g, '\\"');
      if (query=== "accountsMasterOptionQuery" && search_term){
        
        dispatch(fetchOptionsData(QueryData.accountsMasterOptionQuery(search_term), "Account"),"PurchaseAccount")
      } else{
        setAccountOptions([])
      }
      if (query === "userOptionsQuery" && search_term) {
        
        dispatch(fetchOptionsData(QueryData.userdataOptionsQuery(search_term),"userdata"))
      } else{
        setUserOptions([])
      }

    }

    useEffect(()=>{  
      if (OptionsDataList.name === "Account"){  
        setAccountOptions(OptionsDataList.value.items)
      } else{
        setAccountOptions([])
      }  if (OptionsDataList.name === "userdata"){  
        setUserOptions(OptionsDataList.value.items)
      } else{
        setUserOptions([])
      }   
   },[OptionsDataList])
  return (
     <>
       {WaringToClose()}
       <HistoryData
        historyIds = {historyIds}
        setHistoryIds= {setHistoryIds}
       />
       <ToastContainer/>
      <Modal   size="lg"  show={storeAdd} onHide={Close}  
        backdrop="static"
        keyboard={false}>
        <div  className="row">
          <div className="col-5 m-3" style={{color:"#5CADA9"}}> 
          <Modal.Title>
            <i className='fa-solid fa-arrow-left fa-sm me-2'  
                onClick={() => {handleStoreAddClose();
                  setstoreEditId('');
                  reset_form()}}
            ></i>Store Add</Modal.Title></div>
          <div className="col-6 mt-4 text-end" > 
            <Can I={"Edit"} a={"Store"}>
              <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={!(storeEditId && edit)} onClick={()=>{setEdit(!edit)}}  ></i>
            </Can>
            <Can I={"Delete"} a={"Store"}>
              <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " hidden={!(storeEditId && edit)} onClick={()=>{handledeleteConfomation(initialStore)}} ></i> 
            </Can>
            <Can I={"Historys"} a={"Store"}>
               <TbHistory type="button" className='fs-4 ms-3' data-bs-toggle="offcanvas" hidden={!(storeEditId && edit)} data-bs-target="#History" onClick={()=>{setHistoryIds(initialStore.HistoryDetails)}}/>
            </Can>
            
          </div>
          <hr/>
        </div>
        <Modal.Body>
         <Formik initialValues={initialStore} 
         onSubmit={handlesubmit}
         validationSchema={StoreSchema}>
         {({errors, touched, isSubmitting, values, setFieldValue , Formik, formikProps })=> {
               currentValues = values;
          return(
            <Form>
              <div>
                <div className='row mx-2'>
                    <div className="col-6 staan-input-group">
                          <Field type='text' id='StoreName' name='StoreName' disabled= {storeEditId && edit}
                            className='w-100 staan-form-input'/>
                          <label htmlFor="StoreName" className='staan-form-input-label pt-2 px-1 mx-2'>Name</label>
                          {  touched.StoreName &&  errors.StoreName && <small>{errors.StoreName}</small>}
                    </div>
                    <div className="col-6 staan-input-group">
                      <Select
                            onInputChange={(e)=>{getOption(e, "accountsMasterOptionQuery")}}
                            name="Account"
                            options={accountOptions.map((item) => ({ value: item.id, label: item.accountsName }))}
                            isSearchable={true} 
                            isDisabled={storeEditId && edit}
                            value={selectAccount}
                            onChange={(option) => {
                              setFieldValue('StoreAccount', option ? option.value : null);
                            handleSelectAccount(option)} }
                            styles={customSelectStyle}
                            className='my-3'
                      />
                      <label htmlFor="Account" className='staan-form-input-label pt-1 px-1 mx-2'>Account</label>
                      {  touched.StoreAccount &&  errors.StoreAccount && <small>{errors.StoreAccount}</small>}
                    </div>
                </div>
                <div className='row mx-2'>
                    <div className="col-6 staan-input-group">
                          <Select
                                onInputChange={(e)=>{getOption(e, "userOptionsQuery")}}
                                name="StoreInCharge"
                                options={userOptions.map((item) => ({ value: item.id, label: item.username }))}
                                isSearchable={true} 
                                isDisabled={storeEditId && edit}
                                value={selectInCharge}
                                isClearable
                                onChange={(option) => {setFieldValue('StoreInCharge', option ? option.value : "");
                                handleSelectCharge(option)} }
                                styles={customSelectStyle}
                                className='my-3'
                          />
                          <label htmlFor="Store In Charge" className='staan-form-input-label pt-1 px-1 mx-2'>Store InCharge</label>
                          {  touched.StoreInCharge &&  errors.StoreInCharge && <small>{errors.StoreInCharge}</small>}
                    </div>
                    <div className='col-6 row d-flex align-items-center'>
                      <div className="col-4">
                          <Field type="checkbox" name="matained"  disabled={storeEditId ? true : false} className='me-2'/>
                          <label>Keep Stock</label>
                      </div>
                      <div className="col-4">
                          <Field type="checkbox" name="conference"  disabled={storeEditId && edit} className='me-2'/>
                          <label>Conference</label>
                      </div>
                      <div className="col-4 text-end">
                          <Field type="checkbox" name="Action" disabled={storeEditId && edit}  className='me-2'/>
                          <label>Active</label>
                      </div>
                    </div>
                </div>
              </div>
            <Modal.Footer>
                <Button variant="outline-danger" size='sm' onClick={()=>{handleStoreAddClose();
                   setstoreEditId('');
                   reset_form()}}>
                    Cancel
                </Button>
                <Can allow_list={["Save_Store","Edit_Store"]}>
                  <Button type='submit' className='mx-2 px-3' disabled ={isSubmitting}  size='sm' variant="outline-success" >
                      Save
                  </Button>
                </Can>
                
            </Modal.Footer>
         </Form>
          )
         }}
         </Formik>
        </Modal.Body>
      </Modal>
      
      {deleteData && <DeleteConformation 
      handleDeletedId = {(id_)=>{handleDeletedId(id_)}}
        deleteData= {deleteData}
        url={url}/> } 
     </>
  )
}

export default StoreAdd