import { useEffect, useContext, useState} from "react";
import PoFg from "./PoFg";
import PoOtherCharges from "./PoOtherCharges";
import PoRawMaterils from "./PoRawMaterials";
import PoRouting from "./PoRouting";
import PoScrap from "./PoScrap";
import PoSubProductionOrder from "./PoSubProductionOrder";
import PoComboFg from "./PoComboFg";
import ProductionContext from "../../../context/ProductionContext";
import { ToastContainer, toast } from "react-toastify";
import MRConfirmation from "../../MaterialRequest/comps/MRConfirmation";


const PoDetails = ({itemDetail}) => {
    const {manufacturingRawMaterialData, subcontractRawMaterialData} = useContext(ProductionContext);
    const [showMRConfirmation, setShowMRConfirmation] = useState(false);

    const handleMaterialRequest = () => {
        // console.log('material request clicked!');
        // console.log(manufacturingRawMaterialData, "manufacturingRawMaterialData");
        // console.log(subcontractRawMaterialData, "subcontractRawMaterialData");
        if(manufacturingRawMaterialData?.length > 0 || subcontractRawMaterialData?.length > 0){
            setShowMRConfirmation(true)
            // let is_supplier_not_selected = subcontractRawMaterialData?.find(sub_rm => sub_rm.selectedSupplier === '--Select Supplier--');
            // if(is_supplier_not_selected){
            //     toast.error('Please Select All Suppliers in Sub-Contract Raw Materials!', {
            //         position: "bottom-right",
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //         theme: "light",
            //     });
            // }else{
            //     setShowMRConfirmation(true)
            // }
        }else{
            toast.error('No Raw Materials listed!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const handleCloseMaterialRequestConfirmation = () => {
        setShowMRConfirmation(false);
    }

    useEffect(()=>{
        console.log(itemDetail);
    }, [itemDetail])
    return (
        <>
            <ToastContainer />
            <div className="px-3">
                <div className="row">
                        <div className="col-4">
                            <p>Status: {itemDetail?.poItemDetail?.status?.name}</p>
                        </div>
                        <div className="col-4">
                            <p>Created By: {itemDetail?.poMaster?.createdBy?.username}</p>
                        </div>
                        <div className="col-4">
                            <p>Last Modified Date: {itemDetail?.poMaster?.orderDate}</p>
                        </div>
                </div>
                <div className="my-3 d-flex justify-content-between">
                    <div style={{width: '49%'}} className="card border border-light py-2 pt-4">
                        <div className="row mx-2 my-2">
                            <div className="col-6 staan-input-group">
                                <input type='text' id='orderNo' name='orderNo'  
                                    value={itemDetail?.poMaster?.orderNo} 
                                    className='w-100 staan-form-input'
                                    disabled ={true}
                                /> 
                                <label htmlFor="orderNo" className='staan-form-input-label pt-1 px-1 mx-2'>MO No</label>
                            </div>
                            <div className="col-6 staan-input-group">
                                <input type='text' id='orderDate' name='orderDate'  
                                    value={itemDetail?.poMaster?.orderDate} 
                                    className='w-100 staan-form-input'
                                    disabled ={true}
                                /> 
                                <label htmlFor="orderDate" className='staan-form-input-label pt-1 px-1 mx-2'>MO Date</label>
                            </div>
                        </div>
                        <div className="row mx-2 my-2">
                            <div className="col-6 staan-input-group">
                                <input type='text' id='bomNo' name='bomNo'  
                                    value={itemDetail?.poItem?.bom?.bomNo} 
                                    className='w-100 staan-form-input'
                                    disabled ={true}
                                /> 
                                <label htmlFor="bomNo" className='staan-form-input-label pt-1 px-1 mx-2'>BOM No</label>
                            </div>
                            <div className="col-6 staan-input-group">
                                <input type='text' id='dueDate' name='dueDate'  
                                    value={itemDetail?.poMaster?.orderDate} 
                                    className='w-100 staan-form-input'
                                    disabled ={true}
                                /> 
                                <label htmlFor="dueDate" className='staan-form-input-label pt-1 px-1 mx-2'>Due Date</label>
                            </div>
                        </div>
                        <div className="row mx-2 my-2">
                            <div className="col-6 staan-input-group">
                                <input type='text' id='bomName' name='bomName'  
                                    value={itemDetail?.poItem?.bom?.bomName} 
                                    className='w-100 staan-form-input'
                                    disabled ={true}
                                /> 
                                <label htmlFor="bomName" className='staan-form-input-label pt-1 px-1 mx-2'>BOM Name</label>
                            </div>
                            <div className="col-6 staan-input-group">
                                <input type='text' id='fgStore' name='fgStore'  
                                    value={itemDetail?.poItem?.bom?.fgStore.storeName} 
                                    className='w-100 staan-form-input'
                                    disabled ={true}
                                /> 
                                <label htmlFor="fgStore" className='staan-form-input-label pt-1 px-1 mx-2'>Finished Store</label>
                            </div>  
                        </div>
                        <div className="row mx-2 my-2">
                            <div className="col-6 staan-input-group">
                                <input type='text' id='bomType' name='bomType'  
                                    value={itemDetail?.poItem?.bom?.bomType} 
                                    className='w-100 staan-form-input'
                                    disabled ={true}
                                /> 
                                <label htmlFor="bomType" className='staan-form-input-label pt-1 px-1 mx-2'>BOM Type</label>
                            </div>
                            <div className="col-6 staan-input-group">
                                <input type='text' id='scrapStore' name='scrapStore'  
                                    value={itemDetail?.poItem?.bom?.scrapStore?.storeName} 
                                    className='w-100 staan-form-input'
                                    disabled ={true}
                                /> 
                                <label htmlFor="scrapStore" className='staan-form-input-label pt-1 px-1 mx-2'>Scrap / Reject Store</label>
                            </div>
                        </div>
                    </div>
                    <div style={{width: '49%'}} className="card border border-light py-2 pt-4">
                        <div className="row mx-2 my-2">
                            <div className="col-6 staan-input-group">
                                <input type='text' id='salesOrderNo' name='salesOrderNo'  
                                    className='w-100 staan-form-input'
                                    disabled ={true}
                                /> 
                                <label htmlFor="salesOrderNo" className='staan-form-input-label pt-1 px-1 mx-2'>Sales Order No</label>
                            </div>
                            <div className="col-6 staan-input-group">
                                <input type='text' id='devOrderNo' name='devOrderNo'  
                                    className='w-100 staan-form-input'
                                    disabled ={true}
                                /> 
                                <label htmlFor="devOrderNo" className='staan-form-input-label pt-1 px-1 mx-2'>Development Order No</label>
                            </div>
                        </div>
                        <div className="row mx-2 my-2">
                            <div className="col-6 staan-input-group">
                                <input type='text' id='department' name='department'  
                                    className='w-100 staan-form-input'
                                    disabled ={true}
                                /> 
                                <label htmlFor="department" className='staan-form-input-label pt-1 px-1 mx-2'>Department</label>
                            </div>
                        </div>
                        <div className="row mx-2 my-2">
                            <div className="col-12 staan-input-group">
                                <textarea className="staan-form-input-text-area" name='remarks' id="textarea" 
                                ></textarea>
                                <label htmlFor="remarks" className='staan-form-input-label pt-2 px-1 mx-2 text-muted'>Remarks</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-5 card border border-light p-3">
                    <h6 style={{color: '#00B2EE'}}>Finished Goods</h6>
                    <div>
                        <PoFg 
                            fgData = {itemDetail?.poItemDetail?.finishedGoods}
                            poData = {itemDetail?.poItem}
                        />
                    </div>
                </div>
                { itemDetail?.poItem?.isCombo && <div className="my-5 card border border-light p-3">
                    <h6 style={{color: '#00B2EE'}}>Sub Manufacturing Orders - Combo Items</h6>
                    <div>
                        <PoComboFg 
                            fgData = {itemDetail?.poItemDetail?.subProductionOrders}
                            poData = {itemDetail?.poItem}
                        />
                    </div>
                </div>}
                <div className="my-5 card border border-light p-3">
                    <h6 style={{color: '#00B2EE'}}>Sub Manufacturing Orders - In-House</h6>
                    <div>
                        <PoSubProductionOrder 
                            data = {itemDetail?.poItemDetail?.subProductionOrders}
                            po_number = {itemDetail?.poMaster?.orderNo}
                            type="MANUFACTURE"
                            detail = {itemDetail?.poItemDetail?.id}
                        />
                    </div>
                </div>
                <div className="my-5 card border border-light p-3">
                    <h6  style={{color: '#00B2EE'}}>Sub Manufacturing Orders - Sub-Contract</h6>
                    <div>
                        <PoSubProductionOrder 
                            data = {itemDetail?.poItemDetail?.subProductionOrders}
                            po_number = {itemDetail?.poMaster?.orderNo}
                            type="SUBCONTRACT"
                            detail = {itemDetail?.poItemDetail?.id}
                        />
                    </div>
                </div>
                <div className="my-5 card border border-light p-3">
                    <h6 style={{color: '#00B2EE'}}>Raw Materials - In-House</h6>
                    <div>
                        <PoRawMaterils
                            data = {itemDetail?.poItemDetail?.rawMaterial}
                            masterData = {itemDetail?.poItemDetail}
                            po_number = {itemDetail?.poMaster?.orderNo}
                            type="MANUFACTURE"
                            detail = {itemDetail?.poItemDetail?.id}
                        />
                    </div>
                </div>
                <div className="my-5 card border border-light p-3">
                    <h6 style={{color: '#00B2EE'}}>Raw Materials - Sub-Contract</h6>
                    <div>
                        <PoRawMaterils 
                            data = {itemDetail?.poItemDetail?.rawMaterial}
                            masterData = {itemDetail?.poItemDetail}
                            po_number = {itemDetail?.poMaster?.orderNo}
                            type="SUBCONTRACT"
                            detail = {itemDetail?.poItemDetail?.id}
                        />
                    </div>
                </div>
                <div className="my-5 card border border-light p-3">
                    <h6 style={{color: '#00B2EE'}}>Scrap / Reject</h6>
                    <div>
                        <PoScrap 
                            data = {itemDetail?.poItemDetail?.scrap}
                        />
                    </div>
                </div>
                <div className="my-5 card border border-light p-3">
                    <h6 style={{color: '#00B2EE'}}>Process</h6>
                    <div>
                        <PoRouting 
                            data = {itemDetail?.poItemDetail?.routes} 
                            master_qty={itemDetail?.poItem?.qty}
                        />
                    </div>
                </div>
                <div className="my-5 card border border-light p-3">
                    <h6 style={{color: '#00B2EE'}}>Other Charges</h6>
                    <div>
                        <PoOtherCharges 
                            data = {itemDetail?.poItemDetail?.otherCharges}
                        />
                    </div>
                </div>
                <div className="mx-auto" style={{width: '50%'}}>
                    <div className="d-flex justify-content-around my-4 mx-1" >
                        <button className="btn btn-sm btn-outline-primary" onClick={handleMaterialRequest}>Material Request</button>
                        {/* <button className="btn btn-sm btn-outline-primary">Work Order</button> */}
                        <button className="btn btn-sm btn-outline-primary">Sub Contract</button>
                        <button className="btn btn-sm btn-outline-primary">Complete</button>
                    </div>
                </div>
            </div>
            {showMRConfirmation && 
                <MRConfirmation 
                    show = {showMRConfirmation}
                    close = {handleCloseMaterialRequestConfirmation}
                    itemDetail = {itemDetail}
                />
            }
        </>
    )
}

export default PoDetails;