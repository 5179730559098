import React, { useState} from 'react' 
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import TableView from '../../Packages/Custom/DataTable';
import AddMR from './comps/AddMR';

const MaterialRequest = () => {
    const [isLoad, setLoad] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);

    const handleShowAddModal = () => {
        setShowAddModal(true)
    }

    const handleCloseAddModal = () => {
        setShowAddModal(false)
        setCurrentData(null)
        setLoad(!isLoad)
    }

    const CustomAddModalComponent = () => {
        handleShowAddModal()
        setLoad(!isLoad)
    }

    const CustomViewModalComponent = (data) => {
        setCurrentData(data);
        handleShowAddModal();
    }

    const mr_column_details = [
        {
            header: 'ID',
            field: 'id',
            flex: 5,
            inputType: 'number',
            align: 'center',
        },
        {
            header: 'Request No',
            field: 'requestNo',
            flex: 8,
            inputType: 'text',
            renderComponent: CustomViewModalComponent,
        },
        {
            header: 'Request Date',
            field: 'requestDate',
            flex: 8,
            inputType: 'text',
        },
        {
            header: 'MR For',
            field: 'requestFor',
            label: 'name',
            flex: 8,
            inputType: 'text',
        },
        {
            header: 'Status',
            field: 'status',
            label: 'name',
            flex: 5,
            inputType: 'text',
        },
        {
            header: 'Store',
            field: 'issuingStore',
            label: 'storeName',
            flex: 12,
            inputType: 'text',
        },
        {
            header: 'Last Modified Date',
            field: 'updatedAt',
            flex: 5,
            inputType: 'text',
            isDateField: true,
        },
        {
            header: 'Last Modified By',
            field: 'modifiedBy',
            label: 'username',
            flex: 7,
            inputType: 'text',
        },
    ]

    return (
        <>
            <SideNavbar />
            <div id="custom-table-itemmaster">
                <TableView 
                    headers = {mr_column_details}
                    query = {'MaterialRequestMasterFetchQuery'}
                    addComponent = {CustomAddModalComponent}
                    loadComponent = {isLoad}
                    titleName = {'Material Request'}
                    heightAdjust = {'40rem'} 
                />
            </div>

            { showAddModal && <>
                <AddMR 
                    show = {showAddModal}
                    close = {handleCloseAddModal}
                    data = {currentData}
                />
            </>}
        </>
    )
}

export default MaterialRequest;