export const leadsCreateMutation = data => {
  // createdBy: 3, enquiryId: 172, id: null, note: "test note "
  return `
    mutation MyMutation {
  leadsCreateMutation(${data}) {
    success
    errors
        LeadsInstance {
      createdBy {
        id
      }
      expectedClosingDate
      id
      status{
        id
        name
      }
      leadName
      leadNo
      leadValue
      requirement
      customer{
        id
        companyName
      }
      leadCurrency{
        id
        Currency{
          id
          name
        }
      }
      salesPerson{
        id
        username
      }
      Enquiry{
        id
        conferenceData{
          name
        }
        linkContactDetalis {
          contactPersonName
          email
          phoneNumber
          whatsappNo
        }
      }
    }
  }
}`
}

export const leadDeleteMutations = id => {
  return `
    mutation MyMutation {
  leadsDeleteMutation(id:  ${id}) {
    errors
    success
  }
}
  `
}
