import React, { useState } from 'react' 
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { customSelectStyle } from '../Packages/Custom/TableUtils/TableUtils';
import Select from 'react-select';
import { commanFetch } from '../api/axoiss';
import {SupplierOptionPerameter } from '../GraphQLQueries/ItemmasterQuery';

const TransportDetails = ({show, setShow, passValueToSubmit, transportData , setTransportData}) => {
    let TranSportDetailsOptionList = [
        {label : "Own Vehicle", value: 'Own Vehicle'},
        {label : "Transport", value: 'Transport'},
        {label : "Courier", value: 'Courier'},
        {label : "Other Mode", value: 'Other Mode'},
    ]
    
    const [transporterList, setTransporterList] =  useState([])
    const [transporterSelected, setTransporterSelected] =  useState([]) 

    async function fetchSupplier(e) {
        let search_term = e.trim().replace(/"/g, '\\"');
        if (search_term){
            let queryWithPerementr = SupplierOptionPerameter(`companyName: "${search_term}", transporter : true`)
        try{
            const response = await commanFetch(queryWithPerementr);
            const reponseData = response?.data?.items
            if (response.success) {  
                let changeTransportFormate = reponseData?.map((item)=>({value: item?.id, label: item?.companyName,}))
                setTransporterList(changeTransportFormate)
            } else {
                console.error("Error fetching data:", response.error);
            }
        } catch(error){
            console.error("Error fetching data:", error);
        }
        } else{
            setTransporterList([])
        }
       
        
    }
        
    function Onsubmit(params) {
        if (transportData?.model && transportData?.model.label === "Own Vehicle") {
            if (transportData?.Vehicle_No && transportData?.Driver_Name) {
                 if (passValueToSubmit){
                    passValueToSubmit(transportData)
                    setShow(false)
                 }
            } else {
                console.error("Vehicle_No or Driver_Name is missing.");
            }
        } else if (transportData?.model && transportData?.model?.label === "Transport") {
            if (transportData?.vehicle_no && transporterSelected?.value) {
                passValueToSubmit(transportData, transporterSelected?.value)
                setShow(false)
            } else {
                console.error("Vehicle No or Transporter Name is missing.");
            }
        } else if (transportData.model && transportData.model.label === "Courier") { 
            if (transportData?.Docket_No && transportData.Docket_Date) {
                passValueToSubmit(transportData)
                setShow(false)
            } else {
                console.error("Docket_No or Docket_Date is missing.");
            }
        } else if (transportData.model && transportData.model.label === "Other Mode") {
            if (transportData.Other_Mode) {
                passValueToSubmit(transportData)
                setShow(false)
            } else {
                console.error("Other_Mode is missing.")
            }
        } else {
            console.error("No valid transport model selected.");
        }
    }
    
    
  return (
    <>
        <Modal show={show} onHide={()=>{setShow(false)}}>
            <Modal.Header closeButton>
            <Modal.Title>Transport Details</Modal.Title>
            </Modal.Header>
        <Modal.Body> 
        <div>
            <div className="row">
                <div className="col-6 staan-input-group">
                    <Select
                        options={TranSportDetailsOptionList}
                        name='TranSportDetailsOptionListType'
                        className='my-3'
                        value={transportData?.model}
                        onChange={(option) => {
                            setTransportData({ model: option});
                        }}
                        styles={customSelectStyle}
                    />
                    <label htmlFor="TranSportDetailsType" className='staan-form-input-label pt-1 px-1 mx-2'>Transport Details Type</label>
                </div>
            </div>
            <div className="row">
                {transportData?.model?.value === "Own Vehicle" && (
                    <>
                        <div className="col-6 staan-input-group">
                            <input 
                                type='text' 
                                name='Vehicle_No' 
                                value={transportData.Vehicle_No} 
                                onChange={(e) => {
                                    setTransportData(prev => ({
                                        ...prev, Vehicle_No: e.target.value
                                    }));
                                }}  
                                className='w-100 staan-form-input mb-2'
                            />
                            <label htmlFor="Vehicle_No" className='staan-form-input-label pt-1 px-1 mx-2'>Vehicle No<span className='text-danger'>*</span></label>
                        </div>
                        <div className="col-6 staan-input-group">
                            <input 
                                type='text' 
                                name='Driver_Name' 
                                value={transportData.Driver_Name} 
                                onChange={(e) => {
                                    setTransportData(prev => ({
                                        ...prev, Driver_Name: e.target.value
                                    }));
                                }}  
                                className='w-100 staan-form-input mb-2'
                            />
                            <label htmlFor="Driver_Name" className='staan-form-input-label pt-1 px-1 mx-2'>Driver Name<span className='text-danger'>*</span></label>
                        </div>
                    </>
                )}
                {transportData?.model?.value === "Transport" && (
                    <>
                       <div className="col-6 staan-input-group">
                            <input 
                                type='text' 
                                name='vehicle_no' 
                                value={transportData?.vehicle_no} 
                                onChange={(e) => {
                                    setTransportData(prev => ({
                                        ...prev, vehicle_no: e.target.value
                                    }));
                                }}
                                className='w-100 staan-form-input mb-2'
                            />
                            <label htmlFor="vehicle_no" className='staan-form-input-label pt-1 px-1 mx-2'>Vehicle No<span className='text-danger'>*</span></label>
                       </div>
                       <div className="col-6 staan-input-group">
                          <Select  
                          onInputChange={(e)=>{fetchSupplier(e)}}
                          options={transporterList}
                          name='Transporter_Name'
                          className='my-3'
                          styles={customSelectStyle}
                          value={transporterSelected}
                          onChange={(option)=>{
                            setTransporterSelected(option)
                          }} 
                          />
                          <label htmlFor="Transporter_Name" className='staan-form-input-label pt-1 px-1 mx-2'>Transporter Name<span className='text-danger'>*</span></label>
                        </div>
                    </>
                )}
                {transportData?.model?.value === "Courier" && (
                    <>
                        <div className="col-6 staan-input-group">
                            <input 
                                type='text' 
                                name='Docket_No' 
                                value={transportData.Docket_No} 
                                onChange={(e) => {
                                    setTransportData(prev => ({
                                        ...prev, Docket_No: e.target.value
                                    }));
                                }}  
                                className='w-100 staan-form-input mb-2'
                            />
                            <label htmlFor="Docket_No" className='staan-form-input-label pt-1 px-1 mx-2'>Docket No<span className='text-danger'>*</span></label>
                        </div>
                        <div className="col-6 staan-input-group">
                            <input 
                                type='date' 
                                name='Docket_Date' 
                                value={transportData.Docket_Date} 
                                onChange={(e) => {
                                    console.log(e.target.value);
                                    setTransportData(prev => ({
                                        ...prev, Docket_Date: e.target.value
                                    }));
                                }}  
                                className='w-100 staan-form-input mb-2'
                            />
                            <label htmlFor="Docket_Date" className='staan-form-input-label pt-1 px-1 mx-2'>Docket Date<span className='text-danger'>*</span></label>
                        </div>
                    </>
                )}
                {transportData?.model?.value === "Other Mode" && (
                    <div className="col-12 staan-input-group">
                        <textarea 
                            rows="4" 
                            type='text' 
                            name='Other_Mode'  
                            value={transportData.Other_Mode} 
                            onChange={(e) => { 
                                setTransportData(prev => ({
                                    ...prev, Other_Mode: e.target.value
                                }));
                            }}  
                            className='w-100 staan-form-input mb-2'
                        />
                        <label htmlFor="Other_Mode" className='staan-form-input-label pt-1 px-1 mx-2'>Other Mode<span className='text-danger'>*</span></label>
                    </div>
                )}
            </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={()=>{setShow(false)}}>
            Close
          </Button>
          <Button variant="outline-success" onClick={()=>{Onsubmit()}}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default TransportDetails