import React, { useState ,   useEffect, useContext} from 'react'
import Modal from 'react-bootstrap/Modal';
import {ToastContainer, toast} from 'react-toastify';
import { Formik, Field, Form } from 'formik'
import { VscSaveAs } from "react-icons/vsc";
import { addSno, calculateTax, customSelectStyle,  DateFormate, gettax, removeEmptyValueInObject, roundToNearest10, ShowErrorNoties, textEditormodules, TimeFormate } from '../../../utils/Utils';
import ReactQuill from 'react-quill';
import Select from 'react-select';
import axiosInstance, { commanFetch } from '../../../api/axoiss';
import {purchaseRetunChallanItemMutation, purchaseRetunChallanMutation, TermsConditionsFetchQuery } from '../../../GraphQLQueries/PurchaseItemMutations';
import DataContext from '../../../context/ItemMasterContext';
import { purchaseReturnChallanFetchQuery } from '../../../GraphQLQueries/PurchaseItemQueries';
import TransportDetails from '../../../components/TransportDetails';
import  loadingModel  from '../../../components/loading/BallTriangle';
import WaringModel from '../../../components/Warings/WaringModel';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import Can from "../../../components/authorizationComponents/Can"

const PurchaseRetunForm = ({isShowing, setIsShowing, initialData, edit, setEdit, setLoad, setPurchaseOrderEdit}) => {

    const [initialValues , setInitialValues] = useState({
        id:"", 
        prc_no : "",
        prc_date : "",
        po_no : "",
        po_id : "",
        Grn:"", 
        supplier_name : "",
        supplier_No : "",
        gstin_type : "",
        gstin : "",
        state : "",
        place_of_supply : "",
        remarks : "",
        EwayBill: "",
        createdBy :"",
        updatedAt : "",
    })
    const {userId} = useContext(DataContext)
    const [prcItemDetails, setPrcItemDetails] = useState([]) 
    const [editRowIndex, setEditRowIndex] = useState(null);
    const [billingAddressSelected, setBillingAddressSelected] = useState(null)
    const [totalTax, setTotalTax] = useState(0.00)
    const [roundof , setRoundof] = useState(0.00)
    const [sgstTax, setSgstTax] = useState([])
    const [cgstTax, setCgstTax] = useState([])
    const [igstTax, setIgstTax] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalAmountBeforeTax, setTotalAmountBeforeTax]  = useState(0.00)
    const [fixedFinalAmount, setFinalAmount]  = useState(0.00)
    const [tCList, setTcList] = useState([])
    const [termsOptions, setTermsOptions] = useState(null)
    const [termsConditions, setTermsConditions] = useState(null)
    const [status, setStatus] =  useState(null)
    const [manulaInputDatas, setManulaInputDatas] =  useState({EwayBill:"", remarks:"" })
    const [isShowTranSpoterDetails, setIsShowTranSpoterDetails] = useState(false)
    const [transportData , setTransportData] =  useState({model: null})
    const [ownVehicle, setOwnVehicle] = useState()
    const [courier, setCourier] =  useState()
    const [otherModel, setOtherModel] = useState()
    const [TranSpoter, setTranSpoter] = useState(null)
    const [deleteWaringModel, setdeleteWaringModel] = useState(false)
    const [deleteitemData, setDeleteitemData] = useState(null)
    
    // initial Process   
    useEffect(()=>{
        if (isShowing && initialData?.initialPurchase && initialData?.ItemDetails ){
            let initialData_ = initialData?.initialPurchase 
            let GrnList = initialData_['grnList']?.map((item)=>{
                return item?.grnNo?.linkedModelId
            })  
            setInitialValues({po_no: initialData_?.['po_no'], 
                            po_id : initialData_?.['id'] ,
                            Grn:String(GrnList),
                            supplier_name: initialData_?.['Supplier_objects']?.['companyName'],
                            supplier_No: initialData_?.['Supplier_objects']?.['supplierNo'],
                            gstin_type: initialData_?.['gstin_type'],
                            gstin : initialData_?.['gstin'],
                            state : initialData_?.['state'],
                            place_of_supply : initialData_?.['place_of_supply']})
                            initialData_['address_objects'] = initialData_['address_objects'] || {};
                            initialData_['address_objects']['country'] = initialData_['country'];

                            setBillingAddressSelected(initialData_?.['address_objects'])
            let modifiedData = initialData?.ItemDetails
            ?.map((items, index) => {
                let retunQty = Number(items?.poQty) - Number(items?.purchaseRetunQty);
                if (retunQty > 0) {
                    return { 
                        "sNo": index + 1,
                        "amount": Number(items?.poQty) * Number(items?.rate),
                        "id": "",
                        "retunQty": retunQty,
                        "purchaseOrderItemDetails": {
                            "id": items?.id,
                            "hsnId": {
                                "hsnCode": items?.hsnId?.hsnCode,
                            },
                            "itemMasterId": {
                                "itemName": items?.itemMasterId?.itemName,
                                "itemPartCode": items?.itemMasterId?.itemPartCode,
                            },
                            "purchaseRetunQty": Number(items?.purchaseRetunQty),
                            "poQty": Number(items?.poQty),
                            "uom": {
                                "name": items?.uom?.name,
                            },
                            "rate": items?.rate,
                            "tax": items?.tax,
                        }
                    };
                }
                return null; // Return null if retunQty is not greater than 0
            })
            .filter(item => item !== null); // Filter out null values
            
            let itemDetail = addSno(modifiedData) 
            setPrcItemDetails(itemDetail)
        } else if(isShowing && edit){ 
            fetchEditData(edit)
        }
    },[isShowing])

    async function fetchEditData(params) { 
        let queryWithPerementr = purchaseReturnChallanFetchQuery(`id:${params?.id}`)
        const response = await commanFetch(queryWithPerementr);
        const reponseData = response?.data?.items?.[0]
        console.log(reponseData);
        
        let GrnList = reponseData?.['purchaseId']?.['goodsreceiptnoteSet']?.map((item)=>{
            return item?.grnNo?.linkedModelId
        })
        
        setStatus(reponseData?.['status']?.['name'])
        setInitialValues({
            id : edit?.id ?  Number(edit?.id) : "",
            prc_no : reponseData['purchaseReturnChallanNo']?.['linkedModelId'] ,
            prc_date : reponseData['purchaseReturnChallanDate'] ,
            po_no: reponseData['purchaseId']?.['purchaseOrderNo']?.['linkedModelId'], 
            po_id : reponseData['purchaseId']?.['id'] ,
            Grn : String(GrnList),
            supplier_name: reponseData['purchaseId']?.['supplierId']?.['companyName'],
            supplier_No: reponseData['purchaseId']?.['supplierId']?.['supplierNo'],
            gstin_type: reponseData['purchaseId']?.['gstinType'],
            gstin : reponseData['purchaseId']?.['gstin'],
            state : reponseData['purchaseId']?.['state'],
            place_of_supply : reponseData['purchaseId']?.['placeOfSupply'],
            remarks: reponseData['remarks'],
            EwayBill : reponseData['ewayBillNo'],
            createdBy : reponseData?.['createdBy'],
            updatedAt : {date:  DateFormate(reponseData?.['updatedAt']), time:TimeFormate(reponseData?.['updatedAt'])}
            })
            setOwnVehicle(JSON.parse(reponseData?.ownVehicle))
            setCourier(JSON.parse(reponseData?.courier))
            setOtherModel(reponseData?.otherModel)
            if (reponseData?.vehicleNo !== null){
                setTranSpoter({vehicleNo:reponseData?.vehicleNo ,transport:reponseData?.transportId?.companyName})
            }
        setManulaInputDatas({EwayBill : reponseData['ewayBillNo'], remarks: reponseData['remarks']})
        setTermsOptions( {value:reponseData['termsConditions']?.id, label: reponseData['termsConditions']?.name})
        setTermsConditions(reponseData['termsConditionsText'])
        setBillingAddressSelected(reponseData?.['purchaseId']?.['address'])
        let itemDetails =  addSno(reponseData?.['purchaseReturnChallanItemDetails'])
        setPrcItemDetails(itemDetails)

    }

    // retun null; calculate the Value
    useEffect(()=>{
        if (prcItemDetails.length > 0 ){
            let Final_value = 0
            // let fixedTotalvalue = 0 
            const igstList = {}
            const cgstList = {}
            const sgstList = {}
            prcItemDetails.forEach(itemValue =>{
                // fixedTotalvalue += Number(itemValue?.rate) * Number(itemValue?.retunQty) 
                Final_value += Number(itemValue?.amount) 
                let prcItemDetailsHSN =  gettax(itemValue, itemValue?.purchaseOrderItemDetails?.tax, billingAddressSelected?.state )
        
                if (prcItemDetailsHSN.igst) {
                    if (igstList[prcItemDetailsHSN.igst]) {
                        igstList[prcItemDetailsHSN.igst].push(prcItemDetailsHSN.amount)
                    } else {
                        igstList[prcItemDetailsHSN.igst] = [prcItemDetailsHSN.amount]
                    }
                    }
                if (prcItemDetailsHSN.cgst) {
                if (cgstList[prcItemDetailsHSN.cgst]) {
                    cgstList[prcItemDetailsHSN.cgst].push(prcItemDetailsHSN.amount)
                } else {
                    cgstList[prcItemDetailsHSN.cgst] = [prcItemDetailsHSN.amount]
                }
                }
                if (prcItemDetailsHSN.sgst) {
                if (sgstList[prcItemDetailsHSN.sgst]) {
                    sgstList[prcItemDetailsHSN.sgst].push(prcItemDetailsHSN.amount)
                } else {
                    sgstList[prcItemDetailsHSN.sgst] = [prcItemDetailsHSN.amount]
                }
                }

            })
            const { taxResult: igstResult, taxValues: igstaxvalues } =
                calculateTax(igstList)
            const { taxResult: cgstResult, taxValues: cgstaxvalues } =
                calculateTax(cgstList)
            const { taxResult: sgstResult, taxValues: sgstaxvalues } =
                calculateTax(sgstList)
            setTotalTax((igstaxvalues + cgstaxvalues + sgstaxvalues).toFixed(2)) 
            setIgstTax(igstResult)
            setCgstTax(cgstResult)
            setSgstTax(sgstResult)
        
            setTotalAmountBeforeTax(Final_value.toFixed(2))
                setFinalAmount(roundToNearest10(igstaxvalues + cgstaxvalues + sgstaxvalues+Final_value).toFixed(2)) 
                setRoundof((roundToNearest10(igstaxvalues + cgstaxvalues + sgstaxvalues+Final_value).toFixed(2)
            - (igstaxvalues + cgstaxvalues + sgstaxvalues+Final_value).toFixed(2)).toFixed(2))
        }
    },[prcItemDetails])
    
    // retun null; fetch the Data
    async function fetchOptions(e,  name, updatedState) {
        let search_term = e.trim().replace(/"/g, '\\"');
        let queryWithPerementr = ""
        if (name === "TermsConditionsFetchQuery"){
            let peremeter = `pageSize:200, name:"${search_term}", module:"Purchase"`
            queryWithPerementr = TermsConditionsFetchQuery(peremeter)
        }
        const response = await commanFetch(queryWithPerementr);
        const reponseData = response?.data?.items
        if (response.success){
                if (name === "TermsConditionsFetchQuery"){  
                    setTcList(reponseData?.map((item)=>(
                        {value:item?.id, label: item?.name, tc: item?.tc}
                    )))
                }
            
        } else {
            console.error("Error fetching data:", response.error);
        }
        }

    //retun Null , close Model
    function Close(params) {
        setIsShowing(false)
        setTermsOptions({value: "" , label:""})
        setTermsConditions("")
        setInitialValues({
            id :   "",
            prc_no :  "" ,
            prc_date : "" ,
            po_no: "", 
            po_id : "" ,
            Grn : "",
            supplier_name: "",
            supplier_No: "",
            gstin_type: "",
            gstin : "",
            state : "",
            place_of_supply : "",
            remarks: "",
            EwayBill : "",
            })
            setStatus(null)
        setManulaInputDatas({EwayBill:"", remarks:"" })
        if (setEdit){
            setEdit({id:""})
        }
        if (setLoad){
            setLoad()
        }
        setTransportData({model: null})
        setBillingAddressSelected() 
        setPrcItemDetails([])
        
    }

    //retun null , set Edit Index for item details
    const handleEditClick = (index) => {
        setEditRowIndex(index); 
    }

    //retun null , set Edit value for item details
    const handleInputChange = (e, index, field, rate) => {
        const newItemsList = [...prcItemDetails];

        newItemsList[index] = {
            ...newItemsList[index],
            [field]: e.target.value,
            amount: Number(e.target.value) * Number(rate)
        };
        
        setPrcItemDetails(newItemsList)
    
    }

    //retun null , save localy value for Item details
    function SavedItemDetailsLocal(item) {
        let allowQty = Number(item?.purchaseOrderItemDetails?.poQty) - Number(item?.purchaseOrderItemDetails?.purchaseRetunQty)
        if (Number(allowQty) >= Number(item?.retunQty) && Number(item?.retunQty) > 0 ){
            handleEditClick(null)
        } else{
            alert("Check The Qty It Is More Then po Qty else Check preves PRC")
        }
        
    }
    async function SaverdcItem(item) {
        let save = {
            id: item?.id ? Number(item?.id) : "",
            purchaseOrderItemDetails: Number(item?.purchaseOrderItemDetails?.id),
            retunQty: item?.retunQty,
            amount: item?.amount, 
            createdBy: item?.createdBy?.id ? Number(item?.createdBy?.id) : Number(userId),
            modifiedBy : item?.createdBy?.id ? Number(userId) : "" ,
        }
        let allow_null = ['id', "modifiedBy"]
        let cleanValue = removeEmptyValueInObject(save, allow_null); 
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, {
                query: purchaseRetunChallanItemMutation(cleanValue)
            });
            const responseData = response?.data?.data?.purchaseReturnChallanItemDetailsCreateMutation;
            if (responseData.success) {
                let purchasRetunChallanItemDetailsInstance = responseData.purchaseReturnChallanItemDetailsInstance;
                return purchasRetunChallanItemDetailsInstance;
            } else {
                let errorData = ShowErrorNoties(responseData.errors);
                toast.error(errorData, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return null; // Return null if there are errors
            }
        } catch (error) {
            console.error("Error saving item:", error); // Log the error
            return null; // Return null in case of a catch error
        }
    } 
    //   Retun Null ; Save 
    async function handleSubmit(  status) {
        if (editRowIndex !== null){
            showErrorToast(true ,"error", `Check SNo ${editRowIndex+1} it is not saved.`)

        }
        setLoading(true)
            let saved_item_ids = []
            if (!termsConditions){
                toast.error("Terms & Conditions required", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return ""
            }
            if (prcItemDetails.length > 0) {
                const savePromises = prcItemDetails.map(item => SaverdcItem(item));
                let  results = await Promise.all(savePromises); 
                results  = addSno(results)
                setPrcItemDetails(results)
                saved_item_ids.push(results.map((item)=>item?.id))
            }  
            let SavePRC = {
                id : initialValues?.id ? Number(initialValues?.id) : "" ,
                status :status ,
                purchaseId : initialValues?.po_id ? Number(initialValues?.po_id) : "",
                ewayBillNo : manulaInputDatas?.EwayBill,
                remarks : manulaInputDatas?.remarks,
                purchaseReturnChallanItemDetails : saved_item_ids,
                termsConditions : termsOptions?.value ? Number(termsOptions?.value) : "",
                termsConditionsText : termsConditions,
                cgst : cgstTax,
                sgst : sgstTax,
                igst : igstTax,
                beforTax : totalAmountBeforeTax ? totalAmountBeforeTax : 0,
                taxTotal : totalTax ? totalTax : 0,
                roundOff : roundof ? roundof : 0,
                netAmount : fixedFinalAmount ? fixedFinalAmount :0,
                createdBy : initialValues?.id ? initialValues?.createdBy?.id? Number(initialValues?.createdBy?.id)  : Number(userId) : Number(userId),
            } 
            // createdBy
            let ClearValue = removeEmptyValueInObject(SavePRC) 
            
            try {
                const response = await axiosInstance.post(`/itemmaster/graphql`, {
                    query: purchaseRetunChallanMutation(ClearValue)
                });
                const responseData = response?.data?.data?.purchaseReturnChallanCreateMutation;
                if (responseData.success) {
                    setIsShowing(false)
                    toast.success( `Saved Successfully`, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light", 
                    });
                    if (setPurchaseOrderEdit){
                        setPurchaseOrderEdit({id:initialValues?.po_id})
                    }
                } else {
                    let errorData = ShowErrorNoties(responseData.errors);
                    toast.error(errorData, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }); 
                }
            } catch (error) { 
                toast.error(error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }); 
            }
        setLoading(false)
    }
    // retun null; change status Dispath
    const passValueToDispath = async(data, trantransporterId)=>{ 
        setLoading(true)
        let currentDetails = { ...data }; 
        let saveWithtransportData = {
            "id" : edit?.id,
            "status" : "Dispatch"
        } 
        
        if (data?.["model"]?.['label'] === "Own Vehicle"){
            delete currentDetails?.model;
            saveWithtransportData['ownVehicle'] = currentDetails 
        } else if (data?.["model"]?.['label'] === "Courier"){
            delete currentDetails?.model;
            saveWithtransportData['courier'] = currentDetails 
        } else if (data?.["model"]?.['label'] === "Other Mode"){
            delete currentDetails?.model;
            saveWithtransportData['otherModel'] = currentDetails?.['Other_Mode']
        } else if (data?.["model"]?.['label'] === "Transport"){
            delete currentDetails?.model;
            saveWithtransportData['vehicleNo'] = currentDetails?.['vehicle_no']
            saveWithtransportData['transportId'] = trantransporterId ? Number(trantransporterId) : null
        }  
        
        let ClearValue = removeEmptyValueInObject(saveWithtransportData) 
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, {
                query: purchaseRetunChallanMutation(ClearValue)
            });
            const responseData = response?.data?.data?.purchaseReturnChallanCreateMutation;
            if (responseData.success) {
                setStatus("Dispatch")
                setIsShowing(false)
                toast.success( `Dispatch successfully.`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            } else {
                let errorData = ShowErrorNoties(responseData.errors);
                toast.error(errorData, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }); 
            }
        } catch (error) { 
            toast.error(error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            }); 
        }
        setLoading(false)
    }

      /* retun null; initial we did not save in 
     data base so we remove in local
     on edit hidden the delete button */
  function deleteLocaly(value) {
    if (value){ 
        setPrcItemDetails( 
        prcItemDetails?.filter(Item => Item?.sNo !== deleteitemData?.sNo)
      )
    }
  }

  return (
    <>   
        {<loadingModel isshow={loading}/>}
        <ToastContainer/>
        <Modal show={isShowing} fullscreen>
            <Modal.Body>
                <div className='itemMaster_Top mx-3 mt-1 d-flex justify-content-center card'   style={{ width: '98%' }}>
                    <div className="row  align-items-center  justify-content-between ">
                        <div className="col-6  d-flex align-items-center"   >
                            <h3 className='mx-2 commanModelTitleColor'>
                            <i className='fa-solid fa-arrow-left fa-sm me-2 commanModelTitleColor' onClick={()=>{Close()}} ></i>
                            Purchase Return Challan   
                            </h3> 
                            {status? <><span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'> {status} </span> </>
                            : ""}
                            
                        </div> 
                        <div className="col-6 text-end">
                            <div className="row">
                                <div className="col-6">

                                </div>
                                <div className="col-6">
                                    <h6 className='text-end'  >
                                        {initialValues?.createdBy?.username ? `Created By : ${initialValues?.createdBy?.username}`: ""}
                                    </h6>
                                    <h6 className='text-end '>
                                        {initialValues?.updatedAt?.date ? `Last Modified Date : ${initialValues?.updatedAt?.date} (${initialValues?.updatedAt?.time})`: ""}
                                    </h6>
                                </div>
                            </div>
                            {/* <TiCancel  hidden={  status ==="Canceled"? true : rdcEdit?.id ? false :  true}
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Cancle"
                                className='fs-2 pb-1'
                                style={{ color: '#7f7f7f', cursor: "pointer" }}
                                onClick={() => { 
                                  setWaringToCancle(true)
                                }}
                            />
                            <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  "
                              onClick={()=>{setWaringToDelete(true)}}
                            hidden={status ==="Canceled"? false : true}  ></i>  */}
                        </div>
                    </div> 
                </div>
                <div style={{width:"98%"}}>
                    <Formik initialValues={initialValues}>
                        {({errors, touched, setFieldValue, values, isSubmitting, submitForm})=>{
                            return(
                                <Form>
                                    <div className='row ms-3 mt-3'>
                                        {/* left Side */}
                                        <div className="col card shadow  mx-2 py-2">
                                            <div className="row">
                                                <div className="col-6 staan-input-group">
                                                    <Field type='text' name='PRCNo' disabled value={initialValues?.prc_no}   className='w-100 staan-form-input'/>
                                                    <label htmlFor="PRCNo" className='staan-form-input-label pt-1 px-1 mx-2'  >PRC No</label> 
                                                </div>
                                                <div className="col-6 staan-input-group">
                                                    <Field type='text' name='PRCDate' disabled value={initialValues?.prc_date}  className='w-100 staan-form-input'/>
                                                    <label htmlFor="PRCDate" className='staan-form-input-label pt-1 px-1 mx-2'>PRC Date</label> 
                                                </div>
                                                <div className="col-6 staan-input-group"> 
                                                    <Field type='text' name='Purchase_Order_No' disabled value={initialValues?.po_no}  className='w-100 staan-form-input'/>
                                                    <label htmlFor="Purchase_Order_No" className='staan-form-input-label pt-1 px-1 mx-2'>Purchase Order No </label>
                                                </div>
                                                <div className="col-6 staan-input-group"> 
                                                    <Field type='text' name='GRN_No' disabled  value={initialValues?.Grn}  className='w-100 staan-form-input'/>
                                                    <label htmlFor="GRN_No" className='staan-form-input-label pt-1 px-1 mx-2' >GRN No  </label>
                                                </div>
                                                <div className="col-6 staan-input-group"> 
                                                    <Field type='text' name='EwayBill' disabled={status === "Submit" ? true : status === "Dispatch" ? true : false}  value={manulaInputDatas?.EwayBill} 
                                                     onChange={(e)=>{ 
                                                        setManulaInputDatas(prevState => ({
                                                           ...prevState,
                                                           EwayBill: e?.target?.value
                                                         }));
                                                       }}
                                                    className='w-100 staan-form-input'/>
                                                    <label htmlFor="EwayBill" className='staan-form-input-label pt-1 px-1 mx-2'>Eway Bill<span className='text-danger'>*</span> </label>
                                                </div>  
                                                <div className="col-12">
                                                        <h5>Transport Details : </h5>
                                                        <div className="row">
                                                            <h6>{ownVehicle? `Own Vehicle` : courier? "Courier":otherModel? "Other Model": ""}</h6>
                                                            {ownVehicle? 
                                                            <>
                                                                 
                                                                <div className="col-6 staan-input-group">
                                                                    <input 
                                                                        type='text' 
                                                                        name='Vehicle_No' 
                                                                        value={ownVehicle?.Vehicle_No}
                                                                        className='w-100 staan-form-input mb-2'
                                                                    />
                                                                    <label htmlFor="Vehicle_No" className='staan-form-input-label pt-1 px-1 mx-2'>Vehicle No<span className='text-danger'>*</span></label>
                                                                </div> 
                                                                <div className="col-6 staan-input-group">
                                                                    <input 
                                                                        type='text' 
                                                                        name='Driver_Name' 
                                                                        value={ownVehicle?.Driver_Name}
                                                                        className='w-100 staan-form-input mb-2'
                                                                    />
                                                                    <label htmlFor="Driver_Name" className='staan-form-input-label pt-1 px-1 mx-2'>Driver Name<span className='text-danger'>*</span></label>
                                                                </div>
                                                            </> :  
                                                            courier? 
                                                            <>
                                                                <div className="col-6 staan-input-group">
                                                                    <input 
                                                                        type='text' 
                                                                        name='Docket_No' 
                                                                        value={courier?.Docket_No}
                                                                        className='w-100 staan-form-input mb-2'
                                                                    />
                                                                    <label htmlFor="Docket_No" className='staan-form-input-label pt-1 px-1 mx-2'>Docket No<span className='text-danger'>*</span></label>
                                                                </div> 
                                                                <div className="col-6 staan-input-group">
                                                                    <input 
                                                                        type='text' 
                                                                        name='Docket_Date' 
                                                                        value={courier?.Docket_Date}
                                                                        className='w-100 staan-form-input mb-2'
                                                                    />
                                                                    <label htmlFor="Docket_Date" className='staan-form-input-label pt-1 px-1 mx-2'>Docket Date<span className='text-danger'>*</span></label>
                                                                </div>
                                                            </> :
                                                            otherModel? 
                                                            <>
                                                                 <div className="col-12 staan-input-group">
                                                                    <textarea 
                                                                        rows="4" 
                                                                        type='text' 
                                                                        name='Other_Mode'  
                                                                        value={otherModel} 
                                                                        
                                                                        className='w-100 staan-form-input mb-2'
                                                                    />
                                                                    <label htmlFor="Other_Mode" className='staan-form-input-label pt-1 px-1 mx-2'>Other Mode<span className='text-danger'>*</span></label>
                                                                </div>
                                                                
                                                            </> 
                                                            :
                                                            TranSpoter? 
                                                            <>
                                                                <div className="col-6 staan-input-group">
                                                                    <input 
                                                                        type='text' 
                                                                        name='vehicleNo' 
                                                                        value={TranSpoter?.vehicleNo}
                                                                        className='w-100 staan-form-input mb-2'
                                                                    />
                                                                    <label htmlFor="vehicleNo" className='staan-form-input-label pt-1 px-1 mx-2'>Vehicle No<span className='text-danger'>*</span></label>
                                                                </div> 
                                                                <div className="col-6 staan-input-group">
                                                                    <input 
                                                                        type='text' 
                                                                        name='transportId' 
                                                                        value={TranSpoter?.transport}
                                                                        className='w-100 staan-form-input mb-2'
                                                                    />
                                                                    <label htmlFor="transportId" className='staan-form-input-label pt-1 px-1 mx-2'>Transport <span className='text-danger'>*</span></label>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                            </>}
                                                            
                                                        </div>
                                                    </div>
                                            </div> 
                                        </div>
                                        {/* right Side */}
                                        <div className="col card shadow  mx-2">
                                            <div className="row">
                                                    <div className="col-6 staan-input-group"> 
                                                        <Field type='text' name='supplier_Number' disabled value={initialValues?.supplier_No}  className='w-100 staan-form-input'/>
                                                        <label htmlFor="supplier_Number" className='staan-form-input-label pt-1 px-1 mx-2'    >Supplier Number   </label>
                                                            
                                                    </div>
                                                    <div className="col-6 staan-input-group"> 
                                                        <Field type='text' name='supplier_name' value={initialValues?.supplier_name} disabled className='w-100 staan-form-input'/> 
                                                        <label htmlFor="supplier_name" className='staan-form-input-label pt-1 px-1 mx-2'   >Supplier Name </label>
                                                    </div>
                                                    <div className="col-12 mt-1" > 
                                                        <div hidden={billingAddressSelected ? false : true}>
                                                        <p className='pt-2' style={{marginBottom:".5rem"}}>{billingAddressSelected?.addressLine1} {billingAddressSelected?.addressLine2},</p> 
                                                        <p className='pb-0' style={{marginBottom:".5rem"}}> {billingAddressSelected?.city} -  {billingAddressSelected?.pincode},</p>
                                                        <p className='pb-0' style={{marginBottom:".5rem"}} >{billingAddressSelected?.state}  ,</p>
                                                        <p style={{marginBottom:".5rem"}}>Country : <span>{billingAddressSelected?.country}</span>.</p>
                                                        </ div>
                                                    </div>
                                                    
                                                    <div className="col-12 staan-input-group mb-2">
                                                        <Field type='text'  as="textarea" disabled={status === "Submit" ? true : status === "Dispatch" ? true : false} value={manulaInputDatas?.remarks} onChange={(e)=>{ 
                                                         setManulaInputDatas(prevState => ({
                                                            ...prevState,
                                                            remarks: e?.target?.value
                                                          }));
                                                        }} rows="3" style={{resize: "vertical", height: "auto"}}   name='remarks'   placeholder="Remarks"  className='w-100 staan-form-input'  />
                                                        <label htmlFor="remarks" className='staan-form-input-label pt-1 px-1 mx-2'>Remark</label>
                                                        {  touched.remarks &&  errors.remarks && <small>{errors.remarks}</small>}
                                                    </div>
                                            </div>

                                        </div> 
                                    </div>
                                     {/* Gst Side */}
                                    <div className="row ms-3 mt-3"> 
                                        <div className="col-12 card  shadow">
                                        <h6 className='commanModelTitleColor m-2'>GST Details</h6>
                                        <div className="row my-1">
                                            <div className="col-3 staan-input-group">
                                            <Field type='text' name='gstin_type' disabled  value={initialValues?.gstin_type} className='w-100 staan-form-input'/>
                                            <label htmlFor="gstin_type" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN Type</label>
                                            {  touched.gstin_type &&  errors.gstin_type && <small>{errors.gstin_type}</small>}
                                            </div>
                                            <div className="col-3 staan-input-group">
                                            <Field type='text' name='gstin' disabled value={initialValues?.gstin} className='w-100 staan-form-input'/>
                                            <label htmlFor="gstin" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN</label>
                                            {  touched.gstin &&  errors.gstin && <small>{errors.gstin}</small>}
                                            </div>
                                            <div className="col-3 staan-input-group">
                                            <Field type='text' name='state'  disabled  value={initialValues?.state} className='w-100 staan-form-input'/>
                                            <label htmlFor="state" className='staan-form-input-label pt-1 px-1 mx-2'>State</label>
                                            {  touched.state &&  errors.state && <small>{errors.state}</small>}
                                            </div>
                                            <div className="col-3 staan-input-group">
                                            <Field type='text' name='place_of_supply'  disabled value={initialValues?.place_of_supply}  className='w-100 staan-form-input'/>
                                            <label htmlFor="place_of_supply" className='staan-form-input-label pt-1 px-1 mx-2'>Place Of Supply</label>
                                            {touched.place_of_supply &&  errors.place_of_supply && <small>{errors.place_of_supply}</small>}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                      {/* Item Details */}
                                    <div className="row ms-3 mt-4 card shadow-lg pb-3">
                                        <div className="row justify-content-between align-items-center">
                                        <div className="col-3 m-2"> 
                                            <h6 className='commanModelTitleColor '>Item Details</h6>
                                        </div> 
                                        </div>
                                        <div className="ag-theme-alpine mt-2" style={{ height: '40rem', width: '100%' }}>
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">S.No</th>
                                                        <th scope="col">Part Code</th>
                                                        <th scope="col">Part Name</th>
                                                        <th scope="col">HSN</th>
                                                        <th scope="col">PO Qty</th>
                                                        <th scope="col">Return Qty</th>
                                                        <th scope="col">UOM</th>
                                                        <th scope="col">Rate</th>
                                                        <th scope="col">Tax</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Action</th>
                                                    </tr> 
                                                </thead>
                                                <tbody>
                                                    {prcItemDetails?.map((item, index)=>(
                                                        <tr key={index}>
                                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.sNo}</td>
                                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.purchaseOrderItemDetails?.itemMasterId?.itemPartCode}</td>
                                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.purchaseOrderItemDetails?.itemMasterId?.itemName}</td>
                                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.purchaseOrderItemDetails?.hsnId?.hsnCode}</td>
                                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.purchaseOrderItemDetails?.poQty}</td>
                                                            <td className={editRowIndex === index ? "py-3" : ""}> 
                                                                {editRowIndex === index ? (
                                                                    <input
                                                                    type="number"
                                                                    style={{height:"2.4rem"}}
                                                                    value={item?.retunQty}
                                                                    onChange={(e) => handleInputChange(e, index, 'retunQty', item?.purchaseOrderItemDetails?.rate)}
                                                                    />
                                                                ) : (
                                                                    item?.retunQty
                                                                )}
                                                                </td>
                                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.purchaseOrderItemDetails?.uom?.name}</td>
                                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.purchaseOrderItemDetails?.rate}</td>
                                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.purchaseOrderItemDetails?.tax}</td>
                                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.amount}</td>
                                                            <td className={editRowIndex === index ? "py-3" : ""}>{editRowIndex === index ? (
                                                                    <button
                                                                    className="btn btn-outline-success btn-sm px-3 mx-2"
                                                                    type='button'
                                                                    onClick={()=>{ SavedItemDetailsLocal(item)}}
                                                                    >
                                                                    <VscSaveAs size={20}/>
                                                                    </button>
                                                            ) :
                                                            <>
                                                                   <button
                                                                    className="btn btn-outline-success btn-sm px-3 me-1 "
                                                                    type='button'
                                                                    disabled={status === "Submit" ? true : status === "Dispatch" ? true : false}
                                                                    onClick={() => handleEditClick(index)} 
                                                                    >
                                                                    <i className="fa-solid fa-pen"></i>
                                                                    </button>
                                                                    <button
                                                                    type="button"
                                                                    disabled = {status === "Submit" ? true : status === "Dispatch" ? true : false}
                                                                        className='btn btn-outline-danger btn-sm px-3 ms-1 '
                                                                        onClick={()=>{setdeleteWaringModel(true)
                                                                            setDeleteitemData(item) }}>
                                                                    <i className="fa-solid fa-trash "></i> 
                                                                    </button>
                                                            </>
                                                             
                                                            }</td>

                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between ms-3  gap-3 mt-4">
                                        {/* Terms & Conditions */}
                                        <div className="col card shadow-lg p-2 pb-3">
                                            <div className="row">
                                                <div className="col-6 staan-input-group">
                                                    <Select
                                                    isDisabled={status === "Submit" ? true : status === "Dispatch" ? true : false}
                                                    
                                                    options={tCList}
                                                    onInputChange={(e)=>{fetchOptions(e, "TermsConditionsFetchQuery" )}}
                                                    value={termsOptions}
                                                    name='termsConditions'
                                                    className='my-3' 
                                                    styles={customSelectStyle}
                                                    onChange={(option)=>{
                                                        setTermsOptions(option)
                                                        setTermsConditions(option?.tc)
                                                    }}
                                                    />
                                                    <label htmlFor="supplier_Number" className='staan-form-input-label pt-1 px-1 mx-2'>Terms & Conditions</label>
                                                </div>
                                                <div className="col-12 staan-input-group " > 
                                                    <ReactQuill 
                                                      readOnly={status === "Submit" ? true : status === "Dispatch" ? true : false}
                                                    value={termsConditions} 
                                                    modules={textEditormodules}
                                                    onChange={(e)=>{  
                                                        setTermsConditions(e) 
                                                      }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                         {/* Amount */}
                                        <div className="col card shadow-lg">
                                            <div className="card mt-3">
                                                <table class="table "> 
                                                <tbody>
                                                    <tr> 
                                                    <td className='text-end'>Before Tax</td> 
                                                    <td className='text-end'>{totalAmountBeforeTax? totalAmountBeforeTax : "0.00"}</td>
                                                    </tr> 
                                                    <tr> 
                                                    <td className='text-end cursor-pointer text-primary' >Tax Total</td> 
                                                    <td className='text-end'>{totalTax ? totalTax : "0.00"}</td>
                                                    </tr>
                                                    <tr> 
                                                    <td className='text-end'>Round of</td> 
                                                    <td className='text-end'>{roundof ? roundof : "0.00"}</td>
                                                    </tr>
                                                    <tr> 
                                                    <td className='text-end  fw-bold'>Net Amount</td> 
                                                    <td className='text-end'>{fixedFinalAmount ? fixedFinalAmount : "0.00"}</td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div> 
                                        </div>
                                        {/* button */}
                                        <div className="row mt-3 ms-3 justify-content-evenly"  >
                                                <div className="col-1">
                                                <Can I={"Draft"} a={"PRC"}>
                                                <button type="button" disabled = {status === null ? false : status === "Submit" || status === "Dispatch"  ? true : false } 
                                                 class="btn btn-outline-secondary  px-4" style={{width:"9rem"}} onClick={()=>{handleSubmit("Draft")}}  > Draft </button>
                                                </Can>
                                                </div> 
                                                <div className="col-1">
                                                <Can I={"Submit"} a={"PRC"}>
                                                <button type="button"  style={{width:"9rem"}} disabled = {status=== "Draft" ? false : true}   class="btn btn-outline-success px-3"  onClick={()=>{handleSubmit("Submit")}}
                                                >Submit</button>
                                                </Can>
                                                </div> 
                                                <div className="col-1">
                                                <Can I={"Dispatch"} a={"PRC"}> 
                                                <button type="button" style={{width:"9rem"}} disabled={status === "Dispatch" ? true :  status === "Draft" ?
                                                 true : status === "Submit" ? false : true } onClick={()=>{setIsShowTranSpoterDetails(true)}}   class="btn btn-outline-primary px-3" 
                                                >Dispatch</button>
                                                </Can>
                                                </div>
                                                <div className="col-1">
                                                <Can I={"Debit_Note"} a={"PRC"}>
                                                <button type="button" style={{width:"9rem"}} class="btn btn-outline-primary px-3" 
                                                >Debit Note</button>
                                                </Can>
                                                </div>
                                                
                                            </div> 
                                    </div>
                                </Form>

                            )}}


                    </Formik>

                </div>
            </Modal.Body>

        </Modal>
        <TransportDetails
           show = {isShowTranSpoterDetails}
           setShow={setIsShowTranSpoterDetails}
           passValueToSubmit ={passValueToDispath}
           transportData = {transportData}
           setTransportData = {setTransportData}
        />
        {deleteWaringModel && 
          <WaringModel
          state={deleteWaringModel}
          setstate = {setdeleteWaringModel}
          title={"Confirm"}
          text={'Confirm to remove the item'}
          Confirmtion={value => {
            deleteLocaly(value)
          }}
          />
        }
        
       
    
    </>
  )
}

export default PurchaseRetunForm