import {
    FETCH_STORE_DATA_REQUEST,
    FETCH_STORE_DATA_SUCCESS,
    FETCH_STORE_DATA_FAILURE,
} from '../Action/storeActions';
  
const initialState = {
    data: [],
    isLoading: false,
    error: null,
};
  
const storeReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_STORE_DATA_REQUEST:
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case FETCH_STORE_DATA_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
        };
      case FETCH_STORE_DATA_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      default:
        return state;
    }
};
  
export default storeReducer;