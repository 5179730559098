import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { TbHistory } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { departmentDeleteMutations, departmentcreateMutations } from '../../../GraphQLQueries/HrMutation';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import axiosInstance from '../../../api/axoiss';
import Can from '../../../components/authorizationComponents/Can';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import DataContext from '../../../context/ItemMasterContext';
import { ShowErrorNoties, customSelectStyle, removeEmptyValueInObject } from '../../../utils/Utils';
import { departmentSchema } from '../../../validations/Hr';
import HistoryData from '../../ItemMaster/model/HistoryData';

const DePartMentModel = ({departmentIsShowing,setDepartmentIsShowing, setLoad, departmentEditData, setDepartmentEditData}) => {
    const dispatch = useDispatch();     
    const OptionsDataList = useSelector(state => state.itemGroup.data);
    const [userOptions, setUserOptions] = useState([]) 
    const [formKey, setFormKey] = useState(0);
    const [deleteData, setDeleteData] = useState({Name:"",id:""})
    const {userId, historyIds, setHistoryIds,handltoastDeleteConfomationShow} = useContext(DataContext)
    const [edit, setEdit]= useState(true)
    const [departmentHead, setDepartmentHead] = useState({value: "" , label: ""})
    const [initialDePartMent, setInitialDePartMent] = useState({
         id : "",
         name : "",
         department_head_user_id : "",
         HistoryDetails : "",
         modified_by : "",
         createdBy : ""
    })

    // retun null  ; save department
    const handleSubmit = async(values, { setSubmitting, resetForm })=>{  
    let save_data = {
        id : initialDePartMent?.id ?  Number(initialDePartMent.id)  : "",
        name: values?.['name'],
        departmentHeadUserId : values?.['department_head_user_id']? Number(values?.['department_head_user_id']) : "",
        createdBy: initialDePartMent?.id ? Number(initialDePartMent?.createdBy) : Number(userId),
        modifiedBy:initialDePartMent?.id ? Number(userId) : ""
    }  
    let allow_null = ['id',"modifiedBy"]
    let clear_data = removeEmptyValueInObject(save_data, allow_null)
    try{
        const response = await axiosInstance.post("/itemmaster/graphql",  {query: departmentcreateMutations(clear_data)}) 
          
        if (response.data.data.departmentCreateMutation.success) {
            setDepartmentIsShowing(false)
            setDepartmentEditData(null)
            resetForm()
            setLoad()
            setEdit(false)
            setDepartmentHead({value: "" , label: ""})
            toast.success('Successfully Saved', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
        } else{ 
          let errorData = ShowErrorNoties(response.data.data.departmentCreateMutation.errors)
          toast.error( errorData , {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            }); 
        }
         
      } catch(error){
          let errorData = ShowErrorNoties(error) 
          toast.error( errorData , {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            }); 
      } 
    }

    // retun object; before close check changes
    function close() {
    setDepartmentIsShowing(false)
    setDepartmentEditData(null)
    setEdit(false)
    }
    useEffect(()=>{
        if (OptionsDataList.name === "userdata"){
          setUserOptions(OptionsDataList?.value?.items?.map((item) => ({value: item?.id, label: item?.username})))
        } else{
          setUserOptions([])
        }   
     },[OptionsDataList])
    // retun null; to fetch options
    const getOption =(e, query, name)=>{ 
        let search_term = e.trim().replace(/"/g, '\\"');
        if (query=== "userOptionsQuery" && search_term){ 
            dispatch(fetchOptionsData(QueryData.userdataOptionsQuery(search_term),"userdata"))
        }   
      } 

    useEffect(()=>{ 
        if (departmentEditData !== null){  
            setEdit(true)
            initialDePartMent.id = departmentEditData?.['id']
            initialDePartMent.name = departmentEditData?.['name']
            initialDePartMent.department_head_user_id = departmentEditData?.['departmentHeadUserId']?.['id']
            initialDePartMent.createdBy =  departmentEditData?.['createdBy']?.['id']
            initialDePartMent.HistoryDetails = departmentEditData?.['historyDetails']
            if (departmentEditData?.['departmentHeadUserId']?.['id']){
                setDepartmentHead({value: departmentEditData?.['departmentHeadUserId']?.['id']
                , label: departmentEditData?.['departmentHeadUserId']?.['username']})
            }
            setFormKey(prve => prve+1)
        } else{ 
            setEdit(false)
            initialDePartMent.id = ""
            initialDePartMent.name = ""
            initialDePartMent.department_head_user_id = ""
            initialDePartMent.createdBy =  ""
            setDepartmentHead({value: "" , label: ""})
        }
         
    },[departmentEditData])
    
    const handledeleteConfomation = (data) => {  
        handltoastDeleteConfomationShow(); 
        setDeleteData({ Name:data?.name, id: data?.id}); 
    }
     //  after delete clear the data
    const handleDeletedId = (deleted_id) =>{ 
        if (deleted_id){ 
          setDepartmentIsShowing(false)  
          setEdit(false)
          setLoad()
        } 
      }
  return (
   <>
   {<ToastContainer/>}
   <HistoryData
   historyIds = {historyIds}
   setHistoryIds= {setHistoryIds} 
   />

   <Modal show={departmentIsShowing} onHide={close} size='lg'>
        <div className='row mx-1'>
            <div className='col-5 m-3' style={{color:"#5CADA9"}}>
                <Modal.Title>
                    <i className='fa-solid fa-arrow-left fa-sm me-2' onClick={close}>
                    </i>Department { "-"+departmentEditData?.['No']}
                </Modal.Title> 
            </div>
            <div className="col-6 mt-4 text-end" >
                <Can I={"Edit"} a={"Department"}>
                    <i className="fa-solid fa-pen fs-5 text-primary pe-3"
                    hidden={!(departmentEditData && edit)}
                    onClick={()=>{setEdit(!edit)}}  ></i>
                </Can>
                <Can I={"Delete"} a={"Department"}>
                    <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " 
                    hidden={!(departmentEditData && edit)}
                    onClick={()=>{handledeleteConfomation(departmentEditData)}}
                    ></i> 
                </Can>
                <Can I={"Historys"} a={"Department"}>
                    <TbHistory type="button" className='fs-4 ms-3' data-bs-toggle="offcanvas" 
                    hidden={!(departmentEditData && edit)} 
                    data-bs-target="#History" 
                    onClick={()=>{setHistoryIds(departmentEditData?.historyDetails)}}
                    />
                </Can>
               
                
                
            </div>
            
            <hr/>
           
        </div>
        <Modal.Body>
            <Formik initialValues = {initialDePartMent}
            validationSchema={departmentSchema}
            onSubmit={handleSubmit}>
                {({errors, touched, isSubmitting , setFieldValue, values})=>{
                    return(
                        <Form>
                            <div className='row'>
                                <div className="col-6 staan-input-group"> 
                                    <Field type="text" name="name" disabled={(departmentEditData && edit)}  placeholder="Name"className='w-100 staan-form-input'/>
                                    <label htmlFor="Name" className='staan-form-input-label pt-1 px-1 mx-2'  >Name</label>
                                    {touched.name &&  errors.name && <small className='text-danger' >{errors.name}</small>}
                                </div>
                                <div className="col-6 staan-input-group">
                                    <Select
                                       onInputChange={(e)=>{getOption(e, "userOptionsQuery")}}
                                        name="department_head_user_id"
                                        isClearable   
                                        options={userOptions}
                                        isDisabled={(departmentEditData && edit)}
                                        isSearchable={true}
                                        value={departmentHead}
                                        onChange={(option) => {
                                            setFieldValue('department_head_user_id', option ? option?.value : null);
                                            setDepartmentHead(option)
                                       }}     
                                        styles={customSelectStyle}     
                                        className='my-3'            
                                    />
                                        <label htmlFor="Group" className='staan-form-input-label pt-1 px-1 mx-2'>
                                        Department Head </label>
                                        {  touched.department_head_user_id &&  errors.department_head_user_id && <small>{errors.department_head_user_id}</small>}
                                </div>
                                <div className="row">
                                    <div className="text-end">
                                    <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={close}  >Cancel</button>
                                    {/*  I={"Create"} a={"Department"} */}
                                    <Can allow_list={["Save_Department","Edit_Department"]}>
                                        <button type="submit" className="btn btn-sm btn-outline-success shadow-sm px-3" disabled={isSubmitting} >Save</button>
                                    </Can>
                                    
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>

        </Modal.Body>

</Modal>
{
    deleteData.Name && <DeleteConformation
    handleDeletedId={(id)=>{handleDeletedId(id)}}
    deleteData={deleteData} 
    fullquery={departmentDeleteMutations(deleteData?.id)}
    queryName={"departmentDeleteMutation"}
    />
}
</>
)
}

export default DePartMentModel