import {
  FETCH_ITEM_MASTER_DATA_REQUEST,
  FETCH_ITEM_MASTER_DATA_SUCCESS,
  FETCH_ITEM_MASTER_DATA_FAILURE,
  CLEAR_ITEM_MASTER_DATA,
  FETCH_GST_TYPE_DATA_REQUEST,
  FETCH_GST_TYP_DATA_SUCCESS,
  FETCH_GST_TYP_DATA_FAILURE,
} from '../Action/itemMasterAction';

import {
  FETCH_OPTIONS_DATA_REQUEST,
  FETCH_OPTIONS_DATA_SUCCESS,
  FETCH_OPTIONS_DATA_FAILURE,
} from '../Action/itemMasterAction'; 

import {
  FETCH_ITEM_DISPLAY_DATA_REQUEST,
  FETCH_ITEM_DISPLAY_DATA_SUCCESS,
  FETCH_ITEM_DISPLAY_DATA_FAILURE,
} from '../Action/itemMasterAction'; 
  
const initialState = {
  data: [],
  isLoading: false,
  error: null,
};
  
export const itemMasterReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ITEM_MASTER_DATA_REQUEST:
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case FETCH_ITEM_MASTER_DATA_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
        };
      case FETCH_ITEM_MASTER_DATA_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      case CLEAR_ITEM_MASTER_DATA:
          return {
            data: [],
            isLoading: false,
            error: null,
        };
      default:
        return state;
    }
};

export const itemOptionsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_OPTIONS_DATA_REQUEST:
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case FETCH_OPTIONS_DATA_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
        };
      case FETCH_OPTIONS_DATA_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      
      default:
        return state;
    }
};

export const itemDisplayGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ITEM_DISPLAY_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_ITEM_DISPLAY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case FETCH_ITEM_DISPLAY_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const gstTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GST_TYPE_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_GST_TYP_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case FETCH_GST_TYP_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}