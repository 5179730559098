import axiosInstance from "../../api/axoiss";

// Action Types
export const FETCH_STOCK_SERIAL_HISTORY_DATA_REQUEST = 'FETCH_STOCK_SERIAL_HISTORY_DATA_REQUEST';
export const FETCH_STOCK_SERIAL_HISTORY_DATA_SUCCESS = 'FETCH_STOCK_SERIAL_HISTORY_DATA_SUCCESS';
export const FETCH_STOCK_SERIAL_HISTORY_DATA_FAILURE = 'FETCH_STOCK_SERIAL_HISTORY_DATA_FAILURE';
// serial number action types
export const FETCH_STOCK_SERIAL_NUMBER_DATA_REQUEST = 'FETCH_STOCK_SERIAL_NUMBER_DATA_REQUEST';
export const FETCH_STOCK_SERIAL_NUMBER_DATA_SUCCESS = 'FETCH_STOCK_SERIAL_NUMBER_DATA_SUCCESS';
export const FETCH_STOCK_SERIAL_NUMBER_DATA_FAILURE = 'FETCH_STOCK_SERIAL_NUMBER_DATA_FAILURE';
// batch number action types
export const FETCH_STOCK_BATCH_NUMBER_DATA_REQUEST = 'FETCH_STOCK_BATCH_NUMBER_DATA_REQUEST';
export const FETCH_STOCK_BATCH_NUMBER_DATA_SUCCESS = 'FETCH_STOCK_BATCH_NUMBER_DATA_SUCCESS';
export const FETCH_STOCK_BATCH_NUMBER_DATA_FAILURE = 'FETCH_STOCK_BATCH_NUMBER_DATA_FAILURE';

// Action Creators
const fetchStockSerialHistoryDataRequest = () => {
  return {
    type: FETCH_STOCK_SERIAL_HISTORY_DATA_REQUEST,
  };
};

const fetchStockSerialHistoryDataSuccess = (data) => {
  return {
    type: FETCH_STOCK_SERIAL_HISTORY_DATA_SUCCESS,
    payload: data,
  };
};

const fetchStockSerialHistoryDataFailure = (error) => {
  return {
    type: FETCH_STOCK_SERIAL_HISTORY_DATA_FAILURE,
    payload: error,
  };
};

// serial number action creator
const fetchStockSerialNumberDataRequest = () => {
  return {
    type: FETCH_STOCK_SERIAL_NUMBER_DATA_REQUEST,
  };
};

const fetchStockSerialNumberDataSuccess = (data) => {
  return {
    type: FETCH_STOCK_SERIAL_NUMBER_DATA_SUCCESS,
    payload: data,
  };
};

const fetchStockSerialNumberDataFailure = (error) => {
  return {
    type: FETCH_STOCK_SERIAL_NUMBER_DATA_FAILURE,
    payload: error,
  };
};

// batch number action creator
const fetchStockBatchNumberDataRequest = () => {
  return {
    type: FETCH_STOCK_BATCH_NUMBER_DATA_REQUEST,
  };
};

const fetchStockBatchNumberDataSuccess = (data) => {
  return {
    type: FETCH_STOCK_BATCH_NUMBER_DATA_SUCCESS,
    payload: data,
  };
};

const fetchStockBatchNumberDataFailure = (error) => {
  return {
    type: FETCH_STOCK_BATCH_NUMBER_DATA_FAILURE,
    payload: error,
  };
};

// Thunk Action Creator
export const fetchStockSerialHistoryData = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchStockSerialHistoryDataRequest());
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
      .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        dispatch(fetchStockSerialHistoryDataSuccess(data));
      })
      .catch(error => {
        dispatch(fetchStockSerialHistoryDataFailure(error.message));
      });
  };
};


export const fetchSerialNumberData = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchStockSerialNumberDataRequest());
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
      .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        dispatch(fetchStockSerialNumberDataSuccess(data));
      })
      .catch(error => {
        dispatch(fetchStockSerialNumberDataFailure(error.message));
      });
  };
}


export const fetchBatchNumberData = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchStockBatchNumberDataRequest());
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
      .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        dispatch(fetchStockBatchNumberDataSuccess(data));
      })
      .catch(error => {
        dispatch(fetchStockBatchNumberDataFailure(error.message));
      });
  };
}