import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { TbHistory } from "react-icons/tb";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles
import Select from 'react-select';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import Can from "../../../components/authorizationComponents/Can";
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import DataContext from '../../../context/ItemMasterContext';
import { TermsConditionsCreateMutations, termsConditionsDeleteMutations } from '../../../GraphQLQueries/HrMutation';
import { ShowErrorNoties, customSelectStyle, removeEmptyValueInObject } from '../../../utils/Utils';
import { termsConditionsSchema } from '../../../validations/Hr';
import HistoryData from '../../ItemMaster/model/HistoryData';

const TermsConditionsModel = ({setTermsConditionsEditData, termsConditionsIsShowing, setLoad, setTermsConditionsIsShowing, termsConditionsEditData}) => {
  const {userId, historyIds, setHistoryIds,handltoastDeleteConfomationShow} = useContext(DataContext)
  const [initialTermsConditions, setInitialTermsConditions] = useState({
    id : "",
    name : "",
    tc : "",
    module: "",
    HistoryDetails : "",
    modified_by : "",
    createdBy : ""
  })
  const [edit, setEdit]= useState(true)
  const [deleteData, setDeleteData] = useState({Name:"",id:""})
  const [termsConditions, setTermsConditions]= useState(null)

  let moduleOptions = [
                  {value:"Purchase" , label:"Purchase"},
                  {value:"Sales" , label:"Sales"},
                  {value:"Account" , label:"Account"},
                  {value:"Service" , label:"Service"},
                  {value:"Production" , label:"Production"},
                  {value:"HR" , label:"HR"},
                  {value:"Quotations" , label:"Quotations"},
                  {value:"Sales_order" , label:"Sales_order"}]
  const modules = {
    toolbar: [
    //   [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    //   [{size: []}],
    //   ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'},
      {'indent': '-1'}, {'indent': '+1'}],
    //   ['link', 'image', 'video'],
      ['clean']
    ],
  };

  const [moduleValue, setModuleValue] = useState(null)
  const handleSubmit = async(values, { setSubmitting, resetForm })=>{
    let save_data = {
        id : initialTermsConditions?.id? Number(initialTermsConditions?.id): "",
        name: values?.['name'],
        module : values?.['module'],
        tc: values?.['tc'],
        createdBy: initialTermsConditions?.id ? Number(initialTermsConditions?.createdBy) : Number(userId),
        modifiedBy:initialTermsConditions?.id ? Number(userId) : ""
    }   
    let allow_null = ['id',"modifiedBy"]
    let clear_data = removeEmptyValueInObject(save_data, allow_null) 
    try{
        const response = await axiosInstance.post("/itemmaster/graphql",  {query: TermsConditionsCreateMutations(clear_data)}) 
          
        if (response.data.data.termsConditionsCreateMutation.success) {
            resetForm()
            setLoad()
            close()
            toast.success('Successfully Saved', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
        } else{ 
          let errorData = ShowErrorNoties(response.data.data.termsConditionsCreateMutation.errors)
          toast.error( errorData , {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            }); 
        }
         
      } catch(error){
          let errorData = ShowErrorNoties(error) 
          toast.error( errorData , {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            }); 
      } 
    }
  function close(params) {
    setTermsConditionsEditData(null)
    setTermsConditions(null)
    setModuleValue(null)
    setTermsConditionsIsShowing(false)
    
    setInitialTermsConditions({
        id : "",
        name:"",
        module :"",
        tc : "",
        createdBy:"",
        HistoryDetails:"",
        modified_by:"" 
    }) 
    setEdit(true)
  }
  useEffect(()=>{
    if (termsConditionsEditData !== null){
        initialTermsConditions.id = termsConditionsEditData?.id
        initialTermsConditions.name = termsConditionsEditData?.name
        initialTermsConditions.module = termsConditionsEditData?.module
        initialTermsConditions.tc = termsConditionsEditData?.tc
        if (initialTermsConditions.module){
            setModuleValue({ value: termsConditionsEditData?.module, label: termsConditionsEditData?.module})
        }
        if (initialTermsConditions.tc){
            setTermsConditions(initialTermsConditions.tc)
        }
        initialTermsConditions.createdBy  = termsConditionsEditData?.createdBy?.id
    } else{
        initialTermsConditions.id = ""
    }
    
  },[termsConditionsEditData])
  const handledeleteConfomation = (data) => {
    handltoastDeleteConfomationShow();
    setDeleteData({ Name:data?.name, id: data?.id});
  }
  const handleDeletedId = (deleted_id) =>{
    if (deleted_id){
      setTermsConditionsIsShowing(false)
      setEdit(false)
      setLoad()
      close()
    } 
  }

  return (
    <>
    <ToastContainer/>
    <HistoryData
    historyIds = {historyIds}
    setHistoryIds = {setHistoryIds}/>

    <Modal show={termsConditionsIsShowing} size='lg' onHide={close}>
        <div className='row mx-1'>
            <div className='col-5 m-3' style={{color:"#5CADA9"}}>
                <Modal.Title>
                    <i className='fa-solid fa-arrow-left fa-sm me-2' onClick={close}>
                    </i>Terms Conditions
                </Modal.Title> 
            </div>
            <div className="col-6 mt-4 text-end" >
              <Can I="Edit" a="Terms_Conditions">
                <i className="fa-solid fa-pen fs-5 text-primary pe-3"
                  hidden={!(termsConditionsEditData && edit)} 
                  onClick={()=>{setEdit(!edit)}}  ></i>
              </Can>
              <Can I="Delete" a="Terms_Conditions">
                <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " 
                  hidden={!(termsConditionsEditData && edit)} 
                  onClick={()=>{handledeleteConfomation(termsConditionsEditData)}}
                  ></i> 
              </Can>
              <Can I="Historys" a="Terms_Conditions">
                <TbHistory type="button" className='fs-4 ms-3' data-bs-toggle="offcanvas" 
                  hidden={!(termsConditionsEditData && edit)} 
                  data-bs-target="#History" 
                  onClick={()=>{setHistoryIds(termsConditionsEditData?.historyDetails)}}
                  />
              </Can>
                
                
            </div>
            
            <hr/>
        </div>
        <Modal.Body>
            <Formik initialValues={initialTermsConditions}
            validationSchema={termsConditionsSchema} onSubmit={handleSubmit}>
                {({errors, touched, isSubmitting , setFieldValue, values})=>{
                    return(
                        <Form>
                            <div className="row">
                                <div className="col-6 staan-input-group">
                                  <Field type="text" name="name"   placeholder="Name" disabled={(termsConditionsEditData && edit)} className='w-100 staan-form-input'/>
                                  <label htmlFor="Name" className='staan-form-input-label pt-1 px-1 mx-2'  >Name</label>
                                    {touched.name &&  errors.name && <small className='text-danger' >{errors.name}</small>}
                                </div>
                                <div className="col-6 staan-input-group">
                                    <Select
                                        name="module"
                                        isClearable
                                        options={moduleOptions}
                                        isSearchable={true}
                                        isDisabled={(termsConditionsEditData && edit)}
                                        value={moduleValue}
                                        onChange={(option) => {
                                            setFieldValue('module', option ? option?.value : null);
                                            setModuleValue(option)
                                        }}
                                        styles={customSelectStyle}
                                        className='my-3'
                                    />
                                    <label htmlFor="module" className='staan-form-input-label pt-1 px-1 mx-2'>
                                    Module </label>
                                    {  touched.module &&  errors.module && <small>{errors.module}</small>}
                                </div>
                                <div className="col-12 staan-input-group"    >
                                  <label htmlFor="Name" className='  pt-1 px-1 mx-2'  >Terms Conditions</label>
                                    <ReactQuill
                                    value={termsConditions}
                                    readOnly={termsConditionsEditData && edit}
                                    onChange={(e)=>{
                                      setTermsConditions(e)
                                      setFieldValue('tc', e);
                                    }}
                                    modules={modules} />
                                    {touched.tc &&  errors.tc && <small className='text-danger' >{errors.tc}</small>}
                                </div>
                                <div className="row mt-2">
                                    <div className="text-end">
                                    <Can allow_list={["Save_Terms_Conditions","Edit_Terms_Conditions"]}>
                                      <button type="button" className="btn btn-outline-danger btn-sm me-2"
                                      onClick={close}
                                      >Cancel</button>
                                    </Can>
                                    
                                    <button type="submit" className="btn btn-sm btn-outline-success shadow-sm px-3" disabled={isSubmitting} >Save</button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Modal.Body>
    </Modal>
    {deleteData.Name && <DeleteConformation
    handleDeletedId={(id)=>{handleDeletedId(id)}}
    deleteData={deleteData}
    fullquery={termsConditionsDeleteMutations(deleteData?.id)}
    />
    }
    </>
  )
}

export default TermsConditionsModel