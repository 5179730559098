import React, { useContext, useState } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import DataContext from '../../context/ItemMasterContext'
import TableView from '../../Packages/Custom/DataTable'
import RoleForm from './Models/RoleForm'

const RoleTable = () => {
    const {screenSize} = useContext(DataContext)
    const [isLoad, setLoad] = useState(false)
    const [isShow, setIsShow] = useState(false)
    const [editData, setEditData] = useState(null)
    const CustomAddModalComponent = () => { 
        setIsShow(true)
    }

    const CustomViewModalComponent = (data) => {
        setEditData(data)
        setIsShow(true) 
    }
    const headers = [
        {
          header: 'Role name',
          field: 'roleName', 
          flex: 10,   
          inputType: 'text',  
          renderComponent: CustomViewModalComponent,
        }, 
        {
          header: 'Description',
          field: 'descriptions', 
          flex: 10,
          inputType: 'text',
        },

    ]
  return (
    <>
      <div className=' index_page_aliment_warp'>
          <div className='index_page_aliment_sidebar'>
            <SideNavbar />
          </div>
          <div className='index_page_aliment_table'>
            <TableView
                  headers={headers}
                  query={`RoleQuery`}
                  addComponent = {CustomAddModalComponent}
                  loadComponent = {isLoad}
                  permission={"Save_Roles"}
                  titleName = {'Roles'}
                  heightAdjust = {`${(screenSize?.height/20)}rem`}
                />
          </div>
      </div>

      <RoleForm
            isShow={isShow}
            setIsShow={setIsShow}
            setLoad={()=>{setLoad(!isLoad)}}
            editData={editData}
            setEditData={setEditData}/>
    
    </>
  )
}

export default RoleTable