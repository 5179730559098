import React, { useContext, useState } from 'react';
import Select from 'react-select';
import axiosInstance, { commanFetch } from '../../../api/axoiss';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import DataContext from '../../../context/ItemMasterContext';
import { otherIncomeChargesDeleteMutation, quotationsOtherIncomeChargesCreateMutation } from '../../../GraphQLQueries/OtherIncomeChargesQuery/OtherIncomingChargesMutation';
import { discountClearAllQuery, otherIncomeChargesList } from '../../../GraphQLQueries/QuatationqueryAndMutatiom/quatationQuery';
import { removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import { useQuotationContext } from '../Context/quotationContext';
import Discount from '../Popup/overallDiscount';

const OtherIncomeCharges = ({ stateValue, editView, setisSaveOtherIncomeCharges }) => {
  const { userId, handltoastDeleteConfomationShow } = useContext(DataContext)
  const { itemDetailsData, setItemDetailsData, otherIncomeChargesData, setOtherIncomeChargesData, 
    isAfterOverallDiscount, setOverallDiscountPercentage, setOverallDiscountValue, setFinalTotal,currencyData,loading, 
    setLoading } = useQuotationContext();
  const [accountList, setAccountList] = useState([])

  const [errorMessages, setErrorMessages] = useState([]);
  const [onNewItemSelect, setonNewItemSelect] = useState(false)
  const [deleteData, setdeleteData] = useState({ Name: '', id: '' })
  const [isUpdated, setisUpdated] = useState(false)
  const [showDiscount, setShowDiscount] = useState(false);
  const [otherIncomeValue, setOtherIncomeValue] = useState({ editIndex: '', account: "", tax: "", amount: "", id: "", createdBy: '' })


  const clearDiscountData = async (itemId, otherIncomeId) => {
    // 
    if (itemId?.length > 0) {
      const requestBody = {
        ids: itemId,
        otherIncomeId: otherIncomeId.length > 0 ? otherIncomeId : [],
      };
      const response = await commanFetch(discountClearAllQuery(requestBody));
      const responseData = response?.data;
      setItemDetailsData(responseData?.items);
      const transformedOtherIncomeChargesData = responseData?.quotationsOtherIncomeCharges?.map(item => ({
        ...item,
        account: {
          label: item?.otherIncomeChargesId?.name,
          tax: item?.otherIncomeChargesId?.hsn?.gstRates?.rate,
          value: item?.otherIncomeChargesId?.id,
        },
        tax: item?.otherIncomeChargesId?.hsn?.gstRates?.rate
      }));
      setOtherIncomeChargesData(transformedOtherIncomeChargesData);
      setOverallDiscountPercentage('')
      setOverallDiscountValue('')
      setFinalTotal('')
    }
  };



  const handleNewRow = () => {
    setonNewItemSelect(true)
    const newRow = {
      id: '',
      index: otherIncomeChargesData ? otherIncomeChargesData.length === 0 ? 1 : otherIncomeChargesData.length + 1 : 1,
      account: '',
      tax: '',
      amount: '',
      createdBy: '',
      isEditing: true,
      afterDiscountValue: '',
      discountValue: ''
    }
    setOtherIncomeValue(prve => ({ ...prve, editIndex: otherIncomeChargesData ? otherIncomeChargesData.length: 0}))

    setOtherIncomeChargesData([...otherIncomeChargesData, newRow])
  }


  const handleSaveRow = async index => {
    let requestBody = {
      id: otherIncomeValue.id ? Number(otherIncomeValue.id) : '',
      otherIncomeChargesId: Number(otherIncomeValue?.account?.value),
      amount: otherIncomeValue.amount,
      createdBy: otherIncomeValue?.id ? Number(otherIncomeValue?.createdBy?.id) : Number(userId),
      tax: otherIncomeValue?.tax ? Number(otherIncomeValue?.tax) : "",
      states: stateValue
    };    
    
    let ClearValue
    if (otherIncomeValue.id) {
      requestBody.modifiedBy = Number(userId);
      ClearValue = removeEmptyValueInObject(requestBody, [])
    }
    else {
      ClearValue = removeEmptyValueInObject(requestBody, ['id', 'modifiedBy'])
    }
    try {
      setLoading(true)
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query: quotationsOtherIncomeChargesCreateMutation(ClearValue)
      })
      const responseData = response?.data?.data?.quotationsOtherIncomeChargesCreateMutation
      if (responseData.success) {
        console.log(responseData);
        
        setisUpdated(true)
        const updatedRows = otherIncomeChargesData?.map((row, i) => {
          if (i === index) {
            return {
              ...row,
              id: responseData?.quotationsOtherIncomeCharges?.id ?? row.id,
              amount: responseData?.quotationsOtherIncomeCharges?.amount ?? row.amount,
              account: {
                value: responseData?.quotationsOtherIncomeCharges?.otherIncomeChargesId?.id ?? row.account.value,
                label: responseData?.quotationsOtherIncomeCharges?.otherIncomeChargesId?.name ?? row.account.label,
                tax: responseData?.quotationsOtherIncomeCharges?.otherIncomeChargesId?.hsn?.gstRates?.rate ?? row.account.tax,
              },
              tax: responseData?.quotationsOtherIncomeCharges?.tax,
              createdBy: Number(responseData?.quotationsOtherIncomeCharges?.createdBy?.id),
              currencyId: Number(currencyData?.value),
              isEditing: false
            };
          }
          return row; // Return the original row if no changes are made
        });

        setisSaveOtherIncomeCharges(true)
        await
          

        setOtherIncomeChargesData(updatedRows)
        if (isAfterOverallDiscount) {
          const idList = itemDetailsData?.map(item => parseInt(item?.id, 10));
          console.log("otherIncomeChargesData-------------", otherIncomeChargesData);

          const otherIncomeIds = otherIncomeChargesData.filter(item => item?.id && item.id.trim() !== '').map(item => Number(item.id));
          const newId = Number(responseData?.quotationsOtherIncomeCharges?.id)
          otherIncomeIds.push(newId)
          await clearDiscountData(idList, otherIncomeIds);

        }
        setOtherIncomeValue(prve => ({ ...prve, editIndex: "", account: "", tax: "", amount: "" }))
        setonNewItemSelect(false)
        setLoading(false)
      } else {
        let errorData = ShowErrorNoties(responseData.errors)
        showErrorToast(true, 'error', errorData)
        setLoading(false)
      }
    } catch (error) {
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorData)
      setLoading(false)
    }

  };

  // Prepares to delete a row by setting the selected row's data for confirmation.
  // Does not return any value.
  const handledeleteConfomation = (index) => {
    const updatedRow = otherIncomeChargesData[index];
    setonNewItemSelect(false)
    if (updatedRow) {
      handltoastDeleteConfomationShow()
      setdeleteData({
        Name: updatedRow?.account?.label,
        id: updatedRow?.id
      })
    }
  }

  //   Removes a row from the state based on the deleted ID.
  // Does not return any value.
  const handleDeletedId = deleted_id => {
    if (deleted_id) {
      console.log("deleted Id-------------", deleted_id);
      setOtherIncomeChargesData(prevRowData =>
        prevRowData.filter(row => row.id !== deleted_id)
      );
      setonNewItemSelect(false)
      setisUpdated(true)
      setdeleteData({ Name: '', id: '' })
    }
  }

  async function getOption(e, queryName) {
    let search_term = e.trim().replace(/"/g, '\\"')

    if (queryName === 'accountList' && search_term) {
      let param = `name:"${search_term}"`
      const response = await commanFetch(otherIncomeChargesList(param))
      if (response.success) {
        const responseData = response?.data?.items
        const activeItems = responseData?.filter(item => item?.account?.accountsActive);
        setAccountList(
          activeItems?.map(data => ({
            value: data?.id,
            label: data?.name,
            tax: data?.hsn?.gstRates?.rate,
          }))
        );
      }
    } else {
    }
  }

  const renderAmount = (item, rowData) => {
    let amount = item?.amount !== undefined ? item?.amount : 0;
    let discountValue = item?.discountValue !== undefined ? item?.discountValue : 0;
    let afterDiscountValue = item?.afterDiscountValue !== undefined ? item?.afterDiscountValue : 0;    
    if (discountValue) {
      return `${amount} - ${discountValue} = ${afterDiscountValue}`;
    }
    else {
      return `${amount}`
    }

  };



  return (
    <>
      
      {showDiscount &&
        <Discount
          itemDetailsData={itemDetailsData}
          setItemDetailsData={setItemDetailsData}
          setShowDiscount={setShowDiscount}
          showDiscount={showDiscount}
          setOtherIncomeCharges={setOtherIncomeChargesData}
          setafterUpdated={setisUpdated}
        />
      }
      <div className='card shadow-lg p-3'>
        <h6 className='commanModelTitleColor p-2'>Other Income Charges</h6>
        <div id='staan-input-table' style={{ height: '12rem', overflowY: 'auto' }}>
          <table
            className='StaanTable'
            // style={{
            //   width: '66rem',
            //   borderCollapse: 'collapse',
            // }}
          >
            <thead>
              <tr style={{ fontSize: '.9rem', height: '2rem' }}>
                <th style={{ width: '15rem' }} onClick={() => {
                  console.log(otherIncomeChargesData);
                }}  >Account</th>
                <th style={{ width: '8rem' }} onClick={()=>{console.log("editview",editView);
                }}>Tax</th>
                <th style={{ width: '15rem' }}>Amount</th>
                <th style={{ width: '10rem' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {otherIncomeChargesData?.map((item, rowIndex) => (
                <tr key={rowIndex} style={{ height: '2rem' }}>
                  <td style={{padding: '0.5rem' }}>
                    {otherIncomeValue?.editIndex === rowIndex ? (
                      <Select
                        onInputChange={e => { getOption(e, 'accountList'); }}
                        options={accountList}
                        value={otherIncomeValue?.account}
                        onChange={e => {
                          setOtherIncomeValue(prve => ({ ...prve, account: e, tax: e.tax, id: item?.id, createdBy: item?.createdBy }))
                          // handleInputChange(rowIndex, 'account', e);
                          // handleInputChange(rowIndex, 'tax', );
                        }}
                        className='staan-input-group my-3'
                      />
                    ) : (
                      item?.account?.label
                    )}
                    {errorMessages[rowIndex]?.amount && (
                      <span style={{ color: 'red', marginLeft: '0.5rem' }}>
                        {errorMessages[rowIndex].amount}
                      </span>
                    )}
                  </td>

                  <td style={{padding: '0.5rem' }}>
                    {otherIncomeValue?.editIndex === rowIndex ? (                    
                      <input
                        type='text'
                        value={otherIncomeValue?.tax}
                        disabled
                        style={{ width: '100%', height: '38px' }} // Fixed height
                      />
                    ) : (
                      item.tax
                    )}
                  </td>
                  <td style={{padding: '0.5rem' }}>
                    {otherIncomeValue?.editIndex === rowIndex ? (
                      <input
                        type='text'
                        value={otherIncomeValue?.amount}
                        onChange={e =>
                          // handleInputChange(rowIndex, 'amount', e.target.value)
                          setOtherIncomeValue(prve => ({ ...prve, amount: e.target.value, id: item?.id, createdBy: item?.createdBy }))
                        }
                        style={{ width: '100%', height: '38px' }} // Fixed height
                      />
                    ) : (
                      renderAmount(item, otherIncomeChargesData)
                    )}
                    {errorMessages[rowIndex]?.account && (
                      <span style={{ color: 'red', marginLeft: '0.5rem' }}>
                        {errorMessages[rowIndex].account}
                      </span>
                    )}
                  </td>

                  <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {otherIncomeValue?.editIndex === rowIndex ? (
                        <>
                          <button type='button' className='save-button' onClick={() => handleSaveRow(rowIndex)} disabled={editView}>
                            Save
                          </button>
                          <button type='button' className='cancel-button' onClick={() => { 
                            setOtherIncomeValue({ editIndex: "", account: "", tax: "", amount: "", id: "", createdBy: item?.createdBy });
                            setonNewItemSelect(false)
                            setOtherIncomeChargesData(prev => {
                              
                              return prev?.filter(item => item?.id !== "" && item?.index !== rowIndex);
                            });
                            
                        }} 
                            disabled={editView}>
                            <i className='fa-solid fa-xmark'></i>
                          </button>


                        </>
                      ) : (
                        <>
                          <button type='button' className='edit-button' onClick={() => {
                            // handleInputChange(rowIndex, 'isEditing', true);
                            setOtherIncomeValue(prve => ({ ...prve, editIndex: rowIndex, account: item?.account, tax: item?.tax, amount: item.amount, createdBy: item?.createdBy }))
                            setonNewItemSelect(true);
                          }} disabled={editView}>
                            <i className='fa-solid fa-pen-to-square'></i>
                          </button>
                          <button type='button' className='delete-button' onClick={() => handledeleteConfomation(rowIndex)} disabled={editView}>
                            <i className='fa-solid fa-trash'></i>
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>

            <tfoot>
              <tr style={{ background: '#ffff' }}>
                <td colSpan={4}>
                  <button
                    className='btn btn-sm btn-outline-primary'
                    type='button'
                    onClick={handleNewRow}
                    style={{
                      borderRadius: 'none !important',
                      border: '1px solid'
                    }}
                    disabled={onNewItemSelect || !stateValue || editView}
                  >
                    <i className='fa-solid fa-plus'></i> New
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

      </div>



      {deleteData && (
        <DeleteConformation
          handleDeletedId={id => handleDeletedId(id)}
          deleteData={deleteData}
          fullquery={otherIncomeChargesDeleteMutation(deleteData?.id)}
        />
      )}
    </>
  )
}

export default OtherIncomeCharges
