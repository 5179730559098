import React, { useContext, useState } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import DataContext from '../../context/ItemMasterContext'
import TableView from '../../Packages/Custom/DataTable'
import SalesOrderForm from './model/SalesOrderForm'

const SalesOrder = () => {
    const {screenSize} = useContext(DataContext)
    const [isLoad, setLoad] = useState(false)
    const [isShow, setIsShow] = useState(false)
    const [editData, setEditData] = useState(null)
    const CustomAddModalComponent = () => {
        setIsShow(true)
    }
    const CustomViewModalComponent = (data) => {
        setEditData(data)
        setIsShow(true)
    }
    const headers = [
        {
            header: 'Sales Order No',
            field: 'salesOrderNo',
            label: "linkedModelId",
            flex: 10,
            inputType: 'text',
            renderComponent: CustomViewModalComponent,
        },
        {
            header: 'Customer Po No',
            field: 'customerPoNo',
            flex: 10,
            inputType: 'text',
        },
        {
            header: 'Department',
            field: 'department',
            label: "name",
            flex: 10,
            inputType: 'text',
        },
        {
            header: 'Costomer Name',
            field: 'buyer',
            label: "companyName",
            flex: 10,
            inputType: 'text',
        },
        {
            header: 'City',
            field: 'buyerAddress',
            label: "city",
            queryField: "city",
            flex: 10,
            inputType: 'text',
        },
        {
            header: 'State',
            field: 'buyerAddress',
            label: "state",
            queryField: "state",
            flex: 10,
            inputType: 'text',
        },
        {
            header: 'Net Amount',
            field: 'netAmount',
            flex: 10,
            inputType: 'number',
        },
        {
            header: 'Created By',
            field: 'createdBy',
            label: "username",
            flex: 10,
            inputType: 'text',
        },
        {
            header: 'Date',
            field: 'CreatedAt',
            flex: 10,
            inputType: 'text',
            isDateField: true,
        },
    ]
    return (
        <>
            <div className='container-fluid index_page_aliment_warp'>
                <div className='index_page_aliment_sidebar'>
                    <SideNavbar/>
                </div>
                <div className='index_page_aliment_table'>
                    <TableView
                        headers={headers}
                        query={"allSalesorder"}
                        addComponent={CustomAddModalComponent}
                        titleName={"Sales Order"}
                        loadComponent={isLoad}
                        heightAdjust={`${screenSize?.height/20}rem`}
                        permission={"Save_POS"}
                    />
                </div>
            </div>
            <SalesOrderForm
            isShow = {isShow}
            setIsShow = {setIsShow}
            setLoad = {()=>{setLoad(!isLoad)}}
            editData = {editData}
            setEditData={setEditData}
            />
            
        </>
    )
}

export default SalesOrder