import AddRouting from "./AddRouting";
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../api/axoiss';
import { BomRoutingDataFetch } from "../../../GraphQLQueries/BomMutation";
import { v4 as uuidv4 } from 'uuid';

const BomRouting = ({handleAddRoutingData, data, isEditView, isNew}) => {
    const [isshowAddRoutingModal, setShowAddRoutingModal] = useState(false)
    const handleCloseAddRouting = () => { setShowAddRoutingModal(false) }
    const handleShowAddRouting = () => { setShowAddRoutingModal(true) }
    const [routingData, setRoutingData] = useState([]);
    const [totalDuration, setTotalDuration] = useState(0)

    const handleAddedData = (data) => {
        if(data){
            data = data.filter(item => item !== undefined)
            let routing_data = data.map((item, index)=> ({...item, 'serialNumber': index+1}));
            let created_route_promise = [];
            let created_route_data = [];
            for(let route_item of routing_data){
                let routing_mutation_query = `mutation BomRoutingMutation{
                    bomRoutingCreateMutation(
                        route: ${route_item.value}, 
                        duration: ${route_item.duration}, 
                        workCenter: ${route_item.workCenter}, 
                        serialNumber: "${route_item.serialNumber}",`
                if(route_item?.id){
                    routing_mutation_query += `id: ${route_item.id}`
                }
                routing_mutation_query += `){
                      routing{
                        serialNumber
                        id
                        route{
                            id
                            routeName
                        }
                      }
                      success
                      errors
                    }
                }`
                let promise =  axiosInstance.post("/itemmaster/graphql", {query: routing_mutation_query})
                .then(res => {
                    let response = res?.data?.data?.bomRoutingCreateMutation
                    if(response.success){
                        let data_item = {
                            'id' : response.routing.id,
                            'serialNumber' : response.routing.serialNumber,
                            'label' : response.routing.route.routeName,
                            'value' : response.routing.route.id,
                        }
                        created_route_data.push(data_item)
                        return response.routing.id;
                    }else{
                        return null;
                    }
                }).catch(e => {
                    return null;
                })
                created_route_promise.push(promise);
            }
            created_route_data.sort((a, b) => a.serialNumber - b.serialNumber);
            Promise.all(created_route_promise)
            .then(created_route_ids => {
                setRoutingData(created_route_data)
                handleAddRoutingData(created_route_ids)
            });
        }
    }

    useEffect(()=>{
        if(data){
            let route_fetch_query = BomRoutingDataFetch(data);
            axiosInstance.post("/itemmaster/graphql", {query: route_fetch_query})
            .then(res => {
                let response = res?.data?.data?.bomRouting?.items;
                let route_data_list = response?.map(item => {
                    let updated_data = {...item}
                    updated_data['label'] =  updated_data?.['route']?.['routeName'];
                    updated_data['value'] =  updated_data?.['route']?.['id'];
                    updated_data['workCenterLabel'] =  updated_data?.['workCenter']?.['workCenter'];
                    updated_data['workCenter'] =  updated_data?.['workCenter']?.['id'];
                    return updated_data
                })
                route_data_list.sort((a, b) => a.serialNumber - b.serialNumber);
                let total_duration = route_data_list.reduce((acc, curr) => acc + parseInt(curr.duration), 0);
                setTotalDuration(total_duration)
                setRoutingData(route_data_list)
            }).catch(err => {
                console.log(err);
            })
        }
        if(isNew){
            setRoutingData([])
        }
    }, [data])

    return(
        <>
            <div className='row pt-3'>
                <div className='col-2 fw-bold'>
                    <h6>Process</h6>
                </div>
                <div>
                <div style={{width: '80%'}} className="mx-auto d-flex align-items-center">
                    {routingData && routingData.length > 0 && <div className="d-flex flex-column me-3 align-items-center">
                        <i className="fa-solid fa-play mx-2 text-success mb-2"></i>
                        <span>Work Center</span>
                        <span className="mt-1">Duration</span>
                        </div>}
                    {
                        routingData && routingData.map((item, index) => {
                            return <>
                                {index !== 0 ? 
                                    <div key={uuidv4()} className="d-flex flex-column mx-2 align-items-center">
                                        <i className="fa-solid fa-arrow-right mx-2 fs-6 mb-2"></i>
                                        <span style={{color: 'transparent'}}>t</span>
                                        <span style={{color: 'transparent'}}>t</span>
                                    </div>
                                :"" }
                                <div  key={uuidv4()} className="d-flex flex-column mx-2 align-items-center">
                                    <span 
                                        key = {uuidv4()}
                                        style={{fontSize: '.8rem', letterSpacing: '1px'}}
                                        className="badge rounded-pill bg-secondary mb-2">
                                    {item.label}</span>
                                    <span>{item?.workCenterLabel ? item.workCenterLabel : " "}</span>
                                    <span>{item?.duration ? item.duration +" Mins" : " "}</span>
                                </div>
                            </>
                        })
                    }
                    {routingData && routingData.length > 0 && <div className="d-flex flex-column mx-2 align-items-center">
                        <i className="fa-solid fa-circle mx-2 text-danger mb-2"></i>
                        <span style={{color: 'transparent'}}>t</span>
                        <span className="pt-1">{totalDuration + " Mins"} (Total)</span>
                    </div>}
                </div>
            </div>
                <div className="d-flex justify-content-center mt-4">
                    <div>
                        <button 
                            type="button" 
                            className="btn btn-sm btn-outline-primary" 
                            onClick={()=>{handleShowAddRouting()}}
                        >Select Routing</button>
                    </div>
                </div>
            </div>
            {isshowAddRoutingModal && 
                <AddRouting 
                    show = {isshowAddRoutingModal} 
                    handleClose = {handleCloseAddRouting} 
                    handleAddedData = {(d)=>{handleAddedData(d)}}
                    data = {routingData}
                    isEditView = {isEditView}
                /> 
            }
        </>
    )
}

export default BomRouting;