import React, { useState } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableView from '../../Packages/Custom/DataTable'
import ExpenseClaimModal from './modals/ExpenseClaimModal'

const ExpenseClaim = () => {
    const [isExpenseClaimsShow, setExpenseClaimsShow] = useState(false)
    const [isLoad, setLoad] = useState(false)
    const [claimEditData, setClaimEditData] = useState(null)
    const CustomViewModalComponent = (data) => {
        setClaimEditData(data)
        setExpenseClaimsShow(true)
    }
    const headers = [
        {
            header: 'Expense Claim No',
            field: 'expenseClaimNo',
            flex: 10,
            // inputType: 'text',
            renderComponent: CustomViewModalComponent,
        },
        {
            header: 'Expense Claim Date',
            field: 'expenseClaimDate',
            flex: 10,
        },
        {
            header: 'Employee ID',
            field: 'employeeId',
            label: "employeeId",
            flex: 10,
        },
        {
            header: 'Employee Name ',
            field: 'employeeId',
            label: "employeeName",
            flex: 10,
        },
        {
            header: 'DOE',
            field: 'expenseClaimDetails',
            label: "dateOfExp",
            flex: 10,
            // inputType: 'text',
        }
    ]
    const CustomAddModalComponent = () => {
        setExpenseClaimsShow(true)
    }
    return (
        <>
            <SideNavbar />
            <div className='container-lg'>
                <div id='custom-table-itemmaster'>
                    <TableView
                        headers={headers}
                        query={"ExpenseClaim"}
                        addComponent={CustomAddModalComponent}
                        loadComponent={isLoad}
                        titleName={"Expense Claim"}
                        heightAdjust={"40rem"}
                        permission={"View_Enquiry"}
                    />
                </div>
            </div>
            {
                isExpenseClaimsShow &&
                <ExpenseClaimModal
                    isExpenseClaimsShow={isExpenseClaimsShow}
                    setExpenseClaimsShow={setExpenseClaimsShow}
                    setLoad = {()=>{setLoad(!isLoad)}}
                    claimEditData = {claimEditData}
                    setClaimEditData={setClaimEditData}
                />


            }
        </>
    )
}


export default ExpenseClaim
