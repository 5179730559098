import * as yup from "yup";

export const  ExpenceCategoryValidation=yup.object().shape({
    categoryName : yup.string().required('Category Name is required'),
    accountName : yup.string().required('Account Name is required')
})


export const  ExpenceClaimValidation=yup.object().shape({
    employeeDetailID:yup.string().required('Employee ID is required'),
    employeeDetailName:yup.string().required('Employee Name is required'),
    claimDate:yup.string().required('Expense Claim Date is required'),
})

export const  ClaimDetailsPopUpValidation=yup.object().shape({
    doe:yup.string().required('DOE is required'),
    category:yup.string().required('Category is required'),
    description:yup.string().required('Description is required'),
    amount:yup.number().required('Description is required')
})


export const  ExpenceReconciliationValidation=yup.object().shape({
    employeeID:yup.string().required('Employee ID is required'),
    employeeName:yup.string().required('Employee Name is required'),
    advanceAmount:yup.number().required('Advance Due is required'),
    claimAmount:yup.number().required('Claim Due is required')
})
