import React from 'react';
import { useContext, useState} from 'react';
import DataContext from '../../../../context/ItemMasterContext';
import AddBOM from '../../AddBOM';

const TreeNode = ({ node }) => {
    const { children, label, icon, is_open, key } = node;
    const { setClickedKey, isKeyUpdated, setKeyUpdated} = useContext(DataContext);
    const [isShowAddBOMForm, setShowAddBOMForm] = useState(false);
    const [isLoad, setLoad] = useState(false);
    const [viewBomId, setViewBomId] = useState(null);

    const handleAddBOMShow = (id) => {
        setViewBomId(id)
        setShowAddBOMForm(!isShowAddBOMForm)
    }
    
    const handleCloseBOMShow = () => {
        setViewBomId(null)
        setShowAddBOMForm(!isShowAddBOMForm)
    }

    return (<>
        <div style={{width: '100%'}} className='mb-2'>
            <div style={{width: '100%'}} className='d-flex justify-content-between border-bottom border-secondary mb-1 pb-1'>
                <div>
                    {children && 
                        <i 
                            className={`fs-5 me-2 fa-solid fa-caret-${is_open ? "down": "right"}`} 
                            onClick={()=>{
                                setClickedKey(key); 
                                setKeyUpdated(!isKeyUpdated)
                            }}
                        >
                        </i>
                    }
                    <i 
                        style={{fontSize: '.7rem'}}
                        className={`${icon} me-1`}>
                    </i> 
                    <span 
                        
                        onClick={() => {node.is_bom && handleAddBOMShow(node.bom_id)}}
                        style={node.is_bom ? {cursor: 'pointer'} : {}}
                    >
                        <span className={node.name !== "Part Code" ? node.is_bom ? "text-success fw-bold" : "text-primary" : ""}>{label}</span>
                        { !node.is_bom && node.name !== 'Name' && <span className='ms-2'>- {node.name} </span> }
                        <span className='ms-2 text-success fw-normal'>{node.bomName ? `[${node.bomName}]`: ""}</span>
                    </span>
                </div>
                <div className='d-flex justify-content-between align-items-center' style={{paddingRight: '5rem'}}>
                    {/* <div className='me-3' style={{width: '25rem'}}>{node.name}</div> */}
                    <div className={`me-3 ${node.fixed === "No" ? "text-danger": ""}`} style={{width: '2rem'}}>{node.fixed}</div>
                    <div className='me-3' style={{width: '8rem', textAlign: "center"}}>{node.requiredQty}</div>
                    {/* <div className='me-3' style={{width: '8rem', textAlign: "center"}}>{node.availableQty}</div>
                    <div className='me-3' style={{width: '8rem', textAlign: "center"}}>{node.shortageQty}</div> */}
                </div>
            </div>
            <ul style={{padding: '0px !important', paddingInlineStart: '0px'}}>
                {
                    children && is_open && <Tree 
                        treeData = {children} 
                    />
                }
            </ul>
        </div>
        { isShowAddBOMForm && viewBomId && 
            <AddBOM 
                modal = {{id:1, show: isShowAddBOMForm}}
                handleCloseBOMShow = {handleCloseBOMShow}
                isShowAddBOMForm = {isShowAddBOMForm}
                isLoad = {isLoad}
                setLoad = {setLoad}
                currentBomId = {viewBomId}
                isEditDelOption = {false}
            />
        }
    </>);
}


const Tree = ({treeData}) => {
    return(
        <>
        <ul>
            {treeData.map((node) => (
                <TreeNode 
                    node={node} 
                    key={node.key} 
                />
            ))}
        </ul>
        </>
    )
}

export default Tree;