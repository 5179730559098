import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { TbHistory } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { ToastContainer } from 'react-toastify'
import axiosInstance from '../../../api/axoiss'
import Can from '../../../components/authorizationComponents/Can'
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation'
import showErrorToast from '../../../components/notifications/react-toastify/toast'
import DataContext from '../../../context/ItemMasterContext'
import { createNumberingSeries } from '../../../GraphQLQueries/ItemMastermutation'
import * as QueryData from '../../../GraphQLQueries/ItemmasterQuery'
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction'
import {
  checkChangesInputs,
  removeEmptyValueInObject,
  ShowErrorNoties
} from '../../../utils/Utils'
import { NumberingSchema } from '../../../validations/itemmaster'
import HistoryData from './HistoryData'

const NumberingModal = ({
  NumberingSeries,
  handleNumberingSeriesClose,
  numberingSeriesEdit,
  setNumberngSeriesEdit,
  setLoad
}) => {
  const { userId, historyIds, setHistoryIds, handltoastDeleteConfomationShow } =
    useContext(DataContext)
  const dispatch = useDispatch()
  const getData = useSelector(state => state.itemGroup.data)
  const [waringToCloseModel, setWaringToCloseModel] = useState(false)
  const [edit, setEdit] = useState(false)
  const [deleteData, setdeleteData] = useState({ Name: '', id: '' })
  const [formKey, setFormKey] = useState(0)
  const ResourceOptions = [
    { value: 'Pos', label: 'Pos' },
    { value: 'Purchase Order', label: 'Purchase Order' },
    { value: 'Goods Inward Note', label: 'Goods Inward Note' },
    { value: 'Goods Receipt Note', label: 'Goods Receipt Note' },
    { value: 'Quality Inspection Report', label: 'Quality Inspection Report' },
    { value: 'Rework Delivery Challan', label: 'Rework Delivery Challan' },
    { value: 'Purchase Return Challan', label: 'Purchase Return Challan' },
    { value: 'Quotations', label: 'Quotations' },
    { value: 'SalesOrder', label: 'SalesOrder' }
  ]
  const [departmentList, setDepartmentList] = useState([])
  const [departmentSelect, setDepartmentSelect] = useState([])
  const [resourceOptionsType, setResourceOptionsType] = useState([])
  const [SelectResource, setSelectResource] = useState({ value: '', label: '' })
  const handleSeletcResource = option => {
    setSelectResource({ value: option.value, label: option.label })
  }
  const [SelectReSourceIsPosType, setSelectReSourceIsPosType] = useState({
    value: '',
    label: ''
  })
  const handleReSourceIsPosType = option => {
    setSelectReSourceIsPosType({ value: option.value, label: option.label })
  }
  const [initialValue, setInitialValue] = useState({
    id: '',
    Name: '',
    Resource: '',
    Formate: '',
    Current_Value: 0,
    LastSerialHistory: 0,
    Default: true,
    Department: '',
    Active: true,
    ReSourceIsPosType: '',
    modified_by: '',
    createdby: '',
    HistoryDetails: ''
  })
  // retun null ; on edit retun value
  useEffect(() => {
    if (getData.name === 'PosType') {
      setResourceOptionsType(getData.value.items)
    }
    if (getData.name === 'NumberingEdit') {
      let responceData = getData.value.items[0]
      initialValue.id = responceData['id']
      initialValue.Name = responceData['numberingSeriesName']
      initialValue.Resource = responceData['resource']
      initialValue.Formate = responceData['formate']
      initialValue.Current_Value = responceData['currentValue']
      initialValue.LastSerialHistory = responceData['lastSerialHistory']
      initialValue.Default = responceData.default
      initialValue.Active = responceData.active
      initialValue.Department = responceData?.department?.id
      initialValue.ReSourceIsPosType = responceData?.posType?.id || null
      initialValue.createdby = responceData['createdBy']?.['id']
      initialValue.HistoryDetails = responceData['historyDetails']
        ? responceData['historyDetails']
        : ''
      if (initialValue.ReSourceIsPosType) {
        handleReSourceIsPosType({
          value: initialValue.ReSourceIsPosType,
          label: responceData['posType']['ReSourceIsPosType']
        })
      }
      if (initialValue.Resource) {
        handleSeletcResource({
          value: responceData['resource'],
          label: responceData['resource']
        })
      }
      setDepartmentSelect({
        value: responceData?.department?.id,
        label: responceData?.department?.name
      })
      setFormKey(prevKey => prevKey + 1)
    }
    if (getData.name === 'SerialNumberdepartmant') {
      setDepartmentList(getData.value.items)
    }
  }, [getData])

  // retun null; prefetech
  async function fetchData (id) {
    dispatch(fetchOptionsData(QueryData.PosTypeOptions(), 'PosType'))
    if (id) {
      dispatch(
        fetchOptionsData(QueryData.NumberingSeriesEdit(id), 'NumberingEdit')
      )
    }
  }
  // retun null; on edit set the value.
  useEffect(() => {
    if (numberingSeriesEdit) {
      fetchData(numberingSeriesEdit.id)
      setEdit(true)
    } else {
      setEdit(false)
    }

    fetchData()
  }, [numberingSeriesEdit])

  // retun null ; on save and close clear value.
  function resetdata (params) {
    setInitialValue({
      Name: '',
      Resource: '',
      Formate: '',
      Current_Value: 0,
      LastSerialHistory: 0,
      Department: '',
      Default: true,
      Active: true
    })
    setNumberngSeriesEdit()
    handleSeletcResource({ value: '', label: '' })
  }
  // retun null ; submit function.
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    let lastSerialHistory
    
    if ((values['Resource'] ==="SalesOrder" || values['Resource'] === 'Quotations') &&( values?.['Department'] === "" || values?.['Department'] === null)){
      showErrorToast(true, 'error', `Department is required`, '')
      return ""
    }
    if (
      Number(initialValue.Current_Value) !== Number(values['Current_Value'])
    ) {
      lastSerialHistory = values['Current_Value']
    } else {
      lastSerialHistory = Number(values['LastSerialHistory'])
    }
    let saveValue = {
      id: numberingSeriesEdit ? numberingSeriesEdit.id : '',
      numberingSeriesName: values['Name'],
      resource: values['Resource'],
      formate: values['Formate'],
      currentValue: Number(values['Current_Value']),
      lastSerialHistory: Number(lastSerialHistory),
      default: values['Default'],
      active: values['Active'],
      department: values['Department'] ? Number(values['Department']) : '',
      posType: values['ReSourceIsPosType']
        ? Number(values['ReSourceIsPosType'])
        : '',
      modifiedBy: numberingSeriesEdit ? Number(userId) : '',
      createdBy: numberingSeriesEdit
        ? Number(initialValue.createdby)
        : Number(userId)
    }

    let variable = removeEmptyValueInObject(saveValue, [
      'id',
      'modifiedBy',
      'posType',
      'department'
    ])
    try {
      const respones = await axiosInstance.post('/itemmaster/graphql', {
        query: createNumberingSeries(variable)
      })

      if (respones.data.data.numberingSeriesCreateMutation.success) {
        resetForm()
        handleNumberingSeriesClose()
        resetdata()
        setLoad()
        setSubmitting(false)
        showErrorToast(true, 'success', '', 'Successfully Saved')
      } else {
        let errorData = ShowErrorNoties(
          respones.data.data.numberingSeriesCreateMutation.errors
        )
        showErrorToast(true, 'error', `${errorData}`, '')
      }
    } catch (error) {
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', `${errorData}`, '')
    }
  }
  // retun null ; call delete waring and pass delete data.
  const handledeleteConfomation = data => {
    handltoastDeleteConfomationShow()
    setdeleteData({
      Name: data.numberingSeriesName,
      id: data.id
    })
  }
  let currentValues = {}

  const handleCloseWaring = () => {
    setWaringToCloseModel(false)
  }
  const handleShowWaring = () => {
    setWaringToCloseModel(true)
  }
  // retun null; Waring on close if data change
  const WaringToClose = () => {
    const cleaeTheData = () => {
      handleNumberingSeriesClose()
      resetdata()
      if (numberingSeriesEdit) {
        setEdit(false)
        setNumberngSeriesEdit()
      }
      handleCloseWaring()
    }
    return (
      <>
        <Modal
          show={waringToCloseModel}
          onHide={handleCloseWaring}
          backdrop='static'
          keyboard={false}
          size='sm'
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to leave without saving?</Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-outline-warning btn-sm'
              onClick={handleCloseWaring}
            >
              No
            </button>
            <button
              type='button'
              className='btn btn-outline-success btn-sm'
              onClick={cleaeTheData}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
  // retun null;  on close if data change call Waring
  function Close () {
    let isDuplicate = checkChangesInputs([initialValue, currentValues])
    if (!isDuplicate) {
      handleNumberingSeriesClose()
      resetdata()
      if (numberingSeriesEdit) {
        setEdit(false)
        setNumberngSeriesEdit()
      }
    } else {
      handleShowWaring()
    }
  }
  // retun null;  on Delete clear the value
  const handleDeletedId = deleted_id => {
    if (deleted_id) {
      setNumberngSeriesEdit()
      resetdata()
      handleNumberingSeriesClose()

      handleReSourceIsPosType({ value: '', label: '' })
      handleSeletcResource({ value: '', label: '' })
      setEdit(false)
      setLoad()
    }
  }

  return (
    <>
      {WaringToClose()}
      <HistoryData historyIds={historyIds} setHistoryIds={setHistoryIds} />
      <ToastContainer />
      <Modal show={NumberingSeries} size='lg'>
      <div className='row'>
        <div className="col-5 m-3" style={{color:"#5CADA9"}}>

            <Modal.Title>
            <i
              className='fa-solid fa-arrow-left fa-sm me-2'
              onClick={() => {
                Close()
              }}
            ></i>
            Numbering Series
            </Modal.Title>
        </div>
        <div className="col-6 mt-4 text-end" >
          <Can I='Edit' a='Numbering_Series'>
            <i
              className='fa-solid fa-pen fs-5 text-primary pe-3'
              hidden={!(numberingSeriesEdit && edit)}
              onClick={() => {
                setEdit(!edit)
              }}
            ></i>
          </Can>
          <Can I='Delete' a='Numbering_Series'>
            <i
              className='fa-solid fa-trash text-danger fs-5 pe-3 ps-3  '
              hidden={!(numberingSeriesEdit && edit)}
              onClick={() => {
                handledeleteConfomation(numberingSeriesEdit)
              }}
            ></i>
          </Can>
          <Can I='Historys' a='Numbering_Series'>
            <TbHistory
              type='button'
              className='fs-4 ms-3'
              data-bs-toggle='offcanvas'
              hidden={!(numberingSeriesEdit && edit)}
              data-bs-target='#History'
              onClick={() => {
                setHistoryIds(initialValue.HistoryDetails)
              }}
            />
          </Can>
        </div>
      </div>
      <hr/>
      
        <Modal.Body>
          <Formik
            enableReinitialize={
              typeof numberingSeriesEdit === 'object' ? true : false
            }
            key={formKey}
            initialValues={initialValue}
            validationSchema={NumberingSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting, setFieldValue, values }) => {
              currentValues = values
              return (
                <Form>
                  <div className='row'>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='Name'
                        placeholder=''
                        disabled={numberingSeriesEdit && edit}
                        className='w-100 staan-form-input '
                      />
                      <label
                        htmlFor='Name'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                        onClick={()=>{console.log(values ) 
                          
                        }}
                      >
                        Name
                      </label>
                      {touched.Name && errors.Name && (
                        <small>{errors.Name}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Select
                        name='Resource'
                        value={SelectResource}
                        options={ResourceOptions}
                        isDisabled={numberingSeriesEdit && edit}
                        onChange={option => {
                          setFieldValue(
                            'Resource',
                            option ? option.value : null
                          )
                          handleSeletcResource(option)
                        }}
                        className='mt-3'
                        styles={{
                          control: provided => ({
                            ...provided,
                            fontSize: '.8rem'
                          })
                        }}
                      />
                      <label
                        htmlFor='Resource'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Resource
                      </label>
                      {touched.Resource && errors.Resource && (
                        <small>{errors.Resource}</small>
                      )}
                    </div>
                    <div
                      className='col-6 staan-input-group'
                      hidden={values.Resource !== 'Pos'}
                    >
                      <Select
                        name='ResourcePosType'
                        value={SelectReSourceIsPosType}
                        isDisabled={numberingSeriesEdit && edit}
                        options={resourceOptionsType.map(item => ({
                          value: item.id,
                          label: item.ReSourceIsPosType
                        }))}
                        onChange={option => {
                          setFieldValue(
                            'ReSourceIsPosType',
                            option ? option.value : null
                          )
                          handleReSourceIsPosType(option)
                        }}
                        className='mt-3'
                        styles={{
                          control: provided => ({
                            ...provided,
                            fontSize: '.8rem'
                          })
                        }}
                      />
                      <label
                        htmlFor='Resource'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Pos Type
                      </label>
                      {touched.Resource && errors.Resource && (
                        <small>{errors.Resource}</small>
                      )}
                    </div>
                    <div
                      className='col-6 staan-input-group'
                      hidden={values.Resource == 'SalesOrder' ? false:  values.Resource !== "Quotations"}
                    >
                      <Select
                        onInputChange={e => {
                          e.trim()
                            ? dispatch(
                                fetchOptionsData(
                                  QueryData.DepartmentQuery(
                                    `name: "${e.trim()}"`
                                  ),
                                  'SerialNumberdepartmant'
                                )
                              )
                            : setDepartmentList([])
                        }}
                        name='Department'
                        value={departmentSelect}
                        isDisabled={numberingSeriesEdit && edit}
                        options={departmentList.map(item => ({
                          value: item.id,
                          label: item.name
                        }))}
                        onChange={option => {
                          setFieldValue(
                            'Department',
                            option ? option.value : null
                          )
                          setDepartmentSelect(option)
                        }}
                        className='mt-3'
                      />
                      <label
                        htmlFor='Resource'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Department
                      </label>
                      {touched.Department && errors.Department && (
                        <small>{errors.Department}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='Formate'
                        disabled={numberingSeriesEdit && edit}
                        placeholder=''
                        className='w-100 staan-form-input '
                      />
                      <label
                        htmlFor='Formate'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Format
                      </label>
                      {touched.Formate && errors.Formate && (
                        <small>{errors.Formate}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='Current_Value'
                        disabled={numberingSeriesEdit && edit}
                        placeholder=''
                        className='w-100 staan-form-input '
                      />
                      <label
                        htmlFor='Current_Value'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Current Value
                      </label>
                      {touched.Current_Value && errors.Current_Value && (
                        <small>{errors.Current_Value}</small>
                      )}
                    </div>
                    <div className='col-6 my-3'>
                      <div className='form-check form-switch'>
                        <label className='form-check-label ' htmlFor='Default'>
                          Default
                        </label>
                        <Field
                          type='checkbox'
                          name='Default'
                          id='Default'
                          disabled={numberingSeriesEdit && edit}
                          className='form-check-input text-end ms-2 '
                        />
                        {touched.Default && errors.Default && (
                          <small>{errors.Default}</small>
                        )}
                      </div>
                    </div>
                    <div className='col-6 my-3'>
                      <div className='form-check form-switch'>
                        <label className='form-check-label ' htmlFor='Active'>
                          Active
                        </label>
                        <Field
                          type='checkbox'
                          name='Active'
                          disabled={numberingSeriesEdit && edit}
                          id='Active'
                          className='form-check-input text-end ms-2 '
                        />
                        {touched.Active && errors.Active && (
                          <small>{errors.Active}</small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='row d-flex justify-content-end'>
                    <div className='text-end '>
                      <Button
                        variant='outline-danger'
                        className='mx-2'
                        size='sm'
                        onClick={() => {
                          Close()
                        }}
                      >
                        Cancel
                      </Button>
                      <Can
                        allow_list={[
                          'Save_Numbering_Series',
                          'Edit_Numbering_Series'
                        ]}
                      >
                        <Button
                          type='submit'
                          disabled={isSubmitting}
                          className='mx-2 px-3'
                          size='sm'
                          variant='outline-success'
                        >
                          Save
                        </Button>
                      </Can>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {deleteData && (
        <DeleteConformation
          handleDeletedId={id_ => {
            handleDeletedId(id_)
          }}
          deleteData={deleteData}
          url={'/itemmaster/NumberingSeries'}
        />
      )}
    </>
  )
}

export default NumberingModal
