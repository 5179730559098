import AddScrapStore from "./AddScrapStore";
import CustomTableContainer from "../CustomTableContainer";
import React, { useState, useEffect} from 'react';
import { DeleteScrapItemQuery, ScrapDataFetch } from "../../../GraphQLQueries/BomMutation";
import axiosInstance from "../../../api/axoiss";
import BomDeleteConfirm from "../BomDeleteConfirm";


const ScrapStore = ({handleScrapeStoreData, isEditView, data, handleDeteteScrapStoreData, isNew}) => {
    const [scrapStoreData, setScrapStoreData] = useState([])
    const [isEditViewMode, setEditListViewMode] = useState(false)
    const [rowIndex, setRowIndex] = useState(null);
    const [currentEditingData, setCurrentEditingData] = useState(null)
    const [isShowDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [deleteData, setDeleteData] = useState(null);

    // table columns
    const scrap_store_column_details = [
        {
            header : "Part Code", 
            fieldname : 'part_code', 
            object : "label",
            width: 'col-2',
         },
        {
            header : "Part Name", 
            fieldname : 'part_name',
            object : "label",
            width: 'col-3',
         },
        {
            header : "Category", 
            fieldname :'category',
            width: 'col-1'
        },
        {
            header : "Qty", 
            fieldname :'qty',
            width: 'col-1'
        },
        {
            header : "Unit", 
            fieldname :'uom',
            object : "label",
            width: 'col-1'
        },
        {
            header : "Cost Allocation", 
            fieldname :'cost_allocation',
            width: 'col-2'
        }
    ]

    const [isShowAddScrapStore, setShowAddScrapStore] = useState(false)
    const handleCloseScrapStore = () => {
        setCurrentEditingData(null)
        setShowAddScrapStore(false)
    }

    const handleShowScrapStore = () => {
        setShowAddScrapStore(true)
    }

    const handleAddedData = (data) => {
        let scrap_store_data = [...scrapStoreData]
        const existingIndex = scrap_store_data.findIndex(item => item.id === data.id);
    
        if (existingIndex !== -1) {
            const updatedData = scrap_store_data.map((item, index) =>
                item.id === data.id ? { ...item, ...data} : item
            );
            handleScrapeStoreData(null, data);
            setScrapStoreData(updatedData);
            setCurrentEditingData(null)
        } else {
            setScrapStoreData(prevData => [...prevData, data]);
            handleScrapeStoreData(data.id, data);
            setCurrentEditingData(null)
        }
    };

    useEffect(()=>{
        if(data){
            let scrap_fetch_query = ScrapDataFetch(data);
            axiosInstance.post("/itemmaster/graphql", {query: scrap_fetch_query})
            .then(res => {
                let response = res?.data?.data?.scrap?.items
                let scrap_data_list = response?.map(item => {
                    let updated_data = {...item}
                    updated_data['cost_allocation'] = updated_data['costAllocation']
                    updated_data['part_code'] = {label: updated_data?.['partNo']?.['itemPartCode'], value: updated_data?.['partNo']?.['id']}
                    updated_data['part_name'] = {label: updated_data?.['partNo']?.['itemName'], value: updated_data?.['partNo']?.['id']}
                    updated_data['uom'] = {label: updated_data?.['unit']?.['name'], value: updated_data?.['unit']?.['id']}
                    return updated_data
                })
                setScrapStoreData(scrap_data_list);
            }).catch(err => {
                console.log(err);
            }) 
        }
        if(isNew){
            setScrapStoreData([])
        }
        // setScrapStoreData(bom_scrap_data)
        if(isEditView === true || isEditView === undefined){
            setEditListViewMode(true)
        }else{
            setEditListViewMode(false)
        }
    }, [isEditView, data])

    const handleEditTableRow = (data) => {
        setRowIndex(data)
        setCurrentEditingData(scrapStoreData[data])
        setShowAddScrapStore(true)
    }

    const handleDeleteTableRow = (data) => {
        setDeleteData(data)
        setShowDeleteConfirm(true);
    }

    const ProceedToDelete = (option) => {
        if(option){
            setRowIndex(deleteData);
            let scrap_delete_query = DeleteScrapItemQuery(scrapStoreData[deleteData]?.id);
            axiosInstance.post("/itemmaster/graphql", {query: scrap_delete_query})
            .then(response => {
                const data = response?.data?.data?.scrapDeleteMutation?.success
                if(data){
                    setScrapStoreData(prev_data => prev_data.filter(item=> item.id !== scrapStoreData[deleteData]?.id))
                    handleDeteteScrapStoreData(scrapStoreData[deleteData]?.id)
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    return(
        <>
            
            <div className='row'>
                <div className='col-3'>
                    <h6>Scrap / Reject Store</h6>
                </div>
                <div className='col-7'></div>   
                <div className='col-2 text-end'>
                    {isEditViewMode && <button 
                        type='button'
                        onClick={()=>{handleShowScrapStore()}}
                        className='fw-bold btn btn-sm btn-outline-primary'>
                            <i className='bx bxs-plus-circle' ></i> New</button>}
                </div>
            </div>

            { scrapStoreData.length > 0 && <div className="py-3 fw-bold" style={{fontSize: '.7rem'}}>
                <CustomTableContainer 
                    isEditViewMode = {isEditViewMode}
                    headers = {scrap_store_column_details}
                    data = {scrapStoreData}
                    handleEditTableRow = {(d)=>{handleEditTableRow(d)}}
                    handleDeleteTableRow = {(d)=>{handleDeleteTableRow(d)}}
                />
            </div>}

            {isShowAddScrapStore && <AddScrapStore 
                handleCloseScrapStore = {handleCloseScrapStore}
                isShowAddScrapStore ={isShowAddScrapStore}
                handleAddedData = {handleAddedData}
                data = {currentEditingData}
            />}
            {isShowDeleteConfirm && 
                <BomDeleteConfirm 
                    show = {isShowDeleteConfirm}
                    close = {()=>{setShowDeleteConfirm(!isShowDeleteConfirm)}}
                    handleDeleteData = {ProceedToDelete}
                />
            }
        </>
    )
}

export default ScrapStore;