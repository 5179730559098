export const quatationItemModalMutation = data => {
  return `
      mutation MyMutation {

  quotationsItemDetailsMutations(${data}) {

    errors
    success
    itemCombo
    itemComboData
    quotationsItemDetails {
      hsn{
            hsnCode
      }
            description
      itemCombo
      itemComboItemDetails{
      afterDiscountValueForPerItem
      amount
      id
      display
      isMandatory
      qty
      rate
      itemmaster{
        id
        itemPartCode
        itemName
      }            
      uom{
				id
				name
        }
      }
      
      afterDiscountValueForPerItem 
      tax
      amount 

      cgst 

      discountPercentage 

      discountValue 

      finalValue 

      id 

      igst 
      createdBy{
        id
      }

      qty 

      rate 

      sgst 

      uom { 

        id 

        name 

      } 

      itemmaster { 

        id 

        itemPartCode 

        itemName 

        category { 

          id 

          name 

        } 
          itemHsn{
          gstRates{
            rate
          }
        }
          

      } 

       

    } 

  } 

} 
      `
}

export const quotationCreateMutation = data => {
  return `

mutation MyMutation { 

  quotationsCreateMutation(${data} ) { 

    errors 
outOfRangeErrors
    success 
quotations {
      id 
      leadNo{
        id
        leadNo
      }
      currency{
        id
        rate
        Currency{
          id
          name
          currencySymbol
        }
      }
      status {
        id
        name
      }
      termsConditions{
    id
    name
  }
    salesPerson{
        id
        username
      }
    customerContactPerson{
    id
    contactPersonName
    phoneNumber
    email
  }
    	termsConditionsText
      customerId {
        id
        companyName
        supplierNo
      }
      quotationNo {
        id
        linkedModelId
      }
      CreatedAt
      createdBy{
      id
      username
    } 
      department {
        id
        name
      }
      remarks
      customerAddress{
        id
        addressType
        addressLine1
        addressLine2
        city
        pincode
        state
      }
      itemDetails{
       hsn{
            hsnCode
      }
            description
          afterDiscountValueForPerItem
      		amount
      		cgst
      		discountPercentage
      		discountValue
      		finalValue
      		id 
      		igst
      		itemCombo
          itemComboItemDetails{
            afterDiscountValueForPerItem
      			amount
      			id
      			display
      			isMandatory
      			qty
      			rate
      			itemmaster{
        						id
        						itemPartCode
        						itemName
      				}            
      			uom{
							id
							name
        			}
          }
      		qty
      		rate
        	tax
      		sgst
      		uom { 
        			id
        			name
      		}
          description
         	createdBy{
                 id
          }
      		itemmaster {
        		id
        		itemPartCode 
        		itemName 
        		category { 
          				id
          				name
        		}
          	itemHsn{
          			gstRates{
            					rate
          				}
            	} 
        	
      	} 
      		}
     			 otherIncomeCharge{
          			id 
          			amount 
          			igst 
          			sgst 
          			cgst 
            		afterDiscountValue
                discountValue
          			otherIncomeChargesId{ 
          			  id
          			  name
          			  hsn{
          			    gstRates{
          			      rate
          			    } 
          			  }
          			    createdBy{
          			id
        			}
          	}
          }
          childCount
       		itemTotalBeforTax
      		taxTotal
      		netAmount
  				discountFinalTotal
  				overallDiscountValue
  				overallDiscountPercentage
      		active
    }
          version{
    versionList
  }
  }
}  `
}


export const quotationsItemComboCreateMutations = data => {
  return `
  mutation MyMutation { 

  quotationsItemComboItemDetailsCreateMutations( 

    items:${data}
  ) { 

    errors 

    success 

    parent{ 
     	afterDiscountValueForPerItem 

        amount 

        cgst 

        createdAt 

        discountPercentage 

        discountValue 

        finalValue 

        id 

        itemmaster{ 

          id 

          itemPartCode 

          itemName 

        } 

        qty 

        rate 

        igst 

        sgst 

        tax 

        updatedAt 

        uom{ 

          id 

          name 

        } 

        createdBy{ 

          id 

          username 

        } 

        modifiedBy{ 

          id 

          username 

        } 
          description
                hsn{
        hsnCode
      }
       itemCombo
      itemComboItemDetails{ 

         id 

        createdBy{ 

          id 

        } 

        qty 

        itemmaster{ 

        itemPartCode 

        itemName 

        id 

      } 

      uom{ 

          id 

          name 

        } 

      amount 
      
      afterDiscountValueForPerItem 

      isMandatory 

      display 

      rate 

    } 

    } 
  }
  }
  `
}

export const quotationsItemComboItemDetailsDeleteMutations = data => {
  return `
  mutation MyMutation_1 { 

  quotationsItemComboItemDetailsDeleteMutations(id: "${data}") { 

    errors 

    success 

  } 

} 
  `
}


export const quotationsItemDetailsDeleteMutation = data => {
  return `
  mutation MyMutation { 

  quotationsItemDetailsDeleteMutation(id:${data}) { 

    errors 

    success 

  } 

} `
}

export const quotationsEditItemComboItemDetails = data => {
  return `
  query MyQuery { 

  quotationsItemComboItemDetails(idList: [${data}]) { 
    afterDiscountValueForPerItem 
    amount 
    createdAt 
    id 
    display 
    isMandatory 

    qty 

    rate 

    updatedAt 

   itemmaster{
      id
      itemPartCode
      itemName
    }
    uom{
      id
      name
    }
  } 

} `
}
export const getItemComboUnsavedDetails = data => {
  return `
  mutation MyMutation { 

  getItemComboItemDetails(itemmaster: ${data?.itemmaster}, amount: "${data?.amount}", qty: "${data?.qty}") { 

    itemCombo 

    itemComboData 
    

  } 

} 
  `
}


export const copyQuotasionToSalesOrder2 = data =>{
  // id: "151", userId: 3
  return `mutation MyMutation {
  copyQuotasionToSalesOrder2(${data}) {
    errors
    success
    itemDetails {
    hsn{
            hsnCode
          }
            description
       afterDiscountValueForPerItem
        amount
        cgst
        createdAt
        discountPercentage
        discountValue
        finalValue
        id
        itemmaster{
          id
          itemPartCode
          itemName
        }
        qty
        rate
        igst
        sgst
        tax
        updatedAt
        uom{
          id
          name
        }
          itemCombo
      itemComboItemDetails{
         id
      createdBy{
        id
      }
      qty
      itemmaster{
        itemPartCode
        itemName
        id
      }
      uom{
          id
          name
        }
      amount
      afterDiscountValueForPerItem
      isMandatory
      display
      rate
      }
        createdBy{
          id
          username
        }
        modifiedBy{
          id
          username
        }
    }
    otherIncomeCharge {
      otherIncomeChargesId{
        id
        name
      }
        createdBy{
        id
      }
       currency{
         Currency{
          currencySymbol
        }
      }
      afterDiscountValue
      discountValue
      tax
      amount
      createdAt
      igst
      cgst
      sgst
      id
    }
  }
}`
}

export const QuotationsCancel = data =>{
  // id: "197"
  return `
  mutation MyMutation {
  quotationsCanceledMutation(${data}) {
    errors
    success
  }
}`
}


export const quotationsDeleteMutation = data =>{
  // id: "197"
  return `mutation MyMutation {
  quotationsDeleteMutation(${data}) {
    errors
    success
  }
}`
}


export const quotationCurrencyConvertions=data=>{
  return `
  mutation MyMutation { 

  quotationsCurrencyConvertions(${data}) { 
    errors 
    success 
    
    QuotationsItemDetails { 
          hsn{
            hsnCode
      }
            description
      itemCombo
      itemComboItemDetails{
      afterDiscountValueForPerItem
      amount
      id
      display
      isMandatory
      qty
      rate
      itemmaster{
        id
        itemPartCode
        itemName
      }            
      uom{
				id
				name
        }
      }
      
      afterDiscountValueForPerItem 
      tax
      amount 

      cgst 

      discountPercentage 

      discountValue 

      finalValue 

      id 

      igst 
      createdBy{
        id
      }

      qty 

      rate 

      sgst 

      uom { 

        id 

        name 

      } 

      itemmaster { 

        id 

        itemPartCode 

        itemName 

        category { 

          id 

          name 

        } 
          itemHsn{
          gstRates{
            rate
          }
        }
          

      } 
  } 
    QuotationsOtherincomeChanges { 

            id
      amount
      igst
      sgst
      cgst
      createdBy{
        id
      }
      tax
      otherIncomeChargesId{
        id
        name
        hsn{
          gstRates{
            rate
          }
        }
      } 

    } 
}
}
  `
}

export const quotationsgeneratePDF = (data)=>{
  // currentCurrency: 10 ,itemIdList: 10 , otherIncomeChargeIdList: 10
  return `
  mutation MyMutation {
  generatePDF(${data}) {
    errors
    pdfData
    success
  }
}
  `
}