import React,{ useState, useEffect } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableView from '../../Packages/Custom/DataTable' 
import PurchaseOrder from './PurchaseOrderModel'
import PurchaseOrderModel from './PurchaseOrderModel'

const PurchaseTable = () => {
    const [isLoad, setLoad] = useState(false)
    const [purchaseOrderIsShowing, setpurchaseOrderIsShowing] = useState(false)
    const [purchaseOrderEdit, setPurchaseOrderEdit] = useState(null)
    const CustomViewModalComponent =(data)=>{ 
      setPurchaseOrderEdit(data)
      setpurchaseOrderIsShowing(true)
    }
 
    const headers = [
        {
          header: 'PO No',
          field: 'purchaseOrderNo', 
          flex: 10,
          label : "linkedModelId",
        //   query: 'accountsMasterOptionQueryForTable',
          inputType: 'text',
          renderComponent: CustomViewModalComponent,
        },
        {
          header: 'Date',
          field: 'createdAt', 
          flex: 10,
        //   query: 'accountsMasterOptionQueryForTable',
          inputType: 'text', 
          isDateField: true
        },
        {
          header: 'Status',
          field: 'status',
          flex: 10,
          option: true,
          query: 'poStatusQuery',
          inputType: 'text',
        },
        {
          header: 'Supplier Code',
          field: 'supplierId',
          flex: 10,
          label : "supplierNo", 
          query: 'poSupplierCodeQuery',
          inputType: 'text',
        },
        {
          header: 'Supplier Name',
          field: 'supplierId',
          flex: 10,
          customFilterField: "companyName",
          label : "companyName",
          query: 'poSupplierNameQuery',
          inputType: 'text', 
        },
        {
          header: 'Amount',
          field: 'netAmount',  
          flex: 10, 
          inputType: 'number',
           align: 'end'
        },
        {
          header: 'Due Date',
          field: 'dueDate',  
          flex: 10,
        //   query: 'accountGroupOptionforTable',
          inputType: 'text',
          isDateField: true
        },
        {
          header: 'Department',
          field: 'department',
          label : "name",
          flex: 10,
          query: 'poDepartmentQuery',
          inputType: 'text', 
        },
        {
          header: 'Receiving Store',
          field: 'receivingStoreId',
          label : "storeName",
          flex: 10,
          query: 'poReceivingStoreQuery',
          inputType: 'text', 
        },
    ]

    const CustomAddModalComponent = () => {
      setpurchaseOrderIsShowing(true)
    }
    
  return (
    <>
    <SideNavbar/>
    <div className="">
        <div id='custom-table-itemmaster'>
            <TableView
              headers={headers}
              query={"PurchaseTableQuery"}
              addComponent={CustomAddModalComponent}
              loadComponent={isLoad}
              titleName={"Purchase Order"}
              heightAdjust={"50rem"}
              permission={"Save_Purchase_Order"}
            />
        </div>
    </div> 
     <PurchaseOrderModel
        purchaseOderIsShowing =  {purchaseOrderIsShowing}
        setpurchaseOderIsShowing = {setpurchaseOrderIsShowing}
        setLoad = {()=>{setLoad(!isLoad)}}
        purchaseOrderEdit = {purchaseOrderEdit}
        setPurchaseOrderEdit = {setPurchaseOrderEdit}
     />
     
    </>
  )
}

export default PurchaseTable