export const StoreQuery = (page=1, pageSize=20)=>{
    let query =`
    query myStore{
        Store(page:${page}, pageSize:${pageSize}{
          items{
            storeName
            storeAccount{
              id
              accountsName
            }
            storeIncharge{
              id
              username
            }
            matained
          }
        }
      }`
    return query

}

export const ConferenceFetchQuery = () => {
  let query = `query FetchConferenceData{
    conferenceData(status: true){
      items{
        id
        name
      }
    }
  }`
  return query;
}

export const StockStatementItemComboQtyFetchQuery = (part_code) => {
  let query = `query MyQuery {
    stockStatementItemComboQty(id: ${part_code}){
      items{
        qty
      }
    }
  }`
  return query;
}