import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap"
import BomTreeView from ".";
import { v4 as uuidv4 } from 'uuid';
import axiosInstance from '../../../api/axoiss';
import { BomRmLinkFetchQuery, RawMaterialFetch, fetchBOMById } from "../../../GraphQLQueries/BomMutation";
import { TotalStockByPartCode } from "../../../GraphQLQueries/ItemmasterQuery";


const BomTreeModal = ({show, close, bomId}) => {
    const [existingTreeKeys, setExistingTreeKeys] = useState([])
    const [constructedTreeData, setConstructedTreeData] = useState(null);

    const generateUniqueKey = () => {
        let uniqueId = uuidv4();
        if(existingTreeKeys.includes(uniqueId)){
            generateUniqueKey()
        }else{
            setExistingTreeKeys(prev => [...prev, uniqueId])
            return uniqueId;
        }
    }

    const rawMaterialBomFetch = async (rm_ids) => {
        const item_with_qty = {};
        const fetchData = async (element) => {
            try {
                let fetch_query = BomRmLinkFetchQuery(element);
                const res = await axiosInstance.post(`/itemmaster/graphql`, { query: fetch_query });
                const res_data = res?.data?.data?.rawMaterialBomLink?.items?.[0];  
                if (res_data) {
                    const total_stock_res = await axiosInstance.post(`/itemmaster/graphql`, { query: TotalStockByPartCode(res_data?.rawMaterial?.partNo?.id) });
                    const total_stock_res_data = total_stock_res?.data?.data?.itemStockByPartCode?.items?.[0]?.totalCurrentStock;  
                    const unique_key = generateUniqueKey();
                    let construct_data = {
                        key: unique_key,
                        label: res_data?.rawMaterial?.partNo?.itemPartCode ,
                        icon: "fa-regular fa-circle",
                        name: res_data?.rawMaterial?.partNo?.itemName,
                        fixed: res_data?.rawMaterial?.fixed ? "Yes": "No",
                        is_bom : false,
                        bom_id : null,
                        requiredQty: res_data?.rawMaterial?.qty,
                        availableQty: total_stock_res_data ? total_stock_res_data : 0,
                        shortageQty: "",
                        bomName: res_data['bom']?.bomNo  + ` - ` + res_data['bom']?.bomName
                    }
                    
                    let shortage_qty = construct_data.availableQty - construct_data.requiredQty;
                    if(item_with_qty.hasOwnProperty(res_data?.rawMaterial?.partNo?.id)){
                        let existing_item = item_with_qty[res_data?.rawMaterial?.partNo?.id]
                        if(existing_item.available > 0){
                            shortage_qty = existing_item.available - construct_data.requiredQty;
                            construct_data['availableQty'] = existing_item.available
                        }
                        construct_data['shortageQty'] = shortage_qty
                        item_with_qty[res_data?.rawMaterial?.partNo?.id] = {
                            'available': parseFloat(existing_item.available) - parseFloat(construct_data.requiredQty)
                        }
                    }else{
                        item_with_qty[res_data?.rawMaterial?.partNo?.id] = {
                            'available': parseFloat(construct_data.availableQty) - parseFloat(construct_data.requiredQty)
                        }
                    }
                    shortage_qty = shortage_qty > 0 ? 0 : shortage_qty
                    construct_data['shortageQty'] = String(shortage_qty).replace("-", "");
                    let bom_unique_key = generateUniqueKey();
                    construct_data['children'] = [
                        {
                            key: bom_unique_key,
                            label:  res_data['bom']?.bomNo  + ` - ` + res_data['bom']?.bomName,
                            icon: "fa-regular fa-circle",
                            name: "",
                            fixed: "",
                            is_bom : true,
                            bom_id: res_data?.bom['id'],
                            requiredQty: "",
                            availableQty: "",
                            shortageQty: ""
                        }
                    ]
                    let raw_material_id = res_data?.bom?.rawMaterial;
                    let children = [];
                    if (raw_material_id && raw_material_id?.length > 0) {
                        raw_material_id = raw_material_id.map(item => item.id);
                        children = await Promise.all(raw_material_id.map(id => fetchData(id)));
                    }
                    return {
                        ...construct_data,
                        children: [{
                            // ...construct_data.children[0],
                            children
                        }]
                    };
                }else{
                    let fetch_rm_query = RawMaterialFetch(element);
                    const res_rm = await axiosInstance.post(`/itemmaster/graphql`, { query: fetch_rm_query });
                    const res_data_rm = res_rm?.data?.data?.rawMaterials?.items?.[0];
                    if (res_data_rm) {
                        const total_stock_res = await axiosInstance.post(`/itemmaster/graphql`, { query: TotalStockByPartCode(res_data_rm?.partNo?.id) });
                        const total_stock_res_data = total_stock_res?.data?.data?.itemStockByPartCode?.items?.[0]?.totalCurrentStock;  
                        const unique_key = generateUniqueKey();
                        let construct_data = {
                            key: unique_key,
                            label: res_data_rm?.partNo?.itemPartCode,
                            icon: "fa-regular fa-square",
                            name: res_data_rm?.partNo?.itemName,
                            fixed: res_data_rm?.fixed ? "Yes": "No",
                            is_bom : false,
                            requiredQty: res_data_rm?.qty,
                            availableQty: total_stock_res_data ? total_stock_res_data : 0,
                            shortageQty: "",
                        }
                        let shortage_qty = construct_data.availableQty - construct_data.requiredQty;
                        if(item_with_qty.hasOwnProperty(res_data_rm?.partNo?.id)){
                            let existing_item = item_with_qty[res_data_rm?.partNo?.id]
                            if(existing_item.available > 0){
                                shortage_qty = existing_item.available - construct_data.requiredQty;
                                construct_data['availableQty'] = existing_item.available
                            }
                            construct_data['shortageQty'] = shortage_qty

                            item_with_qty[res_data_rm?.partNo?.id] = {
                                'available': parseFloat(existing_item.available) - parseFloat(construct_data.requiredQty)
                            }
                        }else{
                            item_with_qty[res_data_rm?.partNo?.id] = {
                                'available': parseFloat(construct_data.availableQty) - parseFloat(construct_data.requiredQty)
                            }
                        }
                        shortage_qty = shortage_qty > 0 ? 0 : shortage_qty
                        construct_data['shortageQty'] = String(shortage_qty).replace("-", "");
                        return construct_data
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        const topLevelData = await Promise.all(rm_ids.map(id => fetchData(id)));
        return topLevelData.filter(Boolean); // Filter out any undefined values
    };
    
    const handleBOMTreeViewData = (current_bom_data) => {
        let construct_tree_data = [
            {
                key: "-1",
                label: "Part Code",
                name: "Name",
                fixed: "Fixed",
                requiredQty: "Required Qty",
                availableQty: "Available Qty",
                shortageQty: "Shortage Qty",
            },
        ]
        let bom_main_dict = {
            key: "0-1",
            label: current_bom_data?.['bom_name'],
            icon: "fa-regular fa-circle",
            name: "",
            fixed: "",
            is_bom : true,
            bom_id: current_bom_data?.['id'],
            requiredQty: "",
            availableQty: "",
            shortageQty: "",
            children: []
        }
        rawMaterialBomFetch(current_bom_data['raw_materials_data'])
        .then(res => {
            bom_main_dict['children'] = res
            construct_tree_data.push(bom_main_dict);
            console.log(construct_tree_data)
            setConstructedTreeData(construct_tree_data)
        })
        .catch(error => {
            console.error("Error fetching raw material data:", error);
        });
    }

    useEffect(()=>{
        let bom_fetch_query = fetchBOMById(bomId);
            axiosInstance.post("/itemmaster/graphql", {query: bom_fetch_query})
            .then(res => {
                let response = res?.data?.data?.bom?.items?.[0];
                let bom_data = {
                    bom_name: response?.['bomNo'] + ` - ` + response?.['bomName'] ,
                    id: response?.['id'],
                    remarks: response['remarks'] ? response['remarks'] : "",
                    finished_goods_data: [response['finishedGoods']?.id],
                    finished_goods_name: response['finishedGoods']?.partNo?.itemName,
                    raw_materials_data: response['rawMaterial']?.map(item => item?.id),
                    scrap_store_data: response['scrap']?.map(item => item?.id),
                }
                handleBOMTreeViewData(bom_data);
            }).catch(e => {
                console.log(e);
            })
    }, [bomId])

    return (
        <Modal show={show} onHide={close} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>BOM Tree View</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BomTreeView 
                    treeData = {constructedTreeData}
                />
            </Modal.Body>
        </Modal>
    )
}

export default BomTreeModal;