import React from 'react';

const StaanRows = ({deleteIsdisabled, isdisabled, tempRow, column, toggle, inputSection , setOpenState,   isOpen,   openRowIndex , setOpenRowIndex, getDeleteRowId}) => {
  // const getBooleanString = (value) => {
  //   console.log(typeof value,"->>");
    
  //   return typeof value === "boolean" ? (value ? "Yes" : "No") : value;
  // };
  return (
    
    <>
      {tempRow && tempRow.map((item, rowIndex) => (
        isOpen && Number(item.id) == Number(openRowIndex) ? 
        <tr key={rowIndex}> 
            {/* {console.log(isOpen, item , Number(item.id) ,  Number(openRowIndex),"---->>>")} */}
            <td colSpan={11}>
              { inputSection(toggle, setOpenRowIndex, item, setOpenState, isOpen) }
            </td>
        </tr>
        :
        <tr key={rowIndex}>
            {column?.map((col, colIndex) => (
              <td key={colIndex} style={{ Width: col?.['width'] ? `${col?.['width']}rem` : 'fit-content', display: col?.['hide'] ? 'none' : 'table-cell' }}>
                  {col?.temple? item?.['afterDiscountValue']? (`${item?.["amount"]} - ${item?.['discountValue']} = ${item?.['afterDiscountValue']}`): (`${item?.["amount"]}`): col?.object? (item[col?.['fieldname']])[col?.['object']] : (item[col?.['fieldname']])}
              </td>
            ))}
              <td>
                <div className="row">
                  <div className="col">
                    <button type='button' className="edit-button" disabled={isdisabled} onClick={()=>{setOpenRowIndex(item.id); toggle()}}><i className="fa-solid fa-pen-to-square"></i></button>
                  </div>
                  <div className="col">
                    <button type='button' className="delete-button" disabled={isdisabled || deleteIsdisabled}  onClick={()=>{getDeleteRowId(item)}}><i className="fa-solid fa-trash"></i></button>
                  </div>
                </div>
                
                
              </td>
          </tr>
        ))}
      
    </>
  )
}

export default StaanRows