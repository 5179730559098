import {Formik ,  Form, Field } from 'formik';
import Select from 'react-select';
import FinishedGoods from './FinishedGoods/finishedGoods';
import RawMaterials from './RawMaterials/rawMaterials';
import ScrapStore from './ScrapStore/scrapStore';
import BomRouting from './Routing/bomRouting';
import React, {useContext, useState, useEffect} from 'react' 
import DataContext from '../../context/ItemMasterContext';
import { bom_schema } from '../../validations/bom'
import { customSelectStyle } from '../../utils/Utils';
import axiosInstance from '../../api/axoiss';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStoreData } from '../../ReduxState/Action/storeActions';
import {  storeFetchQuery } from '../../GraphQLQueries/ItemmasterQuery';
import { BomSupplierOptionFetch,  CreateBomCostValidation,  DeleteBomCostValidation,  DeleteBomQuery,  DeleteChildBomLink,  DeleteFgItemQuery,  DeleteRawMaterialQuery,  
    DeleteRoutingItems,  DeleteScrapItemQuery,  DuplicateBomFg,  DuplicateBomForms,  DuplicateBomRm, 
     DuplicateBomRoute,  DuplicateBomScrap,  DuplicateChildBom,  FetchLinkedBom, 
      UpdateBomRawMaterialQuery,  UpdateBomStatusById,  createBomItem, createRmItem, fetchBOMById } from '../../GraphQLQueries/BomMutation';
import { fetchSupplierData } from '../../ReduxState/Action/bomAction';
import {ToastContainer, toast , Bounce} from 'react-toastify';
import BomDeleteConfirm from './BomDeleteConfirm';
import BomCancelConfirm from './BomCancelConfirm';


const AddBOM = ({modal, handleCloseBOMShow, isNew, isLoad, setLoad, currentBomId, isEditDelOption, handleDuplicateCurrentBom, loadComp}) => {
    const {userId} =   useContext(DataContext)
    const dispatch = useDispatch();
    const storeDataList = useSelector(state => state.storeData.data);
    const supplierDataList = useSelector(state => state.supplierData.data);
    let bomInitialValue = {
        bom_name: '',
        bom_number: '',
        fg_store: null,
        scrap_store: null,
        remarks: '',
        finished_goods_data: [],
        raw_materials_data: [],
        scrap_store_data: [],
        RoutingLink: [],
        labour_charges: 0,
        labour_remarks: "",
        machinery_charges: 0,
        machinery_remarks: "",
        electricity_charges: 0,
        electricity_remarks: "",
        other_charges: 0,
        other_remarks: "",
        status: {id:1, name: 'Saved'},
    }
    let input_field_font_size = '.7rem'
    const [bomBasicDetail, setBomBasicDetail] = useState(bomInitialValue);
    const [totalRawMaterial, setTotalRawMaterial] = useState(0);
    const [selectedFgStore, setSelectedFgStore] = useState(null)
    const [selectedScrapStore, setSelectedScrapStore] = useState(null);
    const [isEditView, setEditView] = useState(true);
    const [bomType, setBomType] = useState('MANUFACTURE');
    const [fgItems, setFgItems] = useState([]);
    const [rmItems, setRmItems] = useState([]);
    const [scrapItems, setScrapItems] = useState([]);
    const [isSubContract, setSubContract] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState([]);
    const [showCost, setShowCost] = useState(false);
    const [totalCost, setTotalCost] = useState(0);
    const [isClose, setClose] = useState(true);
    const [isShowDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [isShowCancelConfirm, setShowCancelConfirm] = useState(false);
    const [currentStatus, setCurrentStatus] = useState({id: '', name: ''});
    const [isActive, setIsActive] = useState(true)
    const [isDefault, setIsDefault] = useState(true);
    const [isRmLoad, setRmLoad] = useState(false);
    const [variationData, setVariationData] = useState(null);
    const [variation, setVariation] = useState(null);
    const [isPercentagevariation, setPercentageVariation] = useState(true);
    const [variationLeadTime, setVariationLeadTime] = useState(0);

    const handleClearCurrentData = () => {
        setBomBasicDetail(bomInitialValue)
        setTotalRawMaterial(0)
        setSelectedFgStore(null)
        setSelectedScrapStore(null)
        setBomType('MANUFACTURE')
        setFgItems([])
        setRmItems([])
        setScrapItems([])
        setSubContract(false)
        setSelectedSupplier([])
        setShowCost(false)
        setTotalCost(false)
        setClose(true)
        setShowDeleteConfirm(false)
        setShowCancelConfirm(false)
        setCurrentStatus({id: '', name: ''})
        setIsActive(true)
        setIsDefault(true)
    }

    const handleChangeActiveStatus = () => {
        setIsActive(!isActive)
    }

    const handleChangeDefaultStatus = () => {
        setIsDefault(!isDefault)
    }

    const calculateTotalCost = () => {
        let caculated_cost = 0;
        if(bomType === "MANUFACTURE"){
            caculated_cost += Number(bomBasicDetail?.['electricity_charges']) || 0;
            caculated_cost += Number(bomBasicDetail['machinery_charges']) || 0;
            caculated_cost += Number(bomBasicDetail['labour_charges']) || 0;
            caculated_cost += Number(bomBasicDetail['other_charges']) || 0;
        }else{
            let labour_charges = fgItems?.['labourCharges'] * Number(fgItems.qty)
            if(labour_charges){
                caculated_cost += Number(labour_charges) || 0;
            }
        }
        rmItems?.forEach(item => {
            caculated_cost += Number(item?.itemCost) || 0;
        })
        setTotalCost(caculated_cost);
    }

    const handleStoreUpdate = (key, value) => {
        setBomBasicDetail(previousState => ({
            ...previousState,
            [key]: value,
        }))
    }

    const handleChangeBomBasicDetails = (e) => {
        const { name, value } = e.target;
        setBomBasicDetail(previousState => ({
            ...previousState,
            [name]: value,
        }))
    }

    const handleAddRoutingData = (data) => {
        setBomBasicDetail(previousState => ({
            ...previousState,
            RoutingLink: data,
        }))
    }

    const handleFGData = (data, item) => {
        if(data){
            setBomBasicDetail(previousState => ({
                ...previousState,
                finished_goods_data: data,
            }))
        }
        item['costAllocation'] = item['cost_allocation']
        // console.log(rmItems)
        let fg_qty = parseFloat(item.qty);
        rmItems?.map(rm_item => {
            let updated_qty_value = parseFloat(rm_item.rawQty) * fg_qty;

            let rm_mutation_query = createRmItem({id: rm_item.id, qty: updated_qty_value});
            axiosInstance.post("/itemmaster/graphql", {query: rm_mutation_query})
            .then(response => {
                // const keys = Object.keys(response.data.data);
                // const firstKey = keys.length > 0 ? keys[0] : null;
                // const data = response.data.data[firstKey]?.rawMaterials
            })
            .catch(error => {
                console.log(error)
            });
        })
        setRmLoad(!isRmLoad)
        setFgItems(item)
    }

    const handleRawMaterialsData = (data, item) => {
        let temp_ids = [...bomBasicDetail['raw_materials_data'], data];
        temp_ids = temp_ids.filter(item => item !== undefined)
        setBomBasicDetail(previousState => ({
            ...previousState,
            raw_materials_data: temp_ids,
        }))
        setRmItems(previousState => [...previousState, item])
    }

    const GetTotalRawMaterialCount = (data) => {
        setTotalRawMaterial(data)
    }

    const handleScrapeStoreData = (data, item) => {
        if(data){
            const temp_ids = [...bomBasicDetail['scrap_store_data'], data];
            setBomBasicDetail(previousState => ({
                ...previousState,
                scrap_store_data: temp_ids,
            }))
        }
        let previous_scrap_data = [...scrapItems];
        item['costAllocation'] = item['cost_allocation']
        previous_scrap_data = previous_scrap_data.filter(d => d.id !== item.id)
        setScrapItems([...previous_scrap_data, item])
    }
    
    const handleDeteteScrapStoreData = (data) => {
        let temp_ids = [...bomBasicDetail['scrap_store_data']];
        temp_ids = temp_ids.filter(item => item !== data)
        if(bomInitialValue?.id){
            let bom_update_query = UpdateBomRawMaterialQuery(bomInitialValue?.id, temp_ids);
            axiosInstance.post("/itemmaster/graphql", {query: bom_update_query})
            .then(response => {
                const data = response?.data?.data?.bomCreateMutation?.success
                if(data){
                    setBomBasicDetail(previousState => ({
                        ...previousState,
                        scrap_store_data: temp_ids,
                    }))
                }
            })
            .catch(error => {
                console.log(error)
            });
        }else{
            setBomBasicDetail(previousState => ({
                ...previousState,
                scrap_store_data: temp_ids,
            }))
        }

    }

    const handleDeteteRawMaterialsData = (data) => {
        let temp_ids = [...bomBasicDetail['raw_materials_data']];
        temp_ids = temp_ids.filter(item => item !== data)
        if(bomInitialValue?.id){
            let bom_update_query = UpdateBomRawMaterialQuery(bomInitialValue?.id, temp_ids, totalRawMaterial);
            axiosInstance.post("/itemmaster/graphql", {query: bom_update_query})
            .then(response => {
                const data = response?.data?.data?.bomCreateMutation?.success
                if(data){
                    setBomBasicDetail(previousState => ({
                        ...previousState,
                        raw_materials_data: temp_ids,
                    }))
                }
            })
            .catch(error => {
                console.log(error)
            });
        }else{
            setBomBasicDetail(previousState => ({
                ...previousState,
                raw_materials_data: temp_ids,
            }))
        }
    }

    const handleDeleteBomItem = () => {
        setShowDeleteConfirm(true)
    }

    const throwDeletionError = (msg) => {
        let error_msg = 'This data Linked with other modules'
        if(msg){
            error_msg = msg
        }
        return toast.error(error_msg,
            {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            }
        )
    }

    // Function to handle raw material (RM) deletion
    function deleteRawMaterials() {
        return new Promise((resolve, reject) => {
            let fetch_linked_bom = FetchLinkedBom(currentBomId);
            axiosInstance.post("/itemmaster/graphql", { query: fetch_linked_bom })
            .then(linkedBomRes => {
                let linked_bom_items = linkedBomRes?.res?.res?.rawMaterialBomLink?.items
                if(linked_bom_items && linked_bom_items.length > 0){
                    linked_bom_items = null
                }
                else if(!linked_bom_items){
                    if (bomBasicDetail['raw_materials_data'].length > 0) {
                        let rm_delete_query = DeleteRawMaterialQuery(null, bomBasicDetail['raw_materials_data']);
                        axiosInstance.post("/itemmaster/graphql", { query: rm_delete_query })
                            .then(response => {
                                const is_rawmaterial_del = response?.data?.data?.rawMaterialDeleteMutation?.success;
                                if (!is_rawmaterial_del) {
                                    throwDeletionError(response?.data?.data?.rawMaterialDeleteMutation?.error?.[0]);
                                    reject(false);
                                } else {
                                    resolve(true);
                                }
                            })
                            .catch(err => {
                                throwDeletionError();
                                reject(false);
                            });
                    } else {
                        resolve(true);
                    }
                }else{
                    throwDeletionError('This data Linked with other modules');
                    reject(false);
                }
            })
            .catch(err => {
                console.log(err, "bom")
                throwDeletionError();
                reject(false);
            });
        });
    }

    // Function to handle scrap deletion
    function deleteScrapItems() {
        return new Promise((resolve, reject) => {
            if (bomBasicDetail['scrap_store_data'].length > 0) {
                let scrap_delete_query = DeleteScrapItemQuery(null, bomBasicDetail['scrap_store_data']);
                axiosInstance.post("/itemmaster/graphql", { query: scrap_delete_query })
                    .then(response => {
                        const is_scrap_del = response?.data?.data?.scrapDeleteMutation?.success;
                        if (!is_scrap_del) {
                            throwDeletionError(response?.data?.data?.scrapDeleteMutation?.error?.[0]);
                            reject(false);
                        } else {
                            resolve(true);
                        }
                    })
                    .catch(err => {
                        throwDeletionError();
                        reject(false);
                    });
            } else {
                resolve(true);
            }
        });
    }
    
    // Function to handle routing item deletion
    function deleteRoutingItems() {
        return new Promise((resolve, reject) => {
            if (bomBasicDetail['RoutingLink'].length > 0) {
                let route_delete_query = DeleteRoutingItems(bomBasicDetail['RoutingLink']);
                axiosInstance.post("/itemmaster/graphql", { query: route_delete_query })
                    .then(response => {
                        const is_route_del = response?.data?.data?.routingDeleteMutation?.success;
                        if (!is_route_del) {
                            throwDeletionError(response?.data?.data?.routingDeleteMutation?.error?.[0]);
                            reject(false);
                        } else {
                            resolve(true);
                        }
                    })
                    .catch(err => {
                        throwDeletionError();
                        reject(false);
                    });
            } else {
                resolve(true);
            }
        });
    }
    
    // Function to handle finished goods (FG) deletion
    function deleteFinishedGoods() {
        return new Promise((resolve, reject) => {
            if (bomBasicDetail['finished_goods_data'].length > 0) {
                if (bomBasicDetail['finished_goods_data']?.[0]) {
                    let fg_delete_query = DeleteFgItemQuery(bomBasicDetail['finished_goods_data']?.[0]);
                    axiosInstance.post("/itemmaster/graphql", { query: fg_delete_query })
                        .then(response => {
                            const is_fg_del = response?.data?.data?.finishedGoodsDeleteMutation?.success;
                            if (!is_fg_del) {
                                throwDeletionError(response?.data?.data?.finishedGoodsDeleteMutation?.error?.[0]);
                                reject(false);
                            } else {
                                resolve(true);
                            }
                        })
                        .catch(err => {
                            throwDeletionError();
                            reject(false);
                        });
                } else {
                    resolve(true);
                }
            } else {
                resolve(true);
            }
        });
    }

    const ProceedToDelete = (option) => {
        if(option){
            deleteRawMaterials()
            .then(deleteScrapItems)
            .then(deleteRoutingItems)
            .then(deleteFinishedGoods)
            .then(() => {
                // bom delete
                if(currentBomId){
                    let bom_delete_query = DeleteBomQuery(currentBomId);
                    axiosInstance.post("/itemmaster/graphql", {query: bom_delete_query})
                    .then(response => {
                        const is_fg_del = response?.data?.data?.bomDeleteMutation?.success
                        if(is_fg_del){
                            toast.success('Bom Deleted Successfully!',
                                {
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    transition: Bounce,
                                }
                            )
                            handleClearCurrentData();
                            handleCloseBOMShow(modal.id);
                            setLoad(!isLoad)
                        }else{
                            throwDeletionError(response?.data?.data?.bomDeleteMutation?.error?.[0]);
                        }
                    })
                    .catch(err => {
                        throwDeletionError('Bom Linked with others!');
                        return;
                    })
                }
            })
            .catch(err => {
                // Handle any errors occurred during deletion operations
            });

        }

    }

    const clearInputDatas = () => {
        //
    }

    const handleSupplierOnInputChange = (search_input) => {
        if(search_input.trim() !== ""){
            let fetch_query = BomSupplierOptionFetch(search_input);
            dispatch(fetchSupplierData(fetch_query));
        }
    }

    async function handleCostVariation() {
        let variation_data = null
        if (variation && variation.trim() !== '') {
            let variation_form_data = {
                isPercentage: isPercentagevariation,
                variation: variation,
                leadTime: variationLeadTime,
            };
            let bom_cost_variation_mutation_query = CreateBomCostValidation(variation_form_data);
            try {
                let res = await axiosInstance.post('/itemmaster/graphql', { query: bom_cost_variation_mutation_query });
                let data = res?.data?.data?.bomCostVariationCreateMutation;
                if (data?.success) {
                    variation_data = data?.bomCostVariation
                    setVariationData(data?.bomCostVariation);
                } else {
                    console.log(data?.errors);
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            if (variationData) {
                try {
                    let bom_cost_variation_delete_query = DeleteBomCostValidation(variationData.id);
                    let res = await axiosInstance.post('/itemmaster/graphql', { query: bom_cost_variation_delete_query });
                    let data = res?.data?.data?.bomCostVariationDeleteMutation;
                    if (data?.success) {
                        // delete submit_data?.['costVariation'];
                        variation_data = null
                    } else {
                        console.log(data?.errors);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            // if already variation linked, remove that.
            setVariationData(null);
        }
        return variation_data;
    }

    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors, setFieldError }, toggle, setOpenRowIndex, rowdata) => {
        let scrap_cost_allocation = [];
        scrap_cost_allocation = scrapItems?.map(item => Number(item?.costAllocation || 0));
        scrap_cost_allocation = scrap_cost_allocation.filter(num => typeof num === 'number' && !isNaN(num));
        const scrap_sum = scrap_cost_allocation.reduce((acc, curr) => acc + curr, 0);
        const fg_sum = Number(fgItems?.['costAllocation'] || null)
        if(scrap_sum  !== 100 && scrap_cost_allocation.length > 0){
            toast.error('Scrap Items Total Cost Allocation should be 100%', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setClose(false);
            return;
        }else if(fg_sum  !== 100){
            toast.error('Finished Goods Total Cost Allocation should be 100%', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setClose(false);
            return;
        }else{
            setClose(true);
        }

        let supplier_list = []
        if(selectedSupplier){
            supplier_list = selectedSupplier?.map(item => parseInt(item.value))
        }
        let submit_data = {
            bomName: bomBasicDetail.bom_name,
            fgStore: selectedFgStore?.value,
            scrapStore: selectedScrapStore?.value,
            remarks: bomBasicDetail.remarks,
            action: true,
            default: true,
            finishedGoods: bomBasicDetail.finished_goods_data[0],
            rawMaterial: bomBasicDetail.raw_materials_data,
            scrap: bomBasicDetail.scrap_store_data,
            routes: bomBasicDetail?.RoutingLink,
            totalRawMaterial: totalRawMaterial,
            status: currentStatus?.id,
            createdBy: userId,
            modifiedBy: userId,
            bomType: bomType,
            supplier: supplier_list,
            isDefault: isDefault,
            isActive: isActive,
            electricityCharges: {
                'amount': values.electricity_charges,
                'remarks': values.electricity_remarks
            },
            labourCharges: {
                'amount': values.labour_charges,
                'remarks': values.labour_remarks
            },
            machineryCharges: {
                'amount': values.machinery_charges,
                'remarks': values.machinery_remarks
            },
            otherCharges: {
                'amount': values.other_charges,
                'remarks': values.other_remarks
            },
        }  
        let variation_data = await handleCostVariation();
        if(variation_data && variation_data?.id){
            submit_data['costVariation'] = variation_data?.id
        }else{
            submit_data['costVariation'] = null
        }
        if(currentBomId && !modal.isDuplicate){
            submit_data['id'] = currentBomId
        }
        let mutation_query = createBomItem(submit_data);
        try{
            axiosInstance.post('/itemmaster/graphql', {query: mutation_query}).then(res => {
                let data = res?.data?.data?.bomCreateMutation
                if(data?.success){
                    clearInputDatas()
                    handleClearCurrentData();
                    handleCloseBOMShow(modal.id)
                    setLoad(!isLoad)
                    toast.success('Saved Successfully!', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    })
                }else{
                    toast.error(data?.errors?.[0] || "Failed to Save!", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    })
                }
            }).catch(e => {
                console.log(e)
            })
        }catch(e){
            console.log(e)
        }
    }

    const handleCancelBom = () =>{
        setShowCancelConfirm(true)
    }

    const ProceedToCancel = (option) => {
        if(option){
            if(currentBomId){
                let bom_cancel_query = UpdateBomStatusById(1, currentBomId);
                axiosInstance.post("/itemmaster/graphql", {query: bom_cancel_query})
                .then(response => {
                    if(response?.data?.data?.bomCreateMutation?.success){
                        let delete_child_bom_query = DeleteChildBomLink(null, currentBomId);
                        axiosInstance.post("/itemmaster/graphql", {query: delete_child_bom_query})
                        .then(res => {
                            // console.log(res, "child bom delete")
                        }).catch(err => {
                            console.log(err, "error")
                        })
                        setCurrentStatus({id: 1, name: 'Cancelled'})
                        toast.success('BOM Cancel Successfully!', 
                        {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Bounce,
                        })
                    }else{
                        toast.error('Failed to cancel the Bom!', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Bounce,
                        })
                    }
                })
                .catch(err => {console.log(err)})
            }
        }
    }

    useEffect(()=>{
        // console.log(currentBomId, "currentBomId")
        // console.log(isNew, "isNew")
        let is_duplicate_data = modal.isDuplicate
        if(currentBomId){
            setEditView(false);
            let bom_fetch_query = fetchBOMById(currentBomId);
            axiosInstance.post("/itemmaster/graphql", {query: bom_fetch_query})
            .then(res => {
                let response = res?.data?.data?.bom?.items?.[0];
                if(response?.['supplier']){
                    let supplier_selected = response?.['supplier']?.map(item => ({value: item?.id, label: item?.companyName}));
                    setSelectedSupplier(supplier_selected);
                }
                if(response?.['bomType'] === 'SUBCONTRACT'){
                    setSubContract(true)
                }
                setSelectedFgStore({value: response?.['fgStore']?.id, label: response?.['fgStore']?.storeName})
                setSelectedScrapStore({value: response?.['scrapStore']?.id, label: response?.['scrapStore']?.storeName})
                setTotalRawMaterial(response?.['totalRawMaterial'])
                setBomType(response?.['bomType'])
                setIsActive(response?.['isActive'])
                setIsDefault(response?.['isDefault'])
                setFgItems(response?.['finishedGoods'])
                setRmItems(response?.['rawMaterial'])
                setScrapItems(response?.['scrap'])                
                if(!is_duplicate_data){
                    setCurrentStatus({id: response?.['status']?.id, name: response?.['status']?.status})
                }
                let bom_data = {
                    bom_name: !is_duplicate_data ? response?.['bomName'] : "",
                    bom_no: !is_duplicate_data ? response?.['bomNo'] : '',
                    fg_store: response['fgStore']?.id,
                    scrap_store: response['scrapStore']?.id,
                    remarks: response['remarks'] ? response['remarks'] : "",
                    finished_goods_data: [response['finishedGoods']?.id],
                    raw_materials_data: response['rawMaterial']?.map(item => item?.id),
                    scrap_store_data: response['scrap']?.map(item => item?.id),
                    RoutingLink: response['routes']?.map(item => item?.id),
                    labour_charges: JSON.parse(response['labourCharges'])?.['amount'] || 0,
                    labour_remarks: JSON.parse(response['labourCharges'])?.['remarks'] || "",
                    machinery_charges: JSON.parse(response['machineryCharges'])?.['amount'] || 0,
                    machinery_remarks: JSON.parse(response['machineryCharges'])?.['remarks'] || "",
                    electricity_charges: JSON.parse(response['electricityCharges'])?.['amount'] || 0,
                    electricity_remarks: JSON.parse(response['electricityCharges'])?.['remarks'] || "",
                    other_charges: JSON.parse(response['otherCharges'])?.['amount'] || 0,
                    other_remarks: JSON.parse(response['otherCharges'])?.['remarks'] || "",
                    status: response['status'],
                }
                setBomBasicDetail(bom_data);
                if(response?.['costVariation']){
                    setVariationData(response?.['costVariation'])
                    setPercentageVariation(response?.['costVariation']?.['isPercentage'])
                    setVariation(response?.['costVariation']?.['variation'])
                    setVariationLeadTime(response?.['costVariation']?.['leadTime'])
                }
                // duplicates existing item for new duplicated bom
                if(is_duplicate_data){
                    let duplicate_fg_item = DuplicateBomFg([response['finishedGoods']?.id]);
                    axiosInstance.post("/itemmaster/graphql", {query: duplicate_fg_item})
                    .then(res => {
                        let fg_id = res?.data?.data?.bomDuplicateFgItemMutation?.finishedGoods
                        if(fg_id){
                            setFgItems(fg_id)
                        }else{
                            setFgItems(null)
                        }
                        bom_data['finished_goods_data'] = [fg_id['id']] || []
                    }).catch(err => {console.log(err)})
                    let duplicate_rm_item = DuplicateBomRm(response['rawMaterial']?.map(item => item?.id));
                    axiosInstance.post("/itemmaster/graphql", {query: duplicate_rm_item})
                    .then(res => {
                        let rm_id = res?.data?.data?.bomDuplicateRmItemMutation?.rawMaterial
                        bom_data['raw_materials_data'] = rm_id?.map(item => item?.id) || []
                        if(rm_id && rm_id.length > 0){
                            setRmItems(rm_id)
                        }
                        // let duplicate_child_bom_item = DuplicateChildBom(response['rawMaterial']?.map(item => item?.id), bom_data['raw_materials_data']);
                        // axiosInstance.post("/itemmaster/graphql", {query: duplicate_child_bom_item})
                        // .then(res => {
                        //     console.log(res, "child bom link")
                        // }).catch(err => {console.log('')})
                    }).catch(err => {console.log('')})
                    
                    let duplicate_scrap_item = DuplicateBomScrap(response['scrap']?.map(item => item?.id));
                    axiosInstance.post("/itemmaster/graphql", {query: duplicate_scrap_item})
                    .then(res => {
                        let scrap_id = res?.data?.data?.bomDuplicateScrapItemMutation?.scrap
                        bom_data['scrap_store_data'] = scrap_id?.map(item => item?.id) || []
                        if(scrap_id && scrap_id.length > 0){
                            setScrapItems(scrap_id)
                        }
                    }).catch(err => {console.log('')})
                    let duplicate_routing_item = DuplicateBomRoute(response['routes']?.map(item => item?.id));
                    axiosInstance.post("/itemmaster/graphql", {query: duplicate_routing_item})
                    .then(res => {
                        let route_id = res?.data?.data?.bomDuplicateRoutingItemMutation?.routes
                        bom_data['RoutingLink'] = route_id?.map(item => item?.id) || []
                    }).catch(err => {console.log('')})
                    
                }
            }).catch(e => {
                console.log(e);
            })
        }
        if(is_duplicate_data){
            setEditView(true);
            setIsActive(true);
            setIsDefault(false);
        }
        if(isNew){
            handleClearCurrentData()
            setEditView(isNew)
        }
        dispatch(fetchStoreData(storeFetchQuery()));
    }, [dispatch, currentBomId, loadComp])

    return(
        <>
        <ToastContainer/>
        <Modal 
            key = {modal?.id}
            show={modal?.show} 
            onHide={() => {
                if (isClose) {
                  handleClearCurrentData();
                  handleCloseBOMShow(modal.id);
                }
            }}
            // size='xl' 
            fullscreen = {true}
            dialogClassName="modal-transparent"
            backdrop="static"
        >
            <div style={{fontSize: '.8rem', width: '100%'}} className='mx-auto bg-white'>
                <div className=" mt-3" style={{width: "100%", height:"fit-content" }}>
                    <div className="row mx-auto py-3 border-bottom border-light shadow-sm " style={{width: "100%"}}>
                        <div className="col-5 my-auto">
                            <h4>
                                <span className='me-3' style={{cursor: 'pointer'}} onClick={()=>{
                                    if (isClose) {
                                        handleClearCurrentData();
                                        handleCloseBOMShow(modal.id);
                                    }
                                }}>
                                    <i className="fa-solid fa-arrow-left  fs-4"></i>
                                </span>
                                Bill of Material</h4>
                            <p>Status: <span>{currentBomId ? currentStatus.name: ''}</span></p>
                        </div>
                        <div className='col-5'></div>
                        {currentBomId && !modal.isDuplicate && <div className='col-2 text-end'>
                            {isEditDelOption && <span className='mx-2 text-secondary' style={{cursor: 'pointer'}} 
                                onClick={()=>{handleCancelBom()}}
                            >
                                <i className="fa-solid fa-ban fs-5"></i>
                            </span> }
                            {isEditDelOption && <span className='mx-2 text-success' style={{cursor: 'pointer'}} 
                                onClick={()=>{handleDuplicateCurrentBom()}}
                            >
                                <i className="fa-solid fa-copy fs-5"></i>
                            </span> }
                            {isEditView && <span className='mx-2 text-danger' style={{cursor: 'pointer'}} 
                                onClick={handleDeleteBomItem}
                            >
                                <i className='fa-solid fa-trash fs-6'></i>
                            </span> }
                            {isEditDelOption && <span className='mx-2 text-primary' style={{cursor: 'pointer'}} 
                                onClick={()=>{setEditView(!isEditView)}}
                            >
                                <i className='fa-solid fa-pen fs-6'></i>
                            </span> }
                            
                        </div>}
                    </div>
                    <div className='py-4' style={{height: '85vh'}}>
                            <Formik 
                                enableReinitialize={true}
                                initialValues = {bomBasicDetail}
                                validationSchema={bom_schema}   
                                onSubmit={handleSubmit}
                            >
                            {({errors, touched, isSubmitting, values, setFieldValue , Formik, formikProps })=>{
                                return(<>
                                    <Form style={{width: "100%" }}>
                                        <div  style={{height: '72vh',  overflowY: 'scroll', overflowX: 'hidden'}}>
                                            <div className='row mx-2'>
                                                <div className="col-5 staan-input-group">
                                                    <input type='text' className='w-100 staan-form-input' 
                                                        value={bomBasicDetail.bom_name} 
                                                        name='bom_name' 
                                                        style={{fontSize: input_field_font_size}}
                                                        onChange={(e)=>{
                                                            handleChangeBomBasicDetails(e);
                                                            setFieldValue('bom_name', e.target.value )
                                                        }}
                                                        disabled = {!isEditView}
                                                    />
                                                    <label htmlFor="bom_name" className='staan-form-input-label pt-2 px-1 mx-2'>BOM Name</label>
                                                    <p>{  touched.bom_name &&  errors.bom_name && <small>{errors.bom_name}</small>}</p>
                                                </div>
                                                <div className='col-1'></div>
                                                <div className="col-5 staan-input-group">
                                                    <input type='text' className='w-100 staan-form-input' 
                                                        value={bomBasicDetail.bom_no || ''} 
                                                        name='bom_no' 
                                                        style={{fontSize: input_field_font_size}}
                                                        disabled = {true}
                                                    />
                                                    <label htmlFor="bom_no" className='staan-form-input-label pt-2 px-1 mx-2'>BOM Number</label>
                                                </div>
                                            </div>
                                            <div className='row mx-2'>
                                                <div className="col-5 staan-input-group">
                                                    <Select name="fg_store"  
                                                        options={storeDataList?.items?.filter(item => item?.matained === true)?.map((item) => ({ value: item.id, label: item.storeName  }))}
                                                        onChange={(e)=>{
                                                            setSelectedFgStore(e)
                                                            setFieldValue('fg_store', e ? e.value : null )
                                                            handleStoreUpdate('fg_store', e ? e.value : null)
                                                        }}
                                                        style={{fontSize: input_field_font_size}}
                                                        className='mt-3'
                                                        styles={customSelectStyle}
                                                        value={selectedFgStore}
                                                        isDisabled = {!isEditView}
                                                    />
                                                    <label htmlFor="fg_store" className='staan-form-input-label pt-2 px-1 mx-2'>FG Store</label>
                                                    <p>{  touched.fg_store &&  errors.fg_store && <small>{errors.fg_store}</small>}</p>
                                                </div>
                                                <div className='col-1'></div>
                                                <div className="col-5 staan-input-group">
                                                    <Select name="scrap_store" 
                                                        options={storeDataList?.items?.map((item) => ({ value: item.id, label: item.storeName  }))}
                                                        value={selectedScrapStore}
                                                        style={{fontSize: input_field_font_size}}
                                                        className='mt-3'
                                                        styles={customSelectStyle}
                                                        isDisabled = {!isEditView}
                                                        onChange={(e)=>{
                                                            setSelectedScrapStore(e);
                                                            setFieldValue('scrap_store', e ? e.value : null )
                                                            handleStoreUpdate('scrap_store', e ? e.value : null)
                                                        }} 
                                                    />
                                                    <label htmlFor="scrap_store" className='staan-form-input-label pt-2 px-1 mx-2'>Scrap / Reject Store</label>
                                                    <p>{  touched.scrap_store &&  errors.scrap_store && <small>{errors.scrap_store}</small>}</p>
                                                </div>
                                            </div>
                                            <div className='row mx-2 d-flex align-items-center'>
                                                    <div className='col-3'>
                                                        <label>
                                                            <Field 
                                                                type="checkbox" 
                                                                name="isActive" 
                                                                className = "me-2" 
                                                                disabled={!isEditView} 
                                                                checked = {isActive}
                                                                onChange = {(e)=>{handleChangeActiveStatus(e)}}
                                                            /> Active
                                                        </label>
                                                    </div>
                                                    <div className='col-3'>
                                                        <label>
                                                            <Field 
                                                                type="checkbox" 
                                                                name="isDefault" 
                                                                className = "me-2" 
                                                                disabled={!isEditView} 
                                                                checked = {isDefault}
                                                                onChange = {(e)=>{handleChangeDefaultStatus(e)}}
                                                            /> Default
                                                        </label>
                                                    </div>
                                                    {isEditDelOption && currentBomId &&
                                                        <div className='col-6 row  d-flex align-items-center'>
                                                            <div className='col-3' style={{fontSize: '.8rem'}}>
                                                                <label>
                                                                    <Field 
                                                                        type="checkbox" 
                                                                        name="toggle" 
                                                                        className="me-3"
                                                                        onChange = {() => {setShowCost(!showCost); calculateTotalCost()}}
                                                                        checked = {showCost}
                                                                    />
                                                                    Costing
                                                                </label>
                                                            </div>
                                                            <div className='col-3' style={{fontSize: '.8rem'}}>
                                                                {showCost && <span>Total Cost: {totalCost}</span> }
                                                            </div>
                                                            <div className='col-3  staan-input-group' style={{fontSize: '.8rem'}}>
                                                                <Field 
                                                                    type="text" 
                                                                    name="variation" 
                                                                    className='w-100 staan-form-input' 
                                                                    value = {variation}
                                                                    onChange = {
                                                                        (e) => {
                                                                            if(e.target.value !== ''){
                                                                                setVariation(e.target.value);
                                                                            }else{
                                                                                setVariation(null)
                                                                            }
                                                                        }
                                                                    }
                                                                />
                                                                <label className='staan-form-input-label pt-2 px-1 mx-2'>Variation</label>
                                                                <p>{  touched.variation &&  errors.variation && <small>{errors.variation}</small>}</p>
                                                            </div>
                                                            <div className='col-1 d-flex align-items-center h-100'>
                                                                <div>
                                                                    <span onClick={
                                                                        ()=>{
                                                                            setPercentageVariation(!isPercentagevariation)
                                                                        }}
                                                                    style = {{cursor: 'pointer'}}
                                                                    >
                                                                        <i className={`fs-5 fa-solid ${ isPercentagevariation ? 'fa-percent' : 'fa-indian-rupee-sign' }`}></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='col-2  staan-input-group' style={{fontSize: '.8rem'}}>
                                                                <Field 
                                                                    type="text" 
                                                                    name="leadtime" 
                                                                    className='w-100 staan-form-input' 
                                                                    value = {variationLeadTime}
                                                                    onChange = {
                                                                        (e) => {
                                                                            if(e.target.value !== ''){
                                                                                setVariationLeadTime(e.target.value);
                                                                            }else{
                                                                                setVariationLeadTime(0)
                                                                            }
                                                                        }
                                                                    }
                                                                />
                                                                <label className='staan-form-input-label pt-2 px-1 mx-2'>Lead Time</label>
                                                                <p>{  touched.leadtime &&  errors.leadtime && <small>{errors.leadtime}</small>}</p>
                                                            </div>
                                                        </div> 
                                                    }
                                            </div>
                                            <div className='row mx-2'>
                                                <div className="col-12 staan-input-group">
                                                    <textarea className="staan-form-input-text-area" name='remarks' id="textarea" 
                                                        value={bomBasicDetail.remarks}  
                                                        onChange={(e)=>{
                                                            handleChangeBomBasicDetails(e);
                                                            setFieldValue('remarks', e.target.value)
                                                        }} 
                                                        style={{fontSize: input_field_font_size}}
                                                        disabled = {!isEditView}
                                                    ></textarea>
                                                    <label htmlFor="remarks" className='staan-form-input-label pt-2 px-1 mx-2'>Remarks</label>
                                                    <p>{  touched.remarks &&  errors.remarks && <small>{errors.remarks}</small>}</p>
                                                </div>
                                            </div>
                                            <div className='row mx-2'>
                                                <div id="my-radio-group"><h6>BOM Type</h6></div>
                                                <div className='d-flex align-items-center my-2' role="group" aria-labelledby="my-radio-group">
                                                    <label className='me-3'>
                                                        <Field type="radio" 
                                                            name="bomType" 
                                                            value="MANUFACTURE" 
                                                            className="me-2" 
                                                            onChange = {(e)=>{setBomType("MANUFACTURE"); setSubContract(false)}}
                                                            checked = {bomType === 'MANUFACTURE' ? true : false} disabled={!isEditView} />
                                                        MANUFACTURE
                                                    </label>
                                                    <label className='ms-4'>
                                                        <Field type="radio" 
                                                            name="bomType" value="SUBCONTRACT" 
                                                            className="me-2" 
                                                            onChange = {(e)=>{
                                                                setBomType("SUBCONTRACT"); 
                                                                setSubContract(true)}}
                                                            checked = {bomType === 'SUBCONTRACT' ? true : false}  disabled={!isEditView} />
                                                        SUB CONTRACT
                                                    </label>
                                                    <div style={{width: '50%'}}>
                                                        {bomType === 'SUBCONTRACT' ? 
                                                            <div className='row ms-5'>
                                                                <div className="col-12 staan-input-group">
                                                                    <Select name="supplier" 
                                                                        onInputChange={(e) => {handleSupplierOnInputChange(e)}}
                                                                        options={supplierDataList?.items?.map((item) => ({ value: item.id, label: item.companyName  }))}
                                                                        value={selectedSupplier}
                                                                        style={{fontSize: input_field_font_size}}
                                                                        isMulti
                                                                        className='mt-3'
                                                                        styles={customSelectStyle}
                                                                        isDisabled = {!isEditView}
                                                                        onChange={(e)=>{
                                                                            setSelectedSupplier(e)
                                                                            setFieldValue('supplier', e ? e?.[0].value : null )
                                                                        }} 
                                                                    />
                                                                    <label htmlFor="supplier" className='staan-form-input-label pt-1 px-1 mx-2'>Supplier</label>
                                                                    <p>{  touched.supplier &&  errors.supplier && <small>{errors.supplier}</small>}</p>
                                                                </div>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='my-3 mx-2 border border-light rounded shadow p-3' style={{height: '16rem', overflowY: "scroll"}}>
                                                <FinishedGoods 
                                                    handleFGData={(d, item) => {handleFGData(d, item)}} 
                                                    data = {bomBasicDetail?.finished_goods_data}
                                                    isEditView = {isEditView}
                                                    bomType = {bomType}
                                                    isNew = {isNew}
                                                />
                                            </div>
                                            <div className='my-5 mx-2 border border-light rounded shadow p-3' style={{height: '16rem', overflowY: "scroll"}}>
                                                <RawMaterials 
                                                    handleRawMaterialsData={(d, item) => {handleRawMaterialsData(d, item)}}
                                                    handleDeteteRawMaterialsData = {(d)=>{handleDeteteRawMaterialsData(d)}} 
                                                    GetTotalRawMaterialCount = {(d)=>{GetTotalRawMaterialCount(d)}}
                                                    data = {bomBasicDetail?.raw_materials_data}
                                                    isEditView = {isEditView}
                                                    isNew = {isNew}
                                                    isRmLoad = {isRmLoad}
                                                    fgItems = {fgItems}
                                                />
                                            </div>
                                            <div className='my-5 mx-2 border border-light rounded shadow p-3' style={{height: '16rem', overflowY: "scroll"}}>
                                                <ScrapStore 
                                                    handleScrapeStoreData={(d, item) => {handleScrapeStoreData(d, item)}} 
                                                    handleDeteteScrapStoreData = {(d)=>{handleDeteteScrapStoreData(d)}}
                                                    data = {bomBasicDetail?.scrap_store_data}
                                                    isEditView = {isEditView}
                                                    isNew = {isNew}
                                                />
                                            </div>
                                            { !isSubContract && <div className='border-top my-5 mx-2'>
                                                <BomRouting 
                                                    handleAddRoutingData = {(d)=>{handleAddRoutingData(d)}}
                                                    data = {bomBasicDetail?.RoutingLink}
                                                    isEditView = {isEditView}
                                                    isNew = {isNew}
                                                />
                                            </div>}
                                            { !isSubContract && <div className='border-top my-5 mx-2'>
                                                <div style={{width: '80%'}} className='mx-auto my-4'>
                                                    <div className="row mb-4" style={{fontSize: '.8rem', fontWeight: 'bold'}}>
                                                        <div className="col-1">#</div>
                                                        <div className="col-3">Description</div>
                                                        <div className="col-4">Amount</div>
                                                        <div className="col-4">Remarks</div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col-1">1</div>
                                                        <div className="col-2">Labour Charges</div>
                                                        <div className="col-3">
                                                            <input 
                                                                onChange={(e)=>{
                                                                    setFieldValue("labour_charges", e.target.value);
                                                                    setBomBasicDetail(prev => {
                                                                        return { ...prev, labour_charges: e.target.value };
                                                                    });
                                                                }}
                                                                className="form-control form-control-sm w-100 text-end" 
                                                                name="labour_charges"  
                                                                value = {bomBasicDetail.labour_charges}
                                                                disabled = {!isEditView}
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            <input 
                                                                onChange={(e)=>{
                                                                    setFieldValue("labour_remarks", e.target.value);
                                                                    setBomBasicDetail(prev => {
                                                                        return { ...prev, labour_remarks: e.target.value };
                                                                    });
                                                                }}
                                                                className="form-control form-control-sm w-100" 
                                                                name="labour_remarks" 
                                                                value = {bomBasicDetail.labour_remarks}
                                                                disabled = {!isEditView}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col-1">2</div>
                                                        <div className="col-2">Machinery Charges</div>
                                                        <div className="col-3">
                                                            <input 
                                                                onChange={(e)=>{
                                                                    setFieldValue("machinery_charges", e.target.value);
                                                                    setBomBasicDetail(prev => {
                                                                        return { ...prev, machinery_charges: e.target.value };
                                                                    });
                                                                }}
                                                                className="form-control form-control-sm w-100 text-end" 
                                                                name="machinery_charges" 
                                                                value = {bomBasicDetail.machinery_charges}
                                                                disabled = {!isEditView}
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            <input 
                                                                onChange={(e)=>{
                                                                    setFieldValue("machinery_remarks", e.target.value);
                                                                    setBomBasicDetail(prev => {
                                                                        return { ...prev, machinery_remarks: e.target.value };
                                                                    });
                                                                }}
                                                                className="form-control form-control-sm w-100" 
                                                                name="machinery_remarks" 
                                                                value = {bomBasicDetail.machinery_remarks}
                                                                disabled = {!isEditView}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col-1">3</div>
                                                        <div className="col-2">Electricity Charges</div>
                                                        <div className="col-3">
                                                            <input 
                                                                onChange={(e)=>{
                                                                    setFieldValue("electricity_charges", e.target.value);
                                                                    setBomBasicDetail(prev => {
                                                                        return { ...prev, electricity_charges: e.target.value };
                                                                    });
                                                                }}
                                                                className="form-control form-control-sm w-100 text-end" 
                                                                name="electricity_charges" 
                                                                value = {bomBasicDetail.electricity_charges}
                                                                disabled = {!isEditView}
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            <input 
                                                                onChange={(e)=>{
                                                                    setFieldValue("electricity_remarks", e.target.value);
                                                                    setBomBasicDetail(prev => {
                                                                        return { ...prev, electricity_remarks: e.target.value };
                                                                    });
                                                                }}
                                                                className="form-control form-control-sm w-100" 
                                                                name="electricity_remarks" 
                                                                value = {bomBasicDetail.electricity_remarks}
                                                                disabled = {!isEditView}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col-1">4</div>
                                                        <div className="col-2">Other Charges</div>
                                                        <div className="col-3">
                                                            <input 
                                                                onChange={(e)=>{
                                                                    setFieldValue("other_charges", e.target.value);
                                                                    setBomBasicDetail(prev => {
                                                                        return { ...prev, other_charges: e.target.value };
                                                                    });
                                                                }}
                                                                className="form-control form-control-sm w-100 text-end" 
                                                                name="other_charges" 
                                                                value = {bomBasicDetail.other_charges}
                                                                disabled = {!isEditView}
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            <input 
                                                                onChange={(e)=>{
                                                                    setFieldValue("other_remarks", e.target.value);
                                                                    setBomBasicDetail(prev => {
                                                                        return { ...prev, other_remarks: e.target.value };
                                                                    });
                                                                }}
                                                                className="form-control form-control-sm w-100" 
                                                                name="other_remarks" 
                                                                value = {bomBasicDetail.other_remarks}
                                                                disabled = {!isEditView}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                        <div className='my-3 d-flex justify-content-end'>
                                            <div>
                                                <button type="submit" 
                                                    onClick={()=>{setCurrentStatus({id:2, name: 'Saved'})}}
                                                    className='btn btn-sm btn-outline-success fw-bold px-5 mx-4 mt-3'
                                                >Save</button>
                                            </div> 
                                        </div>
                                    </Form>
                                </>)
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </Modal>
        {isShowDeleteConfirm && 
            <BomDeleteConfirm 
                show = {isShowDeleteConfirm}
                close = {()=>{setShowDeleteConfirm(!isShowDeleteConfirm)}}
                handleDeleteData = {ProceedToDelete}
            />
        }
        {isShowCancelConfirm && 
            <BomCancelConfirm 
                show = {isShowCancelConfirm}
                close = {()=>{setShowCancelConfirm(!isShowCancelConfirm)}}
                handleCancelData = {ProceedToCancel}
            />
        }
        </>
    )
}

export default AddBOM;