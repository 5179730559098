import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { Field } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axiosInstance from '../../../api/axoiss';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import DataContext from '../../../context/ItemMasterContext';
import { itemComboMutations } from '../../../GraphQLQueries/ItemMastermutation';
import { itemComboQuery } from '../../../GraphQLQueries/ItemmasterQuery';
import "../../../ItemMaster/Page/ItemMaster.css";
import { onCellDoubleClicked, removeEmptyValueInObject } from '../../../utils/Utils';
import ItemComboForm from './ItemComboForm';


const ItemCombo = ({values, initialMasterData, handleChangeItemComboPrint, isItemComboPrint, ItemMasterEditDisabled}) => {
      const {ItemComboAdd, handleItemComboClose, handltoastDeleteConfomationShow} = useContext(DataContext)
      const [post, setPost] = useState([])
      const [comboFrom, setComboFrom] = useState(false)
      const handelcomboFromClose = ()=> {setComboFrom(false)}
      const handelcomboFromShow = ()=> {setComboFrom(true)}
      const [itemComboEdit, setItemComboEdit] = useState()
      const [deleteData, setdeleteData] = useState({  Name:"",id:""})
      const [isPrint, setPrint] = useState(false);

      const colums = [
        {
          headerName : "ID",
          field:"sNo", 
          flex: 1, 
        },
        {
          headerName : "Part Code",
          field:"partNumber.itemPartCode",  
          flex:2,
          rowDrag: true,
          onCellDoubleClicked: onCellDoubleClicked,
        },
        {
          headerName : "Part Name",
          flex:2,
          field:"partNumber.itemName",
          onCellDoubleClicked: onCellDoubleClicked,
        },
        {
          headerName : "Qty",
          field:"itemQty",  
          flex:1,
          onCellDoubleClicked: onCellDoubleClicked,
        },
        {
          headerName : "Display",
          field:"itemDisplay.display",  
          flex:2,
          onCellDoubleClicked: onCellDoubleClicked,
        },
        {
          headerName : "Is Mandatory",
          field:"isMandatory",  
          flex:1,
          onCellDoubleClicked: onCellDoubleClicked,
        },
        { headerName: "Action", 
        cellRenderer: 'deleteButton',
        hide : ItemMasterEditDisabled
         
      },
      ]

      const deleteButton = ({ value, data }) => { 
        
        const handledeleteConfomation = () => {  
          handltoastDeleteConfomationShow();
          let check = {
            Name:data?.partNumber?.itemName,
            id: data.id,
           } 
          setdeleteData(check);  
        };

        const handleClick = () => { 
          handelcomboFromShow() 
          setItemComboEdit(data); 
          
        };
        return ( 
          <>
          <button className='btn btn-outline-danger btn-sm px-3 mx-2' onClick={()=>{handledeleteConfomation()}} ><i className="fa-solid fa-trash " ></i></button>
          <button className='btn btn-outline-success btn-sm px-3 mx-2' onClick={handleClick}> <i className="fa-solid fa-pen" ></i> </button>      
          
          </>
        );
      } 
  
      const components = {
        deleteButton: deleteButton,
      };

      const handleAddedData = (data) => {
        setPost((prevData) => {
          return prevData.map((item) => { 
              return item?.id === data?.[0]?.id ? data?.[0] : item;
          });
          })
      }

      const handleAddedNewData = (data) => {
        setPost(prevData => { 
          // Calculate the index number based on the length of the previous data
          const index = prevData.length + 1; 
          // Map through the responseData and add the index to each object
          data[0]['sNo'] = index; 
          // Concatenate the newData with the previous data
          return [...prevData, ...data ];
      }); 
      } 

      const gridApiRef = useRef(null);
      const defaultColDef ={
        sortable:true, editable:false
        , filter:true, floatingFilter:true,  
        resizable: true,
      }
     
      useEffect(() => { 
        let item_combo_ids = initialMasterData?.['itemComboData'];
        if(item_combo_ids){
          if(values['itemComboData']?.length > item_combo_ids?.length){
            item_combo_ids = values['itemComboData']
          }
        }else{
          item_combo_ids = values['itemComboData']
        }  
        setPrint(isItemComboPrint)
        let get_item_combo_data = async () => {
            for (let itemCombo of item_combo_ids) { 
               const response = await axiosInstance.get(`/itemmaster/graphql`, {
               params: {
               query: itemComboQuery(itemCombo), // Make sure alternateQuery is correctly defined and used
               },
               });
               const responseDatas = response.data.data.itemCombo
              //  const responseData = Array.isArray(responseDatas) ? responseDatas : [responseDatas]; 
               if (post.length > 0){
                handleAddedData(responseDatas)
               } else{
                handleAddedNewData(responseDatas)
               } 
        } 
        };
        if (item_combo_ids ){
          get_item_combo_data();
        }
      
        // initialMasterData
      }, [ItemComboAdd]);
      
      const handleRowDragEnd = (event) => {
        const newOrder = [];
        gridApiRef.current.api.forEachNodeAfterFilterAndSort((node, index) => {
            let temp_data = node.data
            temp_data['sNo'] = index+1 
            let saveValue = {
              sNo : Number(temp_data['sNo']),
              id : Number(temp_data['id']),
              partNumber : Number(temp_data['partNumber']['id']),
              isMandatory : temp_data['isMandatory'],
              itemDisplay : temp_data['itemDisplay'] ? Number(temp_data['itemDisplay']['id']) :"" ,
              itemQty : Number(temp_data['itemQty']),
            }
            let variables = removeEmptyValueInObject(saveValue)
            const mutation = itemComboMutations(variables)   
            axiosInstance.post(`/itemmaster/graphql`, {query: mutation} ).then(response =>{
            })
            newOrder.push(temp_data);
        });
        setPost(newOrder);
        // Update the selection
        const item_Combo_dataData = newOrder.map(row => row.id);
        values.Item_Combo_data = item_Combo_dataData;
      };
      const handleDeletedId = (deleted_id) =>{ 
        if (deleted_id){ 
          setPost((prevData) => {
            return prevData.filter((item) => item?.id !== deleted_id);
          }); 
        } 
      }
    
      return (
        <>
          <Modal show={ItemComboAdd} size="xl" onHide={handleItemComboClose}>
            <Modal.Header closeButton>
              <Modal.Title>Item Combo</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
            <div className="row mb-2">
              <div className='col-9'></div>
              <div className="col-2 my-3  staan-input-group">
                  <Field type="checkbox" name="Is_Mandatory"  className='me-2' 
                    checked = {isPrint} 
                    disabled = {ItemMasterEditDisabled}
                    onChange = {(e)=>{setPrint(e.target.checked); handleChangeItemComboPrint(e.target.checked)}}
                  />
                  <label style={{fontSize: '0.8rem'}} className='pb-1'>Print Detail</label>
              </div>
              {!ItemMasterEditDisabled && <div className="col-1 text-end">
                  <button type="button" className="btn btn-outline-primary" onClick={()=>{handelcomboFromShow()}}><i className='bx bxs-plus-circle' ></i> New</button>
              </div>}
            </div>
            <div className='ag-theme-alpine ' style={{ height: '500px', width: '100%' }} > 
                  <AgGridReact
                    ref={gridApiRef}
                    rowData={post}
                    columnDefs={colums}
                    defaultColDef={defaultColDef} 
                    // pagination={true}
                    components={components}
                    rowDragManaged={true}
                    onRowDragEnd={handleRowDragEnd}
                  />
            </div>

            </Modal.Body>
            <Modal.Footer>
            
              <Button variant="outline-success" onClick={()=>{
                  // if (gridApiRef.current) {
                  //   gridApiRef.current.api.selectAll();
                  // }
                  // const item_Combo_dataData = gridApiRef.current.api.getSelectedRows().map(row => row.id);
                  // values.Item_Combo_data = item_Combo_dataData;
                  handleRowDragEnd();
                  handleItemComboClose();
              }}>
                Save  
              </Button>
            </Modal.Footer>
          </Modal>
        {comboFrom && <ItemComboForm 
            comboFrom = {comboFrom}
            setPost = {setPost}
            handelcomboFromClose = {handelcomboFromClose}
            itemComboEdit = { itemComboEdit}
            setItemComboEdit = {setItemComboEdit}
            handleAddedData = {(d)=>{handleAddedData(d)}}
            handleAddedNewData = {(d)=>{handleAddedNewData(d)}}
            post = {post}
        /> } 
        
        {deleteData && <DeleteConformation
           handleDeletedId = {(id_)=>{handleDeletedId(id_)}}
           deleteData= {deleteData}
           query={"itemComboDelete"} 
        />}
        </>
      )
}

export default ItemCombo