export const userFiliterbypermission = (data)=>{ 
    // isSales:true
    let query = ` 
     query MyQuery {
    userPermission(${data}){
      items {
        id 
        userId {
          username
          id
        }
      }
    }
  } 
    `    
    return query
  }