import axiosInstance from "../../api/axoiss";

// Action Types
export const FETCH_STORE_DATA_REQUEST = 'FETCH_STORE_DATA_REQUEST';
export const FETCH_STORE_DATA_SUCCESS = 'FETCH_STORE_DATA_SUCCESS';
export const FETCH_STORE_DATA_FAILURE = 'FETCH_STORE_DATA_FAILURE';

// Action Creators
const fetchStoreDataRequest = () => {
  return {
    type: FETCH_STORE_DATA_REQUEST,
  };
};

const fetchStoreDataSuccess = (data) => {
  return {
    type: FETCH_STORE_DATA_SUCCESS,
    payload: data,
  };
};

const fetchStoreDataFailure = (error) => {
  return {
    type: FETCH_STORE_DATA_FAILURE,
    payload: error,
  };
};

// Thunk Action Creator
export const fetchStoreData = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchStoreDataRequest());
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
      .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        dispatch(fetchStoreDataSuccess(data));
      })
      .catch(error => {
        dispatch(fetchStoreDataFailure(error.message));
      });
  };
};