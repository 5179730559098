import { Formik, Form, Field } from 'formik';
import React, { useContext, useEffect, useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal';
import {ToastContainer, toast , Bounce} from 'react-toastify';
import DataContext from '../../../context/ItemMasterContext';
import {  DateFormate, ShowErrorNoties, TimeFormate, addSno, removeEmptyValueInObject } from '../../../utils/Utils';
import { goodsInwardNoteCancleMutation, goodsReceiptNoteAndStockAddtionsCreateMutation, goodsReceiptNoteCancleMutation, goodsReceiptNoteCreateQuery, goodsReceiptNoteItemDetailsSaveQuery, PurchaseOrderTimeLine } from '../../../GraphQLQueries/PurchaseItemMutations';
import axiosInstance from '../../../api/axoiss';
import { useSelector, useDispatch } from 'react-redux'; 
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { GrnFetchQuery } from '../../../GraphQLQueries/PurchaseItemQueries';
import WaringModel from '../../../components/Warings/WaringModel';
import QIR from '../../QualityInspectionReport/Model/QIR';
import BatchOrSerial from './BatchOrSerial';
import BallTriangles from '../../../components/loading/BallTriangle';
import TreeComponent from '../../../components/react-d3-tree/poHistory';
import { TiCancel } from 'react-icons/ti'
import WaringModelWithCopy from '../../../components/Warings/WaringModelWithCopy';
import Can from "../../../components/authorizationComponents/Can"


const Grn = ({  isGin , setIsGin, isGrnShowing, setIsGrnShowing, purchaseOrderDetails, editGrn, setEditGrn, setLoad}) => {

  const {userId} = useContext(DataContext)
  const [initialGrn, setInitialGrn] = useState({
    id:"",
    purchase_order_no: "",
    EwayBill:"",
  })
  const [waringToCancle, setWaringToCancle] = useState(false)
  const [waringToDelete, setWaringToDelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const modalContentRef = useRef(null);
  const [qirEdit, setQirEdit] = useState(null)
  const [topBarDatas, setTopBarDatas] = useState({status:null, createdBy:"", LastModifiedDate : "",
     grncreatedBy:"", GrnLastModifiedDate:""})
  const [purchaseOrderIsShowing, setpurchaseOrderIsShowing] = useState(false)
  const [purchaseOrderEdit, setPurchaseOrderEdit] = useState(null)
  const [ginItemsList, setGinItemsList] = useState([])
  const [isNew, setIsNew] = useState(false)
  const [deleteWaringModel, setdeleteWaringModel] = useState(false)
  const [deleteData, setDeleteData] = useState(null)
  const [editRowIndex, setEditRowIndex] = useState(null)
  const [isShowqir, setIsShowqir] =  useState(false)
  const [supplierDatas, setSupplierDatas] = useState(
    {
      "id" : "i",
     "ginNo":"",
     "ginDate":"",
     "grnNo":"",
     "grnDate":"", 
     "supplierCode" : "",
     "supplierName" : "",
     "purchaseOrderNo" : "",
     "dueDate" : "",
     "department" : "",
     "receivingStore" : "",
     "scrapRejectStore" : "",
     "gstinType":"",
     "gstin" : "",
     "state" : "",
     "PlaceOfSupply" : "",
     "address" : "",
     "contact" : ""
    }, 
  )
  const dispatch = useDispatch();
  const OptionsDataList = useSelector(state => state?.itemGroup?.data);
  const [isChecked, setIsChecked] = useState(false)
  const [isshowBatchSerialModel, setIsShowBatchSerialModel] = useState(false)
  const [referenceData, setReferenceData] = useState(null)
  const [isStockFullyAdded, setIsStockFullyAdded] = useState(true)

    // alert message using toastify
  const alert_custom_error = (error_msg) => {
      toast.error(error_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
    }
  // initial process
  useEffect(()=>{ 
    if (purchaseOrderDetails !== null && purchaseOrderDetails !== undefined && isGrnShowing === true ) {
      let purchase_order_data = purchaseOrderDetails?.[0] 
      setIsNew(true)
      setSupplierDatas({
      supplierCode : purchase_order_data?.supplierId?.supplierNo,
      supplierName : purchase_order_data?.supplierId?.companyName,
      purchaseOrderNo : purchase_order_data?.purchaseOrderNo?.linkedModelId,
      purchaseOrderId : purchase_order_data?.id,
      dueDate : purchase_order_data?.dueDate,
      department : purchase_order_data?.department?.name,
      receivingStore : purchase_order_data?.receivingStoreId?.storeName,
      scrapRejectStore : purchase_order_data?.scrapRejectStoreId?.storeName,
      remarks : purchase_order_data?.remarks,
      gstinType : purchase_order_data?.gstinType,
      gstin : purchase_order_data?.gstin,
      state : purchase_order_data?.state,
      PlaceOfSupply :  purchase_order_data?.placeOfSupply,
      address : purchase_order_data?.address,
      contact : purchase_order_data?.contact?.contactPersonName
      })
      let gri_list = []
      
      purchase_order_data?.itemDetails?.forEach((itemDetail,index) => { 
 
        if (Number(itemDetail?.poQty) > Number(itemDetail.received)){ 
          const balance = Number(itemDetail?.poQty) - Number(itemDetail.received);
          const excess = balance < 0 ? Math.abs(balance) : 0; // If balance is negative, it's excess
          const adjustedBalance = balance >= 0 ? balance : 0; // Only positive balance is kept
      
          let modifieditemDetail = {
            "sNo" : index+1,
            "id" : "",
             "purchaseOrderId": {
             "id": itemDetail?.id,
             "itemMasterId": itemDetail?.itemMasterId,
             "poQty" : itemDetail?.poQty,
             "uom" : itemDetail?.uom,
             "received" : itemDetail.received,
            },
            "received" : 0,
            "excess" : excess,
            "balance" : adjustedBalance,
          }
           // balance excess  Rcvd
        gri_list.push(modifieditemDetail)
        }
      });  
      setGinItemsList(gri_list)
    }
  },[isGrnShowing])
   
  // Edit  process
  useEffect(()=>{  
    if (isGrnShowing  && editGrn?.id !== "" && editGrn?.id !== undefined){  
      let perementer = `id : ${editGrn?.id}`
      dispatch(fetchOptionsData(GrnFetchQuery(perementer), "GrnEdit"))
      setEditRowIndex(null)
    }
  },[editGrn , isGrnShowing, dispatch])

  useEffect(()=>{
    if (isShowqir === false && editGrn?.id !== "" && editGrn?.id !== undefined){
      setEditGrn({id : editGrn?.id})
      setIsGrnShowing(true) 
    }
  },[isShowqir])
  
  useEffect(()=>{ 
    if (OptionsDataList.name === "GrnEdit"){
      setEditValues(OptionsDataList?.value?.items?.[0])
    }
  },[ OptionsDataList])

  useEffect(() => {
    let allStockAdded = true;
  
    for (let item of ginItemsList) {
      if (!item?.stockAdded) {
        allStockAdded = false;
        break; // Exit the loop if stockAdded is not true
      }
    }
  
    setIsStockFullyAdded(allStockAdded);
  }, [ginItemsList]);


  //  retun null ; set the edit value
  function setEditValues(Value) { 
      
      setIsNew(false)
      let TopBarData ;
      if (isGin){
        TopBarData = {status:Value?.status?.status, createdBy:Value?.createdBy?.username, 
          LastModifiedDate: Value?.updatedAt,
          grncreatedBy : Value?.grnCreatedBy?.username,
          GrnLastModifiedDate : Value?.grnCreatedAt,
        }
        setTopBarDatas(TopBarData)
      } else{
         TopBarData = {status:Value?.grnStatus?.status, createdBy:Value?.createdBy?.username, 
          LastModifiedDate: Value?.updatedAt,
          grncreatedBy : Value?.grnCreatedBy?.username,
          GrnLastModifiedDate : Value?.grnCreatedAt,
        }
        setTopBarDatas(TopBarData)
      }
      
      setQirEdit(Value?.qualityInspectionReportId?.id) 
      setSupplierDatas({
      ginNo : Value?.ginNo?.linkedModelId,
      ginDate : Value?.ginDate ?  DateFormate(Value?.ginDate) : "",
      grnNo :  Value?.grnNo?.linkedModelId,
      grnDate : Value?.grnDate ?  DateFormate(Value?.grnDate) : "",
      supplierCode : Value?.purchaseOrderId?.supplierId?.supplierNo,
      supplierName : Value?.purchaseOrderId?.supplierId?.companyName,
      purchaseOrderNo : Value?.purchaseOrderId?.purchaseOrderNo?.linkedModelId,
      purchaseOrderId : Value?.purchaseOrderId?.id,
      dueDate : Value?.purchaseOrderId?.dueDate,
      department : Value?.purchaseOrderId?.department?.name,
      receivingStore : Value?.purchaseOrderId?.receivingStoreId?.storeName,
      scrapRejectStore : Value?.purchaseOrderId?.scrapRejectStoreId?.storeName,
      remarks : Value?.purchaseOrderId?.remarks,
      id : editGrn?.id
      })
      const updatedItemDetails = Value?.goodsReceiptNoteItemDetailsId.map(grnid => {
        const balance = Number(grnid.purchaseOrderId?.poQty) - Number(grnid?.purchaseOrderId?.received);
        return {
          ...grnid,
          excess: balance < 0 ? Math.abs(balance) : 0,
          balance: balance >= 0 ? balance : 0,
        }
      })
      let itemDetail = addSno(updatedItemDetails)
      setGinItemsList(itemDetail)
      checkQcValidation(itemDetail, Value?.qualityInspectionReportId?.status?.status)
  }

  function checkQcValidation(itemDetail, status) {
    // Extract the itemQc values into an array
    let qc_list = itemDetail.map(item => item?.purchaseOrderId?.itemMasterId?.itemQc) 
    // Check if any value in qc_list is true
    let data = qc_list.some(qc => qc === true);
    if (data){ 
       if (status === "Checked" && status !== undefined){ 
        setIsChecked(true)
       } else{
        setIsChecked(false)
       }
    } else{ 
      setIsChecked(true)
    } 
  }

  // retun objects ; saving the grn item details
  const handleSaveGrnItem = async (data, outOfGrn)=>{
    let save_data ;
    if (outOfGrn === null){
      save_data = {
        id : data?.id ? Number(data?.id): "",
        purchaseOrderId : data?.purchaseOrderId?.id ? Number(data?.purchaseOrderId?.id) : "",
        received :data?.received? `${String(data?.received)}` :0,
        balance : data?.balance ? `${String(data?.balance)}` :0,
        excess : data?.excess ? `${String(data?.excess)}` : 0,
        modifiedBy : data?.id ? Number(userId) : "",
        createdBy : data?.id  ? Number(data?.createdBy?.id) : Number(userId) ,
      } 
    } else{
      save_data = outOfGrn
    }
    
    let allow_null = ["id", "modifiedBy", "batchNumber"]
    let cleanValue = removeEmptyValueInObject(save_data, allow_null)
    try{
      const response = await axiosInstance.post(`/itemmaster/graphql`, {query:  goodsReceiptNoteItemDetailsSaveQuery(cleanValue)});
      const responseData = response?.['data']?.['data']?.['goodsReceiptNoteItemDetailsCreateMutation']
      if (responseData?.success){ 
        let resData =  responseData.goodsReceiptNoteItemDetailsInstance
        if (outOfGrn !== null){ 
          setGinItemsList((prevItems) =>
            prevItems.map((item) => { 
              return item?.id === resData?.id ? { ...item, ...resData, serial: resData['serial'] } : item;
            })
          ); 
        }
        return resData
      } else{
        let errorData = ShowErrorNoties(responseData.errors)
        toast.error(`${errorData}`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      }); 
      }
    } catch (error){
      let errorData = ShowErrorNoties(error)
      toast.error(`${errorData}`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    }); 
    } 
  }



  const [warning, setWarning] = useState(false)
  const [updatedData, setUpdatedData] = useState(null); 
 
  const RcvdInput = ({ value, data }) => { 
    const [rcvdValue, setRcvdValue] = useState(value);

    useEffect(() => {
      setRcvdValue(value);
    }, [value])

    const changeValue = (e) => {
      const newValue = e.target.value;
      setRcvdValue(newValue);
    }
    
    const handleBlur = async () => {
      const receivedValue = Number(rcvdValue);
      let alreadyReceivedValue = Number(data?.purchaseOrderId?.received);
      const poQty = Number(data?.purchaseOrderId?.poQty);
      const balance = poQty - (receivedValue + alreadyReceivedValue);
      const excess = balance < 0 ? Math.abs(balance) : 0; // If balance is negative, it's excess
      const adjustedBalance = balance >= 0 ? balance : 0; // Only positive balance is kept
  
      // Ensure immutability by spreading objects
      
      const newUpdatedData = {
        ...data,
        balance: Number(adjustedBalance).toFixed(3),
        excess: Number(excess).toFixed(3),
        received: Number(receivedValue).toFixed(3), // This might be unnecessary if 'received' is intended to be updated within 'purchaseOrderId'
        purchaseOrderId: {
          ...data.purchaseOrderId,
          received: receivedValue + alreadyReceivedValue
        }
      };
  
      if (excess > 0) {
        setUpdatedData(newUpdatedData);
        setWarning(true);
      } else {
        await saveData(newUpdatedData);
      }
    };

    const saveData = async (dataToSave) => { 
      if (!isNew) {
        const grnItem = await handleSaveGrnItem(dataToSave, null);
        if (grnItem) { 
          setGinItemsList((prevItems) =>
            prevItems.map((item) =>
              item.sNo === data.sNo ? { ...item, ...dataToSave } : item
            )
          )
        }
      } else { 
        setGinItemsList((prevItems) =>
          prevItems.map((item) =>{ 
            if(item?.sNo === dataToSave?.sNo){ 
                return { ...item, ...dataToSave }
            } else{
              return item
            }
          }
          )
        );
      }
      setEditRowIndex(null); // Exit edit mode on blur
    };
  
    const handleWarningConfirmation = (value) => {
      if (value) {
        saveData(updatedData);
      } 
      setUpdatedData(null);
      setWarning(false)
    };
    return (
      <>
        <WaringModel
          state={warning}
          setstate={setWarning}
          title={'Confirm'}
          text={'Received QTY more than Po QTY are you sure want continue ?'}
          Confirmtion={value => {
            handleWarningConfirmation(value)
          }} 
        />
        <input
          tabIndex={1}
          type="text"
          name='Rcvd'
          value={rcvdValue}
          onChange={changeValue}
          onBlur={handleBlur}
        />
      </>
     
      
    );
  }; 

  // retun null ; initial INWard the item
  async function InWard(params) {
    setLoading(true)
    let received_list = []
    let savedGrnItem = []
    ginItemsList.forEach(ginItem =>{
      if (Number(ginItem.received) <= 0){
        received_list.push(ginItem?.purchaseOrderId?.itemMasterId?.itemPartCode)
        toast.error(`Must Enter The Rcvd Qty For ${ginItem?.purchaseOrderId?.itemMasterId?.itemPartCode} or Delete the Data`, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        }) 
      }  
    })
    if (received_list.length > 0){
      setLoading(false)
      return 
    }
   for (const ginItem of ginItemsList) {
    const grnItem = await handleSaveGrnItem(ginItem,null); // Await the promise resolution
    if (grnItem) {
      savedGrnItem.push(grnItem) // Push the resolved item into the array
    }
   }
   if (savedGrnItem.length > 0){
    let saved_data = {
      id :"",
      purchaseOrderId :purchaseOrderDetails?.[0]?.['id'] ? Number(purchaseOrderDetails?.[0]?.['id']) : '',
      status:"Inward",
      goodsReceiptNoteItemDetailsId : savedGrnItem.map(item=>item?.id),
      createdBy : Number(userId) 
    }
    let allow_null = ["id"]
    let cleanValue = removeEmptyValueInObject(saved_data, allow_null)
    try{
      const response = await axiosInstance.post(`/itemmaster/graphql`, {query:  goodsReceiptNoteCreateQuery(cleanValue)});
      const responseData = response?.['data']?.['data']?.['goodsReceiptNoteCreateMutation']
      if (responseData?.success){ 
        toast.success('Inward Successfully', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
          });
        setIsGrnShowing(false)
        
      } else{
        console.log(responseData?.errors);
      }
    } catch (error){
        console.error(error);
    }
     
   }
   setLoading(false)
  }
  
  /* retun null; initial we did not save in 
     data base so we remove in local
     on edit hidden the delete button */
  function deleteLocaly(value) {
    if (value){ 
      setGinItemsList( 
        ginItemsList?.filter(ginItem => ginItem?.sNo !== deleteData?.sNo)
      )
    }
  }

  const handleGrn = () => {
    setIsGin(false)
     
  };

   async function received() {
    setLoading(true)
  for (let item of ginItemsList) {
    if (item.excess <= 0) { 
      let isBatch_ = item?.purchaseOrderId?.itemMasterId?.batchNumber;
      let isSerial_ = item?.purchaseOrderId?.itemMasterId?.serial;
      if (isBatch_) { 
        if (!item?.batchNumber?.id) { 
          alert_custom_error(`${item?.purchaseOrderId?.itemMasterId?.itemPartCode} Must Enter Batch Number`);
          setLoading(false)
          return; // Stops further execution if there's an error
        }
      } else if (isSerial_) {
        if (!item?.serialNumber || item?.serialNumber.length <= 0) {
          alert_custom_error(`${item?.purchaseOrderId?.itemMasterId?.itemPartCode} Must Enter Serial Number`);
          setLoading(false)
          return; // Stops further execution if there's an error
        }
      } 
      try {
        let peremeter = `id : ${editGrn?.id}, createdBy : ${Number(userId)} ` 
        const response = await axiosInstance.post(`/itemmaster/graphql`, {
          query: goodsReceiptNoteAndStockAddtionsCreateMutation(peremeter),
        });
        const responseData = response?.['data']?.['data']?.['goodsReceiptNoteAndStockAddtionsCreateMutation'];
        if (responseData?.success) {
          setEditGrn({id: editGrn?.id})
          toast.success('Received Successfully', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
        } else {
          console.log(responseData?.errors);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      alert_custom_error(`${item?.purchaseOrderId?.itemMasterId?.itemPartCode} Not Allow To Add The Excess`);
      setLoading(false)
      return; // Stops further execution if there's an error
    }
  }
  setLoading(false)
  }
  const [isShowTimeLine, setIsShowTimeLine] = useState(false)
  const [timeLineData, setTimeLineData] = useState([])
  async function fetchTimelineData(id){
    setLoading(true)
    let queryperameter = `purchaseId: ${id}`
    try{
      const respones = await axiosInstance.post(`/itemmaster/graphql`, {
        query:  PurchaseOrderTimeLine(queryperameter)
      })
      let timeLime = respones?.data?.data?.purchaseOrderTimeLine1  
      setTimeLineData(timeLime)
      setIsShowTimeLine(true)
    } catch(error){
       console.log(error);
    }
    setLoading(false)
  }
  const [waringToCopy, setWaringToCopy] = useState(false)
  const [waringContent, setWaringContent] = useState({  Title:"" , content:""})

  // retun null; set status to cancle  grn
  async function makeStatesCancle (params, idDelete ) {
    if (params){
      let perameter = `id:${Number(editGrn?.id)}, isDelete: ${idDelete}`
      try{ 
        const respones = await axiosInstance.post("/itemmaster/graphql", {query: goodsReceiptNoteCancleMutation(perameter) })
        const responseData = respones.data.data.goodsReceiptNoteCancleMutation
        if (responseData.success){ 
          setTopBarDatas(prve=>({
            ...prve , status : "Canceled"
          }))
          let message = "Canceled"
          if (idDelete){
            message = "Deleted"
          }
            toast.success(`${message} Successfully`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
        }  else{
            let error = responseData.errors
            let errorData = ShowErrorNoties(error) 
            toast.error( errorData , {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              });
          }  
    } catch(error){ 
    }
    if (idDelete){
      if (setLoad){
        setIsGrnShowing(false)
        setLoad()
      }
      
    }
    } 
  }

  // retun null; set status to cancle  GIN
  async function makeStatesCancleGIN (params, idDelete) {
    if (params){
      let perameter = `id:${Number(editGrn?.id)}, isDelete: ${idDelete}`
      try{ 
        const respones = await axiosInstance.post("/itemmaster/graphql", {query: goodsInwardNoteCancleMutation(perameter) })
        const responseData = respones.data.data.goodsInwardNoteCancleMutation
        if (responseData.success){ 
          setTopBarDatas(prve=>({
            ...prve , status : "Canceled"
          }))
          let message = "Canceled"
          if (idDelete){
            setEditGrn({"id":""})
                message = "Delete"
                setIsGrnShowing(false)
                setLoad()
          }
            toast.success(`${message} Successfully`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
        } else if(responseData?.action) {
          setWaringToCopy(true)
          setWaringContent({Title:"Waring", content:responseData?.action})
        } else{
            let error = responseData.errors
            let errorData = ShowErrorNoties(error) 
            toast.error( errorData , {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              });
        }
    } catch(error){ 
      let errorData = ShowErrorNoties(error)
      toast.error( errorData , {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
    }
    } 
  }

  // onclick in tree view open the model
  function openTheClickData(params) { 
    let modelName = params?.['name'];
    let id; 
    if (params['attributes']){
       id = params?.['attributes']?.['id']
    } else{
      id = params?.['id']
    }
    if (modelName==="GIN" && id!==""){
      setIsGin(true)
      setIsGrnShowing(true)
      setEditGrn({'id': id}) 
    } 
    else if(modelName==="QIR" && id!==""){
      setIsShowqir(true)
      setQirEdit(id)
    }
     else if(modelName === "GRN" && id!==""){
      setIsGin(false)
      setIsGrnShowing(true)
      setEditGrn({'id': id})
    } else if(modelName === "Purchase Order" && id !== ""){
      setpurchaseOrderIsShowing(true)
      setPurchaseOrderEdit({'id':id})
    } 
  }

  function Close(params) {

    setIsGrnShowing(false) 
    setIsGin(true)
    setEditGrn({'id': ""})
    let TopBarData = {status:null, createdBy:"", 
      LastModifiedDate: "",
      grncreatedBy : "",
      GrnLastModifiedDate : "",
    }
    setTopBarDatas(TopBarData)
  }

  const fieldsOrderTrue = [
    { name: 'gin_no', label: 'GIN No', type: 'text', value: supplierDatas?.ginNo || '' },
    { name: 'gin_date', label: 'GIN Date', type: 'text', value: supplierDatas?.ginDate || '' },
    { name: 'grn_no', label: 'GRN No', type: 'text', value: supplierDatas?.grnNo || '' },
    { name: 'grn_date', label: 'GRN Date', type: 'text', value: supplierDatas?.grnDate || '' }
  ];

  const fieldsOrderFalse = [
    { name: 'grn_no', label: 'GRN No', type: 'text', value: supplierDatas?.grnNo || '' },
    { name: 'grn_date', label: 'GRN Date', type: 'text', value: supplierDatas?.grnDate || '' },
    { name: 'gin_no', label: 'GIN No', type: 'text', value: supplierDatas?.ginNo || '' },
    { name: 'gin_date', label: 'GIN Date', type: 'text', value: supplierDatas?.ginDate || '' }
  ];

  const fields = isGin ? fieldsOrderTrue : fieldsOrderFalse;
  
  return (
   <>
      <BallTriangles isshow={loading}/>
       {<TreeComponent
        show = {isShowTimeLine}
        setShow = {setIsShowTimeLine}
        timeLineData= {timeLineData}
        clickedData={value => {
          openTheClickData(value)
        }}
        currentID = {supplierDatas?.id}
        modelName= {isGin ? "GIN": "GRN" }
       />}
      <Modal show={isGrnShowing} fullscreen={true}   > 
        <Modal.Body> 
          <div className='itemMaster_Top mx-3 mt-3 d-flex justify-content-center card shadow'  ref={modalContentRef}  style={{ width: '98%'}}>
            <div className="row justify-content-between align-items-center">
              <div className="col-5">
                <h4 className='mx-2 commanModelTitleColor'>
                  <i className='fa-solid fa-arrow-left fa-sm me-2' onClick={()=>{Close()}}></i>
                  {isGin ? "Goods Inward Note" : "Goods Receipt Note" }   <span className='ms-3 ps-3 btn badge rounded-pill text-dark  fs-6 logoGreen'  >{topBarDatas.status}</span>
                  <span className='link_color cursor-pointer ms-3'  onClick={()=>{ if (supplierDatas?.purchaseOrderId) { 
                                                                          fetchTimelineData(supplierDatas?.purchaseOrderId)
                                                                          }}}>Time Line</span>
                </h4>
              </div>
              <div className="col-5 text-end me-3">
                <div className="row">
                  {topBarDatas.createdBy ?<>
                  <div className="col-9">
                      <span className='fs-6'  >Created By : {isGin? topBarDatas?.createdBy : topBarDatas?.grncreatedBy}</span>
                      <br/>
                      <span className='fs-6'>Last Modified Date : {isGin?`${ DateFormate(topBarDatas?.LastModifiedDate)} (${TimeFormate(topBarDatas?.LastModifiedDate)})`:
                        `${ DateFormate(topBarDatas?.GrnLastModifiedDate)} (${TimeFormate(topBarDatas?.GrnLastModifiedDate)})`} </span>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-end">
                     {isGin? <>
                        <TiCancel hidden={topBarDatas.status === "Inward"? false : true}
                          data-bs-toggle="tooltip" data-bs-placement="top" title="Cancle"
                          className='fs-2 pb-1'
                          style={{ color: '#7f7f7f', cursor: "pointer" }}
                          onClick={() => {
                            setWaringToCancle(true)
                          }}
                        />
                         {/* <i className="fa-solid fa-pen fs-5 text-primary pe-3"  hidden={!active  || !(purchaseOrderEdit && edit) || states === "Submit" ? true : false }  onClick={()=>{setEdit(!edit)}}  ></i> */}
                        <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3" style={{cursor: "pointer"}}
                           onClick={()=>{setWaringToDelete(true)}}
                        hidden= { topBarDatas?.status ==="Canceled"? false: true}></i> 
                     </>
                     :
                     <>
                        <TiCancel hidden={topBarDatas.status === "Received"? false : true}
                        data-bs-toggle="tooltip" data-bs-placement="top" title="Cancle"
                        className='fs-2 pb-1'
                        style={{ color: '#7f7f7f', cursor: "pointer" }}
                        onClick={() => {
                          setWaringToCancle(true)
                        }}
                        />
                          {/* <i className="fa-solid fa-pen fs-5 text-primary pe-3"  hidden={!active  || !(purchaseOrderEdit && edit) || states === "Submit" ? true : false }  onClick={()=>{setEdit(!edit)}}  ></i> */}
                          <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  "
                          onClick={()=>{setWaringToDelete(true)}} hidden={topBarDatas.status === "Received"? true : topBarDatas?.status ==="Canceled"? false: true}></i> 
                        </>} 
                  </div>
                   </>
                  :
                  <></> }                           
                </div> 
              </div>
            </div>
          </div>
          <div style={{width:"98%"}} id='top'>
              <Formik
              initialValues={initialGrn}
              validationSchema={""}>
              {({errors, touched, setFieldValue, values, isSubmitting})=>{
                return(
                  <Form> 
                    {/* supplier Details */}
                    <div className="row ms-3 mt-2"> 
                        {/*left side*/}
                      <div className="col card  shadow mx-2 ">
                        <div className="row py-3"> 
                          
                          {fields.map((field, index) => (
                              <div key={index} className="col-6 staan-input-group">
                                <Field type={field.type} name={field.name} value={field?.value} disabled className='w-100 staan-form-input' />
                                <label htmlFor={field.name} className='staan-form-input-label pt-1 px-1 mx-2'>{field.label}</label>
                              </div>
                            ))}
                          <div className="col-6 staan-input-group">
                            <Field type='Text' name='SupplierCode' disabled value={supplierDatas?.supplierCode}  className='w-100 staan-form-input'/>
                            <label htmlFor="SupplierCode" className='staan-form-input-label pt-1 px-1 mx-2'>Supplier Code</label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='Text' name='SupplierName' disabled value={supplierDatas?.supplierName}   className='w-100 staan-form-input'/>
                            <label htmlFor="SupplierName" className='staan-form-input-label pt-1 px-1 mx-2'>Supplier Name</label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='Text' name='PurchaseOrderNo' disabled value={supplierDatas?.purchaseOrderNo}   className='w-100 staan-form-input'/>
                            <label htmlFor="PurchaseOrderNo" className='staan-form-input-label pt-1 px-1 mx-2'>Purchase Order No</label>
                          </div>
                        </div>
                      </div>
                        {/* right side */}
                      <div className="col card  shadow mx-2">
                        <div className="row py-3">
                          <div className="col-6 staan-input-group">
                            <Field type='date' name='due_date' disabled value={supplierDatas?.dueDate} className='w-100 staan-form-input'/>
                            <label htmlFor="due_date" className='staan-form-input-label pt-1 px-1 mx-2'>Due Date</label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='Text' name='department' disabled value={supplierDatas?.department} className='w-100 staan-form-input'/>
                            <label htmlFor="department" className='staan-form-input-label pt-1 px-1 mx-2'>Department</label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='Text' name='ReceivingStore' disabled  value={supplierDatas?.receivingStore} className='w-100 staan-form-input'/>
                            <label htmlFor="ReceivingStore" className='staan-form-input-label pt-1 px-1 mx-2'>Receiving Store</label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='Text' name='scrap_reject_store' disabled value={supplierDatas?.scrapRejectStore} className='w-100 staan-form-input'/>
                            <label htmlFor="scrap_reject_store" className='staan-form-input-label pt-1 px-1 mx-2'>scrap reject store</label>
                          </div>
                          <div className="col-12 staan-input-group">
                            <Field type='Text' name='remark' disabled value={supplierDatas?.remarks}  className='w-100 staan-form-input'/>
                            <label htmlFor="remark" className='staan-form-input-label pt-1 px-1 mx-2'>Remark</label>
                          </div>
                        </div>

                      </div>
                    </div>
                    {/* GST Details */}
                    {/* <div className="row ms-3 mt-3">
                      <h5 className='mt-4' >GST Details</h5>
                      <div className="col card shadow mx-2 py-3">
                        <div className="row">
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='gstin_type' disabled value={supplierDatas?.gstinType} className='w-100 staan-form-input'/>
                            <label htmlFor="gstin_type" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN Type</label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='gstin' disabled value={supplierDatas?.gstin} className='w-100 staan-form-input'/>
                            <label htmlFor="gstin" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN</label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='state' disabled value={supplierDatas?.state} className='w-100 staan-form-input'/>
                            <label htmlFor="state" className='staan-form-input-label pt-1 px-1 mx-2'>State</label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='PlaceofSupply' disabled value={supplierDatas?.PlaceOfSupply} className='w-100 staan-form-input'/>
                            <label htmlFor="PlaceofSupply" className='staan-form-input-label pt-1 px-1 mx-2'>Place of Supply</label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='EwayBill' className='w-100 staan-form-input'/>
                            <label htmlFor="EwayBill" className='staan-form-input-label pt-1 px-1 mx-2'>Eway Bill</label>
                          </div> 
                        </div>
                      </div>
                      <div className="col card shadow mx-2 py-3">
                        <div className="row">
                          <div className="col-6">
                            <p className='fs-6'>Billing Address</p> 
                            <div className="row py-1">
                              <div className="col-2">
                              Line 1
                              </div>
                              <div className="col-1">
                                :
                              </div>
                              <div className="col-9">
                                {supplierDatas?.address?.addressLine1}
                              </div>
                            </div>
                            <div className="row py-1">
                              <div className="col-2">
                              Line 2
                              </div>
                              <div className="col-1">
                                :
                              </div>
                              <div className="col-9">
                                {supplierDatas?.address?.addressLine2}
                              </div>
                            </div>
                            <div className="row py-1">
                              <div className="col-2">
                               City
                              </div>
                              <div className="col-1">
                                :
                              </div>
                              <div className="col-9">
                                {supplierDatas?.address?.city}
                              </div>
                            </div>
                            <div className="row py-1">
                              <div className="col-2">
                              State
                              </div>
                              <div className="col-1">
                                :
                              </div>
                              <div className="col-9">
                                {supplierDatas?.address?.state}  
                              </div>
                            </div>
                            <div className="row py-1">
                              <div className="col-2">
                              Pincode
                              </div>
                              <div className="col-1">
                                :
                              </div>
                              <div className="col-9">
                                {supplierDatas?.address?.pincode}
                              </div>
                            </div> 
                          </div>
                          <div className="col-6">
                            <p className='fs-6'>Contact</p>
                            <p>Name <span className='px-4'>:</span> {supplierDatas?.contact} </p>
                          </div>
                             
                        </div>
                      </div>

                    </div> */}
                    <div className="row ms-3 ">
                      <div className="col-3">
                        <h6 className='mt-4 commanModelTitleColor'>Item Details</h6>
                      </div>
                      <div className="card shadow p-3">
                        <div className="ag-theme-alpine mt-2" style={{ height: '40rem', width: '100%' }}>
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Part Code</th>
                                <th scope="col">Part Name</th>
                                <th scope="col">PO Qty</th>
                                <th scope="col">UOM</th>  
                                <th scope="col">Inward</th>
                                <th scope="col" hidden={!isGin}>Balance</th>
                                <th scope="col" >Excess</th>
                                <th scope='col'hidden={isGin}>Accepted</th>
                                <th scope="col" hidden={isGin}>Rejected</th>
                                <th scope="col" hidden={isGin}>Rework</th> 
                                <th scope="col" hidden={topBarDatas.status !== "Canceled" ? isChecked ?  isChecked === isGin ? true : false : true: true}>Reference</th>
                                <th scope="col">QC</th>
                                <th scope="col"  hidden={!isGin}>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                            {ginItemsList?.map((item, index) => ( 
                              <tr key={index}>
                                <td>{item?.sNo}</td>
                                <td className={item?.stockAdded ? "text-success fw-bold" : ""}>{item?.purchaseOrderId?.itemMasterId?.itemPartCode}</td>
                                <td className={item?.stockAdded ? "text-success fw-bold" : ""}>{item?.purchaseOrderId?.itemMasterId?.itemName}</td>
                                <td>{item?.purchaseOrderId?.poQty}</td>
                                <td>{item?.purchaseOrderId?.uom?.name}</td> 
                                <td  >
                                  {isNew || editRowIndex === index ? (
                                    <RcvdInput value={item?.received} data={item}/>
                                  ) : (
                                    item?.received
                                  )}
                                </td>
                                <td hidden={!isGin}>{item?.balance}</td>
                                <td >{item?.excess}</td>
                                <td hidden={isGin}>{item?.qualityinspectionsreportitemdetailsSet?.[0]?.accepted}</td> 
                                <td hidden={isGin}>{item?.qualityinspectionsreportitemdetailsSet?.[0]?.rejected}</td>
                                <td hidden={isGin}>{item?.qualityinspectionsreportitemdetailsSet?.[0]?.rework}</td>
                                <td hidden={topBarDatas.status !== "Canceled" ? isChecked ?  isChecked === isGin ? true : false : true:true }>
                                  {item?.purchaseOrderId?.itemMasterId?.batchNumber || item?.purchaseOrderId?.itemMasterId?.serial ? 
                                    <span  className={ item?.batchNumber?.id !== undefined && item?.batchNumber?.id !== null ? "badge bg-success cursor-pointer" 
                                      : item?.serialNumber?.length ?  "badge bg-success cursor-pointer" : " badge bg-secondary cursor-pointer"
                                  }  onClick={()=>{setIsShowBatchSerialModel(true)
                                  setReferenceData(item)
                                }} >B/S</span>: <span>NA</span>
                                }
                                  </td>
                                <td>{item?.purchaseOrderId?.itemMasterId?.itemQc ? "yes" : "No"}</td>
                                <td>
                                  <button 
                                  hidden={!isGin}
                                    className='btn btn-outline-success btn-sm px-3 mx-2'
                                    onClick={() => {setEditRowIndex(index)
                                      const updatedReceived = item.purchaseOrderId.received - item.received;
                                      item.purchaseOrderId.received = updatedReceived}}
                                    disabled={item?.stockAdded ? true : isNew || editRowIndex === index} // Disable if already in edit mode
                                  >
                                    <i className="fa-solid fa-pen"></i>
                                  </button>
                                  <button
                                  type="button"
                                  hidden = {!isNew}
                                    className='btn btn-outline-danger btn-sm px-3 mx-2'
                                    onClick={()=>{setdeleteWaringModel(true)
                                       setDeleteData(item) }}
                                  >
                                  <i className="fa-solid fa-trash "></i> 
                                  </button>
                                </td>
                              </tr>
                            ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      
                    </div>
                    <div className="row m-3 justify-content-evenly">
                      {isGin ?
                      <>
                        <div className="col-1">
                          <Can I={"Inward"} a={"GIN"}>
                          <button type="button" style={{width:"6rem"}} disabled={topBarDatas.status === null ? false : true } onClick={()=>{InWard()}} class="btn btn-outline-primary">Inward</button>
                          </Can>
                        </div>
                        <div className="col-1">
                          <Can I={"QC"} a={"GIN"}>
                          <button type="button" style={{width:"6rem"}} disabled={topBarDatas.status === "Inward" ? false : true } onClick={()=>{setIsShowqir(true)}} class="btn btn-outline-secondary">QC</button>
                          </Can>
                        </div>
                        <div className="col-1"> 
                          <Can I={"GRN"} a={"GIN"}>
                          <button type="button" style={{width:"6rem"}} disabled={topBarDatas.status === "Inward" && isChecked  ? false : true } onClick={()=>{handleGrn()}} class="btn btn-outline-success">GRN</button>
                          </Can>
                        </div>
                      </> :
                      <>
                        <div className="col-1">
                          <Can I={"Received"} a={"GRN"}>
                          <button type="button" style={{width:"6rem"}} class="btn btn-outline-primary" disabled={topBarDatas?.status === "Canceled" ?true : isStockFullyAdded} onClick={()=>{received()}}>Received</button>
                          </Can>
                        </div>
                      </>
                      }
                     
                    </div>
                  </Form>
                )
              }}
              </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer/> 
      {isShowqir && <QIR
       isShowqir = {isShowqir}
       setIsShowqir = {setIsShowqir}
       ginItemsList = {editGrn?.qualityInspectionReportId?.id ? {"ginItemsList" : ginItemsList, "supplierDatas": supplierDatas, "purchaseId":supplierDatas?.purchaseOrderId, "purchaseId":supplierDatas?.purchaseOrderId} :
      {"ginItemsList" : ginItemsList, "supplierDatas": supplierDatas , "purchaseId": supplierDatas?.purchaseOrderId}}
       editViewId = {qirEdit}
       setQirEdit = {setQirEdit}
      />} 
      {deleteWaringModel && <WaringModel 
        state = {deleteWaringModel}
        setstate = {setdeleteWaringModel}
        title={"Confirm"}
        text={'Confirm to remove the item'}
        Confirmtion={value => {
          deleteLocaly(value)
        }}
      />}
      {isshowBatchSerialModel && 
      <BatchOrSerial
      isshowBatchSerialModel= {isshowBatchSerialModel}
      setIsShowBatchSerialModel = {setIsShowBatchSerialModel}
      referenceData= {referenceData} 
      handleSaveGrnItem = {handleSaveGrnItem}
      />}
      {waringToCopy &&
       <WaringModelWithCopy
       state={waringToCopy}
        setState = {setWaringToCopy}
        title={waringContent?.Title}
        text={waringContent?.content}
       
       />
      }
      {waringToCancle && 
      <WaringModel 
      state={waringToCancle}
      setstate={setWaringToCancle}
      title={"Confirmation"}
      text={'Confirm to cancel'}
      Confirmtion={value => {
        if (isGin){
          makeStatesCancleGIN(value, false)
        } else{
          makeStatesCancle(value, false)
        } 
      }}/>}

      {waringToDelete && 
      <WaringModel
        state={waringToDelete}
        setstate={setWaringToDelete}
        title={"Confirmation"}
        text={'Confirm to Delete'}
        Confirmtion={value => {
          if (isGin){
            makeStatesCancleGIN(value, true)
          } else{
            makeStatesCancle(value, true)
          } 
        }}
      />
      }
   </>
  )
}

export default Grn