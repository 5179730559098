import { 
    FETCH_DATA_FAILURE,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_REQUEST
} from "../constant";

const initialState = {
    data: [],
    isLoading: false,
    error: null,
};
    
export const CurrencyDataReducer = (state = initialState, action) => {
      switch (action.type) {
        case FETCH_DATA_REQUEST:
          return {
            ...state,
            isLoading: true,
            error: null,
        };
        case FETCH_DATA_SUCCESS:
          return {
            ...state,
            isLoading: false,
            data: action.payload,
        };
        case FETCH_DATA_FAILURE:
          return {
            ...state,
            isLoading: false,
            error: action.payload,
        };
        default:
          return state;
      }
};
