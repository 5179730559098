import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const DeleteData = ({value, close, show, proceedDelete}) => {
    return (
        <>
        <Modal
            show={show} 
            backdrop="static"
            keyboard={false}
        > 
            <Modal.Header className='py-1'>
                <Modal.Title>Confirm</Modal.Title>
            </Modal.Header >
            <Modal.Body>
                <div className="row fs-6 ms-2 ">
                    <div className="col-12">
                    <p className='text-center'>Are you sure you want to delete "{value}" ?</p>
                    </div>
                    
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center py-1'>
                <Button size='sm' variant="danger px-4 " onClick={()=>{proceedDelete(false); close()}}>No</Button>
                <Button  size='sm'  variant="success px-4 " onClick={()=>{proceedDelete(true); close()}}>Yes</Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default DeleteData;