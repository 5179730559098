import React, { createContext, useContext, useEffect, useState } from 'react';

const QuotationContext = createContext();

export const QuotationProvider = ({ children }) => {
  const [IsDisableAfterOverallDiscount, setIsDisableAfterOverallDiscount] = useState(false);
  const [otherIncomeChargesData, setOtherIncomeChargesData] = useState([]);
  const [quotationEditView, setquotataionEditView] = useState(null);
  const [isAfterOverallDiscount, setAfterOverallDiscount] = useState(false)
  const [checkDiscount, setCheckedDiscount] = useState(false)
  useEffect(()=>{
    console.log("isAfterOverallDiscount---->>>", isAfterOverallDiscount);
    
  },[isAfterOverallDiscount])

  const [QuotationEditData, setQuotationEditData] = useState([])

  const [itemDetailsData, setItemDetailsData] = useState([])
  const [QuotationItemDetailsShow, setQuotationItemDetailsShow] = useState(false)
  const [IsRateEditable, setRateEditable] = useState(false)


  // state variables for discounts
  const [overallDiscountPercentage, setOverallDiscountPercentage] = useState('');
  const [overallDiscountValue, setOverallDiscountValue] = useState('');
  const [finalTotal, setFinalTotal] = useState('');
  const [loading, setLoading] = useState(false)


  // state variables for item combo
  const [itemComboData, setItemComboData] = useState([])
  const [itemComboStatus, setItemComboStatus] = useState('')
  const [currencyData, setcurrencyData] = useState("")

  const [quotationStatus, setquotationStatus] = useState(" ");

  const [showDiscount, setShowDiscount] = useState(false);


  const [toSaveData, setToSaveData] = useState({
    beforeTax: '',
    otherIncomeChagersBeforeTax: "",
    roundOff: "",
    netAmount: '',
    itemDatalist: [],
    totaltax: '',
    cgst: {},
    sgst: {},
    igst: {},
    actualigstResult: "",
    actualcgstResult: "",
    actualsgstResult: "",
    actualNetAmount: "",
    otherIncomeChargesId: []
  });

  return (
    <QuotationContext.Provider value={{
      IsDisableAfterOverallDiscount,
      setIsDisableAfterOverallDiscount,
      otherIncomeChargesData,
      setOtherIncomeChargesData,
      quotationEditView,
      setquotataionEditView,
      overallDiscountPercentage,
      setOverallDiscountPercentage,
      overallDiscountValue,
      setOverallDiscountValue,
      finalTotal,
      setFinalTotal,
      itemComboData,
      setItemComboData,
      itemDetailsData,
      setItemDetailsData,
      QuotationItemDetailsShow,
      setQuotationItemDetailsShow,
      IsRateEditable,
      setRateEditable,
      QuotationEditData,
      setQuotationEditData,
      quotationStatus,
      setquotationStatus,
      itemComboStatus,
      setItemComboStatus,
      toSaveData,
      setToSaveData,
      checkDiscount,
      setCheckedDiscount,
      isAfterOverallDiscount,
      setAfterOverallDiscount,
      currencyData,
      setcurrencyData,
      showDiscount, 
      setShowDiscount,
      loading, 
      setLoading
    }}>
      {children}
    </QuotationContext.Provider>
  );
};

export const useQuotationContext = () => useContext(QuotationContext);
