import { combineReducers } from 'redux';
import storeReducer from './storeReducer';
import {
    itemMasterReducer, 
    itemOptionsReducer, 
    itemDisplayGroupReducer,
    gstTypeReducer
} from './itemMasterReducer';
import uomReducer from './uomReducer'
import {
    finishedGoodsReducer, 
    rawMaterialReducer, 
    scrapReducer, 
    routingReducer, 
    workCenterReducer, 
    bomReducer,
    supplierReducer
} from './bomReducer';
import posReducer from './posReducer';
import { stockSerialHistoryReducer } from './stockReducer';
import { AccountMasterReducer } from './accountMasterReducer';
import { userReducer } from './userReducer';
import { CurrencyDataReducer } from './currencyMasterReducer';
import { ConferenceReducer } from './conferenceReducer';


const rootReducer = combineReducers({
    storeData: storeReducer,
    itemMasterData: itemMasterReducer,
    itemGroup : itemOptionsReducer ,
    uomData: uomReducer,
    finishedGoodsData: finishedGoodsReducer,
    rawMaterialData: rawMaterialReducer,
    scrapData: scrapReducer,
    itemDisplayGroup: itemDisplayGroupReducer,
    posReducer : posReducer,
    stockSerialHistory: stockSerialHistoryReducer,
    accountMaster: AccountMasterReducer,
    gstType: gstTypeReducer,
    userData: userReducer,
    currencyData: CurrencyDataReducer,
    routingData: routingReducer,
    workCenterData: workCenterReducer,
    bomData: bomReducer,
    supplierData: supplierReducer,
    conferenceData: ConferenceReducer,
});


export default rootReducer;
