import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'; 
import { VscSaveAs } from "react-icons/vsc";
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux'; 
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { userOptionQuery } from '../../../GraphQLQueries/ItemmasterQuery';
import {ToastContainer, toast , Bounce} from 'react-toastify';
import { PurchaseOrderTimeLine, qualityInspectionCancleMutation, qualityInspectionReportCreateQuery, qualityInspectionsReportItemDetailsSave } from '../../../GraphQLQueries/PurchaseItemMutations';
import DataContext from '../../../context/ItemMasterContext';
import {  DateFormate, ShowErrorNoties, TimeFormate,  removeEmptyValueInObject } from '../../../utils/Utils';
import axiosInstance from '../../../api/axoiss';
import { QirFetchQuery } from '../../../GraphQLQueries/PurchaseItemQueries';
import TreeComponent from '../../../components/react-d3-tree/poHistory';
import Grn from '../../GRN/models/Grn';
import { TiCancel } from 'react-icons/ti'
import WaringModel from '../../../components/Warings/WaringModel';
import WaringModelWithCopy from '../../../components/Warings/WaringModelWithCopy';
import DeliveryChallanForm from '../../DeliveryChallan/Model/DeliveryChallanForm'; 
import Can from "../../../components/authorizationComponents/Can"

const QIR = ({isShowqir,setIsShowqir, ginItemsList, editViewId, setQirEdit, setLoad}) => {
    const {userId} = useContext(DataContext)
    const [qirItemsList, setQirItemsList] = useState([])
    const [editRowIndex, setEditRowIndex] = useState(null);
    const [qcUser, setQcUser] =  useState([])
    const [isNew, setIsNew ] = useState(false)
    const dispatch = useDispatch();
    const [remark, setRemark] = useState('');
    const [status, setStatus]= useState('');
    const Options = useSelector(state => state?.itemGroup?.data)
    const [isAllowCheck, setisAllowCheck] = useState(true)
    const [waringToCancle, setWaringToCancle] = useState(false)
    const [waringToDelete, setWaringToDelete] = useState(false)
    const [mainData, setMainData] = useState({
        "qin_no" : "",
        "qin_date" : "",
        "gin_no" : "",
        "gin_date" : "",
        "remark":"",
        "purchaseId":"",
        "reworkDeliveryChallan" : ""
    })

    async function prefetchData(){
        dispatch(fetchOptionsData(userOptionQuery() , "QcUser"))
    }

    useEffect(()=>{ 
        if (Options?.name === "QcUser"){
            setQcUser(Options?.value?.items.map((user)=>({value:user?.id, label: user?.username})))
        }
        if (Options?.name === "EditQir"){
            let EditData = Options?.value?.items?.[0] 
            const modifiedItems = EditData?.qualityInspectionsReportItemDetailId?.map((element, index) => {
                let currentCheckedBy = element['checkedBy']
                let checkedBy  = {value: currentCheckedBy?.['id'], label : currentCheckedBy?.['username']}
                return { ...element, sNo: index + 1, checkedBy :  checkedBy};
            })
            setQirItemsList(modifiedItems)
            setStatus(EditData?.status?.status)
            setRemark(EditData?.remarks) 
            setMainData({
                "qin_no" : EditData?.qirNo?.linkedModelId,
                "qin_date" :EditData?.qirDate,
                "purchaseId":EditData?.goodsreceiptnoteSet?.[0]?.purchaseOrderId?.id,
                "gin_no": EditData?.goodsreceiptnoteSet?.[0]?.grnNo?.linkedModelId,
                "gin_date": EditData?.goodsreceiptnoteSet?.[0]?.grnDate,
                "createdBy" : EditData?.createdBy?.username,
                "createdAt" : EditData?.createdAt,
                "reworkDeliveryChallan" : EditData?.reworkDeliveryChallan?.id
            })
        }
    },[Options])

    // initial 
    useEffect(()=>{
        if (isShowqir && editViewId !== "" && editViewId !== undefined){
            if (editViewId){
                dispatch(fetchOptionsData(QirFetchQuery(`id : ${editViewId}`), "EditQir"))
                setIsNew(false)
            }
        } else if(isShowqir && ginItemsList?.ginItemsList?.length > 0){
            let index = 1
            let qir_list = []
            setIsNew(true)
            ginItemsList?.ginItemsList.forEach(ginItem => {
                if (ginItem?.purchaseOrderId?.itemMasterId?.itemQc){
                    let modifieditemDetail = {
                        "sNo": index,
                        "id": "",
                        "goodsReceiptNoteItem": {
                          "id": ginItem?.id,
                          "purchaseOrderId":  ginItem?.purchaseOrderId,
                          "received" : ginItem?.received
                        },
                        "accepted": "0",
                        "rejected": "0",
                        "rework": "0",
                        "createdBy" :{value: "", lable:''}
                    }
                    index += 1
                    qir_list.push(modifieditemDetail)
                }
            });  
            setMainData({
                gin_no : ginItemsList?.supplierDatas?.ginNo,
                gin_date : ginItemsList?.supplierDatas?.ginDate,
                "purchaseId" : ginItemsList?.purchaseId,
            })
            setQirItemsList(qir_list)
        }
        if (isShowqir){
            prefetchData()
        }
    },[isShowqir, editViewId])

    useEffect(()=>{
        allowCheck()
    },[])

    function allowCheck(params) {
        qirItemsList.forEach(qirItems =>{
            let totalValue = Number(qirItems.accepted) + Number(qirItems.rejected) + Number(qirItems.rework) 
            if (Number(qirItems.goodsReceiptNoteItem?.received) !== totalValue){
                setisAllowCheck(false)
                return ""
            } else{
                setisAllowCheck(true)
            }
        }) 
    }
     
    function close() {
        setIsShowqir(false) 
    }

    const handleEditClick = (index) => {
        setEditRowIndex(index);
        allowCheck()
    }
    
    const handleInputChange = (e, index, field) => {
    const newItemsList = [...qirItemsList];
    if (e?.target){
        newItemsList[index] = {
            ...newItemsList[index],
            [field]: e.target.value
        };
    } else{
        newItemsList[index] = {
            ...newItemsList[index],
            [field]: e
        };
    }
    
    setQirItemsList(newItemsList);
    }
    // handle item save
    const handleSaveClick = async (item) => {
    if(isNew){
        let totalValue = Number(item.accepted) + Number(item.rejected) + Number(item.rework) 
        if (Number(item.goodsReceiptNoteItem?.received) === totalValue ){ 
            setEditRowIndex(null);
        } else if(item?.createdBy?.label === ""){
            alert("")
        } else{
            toast.error(`Accepted, Rejected and Rework must be equal to Rcvd Qty`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
              })
        }
    } else{ 
        let qiritem = await handleSaveQirItem(item);
        qiritem['sNo'] = item.sNo; 
        if (qiritem) {
            setQirItemsList((prevItems) =>
                prevItems.map((prevdata) => 
                    Number(prevdata.id) === Number(qiritem.id) ? { ...prevdata, ...qiritem } : prevdata
                )
            );
            setEditRowIndex(null)
        }   
    }
    allowCheck()
    }
    // return object ; save qir Item
    const handleSaveQirItem = async(data)=>{
        let save_data = {
            id : data?.id ? Number(data?.id): "",
            goodsReceiptNoteItem : data?.goodsReceiptNoteItem?.id ? Number(data?.goodsReceiptNoteItem?.id) : "",
            accepted :data?.accepted ?  `${data?.accepted}` :0,
            rejected : data?.rejected ? `${data?.rejected}` :0,
            rework : data?.rework ? `${data?.rework}` : 0,
            checkedBy: data?.id ? Number(data?.checkedBy?.value) : Number(userId),
            modifiedBy : data?.id ? Number(userId) : "",
            createdBy : data?.id  ? Number(data?.createdBy?.id) : Number(userId) ,
          } 
          let allow_null = ["id", "modifiedBy"]
          let cleanValue = removeEmptyValueInObject(save_data, allow_null)
          try{
            const response = await axiosInstance.post(`/itemmaster/graphql`, {query:  qualityInspectionsReportItemDetailsSave(cleanValue)});
            const responseData = response?.['data']?.['data']?.['qualityInspectionsReportItemDetailsCreateMutation']
            if (responseData?.success){ 
              return responseData.qualityInspectionsReportItemDetailsInstance
            } else{
                let errorData = ShowErrorNoties(responseData.errors)
                toast.error(`${errorData}`, {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
              }); 
            }
          } catch (error){
            let errorData = ShowErrorNoties(error)
            toast.error(`${errorData}`, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          }); 
          }
    }

    const [isShowRDC, setShowRDC]= useState(false)
    const [initialDcData, setInitialDcData] = useState({"poID" : "", "DCItemDetails" : [] , "New" : true})
    async function generateRDC(status){
        let qtyNotmatchingList = []
        let reworkItems = []
        if (status === "Checked"){
            qirItemsList.forEach(qirItems =>{
                let totalValue = Number(qirItems.accepted) + Number(qirItems.rejected) + Number(qirItems.rework) 
                if (Number(qirItems.goodsReceiptNoteItem?.received) !== totalValue){
                    qtyNotmatchingList.push(qirItems?.goodsReceiptNoteItem?.purchaseOrderId?.itemMasterId?.itemPartCode)
                    toast.error(`Accepted, Rejected and Rework must be equal to Rcvd Qty For ${qirItems?.goodsReceiptNoteItem?.purchaseOrderId?.itemMasterId?.itemPartCode} or Delete the Data`, {
                        position: 'bottom-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light'
                      }) 
                }
            })
        }
        if (qtyNotmatchingList.length > 0){
            return 
        }
        for (let index = 0; index < qirItemsList.length; index++) {
            const getRework = qirItemsList[index] 
            if (getRework?.rework > 0){
                let modifiedForRDC = {
                    "sNo":index+1,
                    "itemMasterId":
                    {
                        "id" : getRework?.goodsReceiptNoteItem?.purchaseOrderId?.itemMasterId?.id,
                        "itemPartCode" : getRework?.goodsReceiptNoteItem?.purchaseOrderId?.itemMasterId?.itemPartCode,
                        "itemName" : getRework?.goodsReceiptNoteItem?.purchaseOrderId?.itemMasterId?.itemName,
                    },
                    "hsnId" : {
                        "id" : getRework?.goodsReceiptNoteItem?.purchaseOrderId?.hsnId?.id,
                        "hsnCode"  :getRework?.goodsReceiptNoteItem?.purchaseOrderId?.hsnId?.hsnCode,
                    },
                    "tax" : getRework?.goodsReceiptNoteItem?.purchaseOrderId?.tax,
                    "uom":{
                        "id" : getRework?.goodsReceiptNoteItem?.purchaseOrderId?.uom?.id,
                        "name" : getRework?.goodsReceiptNoteItem?.purchaseOrderId?.uom?.name,
                    },
                    "purchaseOrderItem" :{
                        "id": getRework?.goodsReceiptNoteItem?.purchaseOrderId?.id
                    },
                    "rate": getRework?.goodsReceiptNoteItem?.purchaseOrderId?.rate,
                    "amount" : Number(getRework?.goodsReceiptNoteItem?.purchaseOrderId?.rate) * Number(getRework?.rework),
                    "reworkQty" : getRework?.rework,
                }
                reworkItems.push(modifiedForRDC)
            }
        }
      
        if (reworkItems.length > 0){
            setShowRDC(true) 
            setInitialDcData({"poID" : mainData?.purchaseId, "DCItemDetails":reworkItems, "New" : true})
        } else{
            SaveQir("Checked", 0)
        }
    }

    // retun null ; Save Qir
    async function SaveQir(status, RDC){
        let savedQirData = []
        for (const qirItem of qirItemsList){
            const qirData =  await handleSaveQirItem(qirItem)
            if (qirData){ 
                savedQirData.push(qirData)
            }
        }
        if (savedQirData.length > 0){
            let saved_data = {
                id :editViewId  ? Number(editViewId) : "",
                status: status,
                remarks: remark, 
                qualityInspectionsReportItemDetailId : savedQirData.map(item=>item?.id),
                goodsReceiptNote : Number(ginItemsList?.supplierDatas?.id),
                createdBy : Number(userId)
            }
            if (status === "Checked" && Number(RDC) > 0){
                saved_data['reworkDeliveryChallan'] = Number(RDC)
            }
            let allow_null = ["id"]
            let cleanValue = removeEmptyValueInObject(saved_data, allow_null)
            try{
                const response = await axiosInstance.post(`/itemmaster/graphql`, {query:  qualityInspectionReportCreateQuery(cleanValue)});
                const responseData = response?.['data']?.['data']?.['qualityInspectionReportCreateMutation']
                if (responseData?.success){
                    setIsShowqir(false)
                    setQirEdit(null)
                    if (setLoad){
                        setLoad(true)
                    }
                } else{
                    toast.error(`${responseData?.errors}`, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light'
                      }) 
                }
            } catch (error){
                let filterError =   ShowErrorNoties(error)
                toast.error(`${filterError}`, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light'
                  })
            }
        }
    }
     
    const [isShowTimeLine, setIsShowTimeLine] = useState(false)
    const [timeLineData, setTimeLineData] = useState([]) 
    async function fetchTimelineData(id){
    //   setLoading(true)
      let queryperameter = `purchaseId: ${id}`
      try{
        const respones = await axiosInstance.post(`/itemmaster/graphql`, {
          query:  PurchaseOrderTimeLine(queryperameter)
        })
        let timeLime = respones?.data?.data?.purchaseOrderTimeLine1  
        setTimeLineData(timeLime)
        setIsShowTimeLine(true)
      } catch(error){
         console.log(error);
      }
    //   setLoading(false)
    }

    const [isGin , setIsGin] = useState(true)
    const [isGrnShowing, setIsGrnShowing] = useState(null)
    const [editGrn, setEditGrn] = useState(null)
    function openTheClickData(params) { 
        let modelName = params?.['name'];
        let id;
        
        if (params['attributes']){
           id = params?.['attributes']?.['id']
        } else{
          id = params?.['id']
        }
        if(modelName==="GIN" && id!==""){
          setIsGin(true)
          setIsGrnShowing(true)
          setEditGrn({'id': id})
        } else if(modelName==="QIR" && id!==""){
        } else if(modelName === "GRN" && id!==""){
          setIsGin(false)
          setIsGrnShowing(true)
          setEditGrn({'id': id})
        }  
         
    }
    
    const [waringToCopy, setWaringToCopy] = useState(false)
    const [waringContent, setWaringContent] = useState({  Title:"" , content:""})
    // retun null; set status to cancle
    async function makeStatesCancle (params, isDelete) { 
        if (params){ 
          let perameter =""
          if (isDelete){ 
            perameter += `id:"${editViewId}", grnNo:null, isDelete:${true}`
          } else{
            if (mainData?.gin_no){ 
                perameter += `grnNo:"${mainData?.gin_no}", id:null, isDelete:${false}`
            } else{ 
                perameter += `id:"${editViewId}", grnNo:null, , isDelete:${false}`
            }
          } 
         
          try{
            const respones = await axiosInstance.post("/itemmaster/graphql", {query: qualityInspectionCancleMutation(perameter) })
            const responseData = respones.data.data.qualityInspectionReportCancleMutation
         
            if (responseData?.success){ 
                setStatus("Canceled")
                let meassage = "Canceled"
                if (isDelete){
                    setIsShowqir(false)
                    meassage = "Deleted"
                    if (setLoad){
                        setLoad()
                    }
                }

                toast.success(`${meassage} Successfully`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
            } else if(responseData?.action) { 
                setWaringToCopy(true)
                setWaringContent({Title:"Waring", content:responseData?.action})

            } else{
                let error = responseData?.errors
                let errorData = ShowErrorNoties(error) 
                toast.error( errorData , {
                 position: "bottom-right",
                 autoClose: 5000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
                 progress: undefined,
                 theme: "light",
                 transition: Bounce,
                 });
             }
        } catch(error){  
            let errorData = ShowErrorNoties(error) 
            toast.error( errorData , {
             position: "bottom-right",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "light",
             transition: Bounce,
             });
        }
        }
    }

  return (
     <>
        {<TreeComponent
          show={isShowTimeLine}
          setShow={setIsShowTimeLine}
          timeLineData= {timeLineData}
          clickedData={value => {
            openTheClickData(value)
          }}
          currentID = {editViewId}
        />}
        <Modal show={isShowqir} onHide={close} fullscreen> 
            <Modal.Body> 
                <div className='itemMaster_Top mx-3 mt-3 d-flex justify-content-center card shadow' style={{ width: '98%' }}>
                    <div className="row justify-content-between align-items-center">
                        <div className="col-5">
                            <h4 className='mx-2 commanModelTitleColor'>
                                <i className='fa-solid fa-arrow-left fa-sm me-2' onClick={close}></i>
                                Quality Inspection Report  <span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen ms-2'>{status}</span>
                                <span className='link_color cursor-pointer' onClick={()=>{ if (mainData?.purchaseId) {
                                                                          fetchTimelineData(mainData?.purchaseId);
                                                                        }}}>Time Line</span> 
                            </h4>
                            
                        </div>
                        <div className="col-5 text-end me-3">
                            <div className="row">
                                <div className="col-9">
                                    <span className='fs-6'> {editViewId ? `Created By : ${mainData?.createdBy}` : ""} </span>
                                    <br/>
                                    <span className='fs-6'>{editViewId ? `Last Modified Date : ${ DateFormate(mainData?.createdAt)} (${TimeFormate(mainData?.createdAt)})` : ""  }</span>                                              
                                </div>
                                <div className="col-3 d-flex align-items-center justify-content-end">
                                    <TiCancel hidden={status === "Pending"? false : status ==="Checked"? false: true}
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Cancle"
                                        className='fs-2 pb-1'
                                        style={{ color: '#7f7f7f', cursor: "pointer" }}
                                        onClick={() => {
                                        setWaringToCancle(true)
                                        }}
                                    />
 
                                    {/* <i className="fa-solid fa-pen fs-5 text-primary pe-3"  hidden={!active  || !(purchaseOrderEdit && edit) || states === "Submit" ? true : false }  onClick={()=>{setEdit(!edit)}}  ></i> */}
                                    
                                    <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3 " hidden={status === "Canceled"? false : true}
                                        onClick={()=>{setWaringToDelete(true)}} ></i>                      
                                </div>
                            </div>
                           
                        </div>
                    </div> 
                </div>
                <div style={{width:"98%"}}>
                    <div className="row py-3">
                        <div className="col-12 card  shadow mx-3 ">
                            <div className="row py-3">
                                <div className="col-3 staan-input-group">
                                    <input type='Text' name='qir_no' disabled value={mainData?.qin_no}   className='w-100 staan-form-input'/>
                                    <label htmlFor="qir_no" className='staan-form-input-label pt-1 px-1 mx-2' >QIR No</label>
                                </div>
                                <div className="col-3 staan-input-group">
                                    <input type='Text' name='qir_date' disabled  value={mainData?.qin_no} className='w-100 staan-form-input'/>
                                    <label htmlFor="qir_date" className='staan-form-input-label pt-1 px-1 mx-2'>QIR Date</label>
                                </div>
                                <div className="col-3 staan-input-group">
                                    <input type='Text' name='gin_no' disabled  value={mainData?.gin_no}  className='w-100 staan-form-input'/>
                                    <label htmlFor="gin_no" className='staan-form-input-label pt-1 px-1 mx-2'>GIN No</label>
                                </div>
                                <div className="col-3 staan-input-group">
                                    <input type='Text' name='gin_date' disabled value={mainData?.gin_date}   className='w-100 staan-form-input'/>
                                    <label htmlFor="gin_date" className='staan-form-input-label pt-1 px-1 mx-2'>GIN Date</label>
                                </div>
                                <div className="col-12 staan-input-group"> 
                                    <input type='Text' name='remark' value={remark}  onChange={(e) => { setRemark(e.target.value)}} 
                                    className='w-100 staan-form-input'/>
                                    <label htmlFor="remark" className='staan-form-input-label pt-1 px-1 mx-2' onClick={()=>{console.log(mainData);}}>Remark</label>
                                </div>
                            </div> 
                        </div> 
                    </div>
                    <div className="">
                        <div className="col-3">
                            <h5 className='mt-4' onClick={()=>{console.log(mainData)}}>Item Details</h5>
                        </div>
                        <div className="card shadow p-3">
                            <div className="ag-theme-alpine mt-2" style={{ height: '40rem', width: '100%' }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col">Part Code</th>
                                        <th scope="col">Part Name</th>
                                        <th scope="col">PO Qty</th>
                                        <th scope="col">UOM</th>
                                        <th scope="col">Received</th>
                                        <th scope="col">Accepted</th>
                                        <th scope="col">Rejected</th>
                                        <th scope="col">Rework</th>
                                        <th scope="col">Checked By</th>
                                        <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {qirItemsList?.map((item, index) => (
                                        <tr key={index}> 
                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.sNo}</td>
                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.goodsReceiptNoteItem?.purchaseOrderId?.itemMasterId?.itemPartCode}</td>
                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.goodsReceiptNoteItem?.purchaseOrderId?.itemMasterId?.itemName}</td>
                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.goodsReceiptNoteItem?.purchaseOrderId?.poQty}</td>
                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.goodsReceiptNoteItem?.purchaseOrderId?.uom?.name}</td>
                                            <td className={editRowIndex === index ? "py-3" : ""}>{item?.goodsReceiptNoteItem?.received}</td>
                                            <td>
                                                {editRowIndex === index ? (
                                                    <input
                                                    type="number"
                                                    style={{height:"2.4rem"}}
                                                    value={item?.accepted}
                                                    onChange={(e) => handleInputChange(e, index, 'accepted')}
                                                    />
                                                ) : (
                                                    item?.accepted
                                                )}
                                            </td>
                                            <td>
                                                {editRowIndex === index ? (
                                                    <input
                                                    type="number"
                                                    style={{height:"2.4rem"}}
                                                    value={item?.rejected}
                                                    onChange={(e) => handleInputChange(e, index, 'rejected')}
                                                    />
                                                ) : (
                                                    item?.rejected
                                                )}
                                            </td>
                                            <td>
                                                {editRowIndex === index ? (
                                                    <input
                                                    style={{height:"2.4rem"}}
                                                    type="number"
                                                    value={item?.rework}
                                                    onChange={(e) => handleInputChange(e, index, 'rework')}
                                                    />
                                                ) : (
                                                    item?.rework
                                                )}
                                            </td>
                                            <td  classNames= "p-0 m-0">
                                                {editRowIndex === index ? ( 
                                                    <Select
                                                        name="checkedBy"  
                                                        isSearchable={true} 
                                                        value={item?.checkedBy}
                                                        options={qcUser}
                                                        onChange={(option) => handleInputChange(option, index, 'checkedBy')}
                                                        className='m-0'                 
                                                    /> 
                                                ) : (
                                                     item?.checkedBy?.label ? item?.checkedBy?.label  :item.checkedBy?.username
                                                )}
                                            </td>
                                            <td>
                                            {editRowIndex === index ? (
                                                <button
                                                className="btn btn-outline-success btn-sm px-3 mx-2"
                                                onClick={()=>{handleSaveClick(item)}}
                                                >
                                                <VscSaveAs size={20}/>
                                                </button>
                                            ) : (
                                                <button
                                                className="btn btn-outline-success btn-sm px-3 mx-2"
                                                onClick={() => handleEditClick(index)}
                                                hidden={status === "Checked" ||  status === "Canceled"}
                                                >
                                                <i className="fa-solid fa-pen"></i>
                                                </button>
                                            )}
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-3 justify-content-evenly" hidden={status === "Checked" || status === "Canceled"}> 
                      <div className="col-1">
                        {/* disabled={isAllowCheck} */}
                        <Can I={"Pending"} a={"QIR"}>
                        <button type="button" style={{width:"6rem"}}  class="btn btn-outline-secondary" onClick={()=>{SaveQir("Pending", true)}}>Pending</button>
                        </Can>
                      </div>
                      <div className="col-1">
                        <Can I={"Checked"} a={"QIR"}>
                        <button type="button" style={{width:"6rem"}} disabled={!isAllowCheck} class="btn btn-outline-success" onClick={()=>{generateRDC("Checked")}}>Checked</button>              
                        </Can>
                      </div> 
                </div>
            </Modal.Body> 
            <ToastContainer/> 
        </Modal> 
        <Grn
            isGin = {isGin}
            setIsGin = {setIsGin}
            isGrnShowing = {isGrnShowing}
            setIsGrnShowing = {setIsGrnShowing}
            purchaseOrderDetails = {null}
            editGrn = {editGrn}
            setEditGrn= {setEditGrn}
        /> 
        { waringToCopy &&
        <WaringModelWithCopy
        state={waringToCopy}
        setState = {setWaringToCopy}
        title={waringContent?.Title}
        text={waringContent?.content}
        />
        }
        {waringToCancle && 
        <WaringModel 
        state={waringToCancle}
        setstate={setWaringToCancle}
        title={"Confirmation"}
        text={'Confirm to Cancel'}
        Confirmtion={value => {
            makeStatesCancle(value, false)
          }}
        />} 
        {waringToDelete && 
        <WaringModel 
        state={waringToDelete}
        setstate={setWaringToDelete}
        title={"Confirmation"}
        text={'Confirm to delete'}
        Confirmtion={value => {
            makeStatesCancle(value, true )
          }}
        />}
        <DeliveryChallanForm
        SaveQir = {SaveQir}
        isShowing = {isShowRDC}
        setIsShowing = {setShowRDC}
        initialDcData = {initialDcData}
        />
        
     </>
  )
}

export default QIR