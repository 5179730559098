import React, { useState, useContext, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { customSelectStyle } from '../../../utils/Utils';
import axiosInstance from '../../../api/axoiss';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRoutingData, fetchWorkCenterData } from '../../../ReduxState/Action/bomAction';
import { RoutingFetchQuery } from '../../../GraphQLQueries/ItemmasterQuery';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Select from 'react-select';
import AddWorkCenter from '../../WorkCenter/AddWorkCenter';
import { WorkCenterOptionFetchQuery } from '../../../GraphQLQueries/BomMutation';
import { routing_schema } from '../../../validations/bom';
import { ToastContainer, toast, Bounce} from 'react-toastify';

const AddRouting = ({show, handleClose, handleAddedData, data, isEditView}) => {
    const [routingInputValue, setRoutingInputValue] = useState('');
    const [selectedRoutings, setSelectedRoutings] = useState([]);
    const [selectedRoutingItem, setSelectedRoutingItem] = useState(null)
    const [rowCount, setRowCount] = useState(1);
    const dispatch = useDispatch(); 
    const routingDataList = useSelector(state => state?.routingData?.data);
    const [post, setPost] = useState([]);
    const [currentEditingItem, setCurrentEditingItem] = useState(null);
    const [showAddWorkCenter, setShowAddWorkCenter] = useState(false);
    const [workCenterSelected, setWorkCenterSelected] = useState(null);
    const [timeDuration, setTimeDuration] = useState("");
    const [workCenterOption, setWorkCenterOption] = useState([])

    const handleShowAddWorkCenter = () => {setShowAddWorkCenter(true)};
    const handleCloseAddWorkCenter = () => {setShowAddWorkCenter(false)};
    const handleChangeWorkCenter = (option) => {
        setWorkCenterSelected({label: option?.workCenter, value: option?.id})
    }

    const ActionComponentRenderer = (params) => {
        return(
            <div>
                <span style={{cursor: 'pointer'}} 
                    onClick={()=>{handleEditItem(params?.data)}}><i className='fa-solid fa-pen me-3'></i>
                </span>
                <span style={{cursor: 'pointer'}} 
                    onClick={()=>{handleDeleteItem(params?.data)}}><i className='fa-solid fa-trash text-danger'></i>
                </span>
            </div>
        )
    }

    const gridApiRef = useRef(null);
    const defaultColDef ={
      editable: false, 
    }

    const colums = [
        {
            headerName : "Process",
            field:"label", 
            flex: 3, 
            rowDrag: true 
        },
        {
            headerName : "Work Center",
            field:"workCenterLabel", 
            flex: 3, 
        },
        {
            headerName : "Duration (min)",
            field:"duration", 
            flex: 3, 
        },
        {
            headerName : "Actions",
            flex: 2, 
            cellRenderer: ActionComponentRenderer 
        },
    ]

    const handleRowDragEnd = (e) => {
        const newOrder = [];
        gridApiRef.current.api.forEachNodeAfterFilterAndSort((node, index) => {
            let temp_data = node.data
            temp_data['serialNumber'] = index+1
            newOrder.push(temp_data);
        });
        setPost(newOrder)
    }

    const handleAddRouting = () => {
        setRowCount(prevCount => prevCount + 1); 
    };

    const handleAddNewRoutingItem = async (new_term = '', index) => {
        let routing_query = `mutation MyMutation {
            routingCreateMutation(routeName: "${new_term}") {
              success
              errors
              routing {
                id
                routeName
              }
            }
        }`
        let response = await axiosInstance.post("/itemmaster/graphql", {query: routing_query});
        response = response?.data?.data?.routingCreateMutation?.routing
        if (response){
            let newCreatedRouting = { label: response?.routeName, value: response?.id };
            setSelectedRoutingItem(newCreatedRouting)
            setSelectedRoutings(prevSelectedRoutings => [...prevSelectedRoutings, newCreatedRouting]);
        } else {
            console.error('Invalid response:', response);
        } 
    };

    const handleRoutingChange = (newValue, actionMeta, index) => {
        if(newValue){
            if(actionMeta['action'] === "create-option"){
                handleAddNewRoutingItem(newValue.label, index)
            }else{
                setSelectedRoutingItem(newValue)
                setSelectedRoutings(prevSelectedRoutings => [...prevSelectedRoutings, newValue]);
            }
        }else{
            setSelectedRoutingItem(null)
        }
    };

    const handleRoutingInputChange = (inputValue, actionMeta) => {
        setRoutingInputValue(inputValue);
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Enter' && routingInputValue.trim() !== '') {
          const newOption = { value: routingInputValue.trim(), label: routingInputValue.trim() };
          handleRoutingChange(newOption, { action: 'create-option' }, index);
          event.preventDefault();
        }
    };

    const handleRemoveRow = (delete_row_index) =>{
        let current_row_data = [...selectedRoutings]
        current_row_data = current_row_data.filter((item, index)=> index !== delete_row_index)
        setSelectedRoutings(current_row_data)
        setRowCount(prevCount => prevCount - 1); 
    };

    const handleAddRoutingItem = async () => {
        let current_post_data = [...post];
        let constructed_route_item = {...selectedRoutingItem}
        constructed_route_item['workCenter'] = workCenterSelected?.value ? workCenterSelected?.value : ""
        constructed_route_item['workCenterLabel'] = workCenterSelected?.label ? workCenterSelected?.label : ""
        constructed_route_item['duration'] = parseInt(timeDuration)
        routing_schema.validate(constructed_route_item, {abortEarly: true})
        .then(data => {
            if(currentEditingItem){
                let existing_index = current_post_data.findIndex(item => item?.value === currentEditingItem?.value);
                current_post_data.splice(existing_index, 1, {...constructed_route_item, id: currentEditingItem?.id});
            }else{
                current_post_data = current_post_data.filter(item => item?.value !== selectedRoutingItem?.value)
                current_post_data.push(constructed_route_item)
            }
            setPost(current_post_data);
            setSelectedRoutingItem(null);
            setTimeDuration('');
            setWorkCenterSelected(null);
            setCurrentEditingItem(null);
        })
        .catch(({ errors }) => {
            toast.error(errors?.[0], {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        })
    }

    const handleEditItem = (data) => {
        console.log(data)
        setCurrentEditingItem(data)
        setWorkCenterSelected({label: data?.workCenterLabel, value: data?.workCenter})
        setTimeDuration(data?.duration ? data?.duration : "")
        setSelectedRoutingItem(data)
    }

    const handleDeleteItem = (data) => {
        let current_data = [...post]
        current_data = current_data.filter(item => item.value !== data.value)
        setPost(current_data)
    }

    const handleFetchWorkCenterOptionOnInputChange = (e) => {
        let option_fetch_query = WorkCenterOptionFetchQuery(e)
        axiosInstance.post("/itemmaster/graphql", {query: option_fetch_query})
        .then(res => {
            let response = res?.data?.data?.workCenterMaster?.items
            setWorkCenterOption(response)
        }).catch(e => {
            setWorkCenterOption([])
        })
    }
   
    useEffect(()=>{
        dispatch(fetchRoutingData(RoutingFetchQuery()));
        setPost(data)
    }, [dispatch])
    
   
    return(
        <>
        <ToastContainer />
        <Modal show={show} onHide={handleClose}  dialogClassName="routing-add-modal">
            <Modal.Header closeButton>
                <Modal.Title>Routing</Modal.Title>
            </Modal.Header>
            <Modal.Body>    
                <div>
                    <div className='row'>
                        <div className="col-7 staan-input-group">
                            <CreatableSelect
                                onChange = {(newValue, actionMeta) => handleRoutingChange(newValue, actionMeta, 1)}
                                onInputChange = {handleRoutingInputChange}
                                onKeyDown = {(event) => handleKeyDown(event, 1)}
                                inputValue={routingInputValue}
                                value = {selectedRoutingItem}
                                name = "add_routing" 
                                isClearable
                                options = {routingDataList?.items?.map(item => ({value: item.id, label:  item.routeName}))}
                                styles = {customSelectStyle}
                                clearValue={null}
                                isDisabled = {!isEditView}
                            />
                            <label htmlFor="add_routing" 
                            className='staan-form-input-label-table-create-react-select mx-2 px-1'>Process</label>
                        </div>
                        <div className='col-5 d-flex justify-content-center align-items-center'>
                            <div className='staan-input-group mb-2'>
                                <input type='text' className='w-100 staan-form-input' 
                                    name='duration' 
                                    style={{fontSize: '.7rem', marginTop: '0px'}} 
                                    value = {timeDuration}
                                    onChange={(e)=>{setTimeDuration(e.target.value)}}
                                    disabled = {!isEditView}
                                />    
                                <label htmlFor="duration" 
                                    className='staan-form-input-label px-1 mx-2'
                                    style={{top: '-5px'}}
                                >Duration (min)</label>                                        
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-7 staan-input-group">
                            <Select name="work_center"  
                                onInputChange={(e)=>{handleFetchWorkCenterOptionOnInputChange(e)}}
                                style={{fontSize: '.7rem'}}
                                className='mt-3'
                                styles={customSelectStyle}
                                options={workCenterOption.filter(item => item.workCenter !== undefined).map(item => ({label: item.workCenter, value: item.id}))}
                                onChange={(option)=>{setWorkCenterSelected(option)}}
                                value={workCenterSelected}
                                isClearable
                                isDisabled = {!isEditView}
                            />
                        <label htmlFor="work_center" 
                        className='staan-form-input-label pt-1 px-1 mx-2'>Work Center 
                            <span 
                                onClick={()=>{handleShowAddWorkCenter()}}
                                style={{cursor: 'pointer'}}>
                                    <i className='fa-solid fa-circle-plus ms-1'></i>
                            </span>
                        </label>
                        </div>
                        <div className='col-5 d-flex justify-content-center align-items-center'>
                            <div>
                               <button 
                                    className={`btn btn-sm btn-outline-primary px-2 ${isEditView ? '': 'disabled'}`}
                                    onClick={()=>{handleAddRoutingItem()}}
                                >
                                Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{height: '80%'}}>
                    <div className='ag-theme-alpine mt-4'  style={{ height: '100%', width: '100%' }} >
                        <AgGridReact
                            ref={gridApiRef}
                            rowData={post}
                            columnDefs={colums}
                            defaultColDef={defaultColDef} 
                            rowDragManaged={true}
                            onRowDragEnd={handleRowDragEnd}
                        />
                    </div>
                </div>
            </Modal.Body>
            <div className='my-3 d-flex justify-content-center border-top pt-3'>
                <button type='button' 
                    style={{cursor: 'pointer'}}
                    className='btn btn-sm btn-outline-success' 
                    onClick={()=>{handleAddedData(post); handleClose()}}>Add Routing</button>
            </div>
        </Modal>
        {
            showAddWorkCenter && 
            <AddWorkCenter
                show = {showAddWorkCenter}
                close = {handleCloseAddWorkCenter}
                handleChangeWorkCenter = {(d)=>{handleChangeWorkCenter(d)}}
            />
        }
        </>
    )
}

export default AddRouting;
