import axiosInstance from "../api/axoiss"


export const FetchDataItem = (fetch_query) => {
    return new Promise((resolve, reject) => {
        axiosInstance.post("itemmaster/graphql", {query: fetch_query})
            .then(res => {
                let response = res?.data?.data
                resolve(response); // Resolve with the response data
            })
            .catch(err => {
                console.log(err)
                reject([]); // Reject with an empty array
            });
    });
}