import React, { useState }from 'react';
import { DateRange } from 'react-date-range';

const CustomDatePicker = ({handleGetSelectedDate, headerField}) => {
    const [reportDate, setReportDate] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ])

    const handleDatePicker = (item) => {
        setReportDate([item.selection])
        handleGetSelectedDate(item.selection, headerField)
    }
    
    return(
        <>  
            <div>
                <DateRange
                    editableDateInputs={true}
                    onChange={item => handleDatePicker(item)}
                    moveRangeOnFirstSelection={false}
                    ranges={reportDate}
                />
            </div>
        </>
    )
}


export default CustomDatePicker;