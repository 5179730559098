export const otherExpensesAccountFetchQuery = (data)=>{ 
    let query = `
    query myother{
        otherExpenses{
        items{
          id
            account{
            id
            accountsName
            }
            HSN{
            gstRates{
                rate
            }
            }
            }
        }
    }
`
   return query
}

export const purchasefetchdata =(data)=>{
    let query = `
    query purchase{
        purchaseOrder(${data}){
          items{
            id 
            purchaseOrderNo{
              id
              linkedModelId
            }
            purchasereturnchallanSet {
              id
              status {
                name
              }
              purchaseReturnChallanNo{
                id
                linkedModelId
              }
            }
              supplierId {
              id
              supplierNo
              companyName
            }
             childCount
            active
            status
            supplierId{
              id
              supplierNo
              companyName
            }
            termsConditions{
              id
              name
            }
            termsConditionsText
            updatedAt
            createdAt
            creditPeriod
            paymentTerms
            supplierRef
            dueDate
            createdBy{
              id
            username
            }
            receivingStoreId{
              id
              storeName
            }
            scrapRejectStoreId{
              id
              storeName
            }
            remarks
            department{
              id
              name
            }
            netAmount
            otherExpenses{
              id
              otherExpensesId{
                id
                account{
                  id
                  accountsName
                }
                HSN{
                  gstRates{
                    rate
                  }
                }
                
              }
              amount
              sgst
              cgst
              igst 
            }
            gstinType
            gstin
            contact{
              id
              contactPersonName
              email
          phoneNumber
            }
            address{
              id
              addressType
              addressLine1
              addressLine2
              city
              pincode
              state
              country
              default 
            }
            state
            country
            placeOfSupply
            goodsreceiptnoteSet {
              grnNo {
                linkedModelId
              }
            }
            itemDetails{
              id
              sNo
              verifiedAmount
               hsnId{
                id
                hsnCode
              }
              purchaseRetunQty
              itemMasterId{
                id
                itemName
                itemPartCode
                variation
                itemCost
                itemQc
              }
              category
              poQty
              uom{
                id
                name
              }
              acceptedQty
              received
              rate
              discount
              tax
              amount
              sgst
              cgst
              igst
              createdBy{
                id
                username
              }
          
            }
          }
             version{
        versionList
      }
           pageInfo{
             totalItems
               hasNextPage
               hasPreviousPage
               totalPages
           } 
        }
      }`
    return query
}

export const itemmasterPruchase = (data)=>{
  let query = `
  query itemmaster{
    itemMaster(${data}){
      items{
        itemCost
        variation
        purchaseUom{
          id
          name
        }
        itemUom{
          id
          name
        }
        alternateUom{
          id
          conversionFactor
          addtionalUnit {
            id
            name
          }
        }
      }
    }
  }`
  return query
}


export const GrnFetchQuery = (data)=>{
  let query = `
  query GoodsReceiptNote{
    allGoodsReceiptNote(${data}){
      items{
        id
        status{
         status 
        }
         grnStatus{
        status
        
        }
        ginNo{
          linkedModelId
        }
        ginDate
          grnNo{
          linkedModelId
        }
        grnDate 
        purchaseOrderId{
          id
          purchaseOrderNo{
            linkedModelId
          }
          supplierId{
            supplierNo
            companyName
          }
         dueDate
        department{
          id
          name
        }
        receivingStoreId{
          storeName
        }
        scrapRejectStoreId{
          storeName
        }
          remarks
        }
        goodsReceiptNoteItemDetailsId{
          id
          stockAdded
          batchNumber{
          id
          batchNumberName
          }
          serialNumber{
            id
            serialNumber
          }
          purchaseOrderId{
            id
            itemMasterId{
              id
              itemPartCode
              itemName
              itemQc
              batchNumber
              serial
              serialAutoGentrate
              serialFormat
              serialStarting
            }
            hsnId{
              id
              hsnCode
            }
            tax
            rate
            poQty
            uom{
             id
              name
            }
            received
          }
          balance
          received
          excess
          createdBy{
            id
            username
          }
           qualityinspectionsreportitemdetailsSet{
            accepted
            rework
            rejected
          }
        }
        qualityInspectionReportId{
          id
          status{
            status
          } 
        }
       createdBy{
        id
        username
      }
        updatedAt
        grnCreatedBy{
          id
          username
        }
        grnCreatedAt
      }
    }
}`
  return query
}

export const QirFetchQuery = (data)=>{
  let query = `
      query my {
  allQualityInspectionReport(${data}) {
    items {
      status {
        status
      }
      qirNo {
        linkedModelId
      }
      qirDate
      remarks
      reworkDeliveryChallan{
        id
      }
      goodsreceiptnoteSet {
        grnNo {
          linkedModelId
        }
          grnDate
        purchaseOrderId {
          id
        }
      }
      qualityInspectionsReportItemDetailId {
        id
        goodsReceiptNoteItem {
          id
          purchaseOrderId { 
            id
            itemMasterId {
              id
              itemPartCode
              itemName
              itemQc
            }
            hsnId{
              id
              hsnCode
            }
            tax
            rate
            received
            poQty
            uom {
              id
              name
            }
            
          }
          received
        }
        accepted
        rejected
        rework
         checkedBy{
          id
          username
        }
         createdBy{
          id
          username
        }
      }
      createdBy {
        id
        username
      }
        createdAt
    }
  }
}
`
  return query
}


export const reworkdeliveryChallanFetchQuery = (data)=>{
  let query = `
      query MyQuery {
    deliveryChallan(${data}){
      items {
        id
        status{ 
          name
        }
        dcDate
        dcNo {
          linkedModelId
        }
        termsConditions{
          id
          tc
          name
        }
        termsConditionsText
        supplier{
          id
          supplierNo
          companyName
        }
        address
        ownVehicle
        courier
        otherModel
        vehicleNo
        transportId{
          id
          companyName
        }
        purchaseOrderNo{
          id
          purchaseOrderNo{
            linkedModelId
          }
        }
        receivingStore{
          id
          storeName
        }
        scrapRejectStore{
          id
          storeName
        }
        gstin
        gstinType
        state
        placeOfSupply
        reworkDeliveryChallanItemDetails{
          id
          itemMasterId{
          id
            itemPartCode
            itemName
          }
          hsnId{
            id
            hsnCode
          }
          purchaseOrderItem{
            id
          }
          reworkQty
          uom{
            id
            name
          }
          rate
          tax
          amount
          createdBy{
            id
            username
          }
        }
        beforTax
        taxTotal
        roundOff
        netAmount
      }
    }
}
  `
  return query
}

export const purchaseReturnChallanFetchQuery = (data)=>{
  let query = `
        query MyQuery {
  purchaseReturnChallan(${data}) {
    items {
       purchaseReturnChallanNo {
        linkedModelId
      } 
      purchaseReturnChallanDate
      purchaseId{
        id
        purchaseOrderNo{
          linkedModelId
        }
        goodsreceiptnoteSet{
          grnNo{
            linkedModelId
          }
        }
        supplierId{
          supplierNo
          companyName
        }
        address{
          addressLine1
          addressLine2
          city
          pincode
          state
          country
          
          
        }
        gstinType
        gstin
        state
        placeOfSupply
      }
      remarks
      ewayBillNo
      purchaseReturnChallanItemDetails {
         amount
      createdAt
      id
      retunQty
      purchaseOrderItemDetails {
        id
        hsnId {
          id
          hsnCode
        }
        itemMasterId {
          itemName
          itemPartCode
        }
        purchaseRetunQty
        poQty
        uom { 
          name
          id
        }
        rate
        tax
      }
      }
      termsConditions {
        name
        id
      }
      termsConditionsText
      beforTax
      taxTotal
      cgst
      courier
      createdAt
      ewayBillNo
      id
      igst
      netAmount
      otherModel
      ownVehicle
      purchaseReturnChallanDate
      roundOff
      remarks
      sgst
      createdBy{
        username
        id
      }
      status {
        name
      }
      updatedAt
      vehicleNo
    }
  }
}
  `
  return query
}

