import { createSlice } from "@reduxjs/toolkit";


export const posSlice = createSlice({
    name : "posState",
    initialState : {value:{serialOrBatch:"test"}},
    reducers:{
        onchangePos:(state, action)=>{
            state.value = action.payload
        },
         
    }
    
})

export const {onchangePos} = posSlice.actions
export default posSlice.reducer;