import { AgGridReact } from "ag-grid-react";
import { useContext, useState } from "react";
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { LuGift } from "react-icons/lu";
import axiosInstance from "../../../api/axoiss";
import showErrorToast from "../../../components/notifications/react-toastify/toast";
import WaringModel from "../../../components/Warings/WaringModel";
import DataContext from "../../../context/ItemMasterContext";
import { stockIdFetchQuery } from "../../../GraphQLQueries/ItemmasterQuery";
import { getItemComboUnsavedDetails, quotationsItemDetailsDeleteMutation } from "../../../GraphQLQueries/QuatationqueryAndMutatiom/QuatationItemModalMutation";
import { onCellDoubleClicked, ShowErrorNoties } from "../../../utils/Utils";
import StockStatementDetails from "../../StockStatement/Details/StockStatementDetails";
import { useQuotationContext } from "../Context/quotationContext";
import ItemCombo, { parseAndGroupItemComboData } from "../Popup/itemCombo";
import QuatationItemModel from "../Popup/itemDetailsPopup";




const ItemDetails = ({ stateValue, editView, handleNewItem }) => {
    const { QuotationItemDetailsShow, setQuotationItemDetailsShow, itemDetailsData,setItemDetailsData,
        itemComboStatus, itemComboData, setItemComboData, currencyData } = useQuotationContext();
    const { handltoastDeleteConfomationShow } = useContext(DataContext)

    const [ItemEditShow, setItemEditShow] = useState(false)
    const [ItemEditData, setItemEditData] = useState([])
    const [afterOverallDiscount, setafterOverallDiscount] = useState(false)
    const [showItemCombo, setShowItemCombo] = useState(false);
    const [deleteData, setdeleteData] = useState({ Name: '', id: '' })
    const [lastUpdatedItemDetails, setLastUpdatedData] = useState([])
    const [IsSaveItemCombo, setIsSaveItemCombo] = useState(false)
    const [selectedStockStatementDetails, setSelectedStockStatementDetails] = useState([])
    const [isShowStockStatementModal, setShowStockStatementModal] = useState(false)
    const [selectedPartNo, setSelectedPartNo] = useState("")
    const [isDeleteWarningShow, setIsDeleteWarningShow] = useState(false)

    // Function to create action buttons for item details from the columns 
    const handleShowStockStatementModal = data => {
        axiosInstance
            .post('/itemmaster/graphql', {
                query: stockIdFetchQuery(data?.['itemmaster']?.['id'])
            })
            .then(res => {
                let stock_ids = []
                stock_ids = res?.data?.data?.stockIds?.items
                let construct_stock_dict = {
                    stockId: [],
                    itemPartCode: data?.partCode?.itemPartCode,
                    itemName: data?.partCode?.itemName,
                    description: data?.description,
                    batchNumber: '',
                    serial: ''
                }
                if (stock_ids.length > 0) {
                    construct_stock_dict['partCode'] =
                        stock_ids[0]?.partNumber?.['itemPartCode']
                    construct_stock_dict['partName'] =
                        stock_ids[0]?.partNumber?.['itemName']
                    construct_stock_dict['description'] =
                        stock_ids[0]?.partNumber?.['description']
                    construct_stock_dict['isBatch'] =
                        stock_ids[0]?.partNumber?.['batchNumber']
                    construct_stock_dict['isSerial'] =
                        stock_ids[0]?.partNumber?.['serial']
                }
                construct_stock_dict['stockId'] = stock_ids?.map(item => item.id)
                setSelectedStockStatementDetails(construct_stock_dict)
                setShowStockStatementModal(true)
            })
            .catch(error => {
                // Handle errors here
                console.error('Error fetching data:', error)
            })
        setSelectedPartNo(data?.['itemmaster']?.['id'])
    }
    const handleCloseStockStatementModal = () => {
        setShowStockStatementModal(false)
    }

    async function DeleteTheItem() {
        try {
          const response = await axiosInstance.post(`/itemmaster/graphql`, { query: quotationsItemDetailsDeleteMutation(deleteData?.id) })
          let reponseData = response?.['data']?.['data']?.quotationsItemDetailsDeleteMutation
          if (reponseData?.success) {
            setItemDetailsData(prevData => {
                const filteredData = prevData.filter(item => item.id !== deleteData?.id);
                return [...filteredData];  // Ensure that a new array reference is created
              });
            setdeleteData({ Name: '', id: '' })
          } else {
            showErrorToast(true, "error", reponseData?.errors)
          }
          
        } catch (error) {
          let errorFilter = ShowErrorNoties(error)
          showErrorToast(true, "error", errorFilter)
        }
    
      }
    const itemDetailsAction = ({ data }) => {
        // const handleItemdeleteConfomation = () => {
        //     if (data) {
        //         console.log("data", data);
        //         setdeleteData({
        //             Name: data?.itemmaster?.itemPartCode, id: data.id
        //         })
        //         handltoastDeleteConfomationShow()
        //     }
        // }
        const handleItemCombo = async () => {
            try {
                if (Array.isArray(data?.itemComboItemDetails) && data?.itemComboItemDetails.length === 0) {
                    const reqBody = {
                        itemmaster: data?.itemmaster?.id,
                        amount: String(data?.amount),
                        qty: String(data?.qty)
                    }
                    const response = await axiosInstance.post(`/itemmaster/graphql`, {
                        query: getItemComboUnsavedDetails(reqBody)
                    });
                    console.log("getItemComboUnsavedDetails", response);

                    const responseData = response?.data?.data?.getItemComboItemDetails?.itemComboData;
                    console.log("responseData", responseData);
                    const groupedData = parseAndGroupItemComboData(responseData);
                    setItemComboData(groupedData);
                    setLastUpdatedData(data)
                    setShowItemCombo(true)

                }
                else {
                    setItemComboData(data?.itemComboItemDetails?.map((item) => ({
                        edit: true,
                        isSelected: true,
                        afterDiscountValueForPerItem: item?.afterDiscountValueForPerItem,
                        amount: item?.amount,
                        id: item?.id,
                        display: item?.display,
                        isMandatory: item?.isMandatory,
                        qty: item?.qty,
                        rate: item?.rate,
                        itemmasterId: item?.itemmaster?.id,
                        partCode: item?.itemmaster?.itemPartCode,
                        partName: item?.itemmaster?.itemName,
                        uom: {
                            label: item?.uom?.name,
                            value: item?.uom?.id
                        }
                    })))
                    setIsSaveItemCombo(true)
                    setShowItemCombo(true)
                }

            }

            catch (error) {
                console.log(error);
                const errorData = ShowErrorNoties(error);
                showErrorToast(true, 'error', errorData);
            }


        };
        const showStock = () => {
            handleShowStockStatementModal(data)
        }
        return (
            <>
                <div>
                    {data?.itemCombo === false &&
                        <button
                            type='button'
                            className='btn btn-outline-success btn-sm px-3 mx-2'
                            onClick={() => {
                                setQuotationItemDetailsShow(true);
                                setItemEditData(data);
                                setItemEditShow(true);
                            }}
                            disabled={editView}
                        >
                            <i className='fa-solid fa-pen'></i>
                        </button>
                    }
                    {data?.itemCombo === true &&
                        <span className='ms-4 px-3 mx-2' onClick={handleItemCombo}>
                            <LuGift
                                style={{
                                    cursor: 'pointer',
                                    color: Array.isArray(data?.itemComboItemDetails) && data?.itemComboItemDetails.length === 0 ? 'brown' : 'green'
                                }}
                            />
                        </span>
                    }
                    <button
                        type='button'
                        className='btn btn-outline-danger btn-sm px-3 mx-2'
                        onClick={() => { setIsDeleteWarningShow(true); setdeleteData({
                            Name: data?.itemmaster?.itemPartCode, id: data.id
                        }) }}
                        disabled={editView}
                    >
                        <i className='fa-solid fa-trash '></i>
                    </button>
                    {data?.itemCombo === false &&
                        <AiOutlineShoppingCart
                            style={{ cursor: 'pointer' }}
                            className='fs-3'
                            onClick={() => {
                                showStock()
                            }}
                        />
                    }
                </div>
            </>
        )
    }

    const handleDeletedId = deleted_id => {
        if (deleted_id) {
            console.log('Deleted Id---------', deleted_id)
            setItemEditData(prev => {
                return prev.filter(item => item.id !== deleted_id);
            });
            setdeleteData({ Name: '', id: '' })
        }
    }

    // Function to render the rate cell in the table with discount calculations For UI purpose
    const ratecellrender = params => {
        let discount_percentage = params.data?.discountPercentage !== undefined ? params.data.discountPercentage : 0;
        let discount_value = params.data?.discountValue !== undefined ? params.data.discountValue : 0;
        let afterDiscountValue = params.data.afterDiscountValueForPerItem !== undefined ? params.data.afterDiscountValueForPerItem : 0;


        return (
            <>
                {params.data.rate}
                {Number(discount_percentage) !== 0
                    ? -params.data.discountPercentage +
                    ' % = ' +
                    Number(afterDiscountValue).toFixed(2)
                    : Number(discount_value) !== 0
                        ? '-' +
                        Number(discount_value).toFixed(2) +
                        ' = ' +
                        Number(afterDiscountValue).toFixed(2)
                        : Number(afterDiscountValue) !== 0
                            ? ' - ' +
                            Number(afterDiscountValue).toFixed(2) +
                            ' = ' +
                            (params.data.finalValue !== undefined ? Number(params.data.finalValue).toFixed(2) : '0.00')
                            : ''}
            </>
        );
    };

    // Define the columns for the item details table
    const columns = [
        {
            headerName: 'S.No',
            flex: 0.7,
            valueGetter: params => params.node.rowIndex + 1,// Use params.node.rowIndex
            onCellDoubleClicked: onCellDoubleClicked,
        },
        // {
        //     headerName: 'Part Code',
        //     field: 'itemmaster.itemPartCode',
        //     flex: 2
        //     // cellRenderer: partcodecellrender,
        // },
        // {
        //     headerName: 'Part Name',
        //     field: 'itemmaster.itemName',
        //     flex: 2
        // },
        {
            headerName: 'Description',
            field: 'description',
            flex: 2,
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName: 'HSN',
            field: 'hsn.hsnCode',
            flex: 2,
            onCellDoubleClicked: onCellDoubleClicked,

        },
        {
            headerName: ' Qty',
            field: 'qty',
            flex: .8,
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName: 'UOM',
            field: 'uom.name',
            flex: .8,
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName: 'Rate',
            cellRenderer: ratecellrender,
            onCellDoubleClicked: onCellDoubleClicked,
            field: 'rate',
            flex: 2,
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName: 'Discount',
            valueGetter: params => {
                const discountPercentage = params.data.discountPercentage;
                const discountValue = params.data.discountValue;
                if (discountPercentage && discountValue === null) {
                    return `${discountPercentage} %`;
                } else if (discountValue !== null) {
                    return `${discountValue}`;
                }
                return ' ';
            },
            flex: 1,
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName: 'Tax',
            field: 'itemmaster.itemHsn.gstRates.rate',
            flex: .7,
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName: 'Amount',
            field: 'amount',
            flex: 1,
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName: 'Action',
            flex: 2,
            cellRenderer: itemDetailsAction
        }
    ]
    const defaultColDef = {
        // Default column definition for the table
        filter: true,
        floatingFilter: true,
        resizable: true
    }
    return (
        <>
            <div className='row justify-content-between align-items-center'>
                <div className='col-2'>
                    <h6 className='commanModelTitleColor' onClick={() => {
                        console.log("itemDetailsData", currencyData);
                    }}>Item Details</h6>
                </div>
                <div className='col-4 text-end'>
                    <span className='me-3'>
                        <LuGift className='me-2' style={{ cursor: 'pointer', color: 'brown' }} />
                        Item Combo Not Saved
                    </span>
                    <span className='me-3'>
                        <LuGift className='me-2' style={{ cursor: 'pointer', color: 'green' }} />
                        Item Combo Saved
                    </span>
                </div>
                <div className='col-3 text-end'>
                    <button
                        type='button'
                        className='btn btn-outline-primary mt-4 px-4'
                        onClick={() => {
                            setQuotationItemDetailsShow(true);
                            setItemEditShow(false);
                        }}
                        disabled={!stateValue || editView || !currencyData}
                    >
                        <i className='bx bxs-plus-circle me-2'></i>New
                    </button>
                </div>
            </div>
            <div
                className='ag-theme-alpine mt-2'
                style={{ height: '40rem', width: '100%' }}
            >
                <AgGridReact
                    rowData={itemDetailsData}
                    columnDefs={columns}
                    defaultColDef={defaultColDef}

                />
                <ItemCombo
                    IsshowItemCombo={showItemCombo}
                    setshowItemCombo={setShowItemCombo}
                    lastUpdatedItemDetails={lastUpdatedItemDetails}
                    IsSaveItemCombo={IsSaveItemCombo}
                    setIsSaveItemCombo={setIsSaveItemCombo}

                />
            </div>
            <br />
            {QuotationItemDetailsShow && (
                <QuatationItemModel
                    QuotationItemDetailsShow={QuotationItemDetailsShow}
                    stateValue={stateValue}
                    onNewItem={handleNewItem}
                    EditData={ItemEditData}
                    EditShow={ItemEditShow}
                    editView={editView}
                    afterOverallDiscount={afterOverallDiscount}
                />
            )}

            {/* {deleteData?.Name &&
                <DeleteConformation
                    handleDeletedId={(id) => handleDeletedId(id)}
                    deleteData={deleteData}
                    fullquery={quotationsItemDetailsDeleteMutation(deleteData?.id)}
                />
            } */}
            {isDeleteWarningShow &&
                <WaringModel
                    state={isDeleteWarningShow}
                    setstate={setIsDeleteWarningShow}
                    title={'Confirm'}
                    text={"Confirm to Delete"}
                    Confirmtion={value => {
                        if (value) {
                            DeleteTheItem(value)
                        }
                    }}
                />}

            {isShowStockStatementModal &&
                <StockStatementDetails
                    isShowSelectedStockStatement={isShowStockStatementModal}
                    handleCloseSelectedStockStatement={handleCloseStockStatementModal}
                    stockStatement={selectedStockStatementDetails}
                    part_id={selectedPartNo}
                    from={'pos'}
                />}

        </>
    )
}

export default ItemDetails