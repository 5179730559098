


export const otherIncomeChargesList = (data) => {
  return `
        query MyQuery {
    otherIncomeCharges(${data}) {
      items {
        name
        account {
          id
          accountsName
          accountsActive
        }
        id
        hsn {
          id
          hsnCode
          gstRates{
            rate
          }
        }
      }
      
    }
  }`
}


export const quotationEditQuery = (data) => {
  return `
    query MyQuery {
  allQuotations(id:${data}) {
    items {
      id
      leadNo{
        id
        leadNo
      }
      currency{
        id
        rate
        Currency{
          id
          name
          currencySymbol
        }
        
      }
      createdBy{
        id
        username
      }
      status {
        id
        name
      }
        childCount
      customerId {
          id
          gstin
          address{
             id
             addressType
             addressLine1
             addressLine2
             city
             state
             country
             pincode
             default
          }
        contact{
              id
              contactPersonName
              phoneNumber
              email
              default
            }
        companyName
        supplierNo
      }
      termsConditions{
          id
          name
      }
          salesPerson{
        id
        username
      }
    customerContactPerson{
    id
    contactPersonName
    phoneNumber
    email
  }
      termsConditionsText
      quotationNo {
        id
        linkedModelId
      }
        CreatedAt
      department {
        id
        name
      }
      remarks
      customerAddress{
        id
        addressType
        addressLine1
        addressLine2
        city
        pincode
        state
      }
      itemDetails{
            description
      hsn{
        hsnCode
      }
         afterDiscountValueForPerItem
      amount
      cgst
      discountPercentage
      discountValue
      finalValue
      id 
      igst
      itemCombo
      qty
      rate
      sgst
      tax
      uom { 
        id
        name
      }
      createdBy{
            id
      }
      itemmaster {
        id
        itemPartCode 
        itemName 
        category { 
          id
          name
        }
          
          itemHsn{
          gstRates{
            rate
          }
            } 
      } 
        itemComboItemDetails{
        afterDiscountValueForPerItem
        amount
        id
        qty
        rate
        itemmaster {
          id
          itemPartCode
          itemName
          category {
            id
            name
          }
        }
        uom {
          id
          name
        }
          
        }
      }
      otherIncomeCharge{
          id 
          amount 
          igst 
          sgst 
          cgst 
          afterDiscountValue
          discountValue
          tax
          otherIncomeChargesId{ 
            id
            name
            hsn{
              gstRates{
                rate
              } 
              hsnCode  
            }
              createdBy{
          id
        }
          }
          }
      itemTotalBeforTax
      taxTotal
      netAmount
      overallDiscountValue
      overallDiscountPercentage
      discountFinalTotal
      active
    }
      version{
    versionList
  }
  }
}`
}

export const discountValueItemQuery = (data) => {
  const idList = JSON.stringify(data.ids);
  const otherIncomeId = JSON.stringify(data.otherIncomeId);
  const totalToSubtract = JSON.stringify(data.value)
  const totalValue = JSON.stringify(data.totalValue)
  return `
query MyQuery { 
  quotationsOverallDiscountValue( 
    idList:  ${idList}
    otherIncomeChargeIdList:${otherIncomeId}
    totalToSubtract:${totalToSubtract}
    totalValue:${totalValue}
  ) { 
     items {
      description
      hsn{
        hsnCode
      }
      afterDiscountValueForPerItem
      amount
      cgst
      discountPercentage
      discountValue
      finalValue
      id
      igst
      itemCombo
      qty
      rate
      tax
      
      sgst
      itemComboItemDetails {
        afterDiscountValueForPerItem
        amount
        id
        qty
        rate
        itemmaster {
          id
          itemPartCode
          itemName
          category {
            id
            name
          }
        }
        uom {
          id
          name
        }
      }
      itemmaster {
        id
        itemPartCode
        itemName
        category {
          id
          name
        }
         itemHsn{
          gstRates{
            rate
          }
        }
      }
      uom {
        id
        name
      }
      createdBy{
        id
      }
    }
    quotationsOtherIncomeCharges {
      id
      amount
      igst
      cgst
      sgst
      createdBy{
        id
      }
      discountValue
      afterDiscountValue
      otherIncomeChargesId{
        id
        name
        
        hsn{
          gstRates{
            rate
          }
            
        }
      }
      
    }
  }
}
  
  
`
}


export const discountFinalQuery = (data) => {
  const idList = JSON.stringify(data.ids);
  const otherIncomeId = JSON.stringify(data.otherIncomeId);
  const TotalWithTaxValue = JSON.stringify(data.TotalWithTaxValue)
  const finalTotal = JSON.stringify(data.value)
  return `
  query MyQuery { 
  quotationsFinalTotalDiscount( 
    finalTotal: ${finalTotal}
    idList: ${idList}
    otherIncomeChargeIdList:${otherIncomeId}
    TotalWithTaxValue: ${TotalWithTaxValue}
  ) { 
       items {
        description
      hsn{
        hsnCode
      }
      afterDiscountValueForPerItem
      amount
      cgst
      discountPercentage
      discountValue
      finalValue
      id
      igst
      itemCombo
      qty
      rate
      tax
      
      sgst
      itemComboItemDetails {
        afterDiscountValueForPerItem
        amount
        id
        qty
        rate
        itemmaster {
          id
          itemPartCode
          itemName
          category {
            id
            name
          }
        }
        uom {
          id
          name
        }
      }
      itemmaster {
        id
        itemPartCode
        itemName
        category {
          id
          name
        }
         itemHsn{
          gstRates{
            rate
          }
        }
      }
      uom {
        id
        name
      }
      createdBy{
        id
      }
    }
    quotationsOtherIncomeCharges {
      id
      amount
      igst
      cgst
      sgst
       discountValue
      afterDiscountValue
      createdBy{
        id
      }
      otherIncomeChargesId{
        id
        name
        hsn{
          gstRates{
            rate
          }
        }
      }
      
    }
  }
}`
}

export const discountClearAllQuery = (data) => {
  const idList = JSON.stringify(data.ids);
  const otherIncomeId = JSON.stringify(data.otherIncomeId);
  return `query MyQuery { 
      quotationsItemClearDiscount(idList: ${idList} 
    , otherIncomeChargeIdList: ${otherIncomeId}) { 

    items {
     description
      hsn{
        hsnCode
      }
      afterDiscountValueForPerItem
      amount
      cgst
      discountPercentage
      discountValue
      finalValue
      id
      igst
      tax
      
      itemCombo
       createdBy{
        id
      }
      qty
      rate
      sgst
      itemComboItemDetails {
        afterDiscountValueForPerItem
        amount
        id
        qty
        rate
        itemmaster {
          id
          itemPartCode
          itemName
          category {
            id
            name
          }
        }
        uom {
          id
          name
        }
      }
      itemmaster {
        id
        itemPartCode
        itemName
        category {
          id
          name
        }
         itemHsn{
          gstRates{
            rate
          }
        }
      }
      uom {
        id
        name
      }
      createdBy{
        id
      }
    }
    quotationsOtherIncomeCharges {
      id
      amount
      igst
      cgst
      sgst
      createdBy{
        id
      }
      discountValue
      afterDiscountValue
      otherIncomeChargesId{
        id
        name
        
        hsn{
          gstRates{
            rate
          }
        }
      }
      
    }
  }
}`
}

export const discountOverallPercentage = (data) => {

  const idList = JSON.stringify(data.ids);
  const otherIncomeId = JSON.stringify(data.otherIncomeId);
  return `
  
query MyQuery { 

  quotationsOverallDiscountPercentage( 

    itemIdList: ${idList}

    otherIncomeChargeIdList:${otherIncomeId}

    percentage: "${data.value}"

  ) { 

    items {
     description
      hsn{
        hsnCode
      }
      afterDiscountValueForPerItem
      amount
      cgst
      discountPercentage
      discountValue
      finalValue
      id
      igst
      itemCombo
      qty
      tax
      
      rate
      sgst
        itemComboItemDetails{
        id
      }
      itemComboItemDetails {
        afterDiscountValueForPerItem
        amount
        id
        qty
        rate
        itemmaster {
          id
          itemPartCode
          itemName
          category {
            id
            name
          }
        }
        uom {
          id
          name
        }
      }
      itemmaster {
        id
        itemPartCode
        itemName
        category {
          id
          name
        }
         itemHsn{
          gstRates{
            rate
          }
        }
      }
      uom {
        id
        name
      }
      createdBy{
        id
      }
    }
    quotationsOtherIncomeCharges {
      id
      amount
      igst
      cgst
      sgst
      createdBy{
        id
      }
      discountValue
      afterDiscountValue
      otherIncomeChargesId{
        id
        name
        
        hsn{
          gstRates{
            rate
          }
        }
      }
      
    }
  }
}
  
  
  
  `
}



export const quotationsAmand = (data) => {
  return `
mutation MyMutation { 

  quotationsAmand(id:${data?.id},userId:${data?.userId}) { 

    errors 

    success 

    itemDetails{
     description
      hsn{
        hsnCode
      }
      afterDiscountValueForPerItem 
      amount 
      cgst 
      discountPercentage 
      discountValue 
      finalValue 
      id  
      igst
      tax 
      
      itemCombo 
      itemComboItemDetails{
        afterDiscountValueForPerItem
        amount
        id
        qty
        rate
        itemmaster {
          id
          itemPartCode
          itemName
          category {
            id
            name
            }
        	}
        uom {
          id
          name
        }
        
      	}

      	qty 

      	rate 
	
      	sgst 
	
      	uom {  

        id 

        name 

      } 

      createdBy{
              id
           } 

      itemmaster { 

        id 

        itemPartCode  

        itemName  

        category {  

          id 

          name 

        } 

        itemHsn{ 

          gstRates{ 

            rate 

          } 

            } 

        itemMrp 

        itemMinPrice 

      }  

      } 

    otherIncomeCharge{ 

          id  

          amount  

          igst  

          sgst  

          cgst  

          otherIncomeChargesId{  

            id 

            name 

            hsn{ 

              gstRates{ 

                rate 

              }  

            } 

              createdBy{ 

          id 

        } 

          } 

          } 

  } 

} `
}


export const quotationAmandCancel = (data) => {
  return `
  mutation MyMutation { 

  quotationsAmandCancel(itemDetails:[${data?.itemDetails}], otherIncomeCharge:[${data?.otherIncomeCharge}]) { 

    errors 

    success 

  } 

} 
  `
}

export const quotationsBulkUpdateForTax = (data) => {

  return `
  mutation quotationsBulkUpdateForTax{ 

  quotationsBulkUpdateForTax(${data}) { 

    errors 

    success 

    QuotationsItemDetails{
     description
      hsn{
        hsnCode
      }
      afterDiscountValueForPerItem 
      amount 
      cgst 
      discountPercentage 
      discountValue 
      finalValue 
      id  
      igst
      tax 
      itemCombo 
      itemComboItemDetails{
        afterDiscountValueForPerItem
        amount
        id
        qty
        rate
        itemmaster {
          id
          itemPartCode
          itemName
          category {
            id
            name
            }
        	}
        uom {
          id
          name
        }
        
      	}

      	qty 

      	rate 
	
      	sgst 
	
      	uom {  

        id 

        name 

      } 

      createdBy{
              id
           } 

      itemmaster { 

        id 

        itemPartCode  

        itemName  

        category {  

          id 

          name 

        } 

        itemHsn{ 

          gstRates{ 

            rate 

          } 

            } 

        itemMrp 

        itemMinPrice 

      }  

      } 

    QuotationsOtherincomeChanges{ 

				id 
          amount 
          igst 
          sgst 
          cgst 
          afterDiscountValue
          discountValue
          tax
          otherIncomeChargesId{ 
            id
            name
            hsn{
              gstRates{
                rate
              } 
            }
              createdBy{
          id
        }

          } 

  } 

  } 

} 
  
  
  `
}


export const fetchBuyerDetailsQuery = (data) => {
  // id: 6
  return `
  query MyQuery {
      allSalesorder2(${data}){
      items{
          buyer{
              id
              companyName
              supplierNo
              address{
                id
                addressType
                addressLine1
                addressLine2
                city
                state
                country
                pincode
              }
              contact{
                id
                contactPersonName
                phoneNumber
                email
              }
          }
          buyerAddress{
              id
              addressType
              addressLine1
              addressLine2
              city
              state
              country
              pincode
          }
          buyerContactPerson{
              id
              contactPersonName
              phoneNumber
              email
          }
      }
  }
  `
}