import { Formik, Form} from "formik";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { child_bom_schema } from "../../../validations/bom";
import { customSelectStyle } from "../../../utils/Utils";
import Select from 'react-select';
// import { useSelector, useDispatch } from 'react-redux';
import { FetchBOMOptionOnType, RawMaterialBomLinkCreate, RawMaterialBomLinkDelete, RawMaterialBomLinkFetch } from "../../../GraphQLQueries/BomMutation";
// import { fetchBOMData } from "../../../ReduxState/Action/bomAction";
import FinishedGoods from "../FinishedGoods/finishedGoods";
import RawMaterials from "./rawMaterials";
import axiosInstance from "../../../api/axoiss";
import { ToastContainer, toast, Bounce } from "react-toastify";


const AddChildBOM = ({show, close, rawMaterialId, data, handleDataAdded, rmData, isEditViewMode}) => {
    // const dispatch = useDispatch(); 
    // const bomDataList = useSelector(state => state.bomData.data);
    const [childBom, setChildBom] = useState(null);
    const [finishedGoodsItem, setFinishedGoodsItem] = useState([]);
    const [rawMaterialItem, setRawMaterialItem] = useState([]);
    const [bomType, setBomType] = useState("")
    const [bomDataList, setBomDataList] = useState([]);

    let initialValue = {
        childBom: ""
    }

    // const handleOnInputChangeTypeSearch = (input_text) => {
    //     if(input_text.trim() !== ''){
    //         dispatch(fetchBOMData(FetchBOMOptionOnType(input_text)));
    //     }
    //     // else{
    //     //     dispatch(SupplierDataFetch(ItemMasterProductsFetchQuery()))
    //     // }
    // }

    const handleOnChangeChildBom = (option) => {
        setChildBom(option);
        setBomType(option?.data?.bomType);
        setFinishedGoodsItem(option?.data?.finishedGoods?.id ? [option?.data?.finishedGoods?.id] : [])
        setRawMaterialItem(option?.data?.rawMaterial ? option?.data?.rawMaterial?.map(item => item.id) : [])
    }

    const handleSubmit = (values, { setSubmitting, resetForm, setErrors, setFieldError }) => {
        // rawMaterialId
        let selected_child_bom = childBom?.value
        if(selected_child_bom && rawMaterialId){
            let mutation_query = RawMaterialBomLinkCreate(rawMaterialId, selected_child_bom);
            axiosInstance.post("/itemmaster/graphql", {query: mutation_query})
            .then(response => {
                const keys = Object.keys(response.data.data);
                const firstKey = keys.length > 0 ? keys[0] : null;
                const data = response.data.data[firstKey]?.success
                if(data){
                    handleDataAdded(response?.data?.data?.[firstKey]?.rawMaterialBomLink)
                    setSubmitting(false);
                    close();
                }else{
                    alert('Error While updating Child BOM')
                }
            })
            .catch(error => {
                alert(error)
            });
            
        }else{
            alert('Error!')
        }
    }

    useEffect(()=>{
        if(data){
            let response = data;
            setBomType(response?.bom?.bomType);
            setFinishedGoodsItem(response?.bom?.finishedGoods?.id ? [response?.bom?.finishedGoods?.id] : []);
            setRawMaterialItem(response?.bom?.rawMaterial ? response?.bom?.rawMaterial?.map(item => item.id) : []);
            setChildBom({label: response?.bom?.bomName, value: response?.bom?.id});
        }else{
            let rm_id = rmData?.partNo?.id
            setChildBom(null);
            setFinishedGoodsItem([]);
            setRawMaterialItem([]);
            setBomType("")
            axiosInstance.post("/itemmaster/graphql", {query: FetchBOMOptionOnType(rm_id)})
            .then(response => {
                const keys = Object.keys(response.data.data);
                const firstKey = keys.length > 0 ? keys[0] : null;
                const data = response.data.data[firstKey];
                setBomDataList(data?.items || [])
            })
            .catch(error => {
            });
        }
    }, [])

    return (
        <Modal show={show} onHide={close} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Add Child BOM</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Formik 
                initialValues = {initialValue}
                validationSchema={child_bom_schema}
                onSubmit={(values, formikProps) => handleSubmit(values, formikProps)}
                >
                {({errors, submitForm, touched, setFieldValue })=>(
                    <>
                        <Form style={{width: "100%", fontSize: '.8rem' }}>
                            <div className="row mx-3">
                                <div className="staan-input-group col-6">
                                    <Select
                                        // onInputChange={(e)=>{handleOnInputChangeTypeSearch(e)}}
                                        options={bomDataList?.map((item) => ({ value: item.id, label: item.bomName , data: item}))}
                                        isSearchable={true}
                                        value={childBom}
                                        onChange={(option) => {
                                            setFieldValue('childBom', option ? option.value : null);
                                            handleOnChangeChildBom(option);
                                        }} 
                                        className='mt-3'
                                        styles={customSelectStyle}
                                        isDisabled = {!isEditViewMode}
                                        name='childBom' style={{fontSize: '.7rem'}} 
                                    />
                                    <label htmlFor="childBom" className='staan-form-input-label pt-1 px-1 mx-2'>BOM</label>
                                    <p>{ touched.childBom &&  errors.childBom && <small>{errors.childBom}</small>}</p>
                                </div>
                                <div className="col-6 d-flex align-items-center">
                                    {isEditViewMode && <div>
                                        <button type="submit" className="btn btn-sm btn-outline-success">Save</button>
                                    </div>}
                                </div>
                            </div>
                            <div>
                                {finishedGoodsItem.length > 0 && <div className='my-5 mx-2 border border-light rounded shadow p-3' style={{height: '16rem', overflowY: "scroll"}}>
                                    <FinishedGoods 
                                        isEditView = {false}
                                        bomType = {bomType}
                                        data = {finishedGoodsItem}
                                    />
                                </div>}
                                {rawMaterialItem.length > 0 && <div className='my-5 mx-2 border border-light rounded shadow p-3' style={{height: '16rem', overflowY: "scroll"}}>
                                    <RawMaterials 
                                        isEditView = {false}
                                        data = {rawMaterialItem}
                                    />
                                </div>}
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
            </Modal.Body>
        </Modal>
    )
}

const ChildBom = ({show, close, rawMaterialId, data, isEditViewMode}) => {
    const [showAddChildBom, setShowChildBom] = useState(false);
    const [isChildLinked, setChildLinked] = useState(false);
    const [linkedChildData, setLinkedChildData] = useState(null);
    
    const handleShowAddChildBOMComponent = () => {
        setShowChildBom(true)
    }

    const handleCloseAddChildBOMComponent = () => {
        setShowChildBom(false)
        close()
    }

    const handleDeleteRawMaterialBomLink = () => {
        let delete_query = RawMaterialBomLinkDelete(linkedChildData?.id);
        if(delete_query){
            axiosInstance.post("/itemmaster/graphql", {query: delete_query})
            .then(res => {
                let response = res?.data?.data?.rawMaterialBomLinkDeleteMutation?.success
                if(response){
                    setChildLinked(false);
                    setLinkedChildData(null);
                }                
            })
            .catch(error => {
                // error
            });
        }
    }

    const handleDataAdded = (data) => {
        setChildLinked(true)
        setLinkedChildData(data)
    }

    useEffect(()=>{
        let fetch_query = RawMaterialBomLinkFetch(rawMaterialId);
        axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
        .then(res => {
            let response = res?.data?.data?.rawMaterialBomLink?.items?.[0];
            if(response?.id){
                setChildLinked(true)
                setLinkedChildData(response)
                if(!isEditViewMode){
                    setShowChildBom(true)
                }   
            }else{
                setChildLinked(false)
                if(!isEditViewMode){
                    toast.error('No Child Bom Linked', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                    close()
                }  
            }
            
        })
        .catch(error => {
            setChildLinked(false)
            if(!isEditViewMode){
                toast.error('No Child Bom Linked', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                close()
            }
        });
    }, [])

    return (
        <Modal show={show} onHide={close} size="md">
            <ToastContainer />
            <Modal.Header closeButton>
                <Modal.Title>Child BOM</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isEditViewMode && <div className="mx-5">
                    <div className="mb-3">
                        <div 
                            className={isChildLinked ? "text-success": ""}
                            onClick={()=>{handleShowAddChildBOMComponent()}}
                            style={{cursor: "pointer"}}>
                            <i className="fa-solid fa-thumbtack"></i>
                            <span className="ms-2">Link</span>
                        </div>
                    </div>
                    <div>
                        <div 
                            onClick={()=>{handleDeleteRawMaterialBomLink()}} 
                            style={{cursor: "pointer"}}>
                            <i className="fa-regular fa-trash-can"></i>
                            <span className="ms-2">Remove</span>
                        </div>
                    </div>
                </div> }
            </Modal.Body>
            {showAddChildBom && 
                <AddChildBOM 
                    show = {showAddChildBom}
                    close = {handleCloseAddChildBOMComponent}
                    rawMaterialId = {rawMaterialId}
                    data = {linkedChildData}
                    handleDataAdded = {handleDataAdded}
                    rmData = {data}
                    isEditViewMode = {isEditViewMode}
                />
            }
        </Modal>
    )
}

export default ChildBom;