import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaStar } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import * as QueryData from '../../../GraphQLQueries/ItemmasterQuery'
import { leadsCreateMutation } from '../../../GraphQLQueries/LeadQueryAndMutations/LeadMutation'
import axiosInstance, { commanFetch } from '../../../api/axoiss'
import showErrorToast from '../../../components/notifications/react-toastify/toast'
import DataContext from '../../../context/ItemMasterContext'
import {
  customSelectStyle,
  getCurrentDate,
  removeEmptyValueInObject,
  ShowErrorNoties
} from '../../../utils/Utils'
import { LeadSchema } from '../../../validations/LeadValidation'
import SupplierFrom from '../../ItemMaster/model/SupplierFrom'

const LeadForm = ({ EnquireId, showLeadForm, setShowLeadForm, conference, EnquireInitialValue, salesMan, followupDate,setIsLeadSubmited,handleSubmitEnquireForm }) => {
  const { userId, handleSupplierShow, supplierSelect, prefetchValue } = useContext(DataContext)
  const [initialValue, setInitialValue] = useState({
    LeadName: EnquireInitialValue?.name,
    LeadNumber: '',
    customerValue: '',
    Requirements: EnquireInitialValue?.message,
    LeadCurrency: '',
    LeadValue: '',
    ExpectedClosureDate: '',
    followupDate: followupDate,
    LeadStatus: '',
    Priority: '',
    SalesPerson: salesMan?.value,
    Source: ''
  })
  const [formKey, setFormKey] = useState(0)
  const [customerList, setcustomerList] = useState([])
  const [salePersonlist, setsalePersonlist] = useState([])
  const [leadCurrencylist, setleadCurrencylist] = useState([])
  const [rating, setRating] = useState(0)
  const [supplierValue, setSupplierValue] = useState({})
  const [salesPersonValue, setsalesPersonValue] = useState({})
  const [nextFollowupDate, setnextFollowupDate] = useState(followupDate ? followupDate : '')
  const navigate = useNavigate()
  useEffect(() => {
    if (showLeadForm) {
      setsalesPersonValue(salesMan)
    }
  }, [showLeadForm])

  useEffect(()=>{
    // prefetchValue?.currencyExchangeList
  },[prefetchValue])

  //  return null ;
  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setErrors }
  ) => {
    let saveValues = {
      id: '',
      createdBy: Number(userId),
      customer: Number(values?.customerValue),
      Enquiry: EnquireId?.['id'] ? Number(EnquireId?.['id']) : '',
      expectedClosingDate: values.ExpectedClosureDate,
      leadCurrency: Number(values.LeadCurrency),
      leadName: values.LeadName,
      leadValue: String(values.LeadValue),
      priority: Number(values.Priority),
      requirement: values.Requirements,
      salesPerson: Number(values.SalesPerson),
      status: 'Qualified'
    }

    let ClearValue = removeEmptyValueInObject(saveValues, ['id', 'modifiedBy'])
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query: leadsCreateMutation(ClearValue)
      })
      const responseData = response?.data?.data?.leadsCreateMutation
      if (responseData.success) {
        handleSubmitEnquireForm('CONVERTED_TO_LEAD')
        navigate(`/LeadEdit`, {
          state: { leadData: responseData?.LeadsInstance },
          replace: true
        })
        resetForm()
        showErrorToast(true, 'success', '', 'Saved Successfully')
        setSubmitting(false)
        Close()
      } else {
        let errorData = ShowErrorNoties(responseData.errors)
        showErrorToast(true, 'error', errorData)
      }
    } catch (error) {
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorData)
    }
  }

  const colors = {
    orange: '#F2C265',
    grey: 'a9a9a9'
  }
  const stars = Array(5).fill(0)

  const Close = () => {
    setShowLeadForm(false)
  }
  async function getOption(e, queryName) {
    let search_term = e.trim().replace(/"/g, '\\"')
    if (queryName === 'SupplierOption_withparameter' && search_term) {
      let parameter = `companyName: "${search_term}", customer:true ,`
      const response = await commanFetch(QueryData[queryName](parameter))
      if (response.success) {
        const reponseData = response?.data?.items
        setcustomerList(
          reponseData?.map(customer => ({
            value: customer.id,
            label: customer.companyName
          }))
        )
      }
    } else if (queryName === 'userdataOptionsQuery' && search_term) {
      const response = await commanFetch(QueryData[queryName](search_term))
      if (response.success) {
        const reponseData = response?.data?.items
        setsalePersonlist(
          reponseData?.map(saleperson => ({
            value: saleperson.id,
            label: saleperson.username
          }))
        )
      }
    } else {
      setcustomerList([])
      setsalePersonlist([])
    }
  }
  useEffect(() => {
    if (supplierSelect) {
      setSupplierValue({ value: supplierSelect.id, label: supplierSelect.lable });
      setInitialValue(Prve=>({...Prve,customerValue:supplierSelect.id }))
      setFormKey(!formKey)
    }
  }, [supplierSelect])

  // , setFormKey
  return (
    <>
      <Modal show={showLeadForm} onHide={Close} size='xl'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-4 m-3 mb-0' style={{ color: '#5CADA9' }}>
            <Modal.Title>
              <i
                className='fa-solid fa-arrow-left fa-sm me-2'
                onClick={Close}
                style={{ cursor: 'pointer' }}
              ></i>
              Leads
            </Modal.Title>
          </div>
        </div>
        <Modal.Body>
          <div className='row'>
            <div className='col-12'>
              <hr
                style={{ width: '100%', margin: '0 auto', borderColor: '#ccc' }}
              />
            </div>
          </div>
          <div>
            <Formik
              initialValues={initialValue}
              validationSchema={LeadSchema}
              enableReinitialize
              key={formKey}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                touched,
                setFieldValue,
                isSubmitting,
                values
              }) => (
                <Form>
                  <div className='row ms-3 mt-2 mb-3'>
                    <div className='row'>
                      <div className='col-6 staan-input-group'>
                        <Field
                          type='text'
                          name='LeadName'
                          id='leadName'
                          className='w-100 staan-form-input'
                        />
                        <label
                          htmlFor='leadName'
                          className='staan-form-input-label pt-1 px-1 mx-2'
                        // onClick={() => {
                        //   console.log(supplierValue);
                        // }}
                        >
                          Lead Name<span className='text-danger'>*</span>
                        </label>
                        {touched.LeadName && errors.LeadName && (
                          <small className='staan-error-msg'>
                            {errors.LeadName}
                          </small>
                        )}
                      </div>
                      <div className='col-6 staan-input-group'>
                        <Field
                          type='text'
                          name='LeadStatus'
                          id='LeadStatus'
                          className='w-100 staan-form-input'
                          placeholder='Qualified'
                          disabled={true}
                        />
                        <label
                          htmlFor='LeadStatus'
                          className='staan-form-input-label pt-1 px-1 mx-2'
                        >
                          Lead Status<span className='text-danger'>*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='row ms-3 mb-3'>
                    {' '}
                    <div className='row'>
                      <div className='col-6 staan-input-group'>
                        <Select
                          type='number'
                          options={customerList}
                          onInputChange={e => {
                            getOption(e, 'SupplierOption_withparameter')
                          }}
                          name='customerValue'
                          className='my-3'
                          styles={customSelectStyle}
                          value={supplierValue}
                          onChange={option => {
                            setFieldValue(
                              'customerValue',
                              option ? option?.value : null
                            )
                            setSupplierValue(option ? option : null)
                          }}
                        />
                        <label
                          htmlFor='customerValue'
                          className='staan-form-input-label pt-1 px-1 mx-2'
                        >
                          Customer<span className='text-danger'>*</span> <i role="button" className='bx bx-plus-circle ms-2'
                            onClick={() => { handleSupplierShow() }} ></i>
                        </label>
                        {touched.customerValue && errors.customerValue && (
                          <small className='staan-error-msg'>
                            {errors.customerValue}
                          </small>
                        )}
                      </div>
                      <div className='col-6 staan-input-group'>
                        <Field
                          type='text'
                          name='Source'
                          id='Source'
                          className='w-100 staan-form-input'
                          placeholder={
                            EnquireId
                              ? conference?.['label']
                              : ''
                          }
                          disabled={true}
                        />
                        <label
                          htmlFor='Source'
                          className='staan-form-input-label pt-1 px-1 mx-2'
                        >
                          Source<span className='text-danger'>*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='row ms-3 mb-3'>
                    {' '}
                    <div className='row'>
                      <div className='col-6 staan-input-group'>
                        <Select
                          type='number'
                          options={prefetchValue?.currencyExchangeList}
                          name='LeadCurrency'
                          className='my-3'
                          styles={customSelectStyle}
                          onChange={option => {
                            setFieldValue(
                              'LeadCurrency',
                              option ? option?.value : null
                            )
                          }}
                        />
                        <label
                          htmlFor='LeadCurrency'
                          className='staan-form-input-label pt-1 px-1 mx-2'
                          
                        >
                          Lead Currency<span className='text-danger'>*</span>
                        </label>
                        {touched.LeadCurrency && errors.LeadCurrency && (
                          <small className='staan-error-msg'>
                            {errors.LeadCurrency}
                          </small>
                        )}
                      </div>
                      <div className='col-6 staan-input-group'>
                        <Field
                          type='number'
                          name='LeadValue'
                          id='LeadValue'
                          className='w-100 staan-form-input'
                        />
                        <label
                          htmlFor='LeadValue'
                          className='staan-form-input-label pt-1 px-1 mx-2'
                        >
                          Lead Value<span className='text-danger'>*</span>
                        </label>
                        {touched.LeadValue && errors.LeadValue && (
                          <small className='staan-error-msg'>
                            {errors.LeadValue}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='row ms-3 mb-3'>
                    <div className='row'>
                      <div className='col-6 staan-input-group'>
                        <input
                          type='date'
                          className=' staan-form-input form-control w-100  '
                          style={{
                            border: '1px solid rgb(206, 201, 201)',
                            fontSize: '.8rem'
                          }}
                          min={getCurrentDate()}
                          onChange={e => {
                            if (e.target.value) {
                              setFieldValue(
                                'ExpectedClosureDate',
                                e.target.value
                              )

                            }
                          }}
                        />
                        {touched.ExpectedClosureDate && errors.ExpectedClosureDate && (
                          <small className='staan-error-msg'>
                            {errors.ExpectedClosureDate}
                          </small>
                        )}
                        <label className='staan-form-input-label pt-1 px-1 mx-2'>
                          Expected Closure Date<span className='text-danger'>*</span>
                        </label>
                      </div>
                      <div className='col-6 staan-input-group'>
                        <input
                          type='date'
                          name='followupDate'
                          value={nextFollowupDate}
                          className='staan-form-input form-control w-100'
                          style={{
                            border: '1px solid rgb(206, 201, 201)',
                            fontSize: '.8rem'
                          }}
                          onChange={e => {
                            if (e.target.value) {
                              setFieldValue(
                                'followupDate',
                                e.target.value
                              )
                              setnextFollowupDate(e.target.value)
                            }
                          }}
                        />
                        <label className='staan-form-input-label pt-1 px-1 mx-2' htmlFor='followupDate' onClick={() => {
                          console.log(followupDate);
                        }}>
                          Next Follow Up Date<span className='text-danger'>*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='row ms-3 mb-4'>
                    {/* Added margin-bottom */}
                    <div className='row'>
                      <div className='col-6 staan-input-group mt-1'>
                        <Select
                          options={salePersonlist}
                          onInputChange={e => {
                            getOption(e, 'userdataOptionsQuery')
                          }}
                          name='SalesPerson'
                          //   isDisabled={purchaseOrderEdit && edit}
                          className='my-3'
                          styles={customSelectStyle}
                          value={salesPersonValue}
                          onChange={option => {
                            setFieldValue(
                              'SalesPerson',
                              option ? option?.value : null
                            )
                            setsalesPersonValue(option)
                          }}
                        />
                        <label
                          htmlFor='SalesPerson'
                          className='staan-form-input-label pt-1 px-1 mx-2'
                        >
                          Sales Person<span className='text-danger'>*</span>
                        </label>
                        {touched.SalesPerson && errors.SalesPerson && (
                          <small className='staan-error-msg'>
                            {errors.SalesPerson}
                          </small>
                        )}
                      </div>
                      <div className='col-6 '>
                        <label htmlFor='Priority'>Priority</label>
                        <div>
                          {stars.map((_, index) => {
                            return (
                              <FaStar
                                key={index}
                                size={24}
                                value={rating}
                                onChange={e => setRating(e.target.value)}
                                color={
                                  rating > index ? colors.orange : colors.grey
                                }
                                onClick={() => {
                                  setRating(index + 1)
                                  setFieldValue('Priority', index + 1)
                                }}
                              />
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-6  staan-input-group'>
                        <Field
                          as='textarea'
                          name='Requirements'
                          id='Requirements'
                          className='w-100 staan-form-input'
                          rows='6'
                        />
                        <label
                          htmlFor='Requirements'
                          className='staan-form-input-label pt-1 px-1 mx-2'
                        >
                          Requirements
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end gap-2'>
                    <button type='button' className='btn btn-outline-secondary' onClick={Close}>
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='btn btn-outline-success'
                      onClick={() => {
                        console.log(errors)
                      }}
                    >
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <SupplierFrom lead={true} />
    </>
  )
}

export default LeadForm
