import { Modal } from "react-bootstrap"
import {Formik ,  Form, Field} from 'formik';
import DataContext from '../../../context/ItemMasterContext';
import { useEffect, useState, useContext} from "react";
import { AgGridReact } from 'ag-grid-react';
import POItem from "./POItem";
import axiosInstance from "../../../api/axoiss";
import { DepartmentOptionFetchQuery, FetchProductionOrderItem, ProductionOrderLinkCreateQuery, ProductionOrderLinkingMasterItemFetchQuery, ProductionOrderMasterCreateQuery } from "../../../GraphQLQueries/ProductionOrderQuery";
import { TotalStockByPartCode } from "../../../GraphQLQueries/ItemmasterQuery";
import { customSelectStyle, getCurrentDate } from "../../../utils/Utils";
import Select from 'react-select';
import { po_master_schema } from "../../../validations/ProductionOrderValidation";
import PoDetailCarousal from './PoDetailCarousal'
import { ToastContainer, toast } from 'react-toastify';
import BallTriangleComponent from "../../../components/loading/BallTriangle";


const AddPO = ({show, close, data, po_master}) => {
    const { userId } =   useContext(DataContext);
    let poInitialValue = {
        orderNo: '',
        orderDate: getCurrentDate(),
        department: null,
        productionOrderItem: []
    }
    const [productionOrderMasterData, setProductionOrderMasterData] = useState(poInitialValue);
    const [poItemData, setPoItemData] = useState([]);
    const [poItemDetailData, setPoItemDetailData] = useState(null)
    const [poItemIdList, setPoItemIdList] = useState([]);
    const [showPOItem, setShowPOItem] = useState(false);
    const [currentEditPoId, setCurrentEditPoId] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [departmentOptionList, setDepartmentOptionList] = useState([]);
    const [currentPoMasterId, setCurrentPoMasterId] = useState(null);
    // const [orderNo, setOrderNo] = useState('');
    // const [orderDate, setOrderDate] = useState(getCurrentDate());
    const [isCombo, setIsCombo] = useState(false);
    const [isMultiLevelManufacture, setMultiLevelManufacture] = useState(false);
    const [createdRmIds, setCreatedRmIds] = useState([]);
    const [createdSubPo, setCreatedSubPo] = useState([]);
    const rowStyle = { background: 'white' };
    const [Loading, setLoading] = useState(false);

    const handleShowPOItem = () => {
        if(!selectedDepartment){
            toast.error('Please select Department!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }else{
            // console.log(selectedDepartment)
            setShowPOItem(true);
        }
    }

    const handleClosePOItem = () => {
        setShowPOItem(false);
    }

    const FetchProductionOrderItemList = (id_list) => {
        axiosInstance.post("/itemmaster/graphql", {query: FetchProductionOrderItem(id_list)})
        .then(res => {
            let po_items = res?.data?.data?.productionOrderItem?.items || []
            setPoItemData(po_items);
            setLoading(false)
            // console.log(po_items, "po_items")
        })
        .catch(err =>{ 
            console.log(err, "error")
        })  
    }

    const GetCreatedProductionOrderItemId = (id) => {
        let previous_data = [id, ...poItemIdList]
        setPoItemIdList(previous_data)
        FetchProductionOrderItemList(previous_data)
    }

    const ProdOrderItemBomCell = ({data}) => {
        return (<>
            <span>{data?.bom?.bomNo} -- {data?.bom?.bomName}</span>
        </>)
    }

    const ProdOrderItemCostCell = ({data}) => {
        return (<>
            <span>{data?.qty * data?.cost}</span>
        </>)
    }

    const ProdOrderItemSupplierCell = ({data}) => {
        const [constructedSupplier, setConstructedSupplier] = useState('')
        useEffect(()=>{
            let supplier = data?.supplier?.map(item => item.companyName)
            supplier = supplier.join(', ')
            setConstructedSupplier(supplier)
        }, [])
        return (<>
            <span>{constructedSupplier}</span>
        </>)
    }

    const ProdOrderItemAvailableQtyCell = ({data}) => {
        const [availableQty, setAvailableQty] = useState(0);
        useEffect(()=>{
            axiosInstance.post(`/itemmaster/graphql`, { query: TotalStockByPartCode(data?.partCode?.id) })
            .then(res => {
                const total_stock_res_data = res?.data?.data?.itemStockByPartCode?.items?.[0]?.totalCurrentStock || 0; 
                setAvailableQty(total_stock_res_data)
            })
            .catch(err => {
                setAvailableQty(0)
            })
        }, [data])
        return (<>
            <span>{availableQty}</span>
        </>)
    }

    const ProdOrderItemAction = ({data}) => {
        return (<>
            <div>
                <span 
                    onClick={()=>{
                        if(!currentPoMasterId){
                            setCurrentEditPoId(data); 
                            handleShowPOItem()
                        }
                    }}>
                    <i className="fa-solid fa-pen text-secondary" 
                        style={{cursor: 'pointer'}}></i>
                </span>
            </div>
        </>)
    }

    const columns = [
        {
            headerName:'S.No' , 
            valueGetter: "node.rowIndex + 1",  
            flex: 1,
        },
        {
            headerName:'Part Code' , 
            field:'partCode.itemPartCode',  
            flex: 2,
            valueFormatter: (params) => `${params.data.partCode.itemPartCode}`
        },
        {
            headerName:'Part Name' , 
            field:'partCode.itemName',  
            flex: 3,
            valueFormatter: (params) => `${params.data.partCode.itemName}`
        },
        {
            headerName:'BOM' , 
            field:'bom.bomNo',  
            flex: 1,
            cellRenderer: ProdOrderItemBomCell
        },
        {
            headerName:'MO Qty' , 
            field:'qty',  
            flex: 1,
        },
        {
            headerName:'Stock' , 
            flex: 1,
            cellRenderer: ProdOrderItemAvailableQtyCell,
        },
        {
            headerName:'Source Type' , 
            field:'sourceType.name',  
            flex: 1,
            valueFormatter: (params) => `${params.data.sourceType.name}`
        },
        {
            headerName:'Supplier' , 
            field:'supplier',  
            flex: 1,
            cellRenderer: ProdOrderItemSupplierCell
        },
        {
            headerName:'Cost' , 
            field:'cost',  
            flex: 1,
            cellRenderer: ProdOrderItemCostCell
        },
        {
            headerName:'Action' , 
            flex: 1,
            cellRenderer: ProdOrderItemAction
        },
    ]

    const defaultColDef = {
        editable: false, 
        resizable: true
    }

    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors, setFieldError }, toggle, setOpenRowIndex, rowdata) => {
        let submit_data = {
            orderDate: poInitialValue.orderDate,
            department: parseInt(selectedDepartment?.value),
            status: 7, 
            isCombo: isCombo,
            isMultiLevelManufacture: isMultiLevelManufacture,
            createdBy: parseInt(userId),
            modifiedBy: parseInt(userId),
            isSubProductionOrder: false,
        }
        if(data){
            submit_data['id'] = data
        }
        setLoading(true)
        let po_master_create_query = ProductionOrderMasterCreateQuery(submit_data);
        axiosInstance.post("/itemmaster/graphql", {query: po_master_create_query})
        .then(res => {
            let created_data = res?.data?.data?.productionOrderMasterCreateMutation
            if(created_data?.success){
                setCurrentPoMasterId(created_data?.productionOrderMaster?.id)
                setProductionOrderMasterData(prev => ({...prev, ['orderNo']: created_data?.productionOrderMaster?.orderNo}))
                if(!data){
                    if(poItemIdList && poItemIdList.length > 0 && created_data?.productionOrderMaster?.id){
                        poItemIdList.forEach(item => {
                            let po_link_create_query = ProductionOrderLinkCreateQuery({
                                poItem: parseInt(item), 
                                poMaster: parseInt(created_data?.productionOrderMaster?.id),
                                subProductions: createdSubPo,
                                rawMaterials: createdRmIds,
                            });
                            axiosInstance.post("/itemmaster/graphql", {query: po_link_create_query})
                            .then(resdata => {
                                FetchCurrentProductionOrderMaster(created_data?.productionOrderMaster?.id)
                            })
                            .catch(err =>{ 
                                console.log(err, "error")
                            })
                        })
                    }
                }
            }else{
                //
            }
            
        })
        .catch(err =>{ 
            console.log(err, "error")
        })
    }

    const FetchDepartmentOption = () => {
        axiosInstance.post("/itemmaster/graphql", {query: DepartmentOptionFetchQuery()})
        .then(res => {
            let data_list = res?.data?.data?.department?.items || []
            if(data_list && data_list.length > 0){
                data_list = data_list.map(item => ({label: item.name, value: item.id}))
                setDepartmentOptionList(data_list)
            }
        })
        .catch(err =>{ 
            console.log(err, "error")
        })
    }

    const FetchCurrentProductionOrderMaster = (po_master_id) => {
        axiosInstance.post("/itemmaster/graphql", {query: ProductionOrderLinkingMasterItemFetchQuery(po_master_id, po_master?.isSubProductionOrder || false)})
        .then(res => {
            let data_list = res?.data?.data?.productionOrderLinking?.items || []
            if(data_list && data_list.length > 0){
                let po_master_data = data_list?.[0]?.poMaster || poInitialValue
                setProductionOrderMasterData({...po_master_data, department: parseInt(po_master_data?.department?.id)})
                setSelectedDepartment({label: po_master_data?.department?.name, value: po_master_data?.department?.id})
                // setOrderNo(po_master_data?.orderNo)
                // setOrderDate(po_master_data?.orderDate)
                setIsCombo(po_master_data?.isCombo)
                setMultiLevelManufacture(po_master_data?.isMultiLevelManufacture)
                const po_item_id_list = data_list.map(item => {
                    let po_item_id = item.poItem.id
                    return po_item_id
                })
                setPoItemIdList(po_item_id_list)
                setPoItemDetailData(data_list);
                FetchProductionOrderItemList(po_item_id_list);

            }
        })
        .catch(err =>{ 
            console.log(err, "error")
        })
    }

    const handleAddedRawMaterials = (id_list) => {
        setCreatedRmIds(prev =>[...prev, ...id_list])
    }

    const handleAddedSubProductions = (id_list) => {
        setCreatedSubPo(prev =>[...prev, ...id_list])
    }

    const handleAddedRawMaterialsForPoItem = (id_list) => {
        setCreatedRmIds(prev =>[...prev, ...id_list])
    }

    const handleAddedSubProductionsForCombo = (id_list) => {
        setCreatedSubPo(prev =>[...prev, ...id_list])
    }

    useEffect(()=>{
        // if(poItemIdList && poItemIdList.length > 0){
        //     FetchProductionOrderItemList(poItemIdList);
        // }
        FetchDepartmentOption()
        if(data){
            setLoading(true)
            setCurrentPoMasterId(data)
            console.log(data, 'po master id');
            FetchCurrentProductionOrderMaster(data)
        }else{
            setCurrentPoMasterId(null);
            setPoItemIdList([])
            setPoItemDetailData([])
        }
    }, [data])

    return (
        <Modal show ={show} onHide={close}
            fullscreen = {true}
            dialogClassName="modal-transparent"
            backdrop="static">
            <Modal.Body className="p-0">
                    <div style={{fontSize: '.8rem', width: '100%', height: '100%'}} className='bg-white'>
                        <div style={{width: "100%", height:"100%" }}>
                            <div className="row mx-auto py-3 border-bottom border-light shadow-sm " style={{width: "100%"}}>
                                <div className="col-5 my-auto">
                                    <h4>
                                        <span className='me-3' style={{cursor: 'pointer'}} onClick={()=>{
                                            setCurrentPoMasterId(null);
                                            setPoItemIdList([]);
                                            setPoItemDetailData([]);
                                            close();
                                        }}>
                                            <i className="fa-solid fa-arrow-left  fs-4"></i>
                                        </span>
                                        Manufacturing Order
                                    </h4>
                                </div>
                                <div className='col-5'></div>
                            </div>
                            <div className='py-2' style={{height: '88%', overflow: 'scroll'}}>
                                <Formik 
                                    enableReinitialize={true}
                                    initialValues = {productionOrderMasterData}
                                    validationSchema={po_master_schema}   
                                    onSubmit={handleSubmit}
                                >
                                {({errors, touched, isSubmitting, values, setFieldValue , Formik, formikProps })=>{
                                    return(<>
                                        <Form style={{width: "100%" }}>
                                            <div  style={{height: '100%'}}>
                                            {!currentPoMasterId && <>
                                                    <div className="mx-5">
                                                        <div className="row d-flex align-items-center">
                                                            <div className="col-4 staan-input-group">
                                                                <Select
                                                                    name='department' style={{fontSize: '.8rem'}} 
                                                                    options={departmentOptionList}
                                                                    value = {selectedDepartment}
                                                                    className='mt-3'
                                                                    styles={customSelectStyle}  
                                                                    onChange={(option)=>{
                                                                        setFieldValue('department', parseInt(option?.value) || null)
                                                                        setSelectedDepartment(option);
                                                                    }}
                                                                />
                                                                <label htmlFor="department" className='staan-form-input-label pt-1 px-1 mx-2'>Department</label>
                                                                {touched && errors && errors.department && <span className="text-danger">{errors.department}</span>}
                                                            </div>
                                                            <div className="col-4 staan-input-group text-center">
                                                                <div>
                                                                    <Field type="checkbox" name="is_combo" 
                                                                        checked = {isCombo} 
                                                                        onChange = {(e)=>{setIsCombo(e.target.checked)}}
                                                                        disabled =  {currentPoMasterId}
                                                                    /> Combo
                                                                </div>
                                                            </div>
                                                            <div className="col-4 staan-input-group text-center">
                                                                <Field type="checkbox" name="is_multi_level_manufacture" 
                                                                    checked={isMultiLevelManufacture} 
                                                                    disabled =  {currentPoMasterId}
                                                                    onChange = {(e)=>{setMultiLevelManufacture(e.target.checked)}}
                                                                /> Multi Level BOM
                                                            </div>
                                                        </div>
                                                    </div>                                                
                                                    <div className="mx-5 mt-4 text-end">
                                                        <div>
                                                            <button 
                                                                className="btn btn-sm btn-outline-primary"
                                                                type="button"
                                                                onClick={handleShowPOItem}
                                                                disabled = {currentPoMasterId}
                                                            >
                                                                <i className="fa-solid fa-plus me-2"></i>
                                                                New
                                                            </button>
                                                        </div> 
                                                    </div>
                                                    <div className="mx-5">
                                                        <div style={{height: '24rem'}}>
                                                            <div className='ag-theme-alpine mt-2'  style={{ height: '100%', width: '100%' }} >
                                                                <AgGridReact
                                                                    rowData = {poItemData}
                                                                    columnDefs = {columns}
                                                                    defaultColDef = {defaultColDef}
                                                                    rowStyle = {rowStyle}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='my-3 pb-2 d-flex justify-content-end'>
                                                        <div>
                                                            <button type="submit" className='btn btn-sm btn-outline-success fw-bold px-3 mx-4'
                                                            disabled = {currentPoMasterId}
                                                            >Confirm</button>
                                                        </div> 
                                                    </div>
                                            </> }
                                            </div>
                                        </Form>
                                    </>)
                                }}
                                </Formik>
                                <div>
                                    <div>
                                        {
                                            Loading && <BallTriangleComponent isshow={true} />
                                        }
                                    </div>
                                    <div style={{width: '98%'}} className="mx-auto mt-3">
                                        <div>
                                            {poItemDetailData && !Loading && <PoDetailCarousal 
                                                poItemDetailData = {poItemDetailData}
                                            />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        showPOItem && 
                        <POItem 
                            show = {showPOItem}
                            close = {handleClosePOItem}
                            handleAddedItem =  {GetCreatedProductionOrderItemId}
                            data = {currentEditPoId} 
                            isCombo = {isCombo}
                            handleAddedRawMaterials = {handleAddedRawMaterials}
                            handleAddedSubProductions = {handleAddedSubProductions}
                            handleAddedRawMaterialsForPoItem = {handleAddedRawMaterialsForPoItem}
                            handleAddedSubProductionsForCombo = {handleAddedSubProductionsForCombo}
                            department = {selectedDepartment}
                            isMultiLevelManufacture = {isMultiLevelManufacture}
                            poItemData = {poItemData}
                        />
                    }
            </Modal.Body>
            <ToastContainer />
        </Modal>
    )
}

export default AddPO;