import { AgGridReact } from 'ag-grid-react';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ExpenceReconciliationValidation } from '../../../validations/ExpenceValidation';

const ExpenseReconciliationModal = ({ isReconciliationShow, setReconciliationShow }) => {
    const [initialExpenseReconciliation, setExpenseReconciliation] = useState({
        employeeID: '',
        employeeName: '',
        advanceAmount: '',
        claimAmount: ''
    })
    const COLUMNS = [
        {
            headerName: 'Adv Rcvd Date',
            field: 'sNo',
            flex: .8,
            cellRenderer: 'serialNumberRenderer', // Use custom renderer
        }, {
            headerName: 'Expense Request no',
            field: 'sNo',
            flex: .8,
            cellRenderer: 'serialNumberRenderer', // Use custom renderer
        },
        {
            headerName: 'Advance Amount',
            field: 'sNo',
            flex: .8,
            cellRenderer: 'serialNumberRenderer', // Use custom renderer
        },
        {
            headerName: 'Adjusted Amount',
            field: 'sNo',
            flex: .8,
            cellRenderer: 'serialNumberRenderer', // Use custom renderer
        }
    ]
    const [IsReconciliationModalShow, setReconciliationModalShow] = useState(false)

    return (
        <>
            <Modal show={isReconciliationShow} size="xl">
                <div className="row mx-1">
                    <div className="col-5 m-3" style={{ color: "#5CADA9" }}>
                        <Modal.Title>
                            <i className="fa-solid fa-arrow-left fa-sm me-2"></i>
                            Expense Reconciliation
                        </Modal.Title>
                    </div>
                    <div className="col-6 mt-4 text-end">
                        <i className="fa-solid fa-pen fs-5 text-primary pe-3"></i>
                        <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3"></i>
                    </div>
                </div>
                <hr />
                <Modal.Body>
                    <Formik
                        initialValues={initialExpenseReconciliation}
                        validationSchema={ExpenceReconciliationValidation}
                    >
                        {({ errors, submitForm, touched, setFieldValue, values, resetForm }) => (
                            <>
                                <Form>
                                    <div className="row">
                                        <div className="col-3 staan-input-group">
                                            <Field
                                                type='text'
                                                name='employeeID'
                                                id="employeeID"
                                                values={initialExpenseReconciliation?.employeeID}
                                                className='w-100 staan-form-input'
                                            />
                                            <label htmlFor="employeeID" className='staan-form-input-label pt-1 px-1 mx-2'>Employee ID</label>
                                            {touched.employeeID && errors.employeeID && <small>{errors.employeeID}</small>}

                                        </div>
                                        <div className="col-3 staan-input-group">
                                            <Field
                                                type='text'
                                                name='employeeName'
                                                id="employeeName"
                                                values={initialExpenseReconciliation?.employeeName}
                                                className='w-100 staan-form-input'
                                            />
                                            <label htmlFor="employeeName" className='staan-form-input-label pt-1 px-1 mx-2'>Employee Name</label>
                                            {touched.employeeName && errors.employeeName && <small>{errors.employeeName}</small>}

                                        </div>
                                        <div className="col-3 staan-input-group">
                                            <Field type='text' name='advanceAmount' id='advanceAmount' className='w-100 staan-form-input' />
                                            <label htmlFor="advanceAmount" className='staan-form-input-label pt-1 px-1 mx-2'>Advance Due<span className='text-danger'>*</span></label>
                                            {touched.advanceAmount && errors.advanceAmount && <small>{errors.advanceAmount}</small>}
                                        </div>
                                        <div className="col-3 staan-input-group">
                                            <Field type='text' name='claimAmount' id='claimAmount' className='w-100 staan-form-input' />
                                            <label htmlFor="claimAmount" className='staan-form-input-label pt-1 px-1 mx-2'>Claim Due<span className='text-danger'>*</span></label>
                                            {touched.claimAmount && errors.claimAmount && <small>{errors.claimAmount}</small>}
                                        </div>

                                    </div>
                                    <div className='row  ms-3 mt-4 card shadow mx-2 p-3'>
                                        <div className='row justify-content-between align-items-center'>
                                            <div className='col-4'>
                                                <h6 className='commanModelTitleColor'>Reconciliation Details</h6>
                                            </div>
                                            <div className='col-4 text-end'>
                                                <div className="form-check form-switch">
                                                    <input
                                                        className='form-check-input'
                                                        type="checkbox"
                                                        id="flexSwitchCheckDefault"
                                                    />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                                        Auto Adjust
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        <div
                                            className='ag-theme-alpine mt-2'
                                            style={{ height: '30rem', width: '100%' }}
                                        >
                                            <AgGridReact
                                                columnDefs={COLUMNS}
                                            />

                                        </div>

                                    </div>
                                    <div className="row mt-3 me-2">
                                        <div className="text-end">
                                            <button type="button" className="btn btn-outline-danger btn-sm me-3 px-4">
                                                Draft
                                            </button>
                                            <button type="button" className="btn btn-sm btn-outline-success shadow-sm px-3">
                                                Submit
                                            </button>
                                        </div>
                                    </div>

                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ExpenseReconciliationModal;
