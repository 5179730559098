import {
      FETCH_UOM_DATA_REQUEST,
      FETCH_UOM_DATA_SUCCESS,
      FETCH_UOM_DATA_FAILURE,
} from '../Action/uomAction';
    
    const initialState = {
      data: [],
      isLoading: false,
      error: null,
    };
    
    const uomReducer = (state = initialState, action) => {
      switch (action.type) {
        case FETCH_UOM_DATA_REQUEST:
          return {
            ...state,
            isLoading: true,
            error: null,
          };
        case FETCH_UOM_DATA_SUCCESS:
          return {
            ...state,
            isLoading: false,
            data: action.payload,
          };
        case FETCH_UOM_DATA_FAILURE:
          return {
            ...state,
            isLoading: false,
            error: action.payload,
          };
        default:
          return state;
      }
    };
    
    export default uomReducer;