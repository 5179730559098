import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaSave } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { TiCancel } from 'react-icons/ti';
import showErrorToast from '../../../components/notifications/react-toastify/toast';

export const parseAndGroupItemComboData = (itemComboData) => {
    const parsedData = itemComboData.map(( item, index) => {
        const data = JSON.parse(item);
        return {
            index: index,
            id: data?.id,
            itemmasterId:data?.itemmaster?.id,
            partCode: data?.itemmaster?.itemPartCode,
            partName: data?.itemmaster?.itemName,
            display: data?.display,
            qty: data?.qty,
            rate: data?.rate,
            isSelected : data?.isMandatory? true : false,
            uom: {
                value: data?.uom?.id,
                label: data?.uom?.name
            },
            amount: data?.amount,
            afterDiscountValueForPerItem: data?.afterDiscountValueForPerItem,
            isMandatory: data?.isMandatory
        };
    }
    
);

    return parsedData
}

const calculateItemCombo = (itemComboList_, amount, qty, setItemComboDataList) => {
    
    
    let isItemCombo = false;
    const itemComboData = [];
    
    // Filter mandatory items
    const mandatoryItems = itemComboList_.filter(item => item.isSelected);
    
    if (mandatoryItems.length > 0) {
        isItemCombo = true;

        // Calculate total value for mandatory items
        const totalValue = mandatoryItems.reduce((sum, item) => {
            return sum + (parseFloat(item.rate) * parseFloat(item.qty));
        }, 0);

        const roundedFinalTotal = Math.round(parseFloat(amount) / qty * 100) / 100;

        if (totalValue === 0) {
            console.log("Total checked value is zero; cannot calculate ratios.");
            return [itemComboData];
        }

        // Calculate total discount needed
        const totalDiscountNeeded = totalValue - roundedFinalTotal;

        // Calculate contributions and ratios
        const itemContributions = mandatoryItems.map(item => {
            return parseFloat(item.rate) * parseFloat(item.qty);
        });

        const ratios = itemContributions.map(contribution => contribution / totalValue);

        // Calculate the discount for each item
        const discounts = ratios.map(ratio => totalDiscountNeeded * ratio);

        // Build the itemComboData
        itemComboList_.forEach((item, index) => {
            const originalPrice = parseFloat(item.rate) * parseFloat(item.qty);
 
            const data = {
                index: index,
                itemmasterId: item.itemmasterId,
                partCode: item.partCode,
                partName: item.partName,
                display: item.display || null,
                qty: Number(item.qty),
                rate: item.rate,
                uom: {
                    value: item.uom.value,
                    label: item.uom.label
                },
                isMandatory: item.isMandatory,
            };

            if (item.isSelected) {
                const discountIndex = mandatoryItems.findIndex(mItem => mItem.itemmasterId === item.itemmasterId);
                const discountedAmount = originalPrice - discounts[discountIndex];
                const finalDiscountedAmount = Math.max(discountedAmount, 0).toFixed(2);
                const afterDiscountValuePerItem = (finalDiscountedAmount / parseFloat(item.qty)).toFixed(2);
               

                data.afterDiscountValueForPerItem = afterDiscountValuePerItem;
                data.amount = Number(finalDiscountedAmount).toFixed(2);
                data.isSelected = true
            } else {
                data.afterDiscountValueForPerItem = 0;
                data.amount = "0";
                data.isSelected = false
            }

            itemComboData.push(data);
        });

        // Check total amounts
        const totalCalculatedAmount = itemComboData.reduce((sum, data) => {
            return sum + parseFloat(data.amount);
        }, 0);

        if (totalCalculatedAmount !== roundedFinalTotal) {
            console.warn(`Warning: The total calculated amount ${totalCalculatedAmount} does not match the parameter amount ${amount}.`);
        }
        console.log("itemComboData", itemComboData);
        setItemComboDataList(itemComboData)
   
    }

    return  itemComboData;
};


const SalesOrderItemCombo = ({ IsshowItemCombo, setIsShowItemComboModel, salesOrderValues,setSalesOrderValues ,  itemComboDataList, setItemComboDataList, saveItemCombo }) => {
    const [itemComboValue, setItemComboValue] = useState({currentEditIndex:null})
    useEffect(()=>{
        if (salesOrderValues?.lastUpdatedItemDetails && salesOrderValues?.stringItemCombo){
            let listOfItemComboList = parseAndGroupItemComboData(salesOrderValues?.stringItemCombo)
            setItemComboDataList(listOfItemComboList)
        }
    },[salesOrderValues])
    const totalCalculatedAmount = itemComboDataList.reduce((sum, data) => {
        return sum + parseFloat(data.amount);
    }, 0);


    const handleSelectItem = (selectedItem) => {
        let modified = itemComboDataList.map(item => {
            // Check if the item matches the selected item
            if (item.index === selectedItem.index) {
                // Toggle the isSelected property
                return { ...item, isSelected: !item.isSelected };
            }
            
            // Return the item unchanged if it doesn't match
            return item;
        })
        setItemComboDataList(modified);
       
        
        calculateItemCombo(modified,
            salesOrderValues?.lastUpdatedItemDetails?.amount,
            salesOrderValues?.lastUpdatedItemDetails?.qty, setItemComboDataList)
 
        
    };
    

    const handleEditClick = (item) => {
        
        if (item?.isSelected){
            setItemComboValue(prev=>({...prev, currentEditIndex:item?.index}))
        } else{
            showErrorToast(true,"warn", "Select The Check Box",  "")
        }
        
    };

    const handleSaveClick = (item) => {
        setItemComboValue(prev=>({...prev, currentEditIndex:""}))
        calculateItemCombo(itemComboDataList,
            salesOrderValues?.lastUpdatedItemDetails?.amount,
            salesOrderValues?.lastUpdatedItemDetails?.qty, setItemComboDataList)
    };

    const handleCancelClick = () => {
        setItemComboValue(prev=>({...prev, currentEditIndex:""}))
    };

    // Parse the new quantity and ensure it's a number and Update the quantity in the item,
    // Calculate the new amount,If afterDiscountValuePerItem has a value, use it,Otherwise, use the rate
    const handleQtyChange = (CurrentItem, e) => {
        let QTY_ = e.target.value;
        
        setItemComboDataList(prev => 
            prev.map(item => {
                // Check if the item matches the selected item
                if (item.index === CurrentItem.index) {
                    // Toggle the isSelected property 
                    let rate = item?.afterDiscountValueForPerItem ? item?.afterDiscountValueForPerItem : item?.rate
                    return { ...item, qty:QTY_ , amount:rate*QTY_};
                }
                // Return the item unchanged if it doesn't match
                return item;
            })
        );
    };

    const uniqueDisplayNames = Array.from(new Set(itemComboDataList.map(item => item.display)));
    const isAnyEditTrue = itemComboDataList.some(item => item?.edit === true);


    function close(params) {
        setIsShowItemComboModel(false);
        setItemComboValue({currentEditIndex:null})
        setItemComboDataList([])
        setSalesOrderValues(prev=>({...prev, lastUpdatedItemDetails:null,stringItemCombo:null }))
    }
    return (
        <>
            <Modal show={IsshowItemCombo} size="xl">
                <Modal.Body style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div className='row align-items-center justify-content-between'>
                        <div className='col-4 commanModelTitleColor'>
                            <h3 className='mx-2'>
                                <i
                                    className='fa-solid fa-arrow-left fa-sm me-2'
                                    onClick={close}
                                ></i>
                                Item Combo
                            </h3>
                        </div>
                    </div>
                    <div style={{ overflowY: 'auto', height: '40rem' }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th onClick={()=>{console.log(itemComboDataList);
                                    }}>Mandatory</th>
                                    <th>Part Code</th>
                                    <th>Part Name</th>
                                    <th>Rate</th>
                                    <th>Item Combo Rate</th>
                                    <th>Qty</th>
                                    <th>UOM</th>
                                    <th>Item Combo Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {uniqueDisplayNames.map(displayName => (
                                <React.Fragment key={displayName}> {/* Use displayName for key */}
                                    <tr>
                                        <td colSpan={12} style={{ fontWeight: 'bold' }}>
                                            {displayName}
                                        </td>
                                    </tr>
                                    {itemComboDataList
                                        .filter(item => item.display === displayName)
                                        .map(item => {
                                            const { partCode, partName, rate, isMandatory, qty, uom, amount, isSelected, index, afterDiscountValueForPerItem } = item; // Destructure item

                                            return (
                                                <>
                                                    <tr key={partCode}> {/* Ensure partCode is unique */}
                                                    <td style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={isSelected}
                                                            onChange={() => handleSelectItem(item)}
                                                            disabled={isMandatory|| item?.edit}
                                                        />
                                                    </td>
                                                    <td>{partCode}</td>
                                                    <td>{partName}</td>
                                                    <td>{rate}</td>
                                                    <td>{afterDiscountValueForPerItem}</td>
                                                    <td>
                                                        {isMandatory ? (
                                                            qty
                                                        ) : (
                                                            itemComboValue?.currentEditIndex === index ? (
                                                                <input
                                                                    style={{ width: '12rem', padding: '0.5rem' }}
                                                                    type="number"
                                                                    value={qty}
                                                                    onChange={(e) => {
                                                                        handleQtyChange(item, e);
                                                                    }}
                                                                />
                                                            ) : (
                                                                qty
                                                            )
                                                        )}
                                                    </td>
                                                    <td>{uom?.label}</td>
                                                    <td>{amount}</td>
                                                    <td>
                                                        {isMandatory|| item?.edit  ? (
                                                            <TiCancel className="text-danger" />
                                                        ) : (
                                                            itemComboValue?.currentEditIndex === index ? (
                                                                <>
                                                                    <FaSave
                                                                        onClick={() => handleSaveClick(item)}
                                                                        style={{ cursor: 'pointer', color: 'green' }}
                                                                    />
                                                                    <MdCancel
                                                                        onClick={handleCancelClick}
                                                                        style={{ cursor: 'pointer', color: 'black', marginLeft: '10px' }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <i
                                                                    className='fa-solid fa-pen text-secondary'
                                                                    onClick={() => handleEditClick(item)}
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                            )
                                                        )}
                                                    </td>
                                                    </tr>
                                                    
                                                </>
                                            );
                                        })}
                                </React.Fragment>
                            ))}
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>TOTAL</td>
                                <td>{totalCalculatedAmount}</td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                        
                    </div>
                    <div className="text-end mt-2" style={{ position: 'sticky', bottom: 0, background: 'white', padding: '10px' }}>
                        <button type="submit" className="btn btn-sm btn-success" disabled={isAnyEditTrue ? true: itemComboDataList.length === 0 ? true: itemComboValue?.currentEditIndex}
                            onClick={()=>{saveItemCombo(itemComboDataList, salesOrderValues?.lastUpdatedItemDetails, close)}}>
                            Save
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            
        </>
    );
};

export default SalesOrderItemCombo;