export const PurchaseItemCreateMutations =(data)=>{
    const mutations =`
    mutation create{
        purchaseOrderItemDetailsCreateMutation(${data}){
            purchaseOrderItemDetailsInstance{
                sNo
                id
                verifiedAmount
                received
                itemMasterId{
                 id
                 itemPartCode
                 itemName
                 variation
                 itemCost
                }
                category
                poQty
                hsnId{
                  id
                  gstRates{
                    rate
                  }
                }
                uom{
                    id
                    name
                }
                rate
                discount
                tax
                amount
                igst
                sgst
                cgst
                modifiedBy{
                 id
                 username
                }
                createdBy{
                    id
                    username
                }
            }
            success
            errors
       }
   }`  
    return mutations
  }

export const PurchaseItemDeleteMutations =(data)=>{
  const mutations =`
  mutation delete{
      purchaseOrderItemDetailsDeleteMutation(id:${data}){
        success
        errors
      }
    }` 
  return mutations
}

  
export const TermsConditionsFetchQuery = (data)=>{
  let query = `
  query mytc{
  termsConditions(${data}){
      items{
      id
        tc
        name
        module
      }
  }
}`
return query
}

export const OtherExpensesPurchaseCreateMutations = (data)=>{
  let query = `
  mutation cretaeotherexpence{
    otherExpensesPurchaseOrderCreateMutation(${data}){
      otherExpensesPurchaseOrderInstance{
        id
        amount
        igst
        sgst
        cgst
        createdBy{
          id
          username
        }
        otherExpensesId{
          id
          account{
            id
            accountsName
          }
          HSN{
            gstRates{
              rate
            }
          }
          
        }
      }
     success
     errors
   }
 }`
 return query
}

export const  PurchaseOrderCreateMutations = (data)=>{
  let query = `
  mutation my{
    purchaseOrderCreateMutation(${data}){
      success
      errors
      purchaseOrderInstance{
      id
    }
    }
  }`
 return query
}

export const  PurchaseOrderCancleMutations = (data)=>{
  // id: ""
  let query = `
  mutation MyMutation {
  purchaseOrderCancleMutation(${data}) {
    errors
    success
    action
  }
}`
 return query
}


export const  PurchaseOrderDeleteMutations = (data)=>{
  let query = `
  mutation delete{
    purchaseOrderDeleteMutation(id:${data}){
      success
      errors
      actions
    }
  }`
 return query
}
 
export const OtherExpensesDeleteMutations =(data)=>{
  const mutations =`
  mutation OtherExpenses{
    otherExpensesPurchaseOrderDeleteMutation(id:${data}){
     success
     errors
    }
   }`
  return mutations
}


export const goodsReceiptNoteItemDetailsSaveQuery = (data)=>{
  let query =`
  mutation goodsReceiptNoteItemDetailsCreateMutation{ 

  goodsReceiptNoteItemDetailsCreateMutation(${data}){ 
    goodsReceiptNoteItemDetailsInstance{ 
      id
      stockAdded
       batchNumber{
        id
        batchNumberName
      }
      serialNumber{
        id
        serialNumber
      }
      purchaseOrderId{ 
        id 
        itemMasterId{
            id
            itemPartCode
            itemName
            itemQc
            batchNumber
            serial
            serialAutoGentrate
            serialFormat
            serialStarting
          }
          poQty
          uom{
            id
            name
          }
        received
      } 
      received 
      excess
      balance 
    } 
    success 
    errors 
  }  
} 
  `
  return query
}

export const goodsReceiptNoteCreateQuery = (data)=>{
  let query = `
  mutation goodsReceiptNoteCreateMutation{  
  goodsReceiptNoteCreateMutation(${data}){  
    success  
    errors  
  } 
} `
 return query
}

export const getBatchNumberId = (data)=>{
  // batchNumber: "2024", partNumberId: 67888
let query  = `
mutation MyMutation { 
  getBatchNumberId(${data}){ 
    batchNumberInstance{  
      id 
      batchNumberName
    }  
    success 
    errors 
  } 
} 
`
return query
}

export const validatAndSerialNumber = (data)=>{
  // qty: 2, serialNumber: ["aa-26-14","aa-26-15" ]
    const mutations= `
      mutation MyMutation {  
        validatAndSerialNumberCreateMutation(${data}){ 
          errors 
          success 
          serialNumberItem{ 
            id 
            serialNumber 
          } 
        } 
      } 
    `
  return mutations
}

export const serialAutoGentrate =(data)=>{
  // partCode: 67895, qty: 10
  const query = `mutation MyMutationba3 {  
  serialNumberAutoCreateMutation(${data}){ 
     serialNumberItem 
    errors 
    success 
  } 
}`
return query
}

 
 

export const qualityInspectionsReportItemDetailsSave = (data)=>{
  let query =`
    mutation qualityInspectionsReportItemDetailsCreateMutation{  
  qualityInspectionsReportItemDetailsCreateMutation(${data}){ 
    qualityInspectionsReportItemDetailsInstance{  
      id  
      goodsReceiptNoteItem{
        id
        purchaseOrderId{
          itemMasterId{
            itemPartCode
            itemName
            itemQc
          }
          poQty
          uom{
            name
          }
          
        }
        received
      }
      accepted
      rejected
      rework
      createdBy{ 
        id 
        username 
      } 
    } 
    success 
    errors 
  } 
} 
  `
  return query
}

export const qualityInspectionReportCreateQuery = (data)=>{
  let query = `
  mutation qir{  
  qualityInspectionReportCreateMutation(${data}){
   qualityInspectionReportInstance{
      id
    }
    success 
    errors 
  } 
}  `
 return query
}

export const qualityInspectionCancleMutation = (data)=>{
  // grnNo: "grn_63"
  let query = `
 mutation MyMutation {
  qualityInspectionReportCancleMutation(${data}) {
    errors
    success
    action
  }
}  `
 return query
}


export const goodsReceiptNoteAndStockAddtionsCreateMutation = (data)=>{
  // id: 65 , createdBy: 3, modifiedBy: 3
  return `
  mutation MyMutation {
  goodsReceiptNoteAndStockAddtionsCreateMutation(${data}) {
    errors
    success
     
  }
}`
}


export const goodsInwardNoteCancleMutation=(perameter)=>{
  // id: "155"
  let query = `
  mutation MyMutation {
    goodsInwardNoteCancleMutation(${perameter}) {
      errors
      success
      action
    }
  }
  `
  return query
}



export const goodsReceiptNoteCancleMutation=(perameter)=>{
  let query = `
  mutation MyMutation {
  goodsReceiptNoteCancleMutation(${perameter}) {
    errors
    success
  }
  }
  `
  return query
}




export const PurchaseOrderTimeLine = (data)=>{
  // purchaseId: 182
  let query = `
  query MyQuery {
  purchaseOrderTimeLine1(${data}) {
  
    children {
      name
      attributes {
        department1
        department2
        id
      }
      children {
        name
        attributes {
          department1
          department2
          id
        }
        children {
          name
          attributes {
            department1
            department2
            id
          }
        }
      }
    }
    name
    id
    status
    serial
  }
}`
  return query
}
export const SerialDeletewithString = (data)=>{
  let query = `
  mutation MyMutation {
  serialNumberStringDeleteMutation(serial: "${data}") {
    errors
    success
  } 
  }`
  return query
}

export const reworkdeliveryChallanItemDetailsCreateMutation = (data)=>{
 
  let query = `
  mutation MyMutation {
  deliveryChallanItemDetailsCreateMutation(${data}) {
    errors
    success
    reworkDeliveryChallanItemDetailsInstance {
      createdBy{
        id
        username
      }
      hsnId{
        id
        hsnCode
      }
      itemMasterId{
        id
        itemPartCode
        itemName
      }
      uom{
        id
        name
      }
      amount
      id
      rate
      reworkQty
      tax
      updatedAt
    }
  }
}
 `
  return query
}

 
export const reworkdeliveryChallanCreateMutation = (data)=>{
 
  let query = `
        mutation MyMutation {
  deliveryChallanCreateMutation(${data}) {
    errors
    success
    reworkDeliveryChallanInstance{
      id
    }
  }
}
 `
  return query
}


export const reworkDeliveryChallanCancelMutation = (data)=>{
 
  let query = `
  mutation MyMutation {
  reworkDeliveryChallanCancelMutation(${data}){
   success
   errors
 }
}`
  return query
}

export const deliveryChallanDeleteMutation = (data)=>{
 
  let query = `
  mutation MyMutation {
  deliveryChallanDeleteMutation(${data}) {
    errors
    success
  }
  }`
  return query
}

export const purchaseRetunChallanItemMutation = (data)=>{
  // amount: ""  createdBy: 10  id: ""   modifiedBy: 10  purchaseOrderItemDetails: 10 retunQty: ""
  let query = `
  mutation MyMutation {
  purchaseReturnChallanItemDetailsCreateMutation(${data}) {
    errors
    success
    purchaseReturnChallanItemDetailsInstance {
      amount
      createdAt
      id
      retunQty
      purchaseOrderItemDetails {
        id
        hsnId {
          id
          hsnCode
        }
        itemMasterId {
          itemName
          itemPartCode
        }
        purchaseRetunQty
        poQty
        uom {
          unit {
            id
            itemName
          }
          name
          id
        }
        rate
        tax
      }
    }
  }
}`
  return query
}

export const purchaseRetunChallanMutation = (data)=>{
  //  courier: "" createdBy: 10  id: ""  modifiedBy: 10 otherModel: "" ownVehicle: ""
  //  status: "" 
  // transportId: "" vehicleNo: "" : 10 purchaseReturnChallanItemDetails: 12
  //  purchaseId: 10 , ewayBillNo: "", remarks: "" , termsConditionsText: ""
  // cgst: ""  igst: "" sgst: "" beforTax: ""
  let query = `
  mutation MyMutation {
  purchaseReturnChallanCreateMutation(${data}) {
    errors
    success
    purchaseReturnChallanInstance {
      id
    }
  }
}
   `
  return query
}