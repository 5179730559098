import { Formik, Form} from 'formik';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { work_center_schema } from '../../validations/bom';
import { customSelectStyle } from '../../utils/Utils';
import Select from 'react-select';
import { QueryFilter, userOptionQuery } from '../../GraphQLQueries/ItemmasterQuery';
import axiosInstance from '../../api/axoiss';
import { fetchUserDataList } from '../../ReduxState/Action/userAction';
import { useSelector, useDispatch } from 'react-redux';


const AddWorkCenter = ({show, close, handleChangeWorkCenter}) => {
    let initial_value = {
        'workCenter': '',
        'inCharge': ''
    }

    const dispatch = useDispatch(); 
    const userDataList = useSelector(state => state.userData.data);
    const [initialValue, setInitialValue] = useState(initial_value);
    const [userSelected, setUserSelected] = useState(null);
    const [workCenterName, setWorkCenterName] = useState(null);

    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors, setFieldError }, toggle, setOpenRowIndex, rowdata) => {
        let submit_data = {
            workCenter: workCenterName,
            inCharge: userSelected?.value,
        }
        console.log(submit_data);
        let mutation_query = `mutation WorkCenterMutation{
            workCenterCreateMutation(`
        mutation_query += QueryFilter(submit_data, [], ['inCharge', 'id'])
        mutation_query += `){
              workTypeItem{
                id
                workCenter
                inCharge{
                  id
                  username
                }
              }
              success
              errors
            }
        }`
        console.log(mutation_query);
        try{
            axiosInstance.post('/itemmaster/graphql', {query: mutation_query}).then(res => {
                let data = res?.data?.data?.workCenterCreateMutation
                if(data?.success){
                    // clearInputDatas()
                    if(handleChangeWorkCenter){
                        handleChangeWorkCenter(data?.workTypeItem)
                    }
                    close()
                }else{
                    console.log(data)
                }
                
            }).catch(e => {
                console.log(e)
            })
        }catch(e){
            console.log(e)
        }

    }

    useEffect(()=>{
        dispatch(fetchUserDataList(userOptionQuery()));
    }, [])

    return (
        <div>
            <Modal onHide={close} show={show}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Work Center</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Formik 
                    enableReinitialize = {true}
                    initialValues = {initialValue}
                    validationSchema = {work_center_schema}   
                    onSubmit = {handleSubmit}
                >
                {({errors, touched, isSubmitting, values, setFieldValue , Formik, formikProps })=>{
                    return(<>
                        <Form style={{width: "100%" }}>
                            <div className='row'>
                                <div className="col-6 staan-input-group">
                                    <input type="text" 
                                        name="workCenter"
                                        className='w-100 staan-form-input' 
                                        onChange = {(e)=>{setFieldValue('workCenter', e.target.value); setWorkCenterName(e.target.value)}}
                                        value = {workCenterName}
                                    />
                                    <label 
                                        htmlFor="workCenter" 
                                        className='staan-form-input-label pt-2 px-1 mx-2'
                                    >Work Center</label>
                                </div>
                                <div className="col-6 staan-input-group">
                                    <Select name="inCharge"  
                                        options={userDataList?.items?.map(item => ({label: item.username, value: item.id}))}
                                        style={{fontSize: '.7rem'}}
                                        className='mt-3'
                                        styles={customSelectStyle}
                                        value = {userSelected}
                                        onChange={(e)=>{setFieldValue('inCharge', e ? e?.value: null); setUserSelected(e)}}
                                    />
                                    <label 
                                        htmlFor="inCharge" 
                                        className='staan-form-input-label pt-1 px-1 mx-2'
                                    >Incharge</label>
                                </div>
                            </div>
                            <div className='row mt-2 text-end'>
                                <div>
                                    <button 
                                        className='btn btn-sm btn-outline-secondary' 
                                        type='button'
                                        onClick={()=>{close()}}>Close</button>
                                    <button 
                                        className='btn btn-sm btn-outline-success ms-2'
                                        type='submit'
                                    >Save</button>
                                </div>
                            </div>
                        </Form>
                    </>)
                }}
                </Formik>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddWorkCenter;