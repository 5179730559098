
import axiosInstance from "../../api/axoiss";

// Action Types
export const FETCH_UOM_DATA_REQUEST = 'FETCH_UOM_DATA_REQUEST';
export const FETCH_UOM_DATA_SUCCESS = 'FETCH_UOM_DATA_SUCCESS';
export const FETCH_UOM_DATA_FAILURE = 'FETCH_UOM_DATA_FAILURE';

// Action Creators
const fetchUomRequest = () => {
  return {
    type: FETCH_UOM_DATA_REQUEST,
  };
};

const fetchUomSuccess = (data) => {
  return {
    type: FETCH_UOM_DATA_SUCCESS,
    payload: data,
  };
};

const fetchUomFailure = (error) => {
  return {
    type: FETCH_UOM_DATA_FAILURE,
    payload: error,
  };
};

// Thunk Action Creator
export const fetchUomData = (fetch_query) => {
  return (dispatch) => {
    dispatch(fetchUomRequest());
    axiosInstance.post("/itemmaster/graphql", {query: fetch_query})
      .then(response => {
        const keys = Object.keys(response.data.data);
        const firstKey = keys.length > 0 ? keys[0] : null;
        const data = response.data.data[firstKey]
        dispatch(fetchUomSuccess(data));
      })
      .catch(error => {
        dispatch(fetchUomFailure(error.message));
      });
  };
};