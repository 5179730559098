import { useState } from 'react'
import TableView from '../../Packages/Custom/DataTable'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import { useContext } from 'react'
import DataContext from '../../context/ItemMasterContext'
import ItemaMasterModal from '../ItemMaster/model/ItemaMasterModal'
import ItemMasterEdit from '../../ItemMaster/Page/itemmasterEdit/ItemMasterEdit'

const ItemMaster = () => {
  const {
    handlItemaMastereShow,
    handlItemaMastereEditShow,
    itemaMasterEditModal,
    setEditItemmasterData,
    screenSize
  } = useContext(DataContext)
  const [isLoad, setLoad] = useState(false)
  const [currentItemMasterId, setCurrentItemMasterId] = useState(null)

  const CustomViewModalComponent = data => {
    setEditItemmasterData(data)
    handlItemaMastereEditShow()
    setLoad(!isLoad)
  }

  const CustomAddModalComponent = () => {
    handlItemaMastereShow()
  }

  const headers = [
    {
      header: 'ID',
      field: 'id',
      flex: 7,
      inputType: 'number',
      align: 'end'
    },
    {
      header: 'Part Code',
      field: 'itemPartCode',
      flex: 10,
      inputType: 'text',
      query: 'ItemMasterOptionQuery',
      renderComponent: CustomViewModalComponent
    },
    {
      header: 'Part Name',
      field: 'itemName',
      flex: 30,
      query: 'ItemMasterOptionQuery',
      inputType: 'text'
    },
    {
      header: 'Item Category',
      field: 'category',
      label: 'name',
      flex: 10,
      option: true,
      query: 'ItemCategoryOptionQuery',
      inputType: 'text'
    },
    {
      header: 'Item Group',
      field: 'itemGroup',
      label: 'name',
      flex: 15,
      option: true,
      query: 'ItemGroupOptionQuery',
      inputType: 'text'
    },
    {
      header: 'Item Unit',
      field: 'itemUom',
      label: 'name',
      flex: 8,
      query: 'uomOptionFetchQuery',
      inputType: 'text'
    },
    {
      header: 'Product/Service',
      field: 'itemTypes',
      label: 'name',
      flex: 10,
      option: true,
      query: 'ItemTypeOptionQuery',
      inputType: 'text'
    },
    {
      header: 'Buy/Sell',
      field: 'itemIndicators',
      label: 'name',
      flex: 10,
      option: true,
      query: 'ItemIndicatiorOptionQuery',
      inputType: 'text'
    },
    {
      header: 'Servicable',
      field: 'service',
      align: 'center',
      flex: 10,
      option: true,
      bool: true,
      inputType: 'text'
    },
    {
      header: 'Item Combo',
      field: 'itemComboBool',
      align: 'center',
      flex: 8,
      option: true,
      bool: true,
      inputType: 'text'
    },
    {
      header: 'Keep Stock',
      field: 'keepStock',
      align: 'center',
      flex: 8,
      option: true,
      bool: true,
      inputType: 'text'
    },
    {
      header: 'Active',
      field: 'itemActive',
      align: 'center',
      flex: 8,
      option: true,
      bool: true,
      inputType: 'text'
    }
  ]

  return (
    <>
      <div className='container-fluid index_page_aliment_warp'>
        <div className='index_page_aliment_sidebar'>
          <SideNavbar />
        </div>
        <div className='index_page_aliment_table'>
          <TableView
            headers={headers}
            query={'ItemMasterQuery'}
            addComponent={CustomAddModalComponent}
            loadComponent={isLoad}
            titleName={'Item Master'}
            permission={'Save_Item_Master'}
            heightAdjust={`${screenSize?.height / 20}rem`}
          />
          <ItemaMasterModal
            setLoad={() => {
              setLoad(!isLoad)
            }}
          />
        </div>
        {itemaMasterEditModal && <ItemMasterEdit id={currentItemMasterId}/>}
      </div>
    </>
  )
}

export default ItemMaster
