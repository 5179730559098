import { 
    FETCH_ACCOUNT_DATA_FAILURE,
    FETCH_ACCOUNT_DATA_REQUEST,
    FETCH_ACCOUNT_DATA_SUCCESS,
} from "../Action/accountMaster";


const initialAccountState = {
    data: [],
    isLoading: false,
    error: null,
};

export const AccountMasterReducer = (state = initialAccountState, action) => {
    switch (action.type) {
      case FETCH_ACCOUNT_DATA_REQUEST:
        return {
          ...state,
          isLoading: true,
          error: null,
      };
      case FETCH_ACCOUNT_DATA_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
      };
      case FETCH_ACCOUNT_DATA_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
      };
      default:
        return state;
    }
};
