import React, { useContext, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import DataContext from '../../context/ItemMasterContext';
import TableView from '../../Packages/Custom/DataTable';
import CurrencyMasterFrom from './Model/CurrencyMasterFrom';

const CurrencyMaster = () => {
    const {  handleCurrencyMasterShow} = useContext(DataContext)
 
    const [isLoad, setLoad] = useState(false)
    const [editCurrencyMaster, setEditCurrencyMaster]  = useState()
    const CustomAddModalComponent = () => {
      handleCurrencyMasterShow()
    }
    const CustomViewModalComponent=(data)=>{
      setEditCurrencyMaster(data)
      handleCurrencyMasterShow()
    }
 
 
    const headers =[
      {
        header: 'Name',
        field: 'name', 
        flex: 10,
        query: 'CurrencyMasterOptionsForTable',
        inputType: 'text',
        renderComponent: CustomViewModalComponent,
      },
      {
        header: 'Symbol',
        field: 'currencySymbol', 
        label: 'currencySymbol', 
        flex: 10,
        query: 'CurrencySymbolForTable',
        inputType: 'text', 
      },{
        header: 'Formate',
        field: 'formate', 
        label : "formate",
        flex: 10,
        option: true,
        query: 'formateOption',
        inputType: 'text', 
      },
      {
        header: 'Active',
        field: 'active', 
        flex: 10,
        option: true,
        bool: true,
        inputType: 'text', 
      },
    ]
  return (
    <>
    <ToastContainer/>
    <div className=' index_page_aliment_warp'>
        <div className='index_page_aliment_sidebar'>
            <SideNavbar />
        </div>
        <div className='index_page_aliment_table'>
            <TableView
              headers={headers}
              query = {'CurrencyMasterTableQuery'}
              addComponent = {CustomAddModalComponent}
              loadComponent = {isLoad}
              titleName = {'Currency Master'}
              heightAdjust = {'40rem'}
              permission={"Save_Currency_Master"}
              />
        </div>
    </div>
     
     <CurrencyMasterFrom
     editCurrencyMaster ={editCurrencyMaster}
     setEditCurrencyMaster ={setEditCurrencyMaster}
     setLoad = {()=>{setLoad(!isLoad)}}/>
 
    </>
  )
}

export default CurrencyMaster