import React, { useState, useContext, useEffect }from 'react'; 
import CustomDatePicker from './CustomDatePicker'; 
import Select from 'react-select';
import { customSelectStyle } from '../../../utils/Utils';
import { Modal } from 'react-bootstrap';
import { handleExcelReportDownload } from './ExcelReportDownload';
import { handlePdfReportDownload} from './PdfReportDownload';
import { SideNavbar } from '../../../components/sideNavbar/SideNavbar';
import DataContext from '../../../context/ItemMasterContext';
import { useSelector, useDispatch } from 'react-redux';    
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery"
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';

const PosReport = () => {
    const {userId,  userName, currentConference
         } = useContext(DataContext);
    const today = new Date(); 
    const formattedDate = `${today.getDate().toString().padStart(2, '0')}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getFullYear()}`;
    const [isShowDatePicker, setShowDatePicker] = useState(false)
    const [selectedReportDates, setSelectedReportDates] = useState({
        start_date: formattedDate,
        end_date: formattedDate
    })
    const dispatch = useDispatch();    
    const conferenctData = useSelector(state => state.itemGroup.data);
    const [currentUserEvent, setCurrentUserEvent] = useState(null)
    const [selectedUser, setSelectedUser] = useState([])
    const [marketingEventOptions, setMarketingEventOptions] = useState([])
    const [currentBalanceAmount, setCurrentBalanceAmount] = useState(0)
    const [cashAmountReceived, setCashAmountReceived] = useState(0)
    const [bankAmountReceived, setBankAmountReceived] = useState(0)
    const [swipeAmountReceived, setSwipeAmountReceived] = useState(0)
    const [totalPOSAmount, setTotalPOSAmount] = useState(0)
    const [isShowExportOptionModal, setShowExportOptionModal] = useState(false)
    const handleShowExportOptionModal = () => { setShowExportOptionModal(true)}
    const handleCloseExportOptionModal = () => { 
        setShowExportOptionModal(false);
        let table_container = document.getElementById('pos-table-day-report-data')
        table_container.innerHTML = ''
    }

    useEffect(()=>{ 
        if (currentConference){ 
            handluserEvent({value: currentConference?.value, label: currentConference?.lable})
        }
        
    },[currentConference])

    const handluserEvent =(option)=>{
        if (option){
            setCurrentUserEvent({value: option?.value, label: option?.label}) 
            dispatch(fetchOptionsData(QueryData.PosReport(option?.value, 
                selectedReportDates.start_date, selectedReportDates.end_date), "PayMentDetails"))
        } else{
            setCurrentUserEvent({value: "" , label: ""}) 
            clear()
        }
        
    }

    let export_options = [
        {value: 1, label: 'Excel Export'},
        {value: 2, label: 'PDF Export'}
    ]

    const getData = ()=>{
        if (userId){
            
            dispatch(fetchOptionsData(QueryData.CurrentConferenct(userId, false), "CurrentConferenct"))
        }
    }

    useEffect(()=>{
        getData()
    },[userId])
    function clear( ) {
               setCashAmountReceived(0.00)
                setBankAmountReceived(0.00)
                setSwipeAmountReceived(0.00)
                setCurrentBalanceAmount(0.00)
                setTotalPOSAmount(0.00) 
                setSelectedUser({label: "", value: ""})
        
    }
    useEffect(()=>{
        if (conferenctData.name === "CurrentConferenct"){   
            console.log(conferenctData);
            let event = conferenctData?.value?.items?.map(item => ({value: item?.id, label: item?.name}))
            if (event){
                event.push({value: 0, label: "All"})
            }
           
            setMarketingEventOptions(event) 
        } 
        if (conferenctData.name === "PayMentDetails"){
             let payMentDetaile = conferenctData?.value?.items 
     
                console.log(payMentDetaile);
             if (payMentDetaile?.[0] !== null && payMentDetaile !== undefined){
                setCashAmountReceived(Number(payMentDetaile?.[0]?.['cashAmountReceived']))
                setBankAmountReceived(Number(payMentDetaile?.[0]?.['bankAmountReceived']))
                setSwipeAmountReceived(Number(payMentDetaile?.[0]?.['swipeAmountReceived']))
                setCurrentBalanceAmount(Number(payMentDetaile?.[0]?.['currentBalanceAmount']))
                setTotalPOSAmount(Number(payMentDetaile?.[0]?.['totalPosAmount'])) 
                setSelectedUser({label: userName, value: userId})
             } else{
                clear()
             }
           
        } 
    },[conferenctData])

    const handleDateFilteredData = (start_date = null, end_date = null) => {
        console.log(start_date, end_date)
    }

    const formateGivenStringdate = (date_str) => {
        const date_obj = new Date(date_str);
        const formattedDate = `${date_obj.getDate().toString().padStart(2, '0')}-${(date_obj.getMonth() + 1).toString().padStart(2, '0')}-${date_obj.getFullYear()}`;
        return formattedDate
    }

    const handleDatePickerShow = () => {
        setShowDatePicker(true)
    }

    const handleDatePickerClose = () => {
        setShowDatePicker(false)
    }

    const handleGetSelectedDate = (data) => {
        let selected_start_date = formateGivenStringdate(data.startDate)
        let selected_end_date = formateGivenStringdate(data.endDate)
        if (currentUserEvent?.value){
          dispatch(fetchOptionsData(QueryData.PosReport(currentUserEvent?.value, 
            selected_start_date, selected_end_date), "PayMentDetails"))
        } else{
            clear()
        }
        
        setSelectedReportDates({
            start_date: selected_start_date, 
            end_date: selected_end_date
        })
    }

    const handlePaymentModeFilter = (data_list, data_type) => {
        return data_list.filter(item => item.account_type?.toLowerCase().trim() === data_type).reduce((total, item) => total + Number(item.pay_amount), 0)
    }
    
    // useEffect( ()=>{  
    //     const handleTransactionReports = () => {
    //         let total_amount = 0
    //         let total_received_amount = 0
    //         let payment_ids = []
    //         // let current_event_pos = salesOrderData.filter(item => item.marketingEvent === current_user_event?.id)
    //         let current_event_pos = ""

    //         // loops for total amount, total received amount and payment ids for received payment
    //         for(let sales_order of current_event_pos){
    //             total_amount += Number(sales_order['TotalAmount'])
    //             total_received_amount += Number(sales_order['receivedAmount'])
    //             payment_ids = [...sales_order['payment'], ...payment_ids]
    //         }

    //         let current_event_payment_modes = PosPaymentData.filter(item => payment_ids.includes(item.id)).map(item => {
    //             let payby_option_data = Accountdata.find(account_item => account_item.id === Number(item.payby))
    //             return {...item, account_type: payby_option_data?.AccountType}
    //         })
    //         // calculates payment total based on account types
    //         let bank_type_payments = handlePaymentModeFilter(current_event_payment_modes, 'bank')
    //         let cash_type_payments = handlePaymentModeFilter(current_event_payment_modes, 'cash')
    //         let swipe_type_payments = handlePaymentModeFilter(current_event_payment_modes, 'swipe')
    //         setCashAmountReceived(cash_type_payments)
    //         setBankAmountReceived(bank_type_payments)
    //         setSwipeAmountReceived(swipe_type_payments)

    //         // calculates current balance amount
    //         let blnc_amount = Number(total_amount) - Number(total_received_amount)
    //         setCurrentBalanceAmount(blnc_amount)
    //         setTotalPOSAmount(Number(total_amount))
    //     }
    //     handleTransactionReports()
    //     let current_user = userdata.find(item => item.id === Number(userId))
    //     setSelectedUser({label: current_user?.username, value: current_user?.id})
    // }, [conferenct])

    const DayReportExport = ({show, close, handleClickedExportOption}) => {
        return(
            <Modal
                show={show}
                onHide={close}
                size='sm'
            >
                <Modal.Header closeButton>Export Options</Modal.Header>
                <Modal.Body>
                    <ul style={{listStyleType: 'none'}}>
                        <li className='my-2' role='button' onClick={()=>{handleClickedExportOption(export_options[0].value)}}>
                            <i className="fa-regular fa-file-excel fs-5 me-3"></i> {export_options[0].label}
                        </li>
                        <li className='my-2' role='button' onClick={()=>{handleClickedExportOption(export_options[1].value)}}>
                            <i className="fa-regular fa-file-lines fs-5 me-3"></i> {export_options[1].label}
                        </li>
                    </ul>
                </Modal.Body>
            </Modal>
        )
    }

    const createDayReportPdf = (data_list) => {
        let table_container = document.getElementById('pos-table-day-report-data')
        let child_nodes = table_container.childNodes
        let end_texts = ['Total Amount', 'Cash', 'Bank', 'Swipe', 'Balance']
        if(child_nodes.length>0){
            return;
        }else{
            data_list.map(item => {
                let table_row = document.createElement('tr')
                let tr_item_key = document.createElement('td')
                tr_item_key.innerText = item.key
                let tr_item_value = document.createElement('td')
                if(end_texts.includes(item.key)){
                    tr_item_value.setAttribute('style', 'text-align: end; padding-right: 1rem')
                }
                tr_item_value.innerText = item.value
                table_row.appendChild(tr_item_key)
                table_row.appendChild(tr_item_value)
                table_container.appendChild(table_row)
            })
        }
    }

    const handleClickedExportOption = (option) => {
        let excel_input_data = [
            {key: 'Event Name', value: currentUserEvent?.label},
            {key: 'Incharge Name', value: selectedUser?.label},
            {key: 'Report Date', value: `${selectedReportDates.start_date} to ${selectedReportDates.end_date}`},
            {key: 'Total Amount', value: `${totalPOSAmount.toFixed(2)}`},
            {key: 'Cash', value: `${cashAmountReceived.toFixed(2)}`},
            {key: 'Bank', value: `${bankAmountReceived.toFixed(2)}`},
            {key: 'Swipe', value: `${swipeAmountReceived.toFixed(2)}`},
            {key: 'Balance', value: `${currentBalanceAmount.toFixed(2)}`},
        ]
        if(option === 1){
            handleExcelReportDownload(excel_input_data, 'pos_day_report')
        }else if(option === 2){
            createDayReportPdf(excel_input_data)
            handlePdfReportDownload('pos_day_report')
        }
    }

    return(
        <>  
            <div className='container-fluid' style={{width:"100%", position:"relative"}}>
                <div className='' style={{position:"fixed", top:"0", left:"0", width:"10%", zIndex:"20"}}>
                    <SideNavbar />
                </div>
            </div>
            <div className=' ' style={{position:"fixed", top:"0", left:"5rem",width:"100%" }}>
                <div className='bg-white' style={{height: '100%'}}>
                        <div className='pos-report-header border-bottom border-solid shadow'>
                            <h3 className='py-3 px-4'>POS Day Report</h3>
                        </div>
                        <div className='mx-5 my-5'>
                            <div className='d-flex justify-content-between align-items-center row'>
                                <div className='col-3 row'>
                                    <div className="col-12 staan-input-group">
                                        <Select
                                            name="marketingEvent" 
                                            isClearable 
                                            options={marketingEventOptions}
                                            className='mt-3'
                                            styles={customSelectStyle}
                                            value={currentUserEvent}
                                            onChange={(option)=>{ handluserEvent(option)}}
                                        />
                                        <label htmlFor="marketingEvent" className="staan-form-input-label pt-1 px-1 mx-2">Conference Name</label>
                                    </div>
                                </div>
                                <div className='col-6 row'> 
                                    <div className='col-9' >
                                        <p className='m-0 '>
                                            <span onClick={handleDatePickerShow} className='text-primary' role="button">Date</span>: 
                                            <b> {selectedReportDates.start_date}</b> to <b>{selectedReportDates.end_date}</b> 
                                        </p>
                                    </div>
                                    <div className='col-3'>
                                        <span>
                                            <i className="fa-solid fa-download fs-4 text-primary" 
                                                role='button'
                                                onClick={()=>{handleShowExportOptionModal()}}
                                            ></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='my-5 border'>
                                <div className='row my-4'>
                                    <div className='col-3 text-center'>
                                        <p><i className="fa-solid fa-money-bill-wave text-primary"></i> Cash</p>
                                        <p className='fs-2'>₹ {cashAmountReceived.toFixed(2)}</p>
                                    </div>
                                    <div className='col-3 border-start text-center'>
                                        <p><i className="fa-solid fa-building-columns text-primary"></i> Bank</p>
                                        <p className='fs-2'>₹ {bankAmountReceived.toFixed(2)}</p>
                                    </div>
                                    <div className='col-3 border-start text-center'>
                                        <p><i className="fa-solid fa-money-check-dollar text-primary"></i> Swipe</p>
                                        <p className='fs-2'>₹ {swipeAmountReceived.toFixed(2)}</p>
                                    </div>
                                    <div className='col-3 border-start text-center'>
                                        <p><i className="fa-solid fa-money-check-dollar text-primary"></i> Balance</p>
                                        <p className='fs-2'>₹ {currentBalanceAmount.toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <table id="pos-table-day-report-data" className='mx-auto' style={{width: '80%'}}>
                                
                            </table>
                        </div>
                    </div>
                    <CustomDatePicker 
                        show = {isShowDatePicker}
                        close = {handleDatePickerClose}
                        handleGetSelectedDate = {(d)=>{handleGetSelectedDate(d)}}
                    />
                    {isShowExportOptionModal && 
                        <DayReportExport 
                            show = {isShowExportOptionModal}
                            close = {handleCloseExportOptionModal}
                            handleClickedExportOption = {(e)=>{handleClickedExportOption(e)}}
                        />
                    }

            </div>
        </>
    )
}

export default PosReport