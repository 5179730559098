import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import DataContext from '../../../context/ItemMasterContext';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { OnchangeGetData, ShowErrorNoties, customSelectStyle } from '../../../utils/Utils';
import { PosItemSchema } from '../../../validations/itemmaster';
import StockStatementDetails from '../../StockStatement/Details/StockStatementDetails';

const PosItemDetails = ({positemAdd,handlepositemclose,  posItemEdit, setPosItemEdit,
      selectedStore, SaveSerialNumber,   SaveTheValuesbatch, SaveNoneBs, isDataSave, setisDataSave}) => {
    const dispatch = useDispatch();   
    const getData = useSelector(state => state.itemGroup.data); 
    const {userId} =  useContext(DataContext)
    const [itemmasterPartCodeOptions, setItemmasterPartCodeOptions] = useState([])
    const [itemmasterPartNameOptions, setItemmasterPartNameOptions] = useState([])
    const [serialOrBatch, setSerialOrBatch] = useState(null)
    const [batchNumberOptions, setBatchNumberOptions] = useState([]);
    const [serialNumberOptions, setSerialNumberOptions] = useState([]);
    const [selectBatchNumber , setSelectBatchNumber] = useState([]);
    const [selectSerialNumber, setSelectSerialNumber] = useState([]);
    const [seleteItempartnumber, setSeleteItempartnumber] = useState('')
    const [selectItemPartName, setselectItemPartName] = useState('')
    const [selectUom, setSelectUom] = useState('');
    const [qtydata, setqtydata] = useState('');
    const [rateData, setRateData] = useState('');
    const [itemComboBool_, setItemComboBool_] =  useState(false)
    // initiate the Value
    const [initialValuePosItem, setInitialValuePosItem] = useState({
        partCode : "",
        partName : "",
        Description : "",
        qty : 1,
        rate : "", 
        uom : "",
        itemComboBool : false,
        Amount  :"",
        store : "",
        BatchNo : "",
        Serialnum : "",
        getRate : "",
        createdby : "",
    })
     
    // handle the OnChange Value
    const handleSelectBatchNumber = (option)=>{
      if (option){
        setSelectBatchNumber({ value: option.value , label:  option.label })
      } else{
        setSelectBatchNumber({ value: "" , label:  "" })
      }
      
    }
 
    const handleSelectSerialNumber = (options) => {
      if (options){
        setSelectSerialNumber(options);
      } else{
        setSelectSerialNumber("");
      }
    };
   
    const handlepartnumberChange=(options)=>{
      setSeleteItempartnumber({ value: options.value , label:  options.label })
    } 
 
    const handlepartnameChange = (options)=>{
      setselectItemPartName({ value: options.value , label:  options.label })
    }
   
    const handleuomChange = (options)=>{
      setSelectUom({ value: options.value , label:  options.label })
    }
    
    const handleqtydata= (data)=>{ 
      setqtydata(data)
    }
   
    const handlerateData= (data)=>{
      setRateData(data)
    }
     
     // return 0;  to get Options value
    async function getOptions(e, queryName, selectId){
    setItemmasterPartCodeOptions([])
    setItemmasterPartNameOptions([])
        let search_term = e.trim().replace(/"/g, '\\"'); 
        if (queryName === "itemMasterOptionFetchQuery" && search_term && selectId === "itemPartCode"){
          let responseData =  await  OnchangeGetData(QueryData[queryName]({key: 'itemPartCode', value: search_term}))
         
          if (responseData.success){  
            setItemmasterPartCodeOptions(responseData.data.itemMaster.items) 
          }  
        }  
        if (queryName === "itemMasterOptionFetchQuery" && search_term && selectId === "itemName"){
          let responseData =  await  OnchangeGetData(QueryData[queryName]({key: 'itemName', value: search_term}))
          if (responseData.success){  
            setItemmasterPartNameOptions(responseData.data.itemMaster.items) 
          }  

        }
        
    }
    // return 0;  to get Edit Data
    async function GetEditData(id) { 
      if (id){ 
        dispatch(fetchOptionsData(QueryData.PosItemDetailsEdit(id), "itemMasterInPosDetailsEdit"))
      } 
    }
    // return 0;  to set The value To input  
    useEffect(()=>{
      if (getData.name === "itemMasterInPosDetailsEdit"){ 
          let responceData = getData.value.items[0]     
          initialValuePosItem.partCode = responceData['partCode']['id']
          initialValuePosItem.partName = responceData['partCode']['id']
          initialValuePosItem.Description = responceData['description']
          initialValuePosItem.qty = responceData['qty']
          initialValuePosItem.rate = responceData['rate']
          initialValuePosItem.uom = responceData['uom']['id']
          initialValuePosItem.createdby = responceData['createdBy']['id']
          initialValuePosItem.getRate = responceData['gstRate']
          initialValuePosItem.itemComboBool = responceData['posItemComboBool']
          setItemComboBool_(responceData['posItemComboBool'])
          if (responceData['batch']){
            initialValuePosItem.BatchNo = responceData['batch']['id']
            setSelectBatchNumber({ value: initialValuePosItem.BatchNo , label:  responceData['batch']['batchNumberName']})
          } 
          if (responceData['serial']){
               // SerialNumberData
               let serialLabel = []
               for (let serialId in responceData['serial']) {
                let itratedserialId = responceData['serial'][serialId]  
                serialLabel.push({ value: itratedserialId['id'] , label:  itratedserialId['serialNumber'] })
              }
              setSelectSerialNumber(serialLabel)
              initialValuePosItem.Serialnum = serialLabel.map(serial=>serial.value)
          } 

          selectdataonchange(responceData['partCode'], false)
          setSeleteItempartnumber({ value: initialValuePosItem.partCode , label:  responceData['partCode']['itemPartCode'] })
          setselectItemPartName({ value: initialValuePosItem.partName , label:  responceData['partCode']['itemName']})
          setRateData(responceData['rate'])
          setqtydata(responceData['qty'])
          setSelectUom({value: responceData['uom'].id , label:  responceData['uom'].name})
      
      }
    }, [getData])

    // retun Null; filiter function for serial and batch number options
    const filtertheData = async (itemValue, serial_or_batch) => {
      /*
      get item id and store id send request to  get batch and serial number denpends  on serialOrBatch append the options
      to list
      */
      setBatchNumberOptions([])
      setSerialNumberOptions([])
      let storeobj = selectedStore.value
      if (storeobj && itemValue){
        let responseData =  await  OnchangeGetData(QueryData.getBatchOrSerial(Number(itemValue), Number(storeobj))) 
        if (responseData.success && responseData?.data?.itemStock?.items?.length > 0){ 
          if(serial_or_batch === "batch"){
            if (responseData.data.itemStock.items[0]['batchNumber'] !== null){
              setBatchNumberOptions(responseData?.data?.itemStock?.items)
              setSerialNumberOptions([])
            } else{
               
              toast.error( "Batch Number Not Available" , {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              });
            }
           
          } else if(serial_or_batch === "serial" && responseData.data.itemStock.items.length > 0){ 
            setSerialNumberOptions(responseData.data.itemStock.items[0]['serialNumber'])
            setBatchNumberOptions([])
          }
        } else{ 
              let errorData = ShowErrorNoties(responseData.errors) 
              toast.error( errorData , {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              });
        }
      }
     
       
    };

    // retun Null; pass the edit id to GetEditData Function
    useEffect(()=>{ 
        if (posItemEdit !== ""){
          GetEditData(posItemEdit.id)
        }
    },[posItemEdit]) 
  
    // return Null; onChange partCode or part Name
    function selectdataonchange(option, identity = true) {  
      /* 
         partCode or PartName it set batch or Serial and 
         pass the value to filtertheData then get options
      */
       if (identity){
        if (option.batchNumber){
          setSerialOrBatch('batch')
          filtertheData(option.value, "batch")  
        } else if(option.serial){
          setSerialOrBatch('serial')
          filtertheData(option.value, "serial")  
        } else{
          filtertheData(option.value, "")  
          setSerialOrBatch('')
        }
        setRateData(option.rate) 
        setSelectUom({ value: option?.uom?.id , label: option?.uom?.name});
       } else{
        if (option.batchNumber){
          setSerialOrBatch('batch')
          filtertheData(option.id, "batch")  
        } else if(option.serial){
          setSerialOrBatch('serial')
          filtertheData(option.id, "serial")  
        } else{
          filtertheData(option.id, "")  
          setSerialOrBatch('')
        }
       }
      
    }

    // return Null;  
    function resetData( ) {
      setSeleteItempartnumber("")
      setselectItemPartName("")
      setSelectUom("")
      setqtydata("")
      setRateData("")
      setPosItemEdit('')
      setSelectBatchNumber([])
      setSelectSerialNumber([])
      setSerialOrBatch('')
      setInitialValuePosItem({
        partCode:"",
        partName:"",
        Description : "",
        qty : "",
        rate : "",
        uom : "",
        Amount : "",
        store : "",
        BatchNo : "",
        Serialnum: "",
        getRate : "",
        createdby :""
        })
    }

    // handle submit 
    let error = ""
    const handleSubmit=async (values, { setSubmitting, resetForm, setErrors, setFieldError })=>{ 
      if(serialOrBatch === "batch"){ 
          if (values['BatchNo'] === "") {  
            setFieldError ('BatchNo', 'Batch Number is required');
            error = 'Batch Number is required'
          } else { 
            setFieldError ('BatchNo', null);
            error = ''
          } 
      }  
      if(serialOrBatch === 'serial'){  
          if(values['Serialnum'] === ""){
            setFieldError ('Serialnum', 'Serial Number is required');
        
            error = 'Serial Number is required'
          } else{
                console.log(values);
            if(values['Serialnum'].length === Number(values['qty'])){
              error = ""
            } else{
            
              error = "Serial Number and qty in missmatch"
              setFieldError ('Serialnum', 'Serial Number and qty in missmatch');
            }
          }
      }   

      // console.log(qtydata);
      const savedata = {
        id : posItemEdit ? Number(posItemEdit.id) : "",
        partCode : Number(values['partCode']),
        description : values['Description'],
        qty : Number(values['qty']),
        rate : Number(rateData),
        uom : Number(values['uom']),
        posItemComboBool : itemComboBool_,
        amount :  (Number(rateData) * Number(values['qty'])), 
        batch : values['BatchNo'] ? Number(values['BatchNo']) : "",
        serial : values['Serialnum'] ? values['Serialnum'] : [] ,
        gstRate : values['getRate'],
        modifiedBy : posItemEdit? Number(userId) : "",
        createdBy :posItemEdit ? Number(initialValuePosItem?.createdby) : Number(userId),
      } 
      if(error === ""){  
        if (serialOrBatch === "batch"){  
          SaveTheValuesbatch(savedata, values['getRate'])  
        } else if (serialOrBatch === 'serial'){
          SaveSerialNumber(savedata, values['getRate'])
        } else{ 
          SaveNoneBs(savedata, values['getRate'])
        }
           
      }
    
    }
    // retun null; in handleSubmit it did not retun promiss os after close to reset data and clone model we use this useEffect
    useEffect(()=>{ 
      if (isDataSave){
      //  resetForm();
          handlepositemclose()
          resetData()
          setisDataSave(false)
      }
     },[isDataSave])
   
    const [isShowStockStatementModal, setShowStockStatementModal] = useState(false)
    const [selectedPartNo, setSelectedPartNo] = useState(null);
    const [selectedStockStatementDetails, setSelectedStockStatementDetails] = useState([])

    const handleCloseStockStatementModal = () => {
      setSelectedPartNo(null)
      setShowStockStatementModal(false)
    }

    const handleShowStockStatementModal = () => {
      let query = QueryData.stockIdFetchQuery(seleteItempartnumber?.value, selectedStore.value)
      axiosInstance.post('/itemmaster/graphql', { query: query })
      .then((res) => {
          let stock_ids = []
          stock_ids = res?.data?.data?.stockIds?.items;
          console.log(stock_ids);
          let construct_stock_dict = {
            stockId: [],
            itemPartCode: '',
            itemName: '',
            description: '',
            batchNumber: '',
            serial: '',
          }
          if(stock_ids.length > 0){
            construct_stock_dict['partCode'] = stock_ids[0]?.partNumber?.['itemPartCode']
            construct_stock_dict['partName'] = stock_ids[0]?.partNumber?.['itemName']
            construct_stock_dict['description'] = stock_ids[0]?.partNumber?.['description']
            construct_stock_dict['isBatch'] = stock_ids[0]?.partNumber?.['batchNumber']
            construct_stock_dict['isSerial'] = stock_ids[0]?.partNumber?.['serial']
          }
          construct_stock_dict['stockId'] = stock_ids?.map(item => item.id)
          setSelectedStockStatementDetails(construct_stock_dict)
          setShowStockStatementModal(true)
      }).catch((error) => {
        // Handle errors here
        console.error('Error fetching data:', error);
      });
      setSelectedPartNo(seleteItempartnumber?.value)
    }

  return (
    <>
    <ToastContainer/>
        <Modal show={positemAdd} onHide={()=>{
            handlepositemclose()
            resetData()
            }} size="lg" centered   backdrop="static">
            <Modal.Header closeButton>
                 <Modal.Title>Items Details </Modal.Title>
            </Modal.Header>
            <Modal.Body> 
              <Formik 
                  initialValues={initialValuePosItem}
                  validationSchema = {PosItemSchema}
                  onSubmit={handleSubmit} 
                  >
                  {(formikProps)=>(
                      <Form>
                          <p hidden>{formikProps.values.createdby = userId}</p>
                          {/* new ui code starts */}
                          <div>
                            <div className='row mx-2' >
                                <div className="col-6 staan-input-group"> 
                                    <Select name="partCode" 
                                    onInputChange={(e)=>{getOptions(e, "itemMasterOptionFetchQuery", "itemPartCode")}}
                                      options={itemmasterPartCodeOptions.map((item)=>({value: item.id , label:  item.itemPartCode, 
                                        name : item.itemName , dec : item.description, uom : item.itemUom, rate : item.itemMrp,
                                        batchNumber: item.batchNumber , serial : item.serial, gstRate: item.itemHsn?.gstRates?.rate,
                                        itemComboBool : item.itemComboBool,
                                      }))}
                                      value={seleteItempartnumber}
                                      onChange={(option) => {
                                          if (option) {     
                                              selectdataonchange(option);
                                              handlepartnumberChange(option);
                                              handlepartnameChange({ value: option.value , label:  option.name }); 
                                              formikProps.setFieldValue('getRate', option.gstRate);
                                              formikProps.setFieldValue("Description", option.dec);
                                              formikProps.setFieldValue("rate",option.rate);
                                              formikProps.setFieldValue('uom', option?.uom?.id);                                              
                                              formikProps.setFieldValue('partCode', option.value);
                                              formikProps.setFieldValue('partName', option.value);
                                              formikProps.setFieldValue('store', selectedStore.value);
                                              formikProps.setFieldValue('BatchNo', "");
                                              formikProps.setFieldValue('Serialnum', []);
                                              handleSelectBatchNumber({ value: "" , label:  "" })
                                              handleSelectSerialNumber("");
                                              formikProps.setFieldValue('itemComboBool', option.itemComboBool)
                                              setItemComboBool_(option.itemComboBool)
                                      }}}
                                      className='mt-3'
                                      styles={customSelectStyle}
                                    />
                                    <label htmlFor="partCode" className='staan-form-input-label pt-1 px-1 mx-2 text-primary' 
                                      role="button" 
                                      onClick={handleShowStockStatementModal}
                                    >Part Code<i className="fa-solid fa-up-right-from-square ms-2"></i></label>
                                    {formikProps.touched.partCode && formikProps.errors.partCode && (
                                        <small>{formikProps.errors.partCode}</small>
                                    )}
                                </div>
                                <div className="col-6 staan-input-group">
                                    <Select name="PartName"  
                                        onInputChange={(e)=>{getOptions(e, "itemMasterOptionFetchQuery", "itemName")}}
                                        options={itemmasterPartNameOptions.map((item)=>({value: item.id , label:  item.itemName, 
                                          partcode : item.itemPartCode , dec : item.description, uom : item.itemUom, rate : item.itemMrp,
                                          batchNumber: item.batchNumber , serial : item.serial , gstRate: item.itemHsn?.gstRates?.rate,
                                          itemComboBool : item.itemComboBool
                                        }))}
                                        value={selectItemPartName}
                                        onChange={(option) => {
                                            if (option) { 
                                                selectdataonchange(option)
                                                handlepartnameChange(option)
                                                handlepartnumberChange({value: option.value , label:  option.partcode});
                                                formikProps.setFieldValue('getRate', option.gstRate);
                                                formikProps.setFieldValue("Description", option.dec)
                                                formikProps.setFieldValue("rate",option.rate)
                                                formikProps.setFieldValue('uom', option.uom.id);                                              
                                                formikProps.setFieldValue('partCode', option.value);
                                                formikProps.setFieldValue('partName', option.value);
                                                formikProps.setFieldValue('store', selectedStore.value);
                                                formikProps.setFieldValue('BatchNo', "");
                                                formikProps.setFieldValue('Serialnum', []);
                                                handleSelectBatchNumber({ value: "" , label:  "" })
                                                handleSelectSerialNumber("");
                                                formikProps.setFieldValue('itemComboBool', option.itemComboBool)
                                            }
                                          }}
                                          className='mt-3'
                                          styles={customSelectStyle}
                                        />
                                        <label htmlFor="PartName"   className='staan-form-input-label pt-1 px-1 mx-2'>Part Name</label>
                                        {formikProps.touched.partName && formikProps.errors.partName && (
                                            <small>{formikProps.errors.partName}</small>
                                        )}
                                </div>
                            </div>
                            <div className='row mx-2 my-2'>
                                <div className="col-12 staan-input-group">
                                      <Field type='text' name='Description' value={formikProps.values.Description} onChange={formikProps.handleChange}  className='w-100 staan-form-input  ' />
                                      <label htmlFor="Description" className='staan-form-input-label pt-2 px-1 mx-2'>Description</label>
                                      {formikProps.touched.Description && formikProps.errors.Description && (
                                          <small>{formikProps.errors.Description}</small>
                                      )}
                                </div>
                            </div>
                            <div className='row mx-2 my-2'>
                                <div className="col-2 staan-input-group">
                                    <Field type='text' name='qty' value={qtydata}  autoComplete='off' onChange={(e) => { handleqtydata(e.target.value) 
                                    formikProps.setFieldValue('qty', e.target.value)}} className='w-100 staan-form-input text-center' />
                                    <label htmlFor="Qty" className='staan-form-input-label pt-2 px-1 mx-2'>Qty</label>
                                    {formikProps.touched.qty && formikProps.errors.qty && (
                                        <small>{formikProps.errors.qty}</small>
                                    )}
                                </div>
                                <div className="col-3 staan-input-group">
                                  <Field type='text' name='rate' value={rateData} onChange={(e) => { handlerateData(e.target.value);
                                  formikProps.setFieldValue('rate', e.target.value) }} className='w-100 staan-form-input text-end px-2' />
                                  <label htmlFor="rate" className='staan-form-input-label pt-2 px-1 mx-2'>Rate</label>
                                  {formikProps.touched.rate && formikProps.errors.rate && (
                                          <small>{formikProps.errors.rate}</small>
                                      )}
                                </div>
                                <div className="col-3  staan-input-group">
                                  <Field type='text' name='Amount' value={(rateData * qtydata).toFixed(2)} disabled= {true} onChange={formikProps.handleChange}  className='w-100 staan-form-input text-end' />
                                  <label htmlFor="Amount" className='staan-form-input-label pt-2 px-1 mx-2 text-end px-2'>Amount</label>
                                  {formikProps.touched.Amount && formikProps.errors.Amount && (
                                      <small>{formikProps.errors.Amount}</small>
                                  )}
                                </div>
                                <div className="col-4 staan-input-group">
                                  <Select name="uom"  
                                      // options={ItemUOMdata.map((uom)=>({value: uom.id , label:  uom.name}))}
                                      value={selectUom}
                                      onChange={(option) => {
                                          if (option) { 
                                              handleuomChange(option);
                                          formikProps.setFieldValue('uom', option.value); 
                                          }
                                      }}
                                      className='mt-3'
                                      styles={customSelectStyle}
                                  />
                                  <label htmlFor="UOM" className='staan-form-input-label pt-1 px-1 mx-2'>UOM</label>
                                  {formikProps.touched.uom && formikProps.errors.uom && (
                                      <small>{formikProps.errors.uom}</small>
                                  )}
                                </div>
                            </div>
                            <div className='row mx-2 my-2'>
                                  <div className='col-6 staan-input-group'>
                                      <Select
                                          name="BatchNo" 
                                          options ={batchNumberOptions.map((item)=>({value: item?.batchNumber?.id , label:  item?.batchNumber?.batchNumberName}))}
                                          value={selectBatchNumber}
                                          isDisabled ={serialOrBatch === 'batch' ? false : true}
                                          onChange={(option)=>{
                                              formikProps.setFieldValue('BatchNo', option ? option.value : null)
                                              handleSelectBatchNumber({ value: option.value , label:  option.label })
                                          }}
                                          className='mt-3'
                                          styles={customSelectStyle}
                                        /> 
                                        <label htmlFor="BatchNo" className='staan-form-input-label pt-1 px-1 mx-2'>Batch Number  </label>
                                        {  formikProps.touched.BatchNo &&  formikProps.errors.BatchNo && <small>{formikProps.errors.BatchNo}</small>}
                                  </div> 
                                  <div className='col-6 staan-input-group'> 
                                      <Select
                                          name="Serialnum"  
                                          options ={serialNumberOptions.map((item)=>({value: item.id , label:  item.serialNumber}))}
                                          value={selectSerialNumber}
                                          isDisabled ={serialOrBatch === 'serial' ? false : true}
                                          isMulti
                                          isOptionDisabled= {() => selectSerialNumber?.length >= formikProps.values.qty ?true : false } 
                                          onChange={(options) => { 
                                              formikProps.setFieldValue('Serialnum', options ? options.map(option => option.value)  : []);
                                              handleSelectSerialNumber(options);
                                          }}
                                          className='mt-3'
                                          styles={customSelectStyle}
                                      /> 
                                      <label htmlFor="Serialnum" className='staan-form-input-label pt-1 px-1 mx-2'>Serial Number  </label>
                                  {  formikProps.touched.Serialnum &&  formikProps.errors.Serialnum && <small>{formikProps.errors.Serialnum}</small>}
                                  </div>
                            </div>
                            <div className='row mx-2 mt-4 text-end'>
                                <div>
                                    <button type="submit" onClick={()=>{console.log(formikProps.errors);}} className="btn btn-sm btn-outline-success px-3">Save</button>
                                </div>
                            </div>
                          </div>
                          {/* new ui code ends */}
                      </Form>
                  )}
              </Formik>
              {isShowStockStatementModal && selectedPartNo && <StockStatementDetails 
                isShowSelectedStockStatement = {isShowStockStatementModal}
                handleCloseSelectedStockStatement = {handleCloseStockStatementModal}
                stockStatement = {selectedStockStatementDetails}
                part_id = {selectedPartNo}
                from={'pos'}
              />}
            </Modal.Body>
           
        </Modal>
    
    </>
  )
}

export default PosItemDetails


 
 