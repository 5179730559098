import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { ToastContainer, } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import { countries_list } from '../../../components/CountryWithStates';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import BallTriangleComponent from '../../../components/loading/BallTriangle';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import DataContext from '../../../context/ItemMasterContext';
import { employeeCreateMutation, employeeDeleteMutation } from '../../../GraphQLQueries/EmployeeQueryAndMutations/EmployeeMutations';
import { EmployeeFormEditQuery } from '../../../GraphQLQueries/EmployeeQueryAndMutations/EmployeeQuery';
import { deleteDocument } from '../../../GraphQLQueries/ItemMastermutation';
import { DepartmentQuery } from '../../../GraphQLQueries/ItemmasterQuery';
import { companyAddressCreateMutation } from '../../../GraphQLQueries/SupplierQueryAndMutations/SupplierMutations';
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { handleFileUploadToS3 } from '../../../utils/FileUploadToS3';
import { customSelectStyle, removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import { EmployeeSchema } from '../../../validations/EmployeeValidation';

const EmployeeForm = ({employeeValue, setEmployeeValue, setLoad}) => {
    const {screenSize, CommanFetchForAllUser, allUsersList,userId,handltoastDeleteConfomationShow } = useContext(DataContext)
    const [initialEmployee, setInitialEmployee] = useState({
    id: "",
    employeeId:"",
    employeeName: "",
    department: "",
    userId: "",
    educationQualification: "",
    designation: "",
    email: "",
    mobile:"",
    altMobile: "",
    aadhaarNo: "",
    panNo: "",
    remark: "",
    permanentAddress: "",
    permanent_Address_Line_1 :"",
    permanent_Address_Line_2 :"",
    permanent_City :"",
    permanent_pincode :"",
    permanent_Country :"",
    permanent_State :"",
    presentAddress: "",
    present_Address_Line_1 :"",
    present_Address_Line_2 :"",
    present_City :"",
    present_pincode :"",
    present_Country :"",
    present_State :"",
    document: [],
    bankAccountNo: "",
    bankName: "",
    branch: "",
    ifscCode: "",
    createdBy: userId? Number(userId): "",
    modifiedBy: "",
    userProfile:'',
    })
    const [formKey, setFormKey] = useState(0)
    const DocInputRef = useRef(null);
    const ImageInputRef = useRef(null);
    const [docId , setDocId] = useState([])
    const [selectedDoc , setSelectedDoc ] = useState([]);
    const CountryOptions = countries_list.map(Country => Country.countryName)
    const [optionsList, setOptionsList] = useState({"departmentList":[], presentStateList:[], permanentStateList:[]})
    const [edit, setEdit] = useState("")
    const [deleteData, setDeleteData] = useState({Name:"",id:""})
    
     // Redux
    const dispatch = useDispatch();
    const Options = useSelector(state => state.itemGroup.data);

    // get data by Redux
    useEffect(()=>{
        if (Options.name === "Employeedepartmant"){
            setOptionsList(prve=>({
                ...prve, departmentList :  Options?.value?.items?.map(item => ({ value: item.id, label: item.name}))
            }))
        }
    },[Options])

    //call edit data Functions
    useEffect(()=>{
        if (employeeValue?.employeeIsShow && employeeValue?.editData !== null && employeeValue?.editData?.id){
            fetchEditData(employeeValue?.editData?.id)
            setEdit(true)
        } else{
            setEdit(false)
        }
    },[employeeValue?.employeeIsShow, employeeValue?.editData])

     // return  null ; update the image in aws s3
    const handleFileInputChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const maxSizeInMB = 2;
            const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
            if (file.size > maxSizeInBytes) {
                showErrorToast(true,"error", "File size must be less than 2MB.")
                return; // Stop execution if file size exceeds 2MB
            }
          // Check image dimensions
            const image = new Image();
            image.onload = () => {
            if (image.width > 160 || image.height > 160) {
                showErrorToast(true,"error", "Image dimensions must be 160x160 pixels.")
              return; // Stop execution if dimensions are incorrect
            } else {
              // If size and dimensions are valid, proceed with the upload
                try {
                    handleFileUploadToS3(file, 'UserImages')
                        .then(uploaded_file_url => {
                        const mutation_query = `
                            mutation MyMutation($image: String!) {
                                imageCreateMutation(image: $image) { 
                                imageType{
                                    id
                                    image
                                }
                                success
                                errors
                                }
                            }`;
                        const variables = {
                        image: uploaded_file_url,
                        };
                        axiosInstance.post('/itemmaster/graphql', {
                        query: mutation_query,
                        variables: variables,
                        }).then(response => {
                        let responceData = response?.data?.data?.imageCreateMutation;
                        if (responceData?.success) {
                            let image_id = responceData?.imageType?.id;
                            setEmployeeValue(prve=>({...prve,ImageId:image_id, Image:file }))
                        } else {
                            let filiterError = ShowErrorNoties(responceData.errors);
                            showErrorToast(true,"error", filiterError)
                            setEmployeeValue(prve=>({...prve,ImageId:"", Image:"" }))
                        }
                        }).catch(error => {
                            console.error('Error uploading image:', error);
                        });
                    }).catch(err => {
                        alert('Failed to Upload Image. try again');
                    });
                } catch (error) {
                    let filiterError = ShowErrorNoties(error);
                    alert(filiterError);
                }
            }
            };
            image.onerror = () => {
                showErrorToast(true,"error", "Invalid image file")
            };
            image.src = URL.createObjectURL(file);
        } else {
            setEmployeeValue(prve=>({...prve,ImageId:"", Image:"" }))
        }
    };
    // update the Doc in s3 and saved in data base
    const handleDocSelect = async(e)=>{
        let selectedFiles = e.target.files
        if (selectedFiles.length > 4) {
          showErrorToast(true,"error", "You can only upload up to 4 documents.")
          return;
        }
        setDocId([])
        const maxFileSizeMB = 5;
        const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
        let validFiles = [];
  
        for (let doc of selectedFiles) {
          if (doc.size > maxFileSizeBytes) {
            showErrorToast(true,"error", `${doc.name} exceeds the 5MB size limit.`)
            continue;
          }
          validFiles.push(doc);
        }
        if (validFiles.length === selectedFiles.length){
            for (let doc of Array.from(e.target.files)) {
                try{
                handleFileUploadToS3(doc, 'UserDocuments')
                .then(uploaded_file_url => {
                    const mutation_query = `
                    mutation MyMutation($document: String!) {
                    documentCreateMutation(document: $document) {
                        documentType{
                        id 
                        }
                        success
                        errors
                    }
                    }`
                    const variables = {
                    document: uploaded_file_url,
                    };
                    axiosInstance.post('/itemmaster/graphql', {
                    query: mutation_query,
                    variables: variables,
                })
                .then(response => {
                    const responseData = response?.data?.data?.documentCreateMutation;
                    if (responseData?.success) {
                        const document_id = responseData?.documentType?.id;
                        let extracted_data = doc
                        extracted_data['id'] = document_id
                        setSelectedDoc(prev => [...prev, extracted_data]);
                        setDocId(prev => [...prev, document_id]);
                    } else {
                        const filteredError = ShowErrorNoties(responseData.errors);
                        showErrorToast(true,"error", filteredError)
                    }
                })
                .catch(error => {
                    const filteredError = ShowErrorNoties(error);
                    showErrorToast(true,"error", filteredError)
                });
                })
                .catch(err => {
                    const filteredError = ShowErrorNoties(err);
                    showErrorToast(true,"error", filteredError)
                })
                } catch (error){
                    const filteredError = ShowErrorNoties(error);
                    showErrorToast(true,"error", filteredError)
                }
                
            }
        } else {
            showErrorToast(true,"error", "Some files were invalid and were not uploaded. Please check the file size and try again.")
        }
    }

    // return null; dependes on country selete state
    const handleonChangeCountrylist = (Option, ispresentAddress) => {
        const regionsList = countries_list.find(
          Country => Country.countryName === Option.value
        )?.regions
        if (ispresentAddress){
            setOptionsList(prev=>({...prev, "presentStateList": regionsList}))
        } else{
            setOptionsList(prev=>({...prev, "permanentStateList": regionsList}))
        }
        
    }

    // on close clear the all state value
    function close(params) {
        console.log("---->>");
        
        setInitialEmployee({
            id: "",
            employeeId:"",
            employeeName: "",
            department: "",
            userId: "",
            educationQualification: "",
            designation: "",
            email: "",
            mobile:"",
            altMobile: "",
            aadhaarNo: "",
            panNo: "",
            remark: "",
            permanentAddress: "",
            permanent_Address_Line_1 :null,
            permanent_Address_Line_2 :null,
            permanent_City :null,
            permanent_pincode :null,
            permanent_Country :null,
            permanent_State :null,
            presentAddress: "",
            present_Address_Line_1 :null,
            present_Address_Line_2 :null,
            present_City :null,
            present_pincode :null,
            present_Country :null,
            present_State :null,
            document: [],
            bankAccountNo: "",
            bankName: "",
            branch: "",
            ifscCode: "",
            createdBy: Number(userId),
            modifiedBy: "",
            userProfile: "",
        })
        setEmployeeValue({
            employeeIsShow:false,
        department:"",
        userid:"",
        present_Country:"",
        present_State:"",
        permanent_Country:"",
        permanent_State:"",
        ImageId:"",
        Image:"",
        editData:"",
        })
        setDocId([])
        setSelectedDoc([])
        setLoad()
    }

    // on edit fetch The Value from dataBase
    async function fetchEditData(id) {
        
        try{
        const response = await axiosInstance.post(`/itemmaster/graphql`, {query: EmployeeFormEditQuery(`id:${id}`) });
        const reponseData = response?.data?.data?.allEmployee?.items?.[0]
        if (reponseData){
            setInitialEmployee({
                id: reponseData?.id? Number(reponseData?.id) : "",
                employeeId:reponseData?.employeeId,
                employeeName: reponseData?.employeeName,
                department: reponseData?.department?.id ? Number(reponseData?.department?.id): "",
                userId: reponseData?.userId?.id ? Number(reponseData?.userId?.id) : "",
                educationQualification: reponseData?.educationQualification,
                designation: reponseData?.designation,
                email: reponseData?.email,
                mobile:reponseData?.mobile,
                altMobile: reponseData?.altMobile,
                aadhaarNo: reponseData?.aadhaarNo ? reponseData?.aadhaarNo : "",
                panNo: reponseData?.panNo ? reponseData?.panNo : "",
                remark: reponseData?.remark,
                permanentAddress: reponseData?.permanentAddress?.id ? Number(reponseData?.permanentAddress?.id): "",
                permanent_Address_Line_1 :reponseData?.permanentAddress?.addressLine1,
                permanent_Address_Line_2 :reponseData?.permanentAddress?.addressLine2,
                permanent_City :reponseData?.permanentAddress?.city,
                permanent_pincode :reponseData?.permanentAddress?.pincode,
                permanent_Country :reponseData?.permanentAddress?.country,
                permanent_State :reponseData?.permanentAddress?.state,
                presentAddress: reponseData?.presentAddress?.id ? Number(reponseData?.presentAddress?.id): "",
                present_Address_Line_1 :reponseData?.presentAddress?.addressLine1,
                present_Address_Line_2 :reponseData?.presentAddress?.addressLine2,
                present_City :reponseData?.presentAddress?.city,
                present_pincode :reponseData?.presentAddress?.pincode,
                present_Country :reponseData?.presentAddress?.country,
                present_State :reponseData?.presentAddress?.state,
                document: [],
                bankAccountNo: reponseData?.bankAccountNo,
                bankName: reponseData?.bankName,
                branch: reponseData?.branch,
                ifscCode: reponseData?.ifscCode,
                createdBy: reponseData?.createdBy?.id?  Number(reponseData?.createdBy?.id): "",
                modifiedBy: "",
                userProfile:reponseData?.userProfile?.id ? Number(reponseData?.userProfile?.id):"",
            })
            let constructed_image_obj = ""
            if(reponseData?.userProfile){
                let image_name_splitted = reponseData?.userProfile?.image.split('/')
                let image_name = image_name_splitted.length > 0 ? image_name_splitted[image_name_splitted.length - 1] : ''

                constructed_image_obj = {
                    id: reponseData?.userProfile?.id,
                    name: image_name,
                    type: 'image/',
                    url: reponseData?.userProfile?.image
                }
      
                
            }
            setEmployeeValue(prev=>({
                ...prev,
                department:{ value: reponseData?.department?.id, label: reponseData?.department?.name},
                userid:{ value: reponseData?.userId?.id, label: reponseData?.userId?.username},
                present_Country:{ value: reponseData?.presentAddress?.country, label: reponseData?.presentAddress?.country},
                present_State:{ value: reponseData?.presentAddress?.state, label: reponseData?.presentAddress?.state},
                permanent_Country:{ value: reponseData?.permanentAddress?.country, label: reponseData?.permanentAddress?.country},
                permanent_State: { value: reponseData?.permanentAddress?.state, label: reponseData?.permanentAddress?.state},
                ImageId:reponseData?.userProfile?.id? Number(reponseData?.userProfile?.id):"",
                Image: constructed_image_obj,
            }))
            let product_documents = reponseData?.document || []
            product_documents = product_documents.map(item => {
                const splitPath = item.documentFile.split('/'); 
                const name = splitPath[splitPath.length - 1]; 
                return {...item, name: name};
            });
            let product_doc_ids = product_documents.map(item => item.id)
            setSelectedDoc(product_documents)
            setDocId(product_doc_ids)
            setFormKey(prve=>prve+1)
        }
        } catch(error){
        // let errorFilter = ShowErrorNoties(error)
        }
        
    }

    // return id; To saved the address
    async function CreateAddress(params) {
        try{
    
            const response = await axiosInstance.post(`/itemmaster/graphql`, {query: companyAddressCreateMutation(params) })
            let reponseData =  response?.['data']?.['data']?.['companyAddressCreateMutation']
            if (reponseData?.success){
                return {id:Number(reponseData?.companyAddressItem?.id), error:null, success:true}
            } else{
                showErrorToast(true,"error", reponseData?.errors)
                return {id:"", error:reponseData?.errors, success:false}
            }
        } catch(error){
            let errorFilter = ShowErrorNoties(error)
            showErrorToast(true,"error", errorFilter)
            return {id:"", error:errorFilter, success:false}
        }
    }

    // return null; update the value
    const handleSubmit = async (values, ) => {
        setEmployeeValue(prev=>({...prev, isLoading:true}))
        
        if (String(values?.['present_Address_Line_1']).trim() !== "" && values?.['present_Address_Line_1'] != null){
            let presentAddress = `addressLine1: "${ (values?.['present_Address_Line_1'])}"
            addressLine2:" ${ (values?.['present_Address_Line_2'])}" city: "${ (values?.['present_City']) }"
            country: "${ (values?.['present_Country']) }" default: false id:   "${values?.['presentAddress']}"  pincode: "${ (values?.['present_pincode'])}"
            state: "${ (values?.['present_State']) }"`
            let presentAddressResponse =  await CreateAddress(presentAddress)
            if (presentAddressResponse?.success){
            values['presentAddress'] = presentAddressResponse?.id
            } else{
            return ""
            }
        }
        if (String(values?.['permanent_Address_Line_1']).trim() !== "" && values?.['permanent_Address_Line_1'] != null){
            let permanentAddress = `addressLine1: "${values?.['permanent_Address_Line_1']}"
            addressLine2: "${values?.['permanent_Address_Line_2'] }" city: "${values?.['permanent_City'] }"
            country: "${values?.['permanent_Country'] }" default: false id:"${values?.['permanentAddress'] }" pincode: "${values?.['permanent_pincode'] }"
            state: "${values?.['permanent_State']}"`
            
            let permanentAddressResponse =  await CreateAddress(permanentAddress)
            if (permanentAddressResponse?.success){
                values['permanentAddress'] = permanentAddressResponse?.id
            } else{
                return ""
            }
        }
        initialEmployee['presentAddress'] =  values?.['presentAddress']
        initialEmployee['permanentAddress'] =  values?.['permanentAddress']
        let duplicateValue = { ...values };
        delete duplicateValue['employeeId']
        delete duplicateValue['present_Address_Line_1'];
        delete duplicateValue['present_Address_Line_2'];
        delete duplicateValue['present_City'];
        delete duplicateValue['present_Country'];
        delete duplicateValue['present_pincode'];
        delete duplicateValue['present_State'];
        delete duplicateValue['permanent_Address_Line_1'];
        delete duplicateValue['permanent_Address_Line_2'];
        delete duplicateValue['permanent_City'];
        delete duplicateValue['permanent_Country'];
        delete duplicateValue['permanent_pincode'];
        delete duplicateValue['permanent_State'];
        duplicateValue['id'] = initialEmployee?.id
        duplicateValue['createdBy'] = userId? Number(userId) : null
        duplicateValue['bankAccountNo'] = String(duplicateValue['bankAccountNo'])
        duplicateValue['mobile'] = String(duplicateValue['mobile'])
        duplicateValue['altMobile'] = String(duplicateValue['altMobile'])
        duplicateValue['userProfile'] = employeeValue?.ImageId ? Number(employeeValue?.ImageId) : ""
        duplicateValue['document'] = docId
        let allow_null = ['id', "userProfile","email","educationQualification","designation","mobile","altMobile","aadhaarNo","panNo","remark",
            "permanentAddress","presentAddress", "userId", "modifiedBy", "bankAccountNo", "bankName", "branch", "ifscCode"]
        let employeeData = removeEmptyValueInObject(duplicateValue, allow_null)
        try{
            const response = await axiosInstance.post(`/itemmaster/graphql`, {query: employeeCreateMutation(employeeData) })
            let reponseData =  response?.['data']?.['data']?.['employeeCreateMutation']
         
            
            if (reponseData?.success){
                showErrorToast(true,"success", "", "Saved Successfully.")
                close()
            } else{
                let errorFilter = ShowErrorNoties(reponseData?.errors)
                showErrorToast(true,"error", errorFilter)
            } 
        } catch(error){
            let errorFilter = ShowErrorNoties(error)
            showErrorToast(true,"error", errorFilter)
        }
        setEmployeeValue(prev=>({...prev, isLoading:false}))
    }
    //Delete the Doc in s3 and saved in data base
    const handleDeleteDocument = (document) => {
        if(document.id){
            let delete_mutation_query = deleteDocument(document.id)
            axiosInstance.post("/itemmaster/graphql", {query: delete_mutation_query})
            .then(res => {
                if(res?.data?.data?.documentDeleteMutation?.success){
                    let document_list = [...selectedDoc]
                    document_list = document_list.filter(item => item?.id !== document?.id)
                    setSelectedDoc(document_list)
                    showErrorToast(true,"success", "Document deleted successfully!.")
                }else{
                    showErrorToast(true,"error", "Failed to delete document!.")
                }
            })
            .catch(err => {
                showErrorToast(true,"error", "Failed to delete document!.")
            })
        }
    }
    // return null; on delete call confomations
    const handledeleteConfomation = (data) => {
        handltoastDeleteConfomationShow();
        setDeleteData({ Name:data?.employeeName, id: data?.id});
    }
      //  after delete clear the data
    const handleDeletedId = (deleted_id) =>{ 
    if (deleted_id){ 
        close()
        setEdit(false)
    } 
    }

    return (
    <Modal
    show={employeeValue?.employeeIsShow}
    backdrop="static"
    keyboard={false}
    fullscreen>
        <ToastContainer/>
        <BallTriangleComponent isshow={employeeValue?.isLoading}/>
        <div  className="row">
            <div className="col-5 m-3" style={{color:"#5CADA9"}}>
                <Modal.Title>
                    <i className='fa-solid fa-arrow-left fa-sm me-2'
                        onClick={close}
                    ></i>Employee
                </Modal.Title>
            </div>
            <div className="col-6 mt-4 text-end" >
            {/* <Can I={"Edit"} a={"Store"}> */}
                <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={!(employeeValue?.editData && edit)} onClick={()=>{setEdit(!edit)}}  ></i>
            {/* </Can> */}
            {/* <Can I={"Delete"} a={"Store"}> */}
                <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " hidden={!(employeeValue?.editData && edit)} 
                onClick={()=>{handledeleteConfomation(initialEmployee)}}
                ></i>
            {/* </Can> */}
            </div>
        </div>
        <hr/>
        <Modal.Body className='mt-3'>
            <Formik initialValues={initialEmployee}
            onSubmit={handleSubmit}
            validationSchema={EmployeeSchema}
            enableReinitialize key={formKey}
            >
                {({errors,   touched, setFieldValue, values,  })=>{
                    return(
                        <Form>
                            {/* Employee Details */}
                            <div className=" row    mx-2">
                                {/* Employee Details left */}
                                <div className="col-12 col-md  card  shadow   py-3 me-1  " >
                                    <div className="row">
                                        <div className="col-12 col-sm-6  staan-input-group">
                                            <Field type='text' name='employeeId' id="employeeId" disabled values={initialEmployee?.employeeId}  className='w-100 staan-form-input'/>
                                            <label htmlFor="employeeId" className='staan-form-input-label pt-1 px-1 mx-2' >Employee Id</label>
                                        </div>
                                        <div className="col-12 col-sm-6  staan-input-group">
                                            <Field type='text' name='employeeName' id="employeeName"  disabled={edit}   className='w-100 staan-form-input'/>
                                            <label htmlFor="employeeName" className='staan-form-input-label pt-1 px-1 mx-2' onClick={()=>{console.log(values);
                                            }}>Employee Name<span className='text-danger'>*</span></label>
                                            {touched.employeeName &&  errors.employeeName && <small>{errors.employeeName}</small>}
                                        </div>
                                        <div className="col-12 col-sm-6  staan-input-group">
                                            <ReactSelect
                                            onInputChange={e => {
                                            e.trim()
                                                ? dispatch(
                                                    fetchOptionsData(
                                                    DepartmentQuery(
                                                        `name: "${e.trim()}"`
                                                    ),
                                                    'Employeedepartmant'
                                                    )
                                                )
                                                : setOptionsList(prve=>({
                                                ...prve, departmentList :[]
                                                }))
                                            }}
                                            isDisabled={edit}
                                            value={employeeValue?.department}
                                            options={optionsList?.departmentList}
                                            name='department'
                                            onChange={(option)=>{
                                                setEmployeeValue(prev=>({...prev,department:option? option : null }))
                                                setFieldValue("department",option?.value ? Number(option?.value) : null )
                                            }}
                                            
                                            className='mt-3'
                                            // isDisabled={edit}
                                            
                                            styles={customSelectStyle}
                                            />
                                            <label    className='staan-form-input-label  px-1 mx-2'>Department<span className='text-danger'>*</span></label>
                                            {touched.department &&  errors.department && <small>{errors.department}</small>}
                                        </div>
                                        <div className="col-12 col-sm-6  staan-input-group">
                                            <Field type='email' name='email' id="email" disabled={edit}   className='w-100 staan-form-input'/>
                                            <label htmlFor="email" className='staan-form-input-label pt-1 px-1 mx-2'>Email</label>
                                            {touched.email &&  errors.email && <small>{errors.email}</small>}
                                        </div>
                                        <div className="col-12 col-sm-6  staan-input-group">
                                            <Field type='text' name='educationQualification' disabled={edit} id="educationQualification"     className='w-100 staan-form-input'/>
                                            <label htmlFor="educationQualification" className='staan-form-input-label pt-1 px-1 mx-2'>Employee Qualification</label>
                                            {touched.educationQualification &&  errors.educationQualification && <small>{errors.educationQualification}</small>}
                                        </div>
                                        <div className="col-12 col-sm-6  staan-input-group">
                                            <Field type='text' name='designation' disabled={edit} id="designation" className='w-100 staan-form-input'/>
                                            <label htmlFor="designation" className='staan-form-input-label pt-1 px-1 mx-2'>Designation</label>
                                            {touched.designation &&  errors.designation && <small>{errors.designation}</small>}
                                        </div>
                                    </div>
                                </div>
                                {/* Employee Details right */}
                                <div className="col-12 mt-3 mt-md-0 col-md   card  shadow  py-3 ms-1   ">
                                    <div className="row">
                                        <div className="col-12 col-sm-6  staan-input-group">
                                            <Field type='number' name='mobile' id="mobile" disabled={edit}    className='w-100 staan-form-input'/>
                                            <label htmlFor="mobile" className='staan-form-input-label pt-1 px-1 mx-2'>Mobile</label>
                                            {touched.mobile &&  errors.mobile && <small>{errors.mobile}</small>}
                                        </div>
                                        <div className="col-12 col-sm-6  staan-input-group">
                                            <Field type='number' name='altMobile' id="altMobile"  disabled={edit}  className='w-100 staan-form-input'/>
                                            <label htmlFor="altMobile" className='staan-form-input-label pt-1 px-1 mx-2'>Alternate Mobile</label>
                                            {touched.altMobile &&  errors.altMobile && <small>{errors.altMobile}</small>}
                                        </div>
                                        <div className="col-12 col-sm-6  staan-input-group">
                                            <Field type='text' name='aadhaarNo' id="aadhaarNo" disabled={edit}    className='w-100 staan-form-input'/>
                                            <label htmlFor="aadhaarNo" className='staan-form-input-label pt-1 px-1 mx-2'>Aadhaar No</label>
                                            {touched.aadhaarNo &&  errors.aadhaarNo && <small>{errors.aadhaarNo}</small>}
                                        </div>
                                        <div className="col-12 col-sm-6  staan-input-group">
                                            <Field type='text' name='panNo' id="panNo" disabled={edit}    className='w-100 staan-form-input'/>
                                            <label htmlFor="panNo" className='staan-form-input-label pt-1 px-1 mx-2'>Pan No</label>
                                            {touched.panNo &&  errors.panNo && <small>{errors.panNo}</small>}
                                        </div>
                                        <div className="  col-12 col-sm-6  staan-input-group">
                                            <Field as="textarea" name='remark' id="remark" disabled={edit}    className='w-100 staan-form-input' rows='4'/>
                                            <label htmlFor="remark" className='staan-form-input-label pt-1 px-1 mx-2'>Remark</label>
                                            {touched.remark &&  errors.remark && <small>{errors.remark}</small>}
                                        </div> 
                                        <div className="col-12 col-sm-6  staan-input-group">
                                            <ReactSelect
                                            onInputChange={e => {CommanFetchForAllUser(e)}}
                                            options={allUsersList}
                                            name='userId'
                                            value={employeeValue?.userid}
                                            className='mt-3'
                                            isDisabled={edit}
                                            onChange={(option)=>{
                                            setFieldValue('userId',option? Number(option?.value) : null )
                                            setEmployeeValue(prve=>({...prve,
                                                userid : option? option : null}))
                                            }}
                                            styles={customSelectStyle}
                                            />
                                            <label   className='staan-form-input-label  px-1 mx-2'>User</label>
                                            {touched.userId &&  errors.userId && <small>{errors.userId}</small>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Employee Address */}
                            <div className="row  mx-2 mt-3">
                                {/* Employee Address left */}
                                <div className="col-12 col-md card  shadow   py-3 me-1">
                                    <div className="row">
                                        <h6>Present Address</h6>
                                        <div className="col-12 col-sm-6 staan-input-group">
                                                <Field type='text' name='present_Address_Line_1' disabled={edit} id="present_Address_Line_1"  className='w-100 staan-form-input'/>
                                                <label htmlFor='present_Address_Line_1' className='staan-form-input-label pt-1 px-1 mx-2'>
                                                    Address Line 1
                                                </label>
                                                {touched.present_Address_Line_1 && errors.present_Address_Line_1 && (
                                                    <small>{errors.present_Address_Line_1}</small>
                                                )}    
                                            </div>
                                            <div className="col-12 col-sm-6 staan-input-group">
                                                <Field type='text' name='present_Address_Line_2' disabled={edit} id="present_Address_Line_2" className='w-100 staan-form-input'/>
                                                <label htmlFor='present_Address_Line_2' className='staan-form-input-label pt-1 px-1 mx-2'>
                                                    Address Line 2
                                                </label>
                                                {touched.present_Address_Line_2 && errors.present_Address_Line_2 && (
                                                    <small>{errors.present_Address_Line_2}</small>
                                                )}    
                                            </div>
                                            <div className="col-12 col-sm-6 staan-input-group">
                                                <Field type='text' name='present_City'  disabled={edit} id ="present_City" className='w-100 staan-form-input'/>
                                                <label htmlFor='present_City' className='staan-form-input-label pt-1 px-1 mx-2'>
                                                    City
                                                </label>
                                                {touched.present_City && errors.present_City && (
                                                    <small>{errors.present_City}</small>
                                                )}
                                            </div>
                                            <div className="col-12 col-sm-6 staan-input-group">
                                                <ReactSelect
                                                    isDisabled={edit}
                                                    name='present_Country'
                                                    styles={customSelectStyle}
                                                    options={CountryOptions.map(country => ({
                                                        value: country,
                                                        label: country
                                                    }))}
                                                    value={employeeValue?.present_Country}
                                                    onChange={option=>{
                                                        handleonChangeCountrylist(option, true)
                                                        setFieldValue('present_Country' ,option?  (option?.value) : null )
                                                        setEmployeeValue(prve=>({...prve, "present_Country" : option? option : null}))
                                                    }}
                                                    className='my-3'
                                                />
                                                <label   className='staan-form-input-label pt-1 px-1 mx-2'>
                                                    Country
                                                </label>
                                                {touched.present_Country && errors.present_Country && (
                                                    <small>{errors.present_Country}</small>
                                                )}
                                            </div>
                                            <div className="col-12 col-sm-6 staan-input-group">
                                                <ReactSelect
                                                    isDisabled={edit}
                                                    name='present_State'
                                                    styles={customSelectStyle}
                                                    className='my-3'
                                                    options={optionsList?.presentStateList?.map(state => ({
                                                        value: state.name,
                                                        label: state.name
                                                    }))}
                                                    value={employeeValue?.present_State}
                                                    onChange={option=>{
                                                        setFieldValue('present_State' ,option?  (option?.value) : null )
                                                        setEmployeeValue(prve=>({...prve, "present_State" : option? option : null}))
                                                    }}
                                                />
                                                <label   className='staan-form-input-label pt-1 px-1 mx-2'>
                                                    State
                                                </label>
                                                {touched.present_State && errors.present_State && (
                                                    <small>{errors.present_State}</small>
                                                )}
                                            </div>
                                            <div className="col-12 col-sm-6 staan-input-group">
                                                <Field type='text' name='present_pincode' isDisabled={edit} id ="present_pincode" className='w-100 staan-form-input'/>
                                                <label htmlFor='present_pincode' className='staan-form-input-label pt-1 px-1 mx-2'>
                                                    Pincode
                                                </label>
                                                {touched.present_pincode && errors.present_pincode && (
                                                    <small>{errors.present_pincode}</small>
                                                )}
                                            </div>
                                    </div>
                                </div>
                                 {/* Employee Address right */}
                                <div className="col-12 mt-3 mt-md-0 col-md   card  shadow  py-3 ms-1">
                                    <div className="row">
                                        <h6>Permanent Address</h6>
                                            <div className="col-12 col-sm-6 staan-input-group">
                                                <Field type='text' name='permanent_Address_Line_1' id="permanent_Address_Line_1" disabled={edit}  className='w-100 staan-form-input'/>
                                                <label htmlFor='permanent_Address_Line_1' className='staan-form-input-label pt-1 px-1 mx-2'>
                                                    Address Line 1
                                                </label>
                                                {touched.permanent_Address_Line_1 && errors.permanent_Address_Line_1 && (
                                                    <small>{errors.permanent_Address_Line_1}</small>
                                                )}    
                                            </div>
                                            <div className="col-12 col-sm-6 staan-input-group">
                                                <Field type='text' name='permanent_Address_Line_2' id="permanent_Address_Line_2" disabled={edit} className='w-100 staan-form-input'/>
                                                <label htmlFor='permanent_Address_Line_2' className='staan-form-input-label pt-1 px-1 mx-2'>
                                                    Address Line 2
                                                </label>
                                                {touched.permanent_Address_Line_2 && errors.permanent_Address_Line_2 && (
                                                    <small>{errors.permanent_Address_Line_2}</small>
                                                )}    
                                            </div>
                                            <div className="col-12 col-sm-6 staan-input-group">
                                                <Field type='text' name='permanent_City' id ="permanent_City" disabled={edit} className='w-100 staan-form-input'/>
                                                <label htmlFor='permanent_City' className='staan-form-input-label pt-1 px-1 mx-2'>
                                                    City
                                                </label>
                                                {touched.permanent_City && errors.permanent_City && (
                                                    <small>{errors.permanent_City}</small>
                                                )}
                                            </div>
                                            <div className="col-12 col-sm-6 staan-input-group">
                                                <ReactSelect
                                                    name='permanent_Country'
                                                    isDisabled={edit}
                                                    styles={customSelectStyle}
                                                    className='my-3'
                                                    options={CountryOptions.map(country => ({
                                                        value: country,
                                                        label: country
                                                    }))}
                                                    value={employeeValue?.permanent_Country}
                                                    onChange={option=>{
                                                        handleonChangeCountrylist(option, false)
                                                        setFieldValue('permanent_Country' ,option?  (option?.value) : null )
                                                        setEmployeeValue(prve=>({...prve, "permanent_Country" : option? option : null}))
                                                    }}
                                                />
                                                <label   className='staan-form-input-label pt-1 px-1 mx-2'>
                                                    Country
                                                </label>
                                                {touched.permanent_Country && errors.permanent_Country && (
                                                    <small>{errors.permanent_Country}</small>
                                                )}
                                            </div>
                                            <div className="col-12 col-sm-6 staan-input-group">
                                                <ReactSelect
                                                    name='permanent_State'
                                                    styles={customSelectStyle}
                                                    isDisabled={edit}
                                                    className='my-3'
                                                    value={employeeValue?.permanent_State}
                                                    options={optionsList?.permanentStateList?.map(state => ({
                                                        value: state.name,
                                                        label: state.name
                                                    }))}
                                                    onChange={option=>{
                                                        setFieldValue('permanent_State' ,option?  (option?.value) : null )
                                                        setEmployeeValue(prve=>({...prve, "permanent_State" : option? option : null}))
                                                    }}
                                                />
                                                <label   className='staan-form-input-label pt-1 px-1 mx-2'>
                                                    State
                                                </label>
                                                {touched.permanent_State && errors.permanent_State && (
                                                    <small>{errors.permanent_State}</small>
                                                )}
                                            </div>
                                            <div className="col-12 col-sm-6 staan-input-group">
                                                <Field type='text' name='permanent_pincode' disabled={edit} id ="permanent_pincode" className='w-100 staan-form-input'/>
                                                <label htmlFor='permanent_pincode' className='staan-form-input-label pt-1 px-1 mx-2'>
                                                    Pincode
                                                </label>
                                                {touched.permanent_pincode && errors.permanent_pincode && (
                                                    <small>{errors.permanent_pincode}</small>
                                                )}
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-2 mt-3">
                                <div className="col-12 col-md card  shadow   py-3 me-1">
                                    <div className="row m-3" >
                                        <div className='d-flex justify-content-between'>  
                                            <div className="col-4 test-center p-2" onClick={()=>{console.log(employeeValue?.selectedDocId);
                                            }} >
                                            Document (.csv,.xlsx,.pdf,.doc)
                                            </div>  
                                            {/* <div className="col-4 text-end">
                                                <AiTwotoneCloseCircle className='fs-5' 
                                                // onClick={(e)=>{
                                                //     handleDeleteAllDocument()
                                                // }}
                                                />
                                            </div> */}
                                        </div>
                                        <div className='d-flex justify-content-between' > 
                                            <div style={{width: '35%'}}>
                                                <ul>
                                                {
                                                    selectedDoc?.map((file, index) => {
                                                        return <> 
                                                            <li key={index} className='d-flex justify-content-between mb-1 text-start'>
                                                                <span><i className="fa-regular fa-circle"></i></span>
                                                                <a href={file.documentFile} download={file.documentFile} style={{cursor: 'pointer'}}>{decodeURI(file.name)}</a>
                                                                <span 
                                                                    className='ms-2 me-4 text-danger' 
                                                                    style={{cursor: 'pointer'}}
                                                                    onClick={()=>{ if (!edit){
                                                                        handleDeleteDocument(file)
                                                                    }}}
                                                                >
                                                                    <i className="fa-regular fa-trash-can"></i>
                                                                </span>
                                                            </li>
                                                        </>
                                                    })
                                                }
                                                </ul>
                                            </div>
                                            <div  className="border rounded document" 
                                                onClick={()=>{DocInputRef.current.click()}}
                                                style={ {
                                                    width: '30%',
                                                    height: `${screenSize?.height/110}rem`,
                                                    overflow:"auto", position:"relative"} }
                                            >
                                                <Field
                                                    onChange={(e)=>{handleDocSelect(e)}}
                                                    innerRef={DocInputRef}
                                                    multiple accept=".csv,.xlsx,.pdf,.doc,.docx"
                                                    className="form-control form-control-sm" hidden id="formFileSm" type="file" name="fieldName" />
                                                <div className='d-flex justify-content-center align-items-center' style={{height:"100%"}}>
                                                    <div className='text-center'>
                                                        <br/>
                                                        <p className='text-center'>Click Here To Select File</p>
                                                        <p>  Size : 5MB</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  className="border rounded document" onClick={()=>{if (!edit){
                                                    ImageInputRef.current.click()
                                            } }} style={{height:`${screenSize?.height/110}rem`,width:"10rem"}} >
                                                <Field innerRef={ImageInputRef}  onChange={(e)=>{
                                                        handleFileInputChange(e)
                                                    }}
                                                    multiple
                                                    accept=".jpg,.png,.jpeg"
                                                    hidden
                                                    className="form-control form-control-sm"   id="formFileSm" type="file" name="fieldName" />
                                                {employeeValue?.Image?.name ?  employeeValue?.Image?.type.startsWith('image/') && (
                                                    <div className="" style={{width:"10rem", height:"10rem"}}>
                                                        <img
                                                        src={employeeValue?.Image?.url  ? employeeValue?.Image?.url : URL.createObjectURL(employeeValue?.Image)}
                                                        alt={`Profile picture of ${employeeValue?.name}`}
                                                        style={{ maxWidth: "90%", maxHeight: "90%", objectFit: "contain", padding: "1px" }}
                                                        />

                                                    </div>
                                                ) : <div className='text-center'>
                                                <p className='text-center pt-3'>Click Here To Select Image</p>
                                                <p> Area : 160 X 160
                                                    <br/>
                                                    Size : 2MB
                                                </p>
                                            </div> }
                                            
                                            </div>
                                        </div>
                                       
                                        
                                    </div>
                                </div>
                                <div className='col-12 mt-3 mt-md-0 col-md   card  shadow  py-3 ms-1'>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 staan-input-group">
                                            <Field type='number' name='bankAccountNo' disabled={edit} id="bankAccountNo"  className='w-100 staan-form-input'/>
                                            <label htmlFor='bankAccountNo' className='staan-form-input-label pt-1 px-1 mx-2'>
                                                Bank Account No
                                            </label>
                                            {touched.bankAccountNo && errors.bankAccountNo && (
                                                <small>{errors.bankAccountNo}</small>
                                            )}
                                        </div>
                                        <div className="col-12 col-sm-6 staan-input-group">
                                            <Field type='text' name='ifscCode' id="ifscCode" disabled={edit}  className='w-100 staan-form-input'/>
                                            <label htmlFor='ifscCode' className='staan-form-input-label pt-1 px-1 mx-2'>
                                               IFSC Code
                                            </label>
                                            {touched.ifscCode && errors.ifscCode && (
                                                <small>{errors.ifscCode}</small>
                                            )}
                                        </div>
                                        <div className="col-12 col-sm-6 staan-input-group">
                                            <Field type='text' name='bankName' id="bankName" disabled={edit} className='w-100 staan-form-input'/>
                                            <label htmlFor='bankName' className='staan-form-input-label pt-1 px-1 mx-2'>
                                                Bank Name
                                            </label>
                                            {touched.bankName && errors.bankName && (
                                                <small>{errors.bankName}</small>
                                            )}    
                                        </div>
                                        <div className="col-12 col-sm-6 staan-input-group">
                                            <Field type='text' name='branch' id="branch" disabled={edit}  className='w-100 staan-form-input'/>
                                            <label htmlFor='branch' className='staan-form-input-label pt-1 px-1 mx-2'>
                                                Branch
                                            </label>
                                            {touched.branch && errors.branch && (
                                                <small>{errors.branch}</small>
                                            )}    
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='text-end mx-2 mt-3'  >
                                <button type="submit"
                                disabled={edit}
                                className="btn btn-sm btn-outline-success fw-bold px-5 py-2 mt-2 shadow-sm">Save</button>
                            </div>
                        </Form>
                    )
                }}

            </Formik>
        </Modal.Body>
        {deleteData?.Name  && <DeleteConformation
           handleDeletedId={(id)=>{handleDeletedId(id)}}
           deleteData={deleteData} 
           fullquery={employeeDeleteMutation(deleteData?.id)}
           queryName={"departmentDeleteMutation"}
        />}
        
    </Modal>
    )
}

export default EmployeeForm