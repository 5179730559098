import { Modal } from "react-bootstrap"

const TaxModel = ({showTaxModel,setShowTaxModel,igstTax,sgstTax,cgstTax}) => {
    return (
      <>
        <Modal show={showTaxModel} onHide={() => setShowTaxModel(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Tax</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row mx-1 '>
              <div className=''>
                {igstTax
                  ? Object.entries(igstTax)?.map(([key, value]) => (
                    <div className='row'>
                      <div key={key} className='col-5'>
                        {`Igst @ ${key}%`}
                      </div>
                      <div className='col-5  '>{` : ₹  ${value}`}</div>
                    </div>
                  ))
                  : ''}
              </div>
              <div className=''>
                {sgstTax
                  ? Object.entries(sgstTax)?.map(([key, value]) => (
                    <div className='row  '>
                      <div key={key} className='col-5'>
                        {`sgst @ ${key}%`}
                      </div>
                      <div className='col-5 '>{` : ₹  ${value}`}</div>
                    </div>
                  ))
                  : ''}
              </div>
              <div className=''>
                {cgstTax
                  ? Object.entries(cgstTax).map(([key, value]) => (
                    <div className='row'>
                      <div key={key} className='col-5'>
                        {`cgst @ ${key}%`}
                      </div>
                      <div className='col-5 '>{` : ₹  ${value}`}</div>
                    </div>
                  ))
                  : ''}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    )
  }


  export default TaxModel