import React ,{useContext,useState, useEffect} from 'react' 
import Modal from 'react-bootstrap/Modal';
import DataContext from '../../../context/ItemMasterContext';
import { Formik, Field, Form } from 'formik/dist';
import { CurrencyMasterSchema } from '../../../ItemMaster/validations/itemmaster';
import axiosInstance from '../../../api/axoiss';
import Select from 'react-select';
import { TbHistory } from "react-icons/tb";
import { Bounce,  toast } from 'react-toastify';
import { ShowErrorNoties, customSelectStyle, removeEmptyValueInObject } from '../../../utils/Utils';
import HistoryData from '../../ItemMaster/model/HistoryData';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery"
import { useSelector, useDispatch } from 'react-redux'; 
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { createCurrencyMaster } from '../../../GraphQLQueries/ItemMastermutation';
import Can from '../../../components/authorizationComponents/Can';

const CurrencyMasterFrom = ({editCurrencyMaster, setEditCurrencyMaster, setLoad}) => {
    const {currencyMaster, handleCurrencyMasterClose,userId,setCurrencyMasterData, handltoastDeleteConfomationShow,
      historyIds, setHistoryIds} = useContext(DataContext)
    const [edit, setEdit]= useState(false)
    const [deleteData, setdeleteData] = useState({  Name:"",id:""})
    const [formateOptions , setFormateOptions] = useState([])
    let url = "/itemmaster/CurrencyMaster"
    const dispatch = useDispatch();   
    const OptionsData = useSelector(state => state.itemGroup.data);
    const [initialValues, setInitialValues] = useState({
      id : "",
      Name: "",
      Currency_symbol: "",
      Formate: "",
      Active: true,
      modified_by:  "",
      created_by : "",
      HistoryDetails : ""
    }); 
    async function fetchData(id){
        dispatch(fetchOptionsData(QueryData.formateOption(), "currencyFormate"))
        if (id){
          dispatch(fetchOptionsData(QueryData.CurrencyMasterEdit(id), "CurrencyMasterEdit"))
        }
    }
    useEffect(()=>{
      fetchData() 
    },[])
    
    useEffect(()=>{
       if (OptionsData.name === "currencyFormate"){ 
        setFormateOptions(OptionsData.value.items)
       }
       if (OptionsData.name === "CurrencyMasterEdit"){ 
         let responceData = OptionsData?.value?.items?.[0]
         initialValues.id = responceData?.['id']
         initialValues.Name = responceData?.['name']
         initialValues.Currency_symbol = responceData?.['currencySymbol']
         initialValues.Formate = responceData?.['formate']?.['id']
         initialValues.Active = responceData?.['active']
         initialValues.HistoryDetails =  responceData?.['historyDetails']
         initialValues.created_by = responceData?.['createdBy']?.['id']
         setSelectFormat({value: initialValues.Formate , label: responceData['formate']['formate']})
       }
    },[OptionsData])

    useEffect(() => {
      if(editCurrencyMaster){  
        fetchData(editCurrencyMaster.id) 
         
      setEdit(true)
      } else{
        setEdit(false)
      }
  
    }, [editCurrencyMaster])
    

    const handlesubmit = async (values, { setSubmitting, resetForm })=>{ 
   
        const saveValue = {
          id : editCurrencyMaster? initialValues.id : "",
          name: values['Name'],
          currencySymbol:  values['Currency_symbol'],
          formate: Number(values['Formate']),
          active: values['Active'], 
          modifiedBy : editCurrencyMaster ? Number(userId) : "",
          createdBy: editCurrencyMaster ? Number(initialValues.created_by) : Number(userId),
        }
        let variables = removeEmptyValueInObject(saveValue) 
        if (editCurrencyMaster){ 
          try{ 
            const respones = await axiosInstance.post('/itemmaster/graphql', {query : createCurrencyMaster(variables)});
            const responseData = respones.data.data.currencyMasterCreateMutation
            if (responseData.success){
              resetForm()
              handleCurrencyMasterClose()
              toast.success('Updated', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                })
              reset()
              setLoad()
              setSubmitting(false)
            } else{
              let error = responseData.errors
              let errorData = ShowErrorNoties(error) 
              toast.error( errorData , {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              });
            }
            
          } catch (error){
            let errorData = ShowErrorNoties(error) 
            toast.error( errorData , {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });
             
          }

        }else{
          try{
            const respones = await axiosInstance.post('/itemmaster/graphql', {query : createCurrencyMaster(variables)});
            const responseData = respones.data.data.currencyMasterCreateMutation
            if (responseData.success){
                resetForm()
                handleCurrencyMasterClose()
                setSubmitting(false)
                toast.success('Saved', {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  }); 
                  reset()
                  setLoad()
            } else{
              let error = responseData.errors
              let errorData = ShowErrorNoties(error) 
              toast.error( errorData , {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              });
            }
           
          } catch (error){
            let errorData = ShowErrorNoties(error) 
            toast.error( errorData , {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });
  
          }
        }

    }

    const [selectFormat, setSelectFormat]= useState([])
    const handleFormatChange = (option) => {
      if (option){
        setSelectFormat({value: option.value , label: option.label})
      } else{
        setSelectFormat({value: "" , label: ""})
      } 
    };
    function reset() {
      initialValues.Name = ""
      initialValues.Currency_symbol = ''
      initialValues.Formate = ''
      initialValues.Active = true
      initialValues.SavedBy = userId
      setSelectFormat('')
      setEditCurrencyMaster('')
    } 
  const handledeleteConfomation = (data) => { 
    handltoastDeleteConfomationShow();
   setdeleteData({
    Name:data.name,
    id: data.id
   }); 
 };
 const handleDeletedId = (deleted_id) =>{ 
  if (deleted_id){
    handleCurrencyMasterClose()
    reset()
    setEdit(false)
    setLoad()
  } 
}

  return (
     <>
     <HistoryData
     historyIds={historyIds}
     setHistoryIds= {setHistoryIds}
     />
       <Modal show={currencyMaster} onHide={()=>{
        handleCurrencyMasterClose()
        reset()
       }}>
        <div  className="row">
            <div className="col-5 m-3" style={{color:"#5CADA9"}}> <Modal.Title>
            <i className='fa-solid fa-arrow-left fa-sm me-2' 
                onClick={() => {   handleCurrencyMasterClose()
                  reset()}}
            ></i>
              Currency Master </Modal.Title></div>
            <div className="col-6 mt-4 text-end" >
              <Can I={"Edit"} a={"Currency_Master"}>
                <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={!(editCurrencyMaster && edit)} onClick={()=>{setEdit(!edit)}}  ></i>
              </Can>
              <Can I={"Delete"} a={"Currency_Master"}>
                <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " hidden={!(editCurrencyMaster && edit)} onClick={()=>{handledeleteConfomation(editCurrencyMaster)}} ></i> 
              </Can>
              <Can I={"Historys"} a={"Currency_Master"}>
                <TbHistory type="button" className='fs-4 ms-3' data-bs-toggle="offcanvas" hidden={!(editCurrencyMaster && edit)} data-bs-target="#History" onClick={()=>{setHistoryIds(initialValues?.HistoryDetails)}} />
              </Can>
          </div>
          <hr/>
        </div> 
        <Modal.Body> 
            <Formik initialValues={initialValues} validationSchema={CurrencyMasterSchema}
            onSubmit={handlesubmit} >
               {({errors, touched, setFieldValue, values, isSubmitting})=>(
                  <Form>
                    <div>
                      <div className="row">
                          <div className="col-6  staan-input-group">
                              <Field type='text' id='Name' name='Name' disabled={editCurrencyMaster && edit}  className='w-100 staan-form-input'/>   
                              <label htmlFor="Name" className='staan-form-input-label pt-2 px-1 mx-2'>Name </label>
                              {  touched.Name &&  errors.Name && <small>{errors.Name}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                              <Field type='text' id='Name' name='Currency_symbol' disabled={editCurrencyMaster && edit} className='w-100 staan-form-input'/>   
                              <label htmlFor="Symbol" className='staan-form-input-label pt-2 px-1 mx-2'>Symbol </label>
                              {  touched.Currency_symbol &&  errors.Currency_symbol && <small>{errors.Currency_symbol}</small>}
                          </div>
                      </div>
                      <div className='row'>
                          <div className="col-6 staan-input-group">
                            <Select
                                name="Formate"
                                isClearable
                                isDisabled={editCurrencyMaster && edit}
                                value={selectFormat}
                                onChange={(option) => {
                                    handleFormatChange(option);
                                    setFieldValue('Formate', option?.value);
                                }}  
                                options={formateOptions.map((item) => ({
                                  value: item.id,
                                  label: item.formate,
                                }))}
                                styles={customSelectStyle}
                                className='my-3'
                              />
                              <label htmlFor="Formate" className='staan-form-input-label pt-1 px-1 mx-2'>Format </label>
                              {  touched.Formate &&  errors.Formate && <small>{errors.Formate}</small>}
                          </div>
                          <div className="col-6 staan-input-group d-flex align-items-center"> 
                            <div>
                                <Field type="checkbox" name="Active" disabled={editCurrencyMaster && edit}  className='me-3' />
                                <label>Active</label>
                                {  touched.Active &&  errors.Active && <small>{errors.Active}</small>}
                            </div>
                          </div>
                        </div>
                    </div>
                    <Modal.Footer>
                    <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={()=>{ handleCurrencyMasterClose()
                    reset()}}>Cancel</button>
                    <Can allow_list={["Save_Currency_Master","Edit_Currency_Master"]}>
                        <button type='submit' disabled={isSubmitting}  className="btn btn-sm btn-outline-success">Submit</button>
                    </Can>
                    
                    </Modal.Footer>
                  </Form>
               )}
            </Formik>
        </Modal.Body> 
      </Modal>
      {deleteData && <DeleteConformation
       handleDeletedId =  {(id_)=>{handleDeletedId(id_)}}
       deleteData= {deleteData}
       url={url} 
      />}
     </>
  )
}

export default CurrencyMasterFrom