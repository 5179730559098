export const departmentcreateMutations = (data)=>{
    const mutations = `
    mutation mydep{
        departmentCreateMutation(`+data+`){
          success
          errors
        }
      }
    `
  return mutations 
}

export const departmentDeleteMutations = (data)=>{
  const mutations = `
  mutation mydepdelete {
    departmentDeleteMutation(id:${data}){
     errors
     success
   }
 }`
return mutations 
}

export const otherExpensesCreateMutations = (data) =>{
  const mutation = `
  mutation  otherExpenses{
    otherExpensesCreateMutation(${data}){
    success
    errors
   }
  }
  `
  return mutation
}

export const otherExpensesDeleteMutations = (data) =>{
  const mutation = `
  mutation otherDelete {
    otherExpensesDeleteMutation(id :${data}){
      success
      errors
    }
  }
  `
  return mutation
}


export const TermsConditionsCreateMutations = (data) =>{
  const mutation = `
  mutation tc{
    termsConditionsCreateMutation(${data}){
   success
   errors
 }
} 
  `
  return mutation
}

export const termsConditionsDeleteMutations = (data) =>{
  const mutation = `
  mutation tcdelete{
    termsConditionsDeleteMutation(id:${data}){
      success
      errors
    }
  }
  `
  return mutation
}
