import React, { createContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [role, setRole] = useState('guest');

  const value = {
    role,
    setRole,
    permissions: ["enquiry_Delete", "enquiry_add", "enquiry_edit", "enquiry_delete"],
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext
