export const OtherExpensesGetWithId = (data)=>{ 
    let query = ` 
    query other{
      otherExpenses(id:${data}){
        items{
          id
          name
          account{
            id
            accountsName
          }
          createdBy{
            id
            username
          }
          HSN{
            id
            hsnCode
          }
        historyDetails{
        id
        Action
        ColumnName
        PreviousState
        UpdatedState
        modifiedDate
        SavedBy{
            id
            username
        }
        }
     }
         
    }
    }  
    `    
    return query
  }