import React, {useEffect, useState, useContext} from 'react' 
import Modal from 'react-bootstrap/Modal';
import {   Formik, FieldArray,  Form } from 'formik';
import Select from 'react-select';
import { customSelectStyle,  DateFormate, getCurrentDate, ShowErrorNoties, TimeFormate } from '../../../utils/Utils';
import DataContext from '../../../context/ItemMasterContext';
import { ToastContainer,  toast} from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import Decimal from 'decimal.js'
import { paymentmodeQuery } from '../../../GraphQLQueries/ItemmasterQuery';

const POSPayment = ({handlePosTypeChangeError, isShowPaymentModal, 
    handleClosePaymentModal, totalAmount, handlePaymentTransactionIds, 
    data, isPaymentAdd, isSales, handleCurrentPendingBalance, status, initialValue, formikProps}) => {


    const {PosPaymentData, setPosPaymentData, AccountMasterData, userId} = useContext(DataContext)
    const [selectPaymentBy, setSelectPaymentBy] = useState('')
    const [isChange, setIsChange] =  useState(false)
    const [isPaymentCompleted, setPaymentCompleted] = useState(false);
    const [currentRowAdded, setCurrentRowAdded] = useState(false);
    const [editMode, setEditMode] = useState(true)
    const [newForm, setNewForm] = useState(false)
    const [isFormSaved, setFormSaved] = useState(false)
    let need_account_types = ['Bank', 'Cash', "Swipe"]
    const [isAllSaved, setAllSaved] = useState(false)

    const [paymentDataItem, setPaymentDataItem] = useState(null)
    let initialPayByOptions = AccountMasterData?.filter(item => need_account_types.includes(item.accountType) && item.allowReceipt === true).map(item => ({label: item.accountsName, value: item.id}))
    let initialmaster = {
        payby: '',
        pay_amount: 0.00,
        balance_amount: 0.00,
        disabled: false,
        payby_options: initialPayByOptions
    }
    const [payByOptions, setPayByOptions] = useState(initialPayByOptions)
    
     useEffect(()=>{
        if (status === "Submited"){
            setEditMode(false)
        } else if (status === "Canceled"){
            setEditMode(false)
        }
     },[status])



    const checkPayOptions = () => {
        let row_elements = document.getElementsByClassName('payment-data-input')
        if(row_elements.length === initialPayByOptions.length){
            return true
        }else{
            return false
        }
    }

    useEffect(()=>{
        let blnc_amt = 0
        let pos_payment_data = []  
        axiosInstance.post("itemmaster/graphql", {query: paymentmodeQuery(null, data)}).then(res => {
           
            pos_payment_data = res?.data?.data?.paymentMode?.items
            pos_payment_data = pos_payment_data.map(item => {
                item.payby = {label: item.payby?.accountsName, value: item.payby?.id};
                item.pay_amount = new Decimal(item.payAmount).toFixed(2)
                item.balance_amount = new Decimal(item.balanceAmount).toFixed(2)
                item.disabled = true
                blnc_amt += new Decimal(item.balance_amount).toFixed(2)
                return item;
            });
            if(pos_payment_data.length > 0){
                setPaymentDataItem(pos_payment_data)
                if(blnc_amt !== Number(totalAmount)){
                    setCurrentRowAdded(true)
                    setAllSaved(false)
                }
                setNewForm(false)
            }else{
                setPaymentDataItem([initialmaster])
                setNewForm(true)
            }

            if(isSales){
                setPayByOptions(initialPayByOptions)
                handlePosTypeChangeError(false)
            }else{
                let updated_pay_options = AccountMasterData?.filter(item => item.accountType === 'Cash' && item.allowReceipt === true).map(item => ({label: item.accountsName, value: item.id}))
                setPayByOptions(updated_pay_options)
                let payby_options_allowed = updated_pay_options?.map(item => item.value)
                let existing_payby_options = pos_payment_data?.map(item => payby_options_allowed.includes(item.payby.value))
                const allTrue = existing_payby_options.every(value => value === true); 
                handlePosTypeChangeError(!allTrue)
            }
        }).catch(err => { 
            setPaymentDataItem([initialmaster])
            setNewForm(true)

            if(isSales){
                setPayByOptions(initialPayByOptions)
                // console.log("---mmmmfalse");
                handlePosTypeChangeError(false)
            }else{
                let updated_pay_options = AccountMasterData?.filter(item => item.accountType === 'Cash' && item.allowReceipt === true).map(item => ({label: item.accountsName, value: item.id}))
                setPayByOptions(updated_pay_options)
            }
        })
    }, [data])

    const handlePaymentBySelect = (option) => {
        if(option?.value){
            setSelectPaymentBy(option)
        }else{
            setSelectPaymentBy('')
        }
    }

    const hanldeNewDataSubmit = (responseData, data_item) => {
        let newItem = { ...responseData, payby: data_item?.payby?.value };
        newItem['balance_amount'] = newItem['balanceAmount']
        newItem['pay_amount'] = newItem['payAmount']
        const isItemPresent = PosPaymentData.some(item => item.id === newItem.id);
        if (isItemPresent) {
            const updatedData = PosPaymentData.map(item => {
                if (item.id === newItem.id) {
                    return newItem;
                } else {
                    return item;
                }
            });
            setPosPaymentData(updatedData);
        } else {
            setPosPaymentData(prevData => [...prevData, newItem]);
        }
    }

    const handleSubmit = async (values, { setSubmitting,   setErrors }) =>{
        let payment_rows = document.getElementsByClassName('pos_pay_amount') 
        let paymentValues = Array.from(payment_rows).map(item => Number(item.value));
        let final_data = values.rows.filter(item => item.payby !== '')
      
        // if(paymentValues.length !== final_data.length){
        //     toast.error("Choose Payby Option Properly", {
        //         position: "bottom-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "light",
        //     });
        //     return ;
        // }
        setPaymentDataItem(final_data);
        let created_payment_ids = []
        let balance_amount_update = 0;
        try{
            balance_amount_update  = final_data[final_data.length-1].balance_amount
        }catch(e){
            // pass
        }
        if(!isSales){
            let updated_pay_options = AccountMasterData?.filter(item => item.accountType === 'Cash' && item.allowReceipt === true).map(item => ({label: item.accountsName, value: item.id}))
            let payby_options_allowed = updated_pay_options.map(item => item.value)
            let existing_payby_options = final_data.map(item => payby_options_allowed.includes(item.payby.value))
            const allTrue = existing_payby_options.every(value => value === true);
            // console.log("---mmmmsvsv");
            // console.log(allTrue);
            handlePosTypeChangeError(!allTrue) 
        }

        let total_amount_received = 0;
        for(let data_item of final_data){
            const hasId = data_item.hasOwnProperty('id');
            let payload_data = {
                payby: data_item.payby.value,
                pay_amount: new Decimal(data_item.pay_amount).toFixed(2),
                balance_amount: new Decimal(data_item.balance_amount).toFixed(2)
            }
            total_amount_received += Number(data_item.pay_amount)
            let payment_mutation = `mutation createPaymentMode{
                paymentModeCreateMutation(
                    balanceAmount: "${payload_data['balance_amount']}", 
                    payAmount: "${payload_data['pay_amount']}", 
                    payby:  ${payload_data['payby']}, 
                    createdBy: ${userId}){
                paymentModeInstance{
                  id
                  payby {
                    id
                    accountsName
                  }
                  payAmount
                  balanceAmount
                  createdBy {
                    id
                    username
                  }
                  CreatedAt
                }
              }
            }` 
            if(hasId){
                payment_mutation = `mutation createPaymentMode{
                    paymentModeCreateMutation(
                        balanceAmount: "${payload_data['balance_amount']}", 
                        payAmount: "${payload_data['pay_amount']}", 
                        payby: ${payload_data['payby']}, 
                        id: ${data_item['id']}, 
                        createdBy: ${userId}){
                    paymentModeInstance{
                        id
                        payby {
                          id
                          accountsName
                        }
                        payAmount
                        balanceAmount
                        createdBy {
                          id
                          username
                        }
                        CreatedAt
                    }
                  }
                }`
            } 
            try {
                const response = await axiosInstance.post("itemmaster/graphql", {query: payment_mutation});
                const responseData = response?.data?.data?.paymentModeCreateMutation?.paymentModeInstance  
                created_payment_ids.push(responseData?.['id'])
                
                initialValue["payment"].push(responseData?.['id'])
                hanldeNewDataSubmit(responseData, data_item)
                
            } catch (error) { 
                let filiterError = ShowErrorNoties(error)
                toast.error(filiterError, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    // transition: Bounce,
                }); 
            }
        }
        handleCurrentPendingBalance(balance_amount_update, total_amount_received)
        handlePaymentTransactionIds(created_payment_ids)
        handleClosePaymentModal();
        // if (status === "Save"){
        //     formikProps.submitForm()
        // }

    }


    const handlePaymentAdd = (data, index) => {
        let pay_amount = Number(data[index].pay_amount)
        let blnc_amt = data[index-1]?.balance_amount ?  data[index-1]?.balance_amount : Number(totalAmount)
        let current_outstanding = blnc_amt - pay_amount
        // let added_pay_by_options = data.filter((item, sub_index) => index !== sub_index)
        // let current_added_data = data[index]
        // let already_option_exists = added_pay_by_options.find(item => item.payby.value === current_added_data.payby.value)
        // if(already_option_exists){
        //     toast.error("Selected Pay by option already Exists!", {
        //         position: "bottom-right",
        //         autoClose: 3000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "light",
        //       });
        //       return undefined;
        // }else 
        if(String(data[index].pay_amount).trim() === ''){
            toast.error("Please Enter Pay Amount !", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              return undefined;
        }else if(current_outstanding < 0){
            toast.error("Entered Amount Exceeds Pay Amount", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return undefined;
        }else{
            if (current_outstanding > 0 || current_outstanding < 0) {
                setPaymentCompleted(false);
            }else{
                setPaymentCompleted(true);
            }
            return current_outstanding.toFixed(2)
        }
    }

    const handleAllPaymentsChange = (data) => {
        let blnc_amt = Number(totalAmount)
        let final_data = data?.map((item, ind)=>{
            let current_outstanding = blnc_amt - item.pay_amount
            blnc_amt = current_outstanding.toFixed(2)
            return {...item, balance_amount: current_outstanding.toFixed(2)}
        })
        return final_data
    }


    const [isShowWarningBeforeCloseModal, setShowWarningBeforeCloseModal] = useState(false)
    const handleCloseWarningModal=()=>{setShowWarningBeforeCloseModal(false)}
    const handleShowWarningModal =()=>{setShowWarningBeforeCloseModal(true)}

    const WarningBeforeClose = ()=>{
        const cleaeTheData=()=>{
            setPayByOptions(initialPayByOptions)
            setPaymentCompleted(false)
            handleClosePaymentModal()
        }

        return(
          <>
            <Modal
                show={isShowWarningBeforeCloseModal}
                onHide={handleCloseWarningModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Do you want to leave without saving?
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <button type="button" class="btn btn-outline-danger btn-sm px-3 fw-bold" onClick={handleCloseWarningModal}>No</button>
                    <button type="button" class="btn btn-outline-success btn-sm px-3 fw-bold" onClick={cleaeTheData}>Yes</button>
                </Modal.Footer>
            </Modal>
          </>
        )
    } 

    const handleCloseModal = () => {
        if(isChange){
            handleShowWarningModal()
        }else{
            setPayByOptions(initialPayByOptions)
            setPaymentCompleted(false)
            handleClosePaymentModal()
        }

    }

    const handleDeleteRowItem = (data_list, index) => {
        if(data_list.length === 1){
            const hasId = data_list?.[0]?.hasOwnProperty('id');
            if(hasId){
                try {
                    axiosInstance.delete(`/itemmaster/payment_mode/${data_list[0].id}`).then(data => {
                        handlePaymentTransactionIds([])
                        setPaymentDataItem([initialmaster])
                        return [initialmaster];
                    })
                    return [initialmaster];
                } catch (error) {
                    ShowErrorNoties(error)
                    return [initialmaster];
                }
            }else{
                return [initialmaster];
            }
        }else{
            let deleted_data = data_list.find((item, ind) => index === ind)
            let updated_rows = data_list.filter((item, ind) => ind !== index)
            const hasId = deleted_data.hasOwnProperty('id');
            if(hasId){
                try {
                    axiosInstance.delete(`/itemmaster/payment_mode/${deleted_data.id}`).then(data => {
                        let updated_ids = updated_rows.map(item => item.id)
                        handlePaymentTransactionIds(updated_ids)
                        setIsChange(true)
                        return updated_rows
                    })
                } catch (error) {
                    ShowErrorNoties(error)
                    return updated_rows
                }
            }
            return updated_rows
        }
    }

    const [isShowDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const handleCloseDeleteConfirmationModal = () => {setShowDeleteConfirmation(false)}
    const handleShowDeleteModal =()=>{setShowDeleteConfirmation(true)}
    const [deleteIndex, setDeleteIndex] = useState(null)


    const handleDeleteData = (currentDataList, setFieldValue) => {
        if(currentDataList.length > 0){
            let updatedRows =  handleDeleteRowItem(currentDataList, deleteIndex)
            console.log(updatedRows, "updatedRows")
            updatedRows = handleAllPaymentsChange(updatedRows)
            let receivePay = 0
            let balance_pay = data[data.length - 1]?.['balanceAmount']
            if(updatedRows?.length > 0){
                for (let singlePay of updatedRows) {
                    receivePay += Number(singlePay['pay_amount'])
                }  
            }
            handleCurrentPendingBalance(balance_pay, receivePay)
            setFieldValue('rows', updatedRows)
            handleCloseDeleteConfirmationModal()
        }else{
            setFieldValue('rows', [initialmaster])
        }

    }

    return(<>
        <ToastContainer/>
        <WarningBeforeClose />
        <Modal show={isShowPaymentModal}  size="xl"  onHide={handleCloseModal}  backdrop="static"
        keyboard={false} >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5>POS Payment</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {paymentDataItem && 
            <Formik 
                initialValues={{rows: paymentDataItem}} 
                onSubmit={handleSubmit}
            >
            {({errors, touched, isSubmitting, values, setFieldValue , Formik, formikProps })=> {
                return(<>
                    <Form>
                        <div className='mx-3 my-3'>
                            <FieldArray name="rows">
                                {({ push }) => (
                                <div>
                                    {!newForm && <div className='d-flex justify-content-end'> 
                                    </div>}
                                    {values.rows?.map((row, index) => (
                                        <div key={index}>
                                            <div className='row payment-data-input'> 
                                                <div className="col-2 py-3"> 
                                                  { DateFormate(values.rows[index].CreatedAt)} {TimeFormate(values.rows[index].CreatedAt)} 
                                                </div>
                                                <div className='col-2 staan-input-group'>
                                                    <Select 
                                                        name={`rows.${index}.payby`}
                                                        className='mt-3'
                                                        styles = {customSelectStyle}
                                                        options = {payByOptions? payByOptions : initialPayByOptions}
                                                        value = {values.rows[index].payby}
                                                        isDisabled = {values.rows[index].disabled}
                                                        onChange={(option)=>{
                                                            setFieldValue(`rows.${index}.payby`, option ? option: '');
                                                            handlePaymentBySelect(option)
                                                        }}
                                                    />
                                                    <label htmlFor="payby" className='staan-form-input-label pt-1 px-1 mx-2'>Pay BY</label>
                                                    {  touched.payby &&  errors.payby && <small className="staan-error-msg">{errors.payby}</small>}
                                                </div>
                                                <div className='col-2 staan-input-group'>
                                                    <input 
                                                        type="text" 
                                                        id='pay_amount' 
                                                        name={`rows.${index}.pay_amount`}
                                                        value={values.rows[index].pay_amount}
                                                        className='staan-form-input text-end pe-3 pos_pay_amount' 
                                                        onChange={(e)=>{
                                                            setFieldValue(`rows.${index}.pay_amount`, e.target.value);
                                                        }}
                                                        disabled = {values.rows[index].disabled}
                                                    />
                                                    <label htmlFor="pay_amount" className='staan-form-input-label pt-2 px-1 mx-2'>Pay Amount</label>
                                                </div>
                                                <div className='col-2 staan-input-group'>
                                                    <input 
                                                        type="text" 
                                                        name={`rows.${index}.balance_amount`}
                                                        id='balance_amount' 
                                                        className='staan-form-input text-end pe-3' 
                                                        disabled 
                                                        value = {values.rows[index].balance_amount}
                                                    />
                                                    <label htmlFor="balance_amount" className='staan-form-input-label pt-2 px-1 mx-2'>Balance</label>
                                                </div>
                                                <div className='col-3 d-flex align-items-end justify-content-start'>
                                                    <div style={{width: "100%"}} className='d-flex align-items-end justify-content-start'>
                                                        <button 
                                                            type='button' 
                                                            className='btn btn-sm btn-outline-success px-3 ms-3'
                                                            disabled={!editMode}
                                                            onClick={() => {
                                                                let current_out = handlePaymentAdd(values.rows, index)
                                                                setFieldValue(values.rows[index].balance_amount, current_out)
                                                                let updatedRows = handleAllPaymentsChange(values.rows)
                                                                setIsChange(true)
                                                                setFieldValue('rows', updatedRows)
                                                                setCurrentRowAdded(true)
                                                                setAllSaved(true)
                                                                if(current_out){
                                                                    let updatedRows = values.rows.map((item, sub_index) => {
                                                                        if(sub_index === index ){
                                                                            return {...item, 
                                                                                balance_amount: current_out, 
                                                                                disabled: true, 
                                                                                CreatedAt : getCurrentDate()
                                                                            }
                                                                        }
                                                                        return item
                                                                    })
                                                                    setFieldValue('rows', updatedRows)
                                                                }else{
                                                                    setCurrentRowAdded(false)
                                                                    setAllSaved(false)
                                                                }
                                                                // let options_completed = checkPayOptions()
                                                                // if(options_completed){
                                                                //     setCurrentRowAdded(false)
                                                                //     setAllSaved(false)
                                                                //     let updatedRows = values.rows.map((item, sub_index) => {
                                                                //         if(sub_index === index ){
                                                                //             return {...item, balance_amount: current_out}
                                                                //         }
                                                                //         return item
                                                                //     })
                                                                //     setFieldValue('rows', updatedRows)
                                                                // }else if(current_out === 0 || current_out === undefined){
                                                                //     setCurrentRowAdded(false)
                                                                //     setAllSaved(false)
                                                                // }else{
                                                                //     setCurrentRowAdded(true)
                                                                //     setAllSaved(true)
                                                                // }
                                                            }}
                                                        ><i class="fa-solid fa-check"></i>
                                                        </button>
                                                        <button 
                                                            type='button' 
                                                            disabled={!editMode} 
                                                            className='btn btn-sm btn-outline-danger px-3 ms-3'
                                                            onClick={() => {
                                                                setDeleteIndex(index)
                                                                handleShowDeleteModal()
                                                            }}
                                                        >
                                                            <i class="fa-solid fa-trash "></i>
                                                        </button>
                                                        <button 
                                                            type='button' 
                                                            onClick={() => {
                                                                let updatedRows = values.rows.filter(item => item.payby !== '').map((row, idx) => {
                                                                    return  { ...row, disabled: true };
                                                                });
                                                                // let options_completed = checkPayOptions()
                                                                // if(!options_completed){ 
                                                                push({  payby: '', pay_amount: '', balance_amount: ''});
                                                                updatedRows.push({  payby: '', pay_amount: '', balance_amount: ''})
                                                                // }else{
                                                                //     const lastIndex = updatedRows.length - 1;
                                                                //     if (lastIndex >= 0) {
                                                                //         updatedRows[lastIndex] = { ...updatedRows[lastIndex], disabled: false };
                                                                //     }
                                                                // }
                                                                setFieldValue('rows', updatedRows)
                                                                setCurrentRowAdded(false)
                                                                setAllSaved(false)
                                                            }}
                                                            disabled={!currentRowAdded || !editMode} 
                                                            className='btn btn-sm btn-outline-primary px-3 ms-3'>
                                                        <i class="bx bxs-plus-circle"></i> New
                                                        </button>
                                                    </div>
                                                </div>      
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                )}
                            </FieldArray>
                            <div>
                                <Modal
                                    show={isShowDeleteConfirmation}
                                    onHide={handleCloseDeleteConfirmationModal}
                                    backdrop="static"
                                    keyboard={false}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Confirm</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        Are you sure want to delete the data?
                                    </Modal.Body>
                                    <Modal.Footer className='d-flex justify-content-center'>
                                        <button type="button" class="btn btn-outline-danger btn-sm px-3 fw-bold" onClick={handleCloseDeleteConfirmationModal}>No</button>
                                        <button type="button" class="btn btn-outline-success btn-sm px-3 fw-bold" onClick={()=>{handleDeleteData(values.rows, setFieldValue)}}>Yes</button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                            <div  className='d-flex justify-content-center mt-3'>
                                <div className='text-center'>
                                    <button 
                                        type="submit" 
                                        className={`btn btn-sm btn-outline-success px-3 ${ !isAllSaved ? 'disabled': ""}`} 
                                        onClick={()=>{setFormSaved(true)}}> Save</button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </>)
            }}
            </Formik>
            }
            </Modal.Body>
        </Modal>
    </>)
}


export default POSPayment 