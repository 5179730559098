import React, {useMemo, useState, useEffect} from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import { FetchProductionOrderScrapItems } from "../../../GraphQLQueries/ProductionOrderQuery";
import axiosInstance from "../../../api/axoiss";


const PoScrap = ({data}) => {
    const containerStyle = useMemo(() => ({ width: "100%", height: "20rem" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const rowStyle = { background: 'white' };
    const [rowData, setRowData] = useState(data);
    const columnDefs = [
        { headerName: 'S No', valueGetter: "node.rowIndex + 1", flex: 1},
        { headerName: 'Part No', field: "partCode.itemPartCode", tooltipValueGetter: (p) => p.value , flex: 3},
        { headerName: 'Part Name', field: "partCode.itemName", tooltipValueGetter: (p) => p.value, flex: 5 },
        { headerName: 'Category', field: "category.name" , flex: 2},
        { headerName: 'UOM', field: "unit.name" , flex: 2},
        { headerName: 'Actual', field: "actualQty", editable: true, flex: 2 },
        { headerName: 'Store', field: "store.storeName", tooltipValueGetter: (p) => p.value, flex: 2 },
        { headerName: 'Cost Allocation', field: "costAllocation", flex: 2 },
    ]

    const defaultColDef = {
        // editable: true, 
        resizable: true
    }

    useEffect(()=>{
        if(data && data.length > 0){
            let scrap_id = data?.map(item => item.id)
            axiosInstance.post("/itemmaster/graphql", {query: FetchProductionOrderScrapItems(scrap_id)})
            .then(res => {
                let data_list = res?.data?.data?.productionOrderScrap?.items || []
                setRowData(data_list)
            })
            .catch(err =>{ 
                // console.log(err, "error")
            })
            
        }
    }, [data])

    return(<>
        <div>
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowStyle ={rowStyle}
                    />
                </div>
            </div>
        </div>
    </>)
}

export default PoScrap;