import React, { useState } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableView from '../../Packages/Custom/DataTable'
import ExpenseReconciliationModal from './modal/ExpenseReconciliationModal'

const ExpenseReconciliation = () => {
    const [isReconciliationShow, setReconciliationShow] = useState(false)
    const headers = [
        {
            header: 'Adv Rcvd Date ',
            field: 'expenseCategoryName',
            flex: 10,
        },
        {
            header: 'Advance Amount ',
            field: 'accountName',
            flex: 10,
        },
        {
            header: 'Adjusted Amount ',
            field: 'active',
            flex: 10,
            align: 'center',
        },
    ]
    const CustomAddModalComponent = () => {
        setReconciliationShow(true)
    }
    return (
        <>
            <SideNavbar />
            <div className='container-lg'>
                <div id='custom-table-itemmaster'>
                    <TableView
                        headers={headers}
                        // query={"DepartmentTableQuery"}
                        addComponent={CustomAddModalComponent}
                        loadComponent={false}
                        titleName={"Expense Reconciliation"}
                        heightAdjust={"40rem"}
                        permission={"View_Enquiry"}
                    />
                </div>
            </div>
            {
                isReconciliationShow &&
                <ExpenseReconciliationModal
                    isReconciliationShow={isReconciliationShow}
                    setReconciliationShow={setReconciliationShow}
                />
            }

        </>
    )
}


export default ExpenseReconciliation
