import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select'
import HistoryData from '../../Page/ItemMaster/model/HistoryData'
import DataContext from '../../context/ItemMasterContext'
import {
  customSelectStyle,
  removeEmptyValueInObject,
  ShowErrorNoties
} from '../../utils/Utils'
// import { fetchOptionsData } from "../../../ReduxState/Action/itemMasterAction";
import Button from 'react-bootstrap/Button'
import { TbHistory } from 'react-icons/tb'
import { Bounce, toast } from 'react-toastify'
import {
  accountsMasterForPosOptionQuery,
  HsnOptionQueryWithFiliter
} from '../../GraphQLQueries/ItemmasterQuery'
import { otherIncomeCharges } from '../../GraphQLQueries/OtherIncomeChargesQuery/OtherIncomeQuery'
import { otherIncomeChargesCreateMutation, otherIncomeChargesDeleteMutation } from '../../GraphQLQueries/OtherIncomeChargesQuery/OtherIncomingChargesMutation'
import axiosInstance, { commanFetch } from '../../api/axoiss'
import DeleteConformation from '../../components/deleteConformations.js/DeleteConformation'
import { otherExpensesSchema } from '../../validations/otherIncomingChargesSchema'

const OtherIncomeChargesModel = ({
  otherIncomeChargesIsShowing,
  setOtherIncomeChargesIsShowing,
  setLoad,
  otherIncomeChargesEdit,
  setOtherIncomeChargesEdit
}) => {
  const { userId, historyIds, setHistoryIds, handltoastDeleteConfomationShow } =
    useContext(DataContext)
  const [initialOtherIncomeCharges, setInitialOtherIncomeCharges] = useState({
    accountsName: '',
    id: '',
    name: '',
    hsn: '',
    effectiveDate: '',
    modified_by: '',
    createdBy: '',
    history: '',
    isActive: ''
  })

  const [selectAccount, setSelectAccount] = useState({ value: '', label: '' })
  const [selectHsn, setSelectHsn] = useState({ value: '', label: '' })
  const [accountList, setAccountList] = useState([])
  const [hsnList, setHsnList] = useState([])
  const [edit, setEdit] = useState(true)
  const [deleteData, setDeleteData] = useState({ Name: '', id: '' })
  const [otherIncomeChargesEditData, setOtherIncomeChargesEditData] =
    useState(null)
  const [isActiveChecked, setActiveChecked] = useState(false)
  const [formKey, setFormKey] = useState(0)
  const [isShoweffectiveDate, setShoweffectiveDate] = useState(true)
  //return null : save data

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log("valesu", values);

    let save_data = {
      id:
        otherIncomeChargesEdit !== null
          ? Number(initialOtherIncomeCharges.id)
          : '',
      name: values?.['name'],
      account: values?.['accountsName'] ? Number(values?.['accountsName']) : '',
      hsn: values?.['hsn'] ? Number(values?.['hsn']) : '',
      createdBy:
        otherIncomeChargesEdit !== null
          ? Number(initialOtherIncomeCharges?.createdBy)
          : Number(userId),
      modifiedBy: otherIncomeChargesEdit !== null ? Number(userId) : '',
      effectiveDate: values?.effectiveDate ? values?.effectiveDate : ''
    }
    let allow_null = ['id', 'modifiedBy', 'hsn', 'effectiveDate']
    let clear_data = removeEmptyValueInObject(save_data, allow_null)
    try {
      const response = await axiosInstance.post('/itemmaster/graphql', {
        query: otherIncomeChargesCreateMutation(clear_data)
      })

      if (response.data.data.otherIncomeChargesCreateMutation.success) {
        resetForm()
        setLoad()
        setEdit(false)
        close()
        toast.success('Successfully Saved', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      } else {
        let errorData = ShowErrorNoties(
          response.data.data.otherIncomeChargesCreateMutation.errors
        )
        toast.error(errorData, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      }
    } catch (error) {
      let errorData = ShowErrorNoties(error)
      toast.error(errorData, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce
      })
    }
  }
  function close() {
    setInitialOtherIncomeCharges({
      id: '',
      name: '',
      HSN: '',
      effectiveDate: '',
      account: '',
      HistoryDetails: '',
      createdBy: '',
      isActive: '',
      modified_by: ''
    })
    setSelectHsn({ value: '', label: '' })
    setSelectAccount({ value: '', label: '' })
    setEdit(false)
    setOtherIncomeChargesIsShowing(false)
    setOtherIncomeChargesEditData(null)
    setOtherIncomeChargesEdit(null)
  }
  let formik_setvalue = ''

  async function fetch(id) {
    let perameter = `id: ${id}`
    const response = await commanFetch(otherIncomeCharges(perameter))
    const responseData = response.data.items

    if (responseData?.length > 0) {
      setInitialOtherIncomeCharges({
        id: responseData?.[0]?.id,
        name: responseData?.[0]?.name,
        accountsName: responseData?.[0]?.account?.id,
        hsn: responseData?.[0]?.hsn?.id,
        effectiveDate: responseData?.[0]?.hsn?.effectiveDate,
        createdBy: responseData?.[0]?.createdBy?.id,
        history: responseData?.[0]?.historyDetails
      })
      // if (formik_setvalue){
      //     formik_setvalue("name", responseData?.[0]?.name)
      //     formik_setvalue("accountsName", responseData?.[0]?.account?.id)
      //     formik_setvalue("hsn", responseData?.[0]?.hsn?.id)

      // }
      // initialOtherIncomeCharges.id = responseData?.[0]?.id
      // initialOtherIncomeCharges.name = responseData?.[0]?.name
      // initialOtherIncomeCharges.accountsName = responseData?.[0]?.account?.id,
      // initialOtherIncomeCharges.hsn = responseData?.[0]?.hsn?.id
      // initialOtherIncomeCharges.createdBy = responseData?.[0]?.createdBy?.id
      // initialOtherIncomeCharges.history =  responseData?.[0]?.historyDetails
      setSelectHsn({ value: responseData?.[0]?.hsn?.id, label: responseData?.[0]?.hsn?.hsnCode })
      setSelectAccount({ value: responseData?.[0]?.account?.id, label: responseData?.[0]?.account?.accountsName })
      setFormKey(prve => prve + 1)
    }
  }

  useEffect(() => {
    if (otherIncomeChargesEdit !== null) {
      console.log(otherIncomeChargesEdit);

      setEdit(true)
      fetch(otherIncomeChargesEdit?.id)
    } else {
      setOtherIncomeChargesEditData(null)
      setOtherIncomeChargesEdit(null)
      setInitialOtherIncomeCharges({
        id: '',
        name: '',
        hsn: '',
        effectiveDate: '',
        account: '',
        HistoryDetails: '',
        createdBy: '',
        isActive: '',
        modified_by: ''
      })
      setSelectHsn({ value: '', label: '' })
      setSelectAccount({ value: '', label: '' })
    }
  }, [otherIncomeChargesEdit])

  useEffect(() => {
    if (otherIncomeChargesEditData !== null) {
      initialOtherIncomeCharges.id = otherIncomeChargesEditData?.id
      initialOtherIncomeCharges.name = otherIncomeChargesEditData?.name
      if (otherIncomeChargesEdit?.hsn?.id) {
        initialOtherIncomeCharges.hsn = otherIncomeChargesEdit?.hsn?.id
        setSelectHsn({
          value: otherIncomeChargesEdit?.hsn?.id,
          label: otherIncomeChargesEdit?.hsn?.hsnCode
        })
      }
      initialOtherIncomeCharges.account =
        otherIncomeChargesEditData?.account?.id
      if (initialOtherIncomeCharges.account) {
        setSelectAccount({
          value: otherIncomeChargesEditData?.account?.id,
          label: otherIncomeChargesEditData?.account?.accountsName
        })
      }
      initialOtherIncomeCharges.createdBy =
        otherIncomeChargesEditData?.createdBy?.id
      initialOtherIncomeCharges.HistoryDetails =
        otherIncomeChargesEditData?.historyDetails
    }
  }, [otherIncomeChargesEditData])

  //return null to fetch options
  async function getOption(e, query, name) {
    let search_term = e.trim().replace(/"/g, '\\"')
    // console.log(search_term);

    if (query === 'accountsMasterForPosOptionQuery' && search_term) {
      let filter_data = `accountsType: "Income", accountsName : "${search_term}"`
      const response = await commanFetch(
        accountsMasterForPosOptionQuery(filter_data)
      )
      const responseData = response?.data?.items
      if (responseData?.length > 0) {
        setAccountList(
          responseData?.map(item => ({
            value: item?.id,
            label: item?.accountsName
          }))
        )
      }
    }
    if (query === 'HsnOptionQueryWithFiliter') {
      let filter_data = `hsnCode : ${search_term}`
      const response = await commanFetch(HsnOptionQueryWithFiliter(filter_data))
      const responseData = response?.data?.items
      if (responseData?.length > 0) {
        setHsnList(
          responseData?.map(item => ({ value: item?.id, label: item?.hsnCode }))
        )
      }
    }
  }
  const handledeleteConfomation = data => {
    handltoastDeleteConfomationShow()
    setDeleteData({ Name: data?.name, id: data?.id })
  }
  const handleDeletedId = deleted_id => {
    if (deleted_id) {
      setOtherIncomeChargesIsShowing(false)
      setEdit(false)
      setLoad()
      close()
    }
  }

  return (
    <>
      <HistoryData historyIds={historyIds} setHistoryIds={setHistoryIds} />
      <Modal show={otherIncomeChargesIsShowing} size='lg'>
        <div className='row mx-1'>
          <div className='col-5 m-3' style={{ color: '#5CADA9' }}>
            <Modal.Title>
              <i
                className='fa-solid fa-arrow-left fa-sm me-2'
                onClick={() => {
                  setOtherIncomeChargesIsShowing(false)
                }}
              ></i>{' '}
              Other Income Charges
            </Modal.Title>
          </div>
          <div className='col-6 mt-4 text-end'>
            <i
              className='fa-solid fa-pen fs-5 text-primary pe-3'
              hidden={!(otherIncomeChargesEdit && edit)}
              onClick={() => {
                setEdit(!edit)
              }}
            ></i>
            <i
              className='fa-solid fa-trash text-danger fs-5 pe-3 ps-3  '
              hidden={!(otherIncomeChargesEdit && edit)}
              onClick={() => {
                handledeleteConfomation(otherIncomeChargesEdit)
              }}
            ></i>
            <TbHistory
              type='button'
              className='fs-4 ms-3'
              data-bs-toggle='offcanvas'
              hidden={!(otherIncomeChargesEdit && edit)}
              data-bs-target='#History'
              onClick={() => {
                setHistoryIds(initialOtherIncomeCharges?.history)
              }}
            />
          </div>
          <Modal.Body>
            <Formik
              enableReinitialize key={formKey}
              initialValues={initialOtherIncomeCharges}
              validationSchema={otherExpensesSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting, setFieldValue, values, submitForm }) => {
                return (
                  <Form>
                    <div className='row'>
                      <div className='col-6 staan-input-group'>
                        <Field
                          type='text'
                          name='name'
                          value={values.name}
                          disabled={otherIncomeChargesEdit && edit}
                          onChange={e => {
                            setFieldValue('name', e.target.value)
                            // setInitialOtherIncomeCharges(prev => ({
                            //   ...prev,
                            //   name: e.target.value
                            // }))
                          }}
                          className='w-100 staan-form-input'
                        />
                        <label
                          htmlFor='name'
                          className='staan-form-input-label pt-1 px-1 mx-2'
                          onClick={() => {
                            console.log(values)
                          }}
                        >
                          Name<span className='text-danger'>*</span>
                        </label>
                        {touched.name && errors.name && (
                          <small className='text-danger'>{errors.name}</small>
                        )}
                      </div>
                      <div className='col-6 staan-input-group'>
                        <Select
                          onInputChange={e => {
                            getOption(
                              e,
                              'accountsMasterForPosOptionQuery',
                              'accountsName'
                            )
                          }}
                          isDisabled={otherIncomeChargesEdit && edit}
                          name='accountsName'
                          options={accountList}
                          isSearchable={true}
                          value={selectAccount}
                          onChange={option => {
                            setFieldValue(
                              'accountsName',
                              option ? option?.value : null
                            )
                            setSelectAccount(option)

                          }}
                          styles={customSelectStyle}
                          className='my-3'
                        />
                        <label
                          htmlFor='accountsName'
                          className='staan-form-input-label pt-1 px-1 mx-2'
                        >
                          Account{' '}<span className='text-danger'>*</span>
                        </label>
                        {touched.accountsName && errors.accountsName && (
                          <small>{errors.accountsName}</small>
                        )}
                        {<small>{errors.accountsName}</small>}
                      </div>
                      <div className='col-3 staan-input-group'>
                        <Select
                          onInputChange={e => {
                            e ?
                              getOption(e, 'HsnOptionQueryWithFiliter') : setHsnList([])
                          }}

                          isDisabled={otherIncomeChargesEdit && edit}
                          name='hsn'
                          isClearable
                          options={hsnList}
                          value={selectHsn}
                          onChange={option => {

                            setFieldValue(
                              'hsn',
                              option ? option?.value : null
                            )

                            setSelectHsn(option)
                            if (otherIncomeChargesEdit?.id) {
                              setShoweffectiveDate(false)
                            }
                          }}
                          styles={customSelectStyle}
                          className='my-3'
                        />
                        <label
                          htmlFor='hsn'
                          className='staan-form-input-label pt-1 px-1 mx-2'
                        >
                          HSN{' '}<span className='text-danger'>*</span>
                        </label>
                        {touched.hsn && errors.hsn && (
                          <small>{errors.hsn}</small>
                        )}
                      </div>
                      <div className='col-3 staan-input-group' hidden={isShoweffectiveDate}>
                        <Field
                          type='date'
                          name='effectiveDate'
                          value={values.effectiveDate}
                          disabled={otherIncomeChargesEdit && edit}
                          onChange={e => {
                            setFieldValue('effectiveDate', e.target.value)
                            // setInitialOtherIncomeCharges(prev => ({
                            //   ...prev,
                            //   effectiveDate: e.target.value
                            // }))
                          }}
                          className='w-100 staan-form-input'

                        />
                        <label
                          htmlFor='effectiveDate' onClick={() => {
                            console.log(values);
                          }}
                          className='staan-form-input-label pt-1 px-1 mx-2'
                        >
                          Effective Date{' '}<span className='text-danger'>*</span>
                        </label>
                        {touched.effectiveDate && errors.effectiveDate && (
                          <small>{errors.effectiveDate}</small>
                        )}
                      </div>
                      <div className='col-6 staan-input-group mt-4'>
                        <input
                          type="checkbox"
                          checked={isActiveChecked}
                          onChange={e => {
                            const isChecked = e.target.checked;
                            if (isChecked) {
                              setActiveChecked(true)
                              setFieldValue('isActive', true)
                              // setInitialOtherIncomeCharges(prev => ({
                              //   ...prev,
                              //   isActive: true
                              // }))
                            }
                            else {
                              setActiveChecked(false)
                              setFieldValue('isActive', false)
                              // setInitialOtherIncomeCharges(prev => ({
                              //   ...prev,
                              //   isActive: false
                              // }))
                            }
                          }}
                          disabled={otherIncomeChargesEdit && edit}
                          className="me-2"
                        />
                        <span>
                          Active
                        </span>
                      </div>

                      <div className='row mt-3'>
                        <div className='text-end'>
                          <Button
                            variant='outline-danger'
                            className='mx-2'
                            size='sm'
                            onClick={close}
                          >
                            Cancel
                          </Button>
                          <Button

                            type='submit'
                            className='mx-2 px-3'
                            size='sm'
                            variant='outline-success'

                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </Modal.Body>
        </div>
      </Modal>
      {deleteData.Name && (
        <DeleteConformation
          handleDeletedId={id => {
            handleDeletedId(id)
          }}
          deleteData={deleteData}
          fullquery={otherIncomeChargesDeleteMutation(deleteData?.id)}
        />
      )}
    </>
  )
}
export default OtherIncomeChargesModel
