import {
    FETCH_FG_DATA_REQUEST,
    FETCH_FG_DATA_SUCCESS,
    FETCH_FG_DATA_FAILURE,
    CREATE_FG_DATA_REQUEST,
    CREATE_FG_DATA_SUCCESS,
    CREATE_FG_DATA_FAILURE,
    FETCH_RM_DATA_REQUEST,
    FETCH_RM_DATA_SUCCESS,
    FETCH_RM_DATA_FAILURE,
    CREATE_RM_DATA_REQUEST,
    CREATE_RM_DATA_SUCCESS,
    CREATE_RM_DATA_FAILURE,
    FETCH_SCRAP_DATA_REQUEST,
    FETCH_SCRAP_DATA_SUCCESS,
    FETCH_SCRAP_DATA_FAILURE,
    CREATE_SCRAP_DATA_REQUEST,
    CREATE_SCRAP_DATA_SUCCESS,
    CREATE_SCRAP_DATA_FAILURE,
    FETCH_DATA_REQUEST,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_FAILURE
} from '../Action/bomAction';
    
const initialFgState = {
    data: [],
    isLoading: false,
    error: null,
};
    
export const finishedGoodsReducer = (state = initialFgState, action) => {
      switch (action.type) {
        case FETCH_FG_DATA_REQUEST:
          return {
            ...state,
            isLoading: true,
            error: null,
        };
        case FETCH_FG_DATA_SUCCESS:
          return {
            ...state,
            isLoading: false,
            data: action.payload,
        };
        case FETCH_FG_DATA_FAILURE:
          return {
            ...state,
            isLoading: false,
            error: action.payload,
        };
        case CREATE_FG_DATA_REQUEST:
          return {
            ...state,
            isLoading: true,
            error: null,
        };
        case CREATE_FG_DATA_SUCCESS:
          return {
            ...state,
            isLoading: false,
            data: action.payload,
        };
        case CREATE_FG_DATA_FAILURE:
          return {
            ...state,
            isLoading: false,
            error: action.payload,
        };
        default:
          return state;
      }
};

const initialRMState = {
  data: [],
  isLoading: false,
  error: null,
};
    

export const rawMaterialReducer = (state = initialRMState, action) => {
  switch (action.type) {
    case FETCH_RM_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
    };
    case FETCH_RM_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
    };
    case FETCH_RM_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
    };
    case CREATE_RM_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
    };
    case CREATE_RM_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
    };
    case CREATE_RM_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
    };
    default:
      return state;
  }
};


const initialScrapState = {
  data: [],
  isLoading: false,
  error: null,
};
    

export const scrapReducer = (state = initialScrapState, action) => {
  switch (action.type) {
    case FETCH_SCRAP_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
    };
    case FETCH_SCRAP_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
    };
    case FETCH_SCRAP_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
    };
    case CREATE_SCRAP_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
    };
    case CREATE_SCRAP_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
    };
    case CREATE_SCRAP_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
    };
    default:
      return state;
  }
};


const initialRoutingState = {
  data: [],
  isLoading: false,
  error: null,
};

export const routingReducer = (state = initialRoutingState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
    };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
    };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
    };
    default:
      return state;
  }
};

const initialWorkCenterState = {
  data: [],
  isLoading: false,
  error: null,
}

export const workCenterReducer = (state = initialWorkCenterState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
    };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
    };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
    };
    default:
      return state;
  }
};


const initialBomState = {
  data: [],
  isLoading: false,
  error: null,
}

export const bomReducer = (state = initialBomState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
    };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
    };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
    };
    default:
      return state;
  }
};


const initialSupplierState = {
  data: [],
  isLoading: false,
  error: null,
}

export const supplierReducer = (state = initialSupplierState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
    };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
    };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
    };
    default:
      return state;
  }
};