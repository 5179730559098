import React, { useContext, useState } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableView from '../../Packages/Custom/DataTable'
import DataContext from '../../context/ItemMasterContext'
import DeliveryChallanForm from './Model/DeliveryChallanForm'

const DeliveryChallan = () => {
    const {screenSize} = useContext(DataContext)
    const [isLoad, setLoad] = useState(false)
    const [isShowDc, setIsShowDc] = useState(false)
    const [rdcEdit, setRdcEdit] = useState({'id':''})
    
    const CustomAddModalComponent = () => {
        setIsShowDc(true)
    }
    
    const CustomViewModalComponent =(data)=>{ 
      console.log(data);
      
      setRdcEdit({id:data?.id})
      setIsShowDc(true)
    }

    const headers = [ 
      {
        header: 'ID',
        field: 'id',
        flex: 5,
      //   query: 'ItemMasterOptionQuery',
        inputType: 'text',
        renderComponent: CustomViewModalComponent,
      },
        {
          header: 'Rework DC No',
          field: 'dcNo',
          customFilterField: "linkedModelId",
          label: 'linkedModelId',
          flex: 10,
          inputType: 'text',
        //   query: 'ItemMasterOptionQuery',
        //   renderComponent: CustomViewModalComponent,
        },
        {
          header: 'Rework DC Date',
          field: 'dcDate',
          flex: 30,
        //   query: 'ItemMasterOptionQuery',
          inputType: 'text',
        }, 
        
    ]
    
  return (
    <>
       <SideNavbar/>
       <div id='custom-table-itemmaster'>
            <TableView
                headers={headers}
                query={'DeliveryChallanQuery'}
                addComponent={CustomAddModalComponent}
                loadComponent={isLoad}
                titleName={'Rework Delivery Challan'}
                heightAdjust = {`${(screenSize?.height/20)}rem`}
            />
       </div>
       {console.log(isShowDc)}
       {isShowDc ? 
           <DeliveryChallanForm
           SaveQir = {null}
           isShowing = {isShowDc}
           setIsShowing = {setIsShowDc}
           initialDcData = {{"poID" : "", "DCItemDetails": [], "New" : false}}
           rdcEdit = {rdcEdit}
           />
           : ""
        }
      
    </>
  )
}

export default DeliveryChallan