import * as  yup from "yup";

export const finished_goods_schema =yup.object().shape({
    part_code: yup.string().required('Part Code is required'),
    part_name : yup.string().required('Part Name is required'),
    category : yup.string(),
    qty : yup.number().required('Quantity is required'),
    uom : yup.string().required('Unit is required'),
    cost_allocation : yup.number().required('Cost Allocation is required'),
    remarks: yup.string(),
    labourCharges: yup.number().required('Please Enter Labour Charges')
})


export const raw_materials_schema =yup.object().shape({
    part_code : yup.string().required('Part Code is required'),
    part_name : yup.string().required('Part Name is required'),
    category : yup.string(),
    // qty : yup.number().required('Quantity is required'),
    raw_qty: yup.number().required('Quantity is required'),
    uom : yup.string().required('Unit is required'),
    store : yup.string()
})


export const scrap_store_schema =yup.object().shape({
    part_code : yup.string().required('Part Code is required'),
    part_name : yup.string().required('Part Name is required'),
    category : yup.string(),
    qty : yup.number().required('Quantity is required'),
    uom : yup.string().required('Unit is required'),
    cost_allocation : yup.number(),
})


export const bom_schema = yup.object().shape({
    bom_name: yup.string().required('BOM Name is required'),
    fg_store: yup.string().required('Finished Goods is required'), 
    scrap_store: yup.string().required('Scrap Store is required'), 
    remarks: yup.string(),
    finished_goods_data: yup.array(),
    raw_materials_data: yup.array(),
    scrap_store_data:  yup.array(),
    variation: yup.number(),
    leadtime: yup.string()
})


export const work_center_schema = yup.object().shape({
    workCenter: yup.string().required('Work Center Name is Required'),
    inCharge: yup.string().required('Work Center Name is Required'),
})


export const routing_schema = yup.object().shape({
    workCenter: yup.string().required('Work Center Name is Required'),
    duration: yup.number().required('Duration is Required'),
    label: yup.string().required('Process is Required'),
    value: yup.string().required('Process is Required'),
})

export const child_bom_schema = yup.object().shape({
    childBom: yup.number().required('BOM is Required')
})