import React, { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axiosInstance, { commanFetch } from '../api/axoiss'
import { BASE_URL } from '../ApiDomain'
import showErrorToast from '../components/notifications/react-toastify/toast'
import * as QueryData from '../GraphQLQueries/ItemmasterQuery'
import {
  AccountMasterFetchQuery,
  CustomerGroupFetchQuery,
  SupplierGroupFetchQuery
} from '../GraphQLQueries/ItemmasterQuery'
import { initializeMsal } from '../msalUtils'
import { OnchangeGetData, ShowErrorNoties } from '../utils/Utils'

const DataContext = createContext({})

export const DataProvider = ({ children }) => {
  const today = new Date();
  const [itemmasterdata, setItemMasterData] = useState([])
  const [ItemGroupdata, setItemGroupData] = useState([])
  const [ItemGroupSelect, setItemGroupSelect] = useState([])
  const [ItemUOMdata, setUOMData] = useState([])
  const [ItemUOMSelect, setItemUOMSelect] = useState([])
  const [type, setType] = useState("")
  const [ItemHsndata, setHsnData] = useState([])
  const [ItemHSNSelect, setItemHSNSelect] = useState([])
  const [tax, setTax] = useState('')
  const [Accountdata, setAccountData] = useState([])
  const [supplierSelect, setSupplierSelect] = useState()
  const [Categorydata, setCategoryData] = useState([])
  const [ItemAccountGroupSelect, setItemAccountGroupSelect] = useState([])
  const [AccountGroupType, setAccountGroupType] = useState('')
  const [storedata, setStoredata] = useState([])
  const [userdata, setUserdata] = useState([])
  const [conferenct, setConference] = useState([])
  const [enquiry, setEnquiry] = useState([])
  const [enquiryTable, setEnquiryTable] = useState([])
  const [msalInstance, setMsalInstance] = useState(null)
  const Navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [userName, setUserName] = useState()
  const [userEmail, setUserEmail] = useState()
  const [accessToken, setAccessToken] = useState()
  const [userId, setUserId] = useState('') 
  const [currentConference, setCurrentConference] = useState()
  const [currentConferenceStore, setCurrentConferenceStore] = useState()
  const [stock, setStock] = useState([])
  const [stockInventory, setStockInventory] = useState([])
  const [extractData, setExtractData] = useState('')
  const [product, setProduct] = useState([])
  const [editListView, setEditListView] = useState([])
  const [CurrencyExchange, setCurrencyExchange] = useState([])
  const [currencyMasterData, setCurrencyMasterData] = useState([])
  const [posItemData, setPosItemData] = useState([])
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  const [salesOrderData, setSalesOrderData] = useState([])
  const [contanctPost, setContanctPost] = useState([])
  const [AddressPost, setAddressPost] = useState([])
  const [supplierdetails, setsupplierdetails] = useState([])
  const [contactdata, setcontactdata] = useState([])
  const [Addressdata, setAddressdata] = useState([])
  const [supplierFiliter, SetSupplierFiliter] = useState('Both')
  const [costomerGroupdata, SetCostomerGroupdata] = useState([])
  const [supplierGroupdata, SetSupplierGroupdata] = useState([])
  const [StockAdjusmentTableData, setStockAdjusmentTableData] = useState([])
  const [SerialNumberData, setSerialNumberData] = useState([])
  const [InventoryHandlerData, setInventoryHandlerData] = useState([])
  const [StockSerialHistory, setStockSerialHistory] = useState([])
  const [BatchNumberData, setBatchNumberData] = useState([])
  const [InventoryHandlerDeletetionData, setInventoryHandlerDeletetionData] = useState([])
  const [stockStatement, setStockStatement] = useState([])
  const [posEdit, setPosEdit] = useState(null)
  const [numberingData, setNumberingData] = useState([])
  const [PosPaymentData, setPosPaymentData] = useState([])
  const [BomMasterData, setBomMasterData] = useState([])
  const [historyIds, setHistoryIds] = useState([])
  const [BOMFinishedGoods, setBOMFinishedGoods] = useState([])
  const [BOMRawMaterials, setBOMRawMaterials] = useState([])
  const [BOMScrapStoreData, setBOMScrapStoreData] = useState([])
  const [RoutingData, setRoutingData] = useState([])
  const [editItemmasterData, setEditItemmasterData] = useState(null)
  const [accountGroupdata, setAccountGroupdata] = useState([])
  const [ItemAccountSelect, setItemAccountSelect] = useState([])
  const [ItemAccountSalesSelect, setItemAccountSalesSelect] = useState([])
  const [ItemDisplayGroup, setItemDisplayGroup] = useState([])
  const [dupRefData, setDupRefData] = useState(null)
  const [waringModel, setWaringModel] = useState(false)
  const [AccountMasterData, setAccountMasterData] = useState([])
  const [currentUserEvent, setCurrentUserEvent] = useState(null)
  const [activitiesMettingstatusList, setactivitiesMettingstatusList] = useState([])
  const [allUsersList, setAllUsersList] = useState([])
  const [otherIncomeChargelist, setotherIncomeChargelist] = useState([])
  const formattedDate = `${today.getDate().toString().padStart(2, '0')}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getFullYear()}`;
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const [prefetchValue, setPrefetchValue] = useState({currencyMasterList:[], currencyExchangeList:[] , GstRateOptions:[]})

  const YESTERDAY_DATE_FORMAT = `${yesterday.getDate().toString().padStart(2, '0')}-${(yesterday.getMonth() + 1).toString().padStart(2, '0')}-${yesterday.getFullYear()}`;
  const [selectedReportDates, setSelectedReportDates] = useState({
    start_date: formattedDate,
    end_date: formattedDate
  })

  useEffect(() => {
    axiosInstance
      .post('itemmaster/graphql', { query: AccountMasterFetchQuery() })
      .then(res => {
        let account_master_data = res?.data?.data?.accountsMaster?.items
        setAccountMasterData(account_master_data)
      })
      .catch(err => {
        setAccountMasterData([])
      })
      if (userId){
        prefetch()
      }
      
      
  }, [userId])
  const [permissionModelList , setPermissionModelList] = useState([])
  // 
  async function prefetch(params){
    try{
        const response = await commanFetch(QueryData.CommonStatusType(`table: "Meeting"`));
        const reponseData = response?.data 
        if (response.success){
          setactivitiesMettingstatusList(reponseData.map((item)=>(
                    {value:item?.id, label: item?.name})))
            
        } else {
            console.error("Error fetching data:", response.error);
        }
      } catch(error){
      
    }
    let permissionAndOtherIncomeCharges = `
   query MyQuery {
        allPermissionModel {
        id
          modelName
          permissionOptions {
            id
            optionsName
          }
        }
        otherIncomeCharges (active:true){
            items {
              name
              account {
                id
                accountsName
              }
              id
              hsn {
                id
                hsnCode
                gstRates{
                  rate
                }
              }
            }
            
          }
  			currencyMaster(active: true) {
        items {
          id
          name
          active
          currencySymbol
        }
      }
        currencyExchangeConnection {
            items {
              id
              rate
              Currency {
                name
                currencySymbol
              }
            }
}
        conferenceData(status: true){
        items{
        id
        name
      }
    }
  			gstRate{
      		items{
        		id
        		rate
      		}
    		}
}
    `
    try{
      const response = await axiosInstance.post(`/itemmaster/graphql`, {query:  permissionAndOtherIncomeCharges});
      const reponseData = response?.data?.data
      
      setPermissionModelList(reponseData?.allPermissionModel)
      setotherIncomeChargelist(reponseData?.otherIncomeCharges?.items?.map((account)=>({
        value:account?.id, label:account?.name, tax:account?.hsn?.gstRates?.rate
      })))
      setPrefetchValue(prve=>({...prve, currencyMasterList:reponseData?.currencyMaster?.items?.map((currency)=>({
        value:currency?.id, label:currency?.name, currencySymbol:currency?.currencySymbol
      })),
      currencyExchangeList : reponseData?.currencyExchangeConnection?.items?.map((currency)=>({
        value:currency?.id, label:currency?.Currency?.name, rate:currency?.rate, currencySymbol:currency?.Currency?.currencySymbol
      })),
      GstRateOptions: reponseData?.gstRate?.items?.map((gstrate)=>({value: gstrate?.id, label: gstrate?.rate}))
    }))
      setConference(reponseData?.conferenceData?.items?.map((conference)=>({
        value:conference?.id, label:conference?.name
      })))
    } catch(error){
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorFilter, "")
    
  }
    
  }


  useEffect(() => {
     
    axiosInstance
      .get(BASE_URL + '/itemmaster/BatchNumber')
      .then(res => {
        const batch_numbers = res.data
        setBatchNumberData(batch_numbers)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })
 

    axiosInstance
      .get(BASE_URL + '/itemmaster/EditListViews')
      .then(res => {
        const EditListResponse = res.data
        setEditListView(EditListResponse)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })
    axiosInstance
      .get(BASE_URL + '/itemmaster/CurrencyExchange')
      .then(res => {
        const setCurrencyExchangeResponse = res.data
        setCurrencyExchange(setCurrencyExchangeResponse)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })
    axiosInstance
      .get(BASE_URL + '/itemmaster/SalesOrder')
      .then(res => {
        const SalesOrderResponse = res.data
        setSalesOrderData(SalesOrderResponse)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })
    axiosInstance
      .get(BASE_URL + '/itemmaster/Supplierform')
      .then(res => {
        const SupplierformResponse = res.data

        setsupplierdetails(SupplierformResponse)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })
    axiosInstance
      .get(BASE_URL + '/itemmaster/Contact')
      .then(res => {
        const contactdataResponse = res.data

        setcontactdata(contactdataResponse)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })
    axiosInstance
      .get(BASE_URL + '/itemmaster/caddress')
      .then(res => {
        const contactdataResponse = res.data

        setAddressdata(contactdataResponse)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })
    axiosInstance
      .post('/itemmaster/graphql', { query: CustomerGroupFetchQuery() })
      .then(res => {
        const customer_group_data = res?.data?.data?.customerGroups?.items
        SetCostomerGroupdata(customer_group_data)
      })
      .catch(error => {
        // Handle errors here
        // console.error('Error fetching data:', error)
        SetCostomerGroupdata([])
      })
    axiosInstance
      .post('/itemmaster/graphql', { query: SupplierGroupFetchQuery() })
      .then(res => {
        const supplier_group_data = res?.data?.data?.supplierGroups?.items
        SetSupplierGroupdata(supplier_group_data)
      })
      .catch(error => {
        // Handle errors here
        SetSupplierGroupdata([])
        // console.error('Error fetching data:', error)
      })
    axiosInstance
      .get(BASE_URL + '/itemmaster/serialNumber')
      .then(res => {
        const serialNumberResponse = res.data

        setSerialNumberData(serialNumberResponse)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })
    // inventory handler data
    axiosInstance
      .get(BASE_URL + '/itemmaster/InventoryHandler')
      .then(res => {
        const posts = res.data
        setInventoryHandlerData(posts)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })
    // AccountsMaster data handler
    axiosInstance
      .get(BASE_URL + '/itemmaster/AccountsMaster')
      .then(res => {
        const accountResponse = res.data
        setAccountData(accountResponse)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })

    // Numbering Number
    axiosInstance
      .get(BASE_URL + '/itemmaster/NumberingSeries')
      .then(res => {
        const NumberingResponse = res.data
        setNumberingData(NumberingResponse)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })
  }, [])

  useEffect(  ()=>{
    const initialize = async () => {
      const instance = await initializeMsal()
     
      
    
      
      setMsalInstance(instance)
      setLoading(false)
    }
    if (localStorage.getItem('Name')) {
      setUserName(localStorage.getItem('Name'))
      setUserEmail(localStorage.getItem('Email'))
      setUserId(localStorage.getItem('userid'))
    }
    async function getCurrentConference() {
      if (userId){
        let responseData = await OnchangeGetData(QueryData.CurrentConferenct(userId, true));
        // OnchangeGetData
        if (responseData.success) {
          let conference_datas =  responseData?.data?.currentConferenceData?.items
          // console.log(conference_datas);
          let current_conference = conference_datas?.[0]
          
          if (current_conference){
            setCurrentConference({value: current_conference?.id , lable: current_conference?.name })
            setCurrentConferenceStore({value:current_conference?.defaultStore?.id ,lable: current_conference?.defaultStore?.storeName})
          }
        }
  
      }
    }
    initialize()
    if (userId){
      getCurrentConference()
      
    }
    
  },[userId])

  const callApi = async token => {
    try {
      const response = await fetch(`${BASE_URL}/user`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const req = await response.json()  
      
      localStorage.setItem('userid', req.id)
      localStorage.setItem('permissionlist', req.permission) 
      localStorage.setItem('permissionlist', JSON.stringify(req.permission));
      Navigate('/Enquiry')
      setUserId(req.id)
    } catch (error) {
      console.error('API Request Error:', error)
    }
  }

  const login = async () => {
    try { 
      let loginResponse ;
      if (msalInstance) {
        try{
            loginResponse = await msalInstance.loginPopup({
            scopes: ['user.read', 'openid', 'profile']
          })
          
          
        } catch(error){
          console.log(error);
          
        }
      

        if (loginResponse.accessToken) {  
          
          localStorage.setItem('access_token', loginResponse.accessToken)
          localStorage.setItem('Name', loginResponse.account.name)
          localStorage.setItem('Email', loginResponse.account.username)
          callApi(loginResponse.accessToken)
          setUserName(loginResponse.account.name)
          setUserEmail(loginResponse.account.username)
          setAccessToken(loginResponse.accessToken)

          // handleAuthenticatedUser()
        } else {
          console.log('No access token found in the response.')
        }
      }
    } catch (error) {
      // Handle login error
      console.error('Login Error:', error)
      const errorString = error.toString()
      if (errorString.includes('BrowserAuthError: interaction_in_progress')) {
        toast.error('Close this tab open in new tab ', {
          position: 'top-center',
          autoClose: 7000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light'
        })
      }
    }
  }

  const logout = async () => {
    try {
      if (msalInstance) {
        localStorage.setItem('Name', '')
        localStorage.setItem('Email', '')
        localStorage.setItem('userid', '')
        localStorage.setItem('permissionlist', "") 
        setUserName('')
        setUserEmail('')
        setUserId('')
        Navigate('/')
        await msalInstance.logout()

        // Close the current tab
        // window.close();

        // // Open a new tab (replace 'newtaburl' with the desired URL)
        // window.open('newtaburl', '_blank');
      }
    } catch (error) {
      // Handle logout error
      console.error('Logout Error:', error)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
      setScreenHeight(window.innerHeight)
    }

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize)

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const fetchData = async () => {
    try {
       axiosInstance.get(BASE_URL + '/api/user').then((res) => {
        const UserResponse = res.data;
        setUserdata(UserResponse);
        }).catch((error) => {
          // Handle errors here
          console.error('Error fetching data:', error);
        });
      axiosInstance
        .get(BASE_URL + '/api/product')
        .then(res => {
          const ProductResponse = res.data
          setProduct(ProductResponse)
        })
        .catch(error => {
          // Handle errors here
          console.error('Error fetching data:', error)
        })
 
      
      axiosInstance
        .get(BASE_URL + '/itemmaster/Category')
        .then(res => {
          const CategoryResponse = res.data
          setCategoryData(CategoryResponse)
        })
        .catch(error => {
          // Handle errors here
          console.error('Error fetching data:', error)
        })
      axiosInstance
        .get(BASE_URL + '/api/')
        .then(res => {
          const posts = res.data
          setEnquiry(posts)
        })
        .catch(error => {
          // Handle errors here
          console.error('Error fetching data:', error)
        })
      axiosInstance
        .get(BASE_URL + '/itemmaster/CurrencyMaster')
        .then(res => {
          const CurrencyMasterDataResponse = res.data
          setCurrencyMasterData(CurrencyMasterDataResponse)
        })
        .catch(error => {
          // Handle errors here
          console.error('Error fetching data:', error)
        })

      //
    } catch (err) {
      console.log(`Error: ${err.message}`)
    }
  }

  const [chnagesalesepersonlist, setchnagesalesepersonlist] = useState([
    'jegathish.e@staan.in',
    'Antony@staan.in',
    'babu.m@staan.in',
    'martin.a@staan.in',
    'madhu.t@staan.in',
    'collection@staan.in',
    'sales@staan.in',
    "malarvizhi.p@staan.in"
  ])
  const [permisiontochangesalesperson, setpermisiontochangesalesperson] = useState(false)
  useEffect(() => {
    const checkEmail = chnagesalesepersonlist.find(item => item === userEmail)
    if (checkEmail) {
      setpermisiontochangesalesperson(true)
    } else {
      setpermisiontochangesalesperson(false)
    }
  }, [userEmail, chnagesalesepersonlist])

  

  // Effect to call fetchData whenever itemmasterdata changes
  useEffect(() => {
    fetchData() // Call the fetchData function directly
  }, [itemmasterdata])

  // For select
  const [customerGroupSelect, setCustomerGroupSelec] = useState({
    value: '',
    label: ''
  })
  const [supplierGroupSelect, setSupplierGroupSelect] = useState({
    value: '',
    label: ''
  })

  // end for Select
  const [itemaMasterModal, setitemaMasterModal] = useState(false)

  const handleItemaMasterClose = () => setitemaMasterModal(false)
  const handlItemaMastereShow = () => setitemaMasterModal(true)

  const [itemaMasterEditModal, setitemaMasterEditModal] = useState(false)
  const handleItemaMasterEditClose = () => setitemaMasterEditModal(false)
  const handlItemaMastereEditShow = () => setitemaMasterEditModal(true)

  const [itemGroupAdd, setItemGroupAdd] = useState(false)
  const handleitemGroupClose = () => setItemGroupAdd(false)
  const handlitemGroupShow = () => setItemGroupAdd(true)

  const [ItemUomAdd, setuomAdd] = useState(false)
  const handleUOMClose = () => setuomAdd(false)
  const handlUOMShow = () => setuomAdd(true)

  const [ItemHSNAdd, setHSNAdd] = useState(false)
  const handleHSNClose = () => setHSNAdd(false)
  const handlHSNShow = () => setHSNAdd(true)

  const [ItemAccountAdd, setAccountAdd] = useState(false)
  const handleAccountClose = () => setAccountAdd(false)
  const handlAccountShow = () => setAccountAdd(true)

  const [ItemAccountGroupAdd, setAccountGroupAdd] = useState(false)
  const handleAccountGroupClose = () => setAccountGroupAdd(false)
  const handlAccountGroupShow = () => setAccountGroupAdd(true)

  const [AlternateUomAdd, setAlternateUomAdd] = useState(false)
  const handleAlternateUomClose = () => setAlternateUomAdd(false)
  const handlAlternateUomShow = () => setAlternateUomAdd(true)

  const [storeAdd, setStoreAdd] = useState(false)
  const handleStoreAddClose = () => setStoreAdd(false)
  const handlStoreAddShow = () => setStoreAdd(true)

  const [toastSave, setToastSave] = useState(false)
  const toggleShowA = () => setToastSave(!toastSave)

  const [toastDeleteConfomation, setToastDeleteConfomation] = useState(false)
  const handletoastDeleteConfomationClose = () => setToastDeleteConfomation(false)
  const handltoastDeleteConfomationShow = () => { 
    setToastDeleteConfomation(true)
  }

  const [showEditListView, setShowEditListView] = useState(false)

  const handleEditListViewClose = () => setShowEditListView(false)
  const handleEditListViewShow = () => setShowEditListView(true)

  const [ItemComboAdd, setItemComboAdd] = useState(false)
  const handleItemComboClose = () => setItemComboAdd(false)
  const handleItemComboShow = () => setItemComboAdd(true)

  const [SupplierAdd, setSupplierAdd] = useState(false)
  const handleSupplierClose = () => setSupplierAdd(false)
  const handleSupplierShow = () => setSupplierAdd(true)

  const [CurrencyExchangeAdd, setCurrencyExchangeAdd] = useState(false)
  const handleCurrencyExchangeClose = () => setCurrencyExchangeAdd(false)
  const handleCurrencyExchangeShow = () => setCurrencyExchangeAdd(true)

  const [currencyMaster, setcurrencyMaster] = useState(false)
  const handleCurrencyMasterClose = () => setcurrencyMaster(false)
  const handleCurrencyMasterShow = () => setcurrencyMaster(true)

  const [supplierGroup, setsupplierGroup] = useState(false)
  const handlesupplierGroupClose = () => setsupplierGroup(false)
  const handlesupplierGroupShow = () => setsupplierGroup(true)

  const [customerGroup, setcustomerGroup] = useState(false)
  const handlecustomerGroupClose = () => setcustomerGroup(false)
  const handlecustomerGroupShow = () => setcustomerGroup(true)
  
  // bom tree view related
  const [clickedKey, setClickedKey] = useState(null);
  const [isKeyUpdated, setKeyUpdated] = useState(false);
  const [isMrpTreeOrderQtyChecked, setMrpTreeOrderQtyChecked] = useState(false);
  const [mrpItemDataList, setMrpItemDataList] = useState([]);
  

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [modelKey, setModelKey] =  useState([])
  const handleCloseModelShow = (id) => {  
    setModelKey(modelKey.map(modal => modal.id === id ? { ...modal, show: false,isDuplicate: false  } : modal));
  };
  const handleDuplicateCurrentBom = (data) =>{
   
    const newId = modelKey.length > 0 ? modelKey[modelKey.length - 1].id + 1 : 1;
    data = {...data, "modelId": newId}
    console.log(modelKey);
    setModelKey([...modelKey, { id: newId, show: true, isDuplicate: true , editData: data}]);
  }

  
  //to get  screenSize 
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const options = [
    { value: 'Achalpur', label: 'Achalpur' },
    { value: 'Achhnera', label: 'Achhnera' },
    { value: 'Adalaj', label: 'Adalaj' },
    { value: 'Adilabad', label: 'Adilabad' },
    { value: 'Adityapur', label: 'Adityapur' },
    { value: 'Adoni', label: 'Adoni' },
    { value: 'Adoor', label: 'Adoor' },
    { value: 'Adra', label: 'Adra' },
    { value: 'Adyar', label: 'Adyar' },
    { value: 'Afzalpur', label: 'Afzalpur' },
    { value: 'Agartala', label: 'Agartala' },
    { value: 'Agra', label: 'Agra' },
    { value: 'Ahmedabad', label: 'Ahmedabad' },
    { value: 'Ahmednagar', label: 'Ahmednagar' },
    { value: 'Aizawl', label: 'Aizawl' },
    { value: 'Ajmer', label: 'Ajmer' },
    { value: 'Akola', label: 'Akola' },
    { value: 'Akot', label: 'Akot' },
    { value: 'Alappuzha', label: 'Alappuzha' },
    { value: 'Aligarh', label: 'Aligarh' },
    { value: 'Alipurduar', label: 'Alipurduar' },
    { value: 'Alirajpur', label: 'Alirajpur' },
    { value: 'Allahabad', label: 'Allahabad' },
    { value: 'Alwar', label: 'Alwar' },
    { value: 'Amalapuram', label: 'Amalapuram' },
    { value: 'Amalner', label: 'Amalner' },
    { value: 'Ambejogai', label: 'Ambejogai' },
    { value: 'Ambikapur', label: 'Ambikapur' },
    { value: 'Amravati', label: 'Amravati' },
    { value: 'Amreli', label: 'Amreli' },
    { value: 'Amritsar', label: 'Amritsar' },
    { value: 'Amroha', label: 'Amroha' },
    { value: 'Anakapalle', label: 'Anakapalle' },
    { value: 'Anand', label: 'Anand' },
    { value: 'Anantapur', label: 'Anantapur' },
    { value: 'Anantnag', label: 'Anantnag' },
    { value: 'Anjangaon', label: 'Anjangaon' },
    { value: 'Anjar', label: 'Anjar' },
    { value: 'Ankleshwar', label: 'Ankleshwar' },
    { value: 'Arakkonam', label: 'Arakkonam' },
    { value: 'Arambagh', label: 'Arambagh' },
    { value: 'Araria', label: 'Araria' },
    { value: 'Arrah', label: 'Arrah' },
    { value: 'Arsikere', label: 'Arsikere' },
    { value: 'Aruppukkottai', label: 'Aruppukkottai' },
    { value: 'Arvi', label: 'Arvi' },
    { value: 'Arwal', label: 'Arwal' },
    { value: 'Asansol', label: 'Asansol' },
    { value: 'Asarganj', label: 'Asarganj' },
    { value: 'Ashok-Nagar', label: 'Ashok-Nagar' },
    { value: 'Athni', label: 'Athni' },
    { value: 'Attingal', label: 'Attingal' },
    { value: 'Aurangabad', label: 'Aurangabad' },
    { value: 'Azamgarh', label: 'Azamgarh' },
    { value: 'Bagaha', label: 'Bagaha' },
    { value: 'Bageshwar', label: 'Bageshwar' },
    { value: 'Bahadurgarh', label: 'Bahadurgarh' },
    { value: 'Baharampur', label: 'Baharampur' },
    { value: 'Bahraich', label: 'Bahraich' },
    { value: 'Balaghat', label: 'Balaghat' },
    { value: 'Balangir', label: 'Balangir' },
    { value: 'Baleshwar-Town', label: 'Baleshwar-Town' },
    { value: 'Balurghat', label: 'Balurghat' },
    { value: 'Bankura', label: 'Bankura' },
    { value: 'Bapatla', label: 'Bapatla' },
    { value: 'Baramula', label: 'Baramula' },
    { value: 'Barbil', label: 'Barbil' },
    { value: 'Bargarh', label: 'Bargarh' },
    { value: 'Barh', label: 'Barh' },
    { value: 'Baripada-Town', label: 'Baripada-Town' },
    { value: 'Barmer', label: 'Barmer' },
    { value: 'Barnala', label: 'Barnala' },
    { value: 'Barpeta', label: 'Barpeta' },
    { value: 'Batala', label: 'Batala' },
    { value: 'Bathinda', label: 'Bathinda' },
    { value: 'Begusarai', label: 'Begusarai' },
    { value: 'Belagavi', label: 'Belagavi' },
    { value: 'Bellampalle', label: 'Bellampalle' },
    { value: 'Belonia', label: 'Belonia' },
    { value: 'Bengaluru', label: 'Bengaluru' },
    { value: 'Bettiah', label: 'Bettiah' },
    { value: 'Bhadrachalam', label: 'Bhadrachalam' },
    { value: 'Bhadrak', label: 'Bhadrak' },
    { value: 'Bhagalpur', label: 'Bhagalpur' },
    { value: 'Bhainsa', label: 'Bhainsa' },
    { value: 'Bharatpur', label: 'Bharatpur' },
    { value: 'Bharuch', label: 'Bharuch' },
    { value: 'Bhatapara', label: 'Bhatapara' },
    { value: 'Bhavnagar', label: 'Bhavnagar' },
    { value: 'Bhawanipatna', label: 'Bhawanipatna' },
    { value: 'Bheemunipatnam', label: 'Bheemunipatnam' },
    { value: 'Bhilai-Nagar', label: 'Bhilai-Nagar' },
    { value: 'Bhilwara', label: 'Bhilwara' },
    { value: 'Bhimavaram', label: 'Bhimavaram' },
    { value: 'Bhiwandi', label: 'Bhiwandi' },
    { value: 'Bhiwani', label: 'Bhiwani' },
    { value: 'Bhongir', label: 'Bhongir' },
    { value: 'Bhopal', label: 'Bhopal' },
    { value: 'Bhubaneswar', label: 'Bhubaneswar' },
    { value: 'Bhuj', label: 'Bhuj' },
    { value: 'Bikaner', label: 'Bikaner' },
    { value: 'Bilaspur', label: 'Bilaspur' },
    { value: 'Bobbili', label: 'Bobbili' },
    { value: 'Bodhan', label: 'Bodhan' },
    { value: 'Bokaro-Steel-City', label: 'Bokaro-Steel-City' },
    { value: 'Brahmapur', label: 'Brahmapur' },
    { value: 'Buxar', label: 'Buxar' },
    { value: 'Chaibasa', label: 'Chaibasa' },
    { value: 'Chalakudy', label: 'Chalakudy' },
    { value: 'Chandausi', label: 'Chandausi' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Changanassery', label: 'Changanassery' },
    { value: 'Charkhi-Dadri', label: 'Charkhi-Dadri' },
    { value: 'Chatra', label: 'Chatra' },
    { value: 'Chennai', label: 'Chennai' },
    { value: 'Cherthala', label: 'Cherthala' },
    { value: 'Chhapra', label: 'Chhapra' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' },
    { value: 'Chikkamagaluru', label: 'Chikkamagaluru' },
    { value: 'Chilakaluripet', label: 'Chilakaluripet' },
    { value: 'Chirala', label: 'Chirala' },
    { value: 'Chirimiri-Colly', label: 'Chirimiri-Colly' },
    { value: 'Chirkunda', label: 'Chirkunda' },
    { value: 'Chittoor', label: 'Chittoor' },
    { value: 'Chittur-Thathamangalam', label: 'Chittur-Thathamangalam' },
    { value: 'Coimbatore', label: 'Coimbatore' },
    { value: 'Cuttack', label: 'Cuttack' },
    { value: 'Dalli-Rajhara', label: 'Dalli-Rajhara' },
    { value: 'Darbhanga', label: 'Darbhanga' },
    { value: 'Darjiling', label: 'Darjiling' },
    { value: 'Davanagere', label: 'Davanagere' },
    { value: 'Deesa', label: 'Deesa' },
    { value: 'Dehradun', label: 'Dehradun' },
    { value: 'Dehri-on-Sone', label: 'Dehri-on-Sone' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Deoghar', label: 'Deoghar' },
    { value: 'Dhamtari', label: 'Dhamtari' },
    { value: 'Dhanbad', label: 'Dhanbad' },
    { value: 'Dharmanagar', label: 'Dharmanagar' },
    { value: 'Dharmavaram', label: 'Dharmavaram' },
    { value: 'Dhenkanal', label: 'Dhenkanal' },
    { value: 'Dhoraji', label: 'Dhoraji' },
    { value: 'Dhubri', label: 'Dhubri' },
    { value: 'Dhule', label: 'Dhule' },
    { value: 'Dhuri', label: 'Dhuri' },
    { value: 'Dibrugarh', label: 'Dibrugarh' },
    { value: 'Dimapur', label: 'Dimapur' },
    { value: 'Diphu', label: 'Diphu' },
    { value: 'Dumka', label: 'Dumka' },
    { value: 'Dumraon', label: 'Dumraon' },
    { value: 'Durg', label: 'Durg' },
    { value: 'Eluru', label: 'Eluru' },
    { value: 'English-Bazar', label: 'English-Bazar' },
    { value: 'Erode', label: 'Erode' },
    { value: 'Etawah', label: 'Etawah' },
    { value: 'Faridabad', label: 'Faridabad' },
    { value: 'Faridkot', label: 'Faridkot' },
    { value: 'Farooqnagar', label: 'Farooqnagar' },
    { value: 'Fatehabad', label: 'Fatehabad' },
    { value: 'Fatehpur-Sikri', label: 'Fatehpur-Sikri' },
    { value: 'Fazilka', label: 'Fazilka' },
    { value: 'Firozabad', label: 'Firozabad' },
    { value: 'Firozpur', label: 'Firozpur' },
    { value: 'Forbesganj', label: 'Forbesganj' },
    { value: 'Gadwal', label: 'Gadwal' },
    { value: 'Gangarampur', label: 'Gangarampur' },
    { value: 'Gaya', label: 'Gaya' },
    { value: 'Giridih', label: 'Giridih' },
    { value: 'Goalpara', label: 'Goalpara' },
    { value: 'Gobichettipalayam', label: 'Gobichettipalayam' },
    { value: 'Gobindgarh', label: 'Gobindgarh' },
    { value: 'Godhra', label: 'Godhra' },
    { value: 'Gohana', label: 'Gohana' },
    { value: 'Gokak', label: 'Gokak' },
    { value: 'Gooty', label: 'Gooty' },
    { value: 'Gopalganj', label: 'Gopalganj' },
    { value: 'Gudivada', label: 'Gudivada' },
    { value: 'Gudur', label: 'Gudur' },
    { value: 'Gumia', label: 'Gumia' },
    { value: 'Guntakal', label: 'Guntakal' },
    { value: 'Guntur', label: 'Guntur' },
    { value: 'Gurdaspur', label: 'Gurdaspur' },
    { value: 'Gurgaon', label: 'Gurgaon' },
    { value: 'Guruvayoor', label: 'Guruvayoor' },
    { value: 'Guwahati', label: 'Guwahati' },
    { value: 'Gwalior', label: 'Gwalior' },
    { value: 'Habra', label: 'Habra' },
    { value: 'Hajipur', label: 'Hajipur' },
    { value: 'Haldwani-cum-Kathgodam', label: 'Haldwani-cum-Kathgodam' },
    { value: 'Hansi', label: 'Hansi' },
    { value: 'Hapur', label: 'Hapur' },
    { value: 'Haridwar', label: 'Haridwar' },
    { value: 'Hazaribag', label: 'Hazaribag' },
    { value: 'Hindupur', label: 'Hindupur' },
    { value: 'Hisar', label: 'Hisar' },
    { value: 'Hoshiarpur', label: 'Hoshiarpur' },
    { value: 'Hubli-Dharwad', label: 'Hubli-Dharwad' },
    { value: 'Hugli-Chinsurah', label: 'Hugli-Chinsurah' },
    { value: 'Hyderabad', label: 'Hyderabad' },
    { value: 'Ichalkaranji', label: 'Ichalkaranji' },
    { value: 'Imphal', label: 'Imphal' },
    { value: 'Indore', label: 'Indore' },
    { value: 'Itarsi', label: 'Itarsi' },
    { value: 'Jabalpur', label: 'Jabalpur' },
    { value: 'Jagdalpur', label: 'Jagdalpur' },
    { value: 'Jaggaiahpet', label: 'Jaggaiahpet' },
    { value: 'Jagraon', label: 'Jagraon' },
    { value: 'Jagtial', label: 'Jagtial' },
    { value: 'Jaipur', label: 'Jaipur' },
    { value: 'Jalandhar', label: 'Jalandhar' },
    { value: 'Jalpaiguri', label: 'Jalpaiguri' },
    { value: 'Jamalpur', label: 'Jamalpur' },
    { value: 'Jammalamadugu', label: 'Jammalamadugu' },
    { value: 'Jammu', label: 'Jammu' },
    { value: 'Jamnagar', label: 'Jamnagar' },
    { value: 'Jamshedpur', label: 'Jamshedpur' },
    { value: 'Jamui', label: 'Jamui' },
    { value: 'Jangaon', label: 'Jangaon' },
    { value: 'Jatani', label: 'Jatani' },
    { value: 'Jehanabad', label: 'Jehanabad' },
    { value: 'Jhansi', label: 'Jhansi' },
    { value: 'Jhargram', label: 'Jhargram' },
    { value: 'Jharsuguda', label: 'Jharsuguda' },
    { value: 'Jhumri-Tilaiya', label: 'Jhumri-Tilaiya' },
    { value: 'Jind', label: 'Jind' },
    { value: 'Jodhpur', label: 'Jodhpur' },
    { value: 'Jorhat', label: 'Jorhat' },
    { value: 'Kadapa', label: 'Kadapa' },
    { value: 'Kadi', label: 'Kadi' },
    { value: 'Kadiri', label: 'Kadiri' },
    { value: 'Kagaznagar', label: 'Kagaznagar' },
    { value: 'Kailasahar', label: 'Kailasahar' },
    { value: 'Kaithal', label: 'Kaithal' },
    { value: 'Kakinada', label: 'Kakinada' },
    { value: 'Kalimpong', label: 'Kalimpong' },
    { value: 'Kalpi', label: 'Kalpi' },
    { value: 'Kalyan-Dombivali', label: 'Kalyan-Dombivali' },
    { value: 'Kamareddy', label: 'Kamareddy' },
    { value: 'Kancheepuram', label: 'Kancheepuram' },
    { value: 'Kandukur', label: 'Kandukur' },
    { value: 'Kanhangad', label: 'Kanhangad' },
    { value: 'Kannur', label: 'Kannur' },
    { value: 'Kanpur', label: 'Kanpur' },
    { value: 'Kapadvanj', label: 'Kapadvanj' },
    { value: 'Kapurthala', label: 'Kapurthala' },
    { value: 'Karaikal', label: 'Karaikal' },
    { value: 'Karimganj', label: 'Karimganj' },
    { value: 'Karimnagar', label: 'Karimnagar' },
    { value: 'Karjat', label: 'Karjat' },
    { value: 'Karnal', label: 'Karnal' },
    { value: 'Karur', label: 'Karur' },
    { value: 'Karwar', label: 'Karwar' },
    { value: 'Kasaragod', label: 'Kasaragod' },
    { value: 'Kashipur', label: 'Kashipur' },
    { value: 'Katihar', label: 'Katihar' },
    { value: 'Katni', label: 'Katni' },
    { value: 'Kavali', label: 'Kavali' },
    { value: 'Kayamkulam', label: 'Kayamkulam' },
    { value: 'Kendrapara', label: 'Kendrapara' },
    { value: 'Keshod', label: 'Keshod' },
    { value: 'Khair', label: 'Khair' },
    { value: 'Khambhat', label: 'Khambhat' },
    { value: 'Khammam', label: 'Khammam' },
    { value: 'Khanna', label: 'Khanna' },
    { value: 'Kharagpur', label: 'Kharagpur' },
    { value: 'Kharar', label: 'Kharar' },
    { value: 'Khowai', label: 'Khowai' },
    { value: 'Kishanganj', label: 'Kishanganj' },
    { value: 'Kochi', label: 'Kochi' },
    { value: 'Kodungallur', label: 'Kodungallur' },
    { value: 'Kohima', label: 'Kohima' },
    { value: 'Kolar', label: 'Kolar' },
    { value: 'Kolkata', label: 'Kolkata' },
    { value: 'Kollam', label: 'Kollam' },
    { value: 'Koratla', label: 'Koratla' },
    { value: 'Korba', label: 'Korba' },
    { value: 'Kot-Kapura', label: 'Kot-Kapura' },
    { value: 'Kothagudem', label: 'Kothagudem' },
    { value: 'Kottayam', label: 'Kottayam' },
    { value: 'Kovvur', label: 'Kovvur' },
    { value: 'Koyilandy', label: 'Koyilandy' },
    { value: 'Kozhikode', label: 'Kozhikode' },
    { value: 'Kunnamkulam', label: 'Kunnamkulam' },
    { value: 'Kurnool', label: 'Kurnool' },
    { value: 'Lachhmangarh', label: 'Lachhmangarh' },
    { value: 'Ladnu', label: 'Ladnu' },
    { value: 'Ladwa', label: 'Ladwa' },
    { value: 'Lahar', label: 'Lahar' },
    { value: 'Laharpur', label: 'Laharpur' },
    { value: 'Lakheri', label: 'Lakheri' },
    { value: 'Lakhimpur', label: 'Lakhimpur' },
    { value: 'Lakhisarai', label: 'Lakhisarai' },
    { value: 'Lakshmeshwar', label: 'Lakshmeshwar' },
    { value: 'Lal-Gopalganj-Nindaura', label: 'Lal-Gopalganj-Nindaura' },
    { value: 'Lalganj', label: 'Lalganj' },
    { value: 'Lalgudi', label: 'Lalgudi' },
    { value: 'Lalitpur', label: 'Lalitpur' },
    { value: 'Lalsot', label: 'Lalsot' },
    { value: 'Lanka', label: 'Lanka' },
    { value: 'Lar', label: 'Lar' },
    { value: 'Lathi', label: 'Lathi' },
    { value: 'Latur', label: 'Latur' },
    { value: 'Lilong', label: 'Lilong' },
    { value: 'Limbdi', label: 'Limbdi' },
    { value: 'Loha', label: 'Loha' },
    { value: 'Lohardaga', label: 'Lohardaga' },
    { value: 'Lonavla', label: 'Lonavla' },
    { value: 'Longowal', label: 'Longowal' },
    { value: 'Loni', label: 'Loni' },
    { value: 'Losal', label: 'Losal' },
    { value: 'Lucknow', label: 'Lucknow' },
    { value: 'Ludhiana', label: 'Ludhiana' },
    { value: 'Lumding', label: 'Lumding' },
    { value: 'Lunglei', label: 'Lunglei' },
    { value: 'Macherla', label: 'Macherla' },
    { value: 'Machilipatnam', label: 'Machilipatnam' },
    { value: 'Madanapalle', label: 'Madanapalle' },
    { value: 'Maddur', label: 'Maddur' },
    { value: 'Madhepura', label: 'Madhepura' },
    { value: 'Madhubani', label: 'Madhubani' },
    { value: 'Madhugiri', label: 'Madhugiri' },
    { value: 'Madhupur', label: 'Madhupur' },
    { value: 'Madikeri', label: 'Madikeri' },
    { value: 'Madurai', label: 'Madurai' },
    { value: 'Magadi', label: 'Magadi' },
    { value: 'Mahad', label: 'Mahad' },
    { value: 'Mahalingapura', label: 'Mahalingapura' },
    { value: 'Maharajganj', label: 'Maharajganj' },
    { value: 'Maharajpur', label: 'Maharajpur' },
    { value: 'Mahasamund', label: 'Mahasamund' },
    { value: 'Mahemdabad', label: 'Mahemdabad' },
    { value: 'Mahendragarh', label: 'Mahendragarh' },
    { value: 'Mahesana', label: 'Mahesana' },
    { value: 'Mahidpur', label: 'Mahidpur' },
    { value: 'Mahnar-Bazar', label: 'Mahnar-Bazar' },
    { value: 'Mahuva', label: 'Mahuva' },
    { value: 'Maihar', label: 'Maihar' },
    { value: 'Mainaguri', label: 'Mainaguri' },
    { value: 'Makhdumpur', label: 'Makhdumpur' },
    { value: 'Makrana', label: 'Makrana' },
    { value: 'Malappuram', label: 'Malappuram' },
    { value: 'Malavalli', label: 'Malavalli' },
    { value: 'Malda', label: 'Malda' },
    { value: 'Malegaon', label: 'Malegaon' },
    { value: 'Malerkotla', label: 'Malerkotla' },
    { value: 'Malkangiri', label: 'Malkangiri' },
    { value: 'Malkapur', label: 'Malkapur' },
    { value: 'Malout', label: 'Malout' },
    { value: 'Malpura', label: 'Malpura' },
    { value: 'Malur', label: 'Malur' },
    { value: 'Manachanallur', label: 'Manachanallur' },
    { value: 'Manasa', label: 'Manasa' },
    { value: 'Manavadar', label: 'Manavadar' },
    { value: 'Manawar', label: 'Manawar' },
    { value: 'Mancherial', label: 'Mancherial' },
    { value: 'Mandalgarh', label: 'Mandalgarh' },
    { value: 'Mandamarri', label: 'Mandamarri' },
    { value: 'Mandapeta', label: 'Mandapeta' },
    { value: 'Mandawa', label: 'Mandawa' },
    { value: 'Mandi', label: 'Mandi' },
    { value: 'Mandi-Dabwali', label: 'Mandi-Dabwali' },
    { value: 'Mandideep', label: 'Mandideep' },
    { value: 'Mandla', label: 'Mandla' },
    { value: 'Mandsaur', label: 'Mandsaur' },
    { value: 'Mandvi', label: 'Mandvi' },
    { value: 'Mandya', label: 'Mandya' },
    { value: 'Manendragarh', label: 'Manendragarh' },
    { value: 'Mangaldoi', label: 'Mangaldoi' },
    { value: 'Mangaluru', label: 'Mangaluru' },
    { value: 'Mangalvedhe', label: 'Mangalvedhe' },
    { value: 'Manglaur', label: 'Manglaur' },
    { value: 'Mangrol', label: 'Mangrol' },
    { value: 'Mangrulpir', label: 'Mangrulpir' },
    { value: 'Manihari', label: 'Manihari' },
    { value: 'Manjlegaon', label: 'Manjlegaon' },
    { value: 'Mankachar', label: 'Mankachar' },
    { value: 'Manmad', label: 'Manmad' },
    { value: 'Mansa', label: 'Mansa' },
    { value: 'Manuguru', label: 'Manuguru' },
    { value: 'Manvi', label: 'Manvi' },
    { value: 'Manwath', label: 'Manwath' },
    { value: 'Mapusa', label: 'Mapusa' },
    { value: 'Margao', label: 'Margao' },
    { value: 'Margherita', label: 'Margherita' },
    { value: 'Marhaura', label: 'Marhaura' },
    { value: 'Mariani', label: 'Mariani' },
    { value: 'Marigaon', label: 'Marigaon' },
    { value: 'Markapur', label: 'Markapur' },
    { value: 'Masaurhi', label: 'Masaurhi' },
    { value: 'Mathabhanga', label: 'Mathabhanga' },
    { value: 'Mathura', label: 'Mathura' },
    { value: 'Mattannur', label: 'Mattannur' },
    { value: 'Mavelikkara', label: 'Mavelikkara' },
    { value: 'Mavoor', label: 'Mavoor' },
    { value: 'Mayang-Imphal', label: 'Mayang-Imphal' },
    { value: 'Medak', label: 'Medak' },
    { value: 'Medinipur', label: 'Medinipur' },
    { value: 'Meerut', label: 'Meerut' },
    { value: 'Mehkar', label: 'Mehkar' },
    { value: 'Memari', label: 'Memari' },
    { value: 'Merta-City', label: 'Merta-City' },
    { value: 'Mhaswad', label: 'Mhaswad' },
    { value: 'Mira-Bhayandar', label: 'Mira-Bhayandar' },
    { value: 'Mirganj', label: 'Mirganj' },
    { value: 'Miryalaguda', label: 'Miryalaguda' },
    { value: 'Modasa', label: 'Modasa' },
    { value: 'Modinagar', label: 'Modinagar' },
    { value: 'Moga', label: 'Moga' },
    { value: 'Mohali', label: 'Mohali' },
    { value: 'Mokameh', label: 'Mokameh' },
    { value: 'Mokokchung', label: 'Mokokchung' },
    { value: 'Moradabad', label: 'Moradabad' },
    { value: 'Morena', label: 'Morena' },
    { value: 'Mormugao', label: 'Mormugao' },
    { value: 'Morshi', label: 'Morshi' },
    { value: 'Motihari', label: 'Motihari' },
    { value: 'Motipur', label: 'Motipur' },
    { value: 'Mount-Abu', label: 'Mount-Abu' },
    { value: 'Mudabidri', label: 'Mudabidri' },
    { value: 'Mudalagi', label: 'Mudalagi' },
    { value: 'Muddebihal', label: 'Muddebihal' },
    { value: 'Mudhol', label: 'Mudhol' },
    { value: 'Mukerian', label: 'Mukerian' },
    { value: 'Mukhed', label: 'Mukhed' },
    { value: 'Muktsar', label: 'Muktsar' },
    { value: 'Mulbagal', label: 'Mulbagal' },
    { value: 'Multai', label: 'Multai' },
    { value: 'Mumbai', label: 'Mumbai' },
    { value: 'Mundargi', label: 'Mundargi' },
    { value: 'Mundi', label: 'Mundi' },
    { value: 'Mungeli', label: 'Mungeli' },
    { value: 'Munger', label: 'Munger' },
    { value: 'Murshidabad', label: 'Murshidabad' },
    { value: 'Murtijapur', label: 'Murtijapur' },
    { value: 'Musabani', label: 'Musabani' },
    { value: 'Mussoorie', label: 'Mussoorie' },
    { value: 'Muvattupuzha', label: 'Muvattupuzha' },
    { value: 'Muzaffarpur', label: 'Muzaffarpur' },
    { value: 'Mysore', label: 'Mysore' },
    { value: 'Nabadwip', label: 'Nabadwip' },
    { value: 'Nabarangapur', label: 'Nabarangapur' },
    { value: 'Nabha', label: 'Nabha' },
    { value: 'Nadbai', label: 'Nadbai' },
    { value: 'Nadiad', label: 'Nadiad' },
    { value: 'Nagaon', label: 'Nagaon' },
    { value: 'Nagapattinam', label: 'Nagapattinam' },
    { value: 'Nagari', label: 'Nagari' },
    { value: 'Nagarkurnool', label: 'Nagarkurnool' },
    { value: 'Nagaur', label: 'Nagaur' },
    { value: 'Nagda', label: 'Nagda' },
    { value: 'Nagercoil', label: 'Nagercoil' },
    { value: 'Nagina', label: 'Nagina' },
    { value: 'Nagla', label: 'Nagla' },
    { value: 'Nagpur', label: 'Nagpur' },
    { value: 'Nahan', label: 'Nahan' },
    { value: 'Naharlagun', label: 'Naharlagun' },
    { value: 'Naidupet', label: 'Naidupet' },
    { value: 'Naihati', label: 'Naihati' },
    { value: 'Naila-Janjgir', label: 'Naila-Janjgir' },
    { value: 'Nainital', label: 'Nainital' },
    { value: 'Nainpur', label: 'Nainpur' },
    { value: 'Najibabad', label: 'Najibabad' },
    { value: 'Nakodar', label: 'Nakodar' },
    { value: 'Nakur', label: 'Nakur' },
    { value: 'Nalbari', label: 'Nalbari' },
    { value: 'Namagiripettai', label: 'Namagiripettai' },
    { value: 'Namakkal', label: 'Namakkal' },
    { value: 'Nanded-Waghala', label: 'Nanded-Waghala' },
    { value: 'Nandgaon', label: 'Nandgaon' },
    { value: 'Nandivaram-Guduvancheri', label: 'Nandivaram-Guduvancheri' },
    { value: 'Nandura', label: 'Nandura' },
    { value: 'Nandurbar', label: 'Nandurbar' },
    { value: 'Nandyal', label: 'Nandyal' },
    { value: 'Nanjangud', label: 'Nanjangud' },
    { value: 'Nanjikottai', label: 'Nanjikottai' },
    { value: 'Nanpara', label: 'Nanpara' },
    { value: 'Narasapuram', label: 'Narasapuram' },
    { value: 'Narasaraopet', label: 'Narasaraopet' },
    { value: 'Naraura', label: 'Naraura' },
    { value: 'Narayanpet', label: 'Narayanpet' },
    { value: 'Nargund', label: 'Nargund' },
    { value: 'Narkhed', label: 'Narkhed' },
    { value: 'Narnaul', label: 'Narnaul' },
    { value: 'Narsinghgarh', label: 'Narsinghgarh' },
    { value: 'Narsipatnam', label: 'Narsipatnam' },
    { value: 'Narwana', label: 'Narwana' },
    { value: 'Nashik', label: 'Nashik' },
    { value: 'Nasirabad', label: 'Nasirabad' },
    { value: 'Natham', label: 'Natham' },
    { value: 'Nathdwara', label: 'Nathdwara' },
    { value: 'Naugachhia', label: 'Naugachhia' },
    { value: 'Naugawan-Sadat', label: 'Naugawan-Sadat' },
    { value: 'Nautanwa', label: 'Nautanwa' },
    { value: 'Navsari', label: 'Navsari' },
    { value: 'Nawabganj', label: 'Nawabganj' },
    { value: 'Nawada', label: 'Nawada' },
    { value: 'Nawanshahr', label: 'Nawanshahr' },
    { value: 'Nedumangad', label: 'Nedumangad' },
    { value: 'Neem-Ka-Thana', label: 'Neem-Ka-Thana' },
    { value: 'Neemuch', label: 'Neemuch' },
    { value: 'Nehtaur', label: 'Nehtaur' },
    { value: 'Nelamangala', label: 'Nelamangala' },
    { value: 'Nellikuppam', label: 'Nellikuppam' },
    { value: 'Nellore', label: 'Nellore' },
    { value: 'Nepanagar', label: 'Nepanagar' },
    { value: 'New-Delhi', label: 'New-Delhi' },
    { value: 'Neyveli ' },
    { value: 'Neyyattinkara', label: 'Neyyattinkara' },
    { value: 'Nidadavole', label: 'Nidadavole' },
    { value: 'Nilambur', label: 'Nilambur' },
    { value: 'Nilanga', label: 'Nilanga' },
    { value: 'Nimbahera', label: 'Nimbahera' },
    { value: 'Nirmal', label: 'Nirmal' },
    { value: 'Niwai', label: 'Niwai' },
    { value: 'Niwari', label: 'Niwari' },
    { value: 'Nizamabad', label: 'Nizamabad' },
    { value: 'Nohar', label: 'Nohar' },
    { value: 'Noida', label: 'Noida' },
    { value: 'Nokha', label: 'Nokha' },
    { value: 'Nongstoin', label: 'Nongstoin' },
    { value: 'Noorpur', label: 'Noorpur' },
    { value: 'North-Lakhimpur', label: 'North-Lakhimpur' },
    { value: 'Nowgong', label: 'Nowgong' },
    { value: 'Nuzvid', label: 'Nuzvid' },
    { value: 'Obra', label: 'Obra' },
    { value: 'Oddanchatram', label: 'Oddanchatram' },
    { value: 'Ongole', label: 'Ongole' },
    { value: 'Orai', label: 'Orai' },
    { value: 'Osmanabad', label: 'Osmanabad' },
    { value: 'Ottappalam', label: 'Ottappalam' },
    { value: 'Pachora', label: 'Pachora' },
    { value: 'Pachore', label: 'Pachore' },
    { value: 'Padmanabhapuram', label: 'Padmanabhapuram' },
    { value: 'Padra', label: 'Padra' },
    { value: 'Padrauna', label: 'Padrauna' },
    { value: 'Paithan', label: 'Paithan' },
    { value: 'Pakaur', label: 'Pakaur' },
    { value: 'Palacole', label: 'Palacole' },
    { value: 'Palai', label: 'Palai' },
    { value: 'Palakkad', label: 'Palakkad' },
    { value: 'Palampur', label: 'Palampur' },
    { value: 'Palani', label: 'Palani' },
    { value: 'Palanpur', label: 'Palanpur' },
    { value: 'Palasa-Kasibugga', label: 'Palasa-Kasibugga' },
    { value: 'Palghar', label: 'Palghar' },
    { value: 'Pali', label: 'Pali' },
    { value: 'Palia-Kalan', label: 'Palia-Kalan' },
    { value: 'Palitana', label: 'Palitana' },
    { value: 'Palladam', label: 'Palladam' },
    { value: 'Pallapatti', label: 'Pallapatti' },
    { value: 'Pallikonda', label: 'Pallikonda' },
    { value: 'Palwal', label: 'Palwal' },
    { value: 'Palwancha', label: 'Palwancha' },
    { value: 'Panagar', label: 'Panagar' },
    { value: 'Panagudi', label: 'Panagudi' },
    { value: 'Panaji', label: 'Panaji' },
    { value: 'Panamattom', label: 'Panamattom' },
    { value: 'Panchkula', label: 'Panchkula' },
    { value: 'Pandharkaoda', label: 'Pandharkaoda' },
    { value: 'Pandharpur', label: 'Pandharpur' },
    { value: 'Pandhurna', label: 'Pandhurna' },
    { value: 'Panipat', label: 'Panipat' },
    { value: 'Panna', label: 'Panna' },
    { value: 'Panniyannur', label: 'Panniyannur' },
    { value: 'Panruti', label: 'Panruti' },
    { value: 'Panvel', label: 'Panvel' },
    { value: 'Pappinisseri', label: 'Pappinisseri' },
    { value: 'Paradip', label: 'Paradip' },
    { value: 'Paramakudi', label: 'Paramakudi' },
    { value: 'Parangipettai', label: 'Parangipettai' },
    { value: 'Parasi', label: 'Parasi' },
    { value: 'Parbhani', label: 'Parbhani' },
    { value: 'Pardi', label: 'Pardi' },
    { value: 'Parlakhemundi', label: 'Parlakhemundi' },
    { value: 'Parli', label: 'Parli' },
    { value: 'Partur', label: 'Partur' },
    { value: 'Parvathipuram', label: 'Parvathipuram' },
    { value: 'Paschim-Punropara', label: 'Paschim-Punropara' },
    { value: 'Pasighat', label: 'Pasighat' },
    { value: 'Patan', label: 'Patan' },
    { value: 'Pathanamthitta', label: 'Pathanamthitta' },
    { value: 'Pathankot', label: 'Pathankot' },
    { value: 'Pathardi', label: 'Pathardi' },
    { value: 'Pathri', label: 'Pathri' },
    { value: 'Patiala', label: 'Patiala' },
    { value: 'Patna', label: 'Patna' },
    { value: 'Patratu', label: 'Patratu' },
    { value: 'Pattamundai', label: 'Pattamundai' },
    { value: 'Patti', label: 'Patti' },
    { value: 'Pattukkottai', label: 'Pattukkottai' },
    { value: 'Patur', label: 'Patur' },
    { value: 'Pauni', label: 'Pauni' },
    { value: 'Pauri', label: 'Pauri' },
    { value: 'Pavagada', label: 'Pavagada' },
    { value: 'Pedana', label: 'Pedana' },
    { value: 'Peddapuram', label: 'Peddapuram' },
    { value: 'Pehowa', label: 'Pehowa' },
    { value: 'Pen', label: 'Pen' },
    { value: 'Perambalur', label: 'Perambalur' },
    { value: 'Peravurani', label: 'Peravurani' },
    { value: 'Peringathur', label: 'Peringathur' },
    { value: 'Perinthalmanna', label: 'Perinthalmanna' },
    { value: 'Periyakulam', label: 'Periyakulam' },
    { value: 'Periyasemur', label: 'Periyasemur' },
    { value: 'Perumbavoor', label: 'Perumbavoor' },
    { value: 'Petlad', label: 'Petlad' },
    { value: 'Phagwara', label: 'Phagwara' },
    { value: 'Phalodi', label: 'Phalodi' },
    { value: 'Phaltan', label: 'Phaltan' },
    { value: 'Phillaur', label: 'Phillaur' },
    { value: 'Phulabani', label: 'Phulabani' },
    { value: 'Phulera', label: 'Phulera' },
    { value: 'Phulpur', label: 'Phulpur' },
    { value: 'Phusro', label: 'Phusro' },
    { value: 'Pihani', label: 'Pihani' },
    { value: 'Pilani', label: 'Pilani' },
    { value: 'Pilibanga', label: 'Pilibanga' },
    { value: 'Pilibhit', label: 'Pilibhit' },
    { value: 'Pilkhuwa', label: 'Pilkhuwa' },
    { value: 'Pindwara', label: 'Pindwara' },
    { value: 'Pinjore', label: 'Pinjore' },
    { value: 'Pipar-City', label: 'Pipar-City' },
    { value: 'Pipariya', label: 'Pipariya' },
    { value: 'Piriyapatna', label: 'Piriyapatna' },
    { value: 'Piro', label: 'Piro' },
    { value: 'Pithampur', label: 'Pithampur' },
    { value: 'Pithapuram', label: 'Pithapuram' },
    { value: 'Pithoragarh', label: 'Pithoragarh' },
    { value: 'Pollachi', label: 'Pollachi' },
    { value: 'Polur', label: 'Polur' },
    { value: 'Pondicherry', label: 'Pondicherry' },
    { value: 'Ponnani', label: 'Ponnani' },
    { value: 'Ponneri', label: 'Ponneri' },
    { value: 'Ponnur', label: 'Ponnur' },
    { value: 'Porbandar', label: 'Porbandar' },
    { value: 'Porsa', label: 'Porsa' },
    { value: 'Port-Blair', label: 'Port-Blair' },
    { value: 'Powayan', label: 'Powayan' },
    { value: 'Prantij', label: 'Prantij' },
    { value: 'Pratapgarh', label: 'Pratapgarh' },
    { value: 'Proddatur', label: 'Proddatur' },
    { value: 'Pudukkottai', label: 'Pudukkottai' },
    { value: 'Pudupattinam', label: 'Pudupattinam' },
    { value: 'Pukhrayan', label: 'Pukhrayan' },
    { value: 'Pulgaon', label: 'Pulgaon' },
    { value: 'Punalur', label: 'Punalur' },
    { value: 'Pune', label: 'Pune' },
    { value: 'Punganur', label: 'Punganur' },
    { value: 'Punjaipugalur', label: 'Punjaipugalur' },
    { value: 'Puranpur', label: 'Puranpur' },
    { value: 'Puri', label: 'Puri' },
    { value: 'Purna', label: 'Purna' },
    { value: 'Purnia', label: 'Purnia' },
    { value: 'Purulia', label: 'Purulia' },
    { value: 'Pusad', label: 'Pusad' },
    { value: 'Puthuppally', label: 'Puthuppally' },
    { value: 'Puttur', label: 'Puttur' },
    { value: 'Qadian', label: 'Qadian' },
    { value: 'Rabkavi-Banhatti', label: 'Rabkavi-Banhatti' },
    { value: 'Radhanpur', label: 'Radhanpur' },
    { value: 'Rae-Bareli', label: 'Rae-Bareli' },
    { value: 'Raghogarh-Vijaypur', label: 'Raghogarh-Vijaypur' },
    { value: 'Raghunathganj', label: 'Raghunathganj' },
    { value: 'Raghunathpur', label: 'Raghunathpur' },
    { value: 'Rahatgarh', label: 'Rahatgarh' },
    { value: 'Rahuri', label: 'Rahuri' },
    { value: 'Raiganj', label: 'Raiganj' },
    { value: 'Raigarh', label: 'Raigarh' },
    { value: 'Raikot', label: 'Raikot' },
    { value: 'Raipur', label: 'Raipur' },
    { value: 'Rairangpur', label: 'Rairangpur' },
    { value: 'Raisen', label: 'Raisen' },
    { value: 'Raisinghnagar', label: 'Raisinghnagar' },
    { value: 'Rajagangapur', label: 'Rajagangapur' },
    { value: 'Rajahmundry', label: 'Rajahmundry' },
    { value: 'Rajakhera', label: 'Rajakhera' },
    { value: 'Rajaldesar', label: 'Rajaldesar' },
    { value: 'Rajam', label: 'Rajam' },
    { value: 'Rajampet', label: 'Rajampet' },
    { value: 'Rajapalayam', label: 'Rajapalayam' },
    { value: 'Rajauri', label: 'Rajauri' },
    { value: 'Rajgarh', label: 'Rajgarh' },
    { value: 'Rajgir', label: 'Rajgir' },
    { value: 'Rajkot', label: 'Rajkot' },
    { value: 'Rajnandgaon', label: 'Rajnandgaon' },
    { value: 'Rajpipla', label: 'Rajpipla' },
    { value: 'Rajpura', label: 'Rajpura' },
    { value: 'Rajsamand', label: 'Rajsamand' },
    { value: 'Rajula', label: 'Rajula' },
    { value: 'Rajura', label: 'Rajura' },
    { value: 'Ramachandrapuram', label: 'Ramachandrapuram' },
    { value: 'Ramagundam', label: 'Ramagundam' },
    { value: 'Ramanagaram', label: 'Ramanagaram' },
    { value: 'Ramanathapuram', label: 'Ramanathapuram' },
    { value: 'Ramdurg', label: 'Ramdurg' },
    { value: 'Rameshwaram', label: 'Rameshwaram' },
    { value: 'Ramganj-Mandi', label: 'Ramganj-Mandi' },
    { value: 'Ramgarh', label: 'Ramgarh' },
    { value: 'Ramnagar', label: 'Ramnagar' },
    { value: 'Ramngarh', label: 'Ramngarh' },
    { value: 'Rampur', label: 'Rampur' },
    { value: 'Rampur-Maniharan', label: 'Rampur-Maniharan' },
    { value: 'Rampura-Phul', label: 'Rampura-Phul' },
    { value: 'Rampurhat', label: 'Rampurhat' },
    { value: 'Ramtek', label: 'Ramtek' },
    { value: 'Ranaghat', label: 'Ranaghat' },
    { value: 'Ranavav', label: 'Ranavav' },
    { value: 'Ranchi', label: 'Ranchi' },
    { value: 'Rangia', label: 'Rangia' },
    { value: 'Rania', label: 'Rania' },
    { value: 'Ranibennur', label: 'Ranibennur' },
    { value: 'Ranipet', label: 'Ranipet' },
    { value: 'Rapar', label: 'Rapar' },
    { value: 'Rasipuram', label: 'Rasipuram' },
    { value: 'Ratangarh', label: 'Ratangarh' },
    { value: 'Rath', label: 'Rath' },
    { value: 'Ratia', label: 'Ratia' },
    { value: 'Ratlam', label: 'Ratlam' },
    { value: 'Ratnagiri', label: 'Ratnagiri' },
    { value: 'Rau', label: 'Rau' },
    { value: 'Raurkela', label: 'Raurkela' },
    { value: 'Rawatbhata', label: 'Rawatbhata' },
    { value: 'Raxaul-Bazar', label: 'Raxaul-Bazar' },
    { value: 'Rayachoti', label: 'Rayachoti' },
    { value: 'Rayagada', label: 'Rayagada' },
    { value: 'Reengus', label: 'Reengus' },
    { value: 'Rehli', label: 'Rehli' },
    { value: 'Renigunta', label: 'Renigunta' },
    { value: 'Renukoot', label: 'Renukoot' },
    { value: 'Reoti', label: 'Reoti' },
    { value: 'Repalle', label: 'Repalle' },
    { value: 'Revelganj', label: 'Revelganj' },
    { value: 'Rewa', label: 'Rewa' },
    { value: 'Rewari', label: 'Rewari' },
    { value: 'Rishikesh', label: 'Rishikesh' },
    { value: 'Risod', label: 'Risod' },
    { value: 'Robertsganj', label: 'Robertsganj' },
    { value: 'Robertson-Pet', label: 'Robertson-Pet' },
    { value: 'Rohtak', label: 'Rohtak' },
    { value: 'Ron', label: 'Ron' },
    { value: 'Roorkee', label: 'Roorkee' },
    { value: 'Rosera', label: 'Rosera' },
    { value: 'Rudauli', label: 'Rudauli' },
    { value: 'Rudrapur', label: 'Rudrapur' },
    { value: 'Rupnagar', label: 'Rupnagar' },
    { value: 'Sadabad', label: 'Sadabad' },
    { value: 'Sadasivpet', label: 'Sadasivpet' },
    { value: 'Sadri', label: 'Sadri' },
    { value: 'Sadulpur', label: 'Sadulpur' },
    { value: 'Sadulshahar', label: 'Sadulshahar' },
    { value: 'Safidon', label: 'Safidon' },
    { value: 'Safipur', label: 'Safipur' },
    { value: 'Sagar', label: 'Sagar' },
    { value: 'Sagara', label: 'Sagara' },
    { value: 'Sagwara', label: 'Sagwara' },
    { value: 'Saharanpur', label: 'Saharanpur' },
    { value: 'Saharsa', label: 'Saharsa' },
    { value: 'Sahaspur', label: 'Sahaspur' },
    { value: 'Sahaswan', label: 'Sahaswan' },
    { value: 'Sahawar', label: 'Sahawar' },
    { value: 'Sahibganj', label: 'Sahibganj' },
    { value: 'Sahjanwa', label: 'Sahjanwa' },
    { value: 'Saidpur', label: 'Saidpur' },
    { value: 'Saiha', label: 'Saiha' },
    { value: 'Sailu', label: 'Sailu' },
    { value: 'Sainthia', label: 'Sainthia' },
    { value: 'Sakaleshapura', label: 'Sakaleshapura' },
    { value: 'Sakti', label: 'Sakti' },
    { value: 'Salaya', label: 'Salaya' },
    { value: 'Salem', label: 'Salem' },
    { value: 'Salur', label: 'Salur' },
    { value: 'Samalkha', label: 'Samalkha' },
    { value: 'Samalkot', label: 'Samalkot' },
    { value: 'Samana', label: 'Samana' },
    { value: 'Samastipur', label: 'Samastipur' },
    { value: 'Sambalpur', label: 'Sambalpur' },
    { value: 'Sambhal', label: 'Sambhal' },
    { value: 'Sambhar', label: 'Sambhar' },
    { value: 'Samdhan', label: 'Samdhan' },
    { value: 'Samthar', label: 'Samthar' },
    { value: 'Sanand', label: 'Sanand' },
    { value: 'Sanawad', label: 'Sanawad' },
    { value: 'Sanchore', label: 'Sanchore' },
    { value: 'Sandi', label: 'Sandi' },
    { value: 'Sandila', label: 'Sandila' },
    { value: 'Sangamner', label: 'Sangamner' },
    { value: 'Sangareddy', label: 'Sangareddy' },
    { value: 'Sangaria', label: 'Sangaria' },
    { value: 'Sangli', label: 'Sangli' },
    { value: 'Sangole', label: 'Sangole' },
    { value: 'Sangrur', label: 'Sangrur' },
    { value: 'Sankarankovil', label: 'Sankarankovil' },
    { value: 'Sankari', label: 'Sankari' },
    { value: 'Sankeshwara', label: 'Sankeshwara' },
    { value: 'Santipur', label: 'Santipur' },
    { value: 'Sarangpur', label: 'Sarangpur' },
    { value: 'Sardarshahar', label: 'Sardarshahar' },
    { value: 'Sardhana', label: 'Sardhana' },
    { value: 'Sarni', label: 'Sarni' },
    { value: 'Sarsod', label: 'Sarsod' },
    { value: 'Sasaram', label: 'Sasaram' },
    { value: 'Sasvad', label: 'Sasvad' },
    { value: 'Satana', label: 'Satana' },
    { value: 'Satara', label: 'Satara' },
    { value: 'Sathyamangalam', label: 'Sathyamangalam' },
    { value: 'Satna', label: 'Satna' },
    { value: 'Sattenapalle', label: 'Sattenapalle' },
    { value: 'Sattur', label: 'Sattur' },
    { value: 'Saunda', label: 'Saunda' },
    { value: 'Saundatti-Yellamma', label: 'Saundatti-Yellamma' },
    { value: 'Sausar', label: 'Sausar' },
    { value: 'Savanur', label: 'Savanur' },
    { value: 'Savarkundla', label: 'Savarkundla' },
    { value: 'Sawai-Madhopur', label: 'Sawai-Madhopur' },
    { value: 'Sawantwadi', label: 'Sawantwadi' },
    { value: 'Sedam', label: 'Sedam' },
    { value: 'Sehore', label: 'Sehore' },
    { value: 'Sendhwa', label: 'Sendhwa' },
    { value: 'Seohara', label: 'Seohara' },
    { value: 'Seoni', label: 'Seoni' },
    { value: 'Seoni-Malwa', label: 'Seoni-Malwa' },
    { value: 'Shahabad', label: 'Shahabad' },
    { value: 'Shahade', label: 'Shahade' },
    { value: 'Shahbad', label: 'Shahbad' },
    { value: 'Shahdol', label: 'Shahdol' },
    { value: 'Shahganj', label: 'Shahganj' },
    { value: 'Shahjahanpur', label: 'Shahjahanpur' },
    { value: 'Shahpur', label: 'Shahpur' },
    { value: 'Shahpura', label: 'Shahpura' },
    { value: 'Shajapur', label: 'Shajapur' },
    { value: 'Shamgarh', label: 'Shamgarh' },
    { value: 'Shamli', label: 'Shamli' },
    { value: 'Shegaon', label: 'Shegaon' },
    { value: 'Sheikhpura', label: 'Sheikhpura' },
    { value: 'Shenkottai', label: 'Shenkottai' },
    { value: 'Sheoganj', label: 'Sheoganj' },
    { value: 'Sheohar', label: 'Sheohar' },
    { value: 'Sherghati', label: 'Sherghati' },
    { value: 'Sherkot', label: 'Sherkot' },
    { value: 'Shikaripur', label: 'Shikaripur' },
    { value: 'Shikohabad', label: 'Shikohabad' },
    { value: 'Shillong', label: 'Shillong' },
    { value: 'Shimla', label: 'Shimla' },
    { value: 'Shirdi', label: 'Shirdi' },
    { value: 'Shirpur-Warwade', label: 'Shirpur-Warwade' },
    { value: 'Shirur', label: 'Shirur' },
    { value: 'Shishgarh', label: 'Shishgarh' },
    { value: 'Shivamogga', label: 'Shivamogga' },
    { value: 'Shivpuri', label: 'Shivpuri' },
    { value: 'Sholavandan', label: 'Sholavandan' },
    { value: 'Sholingur', label: 'Sholingur' },
    { value: 'Shoranur', label: 'Shoranur' },
    { value: 'Shrigonda', label: 'Shrigonda' },
    { value: 'Shrirampur', label: 'Shrirampur' },
    { value: 'Shrirangapattana', label: 'Shrirangapattana' },
    { value: 'Shujalpur', label: 'Shujalpur' },
    { value: 'Sibsagar', label: 'Sibsagar' },
    { value: 'Siddipet', label: 'Siddipet' },
    { value: 'Sidhi', label: 'Sidhi' },
    { value: 'Sidhpur', label: 'Sidhpur' },
    { value: 'Sidlaghatta', label: 'Sidlaghatta' },
    { value: 'Sihora', label: 'Sihora' },
    { value: 'Sikanderpur', label: 'Sikanderpur' },
    { value: 'Sikandra-Rao', label: 'Sikandra-Rao' },
    { value: 'Sikandrabad', label: 'Sikandrabad' },
    { value: 'Sikar', label: 'Sikar' },
    { value: 'Silao', label: 'Silao' },
    { value: 'Silapathar', label: 'Silapathar' },
    { value: 'Silchar', label: 'Silchar' },
    { value: 'Siliguri', label: 'Siliguri' },
    { value: 'Sillod', label: 'Sillod' },
    { value: 'Silvassa', label: 'Silvassa' },
    { value: 'Simdega', label: 'Simdega' },
    { value: 'Sindagi', label: 'Sindagi' },
    { value: 'Sindhnur', label: 'Sindhnur' },
    { value: 'Singrauli', label: 'Singrauli' },
    { value: 'Sinnar', label: 'Sinnar' },
    { value: 'Sira', label: 'Sira' },
    { value: 'Sircilla', label: 'Sircilla' },
    { value: 'Sirhind-Fatehgarh-Sahib', label: 'Sirhind-Fatehgarh-Sahib' },
    { value: 'Sirkali', label: 'Sirkali' },
    { value: 'Sirohi', label: 'Sirohi' },
    { value: 'Sironj', label: 'Sironj' },
    { value: 'Sirsa', label: 'Sirsa' },
    { value: 'Sirsi', label: 'Sirsi' },
    { value: 'Siruguppa', label: 'Siruguppa' },
    { value: 'Sitamarhi', label: 'Sitamarhi' },
    { value: 'Sitapur', label: 'Sitapur' },
    { value: 'Sitarganj', label: 'Sitarganj' },
    { value: 'Sivaganga', label: 'Sivaganga' },
    { value: 'Sivagiri', label: 'Sivagiri' },
    { value: 'Sivakasi', label: 'Sivakasi' },
    { value: 'Siwan', label: 'Siwan' },
    { value: 'Sohagpur', label: 'Sohagpur' },
    { value: 'Sohna', label: 'Sohna' },
    { value: 'Sojat', label: 'Sojat' },
    { value: 'Solan', label: 'Solan' },
    { value: 'Solapur', label: 'Solapur' },
    { value: 'Sonamukhi', label: 'Sonamukhi' },
    { value: 'Sonepur', label: 'Sonepur' },
    { value: 'Songadh', label: 'Songadh' },
    { value: 'Sonipat', label: 'Sonipat' },
    { value: 'Sopore', label: 'Sopore' },
    { value: 'Soro', label: 'Soro' },
    { value: 'Soron', label: 'Soron' },
    { value: 'Soyagaon', label: 'Soyagaon' },
    { value: 'Sri-Madhopur', label: 'Sri-Madhopur' },
    { value: 'Srikakulam', label: 'Srikakulam' },
    { value: 'Srikalahasti', label: 'Srikalahasti' },
    { value: 'Srinagar', label: 'Srinagar' },
    { value: 'Srinivaspur', label: 'Srinivaspur' },
    { value: 'Srirampore', label: 'Srirampore' },
    { value: 'Srivilliputhur', label: 'Srivilliputhur' },
    { value: 'Sugauli', label: 'Sugauli' },
    { value: 'Sujangarh', label: 'Sujangarh' },
    { value: 'Sujanpur', label: 'Sujanpur' },
    { value: 'Sullurpeta', label: 'Sullurpeta' },
    { value: 'Sultanpur', label: 'Sultanpur' },
    { value: 'Sumerpur', label: 'Sumerpur' },
    { value: 'Sunabeda', label: 'Sunabeda' },
    { value: 'Sunam', label: 'Sunam' },
    { value: 'Sundargarh', label: 'Sundargarh' },
    { value: 'Sundarnagar', label: 'Sundarnagar' },
    { value: 'Supaul', label: 'Supaul' },
    { value: 'Surandai', label: 'Surandai' },
    { value: 'Surat', label: 'Surat' },
    { value: 'Suratgarh', label: 'Suratgarh' },
    { value: 'Suri', label: 'Suri' },
    { value: 'Suriyampalayam', label: 'Suriyampalayam' },
    { value: 'Suryapet', label: 'Suryapet' },
    { value: 'Tadepalligudem', label: 'Tadepalligudem' },
    { value: 'Tadpatri', label: 'Tadpatri' },
    { value: 'Takhatgarh', label: 'Takhatgarh' },
    { value: 'Taki', label: 'Taki' },
    { value: 'Talaja', label: 'Talaja' },
    { value: 'Talcher', label: 'Talcher' },
    { value: 'Talegaon-Dabhade', label: 'Talegaon-Dabhade' },
    { value: 'Talikota', label: 'Talikota' },
    { value: 'Taliparamba', label: 'Taliparamba' },
    { value: 'Talode', label: 'Talode' },
    { value: 'Talwara', label: 'Talwara' },
    { value: 'Tamluk', label: 'Tamluk' },
    { value: 'Tanda', label: 'Tanda' },
    { value: 'Tandur', label: 'Tandur' },
    { value: 'Tanuku', label: 'Tanuku' },
    { value: 'Tarakeswar', label: 'Tarakeswar' },
    { value: 'Tarana', label: 'Tarana' },
    { value: 'Taranagar', label: 'Taranagar' },
    { value: 'Tarbha', label: 'Tarbha' },
    { value: 'Tarikere', label: 'Tarikere' },
    { value: 'Tarn-Taran', label: 'Tarn-Taran' },
    { value: 'Tasgaon', label: 'Tasgaon' },
    { value: 'Tehri', label: 'Tehri' },
    { value: 'Tenali', label: 'Tenali' },
    { value: 'Tenkasi', label: 'Tenkasi' },
    { value: 'Terdal', label: 'Terdal' },
    { value: 'Tezpur', label: 'Tezpur' },
    { value: 'Thammampatti', label: 'Thammampatti' },
    { value: 'Thana-Bhawan', label: 'Thana-Bhawan' },
    { value: 'Thane', label: 'Thane' },
    { value: 'Thanesar', label: 'Thanesar' },
    { value: 'Thangadh', label: 'Thangadh' },
    { value: 'Thanjavur', label: 'Thanjavur' },
    { value: 'Tharad', label: 'Tharad' },
    { value: 'Tharamangalam', label: 'Tharamangalam' },
    { value: 'Theni-Allinagaram', label: 'Theni-Allinagaram' },
    { value: 'Thirumangalam', label: 'Thirumangalam' },
    { value: 'Thirupuvanam', label: 'Thirupuvanam' },
    { value: 'Thiruvalla', label: 'Thiruvalla' },
    { value: 'Thiruvallur', label: 'Thiruvallur' },
    { value: 'Thiruvananthapuram', label: 'Thiruvananthapuram' },
    { value: 'Thiruvarur', label: 'Thiruvarur' },
    { value: 'Thodupuzha', label: 'Thodupuzha' },
    { value: 'Thoubal', label: 'Thoubal' },
    { value: 'Thrissur', label: 'Thrissur' },
    { value: 'Thuraiyur', label: 'Thuraiyur' },
    { value: 'Tikamgarh', label: 'Tikamgarh' },
    { value: 'Tilhar', label: 'Tilhar' },
    { value: 'Tindivanam', label: 'Tindivanam' },
    { value: 'Tinsukia', label: 'Tinsukia' },
    { value: 'Tiptur', label: 'Tiptur' },
    { value: 'Tirora', label: 'Tirora' },
    { value: 'Tiruchendur', label: 'Tiruchendur' },
    { value: 'Tiruchengode', label: 'Tiruchengode' },
    { value: 'Tiruchirappalli', label: 'Tiruchirappalli' },
    { value: 'Tirukalukundram', label: 'Tirukalukundram' },
    { value: 'Tirukkoyilur', label: 'Tirukkoyilur' },
    { value: 'Tirunelveli', label: 'Tirunelveli' },
    { value: 'Tirupathur', label: 'Tirupathur' },
    { value: 'Tirupati', label: 'Tirupati' },
    { value: 'Tiruppur', label: 'Tiruppur' },
    { value: 'Tirur', label: 'Tirur' },
    { value: 'Tiruttani', label: 'Tiruttani' },
    { value: 'Tiruvannamalai', label: 'Tiruvannamalai' },
    { value: 'Tiruvethipuram', label: 'Tiruvethipuram' },
    { value: 'Tiruvuru', label: 'Tiruvuru' },
    { value: 'Tirwaganj', label: 'Tirwaganj' },
    { value: 'Titlagarh', label: 'Titlagarh' },
    { value: 'Tittakudi', label: 'Tittakudi' },
    { value: 'Todabhim', label: 'Todabhim' },
    { value: 'Todaraisingh', label: 'Todaraisingh' },
    { value: 'Tohana', label: 'Tohana' },
    { value: 'Tonk', label: 'Tonk' },
    { value: 'Tuensang', label: 'Tuensang' },
    { value: 'Tulsipur', label: 'Tulsipur' },
    { value: 'Tumkur', label: 'Tumkur' },
    { value: 'Tumsar', label: 'Tumsar' },
    { value: 'Tundla', label: 'Tundla' },
    { value: 'Tuni', label: 'Tuni' },
    { value: 'Tura', label: 'Tura' },
    { value: 'Udaipur', label: 'Udaipur' },
    { value: 'Udaipurwati', label: 'Udaipurwati' },
    { value: 'Udgir', label: 'Udgir' },
    { value: 'Udhagamandalam', label: 'Udhagamandalam' },
    { value: 'Udhampur', label: 'Udhampur' },
    { value: 'Udumalaipettai', label: 'Udumalaipettai' },
    { value: 'Udupi', label: 'Udupi' },
    { value: 'Ujjain', label: 'Ujjain' },
    { value: 'Umarga', label: 'Umarga' },
    { value: 'Umaria', label: 'Umaria' },
    { value: 'Umarkhed', label: 'Umarkhed' },
    { value: 'Umbergaon', label: 'Umbergaon' },
    { value: 'Umred', label: 'Umred' },
    { value: 'Umreth', label: 'Umreth' },
    { value: 'Una', label: 'Una' },
    { value: 'Unjha', label: 'Unjha' },
    { value: 'Unnamalaikadai', label: 'Unnamalaikadai' },
    { value: 'Unnao', label: 'Unnao' },
    { value: 'Upleta', label: 'Upleta' },
    { value: 'Uran', label: 'Uran' },
    { value: 'Uran-Islampur', label: 'Uran-Islampur' },
    { value: 'Uravakonda', label: 'Uravakonda' },
    { value: 'Urmar-Tanda', label: 'Urmar-Tanda' },
    { value: 'Usilampatti', label: 'Usilampatti' },
    { value: 'Uthamapalayam', label: 'Uthamapalayam' },
    { value: 'Uthiramerur', label: 'Uthiramerur' },
    { value: 'Utraula', label: 'Utraula' },
    { value: 'Vadalur', label: 'Vadalur' },
    { value: 'Vadgaon-Kasba', label: 'Vadgaon-Kasba' },
    { value: 'Vadipatti', label: 'Vadipatti' },
    { value: 'Vadnagar', label: 'Vadnagar' },
    { value: 'Vadodara', label: 'Vadodara' },
    { value: 'Vaijapur', label: 'Vaijapur' },
    { value: 'Vaikom', label: 'Vaikom' },
    { value: 'Valparai', label: 'Valparai' },
    { value: 'Valsad', label: 'Valsad' },
    { value: 'Vandavasi', label: 'Vandavasi' },
    { value: 'Vaniyambadi', label: 'Vaniyambadi' },
    { value: 'Vapi', label: 'Vapi' },
    { value: 'Varanasi', label: 'Varanasi' },
    { value: 'Varkala', label: 'Varkala' },
    { value: 'Vasai-Virar', label: 'Vasai-Virar' },
    { value: 'Vatakara', label: 'Vatakara' },
    { value: 'Vedaranyam', label: 'Vedaranyam' },
    { value: 'Vellore', label: 'Vellore' },
    { value: 'Venkatagiri', label: 'Venkatagiri' },
    { value: 'Veraval', label: 'Veraval' },
    { value: 'Vidisha', label: 'Vidisha' },
    { value: 'Vijapur', label: 'Vijapur' },
    { value: 'Vijayapura', label: 'Vijayapura' },
    { value: 'Vijayawada', label: 'Vijayawada' },
    { value: 'Vijaypur', label: 'Vijaypur' },
    { value: 'Vikarabad', label: 'Vikarabad' },
    { value: 'Vikramasingapuram', label: 'Vikramasingapuram' },
    { value: 'Viluppuram', label: 'Viluppuram' },
    { value: 'Vinukonda', label: 'Vinukonda' },
    { value: 'Viramgam', label: 'Viramgam' },
    { value: 'Virudhachalam', label: 'Virudhachalam' },
    { value: 'Virudhunagar', label: 'Virudhunagar' },
    { value: 'Visakhapatnam', label: 'Visakhapatnam' },
    { value: 'Visnagar', label: 'Visnagar' },
    { value: 'Viswanatham', label: 'Viswanatham' },
    { value: 'Vizianagaram', label: 'Vizianagaram' },
    { value: 'Vrindavan', label: 'Vrindavan' },
    { value: 'Vyara', label: 'Vyara' },
    { value: 'Wadgaon-Road', label: 'Wadgaon-Road' },
    { value: 'Wadhwan', label: 'Wadhwan' },
    { value: 'Wadi', label: 'Wadi' },
    { value: 'Wai', label: 'Wai' },
    { value: 'Wanaparthy', label: 'Wanaparthy' },
    { value: 'Wara-Seoni', label: 'Wara-Seoni' },
    { value: 'Warangal', label: 'Warangal' },
    { value: 'Wardha', label: 'Wardha' },
    { value: 'Warisaliganj', label: 'Warisaliganj' },
    { value: 'Warora', label: 'Warora' },
    { value: 'Washim', label: 'Washim' },
    { value: 'Wokha', label: 'Wokha' },
    { value: 'Yadgir', label: 'Yadgir' },
    { value: 'Yamunanagar', label: 'Yamunanagar' },
    { value: 'Yanam', label: 'Yanam' },
    { value: 'Yavatmal', label: 'Yavatmal' },
    { value: 'Yawal', label: 'Yawal' },
    { value: 'Yellandu', label: 'Yellandu' },
    { value: 'Yemmiganur', label: 'Yemmiganur' },
    { value: 'Yerraguntla', label: 'Yerraguntla' },
    { value: 'Yevla', label: 'Yevla' },
    { value: 'Zaidpur', label: 'Zaidpur' },
    { value: 'Zamania', label: 'Zamania' },
    { value: 'Zira', label: 'Zira' },
    { value: 'Zirakpur', label: 'Zirakpur' },
    { value: 'Zunheboto', label: 'Zunheboto' }
  ]


  const salesUserOptions = [
    { value: '', label: '' }, // Add an empty value at the beginning
    ...userdata
      .map(user => {
        if (user.last_name === 'Sales') {
          return { value: user.id, label: user.username }
        }
        return null
      })
      .filter(option => option !== null)
  ]

  // retun null; it is fetch all user
  async function CommanFetchForAllUser(e) {
    let search_term = e.trim().replace(/"/g, '\\"');
    if (search_term){
      const response = await commanFetch(QueryData.userdataOptionsQuery(e))
      const reponseData = response?.data?.items
      setAllUsersList(reponseData.map((user)=>({value:user?.id, label: user?.username, email:user?.email
        ,firstName : user?.firstName, lastName: user?.lastName
      })))
    } else{
      setAllUsersList([])
    }
  }
  
  return (
    <DataContext.Provider
      value={{
        options,
        editListView,
        setEditListView,
        setUserdata,
        userName,
        currentConference,
        userEmail,
        stock,
        setStock,
        stockInventory,
        setStockInventory,
        extractData,
        setExtractData,
        login,
        logout,
        ItemGroupSelect,
        setItemGroupSelect,
        setItemUOMSelect,
        ItemUOMSelect,
        ItemHSNSelect,
        setItemHSNSelect,
        ItemAccountGroupSelect,
        setItemAccountGroupSelect,
        setItemAccountSelect,
        ItemAccountSelect,
        supplierSelect,
        setSupplierSelect,
        type,
        setType,
        tax,
        setTax,
        storedata,
        setStoredata,
        userdata,
        storeAdd,
        handleStoreAddClose,
        handlStoreAddShow,
        AccountGroupType,
        setAccountGroupType,
        itemmasterdata,
        setItemMasterData,
        ItemGroupdata,
        setItemGroupData,
        ItemUOMdata,
        setUOMData,
        ItemHsndata,
        setHsnData,
        Accountdata,
        setAccountData,
        Categorydata,
        itemaMasterModal,
        handlItemaMastereShow,
        handleItemaMasterClose,
        itemGroupAdd,
        handlitemGroupShow,
        handleitemGroupClose,
        setItemGroupAdd,
        ItemUomAdd,
        handlUOMShow,
        handleUOMClose,
        ItemHSNAdd,
        handlHSNShow,
        handleHSNClose,
        ItemAccountAdd,
        handlAccountShow,
        handleAccountClose,
        ItemAccountGroupAdd,
        handlAccountGroupShow,
        handleAccountGroupClose,
        handlAlternateUomShow,
        handleAlternateUomClose,
        toastDeleteConfomation,
        handletoastDeleteConfomationClose,
        handltoastDeleteConfomationShow,
        setConference,
        setToastSave,
        toggleShowA,
        Navigate,
        conferenct,
        enquiry,
        setEnquiry,
        userId,
        loading,
        accessToken,
        product,
        showEditListView,
        handleEditListViewClose,
        handleEditListViewShow,
        ItemComboAdd,
        handleItemComboClose,
        handleItemComboShow,
        SupplierAdd,
        handleSupplierClose,
        handleSupplierShow,
        CurrencyExchange,
        setCurrencyExchange,
        CurrencyExchangeAdd,
        handleCurrencyExchangeClose,
        handleCurrencyExchangeShow,
        enquiryTable,
        setEnquiryTable,
        currencyMasterData,
        setCurrencyMasterData,
        currencyMaster,
        handleCurrencyMasterClose,
        handleCurrencyMasterShow,
        chnagesalesepersonlist,
        setchnagesalesepersonlist,
        permisiontochangesalesperson,
        setpermisiontochangesalesperson,
        salesUserOptions,
        posItemData,
        setPosItemData,
        screenWidth,
        screenHeight,
        salesOrderData,
        contanctPost,
        setContanctPost,
        AddressPost,
        setAddressPost,
        supplierdetails,
        setsupplierdetails,
        contactdata,
        setcontactdata,
        Addressdata,
        setAddressdata,
        supplierFiliter,
        SetSupplierFiliter,
        costomerGroupdata,
        SetCostomerGroupdata,
        supplierGroupdata,
        SetSupplierGroupdata,
        supplierGroup,
        handlesupplierGroupClose,
        handlesupplierGroupShow,
        customerGroup,
        handlecustomerGroupClose,
        handlecustomerGroupShow,
        AlternateUomAdd,
        customerGroupSelect,
        setCustomerGroupSelec,
        supplierGroupSelect,
        setSupplierGroupSelect,
        StockAdjusmentTableData,
        setStockAdjusmentTableData,
        SerialNumberData,
        setSerialNumberData,
        InventoryHandlerData,
        setInventoryHandlerData,
        StockSerialHistory,
        setStockSerialHistory,
        BatchNumberData,
        setBatchNumberData,
        setSalesOrderData,
        InventoryHandlerDeletetionData,
        setInventoryHandlerDeletetionData,
        stockStatement,
        setStockStatement,
        posEdit,
        setPosEdit,
        numberingData,
        setNumberingData,
        ItemDisplayGroup,
        setItemDisplayGroup,
        historyIds,
        setHistoryIds,
        PosPaymentData,
        setPosPaymentData,
        itemaMasterEditModal,
        handleItemaMasterEditClose,
        handlItemaMastereEditShow,
        BomMasterData,
        setBomMasterData,
        editItemmasterData,
        setEditItemmasterData,
        BOMFinishedGoods,
        setBOMFinishedGoods,
        BOMRawMaterials,
        setBOMRawMaterials,
        BOMScrapStoreData, setBOMScrapStoreData,
        RoutingData, setRoutingData,
        accountGroupdata, setAccountGroupdata,
        ItemAccountSalesSelect, setItemAccountSalesSelect,
        dupRefData, setDupRefData,
        AccountMasterData, setAccountMasterData,
        waringModel, setWaringModel,
        clickedKey, setClickedKey, isKeyUpdated, setKeyUpdated, 
        isMrpTreeOrderQtyChecked, setMrpTreeOrderQtyChecked, mrpItemDataList, setMrpItemDataList,
        currentConferenceStore,
        screenSize,
        currentUserEvent, setCurrentUserEvent,
        selectedReportDates, setSelectedReportDates,
        formattedDate, 
        modelKey, setModelKey, handleCloseModelShow, handleDuplicateCurrentBom,
        YESTERDAY_DATE_FORMAT,
        activitiesMettingstatusList,
        allUsersList , CommanFetchForAllUser,
        permissionModelList,
        otherIncomeChargelist,
        prefetchValue
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export default DataContext
