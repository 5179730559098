import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const WaringModel = ({state, setstate , title, text, Confirmtion}) => { 
    const handleClose =()=>{
        if (setstate){
            setstate(false)
        }
        
    }

    return (
    <>
        <Modal
            show={state}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
             {text}.
            </Modal.Body>
            <Modal.Footer>
            <Button variant="outline-warning" size="sm" onClick={handleClose}>
                No
            </Button>
            <Button variant="outline-success" size="sm" onClick={()=>{Confirmtion("yes"); handleClose()}} >Yes</Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}

export default WaringModel