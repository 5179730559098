export const expenseCategoriesCreateMutation=(data)=>{
    return  `
    mutation MyMutation { 
  expenseCategoriesCreateMutation(${data}) { 

    errors 

    success 

  } 

} 
    `
  }

  export const expenseCategoriesDeleteMutation=(data)=>{
    return  `
mutation MyMutation { 

  expenseCategoriesDeleteMutation(${data}) { 

    errors 

    success 

  } 

} 
    `
  }

  export const expenseClaimModalCreateMutation=(data)=>{
    return  `
    mutation MyMutation { 

  expenseClaimDetailsCreateMutation(${data}) { 

    errors 

    success 
ExpenseClaimDetailsInstance {
      amount
      createdBy {
        id
      }
      id
      descriptions
      dateOfExp
      expenseCategories{
        id
        expenseCategoryName
      }
      pdfUrl
      gstIn
    }
  } 

} 
    `
  }


  export const expenseClaimDetailsDeleteMutation=(data)=>{
    return  `
mutation MyMutation { 

  expenseClaimDetailsDeleteMutation(${data}) { 

    errors 

    success 

  } 

}  
    `
  }


  export const expenseClaimCreateMutation=(data)=>{
    return  `
   mutation MyMutation { 

  expenseClaimCreateMutation(${data}) { 

    errors 

    success 

  } 

} 
    `
  }

  export const expenseClaimDeleteMutation=(data)=>{
    return  `
   mutation MyMutation { 

  expenseClaimDeleteMutation(${data}) { 

    errors 

    success 

  } 

} 
    `
  }